import { Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
interface MissingVariablesIcon {
    show: boolean;
    withoutTooltip?: boolean;
}
const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = (props) => {
    if (!props.show) {
        return null;
    }
    if (props.withoutTooltip) {
        return withTheme((theme) => <AlertIcon theme={theme}/>);
    }
    return withTheme((theme) => (<Tooltip content={`Some of the required variable values have not been set`}>
            <AlertIcon theme={theme}/>
        </Tooltip>));
};
MissingVariablesIcon.displayName = "MissingVariablesIcon"
interface AlertIconProps {
    theme: OctopusTheme;
}
function AlertIcon({ theme }: AlertIconProps) {
    return <em className={"fa-solid fa-triangle-exclamation"} style={{ color: theme.alertText }}/>;
}
export default MissingVariablesIcon;
