import { Checkbox, Tooltip } from "@octopusdeploy/design-system-components";
import type { SpaceSearchResult } from "@octopusdeploy/octopus-server-client";
import { typeSafeHasOwnProperty } from "@octopusdeploy/octopus-server-client";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import fuzzysort from "fuzzysort";
import type { ReactElement } from "react";
import React, { useState } from "react";
import { client, repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import type { NonLinkableDocumentType } from "~/components/EventFormatter/EventFormatter";
import { isNonLinkableDocumentType, linkTo, retrieveDocumentTypeFromId } from "~/components/EventFormatter/EventFormatter";
import { NoResults } from "~/components/Images/NoResults/NoResults";
import type { UrlResolver } from "~/components/Navigation/useUrlResolver";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import { Note } from "~/components/form";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import styles from "./GlobalSearch.module.less";
import type { PageDefinitionWithRoutingInfo } from "./GlobalSearchRegistry";
import { pagesToIncludeInSearch } from "./GlobalSearchRegistry";
import HighlightMultipleMatches from "./HighlightMultipleMatches";
import HighlightSingleMatches from "./HighlightSingleMatches";
type PageSearchKeyTypes = "Name" | "Area";
const fuzzySearchKeys: PageSearchKeyTypes[] = ["Name", "Area"];
function searchPages(keyword: string) {
    if (!keyword) {
        return [];
    }
    const pagesToSearch = pagesToIncludeInSearch();
    const resultsToSearch = pagesToSearch
        .filter((x) => x.RouteLink) // They _must_ include a route link to be viable for search.
        .filter((x) => (x.RoutePermission ? isAllowed(x.RoutePermission) : true)) // If a permission is defined, it must pass.
        .sort((a, b) => a.Name.localeCompare(b.Name));
    const fuzzyResults = fuzzysort
        .go(keyword, resultsToSearch, {
        limit: 100,
        threshold: -10000,
        keys: fuzzySearchKeys,
    })
        .map((x) => {
        const result: PageSearchResult = {
            page: x.obj,
            matches: x,
        };
        return result;
    });
    return fuzzyResults;
}
async function searchSpaces(keyword: string) {
    if (!keyword.trim()) {
        return [];
    }
    return await repository.Spaces.search(keyword);
}
const formatRouteLinkForPage = (page: PageDefinitionWithRoutingInfo, pageUrlResolver: UrlResolver) => {
    const routeLink = page.RouteLink();
    if (typeof routeLink === "object") {
        return pageUrlResolver.resolve(routeLink);
    }
    return resolveStringPathWithSpaceId(getRouteFromString(routeLink), client.spaceId ?? "");
};
function getRouteFromString(routeLink: string | (() => string)) {
    if (typeof routeLink === "string") {
        const includesSpacePrefix = routeLink.toLowerCase().includes("spaces-");
        if (!includesSpacePrefix) {
            return `/${client.spaceId}${routeLink}`;
        }
        return routeLink ?? "";
    }
    return routeLink ? routeLink() : "";
}
export interface PageSearchResult {
    page: PageDefinitionWithRoutingInfo;
    matches: Fuzzysort.KeysResult<PageDefinition>;
}
export type GlobalSearchResult = PageSearchResult | SpaceSearchResult;
export function IsPageSearchResult(result: GlobalSearchResult): result is PageSearchResult {
    if (result === null || result === undefined) {
        return false;
    }
    //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const converted = result as PageSearchResult;
    return converted.matches !== undefined && typeSafeHasOwnProperty(converted, "matches");
}
export function usePageSearch(keyword: string) {
    const [pageSearchResults, setPageSearchResults] = useState<PageSearchResult[]>([]);
    React.useEffect(() => {
        const fuzzyResults = searchPages(keyword);
        setPageSearchResults(fuzzyResults);
    }, [keyword]);
    return pageSearchResults;
}
export function useSpaceSearch(keyword: string, doBusyTask: DoBusyTask) {
    const raceConditionerRef: Readonly<React.MutableRefObject<RequestRaceConditioner>> = React.useRef(new RequestRaceConditioner());
    const [spaceSearchResults, setSpaceSearchResults] = useState<SpaceSearchResult[]>([]);
    React.useEffect(() => {
        doBusyTask(async () => {
            await raceConditionerRef.current.avoidStaleResponsesForRequest(searchSpaces(keyword), setSpaceSearchResults);
        });
    }, [keyword, doBusyTask]);
    return spaceSearchResults;
}
export function PageSearchResultListItem({ result }: {
    result: PageSearchResult;
}) {
    const nameIndex = fuzzySearchKeys.indexOf("Name");
    const areaIndex = fuzzySearchKeys.indexOf("Area");
    const pageNameResult = nameIndex !== -1 ? result.matches[nameIndex] : undefined;
    const pageAreaResult = areaIndex !== -1 ? result.matches[areaIndex] : undefined;
    const pageNameMatches = pageNameResult ? fuzzysort.indexes(pageNameResult).map((n) => n.valueOf()) : [];
    const pageAreaMatches = pageAreaResult ? fuzzysort.indexes(pageAreaResult).map((n) => n.valueOf()) : [];
    return (<div className={styles.menuItem}>
            <div className={styles.result}>
                <HighlightMultipleMatches text={result.page.Name} matches={pageNameMatches}/>
            </div>
            <Note className={styles.resultSubtext}>
                <HighlightMultipleMatches text={result.page.Area} matches={pageAreaMatches}/>
            </Note>
        </div>);
}
export function SpaceSearchResultListItem({ result, keyword }: {
    result: SpaceSearchResult;
    keyword: string;
}) {
    return (<div className={styles.menuItem}>
            <div className={styles.result}>
                <HighlightSingleMatches text={result.Name} highlight={keyword}/>
            </div>
            <Note className={styles.resultSubtext}>{result.Type}</Note>
        </div>);
}
export function EmptyGlobalSearchResults({ keyword, busy }: {
    keyword: string;
    busy: Promise<unknown> | undefined | boolean;
}): ReactElement | null {
    return !busy && keyword ? (<span className={styles.emptyState}>
            No results found for "{keyword}".
            <div className={styles.emptyStateImage}>
                <NoResults />
            </div>
        </span>) : (<span />);
}
export function getRedirectUrl(searchResult: GlobalSearchResult, pageUrlResolver: UrlResolver): string | NonLinkableDocumentType {
    if (IsPageSearchResult(searchResult)) {
        return formatRouteLinkForPage(searchResult.page, pageUrlResolver);
    }
    const documentType = retrieveDocumentTypeFromId(searchResult.Id);
    const routeLink = linkTo(searchResult.Id, documentType, client.spaceId ?? "");
    return isNonLinkableDocumentType(routeLink) ? routeLink : typeof routeLink === "object" ? pageUrlResolver.resolve(routeLink) : resolveStringPathWithSpaceId(routeLink, client.spaceId ?? "");
}
export function NoKeywordGuidance() {
    return (<Section className={styles.emptyState}>
            <span>Please enter a search term.</span>
            <div className={styles.emptyStateImage}>
                <NoResults />
            </div>
            <p>
                <em style={{ marginRight: "0.250rem" }} className={"fa-solid fa-circle-info"} aria-hidden="true"/>
                Start a search with <code>Control</code> + <code>Space</code>
                <br />
                Dismiss a search with <code>Esc</code>
            </p>
        </Section>);
}
export function SearchItemCount({ items, showServerResultsOnly, setShowServerResultsOnly }: {
    items: GlobalSearchResult[];
    showServerResultsOnly: boolean;
    setShowServerResultsOnly: (val: boolean) => void;
}) {
    return (<Section className={styles.showResultsSection} bodyClassName={styles.showResultsSectionBody}>
            <div>
                <span>
                    Showing {items.length} {items.length === 1 ? "result" : "results"}
                </span>
            </div>
            <div>
                <Tooltip content="Filter the search to API/server-side records only">
                    <Checkbox label="Server-side records only" value={showServerResultsOnly} onChange={setShowServerResultsOnly} noMargin={true}/>
                </Tooltip>
            </div>
        </Section>);
}
