/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Task from "~/areas/tasks/components/Task/Task";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import DataBaseComponent from "~/components/DataBaseComponent";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { ImportExportMenu, getImportExportMenuItems } from "./ImportExportMenu";
import styles from "./style.module.less";
interface ImportProjectTaskInternalProps extends ImportProjectTaskPageProps {
    isPageHeaderVNextEnabled: boolean;
}
class ImportProjectTaskPageInternal extends DataBaseComponent<ImportProjectTaskInternalProps, {
    space?: SpaceResource;
}> {
    taskId: string;
    constructor(props: ImportProjectTaskInternalProps) {
        super(props);
        this.taskId = this.props.taskId;
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId!)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        return this.state.space !== undefined && session.currentPermissions!.isSpaceManager(this.state.space);
    }
    render() {
        return (<main>
                <ContextualHelpLayout>
                    {this.props.isPageHeaderVNextEnabled ? (<PageHeaderPrimary title="Import project" navUpLink={{ label: "Projects", linkHref: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }} overflowActions={getImportExportMenuItems(this.props.spaceId)}/>) : (<AreaTitle title="Projects" link={links.projectsPage.generateUrl({ spaceId: this.props.spaceId })}>
                            <ActionList actions={[<ImportExportMenu spaceId={this.props.spaceId}/>]}/>
                        </AreaTitle>)}
                    {this.state.space !== undefined && !this.isSpaceManager() && (<Callout title="Permissions" type={CalloutType.Danger}>
                            Only Space Managers can perform an export or import.
                        </Callout>)}
                    {this.state.space !== undefined && this.isSpaceManager() && (<div className={this.props.isPageHeaderVNextEnabled ? styles.containerVNext : styles.container}>
                            <Task taskId={this.taskId} delayRender={() => false}/>
                        </div>)}
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "ImportProjectTaskPageInternal";
}
interface ImportProjectTaskPageProps {
    spaceId: string;
    taskId: string;
}
export const ImportProjectTaskPage = (props: ImportProjectTaskPageProps) => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <ImportProjectTaskPageInternal {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
};
