import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import type { ReportFilter } from "~/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import AreaTitle from "~/components/AreaTitle/index";
import type { Errors } from "~/components/DataBaseComponent/index";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout/index";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary/index";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { ErrorPanel } from "~/components/form/index";
export interface InsightsReportLayoutProps {
    report: InsightsReportResource;
    reportFilter?: ReportFilter;
    children: React.ReactNode;
}
export function InsightsReportLayout({ report, children, reportFilter }: InsightsReportLayoutProps) {
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const sidebarLinks = [
        Navigation.navItem("Overview", links.insightsReportOverviewPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment frequency", links.insightsReportDeploymentFrequencyPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment lead time", links.insightsReportDeploymentLeadTimePage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment failure rate", links.insightsReportDeploymentFailureRatePage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Mean time to recovery", links.insightsReportDeploymentMeanTimeToRecoveryPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Deployment duration", links.insightsReportDeploymentDurationPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id }, reportFilter)),
        Navigation.navItem("Settings", links.insightsReportSettingsPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id })),
    ];
    const computedSidebarLinks = compact(sidebarLinks);
    if (isPageHeaderVNextEnabledOutsideOfProjects) {
        return (<main>
                <NavigationSideBarLayoutVNext description={report.Description} navItems={computedSidebarLinks} content={children} header={<PageHeaderPrimary navUpLink={{ label: "Insights", linkHref: links.insightReportListPage.generateUrl({ spaceId: report.SpaceId }) }} title={report.Name} logo={{ href: report.Links.Logo, accessibleName: `${report.Name} logo` }}/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={links.insightReportListPage.generateUrl({ spaceId: report.SpaceId })} title="Insights"/>
            <NavigationSidebarLayout logoUrl={report.Links.Logo} description={report.Description} name={report.Name} navLinks={computedSidebarLinks} content={children}/>
        </main>);
}
export interface InsightsReportLoadingLayoutProps {
    spaceId: string;
    errors: Errors | undefined;
    busy: boolean;
}
export function InsightsReportLoadingLayout({ spaceId, busy, errors }: InsightsReportLoadingLayoutProps) {
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return (<main>
            {isPageHeaderVNextEnabledOutsideOfProjects ? (<PageHeaderPrimary title="Insights"/>) : (<AreaTitle link={links.insightReportListPage.generateUrl({ spaceId })} title="Insights" busyIndicator={<LinearProgress variant={"indeterminate"} show={busy}/>}/>)}
            {isPageHeaderVNextEnabledOutsideOfProjects && <LinearProgress variant={"indeterminate"} show={busy}/>}
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>}
        </main>);
}
