import { css } from "@emotion/css";
import type { AccountResource, CertificateResource, CommonTemplateTenantValue, ProjectTemplateTenantValue, PropertyValueResource, TenantResource, TenantVariableResource, TenantVariableTemplateResource, WorkerPoolResource, } from "@octopusdeploy/octopus-server-client";
import { ControlType, AccountType, isProjectTemplateTenantValue, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TenantVariableName } from "~/areas/projects/components/Variables/TenantVariables/TenantVariableName";
import { repository } from "~/clientInstance";
import { EnvironmentChip } from "~/components/Chips/index";
import { DataBaseComponent, type DataBaseComponentState } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import TenantVariableInput from "~/components/TenantVariableInput/TenantVariableInput";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
export interface UpdateTenantVariableDialogProps {
    templateOwnerId: string;
    variable: ProjectTemplateTenantValue | CommonTemplateTenantValue;
    onSave: () => Promise<boolean>;
}
export default function UpdateTenantVariableDialog(props: UpdateTenantVariableDialogProps) {
    return (<InitialDataLoader load={loadInitialData.bind(null, props.variable.TenantId)} operationName="UpdateTenantVariable" renderWhenLoaded={(data) => <UpdateTenantVariable {...props} {...data}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title={"Update Variable"} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface InitialDataProps {
    tenant: TenantResource;
    tenantVariables: TenantVariableResource;
}
const InitialDataLoader = DataLoader<InitialDataProps>();
const loadInitialData = async (tenantId: string): Promise<InitialDataProps> => {
    const tenant = await repository.Tenants.get(tenantId);
    const tenantVariables = await repository.Tenants.getVariables(tenant);
    return { tenant, tenantVariables };
};
interface UpdateTenantVariableProps extends UpdateTenantVariableDialogProps, InitialDataProps {
}
interface UpdateTenantVariableState extends DataBaseComponentState {
    accounts: AccountResource[];
    tenantCertificates: CertificateResource[] | null;
    workerPools: WorkerPoolResource[] | null;
    tenantVariables: TenantVariableResource;
    variableTemplate: TenantVariableTemplateResource | undefined;
    value: PropertyValueResource | undefined;
    initialValue: PropertyValueResource | undefined;
}
class UpdateTenantVariable extends DataBaseComponent<UpdateTenantVariableProps, UpdateTenantVariableState> {
    constructor(props: UpdateTenantVariableProps) {
        super(props);
        const variableTemplate = this.determineEditingTemplate();
        const initialValue = variableTemplate === undefined
            ? undefined
            : isProjectTemplateTenantValue(props.variable)
                ? this.props.tenantVariables.ProjectVariables[this.props.templateOwnerId].Variables[props.variable.EnvironmentId][variableTemplate.Id]
                : this.props.tenantVariables.LibraryVariables[this.props.templateOwnerId].Variables[variableTemplate.Id];
        this.state = {
            accounts: [],
            tenantCertificates: null,
            workerPools: null,
            tenantVariables: props.tenantVariables,
            variableTemplate,
            value: initialValue,
            initialValue,
        };
    }
    private determineEditingTemplate() {
        return isProjectTemplateTenantValue(this.props.variable)
            ? this.props.tenantVariables.ProjectVariables[this.props.templateOwnerId].Templates.find((t) => t.Id === this.props.variable.TemplateId)
            : this.props.tenantVariables.LibraryVariables[this.props.templateOwnerId].Templates.find((t) => t.Id === this.props.variable.TemplateId);
    }
    async componentDidMount() {
        await this.loadAccounts();
    }
    render() {
        const { variableTemplate, value, initialValue, busy } = this.state;
        if (!variableTemplate) {
            return (<InfoDialogLayout title="Could not find variable template">
                    <Callout title="Could not find variable template" type={CalloutType.Danger}>
                        Unable to load variable template with Id <strong>{this.props.variable.TemplateId}</strong>.
                    </Callout>
                </InfoDialogLayout>);
        }
        const sourceItems = {
            accounts: {
                items: this.state.accounts,
                type: [AccountType.AzureServicePrincipal, AccountType.AzureSubscription, AccountType.AzureOidc],
                onRequestRefresh: this.loadAccounts.bind(this),
            },
            certificates: {
                items: this.getCertificates.bind(this),
                onRequestRefresh: this.refreshCertificates.bind(this),
            },
            workerPools: {
                items: this.getWorkerPools.bind(this),
                onRequestRefresh: this.refreshWorkerPools.bind(this),
            },
            tenantId: this.props.tenant.Id,
        };
        const connectedProjects = Object.keys(this.props.tenant.ProjectEnvironments).length;
        return (<SaveDialogLayout title={"Update Variable"} busy={busy} errors={this.errors} onSaveClick={this.save.bind(this)} savePermission={isProjectTemplateTenantValue(this.props.variable) ? { permission: Permission.VariableEdit, project: this.props.templateOwnerId, wildcard: true } : { permission: Permission.VariableEdit, wildcard: true }} saveButtonDisabled={value === initialValue} saveButtonLabel={"Update"}>
                <div className={styles.content}>
                    {isProjectTemplateTenantValue(this.props.variable) ? (<div>
                            <TenantVariableName name={variableTemplate.Name}/> <span>for {this.props.variable.TenantName} in</span>
                            <EnvironmentChip environmentName={this.props.variable.EnvironmentName} key={this.props.variable.EnvironmentId}/>
                        </div>) : (<>
                            {connectedProjects > 1 ? (<Callout type={CalloutType.Information} title={"Other projects will be affected"}>
                                    Updating this value will affect <strong>all {connectedProjects} projects</strong> this tenant is connected to.
                                </Callout>) : null}
                            <div>
                                <TenantVariableName name={variableTemplate.Name}/> <span>for {this.props.variable.TenantName}</span>
                            </div>
                        </>)}
                    <TenantVariableInput variableTemplate={variableTemplate} sourceItems={sourceItems} localNames={this.buildOtherBindableVariables()} doBusyTask={this.doBusyTask} value={value} warning={variableTemplate.DefaultValue || value ? undefined : "Value required for deployment"} onChange={this.handleChange.bind(this)} hideSearch={true} withoutDebounce={true}/>
                </div>
            </SaveDialogLayout>);
    }
    handleChange(newValue: PropertyValueResource | undefined) {
        this.setState({ value: newValue });
    }
    async loadAccounts() {
        const template = this.determineEditingTemplate();
        if (!template) {
            return;
        }
        const editingType = template.DisplaySettings["Octopus.ControlType"];
        if (editingType && AccountControlTypes.has(editingType)) {
            await this.doBusyTask(async () => {
                const accounts = await repository.Accounts.all();
                this.setState({ accounts });
            });
        }
    }
    async getCertificates() {
        let certificates = this.state.tenantCertificates;
        if (!certificates) {
            certificates = await repository.Certificates.listForTenant(this.props.tenant.Id);
            this.setState({ tenantCertificates: certificates });
        }
        return certificates;
    }
    refreshCertificates() {
        return this.doBusyTask(async () => {
            const certificates = await repository.Certificates.listForTenant(this.props.tenant.Id);
            this.setState({ tenantCertificates: certificates });
        });
    }
    async getWorkerPools() {
        let pools = this.state.workerPools;
        if (!pools) {
            pools = await repository.WorkerPools.all();
            this.setState({ workerPools: pools });
        }
        return pools;
    }
    refreshWorkerPools() {
        return this.doBusyTask(async () => {
            const pools = await repository.WorkerPools.all();
            this.setState({ workerPools: pools });
        });
    }
    buildOtherBindableVariables() {
        const variables = isProjectTemplateTenantValue(this.props.variable) ? this.props.tenantVariables.ProjectVariables[this.props.templateOwnerId] : this.props.tenantVariables.LibraryVariables[this.props.templateOwnerId];
        const uniqTemplateNames = new Set(variables.Templates.map((t) => t.Name));
        return Array.from(uniqTemplateNames);
    }
    save() {
        return this.doBusyTask(async () => {
            const templateId = this.props.variable.TemplateId;
            const templateCollectionId = this.props.templateOwnerId;
            const tenantVariables = this.props.tenantVariables;
            let updated: TenantVariableResource | null = null;
            if (isProjectTemplateTenantValue(this.props.variable)) {
                const environmentId = this.props.variable.EnvironmentId;
                updated = {
                    ...tenantVariables,
                    ProjectVariables: {
                        ...tenantVariables.ProjectVariables,
                        [templateCollectionId]: {
                            ...tenantVariables.ProjectVariables[templateCollectionId],
                            Variables: {
                                ...tenantVariables.ProjectVariables[templateCollectionId].Variables,
                                [environmentId]: {
                                    ...tenantVariables.ProjectVariables[templateCollectionId].Variables[environmentId],
                                    [templateId]: this.state.value ?? "",
                                },
                            },
                        },
                    },
                };
            }
            else {
                updated = {
                    ...tenantVariables,
                    LibraryVariables: {
                        ...tenantVariables.LibraryVariables,
                        [templateCollectionId]: {
                            ...tenantVariables.LibraryVariables[templateCollectionId],
                            Variables: {
                                ...tenantVariables.LibraryVariables[templateCollectionId].Variables,
                                [templateId]: this.state.value ?? "",
                            },
                        },
                    },
                };
            }
            await repository.Tenants.setVariables(this.props.tenant, updated);
            await this.props.onSave();
        });
    }
    static displayName = "UpdateTenantVariable";
}
const AccountControlTypes = new Set([ControlType.AmazonWebServicesAccount, ControlType.AzureAccount, ControlType.GoogleCloudAccount, ControlType.UsernamePasswordAccount]);
const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
    }),
};
