import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { KubernetesRawYamlProperties } from "@octopusdeploy/legacy-action-properties";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { GitDependencyReference } from "@octopusdeploy/octopus-server-client/dist/src/resources/gitDependencyReference";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import GitCredentialSelect from "~/areas/projects/components/ProjectSettings/VersionControl/GitCredentialSelect";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import type { SummaryNode } from "~/components/form/index";
import { FormSectionHeading, Note, required, Summary } from "~/components/form/index";
export interface KubernetesRawYamlGitSourceComponentProps extends ActionEditProps<KubernetesRawYamlProperties> {
    expandedByDefault: boolean;
    gitCredentials: GitCredentialResource[];
    refreshGitCredentials: () => Promise<unknown>;
    filePathValidation: (filePaths: string, notSetValidationMessage: string) => string;
}
const customResourceYamlFileNameProperty = "Octopus.Action.KubernetesContainers.CustomResourceYamlFileName";
function GetFilePathsFromString(filePathsString?: string | null): string[] {
    if (!filePathsString)
        return [];
    return filePathsString
        .split(/[\r|\n]/)
        .map((i) => i.trim())
        .filter(Boolean);
}
function KubernetesRawYamlGitSourceComponent(props: KubernetesRawYamlGitSourceComponentProps) {
    const [gitCredentials, setGitCredentials] = useState(props.gitCredentials);
    useEffect(() => setGitCredentials(props.gitCredentials), [props.gitCredentials]);
    const primaryGitDependencyFromProps = props.gitDependencies?.find((r) => r.Name === "");
    const filePaths = GetFilePathsFromString(props.properties[customResourceYamlFileNameProperty]);
    const [primaryGitDependency, setGitDep] = useState<GitDependencyReference>({
        Name: primaryGitDependencyFromProps?.Name || "",
        DefaultBranch: primaryGitDependencyFromProps?.DefaultBranch || "main",
        GitCredentialType: primaryGitDependencyFromProps?.GitCredentialType || "Library",
        RepositoryUri: primaryGitDependencyFromProps?.RepositoryUri || "",
        GitCredentialId: primaryGitDependencyFromProps?.GitCredentialId,
        FilePathFilters: primaryGitDependencyFromProps?.FilePathFilters || filePaths,
    });
    const setResourceProperty = useCallback((property: keyof GitDependencyReference, value: string) => {
        setGitDep((prevDep) => ({
            ...prevDep,
            [property]: value,
        }));
        props.setGitDependencies?.([
            {
                ...primaryGitDependency,
                [property]: value,
            },
        ]);
    }, [primaryGitDependency, props]);
    const setFilePathFilter = useCallback((filePathsString: string) => {
        const filePaths = GetFilePathsFromString(filePathsString);
        setGitDep((prevDep) => ({
            ...prevDep,
            FilePathFilters: filePaths,
        }));
        props.setGitDependencies?.([
            {
                ...primaryGitDependency,
                FilePathFilters: filePaths,
            },
        ]);
    }, [primaryGitDependency, props]);
    const setRepositoryUri = (val: string) => setResourceProperty("RepositoryUri", val);
    const setCredentialType = (val: string) => setResourceProperty("GitCredentialType", val);
    const setCredentialId = (val: string) => setResourceProperty("GitCredentialId", val);
    const setDefaultBranch = (val: string) => setResourceProperty("DefaultBranch", val);
    const setFilePaths = (val: string) => {
        setFilePathFilter(val);
        props.setProperties({ [customResourceYamlFileNameProperty]: val });
    };
    const isUsingLibraryAuth = (): boolean => primaryGitDependency.GitCredentialType === "Library";
    const authSummary = (): SummaryNode => {
        if (isUsingLibraryAuth()) {
            const id = primaryGitDependency.GitCredentialId;
            const credential = gitCredentials.find((c) => c.Id === id);
            return Summary.summary(React.Children.toArray([
                <span>Using library credential</span>,
                <span>
                        <strong>{credential ? ` ${credential.Name}` : ""}</strong>
                    </span>,
            ]));
        }
        else {
            return Summary.summary(React.Children.toArray([
                <span>
                        Authenticated as an <strong>Anonymous</strong> user
                    </span>,
            ]));
        }
        return Summary.placeholder("Enter authentication details");
    };
    const repoUrlSummary = (): SummaryNode => (primaryGitDependency.RepositoryUri ? Summary.summary(primaryGitDependency.RepositoryUri) : Summary.placeholder("Enter a Git repository URL"));
    const filePathSummary = (): SummaryNode => {
        const filePaths = props.properties[customResourceYamlFileNameProperty]
            ?.split(/[\r|\n]/)
            .map((i) => i.trim())
            .filter(Boolean)
            .join(", ");
        return filePaths ? Summary.summary(filePaths) : Summary.placeholder("Provide a path to your YAML file(s)");
    };
    const branchSummary = (): SummaryNode => primaryGitDependency.DefaultBranch
        ? Summary.summary(<span>
                      Default branch is <strong>{primaryGitDependency.DefaultBranch}</strong>
                  </span>)
        : Summary.placeholder("Configure default branch");
    const getPathsNote = () => (<Note>
            This field supports glob patterns syntax e.g. <code>my/folder/*</code> will include all files in a folder.
            <br />
            Separate multiple paths with new lines. See our <ExternalLink href={"glob-patterns-cheat-sheet"}>glob patterns cheat sheet</ExternalLink> for more info.
        </Note>);
    const getPathsAccessibleName = () => "Paths to the YAML files in your Git Repository";
    const getPathsLabel = () => "Kubernetes YAML file paths";
    const getPathsHelpText = () => "Provide the paths to your YAML files";
    const getPathsNotSetValidationMessage = () => "Enter the paths to your YAML files";
    return (<div>
            <FormSectionHeading title="Git Repository Details"/>
            <ExpandableFormSection errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.Git.Repository" isExpandedByDefault={props.expandedByDefault} title="Authentication" summary={authSummary()} help={"Choose a method to authenticate your Git repository"}>
                <RadioButtonGroup value={primaryGitDependency.GitCredentialType} onChange={(val) => setCredentialType(val)} error={props.getFieldError("GitCredentialType")} accessibleName="GitCredentialType">
                    <RadioButton value={"Library"} label="Library" accessibleName="GitCredentialTypeLibrary"/>
                    {isUsingLibraryAuth() && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>You need the 'GitCredentialView' permission to change the Git credential.</Note>}>
                            <GitCredentialSelect items={gitCredentials} onChange={(Id) => setCredentialId(Id)} value={primaryGitDependency.GitCredentialId ?? ""} onRequestRefresh={props.refreshGitCredentials} error={props.getFieldError("GitCredentialId")} helperText="Select the Git credential to use" data-testid="gitCredentialSelect"/>
                            <Note> Use credential from the Git credential library </Note>
                        </PermissionCheck>)}
                    <RadioButton value={"Anonymous"} label="Anonymous" accessibleName="GitCredentialTypeAnonymous"/>
                </RadioButtonGroup>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Url" title="Repository URL" summary={repoUrlSummary()} help="Add the Git repository where you store your YAML files" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="Url" localNames={props.localNames} value={primaryGitDependency.RepositoryUri} onChange={(Url) => setRepositoryUri(Url)} label="Repository URL" error={props.getFieldError("RepositoryUri")} validate={required("Enter a Git repository URL.")} accessibleName={"URL for Git repository"}/>
                <Note>
                    The HTTPS URL to your git repo. E.g.{" "}
                    <code>
                        https://
                        <wbr />
                        github.com/
                        <wbr />
                        OctopusDeploy/
                        <wbr />
                        OctopusClients.git
                    </code>
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={branchSummary()} help="Specify the default branch you want to use" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="DefaultBranch" localNames={props.localNames} value={primaryGitDependency.DefaultBranch} onChange={(DefaultBranch) => setDefaultBranch(DefaultBranch)} label="Default Branch" error={props.getFieldError("DefaultBranch")} validate={required("Enter the default branch for your Git repository.")} accessibleName={"Name of the default branch on the Git repository"}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Path" title="Paths" summary={filePathSummary()} help={getPathsHelpText()} isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="Path" localNames={props.localNames} value={props.properties[customResourceYamlFileNameProperty]} onChange={(FilePaths) => setFilePaths(FilePaths)} label={getPathsLabel()} error={props.getFieldError(customResourceYamlFileNameProperty)} validate={(p) => props.filePathValidation(p, getPathsNotSetValidationMessage())} accessibleName={getPathsAccessibleName()} multiline={true}/>
                {getPathsNote()}
            </ExpandableFormSection>
        </div>);
}
export default KubernetesRawYamlGitSourceComponent;
