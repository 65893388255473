/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CertificateResource, ResourceCollection, TagSetResource, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { xor, uniqBy, values } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import InternalLink from "~/components/Navigation/InternalLink";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import CertificateSummary from "./CertificateSummary";
interface CertificateListProp {
    certificates?: ResourceCollection<CertificateResource>;
    selectedCertificateId?: string;
    tenantId?: string;
    doBusyTask: (action: () => Promise<any>) => Promise<boolean>;
    onSelected?: (certificate: CertificateResource) => void;
    archived?: boolean;
}
interface CertificateListState {
    certificates?: ResourceCollection<CertificateResource>;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selectedId?: string;
    busy: boolean;
}
export const CertificateListNotFoundCallout = () => {
    // INFO: the string here was split because linting was
    // complaining about the '.' character
    // It also ensures the correct format of the message
    const front = "We couldn't find any certificates, add one ";
    const back = "here.";
    return (<Callout title="No certificates found" type={CalloutType.Information}>
            <p>
                {front}
                <InternalLink openInSelf={false} to={links.createCertificatePage.generateUrl({ spaceId: repository.spaceId! })}>
                    {back}
                </InternalLink>
            </p>
        </Callout>);
};
class CertificateList extends List<CertificateResource> {
}
export default class CertificateSearch extends BaseComponent<CertificateListProp, CertificateListState> {
    constructor(props: CertificateListProp) {
        super(props);
        this.state = {
            certificates: this.props.certificates,
            environments: [],
            tenants: [],
            tenantTags: [],
            busy: false,
            selectedId: this.props.selectedCertificateId,
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            let certificates = this.state.certificates;
            if (!certificates) {
                certificates = await repository.Certificates.list({ tenant: this.props.tenantId });
            }
            const [tenantTags] = await Promise.all([repository.TagSets.all()]);
            this.setState({
                certificates,
                tenantTags,
            });
        });
    }
    buildRow = (certificate: CertificateResource) => {
        return [
            <CertificateSummary onClick={() => {
                    if (!this.props.onSelected) {
                        return;
                    }
                    this.setState({ selectedId: certificate.Id }, () => {
                        if (this.props.onSelected) {
                            this.props.onSelected(certificate);
                        }
                    });
                }} showSelection={this.props.onSelected ? true : false} selected={certificate.Id === this.state.selectedId} key={certificate.Id} certificate={certificate} environments={values(this.state.environments)} tenants={values(this.state.tenants)} tenantTags={this.state.tenantTags}/>,
        ];
    };
    rowClicked = (certificate: CertificateResource) => {
        if (this.props.onSelected) {
            return null;
        }
        else {
            return links.editCertificatePage.generateUrl({ spaceId: certificate.SpaceId, certificateId: certificate.Id });
        }
    };
    applyFilter(filter: string, resource: CertificateResource) {
        return (!filter ||
            filter.length === 0 ||
            !resource ||
            (resource.Name ? resource.Name.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (resource.SubjectCommonName ? resource.SubjectCommonName.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (resource.Thumbprint ? resource.Thumbprint.toLowerCase().includes(filter.toLowerCase()) : false));
    }
    renderCertificateList(additionalRequestParams: Map<string, any>) {
        return (<CertificateList initialData={this.state.certificates} onRow={this.buildRow} onRowRedirectUrl={this.rowClicked} onFilter={this.applyFilter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name, subject, or thumbprint" onNewItems={this.loadRelatedCertificateData} additionalRequestParams={additionalRequestParams}/>);
    }
    render() {
        const additionalRequestParams = new Map<string, any>();
        if (this.props.archived) {
            additionalRequestParams.set("archived", "true");
        }
        const hasCertificates = this.state.certificates && this.state.certificates.Items.length > 0;
        if (hasCertificates) {
            // Note: This is wrapped in a <div> on purpose for CSS transition animations.
            return <div>{this.renderCertificateList(additionalRequestParams)}</div>;
        }
        else {
            return (<div>
                    <CertificateListNotFoundCallout />
                </div>);
        }
    }
    private loadRelatedCertificateData = async (certificates: CertificateResource[]): Promise<CertificateResource[]> => {
        const environmentIds = xor(certificates.map((c) => c.EnvironmentIds).reduce((list, ids) => list.concat(ids), []), this.state.environments.map((x) => x.Id));
        const tenantIds = xor(certificates.map((c) => c.TenantIds).reduce((list, ids) => list.concat(ids), []), this.state.tenants.map((x) => x.Id));
        await this.props.doBusyTask(async () => {
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ ids: tenantIds }) : Promise.resolve([]);
            const [environments, tenants] = await Promise.all([repository.Environments.all({ ids: environmentIds }), tenantsPromise]);
            this.setState((x) => ({
                ...x,
                environments: uniqBy([...x!.environments, ...environments], (env) => env.Id),
                tenants: uniqBy([...x!.tenants, ...tenants], (tenant) => tenant.Id),
            }));
        });
        return certificates;
    };
    static displayName = "CertificateSearch";
}
