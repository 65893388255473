/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import { Drawer } from "@material-ui/core";
import { IconButton } from "@octopusdeploy/design-system-components";
import { usePage } from "@octopusdeploy/portal-routes";
import { OctoLink } from "@octopusdeploy/utilities";
import classNames from "classnames";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { createHtmlPortalNode, InPortal, OutPortal } from "react-reverse-portal";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import drawerActions from "~/components/ContextualHelpLayout/reducers/actions";
import * as drawerSelectors from "~/components/ContextualHelpLayout/reducers/selectors";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { GlobalState } from "../../store/reducers";
import styles from "./ContextualHelpLayout.module.less";
import { HelpSidebarHandle } from "./HelpSidebarHandle";
import { HelpSidebarResources } from "./HelpSidebarResources";
import { PageHelpOverviewView, PageHelpTopicsView } from "./PageHelpRegistry/PageHelpSelector";
export const drawerBreakpointWidth = 810;
export function ContextualHelpLayout(props: React.PropsWithChildren<{}>) {
    const dispatch = useDispatch();
    const toggleDrawer: () => void = React.useCallback(() => dispatch(drawerActions.toggleDrawer()), [dispatch]);
    const isDrawerOpen = useSelector(drawerSelectors.isDrawerOpen);
    const isHelpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((x) => x.isHelpSidebarEnabled));
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const portalNode = React.useMemo(() => createHtmlPortalNode({ attributes: { class: cx({ [portalNodeStyles]: isLargerThanIpad }) } }), [isLargerThanIpad]);
    return (<React.Fragment>
            <InPortal node={portalNode}>{props.children}</InPortal>
            <MediaQuery minWidth={drawerBreakpointWidth}>
                {(matches: boolean) => {
            if (matches && isHelpSidebarEnabled) {
                return (<div id="drawerwrapper">
                                <div className={styles.root}>
                                    <main className={classNames(styles.mainContent, {
                        [styles.mainContentShift]: isDrawerOpen,
                    })}>
                                        <OutPortal node={portalNode}/>
                                    </main>
                                    <ContextualHelpDrawer open={isDrawerOpen} toggleDrawer={toggleDrawer}/>
                                </div>
                            </div>);
            }
            else {
                return <OutPortal node={portalNode}/>;
            }
        }}
            </MediaQuery>
        </React.Fragment>);
}
const portalNodeStyles = css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
});
enum DrawerTabKey {
    Overview = "overview",
    HelpTopics = "helpTopics"
}
interface ContextualHelpSidebarContentProps {
    additionalTabsElement: React.ReactElement | undefined;
}
function ContextualHelpSidebarContent(props: ContextualHelpSidebarContentProps) {
    const page = usePage();
    const dispatchAction = useAnalyticActionDispatch();
    const [tab, setTab] = React.useState<string>(DrawerTabKey.Overview);
    const customHelpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const customHelpSidebarSupportLinkLabel = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLinkLabel);
    const helpSidebarSupportLink = customHelpSidebarSupportLink || OctoLink.Create("HelpGeneral");
    const getHelpSidebarSupportLinkLabel = () => {
        if (!customHelpSidebarSupportLink) {
            return "Our support team is here to help";
        }
        return customHelpSidebarSupportLinkLabel || "Your organization's internal support";
    };
    const dispatch = useDispatch();
    const toggleDrawer: () => void = React.useCallback(() => dispatch(drawerActions.toggleDrawer()), [dispatch]);
    const changeTab = (newTab: string) => {
        if (newTab === tab)
            return;
        setTab(newTab);
        switch (newTab) {
            case DrawerTabKey.Overview:
                dispatchAction("Sidebar: Click Help Tab", { action: Action.Select, resource: "Help Sidebar Overview" });
                break;
            case DrawerTabKey.HelpTopics:
                dispatchAction("Sidebar: Click Resources Tab", { action: Action.Select, resource: "Help Sidebar Resources" });
                break;
        }
    };
    return (<>
            <div className={styles.header}>
                <h4 className={styles.heading}>Help Sidebar</h4>
                <IconButton icon="Cancel" onClick={() => toggleDrawer()}/>
            </div>
            <div className={styles.content}>
                <>
                    <PageHelpOverviewView pageId={page?.Id}/>
                    <PageHelpTopicsView pageId={page?.Id}/>
                </>
            </div>
            <HelpSidebarResources />
        </>);
}
interface ContextualHelpDrawerProps {
    open: boolean;
    toggleDrawer: () => void;
}
function ContextualHelpDrawer({ open, toggleDrawer }: ContextualHelpDrawerProps) {
    const [height, setHeight] = React.useState(0);
    const drawerDiv = React.useRef<HTMLDivElement>(null);
    const calculateHeight = React.useCallback(() => {
        if (drawerDiv.current !== null) {
            const top = drawerDiv.current.getBoundingClientRect().top;
            const approxHeightOfPaddingAndOtherJunk = 0;
            setHeight(window.innerHeight - top - approxHeightOfPaddingAndOtherJunk);
        }
    }, []);
    //The material drawer in this layout uses a static position and a height of 100% shifts things like footers off screen
    //we therefore calculate the necessary height and set it
    React.useLayoutEffect(calculateHeight, [calculateHeight]);
    //Since we are manually handling the height, we also need to adjust things based on when the browser resizes
    React.useEffect(() => {
        window.addEventListener("resize", calculateHeight);
        return () => window.removeEventListener("resize", calculateHeight);
    }, [calculateHeight]);
    return (<div className={classNames(styles.wrapper, {
            [styles.drawerHidden]: !open,
        })} style={{ minHeight: `${height - 1}px` }}>
            {open ? <HelpSidebarHandle /> : null}
            <Drawer className={styles.drawer} variant="persistent" anchor="right" open={open} classes={{
            paper: styles.drawerPaper,
        }}>
                <div className={styles.container} ref={drawerDiv} style={{ height }}>
                    {open && <ContextualHelpSidebarContent additionalTabsElement={<OverflowMenu menuItems={[OverflowMenuItems.item("Close", toggleDrawer)]} tabIndex={-1}/>}/>}
                </div>
            </Drawer>
        </div>);
}
