/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { borderRadius } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import classNames from "classnames";
import { debounce } from "lodash";
import * as React from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import { client } from "~/clientInstance";
import InternalNavLink from "~/components/Navigation/InternalNavLink/InternalNavLink";
import styles from "./style.module.less";
interface LogoProps {
    url: string;
    size?: string;
    isDisabled?: boolean;
    editLink?: LinkHref;
    className?: string;
    overflow?: boolean;
    isSquare?: boolean;
}
class Logo extends React.Component<LogoProps> {
    private static defaultProps: Partial<LogoProps> = {
        size: "3.1rem",
    };
    // This is static because we want it to be debounced so that when we have lots of Logos calling it we are not rechecking it for every single one
    private static recheckVisibility = debounce(() => {
        forceCheck();
    }, 800);
    // We need to force a recheck because LazyLoad only rechecks on scrolling, but because our screens support filtering, that bypasses the scrolling event
    componentDidUpdate() {
        Logo.recheckVisibility();
    }
    render() {
        if (!this.props.url) {
            return <div />;
        }
        const resolvedUrl = this.props.url.startsWith("~") || this.props.url.startsWith("/") ? client.resolve(this.props.url) : this.props.url;
        const logoImage = (<LazyLoad once={true} height={this.props.size} overflow={this.props.overflow}>
                <img src={resolvedUrl} style={{
                width: this.props.size,
                height: this.props.size,
            }} className={this.props.isDisabled ? styles.disabled : null} alt="Logo"/>
            </LazyLoad>);
        const borderRadiusStyling = css({
            borderRadius: this.props.isSquare ? borderRadius.medium : "3.75rem",
        });
        return (<div className={classNames(styles.logo, borderRadiusStyling, this.props.className)}>
                {this.props.editLink ? (<Tooltip content="Edit logo" position="right">
                        <InternalNavLink to={this.props.editLink} className={styles.logoImageLink}>
                            {logoImage}
                        </InternalNavLink>
                    </Tooltip>) : (logoImage)}
            </div>);
    }
    static displayName = "Logo";
}
export default Logo;
