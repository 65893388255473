/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TelemetryConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import moment from "moment";
import * as React from "react";
import { client, repository, session } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle/index";
import { NewFeatureChip } from "~/components/Chips";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import PaperLayout from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import { TelemetryHasBeenIntroducedLocalStorageKey } from "../Telemetry/TelemetryPage";
interface ConfigurationLayoutState {
    hideAnyAuthenticationProvidersSupportPasswordManagement: boolean;
    telemetryConfiguration: TelemetryConfigurationResource | undefined;
}
interface ConfigurationLayoutProps {
    children: (layout: React.ComponentType<Level2PageLayoutProps>) => React.ReactNode;
}
export class ConfigurationLayout extends DataBaseComponent<ConfigurationLayoutProps, ConfigurationLayoutState> {
    constructor(props: ConfigurationLayoutProps) {
        super(props);
        this.state = {
            // flipped to 'hide' so it'll just disappear in the case where it's not supported
            // instead of showing up after the data fetch, and without delaying display of whole menu for data load
            hideAnyAuthenticationProvidersSupportPasswordManagement: false,
            telemetryConfiguration: undefined,
        };
    }
    componentDidMount() {
        this.doBusyTask(async () => {
            const telemetryConfiguration = isAllowed({ permission: Permission.AdministerSystem }) ? repository.TelemetryConfiguration.get() : Promise.resolve(undefined);
            const document = await repository.Authentication.get();
            this.setState({
                hideAnyAuthenticationProvidersSupportPasswordManagement: !document.AnyAuthenticationProvidersSupportPasswordManagement,
                telemetryConfiguration: await telemetryConfiguration,
            });
        });
    }
    render() {
        //TODO: Busy state and errors aren't currently being observed for the top level layout at the moment.
        return (<ConfigurationLayoutInternal telemetryConfiguration={this.state.telemetryConfiguration} hideAnyAuthenticationProvidersSupportPasswordManagement={this.state.hideAnyAuthenticationProvidersSupportPasswordManagement}>
                {this.props.children}
            </ConfigurationLayoutInternal>);
    }
    static displayName = "ConfigurationLayout";
}
interface ConfigurationLayoutInternalProps {
    children: (layout: React.ComponentType<Level2PageLayoutProps>) => React.ReactNode;
    hideAnyAuthenticationProvidersSupportPasswordManagement: boolean;
    telemetryConfiguration: TelemetryConfigurationResource | undefined;
}
function ConfigurationLayoutInternal({ children, telemetryConfiguration, hideAnyAuthenticationProvidersSupportPasswordManagement }: ConfigurationLayoutInternalProps) {
    const navItems = getConfigurationNavLinks(telemetryConfiguration, hideAnyAuthenticationProvidersSupportPasswordManagement);
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    if (isPageHeaderVNextEnabledOutsideOfProjects) {
        return (<main>
                <NavigationSideBarLayoutVNext navItems={navItems} content={children(PaperLayout)} header={<PageHeaderPrimary title={"Configuration"}/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={links.configurationRootRedirect.generateUrl()} title="Configuration"/>
            <NavigationSidebarLayout navLinks={navItems} content={children(PaperLayout)}/>
        </main>);
}
function getConfigurationNavLinks(telemetryConfiguration: TelemetryConfigurationResource | undefined, hideAnyAuthenticationProvidersSupportPasswordManagement: boolean) {
    const showAsNew = telemetryConfiguration?.ShowAsNewUntil ? moment(telemetryConfiguration.ShowAsNewUntil).isAfter(moment()) : false;
    const telemetryHasBeenIntroduced = localStorage.getItem(TelemetryHasBeenIntroducedLocalStorageKey) === "true";
    return compact([
        Navigation.navItem("Audit", links.auditPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true }),
        Navigation.navItem("Backup", links.backupPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        Navigation.navItem("Diagnostics", links.diagnosticsPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        Navigation.navItem("Features", links.featuresPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
        Navigation.navItem("Git", links.gitPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
        client.getSystemLink((link) => link.LetsEncryptConfiguration) ? Navigation.navItem("Let's Encrypt", links.letsEncryptPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }) : null,
        Navigation.navItem("License", links.licensePage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        Navigation.navItem("Maintenance", links.maintenancePage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        Navigation.navItem("Nodes", links.nodesPage.generateUrl(), undefined, { permission: Permission.AdministerSystem }),
        Navigation.navItem("Performance", links.performancePage.generateUrl(), undefined, { permission: Permission.ConfigureServer }),
        Navigation.navItem("Settings", links.settingsListPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
        isFeatureToggleEnabled("DeploymentFreezeFeatureToggle") ? Navigation.navItem("Deployment Freezes", links.deploymentFreezesListPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem] }) : undefined,
        Navigation.navItem("Extensions", links.extensionsPage.generateUrl(), undefined, { permission: [Permission.ConfigureServer] }),
        Navigation.navItem("SMTP", links.smtpPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.ConfigureServer] }),
        Navigation.navItem("Subscriptions", links.subscriptionsPage.generateUrl(), undefined, { permission: [Permission.SubscriptionView] }),
        session.currentPermissions!.scopeToSpace(repository.spaceId).hasAnyPermissions()
            ? Navigation.navItem("Spaces", links.spacesPage.generateUrl(), undefined)
            : Navigation.navItem("Spaces", links.spacesPage.generateUrl(), undefined, { permission: [Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete] }),
        Navigation.navItem("Teams", links.teamsPage.generateUrl(), undefined, { permission: [Permission.TeamEdit, Permission.TeamView] }),
        Navigation.navItem(<>Telemetry {telemetryHasBeenIntroduced ? "" : showAsNew && <NewFeatureChip />}</>, links.telemetryPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem] }),
        Navigation.navItem("Test Permissions", links.testPermissionsPage.generateUrl(), undefined, { permission: [Permission.TeamEdit, Permission.UserView] }),
        Navigation.navItem("Thumbprint", links.thumbprintPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true }),
        Navigation.navItem("Signing Keys", links.signingKeysPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem] }),
        Navigation.navItem("Users", links.usersPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.UserView] }),
        !hideAnyAuthenticationProvidersSupportPasswordManagement && isAllowed({ permission: Permission.UserInvite })
            ? Navigation.navItem("User Invites", links.userInvitesPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.UserInvite] })
            : undefined,
        Navigation.navItem("User Roles", links.userRolesPage.generateUrl(), undefined, { permission: [Permission.AdministerSystem, Permission.UserRoleView] }),
    ]);
}
export default ConfigurationLayout;
