import { css } from "@emotion/css";
//eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import type { Theme } from "@material-ui/core";
//eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { ClickAwayListener, createStyles, Fade, InputAdornment, makeStyles } from "@material-ui/core";
//eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@octopusdeploy/design-system-components";
import { borderRadius, colorScales, space } from "@octopusdeploy/design-system-tokens";
import MobileDetect from "mobile-detect";
import React, { useCallback, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import type { DataBaseComponentState, DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { useOctopusTheme } from "~/components/Theme";
import type { ReasonForActivation } from "~/globalSearch/analytics/useNotifyGlobalSearchActivated";
import { useNotifyGlobalSearchActivated } from "~/globalSearch/analytics/useNotifyGlobalSearchActivated";
import SimplePopper from "~/primitiveComponents/dataDisplay/Popper/SimplePopper";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import GlobalSearch from "./GlobalSearch";
import type { GlobalSearchRef } from "./GlobalSearch";
import styles from "./SearchAndOpenPopover.module.less";
const keycode = require("keycode");
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SearchAndOpenPopoverState extends DataBaseComponentState {
}
// We're just using a class component to inject our doBusyTask. When we have functional equivlanets, this can be removed.
export class SearchAndOpenPopover extends DataBaseComponent<{}, SearchAndOpenPopoverState> {
    render() {
        return <SearchAndOpenPopoverInternal doBusyTask={this.doBusyTask} busy={this.state?.busy} errors={this.errors}/>;
    }
    static displayName = "SearchAndOpenPopover";
}
interface SearchAndOpenPopoverProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
}
interface SearchAndOpenPopoverState {
    keyword: string;
    isPopoverOpen: boolean;
}
const SearchAndOpenPopoverInternal: React.FC<SearchAndOpenPopoverProps> = ({ doBusyTask, busy, errors }) => {
    const notifyGlobalSearchActivated = useNotifyGlobalSearchActivated();
    const firstMenuItem = useRef<GlobalSearchRef | null>(null);
    const octopusTheme = useOctopusTheme();
    const [popoverState, setPopoverState] = React.useState<SearchAndOpenPopoverState>({ keyword: "", isPopoverOpen: false });
    const searchInputRef = useRef<TextInput | null>(null);
    const searchAnchorElement = useRef<HTMLDivElement | null>(null);
    const handleOpen = (e: React.MouseEvent | null, reason: ReasonForActivation | "Forced") => {
        if (reason !== "Forced") {
            notifyGlobalSearchActivated({ reasonForActivation: reason });
        }
        e?.preventDefault();
        setPopoverState((prevState) => ({ ...prevState, isPopoverOpen: true }));
        // Need to scroll to top for mobile, or the results stay at the top of the container.
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.isPhoneSized()) {
            window.scroll({ top: 0, left: 0 });
        }
    };
    const handleClose = useCallback(() => {
        setPopoverState((prevState) => ({ ...prevState, isPopoverOpen: false, keyword: "" }));
    }, [setPopoverState]);
    useHotkeys("ctrl+space", () => {
        handleOpen(null, "HotKey");
    }, {
        enableOnFormTags: ["INPUT", "SELECT", "TEXTAREA"],
    }, []);
    React.useEffect(() => {
        if (popoverState.isPopoverOpen) {
            searchInputRef?.current?.focus();
        }
        else {
            searchInputRef?.current?.blur();
        }
    }, [popoverState.isPopoverOpen, searchInputRef]);
    const stylesForSearchInput = useStylesForSearchAndPopover();
    const onKeyDownInSearchInput = useCallback((event: React.KeyboardEvent<{}>) => {
        if (keycode(event) === "down") {
            firstMenuItem.current?.focusFirstItem();
            // Prevent the down array from scrolling the list container
            event.preventDefault();
        }
    }, []);
    const onKeyDownAnywhere = useCallback((event: React.KeyboardEvent<{}>) => {
        if (keycode(event) === "esc") {
            handleClose();
        }
    }, [handleClose]);
    return (<div className={styles.root} onKeyDown={onKeyDownAnywhere}>
            <ClickAwayListener onClickAway={handleClose}>
                <div className={styles.contentContainer}>
                    <div className={styles.searchControls} ref={searchAnchorElement} onClick={() => {
            searchInputRef?.current?.focus();
        }}>
                        <div className={styles.searchContainer}>
                            <DebounceText placeholder="Search..." autoComplete={"off"} inputProps={{
            "aria-label": "Search",
            startAdornment: (<InputAdornment position="end">
                                            <SearchIcon htmlColor={octopusTheme.iconNeutral} className={stylesForSearchInput.searchIcon}/>
                                        </InputAdornment>),
            endAdornment: popoverState.keyword && (<IconButton className={stylesForSearchInput.clearSearchButton} onClick={() => {
                    // Refocus when they cancel/clear text to make typing your next search easy.
                    searchInputRef?.current?.focus();
                    setPopoverState((prevState) => ({ ...prevState, keyword: "" }));
                }} icon="Cancel"/>),
            classes: stylesForSearchInput.input,
            disableUnderline: true,
        }} value={popoverState.keyword ?? ""} 
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange={(e: any) => {
            setPopoverState((prevState) => ({ ...prevState, keyword: e }));
            if (!popoverState.isPopoverOpen) {
                handleOpen(null, "Forced");
            }
        }} onFocus={(e) => {
            if (!popoverState.isPopoverOpen) {
                handleOpen(null, "Focused");
            }
            // Automatically select all if the user re-opens the search with an existing keyword (UX nicety).
            if (popoverState.keyword) {
                searchInputRef?.current?.select();
            }
        }} textInputRef={(inputElement) => {
            searchInputRef.current = inputElement;
        }} onKeyDown={onKeyDownInSearchInput} margin="dense"/>
                        </div>
                    </div>
                    <SimplePopper id={popoverState.isPopoverOpen ? "transitions-popper" : undefined} placement="bottom-end" anchorEl={searchAnchorElement.current} open={popoverState.isPopoverOpen} className={styles.popper} disablePortal={false} transition={true}>
                        <Fade in={popoverState.isPopoverOpen} mountOnEnter unmountOnExit>
                            <div>
                                <GlobalSearch keyword={popoverState.keyword.trim()} doBusyTask={doBusyTask} busy={busy} errors={errors} handleClose={handleClose} ref={firstMenuItem}/>
                            </div>
                        </Fade>
                    </SimplePopper>
                </div>
            </ClickAwayListener>
        </div>);
};
SearchAndOpenPopoverInternal.displayName = "SearchAndOpenPopoverInternal"
const useStylesForSearchAndPopover = () => {
    return {
        input: {
            root: searchInputRootUpliftStyles,
            focused: searchInputFocusUpliftStyles,
            input: searchInputUpliftStyles,
        },
        searchIcon: searchIconUpliftStyles,
        clearSearchButton: clearSearchButtonUpliftStyles,
    };
};
const searchInputRootUpliftStyles = css({
    height: 40,
    maxWidth: 244,
    padding: `0 ${space[8]}`,
    borderRadius: borderRadius.medium,
    backgroundColor: colorScales.navy[800],
    ":hover": {
        backgroundColor: colorScales.navy[700],
    },
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color",
    transitionDuration: "0.2s",
});
const searchInputFocusUpliftStyles = css({
    backgroundColor: colorScales.navy[700],
});
const searchInputUpliftStyles = css({
    "&.MuiInputBase-input": {
        padding: `0 ${space[8]}`,
        color: colorScales.navy[100],
        "&::placeholder": {
            color: colorScales.navy[100],
            opacity: 1,
        },
    },
});
const searchIconUpliftStyles = css({
    color: colorScales.navy[400],
});
const clearSearchButtonUpliftStyles = css({
    "--iconNeutral": colorScales.navy[400],
});
const commonStylesForSearchRoot = (theme: Theme) => {
    return {
        overflow: "hidden",
        borderRadius: 4,
        padding: "0.3rem 1rem 0.3rem 0.25rem",
        width: "220px",
        [theme.breakpoints.down("sm")]: {
            width: "180px",
        },
    };
};
const commonStylesForInput = () => {
    return { padding: "0 0.25rem" };
};
const useStylesForSearchDark = makeStyles((theme: Theme) => createStyles({
    root: {
        ...commonStylesForSearchRoot(theme),
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        "&$focused": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        color: "white",
    },
    focused: {},
    input: { ...commonStylesForInput() },
}));
const useStylesForSearchRegular = makeStyles((theme: Theme) => createStyles({
    root: {
        ...commonStylesForSearchRoot(theme),
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        "&$focused": {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
        },
        color: "black",
    },
    focused: {},
    input: { ...commonStylesForInput() },
}));
export default SearchAndOpenPopover;
