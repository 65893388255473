import { AccountType } from "@octopusdeploy/octopus-server-client";
import type { EndpointRegistrationKey } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import URI from "urijs";
import { AccountPage } from "~/areas/infrastructure/components/AccountEdit/AccountPage";
import { AccountsPage } from "~/areas/infrastructure/components/AccountsLayout/AccountsPage";
import { DeploymentTargetSettingsPage } from "~/areas/infrastructure/components/DeploymentTargetSettings/DeploymentTargetSettingsPage";
import { DynamicEnvironmentPage } from "~/areas/infrastructure/components/DynamicEnvironmentLayout/DynamicEnvironmentPage";
import { DynamicEnvironmentsPage } from "~/areas/infrastructure/components/DynamicEnvironmentsLayout/DynamicEnvironmentsPage";
import { EnvironmentPage } from "~/areas/infrastructure/components/EnvironmentLayout/EnvironmentPage";
import { EnvironmentsPage } from "~/areas/infrastructure/components/EnvironmentsLayout/EnvironmentsPage";
import { MachineDeploymentsPage } from "~/areas/infrastructure/components/MachineDeployments/MachineDeploymentsPage";
import { MachineEventsPage } from "~/areas/infrastructure/components/MachineEvents/MachineEventsPage";
import { MachinePoliciesPage } from "~/areas/infrastructure/components/MachinePoliciesLayout/MachinePoliciesPage";
import { MachinePolicyPage } from "~/areas/infrastructure/components/MachinePolicyLayout/MachinePolicyPage";
import { MachineRunbookRunsPage } from "~/areas/infrastructure/components/MachineRunbookRuns/MachineRunbookRunsPage";
import { MachineTargetNewPage } from "~/areas/infrastructure/components/MachineSettings/MachineTargetNewPage";
import { DeploymentTargetsPage } from "~/areas/infrastructure/components/MachinesLayout/DeploymentTargetsPage";
import { WorkerMachinesPage } from "~/areas/infrastructure/components/MachinesLayout/WorkerMachinesPage";
import { ProxiesPage } from "~/areas/infrastructure/components/ProxiesLayout/ProxiesPage";
import { ProxyPage } from "~/areas/infrastructure/components/ProxyLayout/ProxyPage";
import { WorkerMachineNewPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineNewPage";
import { WorkerMachineSettingsPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineSettingsPage";
import { WorkerPoolPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolLayout/WorkerPoolPage";
import { WorkerPoolsPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolsLayout/WorkerPoolsPage";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import InfrastructureDashboard from "../InfrastructureDashboard";
import InfrastructureLayout from "../InfrastructureLayout";
import { DeploymentTargetConnectionsPage, WorkerMachineConnectionsPage } from "../MachineConnection/MachineConnection";
import { DeploymentTargetDiscoveryPage, WorkerMachineDiscoveryPage } from "../MachineDiscovery/MachineDiscovery";
import { MachineLayout, WorkerMachineLayout } from "../MachineLayout/MachineLayout";
const machineDiscoveryWithLayout = renderWithLayout(InfrastructureLayout)(DeploymentTargetDiscoveryPage);
const machineSettingsWithLayout = renderWithLayout(MachineLayout)(DeploymentTargetSettingsPage);
const machineConnectionWithLayout = renderWithLayout(MachineLayout)(DeploymentTargetConnectionsPage);
const machineDeploymentsWithLayout = renderWithLayout(MachineLayout)(MachineDeploymentsPage);
const machineRunbookRunsWithLayout = renderWithLayout(MachineLayout)(MachineRunbookRunsPage);
const machineEventsWithLayout = renderWithLayout(MachineLayout)(MachineEventsPage);
const machineNewWithLayout = renderWithLayout(InfrastructureLayout)(MachineTargetNewPage);
const machineCreateWithLayout = renderWithLayout(InfrastructureLayout)(DeploymentTargetSettingsPage);
const workerMachineDiscoveryWithLayout = renderWithLayout(InfrastructureLayout)(WorkerMachineDiscoveryPage);
const workerMachineSettingsWithLayout = renderWithLayout(WorkerMachineLayout)(WorkerMachineSettingsPage);
const workerMachineConnectionWithLayout = renderWithLayout(WorkerMachineLayout)(WorkerMachineConnectionsPage);
const workerMachineEventsWithLayout = renderWithLayout(WorkerMachineLayout)(MachineEventsPage);
const workerMachineNewWithLayout = renderWithLayout(InfrastructureLayout)(WorkerMachineNewPage);
const workerMachineCreateWithLayout = renderWithLayout(InfrastructureLayout)(WorkerMachineSettingsPage);
const InfrastructureDashboardPage = withPage({ page: pageIds.infrastructure.overview })(InfrastructureDashboard);
const EnvironmentPageWithPageDefinition = withPage({ page: pageIds.infrastructure.environment })(EnvironmentPage);
const EnvironmentsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.environments.root })(EnvironmentsPage);
const DynamicEnvironmentPageWithPageDefinition = withPage({ page: pageIds.infrastructure.dynamicEnvironment })(DynamicEnvironmentPage);
const DynamicEnvironmentsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.dynamicEnvironments.root })(DynamicEnvironmentsPage);
const NewWorkerMachinePageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachines.select })(workerMachineNewWithLayout);
const DiscoverWorkerMachinePageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachines.discover })(workerMachineDiscoveryWithLayout);
const CreateWorkerMachinePageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachines.create })(workerMachineCreateWithLayout);
const WorkerSettingsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachine.settings })(workerMachineSettingsWithLayout);
const WorkerConnectionPageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachine.connection })(workerMachineConnectionWithLayout);
const WorkerEventsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachine.events })(workerMachineEventsWithLayout);
const WorkersPageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerMachines.root })(WorkerMachinesPage);
const EditWorkerPoolPageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerPool })(WorkerPoolPage);
const WorkerPoolsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.workerPools.root })(WorkerPoolsPage);
const NewMachinePageWithPageDefinition = withPage({ page: pageIds.infrastructure.machines.select })(machineNewWithLayout);
const DiscoverMachinePageWithPageDefinition = withPage({ page: pageIds.infrastructure.machines.discover })(machineDiscoveryWithLayout);
const CreateMachinePageWithPageDefinition = withPage({ page: pageIds.infrastructure.machines.create })(machineCreateWithLayout);
const MachineSettingsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machine.settings })(machineSettingsWithLayout);
const MachineConnectionPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machine.connection })(machineConnectionWithLayout);
const MachineDeploymentsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machine.deployments })(machineDeploymentsWithLayout);
const MachineRunbookRunsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machine.runbookRuns })(machineRunbookRunsWithLayout);
const MachineEventsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machine.events })(machineEventsWithLayout);
const DeploymentTargetsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machines.root })(DeploymentTargetsPage);
const CreateProxyPageWithPageDefinition = withPage({ page: pageIds.infrastructure.proxies.create })(ProxyPage);
const EditProxyPageWithPageDefinition = withPage({ page: pageIds.infrastructure.proxy })(ProxyPage);
const ProxiesPageWithPageDefinition = withPage({ page: pageIds.infrastructure.proxies.root })(ProxiesPage);
const CreateMachinePolicyPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machinePolicies.create })(MachinePolicyPage);
const EditMachinePolicyPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machinePolicy })(MachinePolicyPage);
const MachinePoliciesPageWithPageDefinition = withPage({ page: pageIds.infrastructure.machinePolicies.root })(MachinePoliciesPage);
const CreateAccountPageWithPageDefinition = withPage({ page: pageIds.infrastructure.accounts.create })(AccountPage);
const EditAccountPageWithPageDefinition = withPage({ page: pageIds.infrastructure.account })(AccountPage);
const AccountsPageWithPageDefinition = withPage({ page: pageIds.infrastructure.accounts.root })(AccountsPage);
function InfrastructureRoutesInternal() {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    return (<Switch>
            <MigratedReloadableRoute path={routeLinks.infrastructure.overview} render={(props: RouteComponentProps<{
        spaceId: string;
    }>) => <InfrastructureDashboardPage spaceId={props.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.environment(":environmentId")} render={(routeProps: RouteComponentProps<{
        environmentId: string;
    }>) => <EnvironmentPageWithPageDefinition environmentId={routeProps.match.params.environmentId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.environments.root} exact render={(props: RouteComponentProps<{
        spaceId: string;
    }>) => <EnvironmentsPageWithPageDefinition spaceId={props.match.params.spaceId}/>}/>
            {dynamicEnvironmentsEnabled && (<MigratedReloadableRoute path={routeLinks.infrastructure.dynamicEnvironment(":environmentId").overview} exact render={(routeProps: RouteComponentProps<{
            spaceId: string;
            environmentId: string;
        }>) => <DynamicEnvironmentPageWithPageDefinition spaceId={routeProps.match.params.spaceId} environmentId={routeProps.match.params.environmentId}/>}/>)}
            {dynamicEnvironmentsEnabled && (<MigratedReloadableRoute path={routeLinks.infrastructure.dynamicEnvironments.root()} render={(routeProps: RouteComponentProps<{
            spaceId: string;
        }>) => <DynamicEnvironmentsPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>)}
            <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachines.root}>
                <Switch>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachines.new(":workerPoolId?")} render={(routeProps: RouteComponentProps<{
        spaceId: string;
        workerPoolId: string;
    }>) => <NewWorkerMachinePageWithPageDefinition spaceId={routeProps.match.params.spaceId} workerPoolId={routeProps.match.params.workerPoolId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachines.discover(":endpointKey", ":workerPoolId?")} render={(routeProps: RouteComponentProps<{
            spaceId: string;
            endpointKey: EndpointRegistrationKey;
            workerPoolId: string;
        }>) => (<DiscoverWorkerMachinePageWithPageDefinition spaceId={routeProps.match.params.spaceId} endpointKey={routeProps.match.params.endpointKey} workerPoolId={routeProps.match.params.workerPoolId}/>)}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachines.create()} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <CreateWorkerMachinePageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachine(":machineId").settings} render={(routeProps: RouteComponentProps<{
        spaceId: string;
        machineId: string;
    }>) => <WorkerSettingsPageWithPageDefinition spaceId={routeProps.match.params.spaceId} machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachine(":machineId").connection} render={(routeProps: RouteComponentProps<{
        machineId: string;
    }>) => <WorkerConnectionPageWithPageDefinition machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.workerMachine(":machineId").events} render={(routeProps: RouteComponentProps<{
        machineId: string;
    }>) => <WorkerEventsPageWithPageDefinition machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute exact={true} path={routeLinks.infrastructure.workerMachine(":machineId").root} render={(props: RouteComponentProps<{
        machineId: string;
    }>) => <InternalRedirect to={routeLinks.infrastructure.workerMachine(props.match.params.machineId).settings}/>}/>
                    <MigratedReloadableRoute exact={true} path={routeLinks.infrastructure.workerMachines.root} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <WorkersPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                    <RedirectAs404 />
                </Switch>
            </MigratedReloadableRoute>
            <MigratedReloadableRoute path={routeLinks.infrastructure.workerPool(":workerPoolId")} render={(routeProps: RouteComponentProps<{
        workerPoolId: string;
    }>) => <EditWorkerPoolPageWithPageDefinition workerPoolId={routeProps.match.params.workerPoolId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.workerPools.root} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <WorkerPoolsPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.machines.root}>
                <Switch>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.machines.new(":environmentId?")} render={(routeProps: RouteComponentProps<{
        environmentId?: string;
        spaceId: string;
    }>) => <NewMachinePageWithPageDefinition environmentId={routeProps.match.params.environmentId} spaceId={routeProps.match.params.spaceId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.machines.discover(":endpointKey", ":environmentId?")} render={(routeProps: RouteComponentProps<{
            spaceId: string;
            endpointKey: EndpointRegistrationKey;
            environmentId: string;
        }>) => {
            return <DiscoverMachinePageWithPageDefinition spaceId={routeProps.match.params.spaceId} endpointKey={routeProps.match.params.endpointKey} environmentId={routeProps.match.params.environmentId}/>;
        }}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.machines.create()} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <CreateMachinePageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.machine(":machineId").settings} render={(routeProps: RouteComponentProps<{
        spaceId: string;
        machineId: string;
    }>) => <MachineSettingsPageWithPageDefinition spaceId={routeProps.match.params.spaceId} machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.machine(":machineId").connection} render={(routeProps: RouteComponentProps<{
        machineId: string;
    }>) => <MachineConnectionPageWithPageDefinition machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.deploymentTarget(":machineId").deployments} render={(routeProps: RouteComponentProps<{
        machineId: string;
    }>) => <MachineDeploymentsPageWithPageDefinition machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.deploymentTarget(":machineId").runbookRuns} render={(routeProps: RouteComponentProps<{
        machineId: string;
    }>) => <MachineRunbookRunsPageWithPageDefinition machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.machine(":machineId").events} render={(routeProps: RouteComponentProps<{
        machineId: string;
    }>) => <MachineEventsPageWithPageDefinition machineId={routeProps.match.params.machineId}/>}/>
                    <MigratedReloadableRoute exact={true} path={routeLinks.infrastructure.machine(":machineId").root} render={(props: RouteComponentProps<{
        machineId: string;
    }>) => <InternalRedirect to={routeLinks.infrastructure.machine(props.match.params.machineId).settings}/>}/>
                    <MigratedReloadableRoute exact={true} path={routeLinks.infrastructure.machines.root} render={(routeProps) => <DeploymentTargetsPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                    <RedirectAs404 />
                </Switch>
            </MigratedReloadableRoute>
            <MigratedReloadableRoute path={routeLinks.infrastructure.proxies.create} render={() => <CreateProxyPageWithPageDefinition create={true}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.proxy(":proxyId")} render={(routeProps: RouteComponentProps<{
        proxyId: string;
    }>) => <EditProxyPageWithPageDefinition proxyId={routeProps.match.params.proxyId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.proxies.root} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <ProxiesPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.machinePolicies.create} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <CreateMachinePolicyPageWithPageDefinition create={true} spaceId={routeProps.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.machinePolicy(":machinePolicyId")} render={(routeProps: RouteComponentProps<{
            spaceId: string;
            machinePolicyId: string;
        }>) => (<EditMachinePolicyPageWithPageDefinition spaceId={routeProps.match.params.spaceId} machinePolicyId={routeProps.match.params.machinePolicyId}/>)}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.machinePolicies.root} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <MachinePoliciesPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={routeLinks.infrastructure.accounts.root}>
                <Switch>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.accounts.create()} render={(routeProps: RouteComponentProps) => {
            const uri = URI(routeProps.location.search);
            const query = uri.search(true);
            const accountType: keyof typeof AccountType = query.accountType;
            return <CreateAccountPageWithPageDefinition createNew={true} accountType={AccountType[accountType]}/>;
        }}/>
                    <MigratedReloadableRoute path={routeLinks.infrastructure.account(":accountId")} render={(routeProps: RouteComponentProps<{
        accountId: string;
    }>) => <EditAccountPageWithPageDefinition createNew={false} accountId={routeProps.match.params.accountId}/>}/>
                    <MigratedReloadableRoute render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <AccountsPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                </Switch>
            </MigratedReloadableRoute>
            <MigratedReloadableRoute exact={true} path={routeLinks.infrastructure.root} render={() => <InternalRedirect to={routeLinks.infrastructure.overview}/>}/>
            <RedirectAs404 />
        </Switch>);
}
export class InfrastructureRoutes extends React.Component<RouteComponentProps> {
    render() {
        return <InfrastructureRoutesInternal />;
    }
    static displayName = "InfrastructureRoutes";
}
export default InfrastructureRoutes;
