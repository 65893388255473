/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import cardStyles from "./styles.module.less";
interface ActionTemplateCardListProps {
    children: any;
}
const ActionTemplateCardList: React.StatelessComponent<ActionTemplateCardListProps> = (props: ActionTemplateCardListProps) => {
    // Used to toggle step tile and legacy template styles
    const isImproveKubernetesStepSelectionFeatureEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
    return <ol className={isImproveKubernetesStepSelectionFeatureEnabled ? stepTileStyles : cardStyles.templates}>{props.children}</ol>;
};
ActionTemplateCardList.displayName = "ActionTemplateCardList"
export default ActionTemplateCardList;
const stepTileStyles = css({
    display: "grid",
    padding: "0 " + space["16"],
    gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
    gap: space["24"],
    ["@media (min-width: 600px)"]: {
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 348px))",
    },
});
