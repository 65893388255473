import { css, cx, keyframes } from "@emotion/css";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { useMediaQuery } from "react-responsive";
import type { Errors } from "../DataBaseComponent";
import { ErrorPanel } from "../form";
interface SkeletonLoadingLayoutProps {
    errors: Errors | undefined;
}
export function SkeletonLoadingLayout({ errors }: SkeletonLoadingLayoutProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    if (errors) {
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink}/>;
    }
    return (<header className={cx(headerPrimaryStyles, { [headerPrimaryMobileStyles]: !isLargerThanIpad })}>
            <div className={breadcrumbPlaceholderStyles}/>
            <div className={cx(headerContentStyles, { [headerContentMobileStyles]: !isLargerThanIpad })}>
                <div className={titlePlaceholderStyles}/>
                <div className={pageActionsPlaceholderStyles}/>
            </div>
        </header>);
}
const pulseKeyframes = keyframes({
    "0%": { opacity: 1 },
    "50%": { opacity: 0.24 },
    "100%": { opacity: 1 },
});
const skeletonStyles = css({
    // This animation has a 0.5s delay sa that a quick chain of redirects doesn't show a constantly resetting animation
    animation: `${pulseKeyframes} 2s linear 0.5s infinite`,
    borderRadius: borderRadius.medium,
    backgroundColor: themeTokens.color.background.subtle,
});
const headerPrimaryStyles = css({
    display: "flex",
    flexDirection: "column",
    padding: `${space["24"]} ${space["32"]} ${space["16"]} ${space["32"]}`,
    gap: space["8"],
});
const breadcrumbPlaceholderStyles = css(skeletonStyles, {
    height: "1.25rem",
    width: "3.25rem",
});
const titlePlaceholderStyles = css(skeletonStyles, {
    height: "2.25rem",
    width: "14.25rem",
});
const pageActionsPlaceholderStyles = css(skeletonStyles, {
    height: "2.25rem",
    width: "8rem",
});
const headerPrimaryMobileStyles = css({
    padding: `${space["24"]} ${space["16"]} ${space["12"]}`,
});
const headerContentStyles = css({
    display: "flex",
    justifyContent: "space-between",
    gap: space["48"],
});
const headerContentMobileStyles = css({
    flexDirection: "column",
    gap: space["24"],
    alignItems: "unset",
});
