/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, ProjectSummaryResource, PermissionDescriptions, Permissions, ProjectedTeamReferenceDataItem, ProjectGroupResource, SpaceResource, TenantResource, UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { keyBy } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { SimplePagingList } from "~/components/PagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed, isAllowedForSpace } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import Select from "~/primitiveComponents/form/Select/Select";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import ProjectedTeamsList, { ProjectedTeamListItem } from "../ProjectedTeamsList/ProjectedTeamsList";
import { PermissionTable } from "./PermissionTable";
interface TestPermissionPageState extends DataBaseComponentState {
    selectedUserId?: string;
    selectedUser?: UserResource;
    spacesAccessibleBySelectedUser?: SpaceResource[];
    projectedTeamReferenceDataItems?: ProjectedTeamReferenceDataItem[];
    spacePermissions?: Permissions;
    systemPermissions?: PermissionListItem[];
    isTeamsComplete?: boolean;
    isPermissionsComplete?: boolean;
    exportUrl?: string;
    redirectTo?: LinkHref;
    users?: UserResource[];
    projectSummaries?: {
        [ID: string]: ProjectSummaryResource;
    };
    environments?: {
        [ID: string]: EnvironmentResource;
    };
    tenants?: {
        [ID: string]: TenantResource;
    };
    permissionDescriptions?: PermissionDescriptions;
    projectGroups?: {
        [ID: string]: ProjectGroupResource;
    };
    permissionContext: PermissionContext;
    selectedSpaceId?: string;
}
export interface TestPermissionsPageProps {
    userId: string | undefined;
}
interface PermissionListItem {
    Id: string;
    Name: string;
}
enum PermissionContext {
    System = "System",
    Space = "Space"
}
class SystemPermissionList extends SimplePagingList<PermissionListItem> {
}
export class TestPermissionsPage extends DataBaseComponent<TestPermissionsPageProps, TestPermissionPageState> {
    constructor(props: TestPermissionsPageProps) {
        super(props);
        this.state = {
            permissionContext: PermissionContext.Space,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const selectedUserId = this.props.userId;
            const users = repository.Users.all();
            this.setState({
                users: await users,
                selectedUserId,
                permissionDescriptions: await repository.PermissionDescriptions.all(),
                ...(selectedUserId ? await this.loadUserContext(selectedUserId, await users) : {}),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async loadUserContext(selectedUserId: string, users: UserResource[]) {
        const selectedUserViaIdOrUserName = users.find((u) => u.Id === selectedUserId || u.Username === selectedUserId);
        if (!selectedUserViaIdOrUserName) {
            return {};
        }
        const selectedUser = await repository.Users.get(selectedUserViaIdOrUserName.Id);
        if (!selectedUser) {
            return {};
        }
        const spacesAccessibleBySelectedUser = await repository.Users.getSpaces(selectedUser);
        const systemLevelRepository = repository.forSystem();
        const userPermissions = await systemLevelRepository.UserPermissions.getPermissionsConfigurationForAllParitions(selectedUser, true);
        const selectedSpaceId = spacesAccessibleBySelectedUser.length === 1 ? spacesAccessibleBySelectedUser[0].Id : null;
        const newState = selectedSpaceId ? await this.selectedSpaceChanged(selectedSpaceId, selectedUser) : {};
        return {
            ...newState,
            selectedUser,
            selectedUserId,
            spacesAccessibleBySelectedUser,
            exportUrl: repository.resolve(userPermissions.Links["Export"]),
            selectedSpaceId,
        };
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const exportPageAction: PrimaryPageAction | undefined = this.state.exportUrl
            ? {
                type: "navigate",
                label: "Export User Permissions",
                external: true,
                path: this.state.exportUrl,
            }
            : undefined;
        return (<PaperLayout title="Test Permissions" busy={this.state.busy} errors={this.errors} primaryAction={exportPageAction}>
                {this.state.users && (<Section sectionHeader="User">
                        <Select label="Select a user" allowFilter={true} autoFocus={true} items={this.state.users.map((u) => ({ value: u.Id, text: `${u.Username} (${u.DisplayName})` }))} onChange={this.userChanged} value={this.state.selectedUserId}/>
                    </Section>)}
                {this.state.selectedUser && (<Section sectionHeader="Permissions context">
                        <RadioButtonGroup accessibleName={"Permissions Context"} value={this.state.permissionContext} onChange={(permissionContext) => this.permissionContextChanged(permissionContext)}>
                            <RadioButton value={PermissionContext.System} label="Show system permissions"/>
                            <RadioButton value={PermissionContext.Space} label="Show permissions within a specific space"/>
                        </RadioButtonGroup>

                        {this.state.permissionContext === PermissionContext.Space && (<Select label="Select a space" allowFilter={true} autoFocus={true} items={this.state.spacesAccessibleBySelectedUser!.map((s) => ({ value: s.Id, text: `${s.Name} Space` }))} onChange={(spaceId) => this.doBusyTask(async () => this.setState(await this.selectedSpaceChanged(spaceId!, this.state.selectedUser!)))} value={this.state.selectedSpaceId}/>)}
                    </Section>)}
                {this.state.projectedTeamReferenceDataItems && (<Section sectionHeader="Teams">
                        The user <InternalLink to={links.editUserPage.generateUrl({ userId: this.state.selectedUser!.Id })}>{this.state.selectedUser!.Username} </InternalLink>
                        is a member of the following teams:
                        {this.renderIncompleteTeamsInfo()}
                        <ProjectedTeamsList items={this.state.projectedTeamReferenceDataItems} onRowRedirectUrl={(teamReference) => this.getRoot(teamReference.SpaceId!).configuration.team(teamReference.Id)} onRow={(team) => <ProjectedTeamListItem projectedTeam={team} spaceResource={this.state.spacesAccessibleBySelectedUser?.find((x) => team.SpaceId && x.Id === team.SpaceId)}/>}/>
                    </Section>)}
                {this.state.spacePermissions && this.state.permissionContext === PermissionContext.Space && (<Section sectionHeader={Object.keys(this.state.spacePermissions).length === 0 ? "No permissions" : "Permissions"}>
                        {this.renderIncompletePermissionsInfo()}
                        <p>
                            <InternalLink to={links.editUserPage.generateUrl({ userId: this.state.selectedUser!.Id })}>{this.state.selectedUser!.Username || this.state.selectedUser!.DisplayName}'s </InternalLink>
                            permissions are restricted to:
                        </p>
                        <PermissionTable showRestrictions={this.state.permissionContext === PermissionContext.Space} permissions={this.state.spacePermissions} permissionDescriptions={this.state.permissionDescriptions!} projectSummaries={this.state.projectSummaries!} environments={this.state.environments!} tenants={this.state.tenants!} projectGroups={this.state.projectGroups!}/>
                    </Section>)}
                {this.state.systemPermissions && this.state.permissionContext === PermissionContext.System && (<Section sectionHeader={this.state.systemPermissions.length === 0 ? "No permissions" : "Permissions"}>
                        {this.renderIncompletePermissionsInfo()}
                        {this.state.systemPermissions.length > 0 && <SystemPermissionList items={this.state.systemPermissions} onRow={(p: PermissionListItem) => <div>{p.Name}</div>}/>}
                    </Section>)}
            </PaperLayout>);
    }
    renderIncompleteTeamsInfo() {
        return (!this.state.isTeamsComplete && (<Callout type={CalloutType.Information} title={"Limited permissions"}>
                    Your permissions do not allow you to see all of the teams that '{this.state.selectedUser!.DisplayName}' is a member of
                </Callout>));
    }
    renderIncompletePermissionsInfo() {
        return (!this.state.isPermissionsComplete && (<Callout type={CalloutType.Information} title={"Limited permissions"}>
                    Your permissions do not allow you to see all of the permissions granted to '{this.state.selectedUser!.DisplayName}'
                </Callout>));
    }
    private userChanged = async (userId: string | undefined) => {
        this.setState({ redirectTo: userId ? links.testPermissionsForUserPage.generateUrl({ userId }) : links.testPermissionsPage.generateUrl() });
    };
    private getRoot(spaceId: string | null) {
        return spaceId === null ? routeLinks : routeLinks.forSpace(spaceId);
    }
    private permissionContextChanged = async (permissionContext: PermissionContext) => {
        if (permissionContext === PermissionContext.System) {
            await this.doBusyTask(async () => {
                const systemLevelRepository = repository.forSystem();
                const userPermissions = await systemLevelRepository.UserPermissions.getPermissionsConfigurationForCurrentSpaceContext(this.state.selectedUser!, true);
                this.setState({
                    permissionContext,
                    projectedTeamReferenceDataItems: userPermissions.Teams,
                    spacePermissions: userPermissions.SpacePermissions,
                    systemPermissions: userPermissions.SystemPermissions.map((p: Permission) => ({ Id: p, Name: p })),
                    isTeamsComplete: userPermissions.IsTeamsComplete,
                    isPermissionsComplete: userPermissions.IsPermissionsComplete,
                    projectSummaries: {},
                    environments: {},
                    projectGroups: {},
                    tenants: {},
                    selectedSpaceId: null!,
                });
            }, { timeOperationOptions: timeOperationOptions.for("PermissionContextChanged") });
            return;
        }
        this.setState(() => ({ permissionContext, spacePermissions: null, systemPermissions: null, projectedTeamReferenceDataItems: null, exportUrl: null }));
    };
    private selectedSpaceChanged = async (spaceId: string, selectedUser: UserResource) => {
        return await timeOperation(timeOperationOptions.for("SelectedSpaceChanged"), async () => {
            const spaceLevelRepository = await repository.forSpace(spaceId);
            const userPermissions = await spaceLevelRepository.UserPermissions.getPermissionsConfigurationForCurrentSpaceContext(selectedUser, false);
            const tenants = isAllowedForSpace(spaceId, { permission: Permission.TenantView }) && isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? spaceLevelRepository.Tenants.all() : Promise.resolve([]);
            const projectSummaries = isAllowedForSpace(spaceId, { permission: Permission.ProjectView }) ? spaceLevelRepository.Projects.summaries() : Promise.resolve([]);
            const environments = isAllowedForSpace(spaceId, { permission: Permission.EnvironmentView }) ? spaceLevelRepository.Environments.all() : Promise.resolve([]);
            const projectGroups = isAllowedForSpace(spaceId, { permission: Permission.ProjectGroupView }) ? spaceLevelRepository.ProjectGroups.all() : Promise.resolve([]);
            return {
                selectedSpaceId: spaceId,
                projectedTeamReferenceDataItems: userPermissions.Teams,
                spacePermissions: userPermissions.SpacePermissions,
                systemPermissions: userPermissions.SystemPermissions.map((p) => ({ Id: p, Name: p })),
                isTeamsComplete: userPermissions.IsTeamsComplete,
                isPermissionsComplete: userPermissions.IsPermissionsComplete,
                projectSummaries: keyBy(await projectSummaries, "Id"),
                environments: keyBy(await environments, "Id"),
                tenants: keyBy(await tenants, "Id"),
                projectGroups: keyBy(await projectGroups, "Id"),
                exportUrl: repository.resolve(userPermissions.Links["Export"], { spaces: spaceId }),
            };
        });
    };
    static displayName = "TestPermissionsPage";
}
