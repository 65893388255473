import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../routeLinks";
import UserApiKeysPage from "../UserApiKeys/UserApiKeysPage";
import UserLoginsPage from "../UserLogins/UserLoginsPage";
import UserProfilePage from "../UserProfile/UserProfilePage";
import UserProfileLayout from "../UserProfileLayout";
import UserRecentActivityPage from "../UserRecentActivity/UserRecentActivityPage";
const userProfilePage = renderWithLayout(UserProfileLayout)(UserProfilePage);
const userRecentActivityPage = renderWithLayout(UserProfileLayout)(UserRecentActivityPage);
const userApiKeysPage = renderWithLayout(UserProfileLayout)(UserApiKeysPage);
const userLoginsPage = renderWithLayout(UserProfileLayout)(UserLoginsPage);
const UserProfilePageDefinition = withPage({ page: pageIds.currentUser.details })(userProfilePage);
const UserRecentActivityPageDefinition = withPage({ page: pageIds.currentUser.activity })(userRecentActivityPage);
const UserApiKeysPageDefinition = withPage({ page: pageIds.currentUser.apiKeys })(userApiKeysPage);
const UserLoginsPageDefinition = withPage({ page: pageIds.currentUser.logins })(userLoginsPage);
export class UserProfileRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (<Switch>
                <MigratedReloadableRoute path={routeLinks.currentUser.details} render={() => <UserProfilePageDefinition />}/>
                <MigratedReloadableRoute path={routeLinks.currentUser.activity} render={() => <UserRecentActivityPageDefinition />}/>
                <MigratedReloadableRoute path={routeLinks.currentUser.apiKeys} render={() => <UserApiKeysPageDefinition />}/>
                <MigratedReloadableRoute path={routeLinks.currentUser.logins} render={() => <UserLoginsPageDefinition />}/>
                <InternalRedirect from={routeLinks.currentUser.me} exact to={routeLinks.currentUser.details}/>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "UserProfileRoutes";
}
export default UserProfileRoutes;
