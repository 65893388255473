import type { SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { useMenuState, SimpleMenu, ActionButtonType, ActionButton } from "@octopusdeploy/design-system-components";
import type { AccountResource, AccountType, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { sortBy, uniq } from "lodash";
import * as React from "react";
import { useDispatchAccountInteraction } from "~/areas/infrastructure/components/AccountEdit/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import FormPage from "~/components/FormPage/FormPage";
import MarkdownDescription from "~/components/MarkdownDescription";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { SimplePagingList } from "~/components/PagingList/SimplePagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FormSectionHeading } from "~/components/form";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { environmentChipListIncludingMissing, tenantChipListIncludingMissing } from "../../../../components/Chips/index";
import { RawAccountTypeDetailsMap } from "../../InfrastructureDetails";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import Onboarding from "./Onboarding";
import styles from "./style.module.less";
class AccountList extends SimplePagingList<AccountResource> {
}
interface AccountsPageProps {
    spaceId: string;
}
interface AccountsPageInternalProps extends AccountsPageProps {
    initialData: InitialData;
}
interface InitialData {
    accounts: AccountResource[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}
const Title = "Accounts";
const AccountsLayoutFormPage = FormPage<InitialData>();
export function AccountsPage({ spaceId }: AccountsPageProps) {
    return (<AccountsLayoutFormPage title={Title} load={async () => {
            const accounts = await repository.Accounts.all();
            const envIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.EnvironmentIds), []));
            const tenantIds = uniq(accounts.reduce<string[]>((list, acc) => list.concat(acc.TenantIds), []));
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all({ ids: tenantIds }) : Promise.resolve([]);
            return {
                accounts,
                environments: await repository.Environments.all({ ids: envIds }),
                tenants: await tenantsPromise,
            };
        }} renderWhenLoaded={(initialData) => <AccountsLayoutInternal initialData={initialData} spaceId={spaceId}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
}
function AccountsLayoutInternal({ spaceId, initialData }: AccountsPageInternalProps) {
    const pageTitle = "Accounts";
    const addAccountPageAction: PrimaryPageAction = {
        type: "custom",
        key: "Add Account",
        content: <AddAccountButton spaceId={spaceId}/>,
        hasPermissions: isAllowed({
            permission: Permission.AccountCreate,
            wildcard: true,
        }),
    };
    return (<InfrastructureLayout>
            {initialData.accounts.length === 0 ? (<PaperLayout title={pageTitle}>
                    <Onboarding actionButtonsAndLinks={[<AddAccountButton spaceId={spaceId}/>]}/>
                </PaperLayout>) : (<PaperLayout title={pageTitle} primaryAction={addAccountPageAction}>
                    {RawAccountTypeDetailsMap.map((accountTypeDetail) => (<AccountGroup key={accountTypeDetail.name} spaceId={spaceId} initialData={initialData} accountTypes={accountTypeDetail.types} groupName={accountTypeDetail.namePlural}/>))}
                </PaperLayout>)}
        </InfrastructureLayout>);
}
interface AccountGroupProps {
    spaceId: string;
    initialData: InitialData;
    accountTypes: AccountType[];
    groupName: string;
}
function AccountGroup({ spaceId, initialData, accountTypes, groupName }: AccountGroupProps) {
    const accounts = initialData.accounts.filter((account) => accountTypes.indexOf(account.AccountType) !== -1);
    if (!accounts || accounts.length === 0) {
        return null;
    }
    const sortedAccounts = sortBy(accounts, (account) => account.Name);
    const title = (<div className={styles.typeHeader}>
            <div className={styles.typeHeaderTitleContainer}>{groupName}</div>
        </div>);
    const accountOverview = (account: AccountResource) => (<div key={account.Id} className={styles.account}>
            <ListTitle>{account.Name}</ListTitle>
            {environmentChipListIncludingMissing(initialData.environments, account.EnvironmentIds)}
            {tenantChipListIncludingMissing(initialData.tenants, account.TenantIds)}
            <MarkdownDescription markup={account.Description}/>
        </div>);
    return (<div>
            <FormSectionHeading title={title}/>
            <div className={styles.typeBody}>
                <AccountList items={sortedAccounts} onRow={accountOverview} onRowRedirectUrl={(account: AccountResource) => links.editInfrastructureAccountPage.generateUrl({ spaceId, accountId: account.Id })}/>
            </div>
        </div>);
}
function AddAccountButton({ spaceId }: {
    spaceId: string;
}) {
    const [openMenu, menuState, buttonArriaAttributes] = useMenuState();
    const analyticsDispatch = useDispatchAccountInteraction();
    const addAccountItems: SimpleMenuItem[] = RawAccountTypeDetailsMap.map((accountTypeDetails) => ({
        type: "internal-link",
        label: accountTypeDetails.name,
        path: links.createInfrastructureAccountPage.generateUrl({ spaceId }, { accountType: accountTypeDetails.types[0] }),
        onClick: () => {
            analyticsDispatch({
                type: "Add Account",
                Location: "Page",
                "Account Type": accountTypeDetails.types[0],
            });
        },
    }));
    return (<>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition="right" label="Add Account" onClick={openMenu} menuButtonAttributes={buttonArriaAttributes}/>
            <SimpleMenu menuState={menuState} items={addAccountItems} accessibleName={"Add account"} compact={true}/>
        </>);
}
