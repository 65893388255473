import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref, PageDefinition } from "@octopusdeploy/portal-routes";
import { links, pageId } from "@octopusdeploy/portal-routes";
import configurationPageIds from "~/areas/configuration/components/ConfigurationRoutes/configurationPageIds";
import configurationRouteLinks from "~/areas/configuration/components/ConfigurationRoutes/configurationRouteLinks";
import dashboardPageIds from "~/areas/dashboard/DashboardOverview/dashboardPageIds";
import { RawAccountTypeDetailsMap } from "~/areas/infrastructure/InfrastructureDetails";
import infrastructurePageIds from "~/areas/infrastructure/components/InfrastructureRoutes/infrastructurePageIds";
import infrastructureRouteLinks from "~/areas/infrastructure/components/InfrastructureRoutes/infrastructureRouteLinks";
import libraryPageIds from "~/areas/library/components/LibraryRoutes/libraryPageIds";
import libraryRouteLinks from "~/areas/library/components/LibraryRoutes/libraryRouteLinks";
import projectPageIds from "~/areas/projects/components/ProjectsRoutes/projectPageIds";
import projectRouteLinks from "~/areas/projects/components/ProjectsRoutes/projectRouteLinks";
import taskPageIds from "~/areas/tasks/components/TaskRoutes/taskPageIds";
import taskRouteLinks from "~/areas/tasks/components/TaskRoutes/taskRouteLinks";
import tenantPageIds from "~/areas/tenants/TenantsRoutes/tenantPageIds";
import userProfilePageIds from "~/areas/users/UserProfileRoutes/userProfilePageIds";
import userProfileRouteLinks from "~/areas/users/UserProfileRoutes/userProfileRouteLinks";
import { client } from "~/clientInstance";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
export interface PageDefinitionWithRoutingInfo extends PageDefinition {
    RouteLink: () => LinkHref; // The internal routelink to navigate to this page.
    RoutePermission?: PermissionCheckProps;
}
const dashboardPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...dashboardPageIds.root,
        RouteLink: () => links.dashboardPage.generateUrl({ spaceId: client.spaceId ?? "" }),
    },
    {
        ...dashboardPageIds.configure,
        RouteLink: () => links.dashboardConfigurationPage.generateUrl({ spaceId: client.spaceId ?? "" }),
    },
];
const libraryLinks = libraryRouteLinks(client.spaceId ?? "");
const libraryPageIdsInstance = libraryPageIds();
const libraryPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...libraryPageIdsInstance.root,
        RouteLink: () => libraryLinks.root,
        RoutePermission: { permission: [Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView, Permission.CertificateView], wildcard: true },
    },
    {
        ...libraryPageIdsInstance.feeds,
        RouteLink: () => links.feedsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedView },
    },
    {
        ...libraryPageIdsInstance.feedCreate,
        RouteLink: () => links.createFeedPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedEdit },
    },
    {
        ...libraryPageIdsInstance.stepTemplates.root,
        RouteLink: () => links.stepTemplatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.ActionTemplateView },
    },
    {
        ...libraryPageIdsInstance.stepTemplates.builtIn,
        RouteLink: () => links.builtInStepTemplatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.ActionTemplateView },
    },
    {
        ...libraryPageIdsInstance.stepTemplates.community,
        RouteLink: () => links.communityStepTemplatesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.ActionTemplateView },
    },
    {
        ...libraryPageIdsInstance.lifecycles,
        RouteLink: () => links.lifecyclesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LifecycleView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.lifecyclesCreate,
        RouteLink: () => links.createLifecyclePage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LifecycleCreate, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.certificates.root,
        RouteLink: () => libraryLinks.certificates.root,
        RoutePermission: { permission: Permission.CertificateView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.certificates.archive,
        RouteLink: () => libraryLinks.certificates.archive,
        RoutePermission: { permission: Permission.CertificateView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.certificates.create,
        RouteLink: () => libraryLinks.certificates.create,
        RoutePermission: { permission: Permission.CertificateCreate, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.variableSets.root,
        RouteLink: () => links.variableSetsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true },
    },
    {
        ...libraryPageIdsInstance.tagSets.root,
        RouteLink: () => links.tagSetsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.VariableView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.tagSets.create,
        RouteLink: () => links.createTagSetPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.TagSetCreate },
    },
    {
        ...libraryPageIdsInstance.builtInRepository.root,
        RouteLink: () => links.builtInRepositoryPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedView },
    },
    {
        ...libraryPageIdsInstance.buildInformationRepository.root,
        RouteLink: () => links.buildInformationOverviewPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.FeedView },
    },
    {
        ...libraryPageIdsInstance.scriptModules.root,
        RouteLink: () => links.scriptModulesPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LibraryVariableSetView, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.scriptModules.create,
        RouteLink: () => links.createScriptModulePage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.LibraryVariableSetCreate, wildcard: true },
    },
    {
        ...libraryPageIdsInstance.gitCredentials.root,
        RouteLink: () => links.gitCredentialsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: [Permission.GitCredentialView], wildcard: true },
    },
    {
        ...libraryPageIdsInstance.gitCredentials.create,
        RouteLink: () => links.createGitCredentialPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: [Permission.GitCredentialEdit], wildcard: true },
    },
];
const configurationLinks = configurationRouteLinks(client.spaceId ?? "");
const configurationPageIdsInstance = configurationPageIds();
const configurationPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...configurationPageIdsInstance.root,
        RouteLink: () => links.configurationRootRedirect.generateUrl(),
        RoutePermission: {
            permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
            wildcard: true,
        },
    },
    {
        ...configurationPageIdsInstance.userInvites,
        RouteLink: () => links.userInvitesPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserInvite] },
    },
    {
        ...configurationPageIdsInstance.auditBaseRoute,
        RouteLink: () => links.auditPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true },
    },
    {
        ...configurationPageIdsInstance.auditArchive,
        RouteLink: () => links.auditArchivePage.generateUrl(),
        RoutePermission: { permission: [Permission.EventRetentionView, Permission.EventRetentionDelete], wildcard: true },
    },
    {
        ...configurationPageIdsInstance.backup,
        RouteLink: () => links.backupPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.features,
        RouteLink: () => links.featuresPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.license,
        RouteLink: () => links.licensePage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.smtp,
        RouteLink: () => links.smtpPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.nodes.root,
        RouteLink: () => links.nodesPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.nodes.serverSettings,
        RouteLink: () => links.serverSettingsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.thumbprint,
        RouteLink: () => links.thumbprintPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true },
    },
    {
        ...configurationPageIdsInstance.maintenance,
        RouteLink: () => links.maintenancePage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.letsEncrypt.root,
        RouteLink: () => links.letsEncryptPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.letsEncrypt.configure,
        RouteLink: () => links.configureLetsEncryptPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.testPermissions,
        RouteLink: () => links.testPermissionsPage.generateUrl(),
        RoutePermission: { permission: [Permission.TeamEdit, Permission.UserView] },
    },
    {
        ...configurationPageIdsInstance.subscriptions.root,
        RouteLink: () => links.subscriptionsPage.generateUrl(),
        RoutePermission: { permission: [Permission.SubscriptionView] },
    },
    {
        ...configurationPageIdsInstance.subscriptions.create,
        RouteLink: () => links.createSubscriptionPage.generateUrl(),
        RoutePermission: { permission: [Permission.SubscriptionCreate] },
    },
    {
        ...configurationPageIdsInstance.users.root,
        RouteLink: () => links.usersPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserView] },
    },
    {
        ...configurationPageIdsInstance.users.create,
        RouteLink: () => links.createUserPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserEdit] },
    },
    {
        ...configurationPageIdsInstance.spaces.root,
        RouteLink: () => links.spacesPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.SpaceView] },
    },
    /* Excluding as an edge case - our UI creates these via a popover.
    { ...configurationPageIdsInstance.spaces.create,
        RouteLink: () =>  RouteLink: () => configurationLinks.spaces.create,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.SpaceCreate] }\
    }
    */
    {
        ...configurationPageIdsInstance.teams.root,
        RouteLink: () => links.teamsPage.generateUrl(),
        RoutePermission: { permission: [Permission.TeamEdit, Permission.TeamView] },
    },
    {
        ...configurationPageIdsInstance.roles.root,
        RouteLink: () => links.userRolesPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleView] },
    },
    {
        ...configurationPageIdsInstance.roles.create,
        RouteLink: () => links.createUserRolePage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.UserRoleEdit] },
    },
    {
        ...configurationPageIdsInstance.diagnostics.root,
        RouteLink: () => links.diagnosticsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.root,
        RouteLink: () => links.diagnosticLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.autoDeploy,
        RouteLink: () => links.diagnosticAutoDeployLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.scheduledDeploy,
        RouteLink: () => links.diagnosticScheduledDeployLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.machineCleanup,
        RouteLink: () => links.diagnosticMachineCleanupRedirect.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.diagnostics.logs.subscription,
        RouteLink: () => links.diagnosticSubscriptionLogsPage.generateUrl(),
        RoutePermission: { permission: Permission.AdministerSystem },
    },
    {
        ...configurationPageIdsInstance.settings.root,
        RouteLink: () => configurationLinks.settings.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.extensions.root,
        RouteLink: () => configurationLinks.extensions.root,
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.ConfigureServer] },
    },
    {
        ...configurationPageIdsInstance.performance,
        RouteLink: () => links.performancePage.generateUrl(),
        RoutePermission: { permission: Permission.ConfigureServer },
    },
    {
        ...configurationPageIdsInstance.telemetry,
        RouteLink: () => links.telemetryPage.generateUrl(),
        RoutePermission: { permission: Permission.ConfigureServer },
    },
];
const taskPageIdsInstance = taskPageIds();
const taskLinks = taskRouteLinks(client.spaceId ?? "");
const taskPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...taskPageIdsInstance.tasks.root,
        RouteLink: () => links.tasksPage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.TaskView], wildcard: true },
    },
    {
        ...taskPageIdsInstance.tasks.console,
        RouteLink: () => links.scriptConsolePage.generateUrl(),
        RoutePermission: { permission: [Permission.AdministerSystem, Permission.TaskCreate], wildcard: true },
    },
];
const infrastructurePagesIdsInstance = infrastructurePageIds();
const infrastructureLinks = infrastructureRouteLinks(client.spaceId ?? "");
const infrastructurePages: () => PageDefinitionWithRoutingInfo[] = () => {
    const results: PageDefinitionWithRoutingInfo[] = [
        {
            ...infrastructurePagesIdsInstance.root,
            RouteLink: () => infrastructureLinks.root,
            RoutePermission: {
                permission: [Permission.MachineView, Permission.EnvironmentView, Permission.WorkerView],
                wildcard: true,
            },
        },
        {
            ...infrastructurePagesIdsInstance.overview,
            RouteLink: () => infrastructureLinks.overview,
            RoutePermission: { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.environments.root,
            RouteLink: () => infrastructureLinks.environments.root,
            RoutePermission: { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true },
        },
        /* Excluding as an edge case - our UI creates these via a popover.
        {
            ...infrastructurePagesIdsInstance.environments.create,
            RouteLink: () => infrastructureLinks.environments.create,
            RoutePermission: { permission: Permission.EnvironmentCreate, wildcard: true },
        }
        */
        {
            ...infrastructurePagesIdsInstance.machines.root,
            RouteLink: () => infrastructureLinks.machines.root,
            RoutePermission: { permission: [Permission.MachineView], wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.machines.create,
            RouteLink: () => infrastructureLinks.machines.new(undefined),
            RoutePermission: {
                permission: Permission.MachineCreate,
                wildcard: true,
            },
        },
        {
            ...infrastructurePagesIdsInstance.proxies.root,
            RouteLink: () => infrastructureLinks.proxies.root,
            RoutePermission: { permission: Permission.ProxyView, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.proxies.create,
            RouteLink: () => infrastructureLinks.proxies.create,
            RoutePermission: { permission: Permission.ProxyCreate, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.machinePolicies.root,
            RouteLink: () => infrastructureLinks.machinePolicies.root,
            RoutePermission: { permission: Permission.MachinePolicyView, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.machinePolicies.create,
            RouteLink: () => infrastructureLinks.machinePolicies.create,
            RoutePermission: { permission: Permission.MachinePolicyCreate, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.accounts.root,
            RouteLink: () => infrastructureLinks.accounts.root,
            RoutePermission: { permission: Permission.AccountView, wildcard: true },
        },
        /*
        // Excluding as an edge case - our UI creates these via a popover.
        {
            ...infrastructurePagesIdsInstance.accounts.create,
            RouteLink: () => infrastructureLinks.accounts.create,
            RoutePermission: { permission: Permission.AccountCreate, wildcard: true }
        }
         */
        {
            ...infrastructurePagesIdsInstance.workerMachines.root,
            RouteLink: () => infrastructureLinks.workerMachines.root,
            RoutePermission: { permission: Permission.WorkerView, wildcard: true },
        },
        {
            ...infrastructurePagesIdsInstance.workerMachines.create,
            RouteLink: () => infrastructureLinks.workerMachines.new(undefined),
            RoutePermission: {
                permission: Permission.WorkerEdit,
                wildcard: true,
            },
        },
        {
            ...infrastructurePagesIdsInstance.workerPools.root,
            RouteLink: () => infrastructureLinks.workerPools.root,
            RoutePermission: { permission: Permission.WorkerView, wildcard: true },
        },
        /*
            // Excluding as an edge case - our UI creates these via a popover.
            ...infrastructurePagesIdsInstance.workerPools.create,
            RouteLink: () => infrastructureLinks.workerPools.create,
            RoutePermission: { permission: Permission.WorkerEdit, wildcard: true }
         */
    ];
    // Inject our account types dynamically.
    const accountTypeDetailsMap = RawAccountTypeDetailsMap;
    accountTypeDetailsMap.forEach((accountTypeDetails) => {
        if (accountTypeDetails.types.length === 0) {
            return null;
        }
        results.push({
            ...pageId({
                area: "Infrastructure",
                id: `Infrastructure.AccountsNew.${accountTypeDetails.name}`,
                name: `Add New ${accountTypeDetails.name} ${accountTypeDetails.name.endsWith("Account") ? "" : "Account"}`,
            }),
            RouteLink: () => links.createInfrastructureAccountPage.generateUrl({ spaceId: client.spaceId ?? "" }, { accountType: accountTypeDetails.types[0] }),
            RoutePermission: {
                permission: Permission.AccountCreate,
                wildcard: true,
            },
        });
    });
    return results;
};
const projectPageIdsInstance = projectPageIds();
const projectLinks = projectRouteLinks(client.spaceId ?? "");
const projectPages = (): PageDefinitionWithRoutingInfo[] => {
    return [
        {
            ...projectPageIdsInstance.projects.root,
            RouteLink: () => projectLinks.projects.root,
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
        {
            ...projectPageIdsInstance.importExport.root,
            RouteLink: () => links.importExportProjectTasksPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
        {
            ...projectPageIdsInstance.importExport.import,
            RouteLink: () => links.importProjectsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
        {
            ...projectPageIdsInstance.importExport.export,
            RouteLink: () => links.exportProjectsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
            RoutePermission: { permission: Permission.ProjectView, wildcard: true },
        },
    ];
};
const tenantPageIdsInstance = tenantPageIds();
const tenantPages: PageDefinitionWithRoutingInfo[] = [
    {
        ...tenantPageIdsInstance.tenants,
        RouteLink: () => links.tenantsPage.generateUrl({ spaceId: client.spaceId ?? "" }),
        RoutePermission: { permission: Permission.TenantView, wildcard: true },
    },
];
const userProfilePageIdsInstance = userProfilePageIds();
const userProfileLinks = userProfileRouteLinks(client.spaceId ?? "", "");
const userProfilePages: PageDefinitionWithRoutingInfo[] = [
    {
        ...userProfilePageIdsInstance.me,
        RouteLink: () => userProfileLinks.me,
    },
    {
        ...userProfilePageIdsInstance.logins,
        RouteLink: () => userProfileLinks.logins,
    },
    {
        ...userProfilePageIdsInstance.details,
        RouteLink: () => userProfileLinks.details,
    },
    {
        ...userProfilePageIdsInstance.apiKeys,
        RouteLink: () => userProfileLinks.apiKeys,
    },
    {
        ...userProfilePageIdsInstance.activity,
        RouteLink: () => userProfileLinks.activity,
        RoutePermission: { permission: Permission.EventView, wildcard: true },
    },
];
export const pagesToIncludeInSearch = () => {
    return [...dashboardPages, ...libraryPages, ...configurationPages, ...taskPages, ...infrastructurePages(), ...projectPages(), ...tenantPages, ...userProfilePages];
};
