import type { InsightsCadence, InsightsMetricsSplit, SetQueryParamValue } from "@octopusdeploy/portal-routes";
import { useQueryStringParams, insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam } from "@octopusdeploy/portal-routes";
export type ReportFilter = {
    split?: InsightsMetricsSplit;
    cadence?: InsightsCadence;
    tenantTagSetId?: string;
};
export type SetReportFilter = SetQueryParamValue<ReportFilter>;
export function useReportFilters(): [
    ReportFilter,
    SetReportFilter
] {
    return useQueryStringParams([insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam]);
}
