import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { RawLogPage } from "~/areas/tasks/components/RawLog/RawLogPage";
import { ScriptConsolePage } from "~/areas/tasks/components/ScriptConsole/ScriptConsolePage";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { TaskPage } from "../Task/TaskPage";
import { TasksPage } from "../Tasks/TasksPage";
import { InterruptionToProjectRedirect } from "./InterruptionToTaskRedirect";
const ScriptConsolePageWithPageDefinition = withPage({ page: pageIds.tasks.console })(ScriptConsolePage);
const RawLogPageWithPageDefinition = withPage({ page: pageIds.task().raw })(RawLogPage);
const TaskPageWithPageDefinition = withPage({ page: pageIds.task().root })(TaskPage);
const TasksPageWithPageDefinition = withPage({ page: pageIds.tasks.root })(TasksPage);
type TasksInterruptionRouteProps = RouteComponentProps<{
    spaceId?: string;
    interruptionId: string;
}>;
export default class TaskRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (<Switch>
                <MigratedReloadableRoute path={routeLinks.tasks.interruption(":interruptionId")} render={(p: TasksInterruptionRouteProps) => <InterruptionToProjectRedirect spaceId={p.match.params.spaceId} interruptionId={p.match.params.interruptionId}/>} exact={true}/>
                <MigratedReloadableRoute path={routeLinks.tasks.console} render={() => <ScriptConsolePageWithPageDefinition />}/>
                <MigratedReloadableRoute path={routeLinks.task(":taskId").raw} render={({ match }: RouteComponentProps<{
            taskId: string;
        }>) => <RawLogPageWithPageDefinition taskId={match.params.taskId}/>}/>
                <MigratedReloadableRoute path={routeLinks.task(":taskId").root} render={({ match }: RouteComponentProps<{
            taskId: string;
        }>) => <TaskPageWithPageDefinition taskId={match.params.taskId}/>}/>
                <MigratedReloadableRoute path={routeLinks.tasks.root} render={() => <TasksPageWithPageDefinition />}/>
            </Switch>);
    }
    static displayName = "TaskRoutes";
}
