import type { RunbookRunResource, RunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
export const RunCreateGoalValues = {
    To: "to",
    TryAgain: "try-again",
} as const;
export type RunCreateGoal = (typeof RunCreateGoalValues)[keyof typeof RunCreateGoalValues];
function createLink(root: string, runbookSnapshotId: string | RunbookSnapshotResource, goal?: RunCreateGoal | string, previousId?: string) {
    const previousSegment = previousId ? `/${previousId}` : "";
    return `${root}/${runbookSnapshotId}/runs/create/${goal}${previousSegment}`;
}
export default (root: string) => ({
    runbookSnapshots: root,
    runbookSnapshotCreate: `${root}/create`,
    runbookSnapshot: (runbookSnapshotId: string | RunbookSnapshotResource) => {
        if (!!runbookSnapshotId && typeof runbookSnapshotId !== "string") {
            runbookSnapshotId = runbookSnapshotId.Id;
        }
        return {
            root: `${root}/${runbookSnapshotId}`,
            edit: `${root}/${runbookSnapshotId}/edit`,
            runbookRuns: {
                specific: (runbookRunId: string | RunbookRunResource) => {
                    if (typeof runbookRunId !== "string") {
                        runbookRunId = runbookRunId.Id;
                    }
                    return `${root}/${runbookSnapshotId}/runs/${runbookRunId}`;
                },
                retry: (previousRunbookRunId: string) => {
                    return createLink(root, runbookSnapshotId, RunCreateGoalValues.TryAgain, previousRunbookRunId);
                },
                create: (goal?: RunCreateGoal | string, previousEnvironmentIdsAsCsv?: string) => {
                    return createLink(root, runbookSnapshotId, goal, previousEnvironmentIdsAsCsv);
                },
            },
        };
    },
});
