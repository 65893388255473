import { ActionButton } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { useEffect, useState } from "react";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { SmallDialogFrame } from "~/components/DialogLayout/Custom";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { Text } from "~/components/form";
type SwitchToFormEditorFeedbackDialogProps = {
    open: boolean;
    onSubmit: (feedback: string | undefined) => void;
    onClose: () => void;
};
export function SwitchToFormEditorFeedbackDialog({ open, onClose, onSubmit }: SwitchToFormEditorFeedbackDialogProps) {
    const [feedback, setFeedback] = useState<string>("");
    const onOkClick = () => {
        if (feedback) {
            onSubmit(feedback);
        }
    };
    const onNoThanksClick = () => {
        onSubmit(undefined);
    };
    useEffect(() => {
        setFeedback("");
    }, [open]);
    return (<CustomDialog open={open} close={onClose} render={() => {
            return (<SmallDialogFrame>
                        <OkDialogLayout title="Why did you switch back to the form editor?" onOkClick={onOkClick} okButtonLabel="Submit" okButtonDisabled={feedback === ""} additionalActions={<ActionButton key="NoThanks" label="No Thanks" title="No Thanks" onClick={onNoThanksClick}/>}>
                            <Text value={feedback} autoFocus={true} onChange={(value) => setFeedback(value)} multiline={true} label="Feedback"/>
                        </OkDialogLayout>
                    </SmallDialogFrame>);
        }}></CustomDialog>);
}
