import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { useCallback } from "react";
interface AddCertificateInteractionEvent {
    type: "Add Certificate";
    Location: "Drawer" | "Page";
    "Correlation Id"?: string;
}
interface SaveCertificateInteractionEvent {
    type: "Save Certificate";
    Location: "Drawer" | "Page";
    "Entity Type": "New" | "Existing";
    Status: "Success" | "Failure";
    "Correlation Id"?: string;
}
export type CertificateInteractionEvent = AddCertificateInteractionEvent | SaveCertificateInteractionEvent;
export function useDispatchCertificateInteraction() {
    const session = useAnalyticSession();
    return useCallback((CertificateInteractionEvent: CertificateInteractionEvent) => {
        session.track(CertificateInteractionEvent.type, { ...CertificateInteractionEvent, type: undefined });
    }, [session]);
}
