import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonLibraryGitCredentialsOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <strong>Git credentials</strong> let you authenticate with any git repository.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonLibraryGitCredentialsTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="GitCredentialsAuth">Git credentials authentication guide</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
