import React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { GitCredentialPage } from "./GitCredentialPage";
import { GitCredentialsPage } from "./GitCredentialsPage";
const EditGitCredentialPageWithPageDefinition = withPage({ page: pageIds.library.gitCredential.root })(GitCredentialPage);
const CreateGitCredentialPageWithPageDefinition = withPage({ page: pageIds.library.gitCredentials.create })(GitCredentialPage);
const GitCredentialsPageWithPageDefinition = withPage({ page: pageIds.library.gitCredentials.root })(GitCredentialsPage);
export class GitCredentialRoutes extends React.Component<RouteComponentProps> {
    render() {
        const libraryRouteLinks = routeLinks.library;
        return (<Switch>
                <MigratedReloadableRoute path={routeLinks.library.gitCredentials.create} render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <CreateGitCredentialPageWithPageDefinition spaceId={match.params.spaceId} create/>}/>
                <MigratedReloadableRoute path={libraryRouteLinks.gitCredentials.credential(":gitCredentialId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            gitCredentialId: string;
        }>) => <EditGitCredentialPageWithPageDefinition spaceId={match.params.spaceId} gitCredentialId={match.params.gitCredentialId}/>}/>
                <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <GitCredentialsPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
            </Switch>);
    }
    static displayName = "GitCredentialRoutes";
}
