import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, LicenseStatusResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ReactNode } from "react";
import { repository } from "~/clientInstance";
import { DefaultSpaceChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { SpaceLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Note } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import Logo from "../../../../components/Logo/Logo";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import PaperLayout from "../../../../components/PaperLayout/PaperLayout";
import ListTitle from "../../../../primitiveComponents/dataDisplay/ListTitle/ListTitle";
import isWithinLicenceLimit from "../License/isWithinLicenceLimit";
import AddSpaceDialogLayout from "./AddSpaceDialogLayout";
import styles from "./style.module.less";
interface SpacesState extends DataBaseComponentState {
    spacesResponse?: ResourceCollection<SpaceResource>;
    licenseStatus?: LicenseStatusResource;
    openUpgradeDialog: boolean;
    redirectTo?: LinkHref;
}
class SpaceList extends List<SpaceResource> {
}
export class SpacesPage extends DataBaseComponent<{}, SpacesState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            openUpgradeDialog: false,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const getSpacesResponse = repository.Spaces.list();
            const getLicenseStatus = repository.Licenses.getCurrentStatus();
            this.setState({
                spacesResponse: await getSpacesResponse,
                licenseStatus: await getLicenseStatus,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const isWithinSpaceLimit = isWithinLicenceLimit(this.state.licenseStatus, "Spaces");
        const addSpacePageAction: PrimaryPageAction = isWithinSpaceLimit
            ? {
                type: "dialog",
                hasPermissions: isAllowed({ permission: Permission.SpaceCreate }),
                label: "Add Space",
                renderDialog: ({ isOpen, closeDialog }) => (<Dialog open={isOpen}>
                          <AddSpaceDialogLayout onSpaceCreated={(space) => this.setState({ redirectTo: links.editSpacePage.generateUrl({ currentSpaceId: space.Id }) })}/>
                      </Dialog>),
            }
            : {
                type: "custom",
                key: "Unlock more spaces",
                content: (<div className={styles.spaceLimitHit}>
                          <ActionButton label="UNLOCK MORE SPACES" type={ActionButtonType.Primary} onClick={() => this.showUpgradeSpacesDialog()}/>
                          <Note>You've hit the maximum number of Spaces.</Note>
                      </div>),
            };
        return (<PaperLayout title="Spaces" busy={this.state.busy} errors={this.errors} primaryAction={this.state.spacesResponse && addSpacePageAction}>
                {this.state.spacesResponse && (<SpaceList initialData={this.state.spacesResponse} onRow={(item: SpaceResource) => this.buildSpaceRow(item)} onRowRedirectUrl={(item: SpaceResource) => links.editSpacePage.generateUrl({ currentSpaceId: item.Id })} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>)}

                <Dialog open={this.state.openUpgradeDialog}>
                    <SpaceLimitReachedDialogLayout onActionClick={() => this.setState({ openUpgradeDialog: false })}/>
                </Dialog>
            </PaperLayout>);
    }
    private showUpgradeSpacesDialog() {
        this.setState({ openUpgradeDialog: true });
    }
    private buildSpaceRow(space: SpaceResource): ReactNode {
        return (<div className={styles.row}>
                <div className={styles.logo}>
                    <Logo url={space.Links.Logo}/>
                </div>
                <div>
                    <ListTitle>
                        {space.Name}
                        {space.IsDefault && <DefaultSpaceChip />}
                    </ListTitle>
                    <div>{space.Description}</div>
                </div>
            </div>);
    }
    private filter(filter: string | null | undefined, resource: SpaceResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase()) || (resource.Description ? resource.Description.toLowerCase().includes(filter.toLowerCase()) : false);
    }
    static displayName = "SpacesPage";
}
