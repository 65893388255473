import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { useCallback } from "react";
interface AddFeedInteractionEvent {
    type: "Add Feed";
    Location: "Page" | "Drawer";
    "Project Id"?: string;
    "Feed Type"?: string;
    "Step Type"?: string;
    "Correlation Id"?: string;
}
interface SaveFeedInteractionEvent {
    type: "Save Feed";
    Location: "Page" | "Drawer";
    "Entity Type": "New" | "Existing";
    "Project Id"?: string;
    "Feed Type"?: string;
    "Step Type"?: string;
    "Correlation Id"?: string;
}
interface SaveAndTestFeedInteractionEvent {
    type: "Save and Test Feed";
    Location: "Page" | "Drawer";
    "Entity Type": "New" | "Existing";
    "Project Id"?: string;
    "Feed Type"?: string;
    "Step Type"?: string;
    "Correlation Id"?: string;
}
type FeedInteractionEvent = AddFeedInteractionEvent | SaveFeedInteractionEvent | SaveAndTestFeedInteractionEvent;
export function useDispatchFeedInteraction() {
    const session = useAnalyticSession();
    return useCallback((feedInteractionEvent: FeedInteractionEvent) => {
        session.track(feedInteractionEvent.type, { ...feedInteractionEvent, type: undefined });
    }, [session]);
}
