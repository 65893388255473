import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppInstallation } from "@octopusdeploy/octopus-server-client";
import React from "react";
interface GitHubInstallationLogoProps {
    installation: GitHubAppInstallation;
    fill?: boolean;
}
export function GitHubInstallationLogo({ installation, fill }: GitHubInstallationLogoProps) {
    const size = fill ? space[24] : space[48];
    const radius = installation.AccountType == "Organization" ? "25%" : "50%";
    const styles = {
        avatar: css({
            width: size,
            borderRadius: radius,
        }),
    };
    return <img aria-hidden className={styles.avatar} src={installation.AccountAvatarUrl} alt={`${installation.AccountLogin} logo`}/>;
}
