/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DeploymentResource, EnvironmentResource, LifecycleProgressionResource, ProjectResource, ReleaseResource, ResourcesById, TaskResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type * as Analytics from "~/analytics/Analytics";
import { ProjectAnalyticView, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { DeployButton } from "~/areas/projects/components/Releases/DeployButton/DeployButton";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { repository } from "../../../../../clientInstance";
import type { DataBaseComponentState } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import Task from "../../../../tasks/components/Task/Task";
import TaskHistory from "../../../../tasks/components/Task/TaskHistory/TaskHistory";
import { ProjectStatus } from "../../ProjectStatus/ProjectStatus";
interface DeploymentDetailsState extends DataBaseComponentState {
    deployment?: DeploymentResource;
    release?: ReleaseResource;
    progression?: LifecycleProgressionResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}
interface DeploymentDetailsPageInternalProps extends DeploymentDetailsPageProps {
    trackAction: Analytics.AnalyticTrackedActionDispatcher;
    project: ProjectResource;
}
class DeploymentDetailsPageInternal extends DataBaseComponent<DeploymentDetailsPageInternalProps, DeploymentDetailsState> {
    constructor(props: DeploymentDetailsPageInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const deployment = await repository.Deployments.get(this.props.deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);
            this.setState({ deployment, release });
        });
    }
    getPrimaryPageAction = (task: TaskResource<any>): PrimaryPageAction | undefined => {
        if (task.IsCompleted && !task.FinishedSuccessfully) {
            const tryAgainLink = links.createDeploymentPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug, releaseVersion: this.state.release!.Version }, { previousDeploymentId: this.state.deployment!.Id });
            return {
                type: "navigate",
                label: "Try again...",
                path: tryAgainLink,
                hasPermissions: isAllowed({ permission: Permission.DeploymentCreate, project: this.state.deployment!.ProjectId, environment: this.state.deployment!.EnvironmentId, tenant: "*" }),
            };
        }
        if (task.FinishedSuccessfully && this.state.progression) {
            return {
                type: "custom",
                key: "Deploy",
                content: (<DeployButton spaceId={this.props.project.SpaceId} releaseVersion={this.state.release!.Version} projectSlug={this.props.project.Slug} projectId={this.props.project.Id} nextDeployments={this.state.progression.NextDeployments} environmentsById={this.state.environmentsById!} tenantedDeploymentMode={this.props.project.TenantedDeploymentMode}/>),
            };
        }
    };
    additionalRefresh = async (task: TaskResource<any>) => {
        if (task.FinishedSuccessfully && !this.state.progression) {
            const progression = repository.Releases.progression(this.state.release!);
            const environmentsById = repository.Environments.allById();
            this.setState({ progression: await progression, environmentsById: await environmentsById });
        }
    };
    render() {
        const deployment = this.state.deployment;
        if (deployment) {
            const deployments = (<div>
                    <TaskHistory doBusyTask={this.doBusyTask} header={<h4>Previous Deployments</h4>} currentDeploymentId={this.props.deploymentId} projectIds={[deployment.ProjectId]} tenantIds={[deployment.TenantId!]} environmentIds={[deployment.EnvironmentId]}/>
                </div>);
            return (<>
                    <ProjectAnalyticView name="View a Deployment" resource="Deploy Release" projectId={deployment.ProjectId}/>
                    <Task taskId={deployment.TaskId} delayRender={() => false} projectId={this.props.project.Id} environmentId={deployment.EnvironmentId} tenantId={deployment.TenantId} additionalSidebar={deployments} getPrimaryPageAction={this.getPrimaryPageAction} additionalRefresh={this.additionalRefresh} statusSection={<ProjectStatus doBusyTask={this.doBusyTask}/>}/>
                </>);
        }
        return <ProjectPageLayout busy={this.state.busy} title="Task" errors={this.errors} fullWidth={true}/>;
    }
    static displayName = "DeploymentDetailsPageInternal";
}
interface DeploymentDetailsPageProps {
    deploymentId: string;
}
export function DeploymentDetailsPage(props: DeploymentDetailsPageProps) {
    const projectContext = useProjectContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    return <DeploymentDetailsPageInternal {...props} project={projectContext.state.model} trackAction={trackAction}/>;
}
