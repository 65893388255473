import type { LibraryVariableSetResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { LibraryLayout } from "~/areas/library/components/LibraryLayout/LibraryLayout";
import { AddVariableSetDialogLayout } from "~/areas/library/components/VariableSets/AddVariableSetDialogLayout";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import MarkdownDescription from "~/components/MarkdownDescription";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PagingList } from "~/components/PagingList/PagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface VariableSetsPageProps {
    spaceId: string;
}
interface VariableSetsState extends DataBaseComponentState {
    variableSets?: ResourceCollection<LibraryVariableSetResource>;
}
export class VariableSetsPage extends DataBaseComponent<VariableSetsPageProps, VariableSetsState> {
    constructor(props: VariableSetsPageProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadVariableSets(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addVariableSetPageAction: PrimaryPageAction = {
            type: "dialog",
            hasPermissions: isAllowed({ permission: Permission.LibraryVariableSetCreate }),
            label: "Add new variable set",
            renderDialog: ({ isOpen }) => (<Dialog open={isOpen}>
                    <AddVariableSetDialogLayout spaceId={this.props.spaceId}/>
                </Dialog>),
        };
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayout title="Variable Sets" primaryAction={addVariableSetPageAction} busy={this.state.busy} errors={this.errors}>
                    {this.state.variableSets && (<VariableSetList initialData={this.state.variableSets} onRow={(variableSet: LibraryVariableSetResource) => this.buildRow(variableSet)} onRowRedirectUrl={(variableSet: LibraryVariableSetResource) => links.editVariableSetPage.generateUrl({ spaceId: variableSet.SpaceId, variableSetId: variableSet.Id })} onFilter={filterVariableSets} filterHintText="Filter by name..." filterSearchEnabled={true} apiSearchParams={["partialName"]} additionalRequestParams={new Map([["contentType", ["Variables"]]])}/>)}
                </PaperLayout>
            </LibraryLayout>);
    }
    private buildRow(tagSet: LibraryVariableSetResource) {
        return (<div>
                <ListTitle>{tagSet.Name}</ListTitle>
                <MarkdownDescription markup={tagSet.Description}/>
            </div>);
    }
    private async loadVariableSets() {
        this.setState({ variableSets: await repository.LibraryVariableSets.list({ contentType: VariableSetContentType.Variables }) });
    }
    static displayName = "VariableSetsPage";
}
function filterVariableSets(filter: string, item: LibraryVariableSetResource) {
    return item.Name.toLowerCase().includes(filter.toLowerCase());
}
class VariableSetList extends PagingList<LibraryVariableSetResource> {
}
