/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton } from "@octopusdeploy/design-system-components";
import type { KubernetesKustomizeProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation, GetNamedPackageReferences, isContainerImageRegistry, PackageAcquisitionLocation, PackageSelectionMode } from "@octopusdeploy/octopus-server-client";
import { RemoveItemsList } from "app/components/RemoveItemsList/RemoveItemsList";
import ExpandableFormSection from "app/components/form/Sections/ExpandableFormSection";
import * as _ from "lodash";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import type { ActionSummaryProps } from "~/components/Actions/actionSummaryProps";
import type { ActionWithFeeds, ActionWithGitRepositorySource } from "~/components/Actions/commonActionHelpers";
import { getKubernetesTargetDiscoveryCloudProviders } from "~/components/Actions/kubernetes/getKubernetesTargetDiscoveryCloudProviders";
import { kubernetesMixedExecutionLocationConfig, KubernetesStepDefaultEditSection, KubernetesStepTopEditSection } from "~/components/Actions/kubernetes/kubernetesMixedExecutionLocationConfig";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import type { ScriptPackageProperties, ScriptPackageReference } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import { ScriptPackageReferenceDialog } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import BaseComponent from "~/components/BaseComponent/index";
import DialogOpener from "~/components/Dialog/DialogOpener";
import { GitRepositorySourceSelector } from "~/components/GitRepositorySource/GitRepositorySourceSelector";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { FormSectionHeading, Note, required, Summary } from "~/components/form/index";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {
}
interface KubernetesKustomizeActionState {
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
    serverNames: string[];
}
type KubernetesKustomizeActionProps = ActionEditProps<KubernetesKustomizeProperties, ScriptPackageProperties>;
type KubernetesKustomizeActionInternalProps = KubernetesKustomizeActionProps & ActionWithFeeds & ActionWithGitRepositorySource;
const overlayDirectoryHelp = (<PopoverHelp trigger="click" placement={"right-start"} absolutePosition={false}>
        <p>
            This field only requires the directory path without the file name. When you execute Kustomize, it will automatically look for the <code>kustomization.yaml</code> file in the directory provided.
        </p>
        <p>We recommend using Octopus variables in your directory path to dynamically populate overlays for environments and tenants.</p>
        <p>Keep in mind that directory paths are case-sensitive.</p>
        <ExternalLink href="kustomize-path">Octopus Docs</ExternalLink>
    </PopoverHelp>);
class KubernetesKustomizeActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: never) {
        super(props);
    }
    render() {
        return <div>Deploy using Kustomize</div>;
    }
    static displayName = "KubernetesKustomizeActionSummary";
}
export class KubernetesKustomizeActionInternal extends BaseComponent<KubernetesKustomizeActionInternalProps, KubernetesKustomizeActionState> {
    constructor(props: KubernetesKustomizeActionInternalProps) {
        super(props);
        this.state = {
            serverNames: [],
        };
    }
    componentDidMount() {
        this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "GitRepository" });
    }
    onKustomizeOverlayDirectoryChanged = (val: string) => {
        this.props.setProperties({ ["Octopus.Action.Kubernetes.Kustomize.OverlayPath"]: val });
    };
    getKustomizeOverlayDirectorySummary = () => {
        const propertyValue = this.props.properties["Octopus.Action.Kubernetes.Kustomize.OverlayPath"];
        if (!propertyValue) {
            return Summary.placeholder("Not provided");
        }
        return Summary.summary(`${this.props.properties["Octopus.Action.Kubernetes.Kustomize.OverlayPath"]}`);
    };
    onVariableSubstituteFilesChanged = (val: string) => {
        this.props.setProperties({ ["Octopus.Action.SubstituteInFiles.TargetFiles"]: val });
    };
    getVariableSubsituteFilesSummary = () => {
        const propertyValue = this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"];
        if (!propertyValue) {
            return Summary.placeholder("Not provided");
        }
        return Summary.summary(`${this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"]}`);
    };
    savePackageReference = (packageReference: ScriptPackageReference) => {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex) {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }
        else {
            packageReferences.push(packageReference);
        }
        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    };
    packageVariableNames = (): string[] => _.flatten(GetNamedPackageReferences(this.props.packages).map((pkg) => [
        `Octopus.Action.Package[${pkg.Name}].PackageId`,
        `Octopus.Action.Package[${pkg.Name}].FeedId`,
        `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
        `Octopus.Action.Package[${pkg.Name}].Path`,
    ]));
    resetSelectedPackageReference = () => this.setState({
        editPackageReference: null!,
        editPackageReferenceIndex: null!,
    });
    addPackageReference = () => {
        const additionalPackage: ScriptPackageReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            // The packages are docker images to be acquired by the cluster, not the target
            AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
            Properties: {
                Extract: "false",
            },
        };
        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null! });
    };
    editPackageReference = (pkg: ScriptPackageReference) => this.setState({
        editPackageReference: _.clone(pkg),
        editPackageReferenceIndex: this.props.packages.indexOf(pkg),
    });
    removePackageReference = (pkg: ScriptPackageReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    };
    packageReferenceListItem = (pkg: ScriptPackageReference) => (<ProcessFeedLookup feedId={pkg.FeedId}>
            {(feed) => (<div>
                    <ListTitle>{pkg.Name}</ListTitle>
                    <div>
                        {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<React.Fragment>
                                <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                            </React.Fragment>)}
                        {pkg.Properties["SelectionMode"] === PackageSelectionMode.Deferred && <React.Fragment>Package will be selected by the project</React.Fragment>}
                    </div>
                </div>)}
        </ProcessFeedLookup>);
    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        if (namedPackageReferences.length === 0) {
            return Summary.placeholder("No additional packages referenced");
        }
        return Summary.summary(`${namedPackageReferences.length} package references`);
    };
    loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    render() {
        const packageReferences = GetNamedPackageReferences(this.props.packages);
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());
        const editPackageReferenceDialog = (<DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
                <ScriptPackageReferenceDialog packageReference={this.state.editPackageReference!} runOn={this.props.runOn} 
        // The docker images will be acquired by the k8s cluster, not by Octopus or targets
        feeds={this.props.feeds
                .filter((f) => isContainerImageRegistry(f.FeedType))
                .map((f) => {
                f.PackageAcquisitionLocationOptions = [PackageAcquisitionLocation.NotAcquired];
                return f;
            })} localNames={localNames} projectId={this.props.projectId!} onChange={(packageReference) => this.savePackageReference(packageReference)} refreshFeeds={this.loadFeeds} parameters={this.props.parameters}/>
            </DialogOpener>);
        return (<div>
                {editPackageReferenceDialog}
                <FormSectionHeading title="Configuration"/>
                <GitRepositorySourceSelector properties={this.props.properties} gitCredentials={this.props.gitCredentials} refreshGitCredentials={this.props.refreshGitCredentials} project={this.props.project} processType={this.props.processType} gitDependencies={this.props.gitDependencies} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setGitDependencies={this.props.setGitDependencies} localNames={this.props.localNames} enableFilePathFilters={false}/>
                <ExpandableFormSection title="Kustomization file directory" isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Kubernetes.Kustomize.OverlayPath" summary={this.getKustomizeOverlayDirectorySummary()} help="Provide the path to the directory where your kustomization.yaml file is located" contextualHelp={overlayDirectoryHelp}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Kubernetes.Kustomize.OverlayPath"]} onChange={(filePaths) => this.onKustomizeOverlayDirectoryChanged(filePaths)} label="Kustomization file directory" error={this.props.getFieldError("Octopus.Action.Kubernetes.Kustomize.OverlayPath")} validate={required("Overlay path can not be empty")} accessibleName="Overlay file directory"/>
                    <Note>
                        If your Kustomize repository uses overlays, consider using the Octopus.Environment.Name variable e.g. <code>overlays/#{"{Octopus.Environment.Name}"}</code> to indicate that you have a subdirectory for each environment.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection title="Substitute Variables in Files" isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.SubstituteInFiles.TargetFiles" summary={this.getVariableSubsituteFilesSummary()} help="Configure files to perform variable substitution on.">
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"]} onChange={(filePaths) => this.onVariableSubstituteFilesChanged(filePaths)} label="Target Files" error={this.props.getFieldError("Octopus.Action.SubstituteInFiles.TargetFiles")} accessibleName={"Variable Substitute path filters"} multiline={true}/>
                    <Note>
                        A newline-separated list of files to transform, relative to the root of the git repository. This field supports glob expression syntax e.g. <code>**/*.env</code>.<br />
                        See our <ExternalLink href="glob-patterns-cheat-sheet">glob expression cheat sheet</ExternalLink> for more info.
                    </Note>
                </ExpandableFormSection>
                <FormSectionHeading title="Additional Options"/>
                <KubernetesStatusCheckComponent jobsSupported={true} timeoutSupported={true} statusCheckSupported={true} showLegacyWait={false} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <ExpandableFormSection title={"Referenced Packages"} isExpandedByDefault={this.props.expandedByDefault} errorKey="Octopus.Action.Script.Packages" summary={this.packageReferenceSummary()} help={"Add packages to be referenced by your scripts at execution-time"}>
                    <Note>
                        Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.
                    </Note>
                    <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()}/>]} data={packageReferences} onRow={(p) => this.packageReferenceListItem(p)} onRowTouch={(pkg) => this.editPackageReference(pkg)} onRemoveRow={(pkg) => this.removePackageReference(pkg)}/>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "KubernetesKustomizeActionInternal";
}
export function KubernetesKustomizeAction(props: PropsWithChildren<KubernetesKustomizeActionProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    const projectContext = useOptionalProjectContext();
    const processContext = useOptionalProcessContext();
    return (<KubernetesKustomizeActionInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} project={projectContext?.state.model} processType={processContext?.selectors.getProcessType()} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials}/>);
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.Kubernetes.Kustomize",
    summary: (properties, targetRolesAsCSV) => <KubernetesKustomizeActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: {
        top: (props: KubernetesKustomizeActionProps) => (<KubernetesStepTopEditSection>
                <KubernetesKustomizeAction {...props}/>
            </KubernetesStepTopEditSection>),
        default: (props: KubernetesKustomizeActionProps) => (<KubernetesStepDefaultEditSection>
                <KubernetesKustomizeAction {...props}/>
            </KubernetesStepDefaultEditSection>),
    },
    canRunOnWorker: true,
    canRunInContainer: true,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckWithTimeoutProperties,
    canUseExecutionTimeouts: false,
    mixedExecutionLocations: kubernetesMixedExecutionLocationConfig,
    disableInlineExecutionContainers: true,
});
