/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ActionTemplateResource, ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import { ActionHandlerCategory } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { groupBy } from "lodash";
import * as React from "react";
import { StepTile } from "~/areas/projects/components/Process/ActionTemplateSelector/StepTile";
import ActionTemplateCard from "~/components/ActionTemplates/ActionTemplateCard";
import ActionTemplateCategory from "~/components/ActionTemplates/ActionTemplateCategory";
import { StepTileOpenDialogButton } from "~/components/ActionTemplates/StepTileOpenDialogButton";
import filterActionTemplates from "~/components/ActionTemplates/filterActionTemplates";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import FeatureToggle, { Feature } from "~/components/FeatureToggle/FeatureToggle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import Section from "~/components/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import ActionTemplateCardList from "./ActionTemplateCardList";
import CommunityTemplateInstallation from "./CommunityTemplateInstallation";
import styles from "./styles.module.less";
require("animate.css");
interface CommunityActionTemplateListProps {
    spaceId: string;
    templates: ActionTemplateSearchResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: {
        Type: string;
        Id: string;
    }) => LinkHref;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => LinkHref;
    installationActionName: string;
}
interface CommunityActionTemplateListState {
    categories?: Array<{
        name: string;
        templates: ActionTemplateSearchResource[];
    }>;
    categoryTemplates?: ActionTemplateSearchResource[];
    redirectTo?: LinkHref;
}
//eslint-disable-next-line react/no-unsafe
class CommunityActionTemplateList extends React.Component<CommunityActionTemplateListProps, CommunityActionTemplateListState> {
    private categoriesElement: HTMLOListElement | null = undefined!;
    private categoryTemplatesElement: HTMLElement | null = undefined!;
    constructor(props: CommunityActionTemplateListProps) {
        super(props);
        this.state = {
            categories: this.categoriseTemplates(props.templates),
            categoryTemplates: [],
        };
    }
    categoriseTemplates(templates: ActionTemplateSearchResource[]) {
        const categorised = groupBy(templates, (t) => t.Categories && t.Categories.length > 0 && t.Categories[0]); // Community steps have a single category (other than the "Community" parent category).
        return Object.keys(categorised)
            .sort((category1, category2) => category1.toLowerCase().localeCompare(category2.toLowerCase()))
            .map((categoryName) => ({ name: categoryName, templates: categorised[categoryName] }));
    }
    showCategoryTemplates(templates: ActionTemplateSearchResource[]) {
        // We are setting min-hight here to avoid "screen jumping" when the user switches from categories to templates.
        // Ideally this would be handled by a CSS transform but it did not work.
        this.categoryTemplatesElement!.style.minHeight = this.categoriesElement!.clientHeight + "px";
        this.setState({ categoryTemplates: templates, categories: [] });
    }
    showCategories = () => {
        this.setState({ categoryTemplates: [], categories: this.categoriseTemplates(this.props.templates) });
    };
    showAllTemplates = () => {
        this.setState({ categoryTemplates: this.props.templates, categories: [] });
    };
    isInCategoryMode() {
        return this.state.categoryTemplates!.length === 0 && this.state.categories!.length > 0;
    }
    isInCategoryTemplatesMode() {
        return this.state.categoryTemplates!.length > 0 && this.state.categories!.length === 0;
    }
    UNSAFE_componentWillReceiveProps(nextProps: CommunityActionTemplateListProps) {
        const nextState: Partial<CommunityActionTemplateListState> = { categories: this.categoriseTemplates(nextProps.templates) };
        if (!nextProps.filter) {
            nextState.categoryTemplates = [];
        }
        this.setState(nextState);
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const isFiltering = !!this.props.filter;
        const filteredResults = filterActionTemplates(this.props.templates, this.props.filter!);
        const numberOfTemplatesShown = isFiltering ? filteredResults.length : this.props.templates && this.props.templates.length;
        return (<div className={styles.container}>
                <Section sectionHeader={`Community Contributed Step Templates (${numberOfTemplatesShown})`} className={styles.stepTemplatesHeader}>
                    <FeatureToggle feature={Feature.CommunityActionTemplates} enabled={false}>
                        <Callout type={CalloutType.Information} title={"Community library is disabled"}>
                            If you want to take advantage of hundreds of great templates developed by the members of our community then please <InternalLink to={links.featuresPage.generateUrl()}>enable the integration feature.</InternalLink>
                        </Callout>
                    </FeatureToggle>
                    <FeatureToggle feature={Feature.CommunityActionTemplates}>
                        {this.props.templates.length === 0 && !this.props.filter && (<Callout type={CalloutType.Information} title={"No community step templates available"}>
                                Community library integration is enabled but there aren't any community step templates available. Review the community step templates <InternalLink to={links.featuresPage.generateUrl()}>feature toggle</InternalLink>
                                &nbsp; for more information.
                            </Callout>)}
                    </FeatureToggle>
                </Section>
                {!isFiltering ? (<Section>
                        <div className={styles.actions}>
                            {this.isInCategoryMode() && <ActionButton label="Show all" type={ActionButtonType.Secondary} onClick={this.showAllTemplates}/>}
                            {this.isInCategoryTemplatesMode() && <ActionButton label={this.state.categoryTemplates?.length !== this.props.templates?.length ? "Back" : "Show Less"} type={ActionButtonType.Secondary} onClick={this.showCategories}/>}
                        </div>
                        <div className={styles.groupingContainer}>
                            {this.state.categories && this.state.categories.length > 0 && (<ol className={styles.categories} ref={(element) => (this.categoriesElement = element)}>
                                    <div>
                                        {this.state.categories.map((category) => {
                        return this.withAnimation(category.name, <ActionTemplateCategory category={ActionHandlerCategory.CommunitySubCategory} name={category.name} active={false} templates={category.templates} onCategorySelected={() => this.showCategoryTemplates(category.templates)}/>);
                    })}
                                    </div>
                                </ol>)}
                            <ol className={styles.categoryTemplates} ref={(element) => (this.categoryTemplatesElement = element)}>
                                <ActionTemplateCardList>{this.state.categoryTemplates!.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
                            </ol>
                        </div>
                    </Section>) : (this.filteredTemplates(filteredResults))}
            </div>);
    }
    private filteredTemplates(filteredResults: ActionTemplateSearchResource[]) {
        if ((!filteredResults || filteredResults.length === 0) && this.props.filter) {
            return <Section bodyClassName={styles.stepTemplatesNoResult}>No results found</Section>;
        }
        return (<TransitionAnimation key="filteredSteps">
                <ActionTemplateCardList>{filteredResults.map((template) => this.renderSingle(template))}</ActionTemplateCardList>
            </TransitionAnimation>);
    }
    private renderSingle(template: ActionTemplateSearchResource) {
        const isImproveKubernetesStepSelectionFeatureEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
        if (isImproveKubernetesStepSelectionFeatureEnabled) {
            return <StepTile key={template.Type + template.Id} step={template} primaryActionButton={this.installationDialog(template)} secondaryLabel={"More Info"} secondaryRedirect={this.props.onDetailsUrlRequested!(template)}/>;
        }
        return this.withAnimation(template.Type + template.Id, <ActionTemplateCard template={template} primaryAction={this.installationDialog(template)} secondaryAction={<InternalLink size={0.75} to={this.props.onDetailsUrlRequested!(template)}>
                        View details
                    </InternalLink>}/>);
    }
    private withAnimation(key: string, content: React.ReactNode) {
        return (<div key={key} className="animate__animated animate__zoomIn">
                {content}
            </div>);
    }
    private installationDialog(template: ActionTemplateSearchResource) {
        const isImproveKubernetesStepSelectionFeatureEnabled = isFeatureToggleEnabled("ImproveKubernetesStepSelectionFeatureToggle");
        if (isImproveKubernetesStepSelectionFeatureEnabled) {
            return (<StepTileOpenDialogButton label={this.props.installationActionName} importance={"secondary"}>
                    <CommunityTemplateInstallation spaceId={this.props.spaceId} communityTemplateId={template.Id} title={this.props.installationActionName} detailsLink={this.props.onDetailsUrlRequested!(template)} saveDone={this.installationSucceeded}/>
                </StepTileOpenDialogButton>);
        }
        return (<OpenDialogButton label={this.props.installationActionName} type={ActionButtonType.Primary}>
                <CommunityTemplateInstallation spaceId={this.props.spaceId} communityTemplateId={template.Id} title={this.props.installationActionName} detailsLink={this.props.onDetailsUrlRequested!(template)} saveDone={this.installationSucceeded}/>
            </OpenDialogButton>);
    }
    private installationSucceeded = (actionTemplate: ActionTemplateResource) => {
        const url = this.props.onPostSelectionUrlRequested!({ Type: actionTemplate.ActionType, Id: actionTemplate.Id });
        this.setState({ redirectTo: url });
    };
    static displayName = "CommunityActionTemplateList";
}
export default CommunityActionTemplateList;
