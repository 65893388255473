import { getGitRefFromRouteParameter, ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { ProjectLayout } from "~/areas/projects/components/ProjectLayout/ProjectLayout";
import ProjectTenantsPage from "~/areas/projects/components/ProjectTenants/ProjectTenantsPage";
import type { ProjectRouteParams, ProjectsRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import CommonVariableTemplates from "~/areas/projects/components/Variables/CommonVariableTemplates/index";
import LibraryVariableSets from "~/areas/projects/components/Variables/LibraryVariableSets/index";
import ProjectVariableTemplates from "~/areas/projects/components/Variables/ProjectVariableTemplates/index";
import ProjectTenantVariables from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariables";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { RedirectProjectIfNotSlug } from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { MigratedDeploymentSettingsRoute } from "../DeploymentProcessSettings/Routing/DeploymentSettingsRoute";
import DeploymentsRoute from "../DeploymentsRoute";
import { ExportProjectTaskPage } from "../ImportExport/ExportProjectTaskPage";
import { ExportProjectsPage } from "../ImportExport/ExportProjectsPage";
import { ImportExportProjectTasksPage } from "../ImportExport/ImportExportProjectTasksPage";
import { ImportProjectTaskPage } from "../ImportExport/ImportProjectTaskPage";
import { ImportProjectsPage } from "../ImportExport/ImportProjectsPage";
import { OperationsRoute as MigratedOperationsRoute } from "../OperationsRoute";
import { MigratedProcessRoute } from "../Process/Routing/ProcessRoute";
import ProjectInsightsRoutes from "../ProjectInsights/ProjectInsightsRoutes/ProjectInsightsRoutes";
import ProjectSettingsRoute from "../ProjectSettings/ProjectSettingsRoute";
import ProjectTasks from "../ProjectTasks/ProjectTasks";
import { Projects } from "../Projects";
import { MigratedRunbooksRoute } from "../Runbooks/RunbooksRoute";
import { MigratedTriggersRoute } from "../Triggers";
import { MigratedAllVariablesRoute } from "../Variables/AllVariables/AllVariablesRoute";
import { MigratedProjectVariablesRoute } from "../Variables/ProjectVariables/ProjectVariablesRoute";
import { MigratedVariablePreviewRoute } from "../Variables/VariablePreview/VariablePreviewRoute";
import { ProjectToOverviewRedirect } from "./ProjectToOverviewRedirect";
const ProjectTasksPage = withPage({ page: pageIds.project().tasks })(ProjectTasks);
const ProjectTenantsPageWithPageDefinition = withPage({ page: pageIds.project().tenants })(ProjectTenantsPage);
const ProjectsPage = withPage({ page: pageIds.projects.root })(Projects);
const ImportExportProjectTasksPageWithPageDefinition = withPage({ page: pageIds.importExport.root })(ImportExportProjectTasksPage);
const ExportProjectsPageWithPageDefinition = withPage({ page: pageIds.importExport.export })(ExportProjectsPage);
const ExportProjectTaskPageWithPageDefinition = withPage({ page: pageIds.importExport.exportTask })(ExportProjectTaskPage);
const ImportProjectsPageWithPageDefinition = withPage({ page: pageIds.importExport.import })(ImportProjectsPage);
const ImportProjectTaskPageWithPageDefinition = withPage({ page: pageIds.importExport.importTask })(ImportProjectTaskPage);
export const ProjectTenantVariablesPage = withPage({ page: pageIds.project().variables.tenant })(ProjectTenantVariables);
export const ProjectVariableTemplatesPage = withPage({ page: pageIds.project().variables.projectTemplates })(ProjectVariableTemplates);
export const CommonVariableTemplatesPage = withPage({ page: pageIds.project().variables.commonTemplates })(CommonVariableTemplates);
export const LibraryVariableSetsPage = withPage({ page: pageIds.project().variables.library })(LibraryVariableSets);
type ProjectsRouteProps = RouteComponentProps<{
    spaceId: string;
}>;
export function ProjectsRoutes() {
    return (<Switch>
            <MigratedReloadableRoute path={routeLinks.v3projectRoutes.newProject}>
                <InternalRedirect to={routeLinks.projects.root}/>
            </MigratedReloadableRoute>
            <MigratedReloadableRoute path={routeLinks.importExport.root} render={() => <ProjectsImportExportRoutes />}/>
            {buildBranchRoutes()}
            {buildProjectRoutes()}
            <MigratedReloadableRoute path={routeLinks.projects.root} render={(props: RouteComponentProps<ProjectsRouteParams>) => <ProjectsPage PageLayout={FullWidthPageLayout} spaceId={props.match.params.spaceId}/>}/>
            <RedirectAs404 />
        </Switch>);
}
function buildBranchRoutes() {
    const branchRouteLinks = routeLinks.projectBranchRoutes();
    return (<MigratedReloadableRoute key="branchRoutes" path={branchRouteLinks.root} doNotReloadWhenTheseKeysChange={["branchName"]}>
            {(routeProps: RouteComponentProps<ProjectRouteParams & ProjectsRouteParams>) => (<RedirectProjectIfNotSlug spaceId={routeProps.match.params.spaceId}>
                    <ProjectLayout spaceId={routeProps.match.params.spaceId} branchName={getGitRefFromRouteParameter(routeProps.match.params.branchName ?? "")} projectSlug={routeProps.match.params.projectSlug}>
                        <Switch>
                            <MigratedReloadableRoute path={branchRouteLinks.deployments.root} render={() => <ProjectBranchDeploymentRoutes />}/>
                            <MigratedReloadableRoute path={branchRouteLinks.variables.root} render={() => <ProjectBranchVariablesRoutes />}/>
                            <MigratedRunbooksRoute path={branchRouteLinks.runbooks}/>
                            <RedirectAs404 />
                        </Switch>
                    </ProjectLayout>
                </RedirectProjectIfNotSlug>)}
        </MigratedReloadableRoute>);
}
function buildProjectRoutes() {
    const projectRouteLinks = routeLinks.projectRoutes();
    return (<MigratedReloadableRoute key="projectRoutes" path={projectRouteLinks.root}>
            {(routeProps: RouteComponentProps<ProjectRouteParams & ProjectsRouteParams>) => {
            return (<RedirectProjectIfNotSlug spaceId={routeProps.match.params.spaceId}>
                        <ProjectLayout spaceId={routeProps.match.params.spaceId} branchName={getGitRefFromRouteParameter(routeProps.match.params.branchName ?? "")} projectSlug={routeProps.match.params.projectSlug} isNewlyCreatedProject={new URLSearchParams(routeProps.location.search).get("newlyCreatedProject")}>
                            <Switch>
                                <MigratedReloadableRoute path={projectRouteLinks.tasks} component={ProjectTasksPage}/>
                                <MigratedReloadableRoute path={projectRouteLinks.tenants} render={({ match }: RouteComponentProps<{
                spaceId: string;
            }>) => <ProjectTenantsPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
                                <MigratedReloadableRoute path={projectRouteLinks.settings.root} component={ProjectSettingsRoute}/>
                                <MigratedTriggersRoute path={projectRouteLinks.triggers.root}/>
                                <MigratedReloadableRoute path={projectRouteLinks.deployments.root} component={DeploymentsRoute}/>
                                <MigratedReloadableRoute path={projectRouteLinks.variables.root} render={() => <MigratedNonBranchProjectVariablesRoutes />}/>
                                <MigratedOperationsRoute path={projectRouteLinks.operations.root}/>
                                <MigratedReloadableRoute path={projectRouteLinks.insights.root} component={ProjectInsightsRoutes}/>
                                <MigratedReloadableRoute exact={true} path={projectRouteLinks.overview} render={(props: RouteComponentProps<ProjectRouteParams>) => <InternalRedirect to={routeLinks.project(props.match.params.projectSlug).deployments.root}/>}/>
                                <MigratedReloadableRoute exact={true} path={projectRouteLinks.root} render={(props: RouteComponentProps<ProjectRouteParams>) => <ProjectToOverviewRedirect />}/>
                                <RedirectAs404 />
                            </Switch>
                        </ProjectLayout>
                    </RedirectProjectIfNotSlug>);
        }}
        </MigratedReloadableRoute>);
}
type ProjectsImportExportTaskRouteProps = RouteComponentProps<{
    spaceId: string;
    taskId: string;
}>;
export function ProjectsImportExportRoutes() {
    const importExportRouteLinks = routeLinks.importExport;
    return (<Switch>
            <MigratedReloadableRoute path={importExportRouteLinks.overview} render={(p: ProjectsRouteProps) => <ImportExportProjectTasksPageWithPageDefinition spaceId={p.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={importExportRouteLinks.exportTask(":taskId")} render={(p: ProjectsImportExportTaskRouteProps) => <ExportProjectTaskPageWithPageDefinition spaceId={p.match.params.spaceId} taskId={p.match.params.taskId}/>}/>
            <MigratedReloadableRoute path={importExportRouteLinks.importTask(":taskId")} render={(p: ProjectsImportExportTaskRouteProps) => <ImportProjectTaskPageWithPageDefinition spaceId={p.match.params.spaceId} taskId={p.match.params.taskId}/>}/>
            <MigratedReloadableRoute path={importExportRouteLinks.export} render={(p: ProjectsRouteProps) => <ExportProjectsPageWithPageDefinition spaceId={p.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={importExportRouteLinks.import} render={(p: ProjectsRouteProps) => <ImportProjectsPageWithPageDefinition spaceId={p.match.params.spaceId}/>}/>
            <RedirectAs404 />
        </Switch>);
}
export function ProjectBranchVariablesRoutes() {
    const branchRouteLinks = routeLinks.projectBranchRoutes();
    return (<ErrorContextProvider>
            <Switch>
                <MigratedAllVariablesRoute path={branchRouteLinks.variables.all}/>
                <MigratedVariablePreviewRoute path={branchRouteLinks.variables.preview}/>
                <MigratedProjectVariablesRoute path={branchRouteLinks.variables.root}/>
                <RedirectAs404 />
            </Switch>
        </ErrorContextProvider>);
}
export function ProjectBranchDeploymentRoutes() {
    const branchRouteLinks = routeLinks.projectBranchRoutes();
    return (<ErrorContextProvider>
            <Switch>
                <MigratedProcessRoute path={branchRouteLinks.deployments.process.root} processType={ProcessType.Deployment}/>
                <MigratedDeploymentSettingsRoute path={branchRouteLinks.deployments.settings}/>
            </Switch>
        </ErrorContextProvider>);
}
function NonBranchProjectVariablesRoutes() {
    const projectRouteLinks = routeLinks.projectRoutes();
    return (<ErrorContextProvider>
            <Switch>
                <MigratedAllVariablesRoute path={projectRouteLinks.variables.all}/>
                <MigratedVariablePreviewRoute path={projectRouteLinks.variables.preview}/>
                <MigratedReloadableRoute path={projectRouteLinks.variables.tenant} render={() => <ProjectTenantVariablesPage />}/>
                <MigratedReloadableRoute path={projectRouteLinks.variables.projectTemplates} render={(props: RouteComponentProps<ProjectRouteParams>) => <ProjectVariableTemplatesPage projectSlug={props.match.params.projectSlug}/>}/>
                <MigratedReloadableRoute path={projectRouteLinks.variables.commonTemplates} render={(props: RouteComponentProps<ProjectRouteParams>) => <CommonVariableTemplatesPage projectSlug={props.match.params.projectSlug}/>}/>
                <MigratedReloadableRoute path={projectRouteLinks.variables.library} render={(props: RouteComponentProps<ProjectRouteParams>) => <LibraryVariableSetsPage spaceId={props.match.params.spaceId} projectSlug={props.match.params.projectSlug}/>}/>
                <MigratedProjectVariablesRoute path={projectRouteLinks.variables.root}/>
                <RedirectAs404 />
            </Switch>
        </ErrorContextProvider>);
}
export const MigratedNonBranchProjectVariablesRoutes = NonBranchProjectVariablesRoutes;
