import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import EditFeedTrigger from "./Release/EditReleaseCreationFeedTrigger";
interface CreateReleaseCreationFeedTriggersPageProps {
    spaceId: string;
    projectSlug: string;
}
export function CreateReleaseCreationFeedTriggersPage({ spaceId, projectSlug }: CreateReleaseCreationFeedTriggersPageProps) {
    return <EditFeedTrigger create={true} triggerActionCategory={TriggerActionCategory.Release} spaceId={spaceId} projectSlug={projectSlug}/>;
}
