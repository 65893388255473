import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import ConfigureHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-tentacle-configure.svg";
import InstallHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-tentacle-install.svg";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
const helpStyles = {
    content: css({ marginTop: 0, h2: { marginTop: 0, color: themeTokens.color.dialog.helpPanel.text }, p: { color: themeTokens.color.dialog.helpPanel.text } }),
};
export function TentacleConfigurationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>
                <strong>Connect seamlessly to Kubernetes</strong>
            </h2>
            <p>Installing the Kubernetes agent in your cluster eliminates the need for firewall adjustments or extra authentication.</p>
            <p>It also doubles as a worker, efficiently managing deployment and standalone tasks.</p>
        </span>);
}
export function tentacleConfigurationHelpImage() {
    return { src: ConfigureHelpImage, altText: "" };
}
export function TentacleInstallationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>
                <strong>What happens after installation?</strong>
            </h2>
            <p>After running the Helm command, your new Kubernetes deployment target will automatically be registered in Octopus Deploy.</p>
            <p>You can then deploy to it using our pre-built steps for YAML, Helm or Kustomize.</p>
            <p>
                Need help installing Helm? <ExternalLink href={"https://helm.sh/docs/intro/install/"}>Refer to the Helm docs</ExternalLink>
            </p>
        </span>);
}
export function tentacleInstallationHelpImage() {
    return { src: InstallHelpImage, altText: "" };
}
