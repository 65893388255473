import { useSetPageEffect } from "@octopusdeploy/portal-routes";
import type { PageDetailProps } from "@octopusdeploy/portal-routes";
import React from "react";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
type FromPropsCallback<TProps> = (mapPage: TProps) => PageDetailProps;
export const withPageFromProps = <TInnerProps>(Component: React.ComponentType<TInnerProps>, mapPageProps: FromPropsCallback<TInnerProps>) => {
    const WithPageFromRouteProps: React.FC<TInnerProps> = (componentProps) => {
        const mappedProps = mapPageProps(componentProps);
        useSetPageEffect({
            Area: mappedProps.page.Area,
            Name: mappedProps.page.Name,
            Id: mappedProps.page.Id,
        });
        return (<ErrorContextProvider>
                <Component {...componentProps}/>
            </ErrorContextProvider>);
    };
    WithPageFromRouteProps.displayName = "WithPageFromRouteProps"
    return WithPageFromRouteProps;
};
