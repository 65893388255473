import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { useCallback } from "react";
interface AddGitCredentialsInteractionEvent {
    type: "Add Git Credentials";
    Location: "Drawer" | "Page";
    "Correlation Id"?: string;
}
interface SaveGitCredentialsInteractionEvent {
    type: "Save Git Credentials";
    Location: "Drawer" | "Page";
    "Entity Type": "New" | "Existing";
    Status: "Success" | "Failure";
    "Correlation Id"?: string;
}
export type GitCredentialsInteractionEvent = AddGitCredentialsInteractionEvent | SaveGitCredentialsInteractionEvent;
export function useDispatchGitCredentialsInteraction() {
    const session = useAnalyticSession();
    return useCallback((GitCredentialsInteractionEvent: GitCredentialsInteractionEvent) => {
        session.track(GitCredentialsInteractionEvent.type, { ...GitCredentialsInteractionEvent, type: undefined });
    }, [session]);
}
