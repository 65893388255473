import React from "react";
import type { StoredAction } from "~/areas/projects/components/Process/types";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import useLocalStorage from "~/hooks/useLocalStorage";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
type ContainersFeedbackCalloutProps = {
    onTaskPage: boolean;
    actionTypes?: string[];
    action?: StoredAction;
};
export function ContainersFeedbackCallout({ onTaskPage, actionTypes = [], action }: ContainersFeedbackCalloutProps) {
    const [dateDismissed, setDateDismissed] = useLocalStorage(`Octopus.Callout.ContainersFeedback.DateDismissed`, new Date("2022-01-1"));
    const [numberOfDismissals, setNumberOfDismissals] = useLocalStorage(`Octopus.Callout.ContainersFeedback.NumberOfDismissals`, 0);
    const taskPageLink = "https://oc.to/ContainersFeedbackDeployment";
    const processPageLink = "https://oc.to/ContainersFeedback";
    const onCloseCallout = () => {
        setDateDismissed(new Date());
        setNumberOfDismissals(numberOfDismissals + 1);
    };
    // we don't want to show this callout if they are using Helm V2
    if (action && action.ActionType === "Octopus.HelmChartUpgrade" && action.Properties["Octopus.Action.Helm.ClientVersion"] === "V2") {
        return null;
    }
    const containerActionTypes = [
        "Octopus.KubernetesRunScript",
        "Octopus.KubernetesDeployService",
        "Octopus.KubernetesDeployIngress",
        "Octopus.KubernetesDeployConfigMap",
        "Octopus.KubernetesDeployContainers",
        "Octopus.KubernetesDeploySecret",
        "Octopus.Kubernetes.Kustomize",
        "Octopus.HelmChartUpgrade",
        "Octopus.KubernetesDeployRawYaml",
    ];
    const hasContainerStep = actionTypes.some((actionType) => containerActionTypes.includes(actionType));
    const isOutsideDismissalPeriod = isNowOutsideDismissalPeriod(dateDismissed, numberOfDismissals);
    if (hasContainerStep && isOutsideDismissalPeriod) {
        const link = onTaskPage ? taskPageLink : processPageLink;
        return (<Callout title="Containerized Deployment Feedback" type={CalloutType.Information} canClose={true} onClose={onCloseCallout}>
                We'd love to <ExternalLink href={link}>hear about your experience</ExternalLink> deploying containers with Octopus Deploy.
            </Callout>);
    }
    return null;
}
function isNowOutsideDismissalPeriod(dateOfLastDismissal: Date, numberOfDismissals: number) {
    const today = new Date();
    const timeBombDate = new Date("2033-03-01");
    const dismissUntil = new Date(dateOfLastDismissal);
    dismissUntil.setDate(dismissUntil.getDate() + numberOfDismissals * 14);
    return today < timeBombDate && today > dismissUntil;
}
