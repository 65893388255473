import { ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
function GettingStartedButton() {
    const dispatchAction = useAnalyticActionDispatch();
    return (<OpenDialogButton type={ActionButtonType.Primary} label={"GET STARTED"} wideDialog={true} renderDialog={(renderProps) => (<AddNewProjectDialog open={renderProps.open} close={(project) => {
                if (project) {
                    dispatchAction("Save First Project", { action: Action.Save, resource: "Project" });
                }
                else {
                    dispatchAction("Cancel First Project Creation", { action: Action.Cancel, resource: "Project" });
                }
                renderProps.closeDialog();
            }}/>)} onClick={() => {
            dispatchAction("Add First Project", { action: Action.Add, resource: "Project" });
        }}/>);
}
export default GettingStartedButton;
