import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import { renderWithLayout } from "~/components/RenderWithLayout/RenderWithLayout";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import ActionTemplateLayout from "../ActionTemplates/ActionTemplateLayout";
import { ActionTemplateUsagePage } from "../ActionTemplates/ActionTemplateUsagePage";
import { ActionTemplatePage } from "./ActionTemplate/ActionTemplatePage";
import { ActionTemplatesPage } from "./ActionTemplatesPage";
import { BuiltinTemplatesPage } from "./BuiltinTemplatesPage";
import { CommunityTemplatePage } from "./CommunityTemplatePage";
import { CommunityTemplatesPage } from "./CommunityTemplatesPage";
import { RunActionTemplatePage } from "./RunActionTemplatePage";
const ActionTemplatePageWithLayout = renderWithLayout(ActionTemplateLayout)(ActionTemplatePage);
const ActionTemplateUsageWithLayout = renderWithLayout(ActionTemplateLayout)(ActionTemplateUsagePage);
const libraryLinks = routeLinks.library;
const libraryPages = pageIds.library;
export const BuiltinTemplatesPageWithPageDefinition = withPage({ page: libraryPages.stepTemplates.builtIn })(BuiltinTemplatesPage);
export const CommunityTemplatePageWithPageDefinition = withPage({ page: libraryPages.stepTemplates.communityTemplate.root })(CommunityTemplatePage);
export const CommunityTemplatesPageWithPageDefinition = withPage({ page: libraryPages.stepTemplates.community })(CommunityTemplatesPage);
export const ActionTemplatePageWithPageDefinition = withPage({ page: libraryPages.stepTemplate.root })(ActionTemplatePageWithLayout);
export const ActionTemplateCreatePageWithPageDefinition = withPage({ page: libraryPages.stepTemplates.new })(ActionTemplatePageWithLayout);
export const RunActionTemplatePageWithPageDefinition = withPage({ page: libraryPages.stepTemplate.run })(RunActionTemplatePage);
export const ActionTemplateUsagePageWithPageDefinition = withPage({ page: libraryPages.stepTemplate.usage })(ActionTemplateUsageWithLayout);
export const ActionTemplatesPageWithPageDefinition = withPage({ page: libraryPages.stepTemplates.root })(ActionTemplatesPage);
export class ActionTemplateRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (<Switch>
                <MigratedReloadableRoute path={libraryLinks.stepTemplates.builtIn} render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <BuiltinTemplatesPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplates.communityTemplate(":templateId").details} render={(props: RouteComponentProps<{
            templateId: string;
        }>) => <InternalRedirect to={libraryLinks.stepTemplates.communityTemplate(props.match.params.templateId).root}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplates.communityTemplate(":templateId").root} render={({ match }: RouteComponentProps<{
            spaceId: string;
            templateId: string;
        }>) => <CommunityTemplatePageWithPageDefinition spaceId={match.params.spaceId} templateId={match.params.templateId}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplates.community} render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <CommunityTemplatesPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplates.new(":actionType")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            actionType: string;
        }>) => <ActionTemplateCreatePageWithPageDefinition spaceId={match.params.spaceId} actionType={match.params.actionType}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplate(":templateId").run} render={({ match }: RouteComponentProps<{
            spaceId: string;
            templateId: string;
        }>) => <RunActionTemplatePageWithPageDefinition spaceId={match.params.spaceId} templateId={match.params.templateId}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplate(":templateId").usage} render={({ match }: RouteComponentProps<{
            spaceId: string;
            templateId: string;
        }>) => <ActionTemplateUsagePageWithPageDefinition spaceId={match.params.spaceId} templateId={match.params.templateId}/>}/>
                <MigratedReloadableRoute path={libraryLinks.stepTemplate(":templateId").root} render={({ match }: RouteComponentProps<{
            spaceId: string;
            templateId: string;
        }>) => <ActionTemplatePageWithPageDefinition spaceId={match.params.spaceId} templateId={match.params.templateId}/>}/>
                <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <ActionTemplatesPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
            </Switch>);
    }
    static displayName = "ActionTemplateRoutes";
}
export default ActionTemplateRoutes;
