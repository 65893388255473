import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { DeploymentTargetResource, EnvironmentResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { HostingEnvironment, AgentCommunicationMode } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import type { JwtPayload } from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import moment from "moment/moment";
import * as React from "react";
import URI from "urijs";
import type { EndpointCardDialogProps } from "~/areas/infrastructure/components/MachineSettings/Endpoints/EndpointCard";
import { TentacleConfigurationHelpContent, tentacleConfigurationHelpImage, TentacleInstallationHelpContent, tentacleInstallationHelpImage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialogHelp";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import { TentacleInstallationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/TentacleInstallationPage";
import { client, repository } from "~/clientInstance";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { PagedOnboardingDialog } from "~/components/OnboardingDialog/PagedOnboardingDialog";
import StringHelper from "~/utils/StringHelper/index";
import { ConfigurationPage } from "./TentacleConfigurationPage";
type KubernetesAgentConfigurationProps = EndpointCardDialogProps;
type FieldErrors<TModel> = {
    [TKey in keyof TModel]?: string;
};
interface KubernetesAgentConfigurationState extends FormBaseComponentState<KubernetesAgentConfigurationResource> {
    environments: EnvironmentResource[];
    machineRoles: string[];
    allowShowAdvanced: boolean;
    showAdvanced: boolean;
    isBusy: boolean;
    foundDeploymentTarget?: DeploymentTargetResource;
    redirectTo?: LinkHref;
    hasTokenExpired: boolean;
    accessTokenExpiryCheckIntervalId: ReturnType<typeof setTimeout> | undefined;
    accessTokenExpiry: moment.Moment | undefined;
}
export interface KubernetesAgentConfigurationResource {
    Space: SpaceResource | undefined;
    Name: string;
    EnvironmentIds: string[];
    MachineRoles: string[];
    ServerUri: string;
    ServerCommsAddress: string;
    AgentCommunicationMode: string;
    AccessToken: string;
}
export class ConfigurationDialog extends FormBaseComponent<KubernetesAgentConfigurationProps, KubernetesAgentConfigurationState, KubernetesAgentConfigurationResource> {
    constructor(props: KubernetesAgentConfigurationProps) {
        super(props);
        this.initialiseState();
    }
    defaultCommsPort = "10943"; // see https://octopus.com/docs/infrastructure/deployment-targets/tentacle/tentacle-communication#polling-tentacles
    cloudHostedCommsPort = ""; // see https://octopus.com/docs/infrastructure/deployment-targets/tentacle/polling-tentacles-over-port-443#octopus-cloud
    cloudHostedSubdomain = "polling";
    initialModel = {
        Space: undefined,
        Name: "",
        EnvironmentIds: [],
        MachineRoles: [],
        ServerUri: client.resolve("/"),
        ServerCommsAddress: "",
        AgentCommunicationMode: AgentCommunicationMode.Polling,
        AccessToken: "",
        AccessTokenExpiry: new Date(),
    };
    initialiseState() {
        this.state = {
            environments: [],
            machineRoles: [],
            allowShowAdvanced: false,
            showAdvanced: false,
            model: this.initialModel,
            cleanModel: this.initialModel,
            isBusy: false,
            foundDeploymentTarget: undefined,
            redirectTo: undefined,
            hasTokenExpired: false,
            accessTokenExpiryCheckIntervalId: undefined,
            accessTokenExpiry: undefined,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const environmentsPromise = repository.Environments.all();
            const machineRolesPromise = repository.MachineRoles.all();
            const licencePromise = repository.Licenses.getCurrentStatus();
            if (!repository.spaceId) {
                throw new Error("Attempted to render Kubernetes Agent Configuration Dialog in a system context. This should never happen.");
            }
            const spacePromise = repository.Spaces.get(repository.spaceId);
            const isSelfHosted = (await licencePromise).HostingEnvironment === HostingEnvironment.SelfHosted;
            const uri = new URI(this.state.model.ServerUri);
            const serverCommsAddress = isSelfHosted ? uri.port(this.defaultCommsPort).toString() : uri.subdomain(`${this.cloudHostedSubdomain}.${uri.subdomain()}`).port(this.cloudHostedCommsPort).toString();
            this.setModelState({
                Space: await spacePromise,
                ServerCommsAddress: serverCommsAddress,
            });
            this.setState({ environments: await environmentsPromise, machineRoles: await machineRolesPromise, allowShowAdvanced: isSelfHosted, cleanModel: this.state.model });
        }, {});
        const pollingInterval = 5000; // 5 seconds
        const accessTokenExpiryCheckIntervalId: ReturnType<typeof setTimeout> = setInterval(async () => {
            this.setState({ hasTokenExpired: this.state.accessTokenExpiry != undefined && this.state.accessTokenExpiry < moment() });
        }, pollingInterval);
        this.setState({ accessTokenExpiryCheckIntervalId });
    }
    componentWillUnmount() {
        clearInterval(this.state.accessTokenExpiryCheckIntervalId);
        super.componentWillUnmount();
    }
    isValidUrl = (url: string): boolean => {
        try {
            const uri = new URI(url);
            return uri.is("absolute") && uri.is("url") && (uri.is("name") || uri.is("ip"));
        }
        catch (error) {
            return false;
        }
    };
    isModelValid = async () => {
        await this.doBusyTask(async () => {
            this.setState({ isBusy: true });
            this.clearErrors();
            const fieldErrors: FieldErrors<KubernetesAgentConfigurationResource> = {};
            const model = this.state.model;
            if (StringHelper.isNullOrWhiteSpace(model.Name)) {
                fieldErrors.Name = ConfigurationMessages.Name.Validation.NotProvided;
            }
            else {
                const existingMachine = await repository.Machines.list({ name: this.state.model.Name });
                if (existingMachine.TotalResults > 0) {
                    fieldErrors.Name = ConfigurationMessages.Name.Validation.AlreadyExists;
                }
            }
            if (model.EnvironmentIds.length === 0) {
                fieldErrors.EnvironmentIds = ConfigurationMessages.EnvironmentIds.Validation.NotProvided;
            }
            if (model.MachineRoles.length === 0) {
                fieldErrors.MachineRoles = ConfigurationMessages.MachineRoles.Validation.NotProvided;
            }
            if (StringHelper.isNullOrWhiteSpace(model.ServerUri) || !this.isValidUrl(model.ServerUri)) {
                fieldErrors.ServerUri = ConfigurationMessages.ServerUri.Validation.Invalid;
                this.setState({ showAdvanced: true });
            }
            if (StringHelper.isNullOrWhiteSpace(model.ServerCommsAddress) || !this.isValidUrl(model.ServerCommsAddress)) {
                fieldErrors.ServerCommsAddress = ConfigurationMessages.ServerCommsAddress.Validation.Invalid;
                this.setState({ showAdvanced: true });
            }
            if (Object.keys(fieldErrors).length > 0) {
                this.setValidationErrors("The following fields have invalid values", fieldErrors);
            }
        });
        this.setState({ isBusy: false });
        if (this.errors?.fieldErrors) {
            return Object.keys(this.errors.fieldErrors).length === 0;
        }
        return true;
    };
    refreshToken = async () => {
        await this.doBusyTask(async () => {
            this.setState({ isBusy: true });
            this.clearErrors();
            const newToken = await repository.Users.generateAccessToken();
            const payload = jwtDecode<JwtPayload>(newToken.AccessToken);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const expiryTime = moment(payload.exp! * 1000); //The payload time is seconds since Epoch, need to convert to milliseconds
            this.setModelState({ AccessToken: newToken.AccessToken });
            this.setState({ accessTokenExpiry: expiryTime, hasTokenExpired: expiryTime < moment() });
        });
        this.setState({ isBusy: false });
    };
    clearModel = () => this.setModelState(this.state.cleanModel);
    onDeploymentTargetFound = (target: DeploymentTargetResource) => {
        this.setState({ foundDeploymentTarget: target });
    };
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false}/>;
        }
        const showRegenerateTokenButton = this.state.hasTokenExpired && !this.state.foundDeploymentTarget;
        return (<PagedOnboardingDialog open={this.props.open} close={() => {
                this.clearModel();
                this.clearErrors();
                this.props.closeDialog();
                if (this.state.foundDeploymentTarget) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.setState({ redirectTo: links.deploymentTargetSettingsPage.generateUrl({ spaceId: repository.spaceId!, machineId: this.state.foundDeploymentTarget.Id }) });
                }
            }} name={"Add Kubernetes Agent"} showPageIndicator={true} closeButtonText={this.state.foundDeploymentTarget ? "View Deployment Target" : undefined} hidePreviousButton={!!this.state.foundDeploymentTarget} pages={[
                {
                    title: "Add New Kubernetes Agent",
                    helpPanelContent: <TentacleConfigurationHelpContent />,
                    helpPanelImage: tentacleConfigurationHelpImage(),
                    isPageModelValid: () => this.isModelValid(),
                    onMovingToNextPage: () => this.refreshToken(),
                    isBusy: this.state.isBusy,
                    render: () => (<>
                                <ConfigurationPage model={this.state.model} onModelChange={(model) => this.setModelState(model)} environments={this.state.environments} machineRoles={this.state.machineRoles} allowShowAdvanced={this.state.allowShowAdvanced} showAdvanced={this.state.showAdvanced} toggleShowAdvanced={() => this.setState({ showAdvanced: !this.state.showAdvanced })} getFieldError={(name: string) => this.getFieldError(name)}/>
                            </>),
                },
                {
                    title: "Install Kubernetes Agent",
                    helpPanelContent: <TentacleInstallationHelpContent />,
                    helpPanelImage: tentacleInstallationHelpImage(),
                    render: () => (<TentacleInstallationPage model={this.state.model} environments={this.state.environments} onDeploymentTargetFound={this.onDeploymentTargetFound} hasTokenExpired={this.state.hasTokenExpired} accessTokenExpiry={this.state.accessTokenExpiry}/>),
                    customPrimaryButton: showRegenerateTokenButton && <ActionButton label="Regenerate Token" onClick={() => this.refreshToken()} type={ActionButtonType.Primary}/>,
                },
            ]}/>);
    }
    static displayName = "ConfigurationDialog";
}
