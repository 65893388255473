/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, EventCategoryResource, EventGroupResource, NewTriggerResourceTyped, ProjectResource, TriggerResource, TriggerResourceTyped } from "@octopusdeploy/octopus-server-client";
import { AutoDeployActionResource, isExistingTriggerResource, MachineFilterResource, Permission, TenantedDeploymentMode, TriggerActionType } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { ProjectFormPageLayout } from "app/areas/projects/components/ProjectFormPageLayout";
import { cloneDeep } from "lodash";
import * as React from "react";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import TriggerDescription from "~/areas/projects/components/Triggers/TriggerDescription";
import { useProjectContext } from "~/areas/projects/context/index";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import EventFilter from "~/components/EventFilter";
import { Feature } from "~/components/FeatureToggle";
import FeatureToggle from "~/components/FeatureToggle/FeatureToggle";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, ExpansionButtons, MarkdownEditor, Note, Summary, Text, UnstructuredFormSection } from "~/components/form";
import { required } from "~/components/form/Validators";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import styles from "./style.module.less";
interface LookupData {
    eventCategories: EventCategoryResource[];
    eventGroups: EventGroupResource[];
    environments: EnvironmentResource[];
    roles: string[];
    project: ProjectResource;
}
type Model = TriggerResourceTyped<MachineFilterResource, AutoDeployActionResource> | NewTriggerResourceTyped<MachineFilterResource, AutoDeployActionResource>;
interface EditState extends OptionalFormBaseComponentState<Model> {
    lookupData?: LookupData;
    redirectTo?: LinkHref;
}
interface CreateDeploymentTriggerProps extends ProjectRouteParams {
    create: true;
}
interface SpecificDeploymentTriggerProps extends ProjectRouteParams {
    triggerId: string;
}
type DeploymentTriggerPageProps = CreateDeploymentTriggerProps | SpecificDeploymentTriggerProps;
type DeploymentTriggerPageInternalProps = DeploymentTriggerPageProps & WithProjectContextInjectedProps;
class DeploymentTriggerPageInternal extends FormBaseComponent<DeploymentTriggerPageInternalProps, EditState, Model> {
    constructor(props: any) {
        super(props);
        const machineFilterResource = new MachineFilterResource();
        machineFilterResource.EnvironmentIds = [];
        machineFilterResource.Roles = [];
        machineFilterResource.EventGroups = [];
        machineFilterResource.EventCategories = [];
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const eventCategories = repository.Events.categories({ appliesTo: "Machine" });
            const eventGroups = repository.Events.groups({ appliesTo: "Machine" });
            const environments = repository.Environments.all();
            const roles = repository.MachineRoles.all();
            const trigger = await this.loadTriggerOrCreateNew(project);
            const lookupData: LookupData = {
                eventCategories: await eventCategories,
                eventGroups: await eventGroups,
                environments: await environments,
                roles: await roles,
                project,
            };
            this.setState({
                model: trigger,
                lookupData,
                cleanModel: cloneDeep(trigger),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const title = isCreateDeploymentTriggerProps(this.props) ? "New Trigger" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const overFlowActions = [];
        if (this.state.model && isExistingTriggerResource(this.state.model) && this.state.lookupData) {
            const model: TriggerResource = this.state.model;
            const lookupData = this.state.lookupData;
            overFlowActions.push(OverflowMenuItems.item(this.state.model && this.state.model.IsDisabled ? "Enable" : "Disable", () => (this.state.model && this.state.model.IsDisabled ? this.enableTrigger() : this.disableTrigger()), {
                permission: Permission.TriggerEdit,
                project: this.state.lookupData.project && this.state.lookupData.project.Id,
            }));
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("trigger", () => this.deleteTrigger(model, lookupData), {
                permission: Permission.TriggerDelete,
                project: this.state.lookupData.project.Id,
            }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const saveText: string = isCreateDeploymentTriggerProps(this.props) ? "Trigger created" : "Trigger details updated";
        return (<ProjectFormPageLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbTitle={"Triggers"} breadcrumbPath={links.deploymentTriggersPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug })} breadcrumbsItems={[{ label: "Triggers", pageUrl: links.deploymentTriggersPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug }) }]} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{
                permission: isCreateDeploymentTriggerProps(this.props) ? Permission.TriggerCreate : Permission.TriggerEdit,
                project: this.state.lookupData && this.state.lookupData.project.Id,
            }} onSaveClick={() => this.saveProject()} overFlowActions={overFlowActions} hideExpandAll={true} saveText={saveText}>
                <p className={styles.information}>
                    This type of trigger helps to automate your project's deployments in response to changes in your deployment targets, ensuring they always have the currently successful releases. Learn more about{" "}
                    <ExternalLink href="AutomaticDeploymentTriggers">Automatic Deployment Triggers</ExternalLink> or read our <ExternalLink href="ElasticTransientEnvironments">comprehensive guide</ExternalLink> about deploying to elastic and
                    transient environments.
                </p>
                {this.state.model && this.state.lookupData && (<TransitionAnimation>
                        <ExpansionButtons errors={this.errors?.fieldErrors} expandAllOnMount={isCreateDeploymentTriggerProps(this.props)}/>

                        {this.state.cleanModel && this.state.cleanModel.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                <Callout type={CalloutType.Warning} title={" This trigger is currently disabled"}/>
                            </UnstructuredFormSection>)}

                        <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your trigger")} help="Enter a name for your trigger.">
                            <Text value={this.state.model.Name || ""} onChange={(Name) => this.setModelState({ Name })} label="Trigger name" validate={required("Please enter a trigger name")} error={this.getFieldError("Name")} autoFocus={true}/>
                            <Note>
                                A short, memorable, unique name for this trigger. Example: <em>Staging Environment Auto-Deploy</em>
                            </Note>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="Description" title="Description" summary={this.state.model.Description ? Summary.summary(this.state.model.Description) : Summary.placeholder("Please enter a description for your trigger")} help="Enter a description for your trigger.">
                            <MarkdownEditor label="Trigger description" value={this.state.model.Description} onChange={(Description) => this.setModelState({ Description })}/>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="property" title="Event Filters" summary={this.eventFilterSummary(this.state.model, this.state.lookupData)} help="Select filters to apply.">
                            <Callout type={CalloutType.Information} title="Suggestion">
                                If unsure, select only the <em>"Machine becomes available for deployment"</em> event group, as it includes all the necessary events required to keep deployment targets up to date.
                            </Callout>
                            <EventFilter doBusyTask={this.doBusyTask} environments={this.state.lookupData.environments} eventCategories={this.state.lookupData.eventCategories} eventGroups={this.state.lookupData.eventGroups} roles={this.state.lookupData.roles} selectedEnvironments={this.state.model.Filter.EnvironmentIds} selectedEventCategories={this.state.model.Filter.EventCategories} selectedEventGroups={this.state.model.Filter.EventGroups} selectedRoles={this.state.model.Filter.Roles} canAddRoles={true} onChangeEventCategories={(EventCategories) => this.setFilterState({ EventCategories })} onChangeEventGroups={(EventGroups) => this.setFilterState({ EventGroups })} onChangeEnvironments={(EnvironmentIds) => this.setFilterState({ EnvironmentIds })} onChangeRoles={(Roles) => this.setFilterState({ Roles })}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Redeploy" title="Existing Targets" summary={this.state.model.Action.ShouldRedeployWhenMachineHasBeenDeployedTo
                    ? Summary.summary("Re-deploy to existing deployment targets that are already up-to-date with the current deployment")
                    : Summary.default("Do not re-deploy to existing deployment targets that are already up-to-date with the current deployment")} help="Choose whether Octopus should re-deploy to existing deployment targets that are already up-to-date with the current deployment.">
                            <Checkbox label="Re-deploy" value={this.state.model.Action.ShouldRedeployWhenMachineHasBeenDeployedTo} onChange={(ShouldRedeployWhenMachineHasBeenDeployedTo) => this.setActionState({ ShouldRedeployWhenMachineHasBeenDeployedTo })}/>
                        </ExpandableFormSection>
                        <UnstructuredFormSection>
                            <ul className={styles.callouts}>
                                <li>
                                    <Callout title="Release" type={CalloutType.Information}>
                                        The most recent successful deployment of this project for each environment
                                        <FeatureToggle feature={Feature.MultiTenancy}>
                                            {this.state.lookupData.project && this.state.lookupData.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && "/tenant combination"}
                                        </FeatureToggle>{" "}
                                        will be requeued to any machines that match the trigger criteria.
                                    </Callout>
                                </li>
                                <FeatureToggle feature={Feature.MultiTenancy}>
                                    {this.state.lookupData.project && this.state.lookupData.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && (<li>
                                            <Callout title="Tenants" type={CalloutType.Information}>
                                                If required, a deployment will be triggered for each tenant mapped to the deployment target. Learn more about multi-tenant hosting models in our{" "}
                                                <ExternalLink href="MultiTenantHostingModel">documentation</ExternalLink>.
                                            </Callout>
                                        </li>)}
                                </FeatureToggle>
                                <li>
                                    <Callout title="Steps" type={CalloutType.Information}>
                                        The goal of an automatic deployment to a deployment target is to make it the same as its counterparts in the same environment. Octopus does this by running the same steps that would be run for the deployment
                                        target as part of a manual deployment. Learn about <ExternalLink href="AutomaticDeploymentTriggers">how the deployment process is calculated for automatic deployments</ExternalLink>.
                                    </Callout>
                                </li>
                            </ul>
                        </UnstructuredFormSection>
                    </TransitionAnimation>)}
            </ProjectFormPageLayout>);
    }
    private async loadTriggerOrCreateNew(project: ProjectResource): Promise<Model> {
        if (isCreateDeploymentTriggerProps(this.props)) {
            const machineFilterResource = new MachineFilterResource();
            machineFilterResource.EnvironmentIds = [];
            machineFilterResource.Roles = [];
            machineFilterResource.EventGroups = [];
            machineFilterResource.EventCategories = [];
            return {
                ProjectId: project.Id,
                Name: "",
                Description: "",
                IsDisabled: false,
                Filter: machineFilterResource,
                Action: new AutoDeployActionResource(),
            };
        }
        else {
            return (await repository.ProjectTriggers.get(this.props.triggerId)) as Model;
        }
    }
    private setFilterState<K extends keyof MachineFilterResource>(filterState: Pick<MachineFilterResource, K>) {
        this.setChildState2("model", "Filter", filterState);
    }
    private setActionState<K extends keyof AutoDeployActionResource>(actionState: Pick<AutoDeployActionResource, K>) {
        this.setChildState2("model", "Action", actionState);
    }
    private async enableTrigger() {
        this.setChildState1("model", { IsDisabled: false }, () => this.saveProject());
    }
    private async disableTrigger() {
        this.setChildState1("model", { IsDisabled: true }, () => this.saveProject());
    }
    private async deleteTrigger(model: TriggerResource, lookupData: LookupData) {
        await this.doBusyTask(async () => {
            await repository.ProjectTriggers.del(model);
            this.setState({ redirectTo: links.deploymentTriggersPage.generateUrl({ spaceId: lookupData.project.SpaceId, projectSlug: lookupData.project.Slug }) });
        });
        return true;
    }
    private eventFilterSummary(model: Model, lookupData: LookupData) {
        if (model.Filter.EventCategories.length || model.Filter.EventGroups.length) {
            const description = new TriggerDescription(model.Filter, lookupData.environments, lookupData.eventCategories, lookupData.eventGroups);
            return Summary.summary(description.buildDescription());
        }
        return Summary.placeholder("Please select filters to apply");
    }
    private async saveProject() {
        await this.doBusyTask(async () => {
            if (!this.state.model) {
                return;
            }
            const result = await repository.ProjectTriggers.save(this.state.model);
            if (isCreateDeploymentTriggerProps(this.props)) {
                const redirectTo = result.Action.ActionType === TriggerActionType.AutoDeploy
                    ? links.editDeploymentTriggerPage.generateUrl({ spaceId: result.SpaceId, projectSlug: this.props.projectSlug, triggerId: result.Id })
                    : links.editDeploymentScheduledTriggerPage.generateUrl({ spaceId: result.SpaceId, projectSlug: this.props.projectSlug, triggerId: result.Id });
                this.setState({ redirectTo });
            }
            else {
                const typedResult = result as Model;
                this.setState({
                    model: typedResult,
                    cleanModel: cloneDeep(typedResult),
                });
            }
        });
    }
    static displayName = "DeploymentTriggerPageInternal";
}
function isCreateDeploymentTriggerProps(props: DeploymentTriggerPageProps): props is CreateDeploymentTriggerProps {
    return "create" in props && props.create;
}
export function DeploymentTriggerPage(props: DeploymentTriggerPageProps) {
    const projectContext = useProjectContext();
    return <DeploymentTriggerPageInternal projectContext={projectContext} {...props}/>;
}
