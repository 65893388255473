import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { OperationsOverviewPage } from "../Runbooks/OperationsOverviewPage";
import { EnhancedRunbookRoutes as RunbookRoutes } from "../Runbooks/RunbookRoutes";
import { MigratedRunbooksRoute } from "../Runbooks/RunbooksRoute";
import { RunbookTriggersRoute } from "../Runbooks/Triggers/RunbookTriggersRoute";
import { opsRouteLinks } from "./opsRouteLinks";
type OperationsRouteProps = {
    path: string;
} & RouteComponentProps<ProjectRouteParams>;
const OperationsOverviewPageWithPageDefinition = withPage({ page: pageIds.project().operations.root })(OperationsOverviewPage);
function OperationsRouteInternal(props: OperationsRouteProps) {
    const links = opsRouteLinks(props.path);
    return (<Switch>
            <MigratedReloadableRoute path={links.root} exact={true} render={() => <OperationsOverviewPageWithPageDefinition />}/>
            <MigratedRunbookTriggersRoute path={links.triggers}/>
            <MigratedRunbookRoutes path={links.runbook(":runbookId").root}/>
            <MigratedRunbooksRoute path={links.runbooks}/>
            <RedirectAs404 />
        </Switch>);
}
const MigratedRunbookRoutes = RunbookRoutes;
const MigratedRunbookTriggersRoute = RunbookTriggersRoute;
export const OperationsRoute = withRouter(OperationsRouteInternal);
