import React from "react";
export function HelmIcon() {
    return (<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5443 16.5259V23.9583H10.9214V20.9129H8.21819V23.9583H6.59521V16.5259H8.21819V19.5712H10.9214V16.5259H12.5443Z" fill="#1A77CA"/>
            <path d="M14.2678 23.9583V16.5259H18.6638V17.8622H15.88V19.5157H18.2715V20.852H15.88V22.6237H18.791V23.9583H14.2678Z" fill="#1A77CA"/>
            <path d="M21.8559 16.5259V22.6165H24.5913V23.9511H20.2347V16.5259H21.8559Z" fill="#1A77CA"/>
            <path d="M29.5875 20.5274L30.9758 16.5201H33.4049V23.9525H31.8553V18.4548H31.8231L30.1679 23.0676H28.8315L27.1996 18.4548H27.1351V23.9579H25.6429V16.5255H28.1347L29.5445 20.5274H29.5875Z" fill="#1A77CA"/>
            <path d="M26.5458 11.6621C26.9076 11.3441 27.2354 10.9893 27.5239 10.6034C28.4518 9.38168 28.8513 8.12414 28.4195 7.79274C27.9878 7.46134 26.8808 8.18684 25.9528 9.40497C25.6547 9.79945 25.4006 10.2253 25.1951 10.675C23.8836 9.87186 22.4092 9.37183 20.8797 9.2115C20.978 8.74019 21.0266 8.25986 21.0248 7.77841C21.0248 6.24321 20.5823 5 20.0359 5C19.4896 5 19.0471 6.24321 19.0471 7.77841C19.0445 8.25006 19.0907 8.72071 19.1851 9.18284C17.6167 9.28072 16.0921 9.73927 14.7299 10.5228C14.5361 10.145 14.3089 9.78527 14.051 9.44796C13.1231 8.22625 12.0178 7.50433 11.5843 7.83573C11.1508 8.16713 11.5485 9.42467 12.48 10.6464C12.7208 10.9667 12.9881 11.2663 13.2789 11.5421C12.4474 12.2377 11.7304 13.0599 11.1544 13.9783L12.9959 15.1248C13.7292 13.9512 14.7523 12.9862 15.9667 12.3226C17.1811 11.659 18.5459 11.3193 19.9296 11.336C21.3134 11.3527 22.6695 11.7254 23.8675 12.4182C25.0655 13.1109 26.065 14.1004 26.7697 15.2914L28.6524 14.17C28.0897 13.2261 27.3784 12.3793 26.5458 11.6621Z" fill="#1A77CA"/>
            <path d="M26.5457 28.3184C27.3783 27.6012 28.0896 26.7544 28.6523 25.8105L26.7696 24.6891C26.065 25.8802 25.0657 26.8698 23.8678 27.5627C22.6699 28.2556 21.3138 28.6285 19.93 28.6454C18.5463 28.6623 17.1815 28.3227 15.967 27.6593C14.7525 26.9959 13.7293 26.031 12.9958 24.8575L11.1417 26.0219C11.7178 26.9403 12.4347 27.7625 13.2663 28.4581C12.9754 28.7338 12.7082 29.0335 12.4673 29.3538C11.5394 30.5755 11.1399 31.8348 11.5717 32.1645C12.0034 32.4941 13.1104 31.7721 14.0384 30.5522C14.2963 30.2149 14.5235 29.8552 14.7173 29.4774C16.0794 30.2609 17.6041 30.7195 19.1724 30.8173C19.0781 31.2795 19.0319 31.7501 19.0345 32.2218C19.0345 33.757 19.4769 35.0002 20.0233 35.0002C20.5697 35.0002 21.0121 33.757 21.0121 32.2218C21.014 31.7403 20.9654 31.26 20.867 30.7887C22.4025 30.6242 23.8814 30.1173 25.195 29.3054C25.4048 29.7538 25.6631 30.1779 25.9653 30.5701C26.8932 31.7919 27.9967 32.5156 28.432 32.1824C28.8673 31.8492 28.4678 30.5934 27.5363 29.3717C27.2437 28.9871 26.9116 28.6341 26.5457 28.3184Z" fill="#1A77CA"/>
        </svg>);
}
