import { css } from "@emotion/css";
import { Button, PopoverBasic } from "@octopusdeploy/design-system-components";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import React from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { useAnalyticActionDispatch } from "~/analytics/Analytics";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
export const projectStatusPopoverType = ["CanAddDeploymentTargets", "InsufficientPermissions", "TargetDiscoveryMissingTargetRole", "TargetDiscovery"] as const;
export type ProjectStatusPopoverType = (typeof projectStatusPopoverType)[number];
export interface ProjectStatusPopoverProps extends ProjectStatusPopover {
    open: boolean;
    anchorEl: HTMLElement | null;
    eventAction: ActionEvent;
}
export interface ProjectStatusPopover {
    popoverType?: ProjectStatusPopoverType;
    unassociatedTargetRoles?: string[];
    onCustomAction?: () => void;
}
export const ProjectStatusPopover = ({ popoverType, unassociatedTargetRoles, open, anchorEl, eventAction, onCustomAction }: ProjectStatusPopoverProps): JSX.Element => {
    const dispatchAction = useAnalyticActionDispatch();
    if (!popoverType) {
        return <></>;
    }
    const onCustomActionClicked = (actionName: string) => {
        dispatchAction(actionName, eventAction);
        onCustomAction?.();
    };
    const getPopoverTitle = (popoverType: ProjectStatusPopoverType): string => {
        if (popoverType === "CanAddDeploymentTargets" || popoverType === "InsufficientPermissions") {
            return "You need a deployment target to deploy your application";
        }
        return "You have Target Discovery enabled";
    };
    const getPopoverDescription = (popoverType: ProjectStatusPopoverType): ReactNode => {
        if (popoverType === "CanAddDeploymentTargets") {
            return (<div>
                    <div>Please add your deployment target(s) with the following target role(s):</div>
                    <ul className={styles.targetRoles}>
                        {unassociatedTargetRoles?.map((role) => (<li>{role}</li>))}
                    </ul>
                    <div className={styles.infoMessage}>
                        <b>Target roles </b>connect your deployment process to your deployment targets.
                    </div>
                </div>);
        }
        if (popoverType === "InsufficientPermissions") {
            return (<Callout type={CalloutType.Danger} title={"Can\u2019t add deployment target"}>
                    You don't have permission to add a deployment target. Please ask your Octopus administrator for the required permissions.
                </Callout>);
        }
        if (popoverType === "TargetDiscoveryMissingTargetRole") {
            return (<div>
                    <div className={styles.message}>At deployment time, Octopus will dynamically create deployment targets for steps that use Target Discovery.</div>
                    <Callout type={CalloutType.Warning} title={"Target Discovery setup is incomplete"}>
                        Please make sure steps that use Target Discovery have at least one target role. If you haven’t already, please follow this <ExternalLink href="CloudTargetDiscovery">setup guide</ExternalLink> to make your targets discoverable.
                    </Callout>
                </div>);
        }
        return (<div>
                <div>
                    At deployment time, Octopus will dynamically create deployment targets for steps that use Target Discovery. If you haven’t already, please follow this <ExternalLink href="CloudTargetDiscovery">setup guide</ExternalLink> to make
                    your targets discoverable.
                </div>
                <div className={styles.action}>
                    <Button label={"I've set up Target Discovery"} onClick={() => onCustomActionClicked("Click on I've set up Target Discovery")} importance={"primary"}/>
                </div>
            </div>);
    };
    const title = getPopoverTitle(popoverType);
    const description = getPopoverDescription(popoverType);
    if (!anchorEl) {
        return <></>;
    }
    return <PopoverBasic placement={"bottom-start"} open={open} anchorEl={anchorEl} title={title} description={description} width={"wide"}/>;
};
const styles = {
    targetRoles: css({
        margin: "0",
        marginLeft: "20px",
        listStyleType: "disc",
        marginBottom: space[12],
    }),
    message: css({
        marginBottom: space[12],
    }),
    infoMessage: css({
        padding: space[12],
        borderRadius: borderRadius.medium,
        backgroundColor: themeTokens.color.popover.background.secondary,
    }),
    action: css({
        marginTop: space[12],
    }),
};
