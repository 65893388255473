import type { GetCommonTemplatesQueryParameters, GetProjectTenantVariablesQueryParameters, ValueStatus } from "@octopusdeploy/octopus-server-client";
import { ValueStatusValues } from "@octopusdeploy/octopus-server-client";
import type { ParseError } from "@octopusdeploy/portal-routes";
import { createQueryParam, numberQueryParam, parseError, stringQueryParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import { useMemo } from "react";
import { ProjectTenantVariablesTabKeys } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariables";
export type ProjectTenantVariablesFilterState = GetProjectTenantVariablesQueryParameters & GetCommonTemplatesQueryParameters & {
    activeTab: string;
    pageNumber: number;
};
export function useProjectTenantVariablesFilterState(): [
    ProjectTenantVariablesFilterState,
    (filter: ProjectTenantVariablesFilterState) => void
] {
    const activeTabParameter = stringQueryParam("activeTab");
    const searchParameter = stringQueryParam("search");
    const environmentParameter = stringQueryParam("environment");
    const excludedEnvironmentParameter = stringQueryParam("excludedEnvironment");
    const tenantParameter = stringQueryParam("tenant");
    const excludedTenantParameter = stringQueryParam("excludedTenant");
    const tagsParameter = stringQueryParam("tags");
    const excludedTagsParameter = stringQueryParam("excludedTags");
    const valueStatusParameter = createQueryParam("valueStatus", validateValueStatus, (v) => v);
    const pageNumberParameter = numberQueryParam("pageNumber");
    const templateIdParameter = stringQueryParam("templateId");
    const libraryVariableSetIdParameter = stringQueryParam("libraryVariableSetId");
    const [filter, setFilter] = useQueryStringParams([
        activeTabParameter,
        searchParameter,
        environmentParameter,
        excludedEnvironmentParameter,
        tenantParameter,
        excludedTenantParameter,
        tagsParameter,
        excludedTagsParameter,
        valueStatusParameter,
        templateIdParameter,
        pageNumberParameter,
        libraryVariableSetIdParameter,
    ]);
    function setProjectTenantVariablesFilterState(filter: ProjectTenantVariablesFilterState) {
        setFilter({
            activeTab: filter.activeTab,
            search: filter.filterByName,
            environment: filter.filterByEnvironment,
            excludedEnvironment: filter.filterByExcludedEnvironment,
            tenant: filter.filterByTenant,
            excludedTenant: filter.filterByExcludedTenant,
            tags: filter.filterByTags.length > 0 ? filter.filterByTags.join(",") : undefined,
            excludedTags: filter.filterByExcludedTags.length > 0 ? filter.filterByExcludedTags.join(",") : undefined,
            valueStatus: filter.filterByValueStatus,
            pageNumber: filter.pageNumber,
            templateId: filter.filterByTemplateId,
            libraryVariableSetId: filter.filterByLibraryVariableSetId,
        });
    }
    const projectTenantVariablesFilterState = useMemo((): ProjectTenantVariablesFilterState => ({
        activeTab: filter.activeTab ?? ProjectTenantVariablesTabKeys.ProjectTemplates,
        filterByName: filter.search,
        filterByEnvironment: filter.environment,
        filterByExcludedEnvironment: filter.excludedEnvironment,
        filterByTenant: filter.tenant,
        filterByExcludedTenant: filter.excludedTenant,
        filterByTags: filter.tags ? filter.tags.split(",") : [],
        filterByExcludedTags: filter.excludedTags ? filter.excludedTags.split(",") : [],
        filterByValueStatus: filter.valueStatus,
        filterByTemplateId: filter.templateId,
        filterByLibraryVariableSetId: filter.libraryVariableSetId,
        pageNumber: filter.pageNumber ?? 1,
    }), [filter.activeTab, filter.search, filter.environment, filter.excludedEnvironment, filter.tenant, filter.excludedTenant, filter.tags, filter.excludedTags, filter.valueStatus, filter.pageNumber, filter.templateId, filter.libraryVariableSetId]);
    return [projectTenantVariablesFilterState, setProjectTenantVariablesFilterState];
}
function validateValueStatus(valueStatus: string): ValueStatus | ParseError {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleStatus = valueStatus as ValueStatus;
    return Object.values(ValueStatusValues).includes(possibleStatus) ? possibleStatus : parseError;
}
