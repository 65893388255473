import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { KubernetesAgentConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import { required, Text } from "~/components/form/index";
import styles from "./ConfigurationDialog.module.less";
interface DataEntryPageProps {
    model: KubernetesAgentConfigurationResource;
    onModelChange: <K extends keyof KubernetesAgentConfigurationResource>(model: Pick<KubernetesAgentConfigurationResource, K>) => void;
    environments: EnvironmentResource[];
    machineRoles: string[];
    allowShowAdvanced: boolean;
    showAdvanced: boolean;
    toggleShowAdvanced: () => void;
    getFieldError: (fieldName: string) => string;
}
export function ConfigurationPage(props: DataEntryPageProps) {
    const { getFieldError } = props;
    return (<>
            <Text value={props.model.Name} helperText={ConfigurationMessages.Name.HelperText} onChange={(Name) => props.onModelChange({ Name })} label={"Name"} error={getFieldError("Name")} validate={required(ConfigurationMessages.Name.Validation.NotProvided)} autoFocus={true}/>
            <EnvironmentMultiSelect environments={props.environments} hideFloatingLabel={false} openOnFocus={false} error={getFieldError("EnvironmentIds")} onChange={(EnvironmentIds) => props.onModelChange({ EnvironmentIds })} helperText={getFieldError("EnvironmentIds") ? "" : ConfigurationMessages.EnvironmentIds.HelperText} value={props.model.EnvironmentIds}/>
            <RoleMultiSelect items={props.machineRoles} onChange={(MachineRoles) => props.onModelChange({ MachineRoles })} error={getFieldError("MachineRoles")} value={props.model.MachineRoles} canAdd={true} helperText={ConfigurationMessages.MachineRoles.HelperText} label="Roles (type to add new)"/>

            {props.allowShowAdvanced && (<>
                    <div className={styles.showAdvancedButton}>
                        <ActionButton label={props.showAdvanced ? "Hide Advanced" : "Show Advanced"} type={ActionButtonType.Ternary} onClick={(e) => {
                e.preventDefault();
                props.toggleShowAdvanced();
            }}/>
                    </div>
                    {props.showAdvanced && (<>
                            <Text value={props.model.ServerUri} onChange={(ServerUri) => props.onModelChange({ ServerUri })} error={getFieldError("ServerUri")} label="Octopus Deploy Server URL" helperText={ConfigurationMessages.ServerUri.HelperText} validate={required(ConfigurationMessages.ServerUri.Validation.NotProvided)}/>
                            <Text value={props.model.ServerCommsAddress} onChange={(ServerCommsAddress) => props.onModelChange({ ServerCommsAddress })} error={getFieldError("ServerCommsAddress")} label="Octopus Deploy Server Communications URL" helperText={ConfigurationMessages.ServerCommsAddress.HelperText} validate={required(ConfigurationMessages.ServerCommsAddress.Validation.NotProvided)}/>
                        </>)}
                </>)}
        </>);
}
