import { links, usePage } from "@octopusdeploy/portal-routes";
import * as React from "react";
import CloseButton from "~/components/Button/CloseButton";
import { useDevToolsDispatch, useDevToolsState, actions } from "~/components/DevTools/DevToolsContext";
import InternalLink from "~/components/Navigation/InternalLink";
import ExternalLink from "../Navigation/ExternalLink";
import styles from "./DevBanner.module.less";
interface DevBannerProps {
    onClose(): void;
}
const DevBanner: React.FC<DevBannerProps> = ({ onClose }) => {
    const page = usePage();
    const handleClose = React.useCallback(onClose, [onClose]);
    const dispatcher = useDevToolsDispatch();
    const stateContext = useDevToolsState();
    return (<div className={styles.devBanner}>
            <div className={styles.entries}>
                <span className={styles.entry}>Current Page: {page && page.Id}</span>
                <span className={styles.entry}>
                    <a href="#" onClick={(e) => {
            e.preventDefault();
            dispatcher?.dispatch(actions.toggleDrawer());
        }}>
                        {stateContext?.drawer.open ? (<span>
                                Close Dev Tools <pre style={{ display: "inline" }}>ctrl+shift+~</pre>
                            </span>) : (<span>
                                Open Dev Tools <pre style={{ display: "inline" }}>ctrl+shift+~</pre>
                            </span>)}
                    </a>
                </span>
                <span className={styles.entry}>
                    <ExternalLink href={"https://oc.to/design-system"}>Design System</ExternalLink>
                </span>
                <span className={styles.entry}>
                    <ExternalLink href="https://oc.to/component-library">Component Library</ExternalLink>
                </span>
                <span className={styles.entry}>
                    <InternalLink to={links.styleGuidePage.generateUrl()}>Style Guide</InternalLink>
                </span>
            </div>
            <div className={styles.closeBanner}>
                <CloseButton onClose={handleClose}/>
            </div>
        </div>);
};
DevBanner.displayName = "DevBanner"
export default DevBanner;
