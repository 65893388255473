import { css, injectGlobal } from "@emotion/css";
import { borderRadius, fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
const background = themeTokens.color.codeEditor.background;
export const codeEditorOuterContainerStyles = css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
});
export const codeEditorNoToolbarStyles = css({}); // this is just so we can reference the class name in the selector below
export const codeEditorContainerStyles = css({
    position: "relative",
    minHeight: "15rem",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderBottomLeftRadius: borderRadius.medium,
    borderBottomRightRadius: borderRadius.medium,
    transition: "0.3s",
    borderBottom: `1px solid ${themeTokens.color.border.primary}`,
    borderLeft: `1px solid ${themeTokens.color.border.primary}`,
    borderRight: `1px solid ${themeTokens.color.border.primary}`,
    flexGrow: 1,
    [`&.${codeEditorNoToolbarStyles}`]: {
        borderRadius: borderRadius.medium,
    },
});
export const codeEditorContainerFocusedStyles = css({
    minHeight: "26rem",
});
export const codeEditorToolbarStyles = css({
    border: `1px solid ${themeTokens.color.border.primary}`,
    borderTopLeftRadius: borderRadius.medium,
    borderTopRightRadius: borderRadius.medium,
    zIndex: 9,
    backgroundColor: themeTokens.color.codeEditor.toolbar.background,
    display: "flex",
    justifyContent: "space-between",
    maxHeight: "50px",
    gap: space[12],
    padding: `${space[8]} ${space[12]}`,
    "&:last-child": {
        marginRight: 0,
    },
    "@container (max-width: 664px)": {
        flexDirection: "column",
        maxHeight: "95px",
        gap: "0.5rem",
        alignItems: "flex-start",
    },
    "@container (max-width: 474px)": {
        maxHeight: "157px",
    },
});
export const codeEditorContainerInADialogStyles = css({
    [`&.${codeEditorContainerStyles}`]: {
        borderRadius: 0,
        border: 0,
    },
    [`&.${codeEditorToolbarStyles}`]: {
        borderRadius: 0,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
    },
});
export const codeEditorToolbarButtonsContainerStyles = css({
    display: "flex",
    justifyContent: "flex-end",
    gap: space["12"],
    flexDirection: "row",
    alignItems: "center",
    "&:only-child": {
        marginLeft: "auto",
        "@container (max-width: 474px)": {
            marginLeft: "unset",
        },
    },
    "@container (max-width: 474px)": {
        flexDirection: "column",
        gap: "4px",
        alignItems: "flex-start",
    },
});
export const codeEditorTitleSectionStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: space[12],
    fontSize: "18px",
    color: themeTokens.color.text.primary,
});
export const codeEditorAutocompleteNoteContainerStyles = css({
    marginTop: "0px",
});
export const codeEditorAutocompleteNoteStyles = css({
    fontSize: fontSize["medium"],
    marginBottom: "0",
});
export const codeEditorCustomDialogActionsStyles = css({
    backgroundColor: background,
    borderTop: `1px solid ${themeTokens.color.codeEditor.text.gutter}`,
    alignItems: "center",
    [`.${codeEditorAutocompleteNoteStyles}`]: {
        color: themeTokens.color.codeEditor.text.default,
    },
});
export const codeEditorFuzzySearchTooltipStyles = css({
    lineHeight: "24px",
});
export const codeEditorToolbarIconButtonStyles = css({
    "&&": {
        // increase specificity https://stackoverflow.com/a/64486501
        color: `${themeTokens.color.text.primary}`,
        opacity: 1,
        fontSize: fontSize["medium"],
        fontWeight: "normal",
        padding: "0",
        width: "auto",
        height: "2rem",
    },
    "&.MuiButton-root": {
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "transparent",
            color: `${themeTokens.color.text.primary}`,
        },
    },
    "& .MuiButton-iconSizeMedium > :first-child": {
        fontSize: fontSize["base"],
        width: "24px",
        textAlign: "center",
        justifyItems: "center",
        paddingLeft: "4px",
    },
    "& span": {
        padding: "0 !important",
    },
    "& svg": {
        "&&": {
            fill: `${themeTokens.color.text.primary}`,
            color: `${themeTokens.color.text.primary}`,
        },
        height: "24px",
        width: "24px",
    },
});
export const codeEditorToolbarButtonStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: themeTokens.color.text.primary,
    fontSize: fontSize["medium"],
    paddingLeft: space[8],
    paddingRight: space[8],
    cursor: "pointer",
    borderRadius: borderRadius.medium,
    gap: space[4],
    height: "30px",
    backgroundColor: themeTokens.color.codeEditor.toolbar.background,
    userSelect: "none",
    "&:hover": {
        backgroundColor: themeTokens.color.codeEditor.toolbar.button.hover,
    },
});
export const codeEditorToolbarSelectStyles = css({
    margin: 0,
    height: "34px",
    "& > div": {
        color: themeTokens.color.text.primary,
        fontSize: fontSize["medium"],
        borderRadius: borderRadius.medium,
        margin: "0px",
        cursor: "pointer",
    },
});
export const codeEditorCodeMirrorStyles = css({
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: fontSize["base"],
    position: "absolute",
    ".CodeMirror-fullscreen.CodeMirror-fullscreen": {
        top: 0,
        height: "100%",
    },
    ".CodeMirror.CodeMirror": {
        height: "inherit",
        fontFamily: "Consolas, Menlo, Monaco, 'Courier New', monospace",
        fontSize: "1rem",
        background: `${background}`,
        color: themeTokens.color.codeEditor.text.default,
        padding: "0.5rem 0",
    },
    ".CodeMirror pre.CodeMirror-line": {
        color: themeTokens.color.codeEditor.text.default,
    },
    ".CodeMirror-foldmarker": {
        color: themeTokens.color.text.primary,
        background: themeTokens.color.codeEditor.toolbar.background,
        fontFamily: "arial",
        lineHeight: 0.3,
        cursor: "pointer",
    },
    ".CodeMirror-foldgutter": {
        width: "0.7em",
    },
    ".CodeMirror-foldgutter-open, .CodeMirror-foldgutter-folded": {
        cursor: "pointer",
    },
    ".CodeMirror-foldgutter-open:after": {
        content: "'\\25BE'",
    },
    ".CodeMirror-foldgutter-folded:after": {
        content: "'\\25B8'",
    },
    ".CodeMirror-gutters": {
        background: background,
        color: themeTokens.color.codeEditor.text.gutter,
        border: "none",
    },
    ".CodeMirror-empty": {
        color: "#416F91 !important",
    },
    ".CodeMirror-linenumber": {
        color: themeTokens.color.codeEditor.text.gutter,
    },
    ".cm-s-default.CodeMirror": {
        backgroundColor: background,
        color: themeTokens.color.codeEditor.text.default,
    },
    ".cm-s-default .CodeMirror-gutters": {
        background: background,
        color: themeTokens.color.codeEditor.text.gutter,
        border: "none",
    },
    ".cm-s-default .CodeMirror-guttermarker, .cm-s-default .CodeMirror-guttermarker-subtle, .cm-s-default .CodeMirror-linenumber": {
        color: themeTokens.color.codeEditor.text.gutter,
    },
    ".cm-s-default": {
        "div.CodeMirror-selected": {
            background: themeTokens.color.codeEditor.code.selectedBackground,
        },
        "&.CodeMirror-focused div.CodeMirror-selected": {
            background: themeTokens.color.codeEditor.code.selectedBackground,
        },
        ".CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection": {
            background: themeTokens.color.codeEditor.code.selectedBackground,
        },
        ".CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection": {
            background: themeTokens.color.codeEditor.code.selectedBackground,
        },
        "&.CodeMirror": {
            background: background,
            color: themeTokens.color.codeEditor.text.default,
        },
        ".CodeMirror-gutters": {
            background: background,
            borderRight: 0,
        },
        ".CodeMirror-cursor": {
            borderLeft: `1px solid ${themeTokens.color.codeEditor.code.cursorBorderLeft}`,
            opacity: 0.5,
        },
        ".CodeMirror-activeline-background": {
            background: themeTokens.color.codeEditor.code.activelineBackground,
            opacity: 0.5,
        },
        ".cm-fat-cursor .CodeMirror-cursor": {
            background: themeTokens.color.codeEditor.code.fatCursorBackground,
            borderRight: `.5em solid ${themeTokens.color.codeEditor.code.cursorBorderRight}`,
            opacity: 0.5,
        },
        "span.cm-variable": {
            color: themeTokens.color.codeEditor.code.variableColor,
        },
        "span.cm-atom, span.cm-number, span.cm-keyword, span.cm-attribute, span.cm-quote, .cm-s-duotone-light-light span.cm-hr, .cm-s-duotone-light-light span.cm-link": {
            color: themeTokens.color.codeEditor.code.atomNumberKeywordVariableAttributeQuoteColor,
        },
        "span.cm-property": {
            color: themeTokens.color.codeEditor.code.propertyColor,
        },
        "span.cm-punctuation, span.cm-unit, span.cm-negative": {
            color: themeTokens.color.codeEditor.code.punctuationUnitNegativeColor,
        },
        "span.cm-string, span.cm-operator": {
            color: themeTokens.color.codeEditor.code.stringOperatorColor,
        },
        "span.cm-positive": {
            color: themeTokens.color.codeEditor.code.positiveColor,
        },
        "span.cm-variable-2, span.cm-variable-3, span.cm-string-2, span.cm-url": {
            color: themeTokens.color.codeEditor.code.variable2Variable3String2UrlColor,
        },
        "span.cm-def, span.cm-tag, span.cm-builtin, span.cm-qualifier, span.cm-header, span.cm-em": {
            color: themeTokens.color.codeEditor.code.defTagBuiltinQualifierHeaderEmColor,
        },
        "span.cm-bracket, span.cm-comment": {
            color: themeTokens.color.codeEditor.code.bracketCommentColor,
        },
        "span.cm-error, span.cm-invalidchar": {
            color: themeTokens.color.codeEditor.code.errorInvalidcharColor,
        },
        "span.cm-header": {
            fontWeight: "normal",
        },
        ".CodeMirror-matchingbracket": {
            textDecoration: "underline",
            color: themeTokens.color.codeEditor.code.matchingbracketColor,
        },
    },
});
export const codeEditorHintHighlightStyles = css({
    color: themeTokens.color.codeEditor.text.hintHighlight,
    fontWeight: "bold",
});
export const codeEditorCustomDialogStyles = css({
    "&&": {
        background: `${themeTokens.color.codeEditor.toolbar.background}`,
    },
});
injectGlobal({
    ".CodeMirror-hints": {
        "&.default": {
            zIndex: 99999,
            background: themeTokens.color.codeEditor.code.hintsBackground,
            fontFamily: "Consolas, Menlo, Monaco, 'Courier New', monospace",
        },
    },
    "li.CodeMirror-hint": {
        color: themeTokens.color.codeEditor.code.hint,
    },
    "li.CodeMirror-hint-active": {
        background: themeTokens.color.codeEditor.code.hintActiveBackground,
        color: themeTokens.color.codeEditor.code.hint,
    },
});
export const codeEditorSettingsPopoverStyles = css({
    padding: "10px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
});
export const codeEditorSettingsSwitchStyles = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
});
