import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useEffect, useState } from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticActionDispatch, Action as AnalyticsAction } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { PermissionCheck } from "~/components/PermissionCheck";
import { Text } from "~/components/form";
import { Summary } from "~/components/form/Sections/index";
import { ExpandableFormSection, FormSectionHeading } from "~/components/form/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import styles from "./style.module.less";
interface SharedGitPageProps {
    PageLayout: React.ComponentType<Level2PageLayoutProps>;
}
interface GitPageInternalProps extends SharedGitPageProps {
    dispatchAction: AnalyticActionDispatcher;
}
interface GitPageState {
    hasClearedCache: boolean;
    isResetRegistrationPromptOpen: boolean;
    hasResetRegistration: boolean;
}
class GitPageInternal extends DataBaseComponent<GitPageInternalProps, GitPageState> {
    constructor(props: GitPageInternalProps) {
        super(props);
        this.state = {
            hasClearedCache: false,
            isResetRegistrationPromptOpen: false,
            hasResetRegistration: false,
        };
    }
    private onClearCacheClick = async () => {
        this.props.dispatchAction("Clear Local Cache", { resource: "Features", action: AnalyticsAction.Clear });
        this.setState({ hasClearedCache: false });
        await this.doBusyTask(async () => {
            logger.info("Clearing local git cache");
            await repository.ServerConfiguration.clearCache();
            this.setState({ hasClearedCache: true });
        });
        return;
    };
    private onResetRegistrationClick = async () => {
        await this.doBusyTask(async () => {
            this.setState({ hasResetRegistration: false, isResetRegistrationPromptOpen: false });
            await repository.GitHubApp.resetRegistration();
            this.setState({ hasResetRegistration: true });
        });
        return;
    };
    render() {
        const PageLayout = this.props.PageLayout;
        return (<PageLayout title="Git Configuration" errors={this.errors} busy={this.state.busy}>
                <FormSectionHeading title="Git"/>
                <ExpandableFormSection errorKey={"ClearGitCache"} title={"Clear Git Cache"} summary={Summary.summary("Purge all cloned repositories and force Octopus to re-clone them the next time it needs them")} help="Octopus keeps local clones of git repositories used in version controlled projects. This will completely purge all cloned repositories and force Octopus to re-clone them the next time it needs them.">
                    {this.state.hasClearedCache && (<div className={styles.successCallout}>
                            <Callout type={CalloutType.Success} title={"Cache Cleared"}/>
                        </div>)}
                    <ActionButton type={ActionButtonType.Secondary} title="Clear Local Git Cache" label="Clear Local Cache" onClick={() => this.onClearCacheClick()} busyLabel={"Clearing..."} disabled={this.state.busy}/>
                </ExpandableFormSection>
                <FeatureToggleVisibility toggle="GitHubConnectionsFeatureToggle">
                    <PermissionCheck permission={Permission.ConfigureServer}>
                        <FormSectionHeading title="GitHub App"/>
                        <ResetGitHubAppRegistrationDialog open={this.state.isResetRegistrationPromptOpen} onResetRegistration={() => this.onResetRegistrationClick()} onClose={() => this.setState({ isResetRegistrationPromptOpen: false })}/>
                        <ExpandableFormSection errorKey={"DeleteGitHubAppRegistration"} title={"Reset Registration"} summary={Summary.summary("Reset the GitHub app registration")} help="Octopus registers a GitHub app to enable GitHub connections. This will reset the GitHub app registration and disconnect all existing connections. This is a destructive operation and will require all connections to be recreated.">
                            {this.state.hasResetRegistration && (<div className={styles.successCallout}>
                                    <Callout type={CalloutType.Success} title={"Registration reset"}/>
                                </div>)}
                            <ActionButton type={ActionButtonType.Delete} title="Reset Registration" label="Reset Registration" onClick={() => {
                this.setState({ isResetRegistrationPromptOpen: true });
            }} busyLabel={"Resetting"} disabled={this.state.busy || this.state.isResetRegistrationPromptOpen}/>
                        </ExpandableFormSection>
                    </PermissionCheck>
                </FeatureToggleVisibility>
            </PageLayout>);
    }
    static displayName = "GitPageInternal";
}
interface ResetGitHubAppRegistrationDialogProps {
    open: boolean;
    onClose(): void;
    onResetRegistration(): void;
}
function ResetGitHubAppRegistrationDialog({ open, onClose, onResetRegistration }: ResetGitHubAppRegistrationDialogProps) {
    const deleteConfirmationText = "Reset GitHub App registration";
    const [confirmationText, setConfirmationText] = useState("");
    const [confirmTextMatch, setConfirmTextMatch] = useState(false);
    useEffect(() => {
        setConfirmTextMatch(confirmationText.toLowerCase() === deleteConfirmationText.toLowerCase());
    }, [confirmationText]);
    function onCloseClick() {
        setConfirmationText("");
        onClose();
    }
    return (<DeleteDialog open={open} onClose={onCloseClick} title="Are you sure you want to reset the GitHub app registration?" deleteButtonDisabled={!confirmTextMatch} deleteButtonLabel="Reset Registation" onDeleteClick={onResetRegistration} renderContent={() => (<>
                    <Callout title="This is a destructive action" type={CalloutType.Danger}>
                        This action <strong>cannot</strong> be undone. This will break all existing GitHub app connections in all spaces. These will need to be recreated.
                    </Callout>
                    <div>
                        <p>
                            Please type in <strong>{deleteConfirmationText}</strong> to confirm.
                        </p>
                        <Text value={confirmationText} onChange={setConfirmationText}/>
                    </div>
                </>)}/>);
}
type GitPageProps = SharedGitPageProps;
// Wrap the component and inject the analytic dispatch action.
export function GitPage(props: GitPageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    return <GitPageInternal dispatchAction={dispatchAction} PageLayout={props.PageLayout}/>;
}
