import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useEffect, useState } from "react";
import { Action } from "~/analytics/Analytics";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import { OnboardingDialogLayout } from "~/components/OnboardingDialog/OnboardingDialogLayout";
import { useRequiredContext } from "~/hooks/index";
const ImageTourComplete = require("./Assets/TourComplete.svg");
export interface SampleProjectCompleteDialogProps {
    project: ProjectResource;
    open: boolean;
    onCloseDialog: () => void;
}
export function SampleProjectCompleteDialog({ project, open, onCloseDialog }: SampleProjectCompleteDialogProps) {
    const { dispatchAnalyticsEventForCurrentState } = useRequiredContext(SampleProjectTourContext);
    const navigation = useSpaceAwareNavigation();
    useEffect(() => {
        if (open) {
            dispatchAnalyticsEventForCurrentState("Sample Project Tour: Completed", Action.View, "SampleProjectTourComplete");
        }
    }, [dispatchAnalyticsEventForCurrentState, open]);
    const styles = {
        title: css({
            font: text.interface.heading.medium,
            margin: 0,
        }),
        contentPanel: css({
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }),
        content: css({
            display: "flex",
            flexDirection: "column",
            gap: space[16],
        }),
        text: css({ font: text.interface.body.default.base }),
        checklist: css({
            backgroundColor: themeTokens.color.background.secondary.default,
            padding: space[12],
            display: "flex",
            flexDirection: "column",
            gap: space[12],
        }),
    };
    const handleCloseDialog = () => {
        dispatchAnalyticsEventForCurrentState("Sample Project Tour: 'Let's go' Clicked", Action.Select, "SampleProjectTourComplete");
        onCloseDialog();
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        navigation.navigate(links.deploymentsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }));
    };
    const handleFeedbackSelection = (value: "Yes" | "No") => {
        dispatchAnalyticsEventForCurrentState(`Sample Project Tour: Helpfulness '${value}' Clicked`, Action.Select, "SampleProjectTourComplete");
    };
    return (<OnboardingDialog name="sample-project-complete-dialog" open={open} close={onCloseDialog} contentPanelHeight={600} helpPanelContent={<TourComplete />} helpPanelShowToggle={false}>
            <OnboardingDialogLayout onCloseDialog={onCloseDialog} title={<h2 className={styles.title}>Congratulations</h2>} actions={<ActionButton label={"LET'S GO"} type={ActionButtonType.Primary} onClick={handleCloseDialog}/>}>
                <div className={styles.contentPanel}>
                    <div className={styles.content}>
                        <div className={styles.text}>You now know the basics of a project. We covered:</div>
                        <div className={styles.checklist}>
                            <ListItem>Viewing your software releases</ListItem>
                            <ListItem>Progressing releases through environments</ListItem>
                            <ListItem>Building your deployment process</ListItem>
                            <ListItem>Managing variables</ListItem>
                            <ListItem>Performing operations with runbooks</ListItem>
                        </div>
                        <div className={styles.text}>It’s your turn to play around and apply what you learned. If you need any help along the way, look to the Help Sidebar on the right to watch video tutorials and read related docs.</div>
                    </div>
                    <Feedback onSelection={handleFeedbackSelection}/>
                </div>
            </OnboardingDialogLayout>
        </OnboardingDialog>);
}
function TourComplete() {
    const styles = {
        wrapper: css({
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }),
    };
    return (<div className={styles.wrapper}>
            <img src={ImageTourComplete} alt="Tour complete"/>
        </div>);
}
function ListItem({ children }: {
    children: string;
}) {
    const style = css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: space[8],
        font: text.interface.body.default.base,
    });
    return (<div className={style}>
            <CheckIcon />
            <span>{children}</span>
        </div>);
}
function CheckIcon() {
    const style = css({
        color: themeTokens.color.icon.success,
        lineHeight: "initial",
        height: 24,
        width: 24,
    });
    return <i className={`fa-solid fa-check-circle fa-lg ${style}`}></i>;
}
type FeedbackStates = "show-question" | "hidden-question" | "hidden-thankyou" | "show-thankyou";
interface FeedbackProps {
    onSelection: (value: "Yes" | "No") => void;
}
function Feedback({ onSelection }: FeedbackProps) {
    const [contentState, setContentState] = useState<FeedbackStates>("show-question");
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: space[8],
            padding: space[12],
            height: 92,
            transition: "opacity 300ms",
            transitionTimingFunction: "ease-in-out",
        }),
        text: css({
            font: text.interface.body.default.medium,
            color: themeTokens.color.text.muted,
            textAlign: "center",
        }),
        buttonGroup: css({
            display: "flex",
            flexDirection: "row",
            gap: space[8],
            justifyContent: "center",
        }),
    };
    const nextState = (currentState: FeedbackStates) => {
        switch (currentState) {
            case "show-question":
                return "hidden-question";
            case "hidden-question":
                return "hidden-thankyou";
            case "hidden-thankyou":
                return "show-thankyou";
            default:
                return undefined;
        }
    };
    const animate = (state: FeedbackStates) => {
        const next = nextState(state);
        if (next !== undefined) {
            setContentState(next);
            setTimeout(() => animate(next), 300);
        }
    };
    const handleSelection = (value: "Yes" | "No") => {
        onSelection(value);
        animate(contentState);
    };
    const showQuestion = contentState === "show-question" || contentState === "hidden-question";
    const showThankyou = contentState === "hidden-thankyou" || contentState === "show-thankyou";
    const hideContent = () => contentState === "hidden-question" || contentState === "hidden-thankyou";
    return (<>
            {showQuestion && (<div className={styles.container} style={hideContent() ? { opacity: 0 } : {}}>
                    <div className={styles.text}>Have you learnt enough to try creating your own project?</div>
                    <div className={styles.buttonGroup}>
                        <ActionButton label="YES" type={ActionButtonType.Secondary} onClick={() => handleSelection("Yes")}></ActionButton>
                        <ActionButton label="NO" type={ActionButtonType.Secondary} onClick={() => handleSelection("No")}></ActionButton>
                    </div>
                </div>)}

            {showThankyou && (<div className={styles.container} style={hideContent() ? { opacity: 0 } : {}}>
                    <div className={styles.text}>Thank you for your feedback.</div>
                </div>)}
        </>);
}
