/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import PaperLayout from "~/components/PaperLayout";
import routeLinks from "~/routeLinks";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface OverviewFilterRedirectState extends DataBaseComponentState {
    redirectTo?: LinkHref;
}
interface RedirectFromScopedRoleProps {
    scopedRoleId: string;
}
export class RedirectFromScopedRole extends DataBaseComponent<RedirectFromScopedRoleProps, OverviewFilterRedirectState> {
    constructor(props: RedirectFromScopedRoleProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const id = this.props.scopedRoleId;
            const scopedUserRole = await repository.ScopedUserRoles.get(id);
            const redirectTo = routeLinks.forSpace(scopedUserRole.SpaceId).configuration.team(scopedUserRole.TeamId);
            this.setState({ redirectTo });
        });
    }
    render() {
        const to = this.state.redirectTo;
        if (!to) {
            // This page redirects to a specific team edit page. While this is calculating, we show this UI.
            // We want the UI to transition smoothly from here to the team page, so this UI mimics what is initially displayed when the team is loading.
            // The `TeamEdit` page uses a `FormPaperLayout` which does not extend to 100% of the available height because it is nested inside some divs.
            // We add this outer div to get the same behaviour.
            return (<div className={containerStyles}>
                    <PaperLayout busy={this.state.busy} title={StringHelper.ellipsis} errors={this.errors} breadcrumbsItems={[{ label: "Teams", pageUrl: links.teamsPage.generateUrl() }]}/>
                </div>);
        }
        return <InternalRedirect push={false} to={to}/>;
    }
    static displayName = "RedirectFromScopedRole";
}
const containerStyles = css({
    width: "100%",
});
