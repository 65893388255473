import { useRouteMatch } from "react-router";
import { useUIOnlyFeatureFlag } from "~/hooks/useUIOnlyFeatureFlag";
import { isOptionalFeatureToggleEnabled } from "../FeatureToggle/New/FeatureToggleContext";
export function useIsPageHeaderVNextEnabled(): boolean {
    const [isPageHeaderVNextFeatureFlagEnabled] = usePageHeaderVNextFeatureFlag();
    return isOptionalFeatureToggleEnabled("PageHeaderUpliftFeatureToggle") === true || isPageHeaderVNextFeatureFlagEnabled;
}
export function usePageHeaderVNextFeatureFlag() {
    return useUIOnlyFeatureFlag("Page Header Next", false);
}
export function useIsPageHeaderVNextEnabledOutsideOfProjects(): boolean {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const [isPageHeaderVNextEnabledOutsideOfProjects] = useIsPageHeaderVNextAppliedOutsideOfProjectsFeature();
    return isPageHeaderVNextEnabled && (isOptionalFeatureToggleEnabled("PageHeaderUpliftEverywhereFeatureToggle") === true || isPageHeaderVNextEnabledOutsideOfProjects);
}
export function useIsPageHeaderVNextAppliedOutsideOfProjectsFeature() {
    return useUIOnlyFeatureFlag("Page Header Next outside of projects", false);
}
export function useIsProjectsPage() {
    const match = useRouteMatch();
    // Legacy routes include the space regex in the route
    // Routes in the new routing infrastructure don't use the regex
    return (match?.path.startsWith("/:spaceId/projects") || match?.path.startsWith("/:spaceId(Spaces-[0-9]+)?/projects")) ?? false;
}
