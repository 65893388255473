import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { repository } from "~/clientInstance";
export function GitHubAppControls() {
    const [busy, setBusy] = React.useState(false);
    const styles = {
        container: css({
            margin: space[16],
        }),
    };
    async function DeauthorizeCurrentUser() {
        setBusy(true);
        await repository.GitHubApp.deleteToken();
        setBusy(false);
    }
    return (<div className={styles.container}>
            <Button disabled={busy} label="Deauthorize Current User" onClick={DeauthorizeCurrentUser} importance="destructive"/>
        </div>);
}
