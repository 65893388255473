/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { AccountProps } from "~/areas/infrastructure/components/AccountDisplay";
import AccountDisplay from "~/areas/infrastructure/components/AccountDisplay";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import styles from "./style.module.less";
type AccountName = string;
interface ReadonlyAccountProps {
    account: AccountName | AccountProps | undefined;
    accountId: string;
    accountName?: string;
    className?: string;
}
// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyAccount: React.SFC<ReadonlyAccountProps> = ({ className, accountId, account, accountName }) => {
    const displayedAccountName = isAccountName(account) ? account : account?.name ?? accountId;
    return (<div className={styles.container}>
            <div className={`${className} ${styles.content}`} title={displayedAccountName}>
                <InternalLink to={links.editInfrastructureAccountPage.generateUrl({ spaceId: repository.spaceId!, accountId })}>{displayedAccountName}</InternalLink>
            </div>
        </div>);
};
ReadonlyAccount.displayName = "ReadonlyAccount"
interface ReadonlyAccountDisplayProps {
    accountId: string;
    accountName?: string;
}
function ReadonlyAccountDisplay({ accountId, accountName }: ReadonlyAccountDisplayProps) {
    if (accountName) {
        return <ReadonlyAccount account={accountName} accountId={accountId}/>;
    }
    return <AccountDisplay accountId={accountId} render={({ account }) => <ReadonlyAccount account={account} accountId={accountId}/>}/>;
}
function isAccountName(account: AccountName | AccountProps | undefined): account is AccountName {
    return typeof account === "string";
}
export default ReadonlyAccountDisplay;
