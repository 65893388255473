/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ChannelResource, NewTriggerResourceTyped, ProjectResource, ScopedDeploymentActionResource, TriggerResource, TriggerResourceTyped, DeploymentProcessResource, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import { TriggerFeedFilterResource, CreateReleaseActionResource, isExistingTriggerResource, Permission, TriggerActionCategory, TriggerFilterType, displayName } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { ProjectFormPageLayout } from "app/areas/projects/components/ProjectFormPageLayout";
import _, { cloneDeep } from "lodash";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import { DeploymentActionPackageChip, MissingChip, ChipIcon } from "~/components/Chips";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent";
import Lookup from "~/components/Lookup";
import DeploymentActionPackageMultiSelect, { DeploymentActionPackageReferenceDataItem } from "~/components/MultiSelect/DeploymentActionPackageMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { ExpandableFormSection, ExpansionButtons, FormSectionHeading, MarkdownEditor, Note, required, Select, Summary, Text, UnstructuredFormSection } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import StringHelper from "~/utils/StringHelper/StringHelper";
type Model = TriggerResourceTyped<TriggerFeedFilterResource, CreateReleaseActionResource> | NewTriggerResourceTyped<TriggerFeedFilterResource, CreateReleaseActionResource>;
interface EditState extends OptionalFormBaseComponentState<Model> {
    channels: ChannelResource[];
    project: ProjectResource;
    channelId: string;
    redirectTo?: LinkHref;
    filterType: TriggerFilterType;
    actionPackages: {
        DeploymentAction: string;
        PackageReference: string;
    }[];
    deploymentProcess: DeploymentProcessResource;
}
export type EditReleaseTriggersInternalProps = EditReleaseCreationFeedTriggerProps & WithProjectContextInjectedProps;
class EditReleaseTriggersInternalPage extends FormBaseComponent<EditReleaseTriggersInternalProps, EditState, Model> {
    public static defaultProps: Partial<EditReleaseTriggersInternalProps> = {
        triggerActionCategory: TriggerActionCategory.Release,
    };
    channelNameMap: {
        [id: string]: ChannelResource;
    } = {};
    constructor(props: EditReleaseTriggersInternalProps) {
        super(props);
        this.state = {
            model: null!,
            cleanModel: null!,
            channels: [],
            channelId: null!,
            project: null!,
            filterType: null!,
            actionPackages: null!,
            deploymentProcess: null!,
        };
    }
    async componentDidMount() {
        let newTrigger: NewTriggerResourceTyped<TriggerFeedFilterResource, CreateReleaseActionResource> = null!;
        const { model: project } = this.props.projectContext.state;
        if (this.props.create) {
            const triggerFeedResource = new TriggerFeedFilterResource([]);
            const triggerActionResource = new CreateReleaseActionResource();
            newTrigger = {
                ProjectId: project.Id,
                Name: null!,
                Description: null!,
                IsDisabled: false,
                Filter: triggerFeedResource,
                Action: triggerActionResource,
            };
        }
        await this.doBusyTask(async () => {
            const { projectContextRepository } = this.props.projectContext.state;
            let channels: ChannelResource[] = [];
            const trigger: TriggerResource | undefined = isEditingExistingReleaseTrigger(this.props) ? await repository.ProjectTriggers.get(this.props.triggerId) : undefined;
            if (isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })) {
                channels = await this.loadChannels(project);
                channels.forEach((channel) => {
                    this.channelNameMap[channel.Id] = channel;
                });
                if (!trigger) {
                    newTrigger.Action.ChannelId = channels.at(0)?.Id ?? "";
                }
            }
            const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();
            const actionPackages = _.chain(deploymentProcess.Steps)
                .flatMap((step) => step.Actions)
                .flatMap((action) => _.chain(action.Packages)
                // TODO: Filter on container feeds
                // .filter((pkg) => pkg.FeedId === builtInFeed.Id)
                .map((pkg) => ({ DeploymentAction: action.Name, PackageReference: pkg.Name! }))
                .value())
                .value();
            this.setState({
                model: newTrigger || trigger,
                channels,
                project,
                cleanModel: cloneDeep(newTrigger || trigger),
                filterType: trigger?.Filter.FilterType ?? TriggerFilterType.FeedFilter,
                deploymentProcess,
                actionPackages,
            });
        });
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const title = this.props.create ? "New Release Trigger" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;
        const overFlowActions = [];
        if (this.state.model && isExistingTriggerResource(this.state.model)) {
            const model: TriggerResource = this.state.model;
            overFlowActions.push(OverflowMenuItems.item(this.state.model && this.state.model.IsDisabled ? "Enable" : "Disable", () => (this.state.model && this.state.model.IsDisabled ? this.enableTrigger() : this.disableTrigger()), {
                permission: Permission.TriggerEdit,
                project: this.state.project && this.state.project.Id,
            }));
            overFlowActions.push(OverflowMenuItems.deleteItemDefault("Release trigger", () => this.deleteTrigger(model), { permission: Permission.TriggerDelete, project: this.state.project && this.state.project.Id }));
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const saveText: string = this.props.create ? "Release trigger created" : "Release trigger details updated";
        const breadcrumbTitle = "Release Triggers";
        const breadcrumbPath = links.releaseTriggersPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug });
        const pageActions: PageAction[] = [];
        return (<ProjectFormPageLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{
                permission: this.props.create ? Permission.TriggerCreate : Permission.TriggerEdit,
                project: this.state.project && this.state.project.Id,
            }} onSaveClick={() => this.saveTrigger()} overFlowActions={overFlowActions} hideExpandAll={true} saveText={saveText} pageActions={pageActions}>
                {this.state.model && (<TransitionAnimation>
                        <ExpansionButtons errors={this.errors?.fieldErrors} expandAllOnMount={this.props.create}/>
                        {this.state.cleanModel && this.state.cleanModel.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                                <Callout type={CalloutType.Warning} title={"This trigger is currently disabled"}/>
                            </UnstructuredFormSection>)}
                        <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your release trigger")} help="Enter a name for your release trigger.">
                            <Text value={this.state.model.Name || ""} onChange={(Name) => this.setModelState({ Name })} label="Release trigger name" validate={required("Please enter a release trigger name")} error={this.getFieldError("Name")} autoFocus={true}/>
                            <Note>
                                A short, memorable, unique name for this release trigger. Example: <i>Create a development release whenever a pre-release package is pushed to my docker feed</i>
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Description" title="Description" summary={this.state.model.Description ? Summary.summary(this.state.model.Description) : Summary.placeholder("Please enter a description for your trigger")} help="Enter a description for your trigger.">
                            <MarkdownEditor label="Trigger description" value={this.state.model.Description} onChange={(Description) => this.setModelState({ Description })}/>
                        </ExpandableFormSection>
                        <FormSectionHeading title="Trigger Action"/>
                        {this.state.channels.length > 1 && (<ExpandableFormSection errorKey="Channel" title="Channel" focusOnExpandAll summary={this.buildChannelSummary()} help={"Select the channel to use when selecting packages for the release"}>
                                <Select allowClear={true} items={this.state.channels.map((c) => ({ text: c.Name, value: c.Id }))} value={(this.state.model.Action as CreateReleaseActionResource).ChannelId} onChange={async (channelId) => await this.onChannelChange({ ChannelId: channelId! })}/>
                                <Note>The release created will use the latest packages available for the specified channel.</Note>
                            </ExpandableFormSection>)}
                        {this.state.actionPackages && (<ExpandableFormSection errorKey="Packages" title="Packages" focusOnExpandAll summary={Summary.summary(`${this.state.model.Filter.Packages.length} packages will be watched for updates`)} help="Select steps/packages to watch for updates">
                                <DeploymentActionPackageMultiSelect label="Package(s)" items={this.state.actionPackages.map((x) => new DeploymentActionPackageReferenceDataItem(x, displayName(x)))} value={this.state.model.Filter.Packages.map((x) => displayName({
                        DeploymentAction: x.DeploymentAction,
                        PackageReference: x.PackageReference,
                    }))} onChange={(actionPackageIndexes) => {
                        const packages = this.state.actionPackages.filter((pkg) => actionPackageIndexes.includes(displayName(pkg)));
                        this.setModelState({ Filter: new TriggerFeedFilterResource(packages) });
                    }} openOnFocus={false}/>
                            </ExpandableFormSection>)}
                    </TransitionAnimation>)}
            </ProjectFormPageLayout>);
    }
    chipRenderer = (r: DeploymentActionPackageReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={this.state.actionPackages.map((x) => new DeploymentActionPackageReferenceDataItem(x, displayName(x)))} lookupId={r.Id} getIdFromElement={(element: ReferenceDataItem) => element.Id} render={(item: ReferenceDataItem) => {
                const deploymentActionPackageReferenceItem = item as DeploymentActionPackageReferenceDataItem;
                return <DeploymentActionPackageChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} actionPackage={deploymentActionPackageReferenceItem.ActionPackage}/>;
            }} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.StepAction} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    private async loadChannels(project: ProjectResource): Promise<ChannelResource[]> {
        return await this.loadChannelsFromProject(project);
    }
    private async loadChannelsFromProject(project: ProjectResource): Promise<ChannelResource[]> {
        const channelResourceCollection = await repository.Projects.getChannels(project);
        return channelResourceCollection.Items;
    }
    private getChannelNameGivenId = (channelId: string) => {
        const channel = this.channelNameMap[channelId];
        if (channel) {
            return channel.Name;
        }
        return channelId;
    };
    private buildChannelSummary = () => Summary.summary(<span>
                Latest packages in channel <strong>{this.getChannelNameGivenId((this.state.model!.Action as CreateReleaseActionResource).ChannelId)}</strong> will be used to create the release
            </span>);
    private onChannelChange = async <K extends keyof ScopedDeploymentActionResource>(state: Pick<ScopedDeploymentActionResource, K>, callback?: () => void) => {
        this.setChildState2("model", "Action", state, callback);
    };
    private async saveTrigger() {
        await this.doBusyTask(async () => {
            const result = (await repository.ProjectTriggers.save(this.state.model!)) as TriggerResourceTyped<TriggerFeedFilterResource, ScopedDeploymentActionResource>;
            if (this.props.create) {
                const redirectTo = links.editReleaseCreationFeedTriggerPage.generateUrl({ spaceId: result.SpaceId, projectSlug: this.props.projectSlug, triggerId: result.Id });
                this.setState({ redirectTo });
            }
            else {
                this.setState({
                    model: result,
                    cleanModel: cloneDeep(result),
                });
            }
        });
    }
    private async enableTrigger() {
        this.setChildState1("model", { IsDisabled: false }, () => this.saveTrigger());
    }
    private async disableTrigger() {
        this.setChildState1("model", { IsDisabled: true }, () => this.saveTrigger());
    }
    private async deleteTrigger(model: TriggerResource) {
        await this.doBusyTask(async () => {
            await repository.ProjectTriggers.del(model);
            const redirectTo = links.deploymentTriggersPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug });
            this.setState({ redirectTo });
        });
        return true;
    }
    static displayName = "EditReleaseTriggersInternalPage";
}
interface SharedProps {
    spaceId: string;
    projectSlug: string;
    triggerActionCategory: TriggerActionCategory.Release;
}
interface EditProps extends SharedProps {
    create: false;
    triggerId: string;
}
interface CreateProps extends SharedProps {
    create: true;
}
type EditReleaseCreationFeedTriggerProps = EditProps | CreateProps;
export function EditReleaseCreationFeedTrigger(props: EditReleaseCreationFeedTriggerProps) {
    const projectContext = useProjectContext();
    return <EditReleaseTriggersInternalPage projectContext={projectContext} {...props}/>;
}
function isEditingExistingReleaseTrigger(props: EditReleaseCreationFeedTriggerProps): props is EditProps {
    return !props.create;
}
export default EditReleaseCreationFeedTrigger;
