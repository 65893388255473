/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TagSetResource, TagResource, MultiTenancyStatusResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { sortBy } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import Markdown from "~/components/Markdown";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { ExpandableFormSection, Summary } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { required } from "~/components/form/Validators";
import { Callout } from "~/primitiveComponents/dataDisplay/Callout";
import { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Text from "~/primitiveComponents/form/Text/Text";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import Tag from "../../../../components/Tag/Tag";
import { configurationActions } from "../../../configuration/reducers/configurationArea";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import TagListEdit from "./TagListEdit/TagListEdit";
import type TagModel from "./TagListEdit/TagModel";
import styles from "./style.module.less";
interface TagSetEditModel {
    name: string;
    description: string;
    tags: TagModel[];
}
interface CreateTagSetPageProps {
    create: true;
}
interface SpecificTagSetPageProps {
    tagSetId: string;
}
type TagSetPageProps = (CreateTagSetPageProps | SpecificTagSetPageProps) & {
    spaceId: string;
};
type TagSetPageInternalProps = TagSetPageProps & {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
};
interface TagSetEditState extends OptionalFormBaseComponentState<TagSetEditModel> {
    tagSet: TagSetResource;
    deleted: boolean;
    newId?: string;
    currentTag: Partial<TagModel>;
}
class TagSetPageInternal extends FormBaseComponent<TagSetPageInternalProps, TagSetEditState, TagSetEditModel> {
    private tagListEdit: TagListEdit | null = undefined!;
    constructor(props: TagSetPageInternalProps) {
        super(props);
        this.state = {
            tagSet: null!,
            model: null!,
            cleanModel: null!,
            deleted: false,
            currentTag: null!,
        };
    }
    async componentDidMount() {
        if (this.state.deleted) {
            return;
        }
        await this.doBusyTask(async () => {
            const tagSet = isSpecificTagSetPageProps(this.props) ? await repository.TagSets.get(this.props.tagSetId) : null!;
            this.setState({ tagSet, model: this.buildModel(tagSet!), cleanModel: this.buildModel(tagSet!) });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    buildModel(tagSet: TagSetResource): TagSetEditModel {
        if (tagSet) {
            return {
                name: tagSet.Name,
                description: tagSet.Description,
                tags: tagSet.Tags.map((t) => ({
                    originalId: t.Id,
                    localId: t.Id || Math.random() + "",
                    name: t.Name,
                    description: t.Description,
                    sortOrder: t.SortOrder,
                    color: t.Color,
                    deleted: false,
                })),
            };
        }
        return {
            name: "",
            description: "",
            tags: [],
        };
    }
    handleDeleteConfirm = async () => {
        const result = await repository.TagSets.del(this.state.tagSet);
        const status = await repository.Tenants.status();
        this.props.onSpaceMultiTenancyStatusFetched(status);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null,
                deleted: true,
            };
        });
        return true;
    };
    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            //the child component actually has a more up to date view of the world than
            //we do, especially when a user has partially added an item and forgotten
            //to hit save. This is a... _less than ideal_ way around the problem.
            //Thes components could do with a refactor
            const tags = this.tagListEdit ? this.tagListEdit.getTagSetIncludingAnyNewTag() : this.state.model!.tags;
            let tagSet: TagSetResource = {
                ...this.state.tagSet,
                Name: this.state.model!.name,
                Description: this.state.model!.description,
                Tags: tags
                    .filter((t) => !t.deleted)
                    .map((tag) => ({
                    Id: tag.originalId,
                    Name: tag.name,
                    SortOrder: tag.sortOrder,
                    Color: tag.color,
                    Description: tag.description,
                    CanonicalTagName: null!,
                })),
            };
            tagSet = await repository.TagSets.save(tagSet);
            this.setState({
                tagSet,
                model: this.buildModel(tagSet),
                cleanModel: this.buildModel(tagSet),
                newId: isSpecificTagSetPageProps(this.props) ? null! : tagSet.Id,
            });
        });
    };
    descriptionSummary() {
        return this.state.model!.description ? Summary.summary(<Markdown markup={this.state.model!.description}/>) : Summary.placeholder("No description provided");
    }
    nameSummary() {
        return this.state.model!.name ? Summary.summary(this.state.model!.name) : Summary.placeholder("Please enter a name for your tag set");
    }
    tagsSummary() {
        return this.state.model!.tags && this.state.model!.tags.length > 0
            ? Summary.summary(<div>
                      {sortBy(this.state.model!.tags, (tag) => tag.sortOrder)
                    .map((tag) => this.tagModelToResource(tag))
                    .map((tag, i) => (<Tag tagName={tag.Name} description={tag.Description} tagColor={tag.Color} key={i}/>))}
                  </div>)
            : Summary.placeholder("No tags have been provided");
    }
    tagModelToResource(tag: TagModel): TagResource {
        return {
            Id: tag.originalId,
            Name: tag.name,
            SortOrder: tag.sortOrder,
            Color: tag.color,
            Description: tag.description,
            CanonicalTagName: null!,
        };
    }
    shouldDisableFormSaveButton() {
        return !this.state.model?.name;
    }
    render() {
        const isSpecificTagSet = isSpecificTagSetPageProps(this.props);
        const title = !isSpecificTagSet ? "New Tenant Tag Set" : this.state.model ? this.state.model.name : StringHelper.ellipsis;
        const overFlowActions = isSpecificTagSetPageProps(this.props)
            ? [
                OverflowMenuItems.deleteItemDefault("tag set", this.handleDeleteConfirm, { permission: Permission.TagSetDelete }),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.props.tagSetId] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <FormPaperLayout title={title} breadcrumbTitle={"Tenant Tag Sets"} breadcrumbPath={links.tagSetsPage.generateUrl({ spaceId: this.props.spaceId })} saveText="Tag Set details changed" busy={this.state.busy} model={this.state.model} errors={this.errors} cleanModel={this.state.cleanModel} savePermission={{ permission: !isSpecificTagSet ? Permission.TagSetCreate : Permission.TagSetEdit }} onSaveClick={this.handleSaveClick} expandAllOnMount={!isSpecificTagSet} overFlowActions={overFlowActions} forceDisableFormSaveButton={this.shouldDisableFormSaveButton()}>
                    {this.state.deleted && <InternalRedirect to={links.tagSetsPage.generateUrl({ spaceId: this.props.spaceId })}/>}
                    {this.state.newId && <InternalRedirect to={links.editTagSetPage.generateUrl({ spaceId: this.props.spaceId, tagSetId: this.state.newId })}/>}
                    {this.state.model && (<div className={styles.expanderContainer}>
                            <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.nameSummary()} help="A short, memorable, unique name for this tag set. Examples: Customer Type, Hosting, Module.">
                                <Text value={this.state.model.name} onChange={(name) => this.setModelState({ name })} label="Tag Set name" validate={required("Please enter a Tag Set name")} autoFocus={true}/>
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="This summary will be presented to users when tagging the tenant, or applying tags to other entities.">
                                <MarkdownEditor value={this.state.model.description} label="Tag set description" onChange={(description) => this.setModelState({ description })}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="tags" title="Tags" summary={this.tagsSummary()} help={!!this.state.model.tags.find((t) => t.deleted && !!t.originalId) ? (<Callout type={CalloutType.Warning} title={"In-use tags cannot be deleted"}>
                                            This action will fail if the tag is already in use.
                                        </Callout>) : (<div>&nbsp;</div>)}>
                                <div>
                                    <TagListEdit tags={this.state.model.tags} currentTag={this.state.currentTag} onChange={(tags) => {
                    this.setModelState({ tags: [...tags] });
                }} onClose={(x) => {
                    this.setState({ currentTag: x });
                }} ref={(tagListEdit) => (this.tagListEdit = tagListEdit)}/>
                                </div>
                            </ExpandableFormSection>
                        </div>)}
                </FormPaperLayout>
            </LibraryLayout>);
    }
    static displayName = "TagSetPageInternal";
}
function isSpecificTagSetPageProps(props: CreateTagSetPageProps | SpecificTagSetPageProps): props is SpecificTagSetPageProps {
    return "tagSetId" in props;
}
export function TagSetPage(props: TagSetPageProps) {
    const dispatch = useDispatch();
    const onSpaceMultiTenancyStatusFetched = React.useCallback((status: MultiTenancyStatusResource) => dispatch(configurationActions.spaceMultiTenancyStatusFetched(status)), [dispatch]);
    return <TagSetPageInternal onSpaceMultiTenancyStatusFetched={onSpaceMultiTenancyStatusFetched} {...props}/>;
}
