import { AccountType, SettingsIdValues, TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import type { ProjectInsightsFilterQueryParams } from "@octopusdeploy/portal-routes";
import { allRoutes, generateQueryString, insightsCadenceParam, insightsSplitParam, insightsTenantTagSetIdParam, projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, projectInsightsTenantIdParam } from "@octopusdeploy/portal-routes";
import Projects from "app/areas/projects/components/Projects/Projects";
import * as React from "react";
import { AuditArchivePage } from "~/areas/configuration/components/AuditLayout/AuditArchivePage";
import type { LegacyAuditQuery } from "~/areas/configuration/components/AuditLayout/AuditPage";
import { AuditPage } from "~/areas/configuration/components/AuditLayout/AuditPage";
import { BackupPage } from "~/areas/configuration/components/Backup/BackupPage";
import DeploymentFreezesListPage from "~/areas/configuration/components/DeploymentFreezes/DeploymentFreezesListPage";
import { DetailedServerLogsPage } from "~/areas/configuration/components/Diagnostics/DetailedServerLogsPage";
import { DiagnosticsPage } from "~/areas/configuration/components/Diagnostics/DiagnosticsPage";
import { AutoDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/AutoDeployLogsPage";
import { ScheduledDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/ScheduledDeployLogsPage";
import { SubscriptionLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/SubscriptionLogsPage";
import { ExtensionsPage } from "~/areas/configuration/components/Extensions/ExtensionsPage";
import { FeaturesPage } from "~/areas/configuration/components/FeaturesLayout/FeaturesPage";
import { GitPage } from "~/areas/configuration/components/Git/GitPage";
import { ConfigureLetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/ConfigureLetsEncryptPage";
import { LetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/LetsEncryptPage";
import { LicensePage } from "~/areas/configuration/components/License/LicensePage";
import { MaintenancePage } from "~/areas/configuration/components/Maintenance/MaintenancePage";
import { NodesPage } from "~/areas/configuration/components/Nodes/NodesPage";
import { ServerSettingsPage } from "~/areas/configuration/components/Nodes/ServerSettingsPage";
import { PerformancePage } from "~/areas/configuration/components/Performance/PerformancePage";
import { UserRolePage } from "~/areas/configuration/components/Roles/UserRolePage";
import { UserRolesPage } from "~/areas/configuration/components/Roles/UserRolesPage";
import { ActiveDirectorySettingsPage } from "~/areas/configuration/components/Settings/ActiveDirectorySettingsPage";
import { ArchiveLimitsSettingsPage } from "~/areas/configuration/components/Settings/ArchiveLimitsSettingsPage";
import { AuthenticationSettingsPage } from "~/areas/configuration/components/Settings/AuthenticationSettingsPage";
import { AzureADSettingsPage } from "~/areas/configuration/components/Settings/AzureADSettingsPage";
import { AzureDevOpsIssueTrackerSettingsPage } from "~/areas/configuration/components/Settings/AzureDevOpsIssueTrackerSettingsPage";
import { EventRetentionSettingsPage } from "~/areas/configuration/components/Settings/EventRetentionSettingsPage";
import { GithubIssueTrackerSettingsPage } from "~/areas/configuration/components/Settings/GithubIssueTrackerSettingsPage";
import { GoogleAppsSettingsPage } from "~/areas/configuration/components/Settings/GoogleAppsSettingsPage";
import { GuestLoginSettingsPage } from "~/areas/configuration/components/Settings/GuestLoginSettingsPage";
import { JiraIntegrationSettingsPage } from "~/areas/configuration/components/Settings/JiraIntegrationSettingsPage";
import { JiraServiceManagementIntegrationSettingsPage } from "~/areas/configuration/components/Settings/JiraServiceManagementIntegrationSettingsPage";
import { LDAPSettingsPage } from "~/areas/configuration/components/Settings/LDAPSettingsPage";
import { OctopusIdSettingsPage } from "~/areas/configuration/components/Settings/OctopusIdSettingsPage";
import { OktaSettingsPage } from "~/areas/configuration/components/Settings/OktaSettingsPage";
import { RetentionPoliciesSettingsPage } from "~/areas/configuration/components/Settings/RetentionPoliciesSettingsPage";
import { ServerFoldersSettingsPage } from "~/areas/configuration/components/Settings/ServerFoldersSettingsPage";
import { ServiceNowIntegrationSettingsPage } from "~/areas/configuration/components/Settings/ServiceNowIntegrationSettingsPage";
import { SettingsListPage } from "~/areas/configuration/components/Settings/SettingsListPage";
import { UpdatesSettingsPage } from "~/areas/configuration/components/Settings/UpdatesSettingsPage";
import { UsernamePasswordSettingsPage } from "~/areas/configuration/components/Settings/UsernamePasswordSettingsPage";
import { WebPortalSettingsPage } from "~/areas/configuration/components/Settings/WebPortalSettingsPage";
import { WebRequestProxySettingsPage } from "~/areas/configuration/components/Settings/WebRequestProxySettingsPage";
import { SigningKeysPage } from "~/areas/configuration/components/SigningKeys/SigningKeysPage";
import { SmtpPage } from "~/areas/configuration/components/Smtp/SmtpPage";
import { EditSpacePage } from "~/areas/configuration/components/Spaces/EditSpacePage";
import { SpacesPage } from "~/areas/configuration/components/Spaces/SpacesPage";
import { SubscriptionPage } from "~/areas/configuration/components/Subscription/SubscriptionPage";
import { SubscriptionsPage } from "~/areas/configuration/components/Subscriptions/SubscriptionsPage";
import { EditTeamPage } from "~/areas/configuration/components/Teams/EditTeamPage";
import { TeamsPage } from "~/areas/configuration/components/Teams/TeamsPage";
import { TelemetryPage } from "~/areas/configuration/components/Telemetry/TelemetryPage";
import { TestPermissionsPage } from "~/areas/configuration/components/TestPermissions/TestPermissionsPage";
import { ThumbprintPage } from "~/areas/configuration/components/Thumbprint/ThumbprintPage";
import { UserInvitePage } from "~/areas/configuration/components/Users/UserInvitePage";
import { CreateUserPage, EditUserPage } from "~/areas/configuration/components/Users/UserPage";
import { UsersPage } from "~/areas/configuration/components/Users/UsersPage";
import { DashboardConfigurationPage } from "~/areas/dashboard/DashboardConfiguration/DashboardConfigurationPage";
import { DashboardOverviewPage } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import { AccountPage } from "~/areas/infrastructure/components/AccountEdit/AccountPage";
import { AccountsPage } from "~/areas/infrastructure/components/AccountsLayout/AccountsPage";
import { DeploymentTargetSettingsPage } from "~/areas/infrastructure/components/DeploymentTargetSettings/DeploymentTargetSettingsPage";
import { DynamicEnvironmentPage } from "~/areas/infrastructure/components/DynamicEnvironmentLayout/DynamicEnvironmentPage";
import { DynamicEnvironmentsPage } from "~/areas/infrastructure/components/DynamicEnvironmentsLayout/DynamicEnvironmentsPage";
import { EnvironmentPage } from "~/areas/infrastructure/components/EnvironmentLayout/EnvironmentPage";
import { EnvironmentsPage } from "~/areas/infrastructure/components/EnvironmentsLayout/EnvironmentsPage";
import InfrastructureDashboardPage from "~/areas/infrastructure/components/InfrastructureDashboard/index";
import InfrastructureLayout from "~/areas/infrastructure/components/InfrastructureLayout/index";
import { DeploymentTargetConnectionsPage, WorkerMachineConnectionsPage } from "~/areas/infrastructure/components/MachineConnection/MachineConnection";
import { MachineDeploymentsPage } from "~/areas/infrastructure/components/MachineDeployments/MachineDeploymentsPage";
import { DeploymentTargetDiscoveryPage, WorkerMachineDiscoveryPage } from "~/areas/infrastructure/components/MachineDiscovery/MachineDiscovery";
import { MachineEventsPage } from "~/areas/infrastructure/components/MachineEvents/MachineEventsPage";
import { MachineLayout, WorkerMachineLayout } from "~/areas/infrastructure/components/MachineLayout/index";
import { MachinePoliciesPage } from "~/areas/infrastructure/components/MachinePoliciesLayout/MachinePoliciesPage";
import { MachinePolicyPage } from "~/areas/infrastructure/components/MachinePolicyLayout/MachinePolicyPage";
import { MachineRunbookRunsPage } from "~/areas/infrastructure/components/MachineRunbookRuns/MachineRunbookRunsPage";
import { MachineTargetNewPage } from "~/areas/infrastructure/components/MachineSettings/MachineTargetNewPage";
import { DeploymentTargetsPage } from "~/areas/infrastructure/components/MachinesLayout/DeploymentTargetsPage";
import { WorkerMachinesPage } from "~/areas/infrastructure/components/MachinesLayout/WorkerMachinesPage";
import { ProxiesPage } from "~/areas/infrastructure/components/ProxiesLayout/ProxiesPage";
import { ProxyPage } from "~/areas/infrastructure/components/ProxyLayout/ProxyPage";
import { WorkerMachineNewPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineNewPage";
import { WorkerMachineSettingsPage } from "~/areas/infrastructure/components/WorkerMachineSettings/WorkerMachineSettingsPage";
import { WorkerPoolPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolLayout/WorkerPoolPage";
import { WorkerPoolsPage } from "~/areas/infrastructure/components/WorkerPools/WorkerPoolsLayout/WorkerPoolsPage";
import { InsightsReportDeploymentDurationPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentDurationPage";
import { InsightsReportDeploymentFailureRatePage } from "~/areas/insights/components/Reports/InsightsReportDeploymentFailureRatePage";
import { InsightsReportDeploymentFrequencyPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentFrequencyPage";
import { InsightsReportDeploymentLeadTimePage } from "~/areas/insights/components/Reports/InsightsReportDeploymentLeadTimePage";
import { InsightsReportDeploymentMeanTimeToRecoveryPage } from "~/areas/insights/components/Reports/InsightsReportDeploymentMeanTimeToRecoveryPage";
import { InsightsReportSettingsPage } from "~/areas/insights/components/Reports/InsightsReportSettingsPage";
import { InsightsReportListPage } from "~/areas/insights/components/Reports/ReportList/InsightsReportListPage";
import type { ReportFilter } from "~/areas/insights/components/Reports/ReportMetricsLayout/useReportFilters";
import { InsightsReportOverviewPage } from "~/areas/insights/components/Reports/ReportOverview/InsightsReportOverviewPage";
import { ActionTemplatePage } from "~/areas/library/components/ActionTemplates/ActionTemplate/ActionTemplatePage";
import ActionTemplateLayout from "~/areas/library/components/ActionTemplates/ActionTemplateLayout/ActionTemplateLayout";
import { ActionTemplateUsagePage } from "~/areas/library/components/ActionTemplates/ActionTemplateUsagePage";
import { ActionTemplatesPage } from "~/areas/library/components/ActionTemplates/ActionTemplatesPage";
import { BuiltinTemplatesPage } from "~/areas/library/components/ActionTemplates/BuiltinTemplatesPage";
import { CommunityTemplatePage } from "~/areas/library/components/ActionTemplates/CommunityTemplatePage";
import { CommunityTemplatesPage } from "~/areas/library/components/ActionTemplates/CommunityTemplatesPage";
import { RunActionTemplatePage } from "~/areas/library/components/ActionTemplates/RunActionTemplatePage";
import { BuildInformationDetailPage } from "~/areas/library/components/BuildInformation/BuildInformationDetailPage";
import { BuildInformationOverviewPage } from "~/areas/library/components/BuildInformation/BuildInformationOverviewPage";
import { BuildInformationVersionsPage } from "~/areas/library/components/BuildInformation/BuildInformationVersionsPage";
import { BuiltInRepositoryPage } from "~/areas/library/components/BuiltInRepository/BuiltInRepositoryPage";
import { PackageDetailsPage } from "~/areas/library/components/BuiltInRepository/PackageDetailPage";
import { PackageVersionsListPage } from "~/areas/library/components/BuiltInRepository/PackageVersionsListPage";
import { CertificatePage } from "~/areas/library/components/Certificates";
import ArchivedCertificatesPage from "~/areas/library/components/Certificates/ArchivedCertificatesPage";
import CertificatesPage from "~/areas/library/components/Certificates/CertificatesPage";
import { ExternalFeedPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedPage";
import { ExternalFeedTestPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedTestPage";
import { ExternalFeedsPage } from "~/areas/library/components/ExternalFeeds/ExternalFeedsPage";
import { AddGitHubConnectionPage } from "~/areas/library/components/GitConnections/AddGitHubConnectionPage";
import { GitConnectionsPage } from "~/areas/library/components/GitConnections/GitConnectionsPage";
import { GitHubConnectionPage } from "~/areas/library/components/GitConnections/GitHubConnectionPage";
import { GitCredentialPage } from "~/areas/library/components/GitCredentials/GitCredentialPage";
import { GitCredentialsPage } from "~/areas/library/components/GitCredentials/GitCredentialsPage";
import { LifecyclePage } from "~/areas/library/components/Lifecycle/Lifecycle/LifecyclePage";
import { LifecyclesPage } from "~/areas/library/components/Lifecycle/Lifecycles/LifecyclesPage";
import { ScriptModulePage } from "~/areas/library/components/Scripts/ScriptModulePage";
import { ScriptModulesPage } from "~/areas/library/components/Scripts/ScriptModulesPage";
import { TagSetPage } from "~/areas/library/components/TagSets/TagSetPage";
import { TagSetsPage } from "~/areas/library/components/TagSets/TagSetsPage";
import { EditVariableSetPage } from "~/areas/library/components/VariableSets/EditVariableSetPage";
import { VariableSetsPage } from "~/areas/library/components/VariableSets/VariableSetsPage";
import { ChannelsWithMatch } from "~/areas/projects/components/Channels/Channels";
import { Edit as EditChannel } from "~/areas/projects/components/Channels/Edit";
import DeploymentProcessSettings from "~/areas/projects/components/DeploymentProcessSettings/DeploymentProcessSettings";
import DeploymentsOverview from "~/areas/projects/components/DeploymentsOverview";
import { ExportProjectTaskPage } from "~/areas/projects/components/ImportExport/ExportProjectTaskPage";
import { ExportProjectsPage } from "~/areas/projects/components/ImportExport/ExportProjectsPage";
import { ImportExportProjectTasksPage } from "~/areas/projects/components/ImportExport/ImportExportProjectTasksPage";
import { ImportProjectTaskPage } from "~/areas/projects/components/ImportExport/ImportProjectTaskPage";
import { ImportProjectsPage } from "~/areas/projects/components/ImportExport/ImportProjectsPage";
import { DeploymentProcessCodePage } from "~/areas/projects/components/Process/DeploymentProcessCodePage";
import { DeploymentProcessListPage } from "~/areas/projects/components/Process/DeploymentProcessListPage";
import { DeploymentProcessStepsPage } from "~/areas/projects/components/Process/DeploymentProcessStepsPage";
import { ProjectInsightsDeploymentDurationPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsDeploymentDuration/ProjectInsightsDeploymentDurationPage";
import { ProjectInsightsFailureRatePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFailureRate/ProjectInsightsFailureRatePage";
import { ProjectInsightsFrequencyPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFrequency/ProjectInsightsFrequencyPage";
import { ProjectInsightsLeadTimePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsLeadTime/ProjectInsightsLeadTimePage";
import { ProjectInsightsOverviewPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsOverview/ProjectInsightsOverviewPage";
import { ProjectInsightsTimeToRecoveryPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsTimeToRecovery/ProjectInsightsTimeToRecoveryPage";
import { DynamicEnvironmentSettingsPage } from "~/areas/projects/components/ProjectSettings/DynamicEnvironments/DynamicEnvironmentSettingsPage";
import { ProjectSettingsPage } from "~/areas/projects/components/ProjectSettings/ProjectSettingsPage";
import { VersionControlSettingsPage } from "~/areas/projects/components/ProjectSettings/VersionControl/VersionControlSettingsPage";
import ProjectTasks from "~/areas/projects/components/ProjectTasks/ProjectTasks";
import ProjectTenantsPage from "~/areas/projects/components/ProjectTenants/ProjectTenantsPage";
import type { CreateDeploymentGoal } from "~/areas/projects/components/Releases/Deployments/CreateDeploymentPage";
import { CreateDeploymentPage } from "~/areas/projects/components/Releases/Deployments/CreateDeploymentPage";
import { DeploymentDetailsPage } from "~/areas/projects/components/Releases/Deployments/DeploymentDetailsPage";
import { CreateOrEditReleasePage } from "~/areas/projects/components/Releases/Edit/CreateOrEditReleasePage";
import { ReleasePage } from "~/areas/projects/components/Releases/ReleasePage";
import { ReleasesPage } from "~/areas/projects/components/Releases/ReleasesPage";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import { OperationsOverviewPage } from "~/areas/projects/components/Runbooks/OperationsOverviewPage";
import RunbookOverviewPage from "~/areas/projects/components/Runbooks/RunbookOverviewPage";
import { RunbookProcessListPage } from "~/areas/projects/components/Runbooks/RunbookProcessListPage";
import { RunbookProcessStepsPage } from "~/areas/projects/components/Runbooks/RunbookProcessStepsPage";
import { RunbookRunDetailsPage } from "~/areas/projects/components/Runbooks/RunbookRunDetailsPage";
import type { RunGoal } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import { RunbookRunNowPage } from "~/areas/projects/components/Runbooks/RunbookRunNowPage";
import { RunbookRunsListPage } from "~/areas/projects/components/Runbooks/RunbookRunsListPage";
import { RunbookSettingsPage } from "~/areas/projects/components/Runbooks/RunbookSettingsPage";
import { CreateRunbookRunForSnapshotPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/CreateRunbookRunForSnapshotPage";
import { RunbookSnapshotEditPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotEditPage";
import { RunbookSnapshotInfoPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotInfoPage";
import { RunbookSnapshotsPage } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotsPage";
import { RunCreateGoalValues } from "~/areas/projects/components/Runbooks/RunbookSnapshots/runbookSnapshotRouteLinks";
import { RunbooksPage } from "~/areas/projects/components/Runbooks/RunbooksPage";
import { CreateRunbookScheduledTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/CreateRunbookScheduledTriggersPage";
import { EditRunbookScheduledTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/EditRunbookScheduledTriggersPage";
import { RunbookTriggersPage } from "~/areas/projects/components/Runbooks/Triggers/RunbookTriggersPage";
import { CreateBuiltInFeedTriggerPage } from "~/areas/projects/components/Triggers/CreateBuiltInFeedTriggerPage";
import { CreateReleaseCreationFeedTriggersPage } from "~/areas/projects/components/Triggers/CreateReleaseCreationFeedTriggersPage";
import { DeploymentTriggerPage } from "~/areas/projects/components/Triggers/DeploymentTriggerPage";
import { EditBuiltInFeedTriggerPage } from "~/areas/projects/components/Triggers/EditBuiltInFeedTriggerPage";
import { EditReleaseCreationFeedTriggersPage } from "~/areas/projects/components/Triggers/EditReleaseCreationFeedTriggersPage";
import { ReleaseTriggersPage } from "~/areas/projects/components/Triggers/ReleaseTriggersPage";
import { CreateDeploymentScheduledTriggerPage } from "~/areas/projects/components/Triggers/Scheduled/CreateDeploymentScheduledTriggerPage";
import { EditDeploymentScheduledTriggerPage } from "~/areas/projects/components/Triggers/Scheduled/EditDeploymentScheduledTriggerPage";
import { TriggersPage } from "~/areas/projects/components/Triggers/TriggersPage";
import AllVariables from "~/areas/projects/components/Variables/AllVariables";
import CommonVariableTemplates from "~/areas/projects/components/Variables/CommonVariableTemplates";
import LibraryVariableSets from "~/areas/projects/components/Variables/LibraryVariableSets/LibraryVariableSets";
import ProjectVariableTemplates from "~/areas/projects/components/Variables/ProjectVariableTemplates";
import ProjectVariables from "~/areas/projects/components/Variables/ProjectVariables/ProjectVariables";
import ProjectTenantVariables from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariables";
import VariablePreview from "~/areas/projects/components/Variables/VariablePreview/VariablePreview";
import { RawLogPage } from "~/areas/tasks/components/RawLog/RawLogPage";
import { ScriptConsolePage } from "~/areas/tasks/components/ScriptConsole/ScriptConsolePage";
import { TaskPage } from "~/areas/tasks/components/Task/TaskPage";
import { TenantOverviewPage } from "~/areas/tenants/TenantOverview/TenantOverviewPage";
import { TenantSettingsPage } from "~/areas/tenants/TenantSettings/TenantSettingsPage";
import { TenantTasksPage } from "~/areas/tenants/TenantTasks/TenantTasksPage";
import { TenantVariablesPage } from "~/areas/tenants/TenantVariables/TenantVariablesPage";
import { TenantsPage } from "~/areas/tenants/Tenants/TenantsPage";
import UserApiKeysPage from "~/areas/users/UserApiKeys/UserApiKeysPage";
import UserLoginsPage from "~/areas/users/UserLogins/UserLoginsPage";
import UserProfilePage from "~/areas/users/UserProfile/UserProfilePage";
import UserRecentActivityPage from "~/areas/users/UserRecentActivity/UserRecentActivityPage";
import { StyleGuidePage } from "~/components/StyleGuide/StyleGuidePage";
import routeLinks from "~/routeLinks";
import { createInfrastructurePageRegistration } from "~/routing/pageRegistrations/InfrastructurePageRegistration";
import { createInsightsPageRegistration, createInsightsReportPageRegistration } from "~/routing/pageRegistrations/InsightsPageRegistration";
import { createTenantPageRegistration } from "~/routing/pageRegistrations/TenantPageRegistration";
import { createPageMap } from "~/routing/pageRegistrations/createPageMap";
import type { PageGroup } from "~/routing/pageRegistrations/createPageMap";
import { ProjectGroupPage } from "../../areas/projects/components/ProjectGroupLayout/ProjectGroupPage";
import { TasksPage } from "../../areas/tasks/components/Tasks/TasksPage";
import { createConfigurationPageRegistration } from "./ConfigurationPageRegistration";
import { createCurrentUserPageRegistration } from "./CurrentUserPageRegistration";
import { createLibraryPageRegistration } from "./LibraryPageRegistration";
import type { UnknownPageRegistration } from "./PageRegistration";
import { createProjectLevel1PageRegistration, createProjectPageRegistration } from "./ProjectPageRegistration";
import { createSpaceRootPageRegistration } from "./SpaceRootPageRegistration";
import { createTaskPageRegistration } from "./TaskPageRegistration";
/*These are experimental routes and won't have any impact on existing routes as we define them today.
The better portal experience team is experimenting with adding an abstraction layer for our routing.*/
export const allPages = createPageMap(allRoutes, {
    partialRoute: (r) => r,
    pages: {},
    childGroups: {
        system: {
            pages: {
                tasksPage: (r) => createTaskPageRegistration(r, () => <TasksPage />, { pageArea: "Tasks", pageId: "Tasks", pageName: "Tasks" }, (routeParams, queryParams) => {
                    const search = generateQueryString(r.queryParameters, queryParams);
                    return `${routeLinks.tasks.root}${search}`;
                }),
                taskPage: (r) => createTaskPageRegistration(r, ({ taskId }) => <TaskPage taskId={taskId}/>, { pageArea: "Tasks", pageId: "Task", pageName: "Task" }, ({ taskId }) => routeLinks.task(taskId).root),
                taskRawLogPage: (r) => createTaskPageRegistration(r, ({ taskId }) => <RawLogPage taskId={taskId}/>, { pageArea: "Tasks", pageId: "Task.Raw", pageName: "Raw Task Log" }, ({ taskId }) => routeLinks.task(taskId).raw),
                scriptConsolePage: (r) => createTaskPageRegistration(r, () => <ScriptConsolePage />, { pageArea: "Tasks", pageId: "Task.ScriptConsole", pageName: "Script Console" }, (routeParams, queryParams) => {
                    const search = generateQueryString(r.queryParameters, queryParams);
                    return `${routeLinks.tasks.console}${search}`;
                }),
                styleGuidePage: (r) => createTaskPageRegistration(r, () => <StyleGuidePage />, { pageArea: "Dev Tools", pageId: "StyleGuide", pageName: "Style guide" }, () => routeLinks.styleGuide),
            },
            partialRoute: (r) => r,
            childGroups: {
                configuration: {
                    partialRoute: (r) => r,
                    childGroups: {
                        settings: {
                            partialRoute: (r) => r,
                            childGroups: {},
                            pages: {
                                settingsListPage: (r) => createConfigurationPageRegistration(r, () => <SettingsListPage />, { pageArea: "Configuration", pageId: "Configuration.Settings", pageName: "Settings" }, () => routeLinks.configuration.settings.root),
                                activeDirectorySettingsPage: (r) => createConfigurationPageRegistration(r, () => <ActiveDirectorySettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-directoryservices`, pageName: "Active Directory Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.ActiveDirectory)),
                                archiveLimitsSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ArchiveLimitsSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.archive-limit`, pageName: "Archive Limits Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.ArchiveLimits)),
                                authenticationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <AuthenticationSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication`, pageName: "Authentication Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.Authentication)),
                                azureADSettingsPage: (r) => createConfigurationPageRegistration(r, () => <AzureADSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-aad`, pageName: "Azure AD Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.AzureAD)),
                                azureDevOpsIssueTrackerSettingsPage: (r) => createConfigurationPageRegistration(r, () => <AzureDevOpsIssueTrackerSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.issuetracker-azuredevops-v2`, pageName: "Azure DevOps Issue Tracker Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.AzureDevOpsIssueTracker)),
                                eventRetentionSettingsPage: (r) => createConfigurationPageRegistration(r, () => <EventRetentionSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.eventretention`, pageName: "Event Retention Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.EventRetention)),
                                githubIssueTrackerSettingsPage: (r) => createConfigurationPageRegistration(r, () => <GithubIssueTrackerSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.issuetracker-github`, pageName: "GitHub Issue Tracker Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.GithubIssueTracker)),
                                googleAppsSettingsPage: (r) => createConfigurationPageRegistration(r, () => <GoogleAppsSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-googleapps`, pageName: "Google Apps Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.GoogleApps)),
                                guestLoginSettingsPage: (r) => createConfigurationPageRegistration(r, () => <GuestLoginSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-guest`, pageName: "Guest Login Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.GuestLogin)),
                                jiraIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <JiraIntegrationSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.jira-integration`, pageName: "Jira Integration Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.JiraIntegration)),
                                jiraServiceManagementIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <JiraServiceManagementIntegrationSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.jiraservicemanagement-integration`, pageName: "Jira Service Management Integration Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.JiraServiceManagementIntegration)),
                                ldapSettingsPage: (r) => createConfigurationPageRegistration(r, () => <LDAPSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-ldap`, pageName: "LDAP Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.LDAP)),
                                octopusIdSettingsPage: (r) => createConfigurationPageRegistration(r, () => <OctopusIdSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-octopusid`, pageName: "Octopus ID Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.OctopusID)),
                                oktaSettingsPage: (r) => createConfigurationPageRegistration(r, () => <OktaSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-od`, pageName: "Okta Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.Okta)),
                                retentionPoliciesSettingsPage: (r) => createConfigurationPageRegistration(r, () => <RetentionPoliciesSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.retentionpolicies`, pageName: "Retention Policies Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.RetentionPolicies)),
                                serverFoldersSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ServerFoldersSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.server-folders`, pageName: "Server Folders Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.ServerFolders)),
                                serviceNowIntegrationSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ServiceNowIntegrationSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.servicenow-integration`, pageName: "ServiceNow Integration Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.ServiceNowIntegration)),
                                updatesSettingsPage: (r) => createConfigurationPageRegistration(r, () => <UpdatesSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.upgrades`, pageName: "Updates Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.Updates)),
                                usernamePasswordSettingsPage: (r) => createConfigurationPageRegistration(r, () => <UsernamePasswordSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.authentication-usernamepassword`, pageName: "Username / Password Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.UsernamePassword)),
                                webPortalSettingsPage: (r) => createConfigurationPageRegistration(r, () => <WebPortalSettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.webportal`, pageName: "Web Portal Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.WebPortal)),
                                webRequestProxySettingsPage: (r) => createConfigurationPageRegistration(r, () => <WebRequestProxySettingsPage />, { pageArea: "Configuration", pageId: `Configuration.Setting.server-proxy`, pageName: "Web Request Proxy Settings" }, () => routeLinks.configuration.setting(SettingsIdValues.WebRequestProxy)),
                            },
                        },
                        deploymentFreezes: {
                            partialRoute: (r) => r,
                            childGroups: {},
                            pages: {
                                deploymentFreezesListPage: (r) => createConfigurationPageRegistration(r, () => <DeploymentFreezesListPage />, { pageArea: "Configuration", pageId: "Configuration.DeploymentFreezes", pageName: "Deployment Freezes" }, () => routeLinks.configuration.deploymentFreezes.root),
                            },
                        },
                    },
                    pages: {
                        auditPage: (r) => createConfigurationPageRegistration(r, () => <AuditPage />, { pageArea: "Configuration", pageId: "Configuration.Audit", pageName: "Audit" }, (_, queryParams) => {
                            const legacyAuditQuery: LegacyAuditQuery = {
                                ...queryParams,
                                includeSystem: queryParams.includeSystem !== undefined ? (queryParams.includeSystem ? "true" : "false") : undefined,
                            };
                            return routeLinks.configuration.auditWithQuery(legacyAuditQuery);
                        }),
                        auditArchivePage: (r) => createConfigurationPageRegistration(r, () => <AuditArchivePage />, { pageArea: "Configuration", pageId: "Configuration.AuditArchive", pageName: "Audit Archive" }, () => routeLinks.configuration.auditArchive),
                        backupPage: (r) => createConfigurationPageRegistration(r, () => <BackupPage />, { pageArea: "Configuration", pageId: "Configuration.Backup", pageName: "Backup" }, () => routeLinks.configuration.backup),
                        diagnosticsPage: (r) => createConfigurationPageRegistration(r, () => <DiagnosticsPage />, { pageArea: "Configuration", pageId: "Configuration.Diagnostics", pageName: "Diagnostics" }, () => routeLinks.configuration.diagnostics.root),
                        diagnosticLogsPage: (r) => createConfigurationPageRegistration(r, () => <DetailedServerLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogs", pageName: "Diagnostic Logs" }, () => routeLinks.configuration.diagnostics.logs.root),
                        diagnosticAutoDeployLogsPage: (r) => createConfigurationPageRegistration(r, () => <AutoDeployLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsAutoDeploy", pageName: "Auto Deploy Diagnostic Logs" }, () => routeLinks.configuration.diagnostics.logs.autoDeploy),
                        diagnosticScheduledDeployLogsPage: (r) => createConfigurationPageRegistration(r, () => <ScheduledDeployLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsScheduledDeploy", pageName: "Scheduled Deploy Diagnostic Logs" }, () => routeLinks.configuration.diagnostics.logs.scheduledDeploy),
                        diagnosticSubscriptionLogsPage: (r) => createConfigurationPageRegistration(r, () => <SubscriptionLogsPage />, { pageArea: "Configuration", pageId: "Configuration.DiagnosticLogsSubscription", pageName: "Subscription Diagnostic Logs" }, () => routeLinks.configuration.diagnostics.logs.subscription),
                        extensionsPage: (r) => createConfigurationPageRegistration(r, () => <ExtensionsPage />, { pageArea: "Configuration", pageId: "Configuration.Extensions", pageName: "Extensions" }, () => routeLinks.configuration.extensions.root),
                        featuresPage: (r) => createConfigurationPageRegistration(r, () => <FeaturesPage />, { pageArea: "Configuration", pageId: "Configuration.Features", pageName: "Features" }, () => routeLinks.configuration.features),
                        letsEncryptPage: (r) => createConfigurationPageRegistration(r, () => <LetsEncryptPage />, { pageArea: "Configuration", pageId: "Configuration.LetsEncrypt", pageName: "Let's Encrypt" }, () => routeLinks.configuration.letsEncrypt.root),
                        configureLetsEncryptPage: (r) => createConfigurationPageRegistration(r, () => <ConfigureLetsEncryptPage />, { pageArea: "Configuration", pageId: "Configuration.LetsEncryptConfigure", pageName: "Configure Let's Encrypt" }, () => routeLinks.configuration.letsEncrypt.configure),
                        maintenancePage: (r) => createConfigurationPageRegistration(r, () => <MaintenancePage />, { pageArea: "Configuration", pageId: "Configuration.Maintenance", pageName: "Maintenance" }, () => routeLinks.configuration.maintenance),
                        performancePage: (r) => createConfigurationPageRegistration(r, () => <PerformancePage />, { pageArea: "Configuration", pageId: "Configuration.Performance", pageName: "Performance" }, () => routeLinks.configuration.performance),
                        serverSettingsPage: (r) => createConfigurationPageRegistration(r, () => <ServerSettingsPage />, { pageArea: "Configuration", pageId: "Configuration.NodesServerSettings", pageName: "Node Server Settings" }, () => routeLinks.configuration.nodes.serverSettings),
                        nodesPage: (r) => createConfigurationPageRegistration(r, () => <NodesPage />, { pageArea: "Configuration", pageId: "Configuration.Nodes", pageName: "Nodes" }, () => routeLinks.configuration.nodes.root),
                        licensePage: (r) => createConfigurationPageRegistration(r, (PageLayout) => <LicensePage PageLayout={PageLayout}/>, { pageArea: "Configuration", pageId: "Configuration.License", pageName: "License" }, () => routeLinks.configuration.license),
                        smtpPage: (r) => createConfigurationPageRegistration(r, () => <SmtpPage />, { pageArea: "Configuration", pageId: "Configuration.Smtp", pageName: "SMTP" }, () => routeLinks.configuration.smtp),
                        gitPage: (r) => createConfigurationPageRegistration(r, (PageLayout) => <GitPage PageLayout={PageLayout}/>, { pageArea: "Configuration", pageId: "Configuration.Git", pageName: "Git" }, () => routeLinks.configuration.git),
                        subscriptionsPage: (r) => createConfigurationPageRegistration(r, () => <SubscriptionsPage />, { pageArea: "Configuration", pageId: "Configuration.Subscriptions", pageName: "Subscriptions" }, () => routeLinks.configuration.subscriptions.root),
                        editSubscriptionPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { subscriptionId }) => <SubscriptionPage subscriptionId={subscriptionId}/>, { pageArea: "Configuration", pageId: "Configuration.Subscription", pageName: "Subscription" }, ({ subscriptionId }) => routeLinks.configuration.subscription(subscriptionId)),
                        createSubscriptionPage: (r) => createConfigurationPageRegistration(r, () => <SubscriptionPage create/>, { pageArea: "Configuration", pageId: "Configuration.SubscriptionsNew", pageName: "SubscriptionsNew" }, (_, queryParams) => {
                            const legacyAuditQuery: LegacyAuditQuery = {
                                ...queryParams,
                                includeSystem: queryParams.includeSystem !== undefined ? (queryParams.includeSystem ? "true" : "false") : undefined,
                            };
                            return routeLinks.configuration.subscriptions.create(legacyAuditQuery);
                        }),
                        testPermissionsPage: (r) => createConfigurationPageRegistration(r, () => <TestPermissionsPage userId={undefined}/>, { pageArea: "Configuration", pageId: "Configuration.TestPermission", pageName: "Test Permission" }, () => routeLinks.configuration.testPermissions),
                        testPermissionsForUserPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { userId }) => <TestPermissionsPage userId={userId}/>, { pageArea: "Configuration", pageId: "Configuration.TestPermissions", pageName: "Test Permissions" }, ({ userId }) => routeLinks.configuration.testPermission(userId)),
                        thumbprintPage: (r) => createConfigurationPageRegistration(r, () => <ThumbprintPage />, { pageArea: "Configuration", pageId: "Configuration.Thumbprint", pageName: "Thumbprint" }, () => routeLinks.configuration.thumbprint),
                        userRolesPage: (r) => createConfigurationPageRegistration(r, () => <UserRolesPage />, { pageArea: "Configuration", pageId: "Configuration.Roles", pageName: "User Roles" }, () => routeLinks.configuration.roles.root),
                        createUserRolePage: (r) => createConfigurationPageRegistration(r, () => <UserRolePage roleId={undefined}/>, { pageArea: "Configuration", pageId: "Configuration.RolesNew", pageName: "Add New User Role" }, () => routeLinks.configuration.roles.create),
                        editUserRolePage: (r) => createConfigurationPageRegistration(r, (PageLayout, { roleId }) => <UserRolePage roleId={roleId}/>, { pageArea: "Configuration", pageId: "Configuration.Role", pageName: "User Role" }, ({ roleId }) => routeLinks.configuration.role(roleId)),
                        spacesPage: (r) => createConfigurationPageRegistration(r, () => <SpacesPage />, { pageArea: "Configuration", pageId: "Configuration.Spaces", pageName: "Spaces" }, () => routeLinks.configuration.spaces.root),
                        editSpacePage: (r) => createConfigurationPageRegistration(r, (PageLayout, { currentSpaceId }) => <EditSpacePage currentSpaceId={currentSpaceId}/>, { pageArea: "Configuration", pageId: "Configuration.Space", pageName: "Space" }, ({ currentSpaceId }) => routeLinks.configuration.space(currentSpaceId)),
                        teamsPage: (r) => createConfigurationPageRegistration(r, () => <TeamsPage />, { pageArea: "Configuration", pageId: "Configuration.Teams", pageName: "Teams" }, () => routeLinks.configuration.teams.root()),
                        editTeamPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { teamId }) => <EditTeamPage teamId={teamId}/>, { pageArea: "Configuration", pageId: "Configuration.Team", pageName: "Team" }, ({ teamId }) => routeLinks.configuration.team(teamId)),
                        usersPage: (r) => createConfigurationPageRegistration(r, () => <UsersPage />, { pageArea: "Configuration", pageId: "Configuration.Users", pageName: "Users" }, () => routeLinks.configuration.users.root),
                        createUserPage: (r) => createConfigurationPageRegistration(r, () => <CreateUserPage />, { pageArea: "Configuration", pageId: "Configuration.UsersNew", pageName: "Add New User" }, () => routeLinks.configuration.users.create),
                        editUserPage: (r) => createConfigurationPageRegistration(r, (PageLayout, { userId }) => <EditUserPage userId={userId}/>, { pageArea: "Configuration", pageId: "Configuration.User", pageName: "User" }, ({ userId }) => routeLinks.configuration.user(userId)),
                        signingKeysPage: (r) => createConfigurationPageRegistration(r, () => <SigningKeysPage />, { pageArea: "Configuration", pageId: "Configuration.SigningKeys", pageName: "Signing Keys" }, () => routeLinks.configuration.signingKeys),
                        telemetryPage: (r) => createConfigurationPageRegistration(r, () => <TelemetryPage />, { pageArea: "Configuration", pageId: "Configuration.Telemetry", pageName: "Telemetry" }, () => routeLinks.configuration.telemetry),
                        userInvitesPage: (r) => createConfigurationPageRegistration(r, () => <UserInvitePage />, { pageArea: "Configuration", pageId: "Configuration.UserInvites", pageName: "Invite Users" }, () => routeLinks.configuration.userInvites),
                    },
                },
                currentUser: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        currentUserDetailsPage: (r) => createCurrentUserPageRegistration(r, () => <UserProfilePage />, { pageArea: "User Profile", pageId: "UserProfile.Details", pageName: "User Profile Details" }, () => routeLinks.currentUser.details),
                        currentUserLoginsPage: (r) => createCurrentUserPageRegistration(r, () => <UserLoginsPage />, { pageArea: "User Profile", pageId: "UserProfile.Logins", pageName: "User Profile Logins" }, () => routeLinks.currentUser.logins),
                        currentUserApiKeysPage: (r) => createCurrentUserPageRegistration(r, () => <UserApiKeysPage />, { pageArea: "User Profile", pageId: "UserProfile.ApiKeys", pageName: "User Profile API Keys" }, () => routeLinks.currentUser.apiKeys),
                        currentUserActivityPage: (r) => createCurrentUserPageRegistration(r, () => <UserRecentActivityPage />, { pageArea: "User Profile", pageId: "UserProfile.Activity", pageName: "User Profile Activity" }, () => routeLinks.currentUser.activity),
                    },
                },
            },
        },
        space: {
            partialRoute: (r) => r,
            pages: {
                dashboardPage: (r) => createSpaceRootPageRegistration(r, ({ spaceId }) => <DashboardOverviewPage spaceId={spaceId}/>, { pageArea: "Dashboard", pageName: "Dashboard", pageId: "Dashboard" }, ({ spaceId }) => routeLinks.forSpace(spaceId).dashboard.root),
                dashboardConfigurationPage: (r) => createSpaceRootPageRegistration(r, ({ spaceId }) => <DashboardConfigurationPage spaceId={spaceId}/>, { pageArea: "Dashboard", pageName: "Configure Dashboard", pageId: "Dashboard.Configure" }, ({ spaceId }) => routeLinks.forSpace(spaceId).dashboard.configure),
                editProjectGroupPage: (r) => createSpaceRootPageRegistration(r, ({ spaceId, projectGroupId }) => <ProjectGroupPage spaceId={spaceId} projectGroupId={projectGroupId}/>, { pageArea: "Project", pageName: "Project Group", pageId: "ProjectGroup.Edit" }, ({ spaceId, projectGroupId }) => routeLinks.projectGroup(projectGroupId).root),
            },
            childGroups: {
                projects: {
                    partialRoute: (r) => r,
                    pages: {
                        projectsPage: (r) => createProjectLevel1PageRegistration(r, (PageLayout, { spaceId }) => <Projects PageLayout={PageLayout} spaceId={spaceId}/>, { pageArea: "Project", pageName: "Projects", pageId: "Projects" }, ({ spaceId }, queryParams) => {
                            if (queryParams?.newProjectGroupId) {
                                return `${routeLinks.forSpace(spaceId).projects.root}?newProjectGroupId=${queryParams.newProjectGroupId}`;
                            }
                            if (queryParams?.projectGroupId) {
                                return routeLinks.forSpace(spaceId).projects.filteredByGroup(queryParams.projectGroupId);
                            }
                            return routeLinks.forSpace(spaceId).projects.root;
                        }),
                        importExportProjectTasksPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ImportExportProjectTasksPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "ImportExportTasks", pageName: "Import/Export" }, ({ spaceId }) => routeLinks.forSpace(spaceId).importExport.overview),
                        importProjectsPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ImportProjectsPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "ImportProjects", pageName: "Import" }, ({ spaceId }) => routeLinks.forSpace(spaceId).importExport.import),
                        importProjectTaskPage: (r) => createProjectPageRegistration(r, ({ spaceId, taskId }) => <ImportProjectTaskPage spaceId={spaceId} taskId={taskId}/>, { pageArea: "Project", pageId: "ImportTask", pageName: "Import Task" }, ({ spaceId, taskId }) => routeLinks.forSpace(spaceId).importExport.importTask(taskId)),
                        exportProjectsPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ExportProjectsPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "ExportProjects", pageName: "Export" }, ({ spaceId }) => routeLinks.forSpace(spaceId).importExport.export),
                        exportProjectTaskPage: (r) => createProjectPageRegistration(r, ({ spaceId, taskId }) => <ExportProjectTaskPage spaceId={spaceId} taskId={taskId}/>, { pageArea: "Project", pageId: "ExportTask", pageName: "Export Task" }, ({ spaceId, taskId }) => routeLinks.forSpace(spaceId).importExport.exportTask(taskId)),
                    },
                    childGroups: {
                        specificProject: {
                            partialRoute: (r) => r,
                            pages: {},
                            childGroups: {
                                branch: {
                                    partialRoute: (r) => r,
                                    pages: {},
                                    childGroups: {
                                        variables: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                branchAllVariablesPage: (r) => createProjectPageRegistration(r, () => <AllVariables />, { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).variables.all),
                                                branchVariablesPage: (r) => createProjectPageRegistration(r, () => <ProjectVariables />, { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).variables.root),
                                                branchVariablesPreviewPage: (r) => createProjectPageRegistration(r, () => <VariablePreview />, { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).variables.preview),
                                            },
                                        },
                                        deployments: {
                                            partialRoute: (r) => r,
                                            childGroups: {},
                                            pages: {
                                                branchDeploymentProcessPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessListPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deployments.process.root),
                                                branchDeploymentProcessCodePage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessCodePage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process Code" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deployments.process.code),
                                                branchDeploymentProcessStepsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessStepsPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, branchName, projectSlug }, parameters) => {
                                                    const search = generateQueryString(r.queryParameters, parameters);
                                                    return `${routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deployments.process.root}/steps${search}`;
                                                }),
                                                branchDeploymentProcessSettingsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessSettings />, { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" }, ({ spaceId, branchName, projectSlug }) => routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deployments.settings),
                                            },
                                        },
                                    },
                                },
                                withoutBranch: {
                                    partialRoute: (r) => r,
                                    pages: {
                                        projectInsightsOverviewPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsOverviewPage />, { pageArea: "Project", pageId: "Project.Insights.Overview", pageName: "Insights Overview" }, ({ spaceId, projectSlug }, filterQueryParam) => routeLinks.forSpace(spaceId).project(projectSlug).insights.overview + generateProjectInsightsReportFilterQueryString(filterQueryParam)),
                                        projectInsightsLeadTimePage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsLeadTimePage />, { pageArea: "Project", pageId: "Project.Insights.LeadTime", pageName: "Lead Time" }, ({ spaceId, projectSlug }, filterQueryParam) => routeLinks.forSpace(spaceId).project(projectSlug).insights.leadTime + generateProjectInsightsReportFilterQueryString(filterQueryParam)),
                                        projectInsightsFrequencyPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsFrequencyPage />, { pageArea: "Project", pageId: "Project.Insights.Frequency", pageName: "Deployment Frequency" }, ({ spaceId, projectSlug }, filterQueryParam) => routeLinks.forSpace(spaceId).project(projectSlug).insights.frequency + generateProjectInsightsReportFilterQueryString(filterQueryParam)),
                                        projectInsightsFailureRatePage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsFailureRatePage />, { pageArea: "Project", pageId: "Project.Insights.FailureRate", pageName: "Failure Rate" }, ({ spaceId, projectSlug }, filterQueryParam) => routeLinks.forSpace(spaceId).project(projectSlug).insights.failureRate + generateProjectInsightsReportFilterQueryString(filterQueryParam)),
                                        projectInsightsTimeToRecoveryPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsTimeToRecoveryPage />, { pageArea: "Project", pageId: "Project.Insights.TimeToRecovery", pageName: "Mean Time To Recovery" }, ({ spaceId, projectSlug }, filterQueryParam) => routeLinks.forSpace(spaceId).project(projectSlug).insights.timeToRecovery + generateProjectInsightsReportFilterQueryString(filterQueryParam)),
                                        projectInsightsDeploymentDurationPage: (r) => createProjectPageRegistration(r, () => <ProjectInsightsDeploymentDurationPage />, { pageArea: "Project", pageId: "Project.Insights.DeploymentDuration", pageName: "Deployment Duration" }, ({ spaceId, projectSlug }, filterQueryParam) => routeLinks.forSpace(spaceId).project(projectSlug).insights.deploymentDuration + generateProjectInsightsReportFilterQueryString(filterQueryParam)),
                                        projectTasksPage: (r) => createProjectPageRegistration(r, () => <ProjectTasks />, { pageArea: "Project", pageId: "Project.Tasks", pageName: "Tasks" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).tasks),
                                        projectTenantsPage: (r) => createProjectPageRegistration(r, ({ spaceId }) => <ProjectTenantsPage spaceId={spaceId}/>, { pageArea: "Project", pageId: "Project.Tenants", pageName: "Tenants" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).tenants),
                                        projectOperationsPage: (r) => createProjectPageRegistration(r, () => <OperationsOverviewPage />, { pageArea: "Project", pageId: "Project.Operations", pageName: "Operations" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.root),
                                        projectRunbookTriggersPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <RunbookTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.Runbook.Triggers", pageName: "Runbook Triggers" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.triggers),
                                        projectRunbookCreateScheduledTriggerForRunbookPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, runbookId }) => <CreateRunbookScheduledTriggersPage spaceId={spaceId} projectSlug={projectSlug} runbookId={runbookId}/>, { pageArea: "Project", pageId: "Project.Runbook.ScheduledTriggerNew", pageName: "Add New Runbook Scheduled Trigger" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.scheduledTriggers.newForRunbook(runbookId)),
                                        projectRunbookCreateScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateRunbookScheduledTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.Runbook.ScheduledTrigger", pageName: "Runbook Scheduled Trigger" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.scheduledTriggers.new),
                                        projectRunbookEditScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <EditRunbookScheduledTriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>, { pageArea: "Project", pageId: "Project.Runbook.Triggers", pageName: "Runbook Triggers" }, ({ spaceId, projectSlug, triggerId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.scheduledTrigger(triggerId)),
                                        projectRunbooksPage: (r) => createProjectPageRegistration(r, () => <RunbooksPage />, { pageArea: "Project", pageId: "Project.Operations.Runbooks", pageName: "Runbooks" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbooks),
                                        deploymentTriggersPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <TriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerActionCategory={TriggerActionCategory.Deployment}/>, { pageArea: "Project", pageId: "Project.Triggers", pageName: "Triggers" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).triggers.root),
                                        createDeploymentTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <DeploymentTriggerPage spaceId={spaceId} projectSlug={projectSlug} branchName={undefined} create={true}/>, { pageArea: "Project", pageId: "Project.DeploymentTargetTriggerNew", pageName: "Add New Deployment Target Trigger" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).triggers.new),
                                        editDeploymentTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <DeploymentTriggerPage spaceId={spaceId} projectSlug={projectSlug} branchName={undefined} triggerId={triggerId}/>, { pageArea: "Project", pageId: "Project.DeploymentTargetTrigger", pageName: "Deployment Target Trigger" }, ({ spaceId, projectSlug, triggerId }) => routeLinks.forSpace(spaceId).project(projectSlug).trigger(triggerId)),
                                        createDeploymentScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateDeploymentScheduledTriggerPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.ScheduledTriggerNew", pageName: "Add New Scheduled Trigger" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).scheduledTriggers.new),
                                        editDeploymentScheduledTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <EditDeploymentScheduledTriggerPage spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>, { pageArea: "Project", pageId: "Project.ScheduledTrigger", pageName: "Scheduled Trigger" }, ({ spaceId, projectSlug, triggerId }) => routeLinks.forSpace(spaceId).project(projectSlug).scheduledTrigger(triggerId)),
                                        projectSettingsPage: (r) => createProjectPageRegistration(r, () => <ProjectSettingsPage />, { pageArea: "Project", pageId: "Project.Settings", pageName: "Settings" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).settings.root),
                                        projectVersionControlSettingsPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <VersionControlSettingsPage spaceId={spaceId} projectSlug={projectSlug} branchName={undefined}/>, { pageArea: "Project", pageId: "Project.VersionControlSettings", pageName: "Version Control Settings" }, ({ spaceId, projectSlug }, { newlyCreatedProject }) => {
                                            const link = routeLinks.forSpace(spaceId).project(projectSlug).settings.versionControl;
                                            return newlyCreatedProject ? `${link}?newlyCreatedProject=true` : link;
                                        }),
                                        projectDynamicEnvironmentSettingsPage: (r) => createProjectPageRegistration(r, () => <DynamicEnvironmentSettingsPage />, { pageArea: "Project", pageId: "Project.DynamicEnvironmentSettings", pageName: "Dynamic Environment Settings" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).settings.dynamicEnvironments),
                                        createReleaseCreationFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateReleaseCreationFeedTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.ReleaseCreationFeedTriggerNew", pageName: "Add New Release Creation Feed Trigger" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).feedTriggers.new),
                                        editReleaseCreationFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, triggerId }) => <EditReleaseCreationFeedTriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>, { pageArea: "Project", pageId: "Project.ReleaseCreationFeedTrigger", pageName: "Release Creation Feed Trigger" }, ({ spaceId, projectSlug, triggerId }) => routeLinks.forSpace(spaceId).project(projectSlug).feedTrigger(triggerId)),
                                        releaseTriggersPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <ReleaseTriggersPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.ReleaseTriggers", pageName: "Release Triggers" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).feedTriggers.root),
                                        createBuiltInFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <CreateBuiltInFeedTriggerPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.BuiltInFeedTriggerNew", pageName: "Add New Built-in Feed Trigger" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).builtInFeedTriggers.new),
                                        editBuiltInFeedTriggerPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <EditBuiltInFeedTriggerPage spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.BuiltInFeedTrigger", pageName: "Built-in Feed Trigger" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).builtInFeedTrigger()),
                                    },
                                    childGroups: {
                                        deployments: {
                                            partialRoute: (r) => r,
                                            pages: {
                                                channelsPage: (r) => createProjectPageRegistration(r, () => <ChannelsWithMatch />, { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.channels),
                                                createChannelPage: (r) => createProjectPageRegistration(r, () => <EditChannel create={true}/>, { pageArea: "Project", pageId: "Project.ChannelNew", pageName: "Add New Channels" }, ({ spaceId, projectSlug }) => `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.channels}/create`),
                                                editChannelPage: (r) => createProjectPageRegistration(r, ({ channelId }) => <EditChannel create={false} channelId={channelId}/>, { pageArea: "Project", pageId: "Project.Channel", pageName: "Channel" }, ({ spaceId, projectSlug, channelId }) => `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.channels}/edit/${channelId}`),
                                                deploymentsPage: (r) => createProjectPageRegistration(r, () => <DeploymentsOverview />, { pageArea: "Project", pageId: "Project.Overview", pageName: "Overview" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.root),
                                                releasesPage: (r) => createProjectPageRegistration(r, () => <ReleasesPage />, { pageArea: "Project", pageId: "Project.Releases", pageName: "Releases" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.releases),
                                                createReleasePage: (r) => createProjectPageRegistration(r, () => <CreateOrEditReleasePage />, { pageArea: "Project", pageId: "Project.ReleaseNew", pageName: "Add New Release" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.releaseCreate),
                                            },
                                            childGroups: {
                                                process: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        deploymentProcessPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessListPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, projectSlug }, { newlyCreatedProject }) => {
                                                            const link = routeLinks.forSpace(spaceId).project(projectSlug).deployments.process.root;
                                                            return newlyCreatedProject ? `${link}?newlyCreatedProject=true` : link;
                                                        }),
                                                        deploymentProcessStepsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessStepsPage />, { pageArea: "Project", pageId: "Project.Process", pageName: "Deployment Process" }, ({ spaceId, projectSlug }, parameters) => {
                                                            const search = generateQueryString(r.queryParameters, parameters);
                                                            return `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.process.root}/steps${search}`;
                                                        }),
                                                    },
                                                },
                                                settings: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        deploymentProcessSettingsPage: (r) => createProjectPageRegistration(r, () => <DeploymentProcessSettings />, { pageArea: "Project", pageId: "Project.DeploymentSettings", pageName: "Settings" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.settings),
                                                    },
                                                },
                                                specificRelease: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        releasePage: (r) => createProjectPageRegistration(r, ({ releaseVersion }) => <ReleasePage releaseVersion={releaseVersion}/>, { pageArea: "Project", pageId: "Project.ReleaseVersion", pageName: "Release Version" }, ({ spaceId, projectSlug, releaseVersion }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.release(releaseVersion).root),
                                                        createDeploymentPage: (r) => createProjectPageRegistration(r, ({ releaseVersion }, parameters) => <CreateDeploymentPage goal={createDeploymentGoal(parameters)} releaseVersion={releaseVersion}/>, { pageArea: "Project", pageId: "Project.DeploymentNew", pageName: "Add New Deployment" }, ({ spaceId, projectSlug, releaseVersion }, { previousDeploymentId, tenantIds, environmentIds }) => {
                                                            if (previousDeploymentId) {
                                                                return routeLinks.forSpace(spaceId).project(projectSlug).deployments.release(releaseVersion).deployments.retry(previousDeploymentId);
                                                            }
                                                            return routeLinks
                                                                .forSpace(spaceId)
                                                                .project(projectSlug)
                                                                .deployments.release(releaseVersion)
                                                                .deployments.create(DeploymentCreateGoal.To, environmentIds?.join(","), tenantIds?.join(","));
                                                        }),
                                                        editReleasePage: (r) => createProjectPageRegistration(r, () => <CreateOrEditReleasePage />, { pageArea: "Project", pageId: "Project.ReleaseVersionEdit", pageName: "Edit Release Version" }, ({ spaceId, projectSlug, releaseVersion }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.release(releaseVersion).edit),
                                                        deploymentDetailsPage: (r) => createProjectPageRegistration(r, ({ deploymentId }) => <DeploymentDetailsPage deploymentId={deploymentId}/>, { pageArea: "Project", pageId: "Project.Deployment", pageName: "Deployment" }, ({ spaceId, projectSlug, releaseVersion, deploymentId }) => routeLinks.forSpace(spaceId).project(projectSlug).deployments.release(releaseVersion).deployments.specific(deploymentId)),
                                                    },
                                                },
                                            },
                                        },
                                        variables: {
                                            partialRoute: (r) => r,
                                            pages: {
                                                commonVariableTemplatesPage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <CommonVariableTemplates projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.VariableCommonTemplates", pageName: "Common Variables Templates" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.commonTemplates),
                                                projectTenantVariablesPage: (r) => createProjectPageRegistration(r, () => <ProjectTenantVariables />, { pageArea: "Project", pageId: "Project.TenantVariables", pageName: "Tenant Variables" }, ({ spaceId, projectSlug }, queryParams) => {
                                                    if (queryParams?.activeTab) {
                                                        return `${routeLinks.forSpace(spaceId).project(projectSlug).variables.tenant}?activeTab=${queryParams.activeTab}`;
                                                    }
                                                    return routeLinks.forSpace(spaceId).project(projectSlug).variables.tenant;
                                                }),
                                                libraryVariablesPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug }) => <LibraryVariableSets spaceId={spaceId} projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.VariableLibrary", pageName: "Variables Library" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.library),
                                                variableTemplatesPage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <ProjectVariableTemplates projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.VariableTemplates", pageName: "Variables Templates" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.projectTemplates),
                                            },
                                            childGroups: {
                                                all: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        allVariablesPage: (r) => createProjectPageRegistration(r, () => <AllVariables />, { pageArea: "Project", pageId: "Project.VariableAll", pageName: "All Variables" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.all),
                                                    },
                                                },
                                                preview: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        variablesPreviewPage: (r) => createProjectPageRegistration(r, () => <VariablePreview />, { pageArea: "Project", pageId: "Project.VariablePreview", pageName: "Variables Preview" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.preview),
                                                    },
                                                },
                                                wrapper: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        variablesPage: (r) => createProjectPageRegistration(r, () => <ProjectVariables />, { pageArea: "Project", pageId: "Project.Variables", pageName: "Variables" }, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).variables.root),
                                                    },
                                                },
                                            },
                                        },
                                        specificRunbook: {
                                            partialRoute: (r) => r,
                                            childGroups: {
                                                process: {
                                                    partialRoute: (r) => r,
                                                    childGroups: {},
                                                    pages: {
                                                        projectRunbookProcessListPage: (r) => createProjectPageRegistration(r, () => <RunbookProcessListPage />, { pageArea: "Project", pageId: "Project.Runbook.Process", pageName: "Runbook Process" }, ({ spaceId, projectSlug, runbookId, processId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookProcess.runbookProcess(processId).root),
                                                        projectRunbookProcessStepsPage: (r) => createProjectPageRegistration(r, () => <RunbookProcessStepsPage />, { pageArea: "Project", pageId: "Project.Runbook.Process", pageName: "Runbook Process" }, ({ spaceId, projectSlug, runbookId, processId }, parameters) => {
                                                            const search = generateQueryString(r.queryParameters, parameters);
                                                            return `${routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookProcess.runbookProcess(processId).process.root}/steps${search}`;
                                                        }),
                                                    },
                                                },
                                            },
                                            pages: {
                                                projectRunbookOverviewPage: (r) => createProjectPageRegistration(r, ({ spaceId, projectSlug, runbookId }) => <RunbookOverviewPage spaceId={spaceId} projectSlug={projectSlug} runbookId={runbookId}/>, { pageArea: "Project", pageId: "Project.Runbook.Overview", pageName: "Runbook Overview" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).overview),
                                                projectRunbookRunsListPage: (r) => createProjectPageRegistration(r, () => <RunbookRunsListPage />, { pageArea: "Project", pageId: "Project.Runbook.RunsList", pageName: "Runbook Runs List" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runslist),
                                                projectRunbookSettingsPage: (r) => createProjectPageRegistration(r, ({ runbookId }) => <RunbookSettingsPage runbookId={runbookId}/>, { pageArea: "Project", pageId: "Project.Runbook.Settings", pageName: "Runbook Settings" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).settings),
                                                projectRunbookSnapshotsPage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <RunbookSnapshotsPage projectSlug={projectSlug}/>, { pageArea: "Project", pageId: "Project.RunbookSnapshots", pageName: "RunbookSnapshots" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookSnapshots),
                                                projectRunbookSnapshotCreatePage: (r) => createProjectPageRegistration(r, ({ projectSlug }) => <RunbookSnapshotEditPage />, { pageArea: "Project", pageId: "Project.RunbookSnapshotNew", pageName: "Add New RunbookSnapshot" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookSnapshotCreate),
                                                projectRunbookSnapshotEditPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }) => <RunbookSnapshotEditPage runbookSnapshotId={runbookSnapshotId}/>, { pageArea: "Project", pageId: "Project.RunbookSnapshotEdit", pageName: "Edit RunbookSnapshot" }, ({ spaceId, projectSlug, runbookId, runbookSnapshotId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookSnapshot(runbookSnapshotId).edit),
                                                projectRunbookSnapshotInfoPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }) => <RunbookSnapshotInfoPage runbookSnapshotId={runbookSnapshotId}/>, { pageArea: "Project", pageId: "Project.RunbookSnapshot", pageName: "RunbookSnapshot" }, ({ spaceId, projectSlug, runbookId, runbookSnapshotId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookSnapshot(runbookSnapshotId).root),
                                                projectRunbookRunDetailPage: (r) => createProjectPageRegistration(r, ({ runbookRunId }) => <RunbookRunDetailsPage runbookRunId={runbookRunId}/>, { pageArea: "Project", pageId: "Project.RunbookRun", pageName: "RunbookRun" }, ({ spaceId, projectSlug, runbookId, runbookSnapshotId, runbookRunId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookSnapshot(runbookSnapshotId).runbookRuns.specific(runbookRunId)),
                                                createRunbookRunForSnapshotPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }, parameters) => <CreateRunbookRunForSnapshotPage runbookSnapshotId={runbookSnapshotId} goal={createRunbookRunGoal(parameters)}/>, { pageArea: "Project", pageId: "Project.RunbookRunNew", pageName: "Add New RunbookRun" }, ({ spaceId, projectSlug, runbookId, runbookSnapshotId }, { previousRunbookRunId, environmentIds }) => {
                                                    if (previousRunbookRunId) {
                                                        return routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runbookSnapshot(runbookSnapshotId).runbookRuns.retry(previousRunbookRunId);
                                                    }
                                                    const environmentIdsForLegacyRoute = environmentIds && environmentIds.length > 0 ? environmentIds.join(",") : undefined;
                                                    return routeLinks
                                                        .forSpace(spaceId)
                                                        .project(projectSlug)
                                                        .operations.runbook(runbookId)
                                                        .runbookSnapshot(runbookSnapshotId)
                                                        .runbookRuns.create(RunCreateGoalValues.To, environmentIdsForLegacyRoute);
                                                }),
                                                runbookRunNowPage: (r) => createProjectPageRegistration(r, () => <RunbookRunNowPage />, { pageArea: "Project", pageId: "Project.Runbook.Run", pageName: "Runbook Run" }, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runNow.root),
                                                runbookRunSnapshotNowPage: (r) => createProjectPageRegistration(r, ({ runbookSnapshotId }) => <RunbookRunNowPage runbookSnapshotId={runbookSnapshotId}/>, { pageArea: "Project", pageId: "Project.Runbook.Run", pageName: "Runbook Run" }, ({ spaceId, projectSlug, runbookId, runbookSnapshotId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).runNow.runbookSnapshot(runbookSnapshotId)),
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                infrastructure: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        infrastructureOverviewPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <InfrastructureDashboardPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Overview", pageName: "Overview" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.overview),
                        infrastructureEnvironmentsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <EnvironmentsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Environments", pageName: "Environments" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.environments.root),
                        infrastructureEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ environmentId }) => <EnvironmentPage environmentId={environmentId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Environment", pageName: "Environment" }, ({ spaceId, environmentId }) => routeLinks.forSpace(spaceId).infrastructure.environment(environmentId)),
                        workerPoolsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <WorkerPoolsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerPools", pageName: "Worker Pools" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.workerPools.root),
                        editWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, ({ workerPoolId }) => <WorkerPoolPage workerPoolId={workerPoolId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerPool", pageName: "Worker Pool" }, ({ spaceId, workerPoolId }) => routeLinks.forSpace(spaceId).infrastructure.workerPool(workerPoolId)),
                        deploymentTargetsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <DeploymentTargetsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargets", pageName: "Deployment Targets" }, ({ spaceId }, queryParameters) => {
                            const search = generateQueryString(r.queryParameters, queryParameters);
                            return `${routeLinks.forSpace(spaceId).infrastructure.machines.root}${search}`;
                        }),
                        newDeploymentTargetPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <MachineTargetNewPage spaceId={spaceId} environmentId={undefined}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsSelect", pageName: "Add New Deployment Target Selection" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.machines.new()),
                        newDeploymentTargetWithEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ environmentId, spaceId }) => (<InfrastructureLayout>
                                        <MachineTargetNewPage spaceId={spaceId} environmentId={environmentId}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsSelect", pageName: "Add New Deployment Target Selection" }, ({ spaceId, environmentId }) => routeLinks.forSpace(spaceId).infrastructure.machines.new(environmentId)),
                        createDeploymentTargetPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <DeploymentTargetSettingsPage spaceId={spaceId}/>
                                    </InfrastructureLayout>), {
                            pageArea: "Infrastructure (Windows, Linux, Mac, Azure, Listening Tentacle, Polling Tentacle, SSH Connection, Offline Package Drop, Azure Web App, Azure Cloud Service, Service Fabric Cluster, Kubernetes (K8s) Cluster, Cloud Region, Step Package)",
                            pageId: "Infrastructure.DeploymentTargetsNew",
                            pageName: "Add New Deployment Target",
                        }, ({ spaceId }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.machines.create(queryParams)),
                        deploymentTargetConnectionPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <DeploymentTargetConnectionsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetConnectivity", pageName: "Deployment Target Connectivity" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.machine(machineId).connection),
                        deploymentTargetSettingsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, machineId }) => (<MachineLayout>
                                        <DeploymentTargetSettingsPage spaceId={spaceId} machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetSettings", pageName: "Deployment Target Settings" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.machine(machineId).settings),
                        deploymentTargetDeploymentsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <MachineDeploymentsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetDeployments", pageName: "Deployment Target Deployments" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.deploymentTarget(machineId).deployments),
                        deploymentTargetRunbookRunsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <MachineRunbookRunsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetRunbookRuns", pageName: "Deployment Target Runbook Runs" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.deploymentTarget(machineId).runbookRuns),
                        deploymentTargetEventsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<MachineLayout>
                                        <MachineEventsPage machineId={machineId}/>
                                    </MachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetEvents", pageName: "Deployment Target Events" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.machine(machineId).events),
                        discoverMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, endpointKey }) => (<InfrastructureLayout>
                                        <DeploymentTargetDiscoveryPage spaceId={spaceId} endpointKey={endpointKey}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsDiscover", pageName: "Discover Deployment Target" }, ({ spaceId, endpointKey }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.machines.discover(endpointKey, undefined, queryParams.category)),
                        discoverMachineWithEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, endpointKey, environmentId }) => (<InfrastructureLayout>
                                        <DeploymentTargetDiscoveryPage spaceId={spaceId} endpointKey={endpointKey} environmentId={environmentId}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.DeploymentTargetsDiscover", pageName: "Discover Deployment Target" }, ({ spaceId, endpointKey, environmentId }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.machines.discover(endpointKey, environmentId, queryParams.category)),
                        createWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <WorkerMachineSettingsPage spaceId={spaceId}/>
                                    </InfrastructureLayout>), {
                            pageArea: "Infrastructure (Windows, Linux, Mac, Listening Tentacle, Polling Tentacle, SSH Connection)",
                            pageId: "Infrastructure.WorkersNew",
                            pageName: "Add New Worker",
                        }, ({ spaceId }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.workerMachines.create(queryParams)),
                        workerMachinesPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <WorkerMachinesPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Workers", pageName: "Workers" }, ({ spaceId }, queryParameters) => {
                            const search = generateQueryString(r.queryParameters, queryParameters);
                            return `${routeLinks.forSpace(spaceId).infrastructure.workerMachines.root}${search}`;
                        }),
                        newWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => (<InfrastructureLayout>
                                        <WorkerMachineNewPage spaceId={spaceId} workerPoolId={undefined}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersSelect", pageName: "Add New Worker Selection" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.workerMachines.new()),
                        newWorkerMachineWithWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, workerPoolId }) => (<InfrastructureLayout>
                                        <WorkerMachineNewPage spaceId={spaceId} workerPoolId={workerPoolId}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersSelect", pageName: "Add New Worker Selection" }, ({ spaceId, workerPoolId }) => routeLinks.forSpace(spaceId).infrastructure.workerMachines.new(workerPoolId)),
                        discoverWorkerMachinePage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, endpointKey }) => (<InfrastructureLayout>
                                        <WorkerMachineDiscoveryPage spaceId={spaceId} endpointKey={endpointKey}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersDiscover", pageName: "Discover Worker" }, ({ spaceId, endpointKey }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.workerMachines.discover(endpointKey, undefined, queryParams.category)),
                        discoverWorkerMachineWithWorkerPoolPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, workerPoolId, endpointKey }) => (<InfrastructureLayout>
                                        <WorkerMachineDiscoveryPage spaceId={spaceId} workerPoolId={workerPoolId} endpointKey={endpointKey}/>
                                    </InfrastructureLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkersDiscover", pageName: "Discover Worker" }, ({ spaceId, endpointKey, workerPoolId }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.workerMachines.discover(endpointKey, workerPoolId, queryParams.category)),
                        workerMachineConnectionPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<WorkerMachineLayout>
                                        <WorkerMachineConnectionsPage machineId={machineId}/>
                                    </WorkerMachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerConnectivity", pageName: "Worker Connectivity" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.workerMachine(machineId).connection),
                        workerMachineSettingsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, machineId }) => (<WorkerMachineLayout>
                                        <WorkerMachineSettingsPage spaceId={spaceId} machineId={machineId}/>
                                    </WorkerMachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerSettings", pageName: "Worker Settings" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.workerMachine(machineId).settings),
                        workerMachineEventsPage: (r) => createInfrastructurePageRegistration(r, ({ machineId }) => (<WorkerMachineLayout>
                                        <MachineEventsPage machineId={machineId}/>
                                    </WorkerMachineLayout>), { pageArea: "Infrastructure", pageId: "Infrastructure.WorkerEvents", pageName: "Worker Events" }, ({ spaceId, machineId }) => routeLinks.forSpace(spaceId).infrastructure.workerMachine(machineId).events),
                        machinePoliciesPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <MachinePoliciesPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.MachinePolicies", pageName: "Machine Policies" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.machinePolicies.root),
                        editMachinePolicyPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, machinePolicyId }) => <MachinePolicyPage spaceId={spaceId} machinePolicyId={machinePolicyId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.MachinePolicy", pageName: "Machine Policy" }, ({ spaceId, machinePolicyId }) => routeLinks.forSpace(spaceId).infrastructure.machinePolicy(machinePolicyId)),
                        createMachinePolicyPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <MachinePolicyPage spaceId={spaceId} create={true}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.MachinePoliciesNew", pageName: "Add New Machine Policy" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.machinePolicies.create),
                        proxiesPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <ProxiesPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Proxies", pageName: "Proxies" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.proxies.root),
                        editProxyPage: (r) => createInfrastructurePageRegistration(r, ({ proxyId }) => <ProxyPage proxyId={proxyId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Proxy", pageName: "Proxy" }, ({ spaceId, proxyId }) => routeLinks.forSpace(spaceId).infrastructure.proxy(proxyId)),
                        createProxyPage: (r) => createInfrastructurePageRegistration(r, () => <ProxyPage create={true}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.ProxiesNew", pageName: "Add New Proxy" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.proxies.create),
                        infrastructureAccountsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <AccountsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Accounts", pageName: "Accounts" }, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.accounts.root),
                        editInfrastructureAccountPage: (r) => createInfrastructurePageRegistration(r, ({ accountId }) => <AccountPage createNew={false} accountId={accountId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.Account", pageName: "Account" }, ({ spaceId, accountId }) => routeLinks.forSpace(spaceId).infrastructure.account(accountId)),
                        createInfrastructureAccountPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }, queryParams) => {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            const accountType = AccountType[queryParams.accountType as keyof typeof AccountType];
                            return <AccountPage createNew={true} accountType={accountType}/>;
                        }, { pageArea: "Infrastructure", pageId: "Infrastructure.AccountsNew", pageName: "Add New Account" }, ({ spaceId }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.accounts.create(queryParams.accountType)),
                        dynamicEnvironmentsPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId }) => <DynamicEnvironmentsPage spaceId={spaceId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.DynamicEnvironments", pageName: "Dynamic Environments" }, ({ spaceId }, queryParams) => routeLinks.forSpace(spaceId).infrastructure.dynamicEnvironments.root(queryParams.environmentDeleted)),
                        dynamicEnvironmentPage: (r) => createInfrastructurePageRegistration(r, ({ spaceId, environmentId }) => <DynamicEnvironmentPage spaceId={spaceId} environmentId={environmentId}/>, { pageArea: "Infrastructure", pageId: "Infrastructure.DynamicEnvironment", pageName: "Dynamic Environment" }, ({ spaceId, environmentId }) => routeLinks.forSpace(spaceId).infrastructure.dynamicEnvironment(environmentId).overview),
                    },
                },
                tenants: {
                    partialRoute: (r) => r,
                    childGroups: {
                        specificTenant: {
                            partialRoute: (r) => r,
                            pages: {
                                tenantOverviewPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantOverviewPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Overview", pageName: "Tenant Overview" }, ({ spaceId, tenantId }) => routeLinks.forSpace(spaceId).tenant(tenantId).overview),
                                tenantVariablesPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantVariablesPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Variables", pageName: "Tenant Variables" }, ({ spaceId, tenantId }) => routeLinks.forSpace(spaceId).tenant(tenantId).variables().pathname),
                                tenantSettingsPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantSettingsPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Settings", pageName: "Tenant Settings" }, ({ spaceId, tenantId }) => routeLinks.forSpace(spaceId).tenant(tenantId).settings),
                                tenantTasksPage: (r) => createTenantPageRegistration(r, ({ tenantId }) => <TenantTasksPage tenantId={tenantId}/>, { pageArea: "Tenants", pageId: "Tenant.Tasks", pageName: "Tenant Tasks" }, ({ spaceId, tenantId }) => routeLinks.forSpace(spaceId).tenant(tenantId).tasks),
                            },
                            childGroups: {},
                        },
                    },
                    pages: {
                        tenantsPage: (r) => createTenantPageRegistration(r, ({ spaceId }) => <TenantsPage spaceId={spaceId}/>, { pageArea: "Tenants", pageId: "Tenants", pageName: "Tenants" }, ({ spaceId }) => routeLinks.forSpace(spaceId).tenants),
                    },
                },
                library: {
                    partialRoute: (r) => r,
                    childGroups: {},
                    pages: {
                        builtInRepositoryPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <BuiltInRepositoryPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.BuiltInRepository", pageName: "Packages" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.builtInRepository.root),
                        packageDetailsPage: (r) => createLibraryPageRegistration(r, ({ spaceId, id }) => <PackageDetailsPage spaceId={spaceId} packageId={id}/>, { pageArea: "Library", pageId: "Library.BuiltInRepositoryPackage", pageName: "Package Details" }, ({ spaceId, id }) => routeLinks.forSpace(spaceId).library.builtInRepository.package(id)),
                        packageVersionsListPage: (r) => createLibraryPageRegistration(r, ({ spaceId, packageId }) => <PackageVersionsListPage spaceId={spaceId} packageId={packageId}/>, { pageArea: "Library", pageId: "Library.BuiltInRepositoryPackageVersions", pageName: "Package Versions" }, ({ spaceId, packageId }) => routeLinks.forSpace(spaceId).library.builtInRepository.versions(packageId)),
                        buildInformationOverviewPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <BuildInformationOverviewPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.BuildInformationRepository", pageName: "Build Information" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.buildInformationRepository.root),
                        buildInformationVersionsPage: (r) => createLibraryPageRegistration(r, ({ spaceId, packageId }) => <BuildInformationVersionsPage spaceId={spaceId} packageId={packageId}/>, { pageArea: "Library", pageId: "Library.BuildInformationRepositoryPackageVersions", pageName: "Build Information Versions" }, ({ spaceId, packageId }) => routeLinks.forSpace(spaceId).library.buildInformationRepository.versions(packageId)),
                        buildInformationDetailPage: (r) => createLibraryPageRegistration(r, ({ spaceId, id }) => <BuildInformationDetailPage spaceId={spaceId} id={id}/>, { pageArea: "Library", pageId: "Library.BuildInformationRepositoryPackage", pageName: "Build Information Details" }, ({ spaceId, id }) => routeLinks.forSpace(spaceId).library.buildInformationRepository.package(id)),
                        feedsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ExternalFeedsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.Feeds", pageName: "External Feeds" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.feeds),
                        createFeedPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ExternalFeedPage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.FeedsNew", pageName: "Add New External Feed" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.feedCreate),
                        editFeedPage: (r) => createLibraryPageRegistration(r, ({ spaceId, feedId }) => <ExternalFeedPage spaceId={spaceId} feedId={feedId}/>, { pageArea: "Library", pageId: "Library.Feed", pageName: "External Feed" }, ({ spaceId, feedId }) => routeLinks.forSpace(spaceId).library.feed(feedId).root),
                        testFeedPage: (r) => createLibraryPageRegistration(r, ({ spaceId, feedId }) => <ExternalFeedTestPage spaceId={spaceId} feedId={feedId}/>, { pageArea: "Library", pageId: "Library.FeedTest", pageName: "Test External Feed" }, ({ spaceId, feedId }) => routeLinks.forSpace(spaceId).library.feed(feedId).test),
                        lifecyclesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <LifecyclesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.Lifecycles", pageName: "Lifecycles" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.lifecycles),
                        createLifecyclePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <LifecyclePage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.LifecyclesNew", pageName: "Add New Lifecycle" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.lifecyclesCreate),
                        editLifecyclePage: (r) => createLibraryPageRegistration(r, ({ spaceId, lifecycleId }) => <LifecyclePage spaceId={spaceId} lifecycleId={lifecycleId}/>, { pageArea: "Library", pageId: "Library.Lifecycle", pageName: "Lifecycle" }, ({ spaceId, lifecycleId }) => routeLinks.forSpace(spaceId).library.lifecycle(lifecycleId)),
                        scriptModulesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ScriptModulesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.ScriptModules", pageName: "Script Modules" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.scripts.root),
                        createScriptModulePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ScriptModulePage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.ScriptModulesNew", pageName: "Add New Script Module" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.scripts.create),
                        editScriptModulePage: (r) => createLibraryPageRegistration(r, ({ spaceId, scriptModuleId }) => <ScriptModulePage spaceId={spaceId} scriptModuleId={scriptModuleId}/>, { pageArea: "Library", pageId: "Library.ScriptModule", pageName: "Script Module" }, ({ spaceId, scriptModuleId }) => routeLinks.forSpace(spaceId).library.script(scriptModuleId)),
                        stepTemplatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ActionTemplatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.StepTemplates", pageName: "Library Step Templates" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.stepTemplates.root),
                        builtInStepTemplatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <BuiltinTemplatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.StepTemplatesBuiltIn", pageName: "Built-in Step Templates" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.stepTemplates.builtIn),
                        communityStepTemplatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <CommunityTemplatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.StepTemplatesCommunity", pageName: "Community Step Templates" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.stepTemplates.community),
                        communityStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => <CommunityTemplatePage spaceId={spaceId} templateId={templateId}/>, { pageArea: "Library", pageId: "Library.StepTemplatesCommunityTemplate", pageName: "Community Step Template" }, ({ spaceId, templateId }) => routeLinks.forSpace(spaceId).library.stepTemplates.communityTemplate(templateId).root),
                        createStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, actionType }) => (<ActionTemplateLayout>
                                        <ActionTemplatePage spaceId={spaceId} actionType={actionType}/>
                                    </ActionTemplateLayout>), { pageArea: "Library", pageId: "Library.StepTemplateNew", pageName: "Add New Step Template" }, ({ spaceId, actionType }) => routeLinks.forSpace(spaceId).library.stepTemplates.new(actionType)),
                        editStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => (<ActionTemplateLayout>
                                        <ActionTemplatePage spaceId={spaceId} templateId={templateId}/>
                                    </ActionTemplateLayout>), { pageArea: "Library", pageId: "Library.StepTemplate", pageName: "Step Template" }, ({ spaceId, templateId }) => routeLinks.forSpace(spaceId).library.stepTemplate(templateId).root),
                        runStepTemplatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => <RunActionTemplatePage spaceId={spaceId} templateId={templateId}/>, { pageArea: "Library", pageId: "Library.StepTemplateRun", pageName: "Run Step Template" }, ({ spaceId, templateId }, queryParameters) => {
                            const search = generateQueryString(r.queryParameters, queryParameters);
                            return `${routeLinks.forSpace(spaceId).library.stepTemplate(templateId).run}${search}`;
                        }),
                        stepTemplateUsagePage: (r) => createLibraryPageRegistration(r, ({ spaceId, templateId }) => (<ActionTemplateLayout>
                                        <ActionTemplateUsagePage spaceId={spaceId} templateId={templateId}/>
                                    </ActionTemplateLayout>), { pageArea: "Library", pageId: "Library.StepTemplateUsage", pageName: "Step Template Usage" }, ({ spaceId, templateId }) => routeLinks.forSpace(spaceId).library.stepTemplate(templateId).usage),
                        archiveCertificatePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <ArchivedCertificatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.CertificatesArchive", pageName: "Certificates Archive" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.certificates.archive),
                        tagSetsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <TagSetsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.TenantTagSets", pageName: "Tenant Tag Sets" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.tagSets.root),
                        createTagSetPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <TagSetPage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.TenantTagSetsNew", pageName: "Add New Tenant Tag Set" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.tagSets.create),
                        editTagSetPage: (r) => createLibraryPageRegistration(r, ({ spaceId, tagSetId }) => <TagSetPage spaceId={spaceId} tagSetId={tagSetId}/>, { pageArea: "Library", pageId: "Library.TenantTagSet", pageName: "Tenant Tag Set" }, ({ spaceId, tagSetId }) => routeLinks.forSpace(spaceId).library.tagSet(tagSetId)),
                        variableSetsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <VariableSetsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.VariableSets", pageName: "Variable Sets" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.variableSets),
                        editVariableSetPage: (r) => createLibraryPageRegistration(r, ({ spaceId, variableSetId }) => <EditVariableSetPage spaceId={spaceId} variableSetId={variableSetId}/>, { pageArea: "Library", pageId: "Library.VariableSet", pageName: "Variable Set" }, ({ spaceId, variableSetId }, queryParams) => {
                            if (queryParams?.activeTab) {
                                return `${routeLinks.forSpace(spaceId).library.variableSet(variableSetId)}?activeTab=${queryParams.activeTab}`;
                            }
                            return routeLinks.forSpace(spaceId).library.variableSet(variableSetId);
                        }),
                        gitCredentialsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <GitCredentialsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.GitCredentials", pageName: "Git Credentials" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.gitCredentials.root),
                        createGitCredentialPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <GitCredentialPage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.GitCredentialsNew", pageName: "Add New Git Credential" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.gitCredentials.create),
                        editGitCredentialPage: (r) => createLibraryPageRegistration(r, ({ spaceId, gitCredentialId }) => <GitCredentialPage spaceId={spaceId} gitCredentialId={gitCredentialId}/>, { pageArea: "Library", pageId: "Library.GitCredential", pageName: "Git Credential" }, ({ spaceId, gitCredentialId }) => routeLinks.forSpace(spaceId).library.gitCredentials.credential(gitCredentialId)),
                        gitConnectionsPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <GitConnectionsPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.GitConnections", pageName: "Git Connections" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.gitConnections.root),
                        createGitHubConnectionPage: (r) => createLibraryPageRegistration(r, ({ spaceId, installationId }) => <AddGitHubConnectionPage spaceId={spaceId} installationId={installationId}/>, { pageArea: "Library", pageId: "Library.GitConnectionsAddGitHub", pageName: "Add GitHub Connection" }, ({ spaceId, installationId }) => routeLinks.forSpace(spaceId).library.gitConnections.addGitHubConnection(installationId)),
                        editGitHubConnectionPage: (r) => createLibraryPageRegistration(r, ({ spaceId, connectionId }) => <GitHubConnectionPage spaceId={spaceId} connectionId={connectionId}/>, { pageArea: "Library", pageId: "Library.GitConnectionsGetGitHub", pageName: "GitHub Connection" }, ({ spaceId, connectionId }) => routeLinks.forSpace(spaceId).library.gitConnections.getGitHubConnection(connectionId)),
                        createCertificatePage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <CertificatePage spaceId={spaceId} create={true}/>, { pageArea: "Library", pageId: "Library.CertificatesNew", pageName: "Add New Certificate" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.certificates.create),
                        editCertificatePage: (r) => createLibraryPageRegistration(r, ({ spaceId, certificateId }) => <CertificatePage spaceId={spaceId} create={false} certificateId={certificateId}/>, { pageArea: "Library", pageId: "Library.Certificate", pageName: "Certificate" }, ({ spaceId, certificateId }) => routeLinks.forSpace(spaceId).library.certificate(certificateId)),
                        certificatesPage: (r) => createLibraryPageRegistration(r, ({ spaceId }) => <CertificatesPage spaceId={spaceId}/>, { pageArea: "Library", pageId: "Library.Certificates", pageName: "Certificates" }, ({ spaceId }) => routeLinks.forSpace(spaceId).library.certificates.root),
                    },
                },
                insights: {
                    partialRoute: (r) => r,
                    pages: {
                        insightReportListPage: (r) => createInsightsPageRegistration(r, ({ spaceId }) => <InsightsReportListPage spaceId={spaceId}/>, { pageArea: "Insights", pageId: "Insights.Reports", pageName: "Insights Reports" }, ({ spaceId }) => routeLinks.forSpace(spaceId).insights.reports),
                    },
                    childGroups: {
                        specificReport: {
                            partialRoute: (p) => p,
                            childGroups: {},
                            pages: {
                                insightsReportOverviewPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportOverviewPage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.Overview", pageName: "Insights Report Overview" }, ({ spaceId, reportId }, reportFilter) => routeLinks.forSpace(spaceId).insights.report(reportId).overview + generateInsightsReportFilterQueryString(reportFilter)),
                                insightsReportDeploymentFrequencyPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentFrequencyPage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.DeploymentFrequency", pageName: "Insights Report Deployment Frequency" }, ({ spaceId, reportId }, reportFilter) => routeLinks.forSpace(spaceId).insights.report(reportId).frequency + generateInsightsReportFilterQueryString(reportFilter)),
                                insightsReportDeploymentLeadTimePage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentLeadTimePage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.DeploymentLeadTime", pageName: "Insights Report Deployment Lead Time" }, ({ spaceId, reportId }, reportFilter) => routeLinks.forSpace(spaceId).insights.report(reportId).leadTime + generateInsightsReportFilterQueryString(reportFilter)),
                                insightsReportDeploymentFailureRatePage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentFailureRatePage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.DeploymentFailureRate", pageName: "Insights Report Failure Rate" }, ({ spaceId, reportId }, reportFilter) => routeLinks.forSpace(spaceId).insights.report(reportId).failureRate + generateInsightsReportFilterQueryString(reportFilter)),
                                insightsReportDeploymentMeanTimeToRecoveryPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentMeanTimeToRecoveryPage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.MeanTimeToRecovery", pageName: "Insights Report Mean Time to Recovery" }, ({ spaceId, reportId }, reportFilter) => routeLinks.forSpace(spaceId).insights.report(reportId).timeToRecovery + generateInsightsReportFilterQueryString(reportFilter)),
                                insightsReportDeploymentDurationPage: (r) => createInsightsReportPageRegistration(r, (report) => <InsightsReportDeploymentDurationPage report={report}/>, { pageArea: "Insights", pageId: "Insights.Report.DeploymentDuration", pageName: "Insights Report Deployment Duration" }, ({ spaceId, reportId }, reportFilter) => routeLinks.forSpace(spaceId).insights.report(reportId).deploymentDuration + generateInsightsReportFilterQueryString(reportFilter)),
                                insightsReportSettingsPage: (r) => createInsightsReportPageRegistration(r, (report, refreshReport) => <InsightsReportSettingsPage report={report} refreshReport={refreshReport}/>, { pageArea: "Insights", pageId: "Insights.Report.Settings", pageName: "Insights Report Settings" }, ({ spaceId, reportId }) => routeLinks.forSpace(spaceId).insights.report(reportId).settings),
                            },
                        },
                    },
                },
            },
        },
    },
});
export const allPagesFlattened = getPagesFromPageMap(allPages);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPagesFromPageMap<ChildGroups extends Record<string, PageGroup<any, any, any>>, PageMap extends PageGroup<any, ChildGroups, any>>(pageMap: PageMap): UnknownPageRegistration[] {
    const childGroupPages = Object.values(pageMap.childGroups).flatMap((childGroup) => getPagesFromPageMap(childGroup));
    const entries: UnknownPageRegistration[] = Object.values(pageMap.pages);
    return [...entries, ...childGroupPages];
}
function generateInsightsReportFilterQueryString(reportFilter: ReportFilter) {
    return generateQueryString([insightsSplitParam, insightsCadenceParam, insightsTenantTagSetIdParam], reportFilter);
}
function generateProjectInsightsReportFilterQueryString(filter: ProjectInsightsFilterQueryParams) {
    return generateQueryString([projectInsightsChannelIdParam, projectInsightsEnvironmentIdParam, insightsCadenceParam, projectInsightsTenantIdParam], filter);
}
function createRunbookRunGoal({ previousRunbookRunId, ...rest }: {
    environmentIds?: string[];
    previousRunbookRunId?: string;
}): RunGoal {
    if (previousRunbookRunId !== undefined) {
        return { previousRunbookRunId };
    }
    return { ...rest };
}
function createDeploymentGoal({ previousDeploymentId, ...rest }: {
    environmentIds?: string[];
    previousDeploymentId?: string;
    tenantIds?: string[];
    tenantTags?: string[];
}): CreateDeploymentGoal {
    if (previousDeploymentId !== undefined) {
        return { previousDeploymentId };
    }
    return { ...rest };
}
