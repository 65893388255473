import { useSetPageEffect } from "@octopusdeploy/portal-routes";
import React from "react";
import type { ReactNode } from "react";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import type { PageIdentity } from "~/routing/pageRegistrations/PageIdentity";
interface PageProps {
    pageIdentity: PageIdentity;
    children: ReactNode;
}
export function Page({ pageIdentity, children }: PageProps) {
    useSetPageEffect({
        Area: pageIdentity.pageArea,
        Name: pageIdentity.pageName,
        Id: pageIdentity.pageId,
    });
    return <ErrorContextProvider>{children}</ErrorContextProvider>;
}
