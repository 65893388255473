import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import classNames from "classnames";
import React from "react";
import Card from "~/components/Card";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { GitIcon } from "~/primitiveComponents/dataDisplay/Icon";
import GitHubIcon from "~/primitiveComponents/dataDisplay/Icon/GitHubIcon";
export enum VersionControlSettingsType {
    GitHub,
    Generic
}
export interface VersionControlSettingsTypeSelectorProps {
    selectedType: VersionControlSettingsType | undefined;
    setSelectedType: (type: VersionControlSettingsType) => void;
}
export default function VersionControlSettingsTypeSelector(props: VersionControlSettingsTypeSelectorProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            gap: space[16],
            padding: space[16],
        }),
        versionControlTypeCard: css({
            cursor: "pointer",
        }),
        versionControlTypeCardInner: css({
            cursor: "pointer",
        }),
    };
    return (<div className={styles.container}>
            <VersionControlSettingsTypeCard type={VersionControlSettingsType.GitHub} title="GitHub" {...props}/>
            <VersionControlSettingsTypeCard type={VersionControlSettingsType.Generic} title="Other Git Provider" {...props}/>
        </div>);
}
interface VersionControlSettingsTypeCardProps extends VersionControlSettingsTypeSelectorProps {
    type: VersionControlSettingsType;
    title: string;
}
function VersionControlSettingsTypeCard({ type, title, selectedType, setSelectedType }: VersionControlSettingsTypeCardProps) {
    const themePaletteType = useThemePaletteType();
    const styles = {
        container: css({
            background: "none",
            border: "none",
            padding: 0,
            margin: 0,
            cursor: "pointer",
            outline: "inherits",
            font: "inherit",
        }),
        cardSelected: css({
            background: `${themeTokens.color.background.selected} !important`,
            color: `${themePaletteType === "dark" ? "inherit" : themeTokens.color.text.inverse} !important`,
        }),
        headerSelected: css({
            color: `${themePaletteType === "dark" ? "inherit" : themeTokens.color.text.inverse}`,
        }),
    };
    const isSelected = selectedType === type;
    return (<button className={styles.container} onClick={() => setSelectedType(type)} name={title}>
            <Card disableMargin className={classNames(isSelected ? styles.cardSelected : undefined)} logo={<VersionControlSettingsTypeLogo type={type}/>} header={<div className={classNames(isSelected ? styles.headerSelected : undefined)}>{title}</div>} content={undefined}/>
        </button>);
}
interface VersionControlSettingsTypeLogoProps {
    type: VersionControlSettingsType;
}
function VersionControlSettingsTypeLogo({ type }: VersionControlSettingsTypeLogoProps) {
    const styles = {
        logo: css({
            width: `${space[32]} !important`,
            height: `${space[32]} !important`,
        }),
    };
    return type === VersionControlSettingsType.GitHub ? <GitHubIcon className={styles.logo}/> : <GitIcon className={styles.logo}/>;
}
