import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "UtoWLWTjIE8",
    shortlink: "OnboardingVersionControlledProjectVideo",
};
export const CommonProjectGitView = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="ConfigAsCode">Config as code</ExternalLink> allows you to store your project configuration in version control.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
