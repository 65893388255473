import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactNode } from "react";
import { useState } from "react";
import * as React from "react";
import type { HelpPanelImage } from "~/components/OnboardingDialog/HelpPanel";
import type { OnboardingDialogProps } from "~/components/OnboardingDialog/OnboardingDialog";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import type { OnboardingDialogLayoutProps } from "~/components/OnboardingDialog/OnboardingDialogLayout";
import { OnboardingDialogLayout } from "~/components/OnboardingDialog/OnboardingDialogLayout";
interface PagedOnboardingDialogProps extends OnboardingDialogProps {
    showPageIndicator: boolean;
    pages: PagedOnboardingDialogPageData[];
    defaultTitle?: ReactNode;
    onClosingDialog?: () => void;
    closeButtonText?: string;
    hidePreviousButton: boolean;
}
interface PagedOnboardingDialogPageData extends Omit<OnboardingDialogLayoutProps, "title" | "actions" | "children" | "busy"> {
    title?: ReactNode | string;
    render: () => ReactNode;
    onCloseDialog?: () => void;
    helpPanelContent?: ReactNode;
    helpPanelImage?: HelpPanelImage;
    onMovingToNextPage?: () => Promise<void>;
    isPageModelValid?: () => Promise<boolean>;
    isBusy?: boolean;
    customPrimaryButton?: ReactNode;
}
interface PageNavigation {
    currentPageData: PagedOnboardingDialogPageData;
    isCurrentPageModelValid: () => Promise<boolean>;
    canMoveToNext: boolean;
    canMoveToPrevious: boolean;
    moveToNextPage: () => Promise<void>;
    moveToPreviousPage: () => void;
    moveToFirstPage: () => void;
}
const usePageNavigation = (pages: PagedOnboardingDialogPageData[]): PageNavigation => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const currentPageData = pages[currentPageIndex];
    const canMoveToNext = currentPageIndex < pages.length - 1;
    const canMoveToPrevious = currentPageIndex > 0;
    const isCurrentPageModelValid = async () => (currentPageData.isPageModelValid === undefined ? true : await currentPageData.isPageModelValid());
    const moveToNextPage = async () => {
        if (canMoveToNext && (await isCurrentPageModelValid())) {
            await currentPageData.onMovingToNextPage?.();
            setCurrentPageIndex((prevIndex) => prevIndex + 1);
        }
    };
    const moveToPreviousPage = () => {
        if (canMoveToPrevious) {
            setCurrentPageIndex((prevIndex) => prevIndex - 1);
        }
    };
    const moveToFirstPage = () => {
        setCurrentPageIndex(0);
    };
    return {
        currentPageData,
        isCurrentPageModelValid,
        canMoveToNext,
        canMoveToPrevious,
        moveToNextPage,
        moveToPreviousPage,
        moveToFirstPage,
    };
};
/**
 * This adds basic paging to the OnboardingDialog component.  This supports props for OnboardingDialog changing for each page, which doesn't cleanly fit into the existing standard OnboardingDialog/OnboardingDialogLayout pattern.
 * After a couple of iterations I have decided to keep this simple, and let any future users sculpt it into a more powerful component as needed.
 * Things like per-page actions, or extra render context for each page can be easily added if needed.
 * See areas/infrastructure/components/MachineSettings/Endpoints/KubernetesTentacle/ConfigurationDialog.tsx for an example of its use.
 * @param props
 */
export const PagedOnboardingDialog = (props: PagedOnboardingDialogProps) => {
    const pageNavigation = usePageNavigation(props.pages);
    const closeDialog = async (isCancel: boolean = false) => {
        if (isCancel || (await pageNavigation.isCurrentPageModelValid())) {
            pageNavigation.currentPageData.onCloseDialog?.();
            props.onClosingDialog?.();
            pageNavigation.moveToFirstPage();
            props.close();
        }
    };
    const commonActions = (<>
            {props.hidePreviousButton ? null : pageNavigation.canMoveToPrevious ? (<ActionButton label={"Back"} type={ActionButtonType.Ternary} onClick={() => pageNavigation.moveToPreviousPage()}/>) : (<ActionButton label={"Cancel"} type={ActionButtonType.Ternary} onClick={() => closeDialog(true)}/>)}
            {pageNavigation.canMoveToNext ? (<ActionButton label={"Next"} type={ActionButtonType.Primary} onClick={async () => await pageNavigation.moveToNextPage()}/>) : (<ActionButton label={props.closeButtonText || "Close"} type={ActionButtonType.Secondary} onClick={() => closeDialog()}/>)}

            {pageNavigation.currentPageData.customPrimaryButton}
        </>);
    const getHelpPanelDetailsForPage = () => ({
        helpPanelContent: pageNavigation.currentPageData.helpPanelContent ?? props.helpPanelContent,
        helpPanelImage: pageNavigation.currentPageData.helpPanelImage ?? props.helpPanelImage,
    });
    const getTitleForPage = () => {
        const title = pageNavigation.currentPageData.title ?? props.defaultTitle;
        if (typeof title === "string") {
            return (<div className={titleStyles.dialogTitle}>
                    <div className={titleStyles.titleContainer}>
                        <h2 className={titleStyles.title}>{title}</h2>
                    </div>
                </div>);
        }
        else {
            return title;
        }
    };
    return (<OnboardingDialog open={props.open} close={props.close} name={"Add Kubernetes Agent"} {...getHelpPanelDetailsForPage()}>
            <OnboardingDialogLayout actions={commonActions} onCloseDialog={props.close} title={getTitleForPage()} busy={pageNavigation.currentPageData.isBusy}>
                <div style={{ overflow: "auto" }}>{pageNavigation.currentPageData.render()}</div>
            </OnboardingDialogLayout>
        </OnboardingDialog>);
};
const titleStyles = {
    dialogTitle: css({
        display: "flex",
        flexWrap: "wrap",
        gap: space[12],
        alignItems: "center",
    }),
    titleContainer: css({
        "@media (max-width: 600px)": {
            flexBasis: "100%",
        },
    }),
    titleIcon: css({
        height: "46px",
        width: "43px",
    }),
    title: css({
        color: themeTokens.color.text.primary,
        font: text.interface.heading.small,
        margin: 0,
    }),
};
