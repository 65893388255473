import * as React from "react";
import type { MixedExecutionLocationsConfig } from "~/components/Actions/pluginRegistry";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
export const kubernetesMixedExecutionLocationConfig = (allLocationsRequireActionContainer?: boolean): MixedExecutionLocationsConfig => {
    if (isFeatureToggleEnabled("KubernetesTentacleFeatureToggle")) {
        return {
            enabled: true,
            allLocationsRequireActionContainer: allLocationsRequireActionContainer || false,
            title: "Kubernetes API Deployment Targets",
            callout: (<Callout type={CalloutType.Information} title={"These fields are not required for Kubernetes agents"}>
                    The <ExternalLink href={"kubernetes-agent"}>Kubernetes agent</ExternalLink> doesn’t require workers and will ignore these fields. Complete the fields below if you’re deploying to Kubernetes API deployment targets.
                </Callout>),
        };
    }
    else {
        return { enabled: false };
    }
};
export const KubernetesStepTopEditSection = ({ children }: React.PropsWithChildren<{}>) => {
    const kubernetesTentacleEnabled = isFeatureToggleEnabled("KubernetesTentacleFeatureToggle");
    return kubernetesTentacleEnabled ? <>{children}</> : null;
};
export const KubernetesStepDefaultEditSection = ({ children }: React.PropsWithChildren<{}>) => {
    const kubernetesTentacleEnabled = isFeatureToggleEnabled("KubernetesTentacleFeatureToggle");
    return kubernetesTentacleEnabled ? <></> : <>{children}</>;
};
