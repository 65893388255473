import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import type { KubernetesAgentConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import type { Language, TextFormat } from "~/components/CodeEditor/CodeEditor";
export interface EnvironmentResourceSubset {
    Id: string;
    Name: string;
    Slug?: string;
}
/**
 * DNS labels can only be lowercase alphanumeric (with hyphens), and can't start/end with hyphens.
 * See https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#dns-label-names
 * Note that Helm has slightly different requirements in certain circumstances. For example, release names
 * can only be a max of 53 characters.
 * @param value
 * @param maxLength
 */
const getAsValidDnsLabel = (value: string, maxLength: number = 63) => value
    .replace(/[^0-9a-z-]/gi, "") // Only alphanumeric and hyphens
    .replace(/^-+|-+$/g, "") // No hyphens at start or end.
    .toLowerCase()
    .substring(0, maxLength);
const getValidReleaseName = (targetName: string) => getAsValidDnsLabel(targetName, 53); // Release names have a max length of 53, see https://helm.sh/docs/chart_template_guide/getting_started/#adding-a-simple-template-call
const getValidTargetName = (targetName: string) => escapeAllDoubleQuotes(targetName);
const escapeAllDoubleQuotes = (value: string) => value.replace(/"/g, "\\\"");
function getEnvironmentSlugsForIds(environmentIds: string[], environments: EnvironmentResourceSubset[]) {
    return environmentIds
        .map((id) => environments.find((env) => env.Id === id)?.Slug ?? "")
        .filter((slug) => slug !== "") // This shouldn't do anything, because slugs are required on environments
        .join(",");
}
function getMachineRolesList(machineRoles: string[]) {
    return machineRoles.map((mr) => escapeAllDoubleQuotes(mr)).join(","); // Machine roles can contain double quotes, so we'll escape them so they don't break the helm command
}
export function createHelmCommand(language: ScriptingLanguage | Language | TextFormat, model: KubernetesAgentConfigurationResource, environments: EnvironmentResourceSubset[]) {
    const lineBreak = language === ScriptingLanguage.Bash ? "\\" : "`";
    const targetName = getValidTargetName(model.Name);
    const machineRoles = getMachineRolesList(model.MachineRoles);
    const environmentSlugs = getEnvironmentSlugsForIds(model.EnvironmentIds, environments);
    const namespace = getNamespace(model.Name);
    const releaseName = getReleaseName(model.Name);
    if (!model.Space) {
        throw new Error("Attempted to render Kubernetes Agent Configuration Dialog outside of a space. This should never happen.");
    }
    return `helm upgrade --install --atomic ${lineBreak}
--set tentacle.ACCEPT_EULA="Y" ${lineBreak}
--set tentacle.targetName="${targetName}" ${lineBreak}
--set tentacle.serverUrl="${model.ServerUri}" ${lineBreak}
--set tentacle.serverCommsAddress="${model.ServerCommsAddress}" ${lineBreak}
--set tentacle.space="${model.Space.Name}" ${lineBreak}
--set tentacle.targetEnvironments="{${environmentSlugs}}" ${lineBreak}
--set tentacle.targetRoles="{${machineRoles}}" ${lineBreak}
--set tentacle.bearerToken="${model.AccessToken}" ${lineBreak}
--create-namespace --namespace ${namespace} ${lineBreak}
${releaseName} ${lineBreak}
oci://registry-1.docker.io/octopusdeploy/kubernetes-agent`;
}
export function getNamespace(targetName: string): string {
    const validTargetName = getValidTargetName(targetName);
    return getAsValidDnsLabel(`octopus-agent-${validTargetName}`);
}
export function getReleaseName(targetName: string): string {
    return getValidReleaseName(targetName);
}
