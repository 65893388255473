import type { ServerStatusResource, ActivityLogElement } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import { reverse } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import TaskLogLines from "~/components/TaskLogLines/TaskLogLines";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import HALogWarning from "./HALogWarning";
interface DetailedServerLogsState extends DataBaseComponentState {
    status?: ServerStatusResource;
    logs?: ActivityLogElement[];
    isHA: boolean;
}
export class DetailedServerLogsPage extends DataBaseComponent<{}, DetailedServerLogsState> {
    constructor(props: {}) {
        super(props);
        this.state = { isHA: false };
    }
    async componentDidMount() {
        const nodes = repository.OctopusServerNodes.list();
        await this.doBusyTask(async () => {
            await this.refresh();
            this.setState({ isHA: (await nodes).Items.length > 1 });
        });
    }
    refresh = async () => {
        const status = await repository.ServerStatus.getServerStatus();
        this.setState({
            status,
            logs: reverse(await repository.ServerStatus.getLogs(status)),
        });
    };
    render() {
        const refreshPageAction: PrimaryPageAction | undefined = this.state.logs
            ? {
                type: "button",
                label: "Refresh",
                busyLabel: "Refreshing...",
                disabled: this.state.busy,
                onClick: this.refresh,
                hasPermissions: isAllowed({ permission: Permission.AdministerSystem }),
            }
            : undefined;
        return (<PaperLayout title="Detailed Server Logs" breadcrumbTitle="Diagnostics" breadcrumbPath={links.diagnosticsPage.generateUrl()} primaryAction={refreshPageAction} busy={this.state.busy} errors={this.errors}>
                {this.state.logs && (<div>
                        <Section>The entries below are the most recent warning and error-level events recorded on this Octopus Server node in this session.</Section>
                        <HALogWarning isHA={this.state.isHA}/>
                        {this.state.logs.length === 0 && (<Section>
                                <Callout type={CalloutType.Information} title={"No events found"}>
                                    No warning or error-level events have been collected in this session.
                                </Callout>
                            </Section>)}
                        <Section>
                            <TaskLogLines lines={this.state.logs} showAdditional={() => noOp()}/>
                        </Section>
                    </div>)}
            </PaperLayout>);
    }
    static displayName = "DetailedServerLogsPage";
}
