/* eslint-disable @typescript-eslint/init-declarations */
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { ActionTemplateRoutes } from "~/areas/library/components/ActionTemplates/ActionTemplateRoutes";
import VariableSetsRoutes from "~/areas/library/components/VariableSets";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { firstAuthorized } from "~/components/PermissionCheck/PermissionCheck";
import pageIds from "~/pageIds";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../../../routeLinks";
import { BuildInformationDetailPage } from "../BuildInformation/BuildInformationDetailPage";
import { BuildInformationOverviewPage } from "../BuildInformation/BuildInformationOverviewPage";
import { BuildInformationVersionsPage } from "../BuildInformation/BuildInformationVersionsPage";
import { BuiltInRepositoryPage } from "../BuiltInRepository/BuiltInRepositoryPage";
import { PackageDetailsPage } from "../BuiltInRepository/PackageDetailPage";
import { PackageVersionsListPage } from "../BuiltInRepository/PackageVersionsListPage";
import { ArchivedCertificatesPage, CertificatePage, CertificatesPage } from "../Certificates";
import ExternalFeedRoutes from "../ExternalFeeds";
import GitConnectionRoutes from "../GitConnections/GitConnectionRoutes";
import { GitCredentialRoutes } from "../GitCredentials/GitCredentialRoutes";
import { LifecyclePage } from "../Lifecycle/Lifecycle/LifecyclePage";
import { LifecyclesPage } from "../Lifecycle/Lifecycles/LifecyclesPage";
import { ScriptModulePage } from "../Scripts/ScriptModulePage";
import { ScriptModulesPage } from "../Scripts/ScriptModulesPage";
import { TagSetPage } from "../TagSets/TagSetPage";
import { TagSetsPage } from "../TagSets/TagSetsPage";
export const PackageVersionsListPageDefinition = withPage({ page: pageIds.library.builtInRepository.versions })(PackageVersionsListPage);
export const PackageDetailsPageDefinition = withPage({ page: pageIds.library.builtInRepository.package })(PackageDetailsPage);
export const BuiltInRepositoryOverviewPageDefinition = withPage({ page: pageIds.library.builtInRepository.root })(BuiltInRepositoryPage);
export const BuildInformationOverviewPageDefinition = withPage({ page: pageIds.library.buildInformationRepository.root })(BuildInformationOverviewPage);
export const BuildInformationVersionsPageDefinition = withPage({ page: pageIds.library.buildInformationRepository.versions })(BuildInformationVersionsPage);
export const BuildInformationDetailsPageDefinition = withPage({ page: pageIds.library.buildInformationRepository.package })(BuildInformationDetailPage);
export const CreateLifecyclePageDefinition = withPage({ page: pageIds.library.lifecyclesCreate })(LifecyclePage);
export const EditLifecyclePageDefinition = withPage({ page: pageIds.library.lifecycle })(LifecyclePage);
export const LifecyclesPageDefinition = withPage({ page: pageIds.library.lifecycles })(LifecyclesPage);
export const CreateScriptModulePageDefinition = withPage({ page: pageIds.library.scriptModules.create })(ScriptModulePage);
export const EditScriptModulePageDefinition = withPage({ page: pageIds.library.scriptModule })(ScriptModulePage);
export const ScriptModulesPageDefinition = withPage({ page: pageIds.library.scriptModules.root })(ScriptModulesPage);
export const CreateTagSetPageDefinition = withPage({ page: pageIds.library.tagSets.create })(TagSetPage);
export const EditTagSetPageDefinition = withPage({ page: pageIds.library.tagSet })(TagSetPage);
export const TagSetsPageDefinition = withPage({ page: pageIds.library.tagSets.root })(TagSetsPage);
export const ArchivedCertificatesPageDefinition = withPage({ page: pageIds.library.certificates.archive })(ArchivedCertificatesPage);
export const CreateCertificatePageDefinition = withPage({ page: pageIds.library.certificates.create })(CertificatePage);
export const CertificatePageDefinition = withPage({ page: pageIds.library.certificate })(CertificatePage);
export const ListCertificatesPageDefinition = withPage({ page: pageIds.library.certificates.root })(CertificatesPage);
export class LibraryRoutes extends React.Component<RouteComponentProps> {
    render() {
        let defaultRoute;
        switch (firstAuthorized([Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView])) {
            case Permission.FeedView:
                defaultRoute = <InternalRedirect to={routeLinks.library.builtInRepository.root}/>;
                break;
            case Permission.LifecycleEdit:
                defaultRoute = <InternalRedirect to={routeLinks.library.lifecycles}/>;
                break;
            case Permission.VariableView:
                defaultRoute = <InternalRedirect to={routeLinks.library.scripts.root}/>;
                break;
            default:
                defaultRoute = <InternalRedirect to={routeLinks.library.stepTemplates.root}/>;
        }
        const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
        return (<Switch>
                <MigratedReloadableRoute path={routeLinks.library.v3routes.certificates.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.certificates.create, search: this.props.location.search }}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.v3routes.feeds.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.feedCreate, search: this.props.location.search }}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.v3routes.lifecycles.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.lifecyclesCreate, search: this.props.location.search }}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.v3routes.scripts.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.scripts.create, search: this.props.location.search }}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.v3routes.tagSets.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.tagSets.create, search: this.props.location.search }}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.v3routes.variableSets.create}>
                    <InternalRedirect to={{ pathname: routeLinks.library.variableSets, search: this.props.location.search }}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.builtInRepository.root}>
                    <Switch>
                        <MigratedReloadableRoute path={routeLinks.library.builtInRepository.versions(":packageId")} render={(routeProps: RouteComponentProps<{
            spaceId: string;
            packageId: string;
        }>) => <PackageVersionsListPageDefinition spaceId={routeProps.match.params.spaceId} packageId={routeProps.match.params.packageId}/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.builtInRepository.package(":id", false)} render={(routeProps: RouteComponentProps<{
            spaceId: string;
            id: string;
        }>) => <PackageDetailsPageDefinition spaceId={routeProps.match.params.spaceId} packageId={decodeURIComponent(routeProps.match.params.id)}/>}/>
                        <MigratedReloadableRoute render={(routeProps: RouteComponentProps<{
            spaceId: string;
        }>) => <BuiltInRepositoryOverviewPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.buildInformationRepository.root}>
                    <Switch>
                        <MigratedReloadableRoute path={routeLinks.library.buildInformationRepository.versions(":packageId+", false)} render={({ match }: RouteComponentProps<{
            spaceId: string;
            packageId: string;
        }>) => <BuildInformationVersionsPageDefinition spaceId={match.params.spaceId} packageId={decodeURIComponent(match.params.packageId)}/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.buildInformationRepository.package(":id", false)} render={({ match }: RouteComponentProps<{
            spaceId: string;
            id: string;
        }>) => <BuildInformationDetailsPageDefinition spaceId={match.params.spaceId} id={match.params.id}/>}/>
                        <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <BuildInformationOverviewPageDefinition spaceId={match.params.spaceId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.feeds} component={ExternalFeedRoutes}/>
                <MigratedReloadableRoute path={routeLinks.library.lifecycles}>
                    <Switch>
                        <MigratedReloadableRoute path={routeLinks.library.lifecyclesCreate} render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <CreateLifecyclePageDefinition spaceId={match.params.spaceId} create/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.lifecycle(":lifecycleId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            lifecycleId: string;
        }>) => <EditLifecyclePageDefinition spaceId={match.params.spaceId} lifecycleId={match.params.lifecycleId}/>}/>
                        <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <LifecyclesPageDefinition spaceId={match.params.spaceId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.scripts.root}>
                    <Switch>
                        <MigratedReloadableRoute path={routeLinks.library.scripts.create} render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <CreateScriptModulePageDefinition spaceId={match.params.spaceId} create/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.script(":scriptModuleId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            scriptModuleId: string;
        }>) => <EditScriptModulePageDefinition spaceId={match.params.spaceId} scriptModuleId={match.params.scriptModuleId}/>}/>
                        <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <ScriptModulesPageDefinition spaceId={match.params.spaceId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.stepTemplates.root} component={ActionTemplateRoutes}/>
                <MigratedReloadableRoute path={routeLinks.library.tagSets.root}>
                    <Switch>
                        <MigratedReloadableRoute exact={true} path={routeLinks.library.tagSets.create} render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <CreateTagSetPageDefinition spaceId={match.params.spaceId} create={true}/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.tagSet(":tagSetId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            tagSetId: string;
        }>) => <EditTagSetPageDefinition spaceId={match.params.spaceId} tagSetId={match.params.tagSetId}/>}/>
                        <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <TagSetsPageDefinition spaceId={match.params.spaceId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.variableSets} component={VariableSetsRoutes}/>
                <MigratedReloadableRoute path={routeLinks.library.certificates.root}>
                    <Switch>
                        <MigratedReloadableRoute path={routeLinks.library.certificates.archive} render={(routeProps) => <ArchivedCertificatesPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.certificates.create} render={(routeProps) => <CreateCertificatePageDefinition spaceId={routeProps.match.params.spaceId} create/>}/>
                        <MigratedReloadableRoute path={routeLinks.library.certificate(":certificateId")} render={(routeProps) => <CreateCertificatePageDefinition spaceId={routeProps.match.params.spaceId} certificateId={routeProps.match.params.certificateId}/>}/>
                        <MigratedReloadableRoute render={(routeProps) => <ListCertificatesPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.library.gitCredentials.root} component={GitCredentialRoutes}/>
                {gitHubConnectionsEnabled && <MigratedReloadableRoute path={routeLinks.library.gitConnections.root} component={GitConnectionRoutes}/>}
                <MigratedReloadableRoute exact={true} path={routeLinks.library.root}>
                    {defaultRoute}
                </MigratedReloadableRoute>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "LibraryRoutes";
}
export default LibraryRoutes;
