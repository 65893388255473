/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { CertificateResource, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { CertificateDataFormat, Permission, SelfSignedCertificateCurve, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import * as React from "react";
import ArchiveCertificate, { ArchiveAction } from "~/areas/library/components/Certificates/Certificate/ArchiveCertificate";
import CertificateDetail from "~/areas/library/components/Certificates/Certificate/CertificateDetail";
import CertificateUsage from "~/areas/library/components/Certificates/Certificate/CertificateUsage";
import DownloadCertificate from "~/areas/library/components/Certificates/Certificate/DownloadCertificate";
import ReplaceCertificate from "~/areas/library/components/Certificates/Certificate/ReplaceCertificate";
import { certificateUsageSummary } from "~/areas/library/components/Certificates/Certificate/certificateUsageSummary";
import type { CertificateUsageEntry } from "~/areas/library/components/Certificates/Certificate/certificateUsageSummary";
import styles from "~/areas/library/components/Certificates/Certificate/style.module.less";
import type { ExternalFeedStateTypes } from "~/areas/library/components/ExternalFeeds/ExternalFeed";
import LibraryLayout from "~/areas/library/components/LibraryLayout/index";
import { repository } from "~/clientInstance";
import { AdvancedTenantsAndTenantTagsSelector } from "~/components/AdvancedTenantSelector/index";
import { CertificateExpiryChip, environmentChipList } from "~/components/Chips/index";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { Feature, FeatureToggle } from "~/components/FeatureToggle/index";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/index";
import FormBaseComponent from "~/components/FormBaseComponent/index";
import FormPaperLayout from "~/components/FormPaperLayout/index";
import Markdown from "~/components/Markdown/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TenantedDeploymentParticipationSelector from "~/components/TenantedDeploymentParticipationSelector/index";
import { withTheme } from "~/components/Theme/index";
import ThumbprintText from "~/components/ThumbprintText/index";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, FormSectionHeading, MarkdownEditor, Note, required, Select, Sensitive, SensitiveFileUpload, Summary, Text } from "~/components/form/index";
import type { SummaryNode } from "~/components/form/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
import { TabItem, UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs/index";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/index";
import StringHelper from "~/utils/StringHelper/index";
import { TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode } from "~/utils/TenantedDeploymentParticipationHelper/TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode";
interface CertificateProps {
    spaceId: string;
    create?: boolean;
    certificateId?: string;
    defaultTab?: string;
}
export type CertificatePropsInternal = FormStateChanged & {
    create?: boolean;
    certificateId?: string;
    defaultTab?: string;
    onStateTransition?: (currentState: CertificateStateTypes | null, newState: CertificateStateTypes) => void;
    expandableContainerKey?: string;
};
interface CertificateState extends OptionalFormBaseComponentState<CertificateResource> {
    deleted: boolean;
    newId: string;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    certificateUsages: CertificateUsageEntry[];
    replacedByCertificate: CertificateResource;
    uploadFormat: UploadFormatOptions;
}
interface FormState {
    model: CertificateResource | undefined;
    cleanModel: CertificateResource | undefined;
    busy: Promise<void> | undefined;
    errors?: Errors;
}
export interface FormStateChanged {
    onFormStateChanged: (newState: FormState) => void;
}
type UploadFormatOptions = "File" | "Text" | "SelfSigned";
interface CommonStateTransitionProps {
    primaryAction?: PageAction;
    secondaryActions?: PageAction[];
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
}
export type CertificateCreateState = CommonStateTransitionProps & {
    state: "create";
};
export type CertificateNewState = CommonStateTransitionProps & {
    state: "new";
    certificateId: string;
    certificate: CertificateResource | undefined;
};
export type CertificateEditState = CommonStateTransitionProps & {
    state: "edit";
    certificateId: string;
    certificate: CertificateResource | undefined;
};
export type CertificateDeleteState = CommonStateTransitionProps & {
    state: "delete";
};
export type CertificateStateTypes = CertificateCreateState | CertificateNewState | CertificateEditState | CertificateDeleteState;
export const CertificatePaperLayout = (props: CertificateProps) => {
    const [state, setState] = React.useState<CertificateStateTypes>({
        state: "create",
    });
    const [formState, setFormState] = React.useState<FormState>({
        busy: undefined,
        model: undefined,
        errors: undefined,
        cleanModel: undefined,
    });
    const [primaryAction, setPrimaryAction] = React.useState<PageAction>();
    const [secondaryActions, setSecondaryActions] = React.useState<PageAction[]>();
    const [overflowMenuItems, setOverflowMenuItems] = React.useState<Array<MenuItem | MenuItem[]>>();
    const onStateTransition = (currentState: CertificateStateTypes | null, newState: CertificateStateTypes) => {
        setState(newState);
        setPrimaryAction(newState.primaryAction);
        setSecondaryActions(newState.secondaryActions);
        setOverflowMenuItems(newState.overflowMenuItems);
    };
    if (state.state === "delete") {
        return <InternalRedirect to={links.certificatesPage.generateUrl({ spaceId: props.spaceId })}/>;
    }
    if (state.state === "new") {
        return <InternalRedirect to={links.editCertificatePage.generateUrl({ spaceId: props.spaceId, certificateId: state.certificateId })}/>;
    }
    const saveText: string = state.state ? "Certificate created" : "Certificate details updated";
    const title = state.state === "create" ? "New Certificate" : formState.model ? formState.model.Name : StringHelper.ellipsis;
    return (<LibraryLayout spaceId={props.spaceId}>
            <FormPaperLayout title={title} breadcrumbTitle={"Certificates"} breadcrumbPath={links.certificatesPage.generateUrl({ spaceId: props.spaceId })} busy={formState.busy} errors={formState.errors} model={formState.model} cleanModel={formState.cleanModel} savePermission={{ permission: state.state === "create" ? Permission.CertificateCreate : Permission.CertificateEdit, wildcard: true }} onSaveClick={() => {
            if (primaryAction?.type === "button")
                primaryAction.onClick(undefined);
        }} pageActions={secondaryActions} saveText={saveText} expandAllOnMount={props.create} overFlowActions={overflowMenuItems}>
                <CertificateInternal certificateId={props.certificateId} onFormStateChanged={setFormState} onStateTransition={onStateTransition} create={props.create} defaultTab={props.defaultTab}/>
            </FormPaperLayout>
        </LibraryLayout>);
};
export class CertificateInternal extends FormBaseComponent<CertificatePropsInternal, CertificateState, CertificateResource> {
    constructor(props: CertificatePropsInternal) {
        super(props);
        this.state = {
            deleted: false,
            newId: null!,
            allEnvironments: [],
            allTenants: [],
            certificateUsages: null!,
            replacedByCertificate: null!,
            uploadFormat: "File",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            let certificate: CertificateResource = null!;
            let allEnvironments: EnvironmentResource[] = null!;
            let allTenants: TenantResource[] = null!;
            let replacedByCertificate: CertificateResource = null!;
            if (this.props.create) {
                certificate = {
                    Id: null!,
                    Name: "",
                    Notes: "",
                    CertificateData: { NewValue: null!, HasValue: false },
                    Password: { NewValue: null!, HasValue: false },
                    EnvironmentIds: [],
                    TenantIds: [],
                    TenantTags: [],
                    TenantedDeploymentParticipation: TenantedDeploymentMode.Untenanted,
                    Links: null!,
                    SpaceId: null!,
                };
            }
            else {
                certificate = await repository.Certificates.get(this.props.certificateId!);
                if (certificate.ReplacedBy) {
                    replacedByCertificate = await repository.Certificates.get(certificate.ReplacedBy);
                }
            }
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            [allEnvironments, allTenants] = await Promise.all([repository.Environments.all(), tenantsPromise]);
            this.setState({
                model: certificate,
                cleanModel: _.cloneDeep(certificate),
                allEnvironments,
                allTenants,
                replacedByCertificate,
            });
        });
    }
    componentDidUpdate(prevProps: CertificatePropsInternal, prevState: CertificateState) {
        if (prevState.busy !== this.state.busy || prevState.model !== this.state.model || prevState.cleanModel !== this.state.cleanModel || prevState.deleted !== this.state.deleted) {
            this.notifyFormStateChanged();
        }
    }
    private notifyFormStateChanged() {
        this.props.onFormStateChanged({
            busy: this.state.busy,
            model: this.state.model,
            cleanModel: this.state.cleanModel,
            errors: this.errors,
        });
        const overFlowActions = [];
        if (this.state.model && !this.props.create) {
            const download = <DownloadCertificate certificate={this.state.model}/>;
            overFlowActions.push(OverflowMenuItems.dialogItem("Download", download));
            const replace = <ReplaceCertificate certificate={this.state.model} afterCertificateReplace={(c) => this.handleCertificateReplaced(c)}/>;
            overFlowActions.push(OverflowMenuItems.dialogItem("Replace", replace, { permission: Permission.CertificateEdit, wildcard: true }));
            if (this.state.model.Archived) {
                overFlowActions.push(OverflowMenuItems.deleteItemDefault("certificate", this.handleDeleteConfirm, { permission: Permission.CertificateDelete, wildcard: true }));
                if (!this.state.model.ReplacedBy) {
                    const unarchive = <ArchiveCertificate certificate={this.state.model} action={ArchiveAction.Unachive} afterAction={() => this.handleArchive()}/>;
                    overFlowActions.push(OverflowMenuItems.dialogItem("Unarchive", unarchive, { permission: Permission.CertificateEdit, wildcard: true }));
                }
            }
            else {
                const archive = <ArchiveCertificate certificate={this.state.model} action={ArchiveAction.Archive} afterAction={() => this.handleArchive()}/>;
                overFlowActions.push(OverflowMenuItems.dialogItem("Archive", archive, { permission: Permission.CertificateEdit, wildcard: true }));
            }
            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.Id] }), {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }
        const commonProps: Omit<ExternalFeedStateTypes, "state"> = {
            primaryAction: {
                type: "button",
                buttonType: "secondary",
                hasPermissions: isAllowed({ permission: Permission.CertificateEdit }),
                label: "Save",
                busyLabel: "Saving...",
                onClick: () => {
                    this.handleSaveClick();
                },
            },
            overflowMenuItems: overFlowActions,
        };
        if (this.props.create && !this.state.newId) {
            this.props.onStateTransition?.(null, {
                state: "create",
                ...commonProps,
            });
        }
        else {
            const state = this.state.newId ? "new" : null;
            if (this.state.deleted) {
                this.props.onStateTransition?.(null, {
                    state: "delete",
                    ...commonProps,
                });
            }
            else if (state !== null) {
                this.props.onStateTransition?.(null, {
                    state: state,
                    certificateId: this.state.newId ? this.state.newId : this.state.model!.Id,
                    certificate: this.state.model,
                    ...commonProps,
                });
            }
            else if (this.state.model) {
                this.props.onStateTransition?.(null, {
                    state: "edit",
                    certificateId: this.state.newId ? this.state.newId : this.state.model!.Id,
                    certificate: this.state.model,
                    ...commonProps,
                });
            }
        }
    }
    render() {
        return (<>
                {this.state.model && (<CertificateFormInternal certificateUsages={this.state.certificateUsages} model={this.state.model} uploadFormat={this.state.uploadFormat} getFieldError={this.getFieldError} create={this.props.create} replacedByCertificate={this.state.replacedByCertificate} setModelState={(state) => {
                    this.setModelState(state);
                }} doBusyTask={this.doBusyTask} allEnvironments={this.state.allEnvironments} allTenants={this.state.allTenants} defaultTab={this.props.defaultTab} setUploadFormat={(uploadFormat) => this.setState({ uploadFormat }, () => this.setModelState({ CertificateData: { HasValue: true } }))} setCertificateUsages={(certificateUsages) => this.setState({ certificateUsages })}/>)}
            </>);
    }
    private handleSaveClick = async () => {
        if (this.state.model && TenantsOrTenantTagsSelectedOnUntenantedDeploymentMode(this.state.model)) {
            this.setValidationErrors("Tenanted deployment mode", { TenantedDeploymentParticipation: "Please remove any associated tenants or tenant tags to use Untenanted deployment mode." });
            return;
        }
        await this.doBusyTask(async () => {
            const isNew = this.state.model!.Id === null;
            const certificate = this.state.uploadFormat === "SelfSigned" ? await repository.Certificates.saveSelfSigned(this.state.model!) : await repository.Certificates.save(this.state.model!);
            this.setState({
                model: certificate,
                cleanModel: _.cloneDeep(certificate),
                newId: isNew ? certificate.Id : null!,
            });
        });
    };
    private handleDeleteConfirm = async () => {
        const result = await repository.Certificates.del(this.state.model!);
        this.setState((state) => {
            return {
                model: null,
                cleanModel: null,
                deleted: true,
            };
        });
        return true;
    };
    private handleCertificateReplaced(replacedCertificate: CertificateResource) {
        const cert = this.state.model;
        this.setState({
            model: replacedCertificate!,
            cleanModel: _.cloneDeep(replacedCertificate),
            newId: replacedCertificate.Id,
            replacedByCertificate: cert!,
        });
    }
    private handleArchive = async () => {
        await this.doBusyTask(async () => {
            const certificate = await repository.Certificates.get(this.props.certificateId!);
            let replacedByCertificate: CertificateResource | null = null;
            if (certificate.ReplacedBy) {
                replacedByCertificate = await repository.Certificates.get(certificate.ReplacedBy);
            }
            this.setState(() => ({
                model: certificate!,
                cleanModel: _.cloneDeep(certificate),
                certificateUsages: null,
                replacedByCertificate,
            }));
        });
    };
    static displayName = "CertificateInternal";
}
interface CertificateFormInternalProps {
    create?: boolean;
    model: CertificateResource;
    certificateUsages: CertificateUsageEntry[] | null;
    allEnvironments: EnvironmentResource[];
    allTenants: TenantResource[];
    doBusyTask: DoBusyTask;
    defaultTab?: string;
    replacedByCertificate?: CertificateResource;
    getFieldError: (fieldName: string) => string;
    setModelState: <K extends keyof CertificateResource>(state: Pick<CertificateResource, K>) => void;
    setCertificateUsages: (certificateUsages: CertificateUsageEntry[]) => void;
    setUploadFormat: (uploadFormat: UploadFormatOptions) => void;
    uploadFormat: UploadFormatOptions;
}
const CertificateFormInternal = (props: CertificateFormInternalProps) => {
    const detailsSummary = (): SummaryNode => {
        return props.model
            ? Summary.summary(withTheme((theme) => (<div className={styles.row}>
                          <div className={styles.propertyContainer}>
                              <span>
                                  <ThirdPartyIcon iconType={ThirdPartyIconType.InfoOutline} color={theme.primaryText}/>
                              </span>
                              <span>{props.model.SubjectCommonName || props.model.SubjectOrganization || props.model.SubjectDistinguishedName}</span>
                          </div>
                          <div className={styles.propertyContainer}>
                              <span>
                                  <ThirdPartyIcon iconType={ThirdPartyIconType.AccountBox} color={theme.primaryText}/>
                              </span>
                              <span>{props.model.SelfSigned ? "Self-Signed" : props.model.IssuerCommonName || props.model.IssuerOrganization || props.model.IssuerDistinguishedName}</span>
                          </div>
                          <div className={styles.propertyContainer}>
                              <CertificateExpiryChip certificate={props.model}/>
                          </div>
                      </div>)))
            : Summary.placeholder("Certificate details");
    };
    const usageSummary = (): SummaryNode => {
        return props.certificateUsages && props.certificateUsages.length > 0
            ? props.certificateUsages.length > 1
                ? Summary.summary(<span>
                          This certificate is used in <b>{props.certificateUsages.length}</b> places
                      </span>)
                : Summary.summary(<span>
                          This certificate is used in <b>one</b> place
                      </span>)
            : Summary.placeholder("This certificate is not used anywhere");
    };
    const environmentsSummary = (): SummaryNode => {
        return props.model.EnvironmentIds && props.model.EnvironmentIds.length
            ? Summary.summary(<span>Only available for deployments to {environmentChipList(props.allEnvironments, props.model.EnvironmentIds)}</span>)
            : Summary.default("Available for deployments to any environment");
    };
    const tenantDeploymentModeSummary = () => {
        return CommonSummaryHelper.tenantDeploymentModeSummary(props.model.TenantedDeploymentParticipation!, props.model.TenantIds, props.model.TenantTags);
    };
    const tenantSummary = () => {
        return CommonSummaryHelper.tenantSummary(props.model.TenantIds, props.model.TenantTags, props.allTenants);
    };
    const onUsageTabActive = async () => {
        if (props.certificateUsages || props.create) {
            return;
        }
        await props.doBusyTask(async () => {
            const certificateUsageData = await repository.CertificateConfiguration.usage(props.model);
            const certificateUsages = certificateUsageSummary(certificateUsageData);
            props.setCertificateUsages(certificateUsages);
        });
    };
    const notesSummary = () => {
        return props.model!.Notes ? Summary.summary(<Markdown markup={props.model!.Notes}/>) : Summary.placeholder("Notes not provided");
    };
    return (<TransitionAnimation>
            <UrlNavigationTabsContainer defaultValue={props.defaultTab ?? "details"}>
                <TabItem label="Details" value="details">
                    {props.replacedByCertificate && (<Callout title="Replaced" type={CalloutType.Information}>
                            This certificate was replaced by certificate with thumbprint <ThumbprintText thumbprint={props.replacedByCertificate.Thumbprint!}/>
                        </Callout>)}
                    {props.model.Archived && (<Callout title="Archived" type={CalloutType.Information}>
                            This certificate was archived on {props.model.Archived}
                        </Callout>)}
                    {props.model.CertificateDataFormat === CertificateDataFormat.Unknown && (<Callout title="Invalid Certificate" type={CalloutType.Warning}>
                            This certificate was unable to be parsed and may be in an invalid format. This certificate will not be able to be used in Octopus deployments and you may need to upload a new certificate which can be correctly loaded.
                        </Callout>)}
                    <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={props.model.Name ? Summary.summary(props.model.Name) : Summary.placeholder("Please enter a name for your certificate")} help="A short, memorable, unique name for this certificate.">
                        <Text value={props.model.Name} onChange={(Name) => props.setModelState({ Name })} label="Name" error={props.getFieldError("Name")} validate={required("Please enter a certificate name")} autoFocus={true}/>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="Notes" title="Notes" summary={notesSummary()} help="This summary will be presented to users when selecting the certificate for inclusion in a variable.">
                        <MarkdownEditor value={props.model.Notes} label="Notes" onChange={(Notes) => props.setModelState({ Notes })}/>
                    </ExpandableFormSection>
                    {!props.create && props.model.CertificateDataFormat !== CertificateDataFormat.Unknown && (<div>
                            <ExpandableFormSection errorKey="Details" title="Details" summary={detailsSummary()} help="Certificate details.">
                                <CertificateDetail certificate={props.model}/>
                                {props.model.CertificateChain!.length > 0 && (<div>
                                        <h4>Certificate Chain</h4>
                                        {props.model.CertificateChain!.map((cert, index) => (<div key={index}>
                                                <CertificateDetail certificate={cert}/>
                                                <br />
                                                <br />
                                            </div>))}
                                    </div>)}
                            </ExpandableFormSection>
                        </div>)}
                    {props.create && props.model.CertificateDataFormat !== CertificateDataFormat.Unknown && (<div>
                            <FormSectionHeading title="Certificate"/>
                            <ExpandableFormSection errorKey="CertificateData" title="Certificate Data" summary={Summary.summary("Supported formats: PFX (PKCS #12), DER, PEM")} help="Select the option which you wish to use to supply the Certificate Data">
                                <RadioButtonGroup value={props.uploadFormat} onChange={(uploadFormat: UploadFormatOptions) => props.setUploadFormat(uploadFormat)}>
                                    <RadioButton value={"File"} label="Upload a File"/>
                                    {props.uploadFormat === "File" && (<SensitiveFileUpload label="Certificate File" value={props.model.CertificateData} onChange={(CertificateData) => props.setModelState({ CertificateData })} error={props.getFieldError("CertificateData")}/>)}

                                    <RadioButton value={"Text"} label="Paste Text"/>
                                    {props.uploadFormat === "Text" && (<div>
                                            <Note>Paste your certificate as text, either base64 encoded or in PEM format</Note>
                                            <Text multiline={true} value={props.model.CertificateData.NewValue!} onChange={(CertificateData) => props.setModelState({ CertificateData: { NewValue: _.trim(CertificateData), HasValue: true } })}/>
                                        </div>)}

                                    <RadioButton value={"SelfSigned"} label="Create Self Signed"/>
                                    {props.uploadFormat === "SelfSigned" && (<>
                                            <div>
                                                <Note>Create a new self signed certificate with the details below.</Note>
                                                <Text label={"Common Name (a FQDN or person's name)"} value={props.model.SubjectCommonName || ""} onChange={(SubjectCommonName) => props.setModelState({ SubjectCommonName })}/>
                                            </div>
                                            <div>
                                                <Select value={props.model.SelfSignedCertificateCurve} onChange={(x) => props.setModelState({ SelfSignedCertificateCurve: x as SelfSignedCertificateCurve })} items={[
                    { value: SelfSignedCertificateCurve.nistP256, text: "NIST P-256" },
                    { value: SelfSignedCertificateCurve.nistP384, text: "NIST P-384" },
                    { value: SelfSignedCertificateCurve.nistP521, text: "NIST P-521" },
                ]} placeholder="Elliptic curve to use"/>
                                                <Note>
                                                    If this is left blank, P-384 will be used by default.
                                                    <br />
                                                    If you want to maximize interoperability with existing browsers and servers, select either P-256 and P-384
                                                </Note>
                                                {props.model.SelfSignedCertificateCurve === SelfSignedCertificateCurve.nistP521 && (<Callout type={CalloutType.Warning} title={"Using anything other than NIST P-256 or NIST P-384 will cause some browsers to block access."}/>)}
                                            </div>
                                        </>)}
                                </RadioButtonGroup>
                            </ExpandableFormSection>
                            {props.uploadFormat !== "SelfSigned" && (<ExpandableFormSection errorKey="Password" title="Password" summary={Summary.summary("The password protecting the file (if required).")} help="Password">
                                    <Sensitive value={props.model.Password} onChange={(Password) => props.setModelState({ Password })} label="Password" error={props.getFieldError("Password")}/>
                                </ExpandableFormSection>)}
                        </div>)}
                    <FormSectionHeading title="Restrictions"/>
                    <ExpandableFormSection errorKey="Environments" title="Environments" summary={environmentsSummary()} help="Choose the environments that are allowed to use this certificate.">
                        <Note>If this field is left blank, the certificate can be used for deployments to any environment. Specifying environment/s (especially for production certificates) is strongly recommended.</Note>
                        <EnvironmentMultiSelect environments={props.allEnvironments} onChange={(EnvironmentIds) => props.setModelState({ EnvironmentIds })} value={props.model.EnvironmentIds}/>
                    </ExpandableFormSection>
                    <FeatureToggle feature={Feature.MultiTenancy}>
                        <PermissionCheck permission={Permission.TenantView} tenant="*">
                            <ExpandableFormSection errorKey="TenantedDeploymentParticipation" title="Tenanted Deployments" summary={tenantDeploymentModeSummary()} help={"Choose the kind of deployments where this certificate should be included."}>
                                <TenantedDeploymentParticipationSelector tenantMode={props.model.TenantedDeploymentParticipation!} resourceTypeLabel="certificate" onChange={(x) => props.setModelState({ TenantedDeploymentParticipation: x as TenantedDeploymentMode })}/>
                            </ExpandableFormSection>
                            {props.model.TenantedDeploymentParticipation !== TenantedDeploymentMode.Untenanted && (<ExpandableFormSection errorKey="Tenants" title="Associated Tenants" summary={tenantSummary()} help={"Choose tenants this certificate should be associated with."}>
                                    <AdvancedTenantsAndTenantTagsSelector tenants={props.allTenants} selectedTenantIds={props.model.TenantIds} selectedTenantTags={props.model.TenantTags} doBusyTask={props.doBusyTask} onChange={(TenantIds, TenantTags) => props.setModelState({ TenantIds, TenantTags })} showPreviewButton={true}/>
                                </ExpandableFormSection>)}
                        </PermissionCheck>
                    </FeatureToggle>
                </TabItem>
                {!props.create && (<TabItem label="Usage" value="usage" onActive={() => onUsageTabActive()}>
                        <ExpandableFormSection errorKey="Usage" title="Usage" summary={usageSummary()} help="This certificate can be referenced by variables">
                            <CertificateUsage certificateUsage={props.certificateUsages ?? []}/>
                        </ExpandableFormSection>
                    </TabItem>)}
            </UrlNavigationTabsContainer>
        </TransitionAnimation>);
};
interface CertificateProps {
    spaceId: string;
    create?: boolean;
    certificateId?: string;
    defaultTab?: string;
}
export default function CertificatePage(props: CertificateProps) {
    return <CertificatePaperLayout {...props}/>;
}
