import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { PageRouteDefinition, ParsedQueryParams, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { PageIdentity } from "~/routing/pageRegistrations/PageIdentity";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
export interface InsightsPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement;
}
export function createInsightsPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement, pageIdentity: PageIdentity, legacyRouteLink: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>) => string): InsightsPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        legacyRouteLink,
        pageIdentity,
    };
}
export interface InsightsReportPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (report: InsightsReportResource, refreshReport: () => Promise<void>, parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement;
}
export function createInsightsReportPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (report: InsightsReportResource, refreshReport: () => Promise<void>, parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement, pageIdentity: PageIdentity, legacyRouteLink: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>) => string): InsightsReportPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        legacyRouteLink,
        pageIdentity,
    };
}
