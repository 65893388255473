import { css, cx } from "@emotion/css";
import { space, themeTokens, fontWeight, fontSize, lineHeight } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateCategoryResource } from "@octopusdeploy/octopus-server-client";
import { ActionHandlerCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
interface CategoryInfoProps {
    categories: ActionTemplateCategoryResource[];
    selectedCategory: ActionHandlerCategory | undefined;
}
const CategoryInfo = (props: CategoryInfoProps) => {
    const category = props.categories.find((x) => x.Id === props.selectedCategory);
    const getCategoryPageName = (selectedCategory: ActionHandlerCategory | undefined): string => {
        switch (selectedCategory) {
            case ActionHandlerCategory.Script:
                return "script-steps";
            case ActionHandlerCategory.Package:
                return "package-steps";
            case ActionHandlerCategory.Kubernetes:
                return "kubernetes-steps";
            case ActionHandlerCategory.Docker:
                return "docker-steps";
            case ActionHandlerCategory.Terraform:
                return "terraform-steps";
            case ActionHandlerCategory.Aws:
                return "aws-steps";
            case ActionHandlerCategory.Azure:
                return "azure-steps";
            case ActionHandlerCategory.GoogleCloud:
                return "google-cloud-steps";
            case ActionHandlerCategory.JavaAppServer:
                return "java-steps";
            case ActionHandlerCategory.WindowsServer:
                return "windows-steps";
            case ActionHandlerCategory.Certificate:
                return "cert-steps";
            case ActionHandlerCategory.Community:
                return "community-steps";
            case ActionHandlerCategory.StepTemplate:
                return "custom-steps";
            default:
                return "";
        }
    };
    const renderCategoryInfo = () => {
        switch (props.selectedCategory) {
            case ActionHandlerCategory.Featured: {
                return <div>Try a featured step template or filter by category</div>;
            }
            case ActionHandlerCategory.Community: {
                return (<div>
                        <ExternalLink href={getCategoryPageName(props.selectedCategory)}>Community step templates</ExternalLink>
                        {"  are contributed by the Octopus Community"}
                    </div>);
            }
            case ActionHandlerCategory.StepTemplate: {
                return (<div>
                        {"Learn about "}
                        <ExternalLink href={getCategoryPageName(props.selectedCategory)}>Custom step templates</ExternalLink>
                        {"  and "}
                        <ExternalLink href={getCategoryPageName(ActionHandlerCategory.Community)}>Community step templates</ExternalLink>
                    </div>);
            }
            case ActionHandlerCategory.Other: {
                return undefined;
            }
            default: {
                return (<div>
                        {"Learn about "}
                        <ExternalLink href={getCategoryPageName(props.selectedCategory)}>{category?.Name} steps</ExternalLink>
                    </div>);
            }
        }
    };
    return (<div className={cx(styles.container)}>
            <div className={cx(styles.title)}>{category?.Name}</div>
            <div className={cx(styles.details)}>{renderCategoryInfo()}</div>
        </div>);
};
export default CategoryInfo;
const styles = {
    container: css({
        padding: `${space[16]} ${space[16]} 0 ${space[16]}`,
    }),
    title: css({
        fontSize: fontSize["large"],
        fontWeight: fontWeight["700"],
        lineHeight: lineHeight["medium"],
    }),
    details: css({
        color: themeTokens.color.text.muted,
        fontSize: fontSize["medium"],
        fontWeight: fontWeight["400"],
        lineHeight: ["small"],
    }),
};
