import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import moment from "moment";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import useLocalStorage from "~/hooks/useLocalStorage";
// When you want to test a new version of the deployment process code editor
// (e.g. a batch of improvements have been made) you can change this date to force
// the default to go back to the code editor again. If a user still decides to
// go back to the form editor after this then the local storage will get updated
// and they will be back to the form editor by default.
export const DateCurrentVersionOfProcessCodeEditorWasEnabledAsTheDefault = "2024-01-30";
// When a user switches from the code editor to the form editor, we want to ask
// for feedback as to why they did this so we can iterate and improve the code editing experience.
// But we don't want to ask them every time they switch, this would get annoying real quick.
// So we'll only ask them if we haven't for the below number of days.
export const NumberOfDaysBeforeAskingForFeedbackAgainWhenSwitchingToFormEditor = 7;
export function isProcessCodeEditorEnabled(project?: ProjectResource, processType?: ProcessType) {
    return isFeatureToggleEnabled("DeploymentProcessCodeEditorFeatureToggle") && project?.IsVersionControlled && processType === ProcessType.Deployment;
}
type ProcessCodeEditorState = {
    isEnabledByDefault: boolean;
    dateLastRevertedToFormEditor: string | undefined;
    dateLastAskedForFeedback: string | undefined;
    dateCalloutWasDismissed: string | undefined;
};
export function useProcessCodeEditorState() {
    const [state, setState] = useLocalStorage<ProcessCodeEditorState | undefined>("Octopus.DeploymentProcessCodeEditor.State", undefined);
    const isProcessCodeEditorFeatureToggleEnabled = isFeatureToggleEnabled("DeploymentProcessCodeEditorFeatureToggle");
    const isANewVersionOfTheCodeEditorAvailableToTry = state === undefined || state.dateLastRevertedToFormEditor === undefined || moment(state.dateLastRevertedToFormEditor).isBefore(DateCurrentVersionOfProcessCodeEditorWasEnabledAsTheDefault);
    const hasEnoughTimePassedToAskForFeedbackAgain = state === undefined || state.dateLastAskedForFeedback === undefined || moment(state.dateLastAskedForFeedback).add(NumberOfDaysBeforeAskingForFeedbackAgainWhenSwitchingToFormEditor, "days").isBefore(moment());
    const hasCalloutBeenDismissed = state !== undefined && state.dateCalloutWasDismissed !== undefined && moment(state.dateCalloutWasDismissed).isAfter(DateCurrentVersionOfProcessCodeEditorWasEnabledAsTheDefault);
    const isProcessCodeEditorEnabledByDefault = isProcessCodeEditorFeatureToggleEnabled && (state?.isEnabledByDefault || isANewVersionOfTheCodeEditorAvailableToTry);
    const canAskForFeedbackWhenSwitchingToFormEditor = isProcessCodeEditorFeatureToggleEnabled && (isANewVersionOfTheCodeEditorAvailableToTry || hasEnoughTimePassedToAskForFeedbackAgain);
    const showCallout = isProcessCodeEditorFeatureToggleEnabled && !hasCalloutBeenDismissed;
    return {
        isProcessCodeEditorEnabledByDefault,
        canAskForFeedbackWhenSwitchingToFormEditor,
        showCallout,
        enableProcessCodeEditorByDefault: () => setState({ isEnabledByDefault: true, dateLastRevertedToFormEditor: state?.dateLastRevertedToFormEditor, dateLastAskedForFeedback: state?.dateLastAskedForFeedback, dateCalloutWasDismissed: state?.dateCalloutWasDismissed }),
        disableProcessCodeEditorByDefault: (feedbackRequested: boolean) => setState({
            isEnabledByDefault: false,
            dateLastRevertedToFormEditor: moment().toISOString(),
            dateLastAskedForFeedback: feedbackRequested ? moment().toISOString() : state?.dateLastAskedForFeedback,
            dateCalloutWasDismissed: state?.dateCalloutWasDismissed,
        }),
        feedbackSubmitted: () => setState({
            isEnabledByDefault: state?.isEnabledByDefault ?? true,
            dateLastRevertedToFormEditor: state?.dateLastRevertedToFormEditor,
            dateLastAskedForFeedback: moment().toISOString(),
            dateCalloutWasDismissed: state?.dateCalloutWasDismissed,
        }),
        calloutDismissed: () => setState({
            isEnabledByDefault: state?.isEnabledByDefault ?? true,
            dateLastRevertedToFormEditor: state?.dateLastRevertedToFormEditor,
            dateLastAskedForFeedback: state?.dateLastAskedForFeedback,
            dateCalloutWasDismissed: moment().toISOString(),
        }),
    };
}
