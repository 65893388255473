import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
interface InsightsNoEnvironmentsCalloutProps {
    spaceId: string;
}
export function InsightsNoEnvironmentsCallout({ spaceId }: InsightsNoEnvironmentsCalloutProps) {
    return (<Callout type={CalloutType.Warning} title="Insights cannot be rendered without environments">
            <InternalLink to={links.infrastructureEnvironmentsPage.generateUrl({ spaceId })}>Create your first environment</InternalLink> in the infrastructure area.
        </Callout>);
}
