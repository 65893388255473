import type { CompleteRoute, RedirectRouteDefinition, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { allRoutes } from "@octopusdeploy/portal-routes";
import routeLinks from "~/routeLinks";
export const allRedirectMappings = [
    createRedirectMapping(allRoutes.redirects.rootRedirect, () => routeLinks.root),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.spaceRootRedirect, ({ spaceId }) => routeLinks.forSpace(spaceId).root),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.deploymentToProjectTaskRedirect, ({ spaceId, deploymentId }) => routeLinks.forSpace(spaceId).deployment(deploymentId).root),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.redirectFeedBasedOnType, ({ spaceId, feedId }) => routeLinks.forSpace(spaceId).library.feed(feedId).redirect),
    createRedirectMapping(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.scopedUserRoleToTeamRedirect, ({ scopedRoleId }) => routeLinks.configuration.teams.redirect(scopedRoleId)),
    createRedirectMapping(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.diagnosticMachineCleanupRedirect, () => routeLinks.configuration.diagnostics.logs.machineCleanup),
    createRedirectMapping(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.configurationRootRedirect, () => routeLinks.configuration.root),
    createRedirectMapping(allRoutes.childRouteSegments.system.childRouteSegments.currentUser.redirects.currentUserRootRedirect, () => routeLinks.currentUser.me),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.infrastructure.redirects.infrastructureRootRedirect, ({ spaceId }) => routeLinks.forSpace(spaceId).infrastructure.root),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.libraryRootRedirect, ({ spaceId }) => routeLinks.forSpace(spaceId).library.root),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.releaseRedirect, ({ spaceId, releaseId }) => routeLinks.forSpace(spaceId).releaseRedirect(releaseId)),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.triggerRedirect, ({ spaceId, triggerId }) => routeLinks.forSpace(spaceId).triggerRedirect(triggerId)),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.channelRedirect, ({ spaceId, channelId }) => routeLinks.forSpace(spaceId).channelRedirect(channelId)),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.runbookSnapshotRedirect, ({ spaceId, runbookSnapshotId }) => routeLinks.forSpace(spaceId).runbookSnapshotRedirect(runbookSnapshotId)),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.runbookRedirect, ({ spaceId, runbookId }) => routeLinks.forSpace(spaceId).runbookRedirect(runbookId)),
    createRedirectMapping(allRoutes.childRouteSegments.space.redirects.runbookRunRedirect, ({ spaceId, runbookRunId }) => routeLinks.forSpace(spaceId).runbookRun(runbookRunId).root),
    createRedirectMapping(allRoutes.childRouteSegments.system.redirects.interruptionToTaskRedirect, ({ interruptionId }) => routeLinks.tasks.interruption(interruptionId)),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.redirects.runbookRootRedirect, ({ spaceId, projectSlug, runbookId }) => routeLinks.forSpace(spaceId).project(projectSlug).operations.runbook(runbookId).root),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.deployments.childRouteSegments.process.redirects.deploymentProcessStepByStepSlugRedirect, ({ spaceId, projectSlug, stepSlug }) => `${routeLinks.forSpace(spaceId).project(projectSlug).deployments.process.root}/steps/${stepSlug}`),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.deployments.redirects.branchDeploymentProcessStepByStepSlugRedirect, ({ spaceId, branchName, projectSlug, stepSlug }) => `${routeLinks.forSpace(spaceId).projectBranch(projectSlug, branchName).deployments.process.root}/steps/${stepSlug}`),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectRootRedirect, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).root),
    createRedirectMapping(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectOverviewRedirect, ({ spaceId, projectSlug }) => routeLinks.forSpace(spaceId).project(projectSlug).overview),
];
function createRedirectMapping<RouteParams>(redirectRoute: RedirectRouteDefinition<RouteParams>, getLegacyRoute: (routeParams: RouteParams) => string) {
    return {
        isEqualTo<TargetRouteParams, TargetQueryParams extends UnknownQueryParam[]>(route: CompleteRoute<TargetRouteParams, TargetQueryParams>) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const candidateRoute: any = route;
            return candidateRoute === redirectRoute.completeRoute;
        },
        getMappedUrl(routeParams: RouteParams) {
            return getLegacyRoute(routeParams);
        },
    };
}
