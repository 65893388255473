import { css } from "@emotion/css";
import { Divider, List } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubRepository, GitHubUnknownRepository } from "@octopusdeploy/octopus-server-client";
import React, { useRef } from "react";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
import GitHubIcon from "~/primitiveComponents/dataDisplay/Icon/GitHubIcon";
import type { GitHubRepositoryWithDisabled } from "./GitHubAppRepositoryListItem";
import { GitHubAppRepositoryListItem } from "./GitHubAppRepositoryListItem";
interface BaseGitHubAppRepositoryListProps<T> {
    repositories: T[];
    selectedRepositories: string[];
    setRepositorySelected: (selected: boolean, repositoryId: string) => void;
    disableInteraction?: boolean;
}
export function GitHubAppUnknownRepositoryList({ repositories, selectedRepositories, setRepositorySelected, disableInteraction }: BaseGitHubAppRepositoryListProps<GitHubUnknownRepository>) {
    return (<>
            {repositories.length > 0 && (<>
                    <List items={repositories} rowKey={(i) => i.RepositoryId} renderRow={(props) => <GitHubAppRepositoryListItem disableInteraction={disableInteraction} repository={props.item} selected={selectedRepositories.includes(props.item.RepositoryId)} onChange={setRepositorySelected}/>}/>
                </>)}
        </>);
}
interface GitHubAppRepositoryListProps extends BaseGitHubAppRepositoryListProps<GitHubRepositoryWithDisabled> {
    hideConfigureInstallation?: boolean;
}
export default function GitHubAppRepositoryList({ repositories, selectedRepositories, setRepositorySelected, disableInteraction, hideConfigureInstallation }: GitHubAppRepositoryListProps) {
    const installationUrl = useRef(repository.GitHubApp.getInstallationUrl(window.location.href));
    const styles = {
        logo: css({
            height: space[24],
            width: space[24],
        }),
        noRepositories: css({
            textAlign: "center",
            marginTop: space[32],
            marginBottom: space[32],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: space[8],
        }),
        configureGitHubAppInstructions: css({
            marginLeft: space[16],
            marginTop: space[16],
            marginBottom: space[16],
        }),
    };
    return (<>
            {repositories.length > 0 && (<List items={repositories} rowKey={(i) => i.RepositoryId} renderRow={(props) => <GitHubAppRepositoryListItem disableInteraction={disableInteraction} repository={props.item} selected={selectedRepositories.includes(props.item.RepositoryId)} onChange={setRepositorySelected}/>}/>)}

            {repositories.length === 0 && (<div className={styles.noRepositories}>
                    <GitHubIcon className={styles.logo}/> <div>No repositories found</div>
                </div>)}

            {!hideConfigureInstallation && (<>
                    <Divider />
                    <p className={styles.configureGitHubAppInstructions}>
                        If you cannot see your repository{" "}
                        <ExternalLink openInSelf href={installationUrl.current}>
                            configure repository access in the Octopus Deploy app on GitHub.
                        </ExternalLink>
                    </p>
                </>)}
        </>);
}
export interface GitHubAppRepositoryListWithNonAdminReposDisabledProps extends BaseGitHubAppRepositoryListProps<GitHubRepository> {
    hideConfigureInstallation?: boolean;
}
export function GitHubAppRepositoryListWithNonAdminReposDisabled({ repositories, selectedRepositories, setRepositorySelected }: GitHubAppRepositoryListProps) {
    const enabledRepositories = [];
    const disabledRepositories = [];
    for (const r of repositories) {
        if (r.IsAdmin) {
            enabledRepositories.push(r);
        }
        else {
            disabledRepositories.push({ ...r, disabled: true, disabledText: "You are not an administrator on this repository" });
        }
    }
    const repositoriesWithDisabled = [...enabledRepositories, ...disabledRepositories];
    return <GitHubAppRepositoryList repositories={repositoriesWithDisabled} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>;
}
