import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "../../../routeLinks";
import { DashboardConfigurationPage } from "../DashboardConfiguration/DashboardConfigurationPage";
import { DashboardOverviewPage } from "../DashboardOverview/DashboardOverviewPage";
const DashboardConfigurationPageWithPageDefinition = withPage({ page: pageIds.dashboard.configure })(DashboardConfigurationPage);
const DashboardOverviewPageWithPageDefinition = withPage({ page: pageIds.dashboard.root })(DashboardOverviewPage);
export const DashboardRoutes = () => {
    return (<Switch>
            <MigratedReloadableRoute path={routeLinks.dashboard.configure} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <DashboardConfigurationPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={routeLinks.dashboard.root} render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <DashboardOverviewPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
        </Switch>);
};
