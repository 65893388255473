import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { usePage } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ActionStatus } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
export function useDispatchSwitchToFormEditor() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback((askedForFeedback: boolean, feedback?: string) => session.track("Switch to form editor", {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Action: Action.OptOut,
        "Asked For Feedback": askedForFeedback ? "True" : "False",
        Feedback: feedback,
    }), [session, page]);
}
export function useDispatchCodeEditorFeedback() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback((feedback?: string) => session.track("Code editor feedback", {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Feedback: feedback,
    }), [session, page]);
}
export function useDispatchSwitchToCodeEditor() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback(() => session.track("Switch to code editor", {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Action: Action.OptIn,
    }), [session, page]);
}
export function useDispatchCommitCodeEditorChanges() {
    const session = useAnalyticSession();
    const page = usePage();
    return React.useCallback((status: ActionStatus, isDefaultBranch: boolean, isNewBranch: boolean, isProtectedBranch: boolean, hasCommitMessage: boolean) => session.track("Commit deployment process", {
        "Page Area": page.Area,
        "Page Name": page.Name,
        Action: Action.Commit,
        Status: status,
        "Is Default Branch": isDefaultBranch ? "True" : "False",
        "Is Protected Branch": isProtectedBranch ? "True" : "False",
        "Commit Branch": isNewBranch ? "New branch" : "Same branch",
        "Has Commit Message": hasCommitMessage ? "True" : "False",
    }), [session, page]);
}
