/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css, cx } from "@emotion/css";
import { ActionButton, ActionButtonType, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ProjectGroupResource, ProjectSummaryResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { flatten, groupBy, isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import URI from "urijs";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { Onboarding } from "~/areas/projects/components/Projects/Onboarding";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import ComponentRow from "~/components/ComponentRow/ComponentRow";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import LoadMoreCard from "~/components/LoadMoreCard/LoadMoreCard";
import Markdown from "~/components/Markdown";
import MarkdownDescription from "~/components/MarkdownDescription";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import Section from "~/components/Section";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Note } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import Select from "~/primitiveComponents/form/Select/Select";
import type { Level1PageLayoutProps } from "~/routing/pageRegistrations/Level1PageLayoutProps";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { getImportExportMenuItems } from "../ImportExport/ImportExportMenu";
import { ProjectCard } from "../ProjectCard";
import { AddProjectGroup } from "./AddProjectGroup";
import styles from "./style.module.less";
type LocationProps = {
    location: ReturnType<typeof useLocation>;
};
type InternalProjectsProps = LocationProps & {
    spaceId: string;
    dispatchAction: AnalyticActionDispatcher;
    isPageHeaderVNextEnabled: boolean;
    isLargerThanIpad: boolean;
    PageLayout: React.ComponentType<Level1PageLayoutProps>;
};
type ProjectsByGroup = {
    [projectGroupId: string]: ProjectSummaryResource[];
};
type FilterableItem = {
    Name: string;
    Description: string;
};
interface ProjectsFilter {
    projectName: string;
    projectGroupId: string;
}
interface ProjectsState extends DataBaseComponentState {
    space?: SpaceResource;
    projectsByGroup: ProjectsByGroup;
    projectGroups: ProjectGroupResource[];
    filteredProjectGroups: ProjectGroupResource[];
    filter: ProjectsFilter;
    redirectTo?: LinkHref;
    hasProjects: boolean;
    showEmptyGroups: boolean;
    isLoaded: boolean;
    projectGroupTakeSize: number;
    projectsTakeSizeLookup: {
        [projectGroupId: string]: number;
    };
}
const defaultFilter: ProjectsFilter = {
    projectName: "",
    projectGroupId: "",
};
interface ProjectsQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}
const ProjectsQueryStringFilters = QueryStringFilters.For<ProjectsFilter, ProjectsQuery>();
class ProjectsInternal extends DataBaseComponent<InternalProjectsProps, ProjectsState> {
    private projectGroupPagingSize = 20;
    private projectPagingSize = Repository.takeDefaultPageSize;
    constructor(props: InternalProjectsProps) {
        super(props);
        this.state = {
            projectsByGroup: null!,
            projectGroups: null!,
            filter: defaultFilter,
            filteredProjectGroups: null!,
            hasProjects: false,
            showEmptyGroups: false,
            isLoaded: false,
            projectGroupTakeSize: this.projectGroupPagingSize,
            projectsTakeSizeLookup: {},
        };
    }
    async loadProjectGroups() {
        const [projectGroups, projects, featuresConfiguration, space] = await Promise.all([
            isAllowed({
                permission: Permission.ProjectGroupView,
                projectGroup: "*",
            })
                ? repository.ProjectGroups.all()
                : [],
            repository.Projects.summaries(),
            repository.FeaturesConfiguration.get(),
            repository.Spaces.get(repository.spaceId!),
        ]);
        const projectsByGroup = groupBy(projects, (p) => p.ProjectGroupId);
        const projectsTakeSizeLookup: {
            [projectGroupId: string]: number;
        } = {};
        projectGroups.forEach((g) => {
            if (!projectsByGroup.hasOwnProperty(g.Id)) {
                projectsByGroup[g.Id] = [];
            }
            if (!projectsTakeSizeLookup.hasOwnProperty(g.Id)) {
                projectsTakeSizeLookup[g.Id] = this.projectPagingSize;
            }
        });
        const hasProjects = projects.length > 0;
        this.setState({ projectsByGroup, projectsTakeSizeLookup, projectGroups, space, hasProjects, isLoaded: true }, () => {
            const newProjectGroupId = this.getNewProjectGroupId();
            this.updateFilteredGroups(newProjectGroupId);
        });
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadProjectGroups(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async componentDidUpdate(prevProps: InternalProjectsProps) {
        if (prevProps.location.search !== this.props.location.search) {
            // When a component redirects to itself and the new url differs from the old one only by query string then
            // the component doesn't get unmounted and mounted again. Instead, the changes are passed via props. This means
            // we have to clear the redirectTo property manually as otherwise we will end up in an infinite redirect loop.
            this.setState({ redirectTo: undefined });
            await this.loadProjectGroups();
        }
    }
    isSpaceManager(): boolean {
        return this.state.space !== undefined && session.currentPermissions!.isSpaceManager(this.state.space);
    }
    renderSearchSection = (newProjectGroupId: string) => {
        const projectGroupFilterItems = this.state.projectGroups &&
            this.state.projectGroups
                .filter((g) => this.showProjectGroup(g, newProjectGroupId))
                .map((g) => ({
                value: g.Id,
                text: g.Name,
            }));
        const matchCount = this.state.filteredProjectGroups && this.state.projectsByGroup && flatten(this.state.filteredProjectGroups.map((g) => this.state.projectsByGroup[g.Id].filter((p) => this.matches(p, this.state.filter.projectName)))).length;
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        const matchCountText = hasFilter ? <span className={cn(styles.info)}>{matchCount !== 1 ? `${matchCount} projects match` : "1 project matches"}</span> : null;
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        const SearchContainer = this.props.isPageHeaderVNextEnabled ? React.Fragment : Section;
        const filterHeaderContainerStyles = this.props.isPageHeaderVNextEnabled ? filterHeaderContainerVNextStyles : styles.filterHeaderContainer;
        return (<SearchContainer>
                <div className={filterHeaderContainerStyles} role="search">
                    <div className={styles.filterFieldContainer}>
                        <ComponentRow key="A" className={styles.filter}>
                            {this.state.projectGroups && this.state.projectGroups.length > 1 && (<div className={styles.filterField}>
                                    <Select label={this.state.filter.projectGroupId ? "Project group" : null!} placeholder="Project group" items={projectGroupFilterItems} onChange={(projectGroupId) => this.setState((prev) => ({
                    filter: {
                        ...prev.filter,
                        projectGroupId,
                    },
                }), () => this.updateFilteredGroups(newProjectGroupId))} value={this.state.filter.projectGroupId} allowClear={true}/>
                                </div>)}
                            <div className={styles.filterField}>
                                <FilterSearchBox inputClassName={styles.filterInput} placeholder="Project name or description" value={this.state.filter.projectName} onChange={(projectName) => this.setState((prev) => ({
                filter: {
                    ...prev.filter,
                    projectName,
                },
            }), () => this.updateFilteredGroups(newProjectGroupId))} autoFocus={autoFocus} fullWidth={true}/>
                            </div>
                        </ComponentRow>
                        {matchCountText}
                    </div>
                    {this.anyEmptyProjectGroups(this.state.projectsByGroup) && (<ActionButton className={styles.showHideEmptyGroups} label={this.showHideGroupsLabel(this.state.showEmptyGroups)} onClick={() => this.setState((prevState) => ({ showEmptyGroups: !prevState.showEmptyGroups }), () => this.updateFilteredGroups(newProjectGroupId))} type={ActionButtonType.Ternary}/>)}
                </div>
            </SearchContainer>);
    };
    render() {
        const { PageLayout, spaceId } = this.props;
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        if (this.state.isLoaded && !this.state.hasProjects) {
            return <Onboarding spaceId={spaceId} hasProjectGroups={this.state.projectGroups.length !== 0} hasProjects={this.state.hasProjects} dispatchAction={this.props.dispatchAction}/>;
        }
        const newProjectGroupId = this.getNewProjectGroupId();
        return (<>
                <ProjectsQueryStringFilters filter={this.state.filter} onFilterChange={(filter) => this.setState({ filter })} getFilter={getFilter} getQuery={getQuery}/>
                <PageLayout areaTitle={"Projects"} areaTitleLink={links.projectsPage.generateUrl({ spaceId })} primaryAction={this.state.isLoaded
                ? {
                    type: "dialog",
                    label: this.state.projectGroups && this.state.projectGroups.length > 0 ? "Add Project" : "Add a Project Group before adding a new project",
                    hasPermissions: isAllowed({ permission: Permission.ProjectCreate, projectGroup: "*" }),
                    disabled: this.state.projectGroups.length === 0,
                    onClick: () => this.props.dispatchAction("Add Project", { resource: "Project", action: Action.Add }),
                    renderDialog: ({ isOpen, closeDialog }) => (<AddNewProjectDialog open={isOpen} close={(project) => {
                            if (!this.state.hasProjects) {
                                if (project) {
                                    this.props.dispatchAction("Save Project", { action: Action.Save, resource: "Project" });
                                }
                                else {
                                    this.props.dispatchAction("Cancel Project Creation", { action: Action.Cancel, resource: "Project" });
                                }
                            }
                            closeDialog();
                        }}/>),
                }
                : undefined} pageActions={this.state.isLoaded
                ? [
                    {
                        type: "dialog",
                        label: "Add Group",
                        hasPermissions: isAllowed({ permission: Permission.ProjectGroupCreate, projectGroup: "*" }),
                        buttonType: "secondary",
                        onClick: () => this.props.dispatchAction("Add Project Group", { resource: "Project Group", action: Action.Add }),
                        renderDialog: ({ closeDialog, isOpen }) => (<Dialog open={isOpen} onRequestClose={closeDialog}>
                                              <AddProjectGroup projectGroupCreated={(id) => this.setState({ redirectTo: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }, { newProjectGroupId: id }) })}/>
                                          </Dialog>),
                    },
                ]
                : undefined} overflowActions={getImportExportMenuItems(this.props.spaceId)} busy={this.state.busy} errors={this.errors}>
                    {this.state.isLoaded && (<TransitionAnimation>
                            <div className={cx(contentContainerStyles, { [contentContainerVNextStyles]: this.props.isPageHeaderVNextEnabled })}>
                                <PermissionCheck permission={Permission.ProjectGroupView} projectGroup="*" wildcard={true} alternate={<Section>
                                            <Callout type={CalloutType.Warning} title={"Permission required"}>
                                                You do not have permission to perform this action. Please contact your Octopus administrator. Missing permission: {Permission.ProjectGroupView}
                                            </Callout>
                                        </Section>}>
                                    {this.state.hasProjects && this.renderSearchSection(newProjectGroupId)}
                                </PermissionCheck>
                                {this.groups()}
                            </div>
                        </TransitionAnimation>)}
                </PageLayout>
            </>);
    }
    private getNewProjectGroupId() {
        const query = URI(this.props.location.search).search(true);
        const newProjectGroupId = query.newProjectGroupId;
        return newProjectGroupId;
    }
    private updateFilteredGroups(newProjectGroupId: string) {
        const filteredProjectGroups = this.state.projectGroups
            .filter((projectGroup) => this.showProjectGroup(projectGroup, newProjectGroupId))
            .filter((projectGroup) => this.state.filter.projectGroupId === "" || projectGroup.Id === this.state.filter.projectGroupId)
            .filter((pg) => {
            // if projectName is not specified don't filter, because that will hide groups `!this.state.filter.projectName`
            return !this.state.filter.projectName || this.state.projectsByGroup[pg.Id].some((p) => this.matches(p, this.state.filter.projectName));
        });
        this.setState({ filteredProjectGroups });
    }
    private groups() {
        const filteredProjectGroups = this.state.filteredProjectGroups;
        const projectsByGroup = this.state.projectsByGroup;
        if (!filteredProjectGroups || !projectsByGroup) {
            return null;
        }
        const showLoadMoreAction = this.state.projectGroupTakeSize < filteredProjectGroups.length;
        const filteredProjectGroupsPage = filteredProjectGroups.slice(0, this.state.projectGroupTakeSize);
        const projectGroupPages = filteredProjectGroupsPage.map((projectGroup) => {
            const projects = projectsByGroup[projectGroup.Id];
            const filteredProjects = projects.filter((p) => this.matches(p, this.state.filter.projectName));
            const projectsTakeSizeLookup = this.state.projectsTakeSizeLookup;
            const projectsTakeSize = projectsTakeSizeLookup[projectGroup.Id];
            const filteredProjectsPage = filteredProjects.slice(0, projectsTakeSize);
            const cards = filteredProjectsPage.map((p) => <ProjectCard key={p.Id} project={p} disableMargin={this.props.isPageHeaderVNextEnabled}/>);
            const showProjectsLoadMoreAction = projectsTakeSize < filteredProjects.length;
            if (showProjectsLoadMoreAction) {
                cards.push(<LoadMoreCard key="lm" onLoadMore={() => {
                        const newTakeSize = projectsTakeSize + this.projectPagingSize;
                        projectsTakeSizeLookup[projectGroup.Id] = newTakeSize;
                        this.setState({ projectsTakeSizeLookup });
                    }} onLoadAll={() => {
                        const newTakeSize = Repository.takeAll;
                        projectsTakeSizeLookup[projectGroup.Id] = newTakeSize;
                        this.setState({ projectsTakeSizeLookup });
                    }} disableMargin={this.props.isPageHeaderVNextEnabled}/>);
            }
            const cardListStyles = this.props.isPageHeaderVNextEnabled ? cx(cardListVNextStyles, { [cardListVNextMobileStyles]: !this.props.isLargerThanIpad }) : styles.cardList;
            const projectGroupHeader = this.props.isPageHeaderVNextEnabled ? (<ProjectGroupHeader spaceId={this.props.spaceId} projectGroup={projectGroup} showProjectsLoadMoreAction={showProjectsLoadMoreAction} projectsTakeSize={projectsTakeSize} projectCount={filteredProjects.length}/>) : (<>
                    <div className={styles.groupHeader}>
                        <div className={styles.groupName}>
                            <div>{projectGroup.Name}</div>
                            {showProjectsLoadMoreAction && <div className={styles.loadMoreHeaderInfo}>{`(Showing ${projectsTakeSize} of ${filteredProjects.length} projects.)`}</div>}
                        </div>
                        {this.projectGroupActions(projectGroup)}
                    </div>
                    <div className={styles.groupDescription}>
                        <MarkdownDescription markup={projectGroup.Description!}/>
                    </div>
                </>);
            return (<div key={projectGroup.Id}>
                    {projectGroupHeader}
                    <div className={cardListStyles}>{cards}</div>
                </div>);
        });
        return (<>
                {showLoadMoreAction && (<div className={styles.loadMoreHeaderInfo}>
                        Showing {this.state.projectGroupTakeSize} of {this.state.projectGroups.length} project groups
                        <br />
                        <Note>(See paging at bottom of results)</Note>
                    </div>)}
                {projectGroupPages}
                {showLoadMoreAction && this.showGroupPagingInLoadMoreStyle()}
            </>);
    }
    private showGroupPagingInLoadMoreStyle() {
        return (<div className={styles.loadMoreContainer}>
                <div className={styles.loadMoreActions}>
                    <>
                        <ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={() => {
                const newTakeSize = this.state.projectGroupTakeSize + this.projectGroupPagingSize;
                this.setState({ projectGroupTakeSize: newTakeSize });
            }}/>
                        <div className={styles.loadMoreSubText}>
                            Or use filters to narrow the search results (or{" "}
                            <a href="#" onClick={(e) => {
                e.preventDefault();
                const newTakeSize = this.state.projectGroups.length;
                this.setState({ projectGroupTakeSize: newTakeSize });
            }}>
                                load all
                            </a>
                            )
                        </div>
                    </>
                </div>
            </div>);
    }
    private matches(item: FilterableItem, filter: string) {
        const normalizedFilter = this.normalize(filter);
        return !normalizedFilter || this.normalize(item.Name).includes(normalizedFilter) || this.normalize(item.Description ?? "").includes(normalizedFilter);
    }
    private normalize(value: string) {
        return value.toLowerCase();
    }
    private showProjectGroup(projectGroup: ProjectGroupResource, newProjectGroupId: string) {
        return this.state.showEmptyGroups || this.state.projectsByGroup[projectGroup.Id].length || (newProjectGroupId && projectGroup.Id === newProjectGroupId);
    }
    private anyEmptyProjectGroups(projectsByGroup: ProjectsByGroup) {
        return Object.keys(projectsByGroup).find((groupId) => projectsByGroup[groupId].length === 0);
    }
    private projectGroupActions(projectGroup: ProjectGroupResource) {
        const actions = [];
        if (isAllowed({ permission: Permission.ProjectCreate, projectGroup: projectGroup.Id })) {
            actions.push(<OpenDialogButton type={ActionButtonType.Secondary} label="Add Project" accessibleName={`Add Project within ${projectGroup.Name}`} wideDialog={true} renderDialog={(renderProps) => <AddNewProjectDialog open={renderProps.open} close={renderProps.closeDialog} projectGroupId={projectGroup.Id}/>} onClick={() => this.props.dispatchAction("Add Project to Group", { resource: "Project", action: Action.Add })}/>);
        }
        actions.push(<OverflowMenu menuItems={[
                OverflowMenuItems.navItem("Edit", links.editProjectGroupPage.generateUrl({ spaceId: this.props.spaceId, projectGroupId: projectGroup.Id }), {
                    permission: Permission.ProjectGroupEdit,
                    projectGroup: "*",
                }),
            ]}/>);
        return <ActionList actions={actions}/>;
    }
    private showHideGroupsLabel(showEmptyGroups: boolean) {
        const action = this.state.showEmptyGroups ? "HIDE" : "SHOW";
        return `${action} EMPTY GROUPS`;
    }
    static displayName = "ProjectsInternal";
}
function getFilter(query: ProjectsQuery): ProjectsFilter {
    return {
        projectName: query.projectName || "",
        projectGroupId: query.projectGroupId || "",
    };
}
function getQuery(filter: ProjectsFilter): ProjectsQuery {
    return {
        projectName: filter.projectName,
        projectGroupId: filter.projectGroupId,
    };
}
interface ProjectPageProps {
    spaceId: string;
    PageLayout: React.ComponentType<Level1PageLayoutProps>;
}
const Projects: React.FC<ProjectPageProps> = ({ spaceId, PageLayout }) => {
    const location = useLocation();
    const dispatchAction = useAnalyticActionDispatch();
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return <ProjectsInternal PageLayout={PageLayout} spaceId={spaceId} location={location} dispatchAction={dispatchAction} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled} isLargerThanIpad={isLargerThanIpad}/>;
};
Projects.displayName = "Projects"
interface ProjectGroupHeaderProps {
    spaceId: string;
    projectGroup: ProjectGroupResource;
    showProjectsLoadMoreAction: boolean;
    projectsTakeSize: number;
    projectCount: number;
}
const ProjectGroupHeader = ({ spaceId, projectGroup, showProjectsLoadMoreAction, projectsTakeSize, projectCount }: ProjectGroupHeaderProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const hasProjectCreatePermission = isAllowed({
        permission: Permission.ProjectCreate,
        projectGroup: projectGroup.Id,
    });
    const hasProjectGroupEditPermission = isAllowed({
        projectGroup: "*",
        permission: Permission.ProjectGroupEdit,
    });
    return (<div className={cx(projectGroupHeaderStyles, { [projectGroupHeaderMobileStyles]: !isLargerThanIpad })}>
            <div className={projectGroupHeaderDetailsStyles}>
                <div className={projectGroupHeaderNameContainerStyles}>
                    <span className={projectGroupHeaderTitleTextStyles}>{projectGroup.Name}</span>
                    {showProjectsLoadMoreAction && <span className={projectGroupHeaderLoadMoreTextStyles}>{`(Showing ${projectsTakeSize} of ${projectCount} projects)`}</span>}
                </div>
                {projectGroup.Description && (<div className={projectGroupDescriptionStyles}>
                        <Markdown markup={projectGroup.Description!}/>
                    </div>)}
            </div>
            <ActionList actions={[
            hasProjectGroupEditPermission ? <NavigationButton type={NavigationButtonType.Ternary} label="Edit" href={links.editProjectGroupPage.generateUrl({ spaceId, projectGroupId: projectGroup.Id })}/> : undefined,
            hasProjectCreatePermission ? (<OpenDialogButton type={ActionButtonType.Secondary} label="Add Project" accessibleName={`Add Project within ${projectGroup.Name}`} wideDialog={true} renderDialog={(renderProps) => <AddNewProjectDialog open={renderProps.open} close={renderProps.closeDialog} projectGroupId={projectGroup.Id}/>} onClick={() => dispatchAction("Add Project to Group", { resource: "Project", action: Action.Add })}/>) : undefined,
        ]}/>
        </div>);
};
const filterHeaderContainerVNextStyles = css({
    padding: `0 ${space[32]} 0 ${space[32]}`,
});
const contentContainerStyles = css({
    display: "flex",
    flexDirection: "column",
});
const contentContainerVNextStyles = css({
    gap: space[24],
});
const projectGroupHeaderStyles = css({
    display: "flex",
    alignItems: "flex-start",
    padding: `${space[16]} ${space[32]} ${space[16]} ${space[32]}`,
    backgroundColor: themeTokens.color.background.primary.default,
    overflow: "hidden",
    justifyContent: "space-between",
});
const projectGroupHeaderMobileStyles = css({
    padding: space["16"],
});
const projectGroupHeaderTitleTextStyles = css({
    font: text.interface.heading.medium,
    color: themeTokens.color.text.primary,
});
const projectGroupHeaderDetailsStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[8],
});
const projectGroupHeaderNameContainerStyles = css({
    display: "flex",
    gap: space[24],
    alignItems: "center",
});
const projectGroupDescriptionStyles = css({
    // This isn't ideal since it knows about how the Markdown component is rendering paragraphs
    p: {
        margin: 0,
        font: text.interface.body.default.medium,
        color: themeTokens.color.text.muted,
    },
});
const projectGroupHeaderLoadMoreTextStyles = css({
    font: text.interface.body.default.medium,
    color: themeTokens.color.text.muted,
});
const cardListVNextStyles = css({
    display: "flex",
    flexWrap: "wrap",
    padding: `${space[24]} ${space[32]} ${space[8]} ${space[32]}`,
    gap: space[32],
});
const cardListVNextMobileStyles = css({
    padding: `${space[24]} ${space[16]} ${space[8]} ${space[16]}`,
});
export default Projects;
