import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useAnalyticTrackedActionDispatch, useAnalyticViewDispatch } from "~/analytics/Analytics";
import { useDispatchGitCredentialsInteraction } from "~/areas/library/components/GitCredentials/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import useLocalStorage from "~/hooks/useLocalStorage";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
import { GitCredentialPageInternal } from "./GitCredentialPage";
export interface GitCredentialDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (gitCredential: GitCredentialResource) => void;
}
export function GitCredentialDrawer({ isOpen, onClose, onSave }: GitCredentialDrawerProps) {
    const [calloutDismissed, setCalloutDismissed] = useLocalStorage(`Octopus.Callout.Drawer.GitCredential.Dismissed`, false);
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchView = useAnalyticViewDispatch();
    const analyticsDispatch = useDispatchGitCredentialsInteraction();
    async function handleOnSave(gitCredential: GitCredentialResource, isNew: boolean) {
        onSave(gitCredential);
        analyticsDispatch({
            type: "Save Git Credentials",
            "Entity Type": isNew ? "New" : "Existing",
            Location: "Drawer",
            Status: "Success",
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async function handleOnDelete() { }
    function onCloseCallout() {
        setCalloutDismissed(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = repository.spaceId!;
    return (<GitCredentialPageInternal spaceId={spaceId} gitCredentialId="" create={true} onSave={handleOnSave} onDelete={handleOnDelete} trackAction={trackAction} dispatchView={dispatchView} resetOnSave={true}>
            {({ formContent, createSaveAction, busy, errors, title, overflowActions }) => {
            const primaryAction = createSaveAction({});
            return (<DrawerWithForm isOpen={isOpen} onClose={onClose} busy={busy} errors={errors} onSubmit={() => {
                    if (primaryAction?.type === "button")
                        primaryAction.onClick(undefined);
                }} expandAllOnMount={true} showExpandAll={true} expandableContainerKey={"GitCredentialDrawer"} customActions={[
                    <ActionButton label="Cancel" onClick={onClose} type={ActionButtonType.Secondary}/>,
                    <ActionButton onClick={() => primaryAction.onClick(undefined)} label={primaryAction.label} type={ActionButtonType.Primary}></ActionButton>,
                ]} title={title} variant="customActions">
                        {!calloutDismissed ? (<Callout type={CalloutType.Information} title="Finalise Git credential" hideTitle={false} canClose={true} onClose={onCloseCallout}>
                                <div>
                                    After you create a new Git credential, you can find it in{" "}
                                    <InternalLink to={links.gitCredentialsPage.generateUrl({ spaceId })} openInSelf={false}>
                                        Git Credentials
                                    </InternalLink>
                                </div>
                            </Callout>) : (<></>)}
                        {formContent}
                    </DrawerWithForm>);
        }}
        </GitCredentialPageInternal>);
}
