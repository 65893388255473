import React from "react";
import { Switch } from "react-router-dom";
import { MigratedRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import DeploymentProcessSettings from "../DeploymentProcessSettings";
export const DeploymentProcessSettingsPage = withPage({ page: pageIds.project().deploymentSettings })(DeploymentProcessSettings);
type DeploymentSettingsRouteProps = {
    path: string;
};
function DeploymentSettingsRoute({ path }: DeploymentSettingsRouteProps) {
    return (<Switch>
            <MigratedRoute path={`${path}`} exact={true} render={() => <DeploymentProcessSettingsPage />}/>
            <RedirectAs404 />
        </Switch>);
}
export const MigratedDeploymentSettingsRoute = DeploymentSettingsRoute;
export function NonVcsDeploymentSettingsRoute() {
    return <MigratedDeploymentSettingsRoute path={routeLinks.projectRoutes().deployments.settings}/>;
}
export const MigratedNonVcsDeploymentSettingsRoute = NonVcsDeploymentSettingsRoute;
export default DeploymentSettingsRoute;
