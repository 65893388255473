import type { CompleteRoute, LegacyRouteResolver, PageRouteDefinition, ParsedQueryParams, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import { allPagesFlattened } from "~/routing/pageRegistrations/allPageRegistrations";
import { allRedirectMappings } from "~/routing/redirects/redirectMappings";
export const legacyRouteResolver: LegacyRouteResolver = {
    resolve<RouteParams, QueryParams extends UnknownQueryParam[]>(route: CompleteRoute<RouteParams, QueryParams>, routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>): string {
        const redirectUrl = mapRedirectUrls(route, routeParams);
        if (redirectUrl !== undefined)
            return redirectUrl;
        const pageRegistration = allPagesFlattened.find((r) => {
            const unknownRoute: UnknownPageRouteDefinition = r.route;
            return unknownRoute === route;
        });
        if (!pageRegistration) {
            throw new Error(`Unable to resolve legacy route, no route registered for ${route.getDebugString()}`);
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const registeredRouteWithCorrectTypeArguments: PageRegistration<RouteParams, QueryParams> = pageRegistration as unknown as PageRegistration<RouteParams, QueryParams>;
        return registeredRouteWithCorrectTypeArguments.legacyRouteLink(routeParams, queryParams);
    },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownPageRouteDefinition = PageRouteDefinition<any, UnknownQueryParam[]>;
function mapRedirectUrls<RouteParams, QueryParams extends UnknownQueryParam[]>(route: CompleteRoute<RouteParams, QueryParams>, routeParams: RouteParams) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-explicit-any
    const anyRouteParams = routeParams as any;
    return allRedirectMappings.find((r) => r.isEqualTo(route))?.getMappedUrl(anyRouteParams);
}
