import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TriggersPage } from "~/areas/projects/components/Triggers/TriggersPage";
interface ReleaseTriggersPageProps {
    spaceId: string;
    projectSlug: string;
}
export const ReleaseTriggersPage: React.FC<ReleaseTriggersPageProps> = ({ spaceId, projectSlug }) => {
    return <TriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerActionCategory={TriggerActionCategory.Release}/>;
};
ReleaseTriggersPage.displayName = "ReleaseTriggersPage"
