import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import MissingImportExportPermissionCallout from "app/areas/projects/components/ImportExport/MissingImportExportPermissionCallout";
import * as React from "react";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { ImportExportMenu, getImportExportMenuItems } from "./ImportExportMenu";
import styles from "./style.module.less";
interface ImportExportProjectTasksInternalProps extends ImportExportProjectTasksPageProps {
    isPageHeaderVNextEnabled: boolean;
}
interface ImportExportProjectTasksPageState extends DataBaseComponentState {
    space?: SpaceResource;
}
class ImportExportProjectTasksPageInternal extends DataBaseComponent<ImportExportProjectTasksInternalProps, ImportExportProjectTasksPageState> {
    constructor(props: ImportExportProjectTasksInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    render() {
        const isSpaceManager = this.isSpaceManager();
        const overflowMenu = <ImportExportMenu spaceId={this.props.spaceId}/>;
        return (<main>
                {!this.props.isPageHeaderVNextEnabled && (<AreaTitle title="Projects" link={links.projectsPage.generateUrl({ spaceId: this.props.spaceId })}>
                        <ActionList actions={[overflowMenu]}/>
                    </AreaTitle>)}
                <ContextualHelpLayout>
                    {this.props.isPageHeaderVNextEnabled && (<PageHeaderPrimary title="Import/Export Tasks" navUpLink={{ label: "Projects", linkHref: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }} overflowActions={getImportExportMenuItems(this.props.spaceId)}/>)}
                    <div className={this.props.isPageHeaderVNextEnabled ? styles.containerVNext : styles.container}>
                        <Tasks renderLayout={(layoutProps) => (<ProjectPageLayout title={this.props.isPageHeaderVNextEnabled ? undefined : "Import/Export Tasks"} busy={layoutProps.busy} errors={this.errors} fullWidth={true}>
                                    {this.state.space && !isSpaceManager && <MissingImportExportPermissionCallout />}
                                    {this.state.space && isSpaceManager && (<>
                                            <div className={styles.layoutContainer}>{layoutProps.children}</div>
                                        </>)}
                                </ProjectPageLayout>)} restrictToTaskTypes={["ExportProjects", "ImportProjects"]} hideAdvancedFilters={true}/>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "ImportExportProjectTasksPageInternal";
}
interface ImportExportProjectTasksPageProps {
    spaceId: string;
}
export function ImportExportProjectTasksPage(props: ImportExportProjectTasksPageProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <ImportExportProjectTasksPageInternal {...props} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
