import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectTenantVariablesFeatureFlag } from "~/areas/projects/components/Variables/TenantVariables/useIsProjectTenantVariablesEnabled";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
export default function OptInProjectTenantVariablesCallout({ project }: {
    project: ProjectResource;
}) {
    const dispatchAction = useAnalyticActionDispatch();
    const [, setProjectTenantVariablesFeatureFlag] = useProjectTenantVariablesFeatureFlag();
    const newProjectTenantVariablesIsEnabled = isFeatureToggleEnabled("ProjectTenantVariablesFeatureToggle");
    const handleOptIn = () => {
        setProjectTenantVariablesFeatureFlag(true);
        dispatchAction("Opt In to Project Tenant Variables", { resource: "Tenant", action: Action.OptIn });
    };
    return newProjectTenantVariablesIsEnabled ? (<Callout title={"Improving variable management with multi-tenancy"} type={CalloutType.NewFeature}>
            We're making it easier to manage variables with multi-tenancy. To try out the new experience in this browser,{" "}
            <InternalLink to={links.projectTenantVariablesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })} onClick={handleOptIn}>
                switch to the new tenant variables page
            </InternalLink>
            .
        </Callout>) : null;
}
