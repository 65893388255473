import type { UnknownQueryParam, PageRouteDefinition, ParsedQueryParams } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { PageIdentity } from "./PageIdentity";
export interface LibraryPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement;
}
export function createLibraryPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[]>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement, pageIdentity: PageIdentity, legacyRouteLink: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>) => string): LibraryPageRegistration<RouteParams, QueryParams> {
    return {
        route,
        render,
        legacyRouteLink,
        pageIdentity,
    };
}
