/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ResourcesById, ProjectResource, TenantResource, RunbookResource, EnvironmentResource, RunbookProgressionResource, RunbooksDashboardItemResource, RunbookSnapshotResource, ResourceCollection, TriggerResource, TriggerScheduleResource, RunRunbookActionResource, RunbookRunTemplateResource, GitRefResource, } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode, TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { isEqual } from "lodash";
import * as React from "react";
import { NextScheduledRuns } from "~/areas/projects/components/Runbooks/NextScheduledRuns";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import Card from "~/components/Card/Card";
import { environmentChipListIncludingMissing } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import Logo from "~/components/Logo";
import InternalLink from "~/components/Navigation/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Section from "~/components/Section";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper";
import { LastPublishedChip } from "./LastPublishedChip";
import RunbooksPaperLayout from "./Layouts/RunbooksPaperLayout";
import PublishButton from "./PublishButton";
import RunNowButton from "./RunNowButton";
import { useRunbookContext } from "./RunbookContext";
import RunbookOnboarding from "./RunbookOnboarding";
import styles from "./RunbookOverviewLayout.module.less";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import { isRunbookConsumerTryingToRunAnUnpublishedSnapshot } from "./isRunbookConsumerTryingToRunAnUnpublishedSnapshot";
const untenantedDeploymentLogo = require("./un-tenanted-deployment-logo.svg");
interface RunbookOverviewPageState extends DataBaseComponentState {
    publishedRunbookSnapshot: RunbookSnapshotResource;
    runbookRunTemplate: RunbookRunTemplateResource;
    progression: RunbookProgressionResource;
    tenants: TenantResource[];
    environmentsById: ResourcesById<EnvironmentResource>;
    hasSteps: boolean;
    failedChecks: Array<{
        permission: Permission;
        isNotAllowed: boolean;
    }>;
    triggersResponse: ResourceCollection<TriggerResource>;
}
export interface RunbookOverviewPageRouteProps {
    runbookId: string;
}
const refreshIntervalInMs = 15000;
interface RunbookOverviewPageInternalProps extends RunbookOverviewPageProps {
    runbook: RunbookResource;
    project: ProjectResource;
    refreshGitVariableErrors: () => Promise<void>;
    gitRef: Readonly<GitRefResource> | undefined;
}
class RunbookOverviewPageInternal extends DataBaseComponent<RunbookOverviewPageInternalProps, RunbookOverviewPageState> {
    constructor(props: RunbookOverviewPageInternalProps) {
        super(props);
        this.state = {
            hasSteps: false,
            publishedRunbookSnapshot: null!,
            runbookRunTemplate: null!,
            progression: null!,
            tenants: [],
            environmentsById: null!,
            failedChecks: [],
            triggersResponse: null!,
        };
    }
    async componentDidMount() {
        // noinspection ES6MissingAwait This is an async method, but we don't care about the result, so fire and forget (no await).
        this.props.refreshGitVariableErrors();
        await this.doBusyTask(async () => {
            await this.reload();
            await this.startRefreshLoop(() => this.getData(), refreshIntervalInMs, true, timeOperationOptions.forRefresh());
        });
    }
    async componentDidUpdate(prevProps: RunbookOverviewPageInternalProps) {
        const nextRunbook = this.props.runbook;
        const currentRunbook = prevProps.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.doBusyTask(async () => {
                return await this.reload();
            });
        }
    }
    getData = async () => {
        const project = this.props.project;
        const runbook = this.props.runbook;
        const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
        const [tenants, environmentsById] = await Promise.all([tenantsPromise, repository.Environments.allById()]);
        const requiredPermissions = [
            { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
            { permission: Permission.EnvironmentView, wildcard: true },
        ];
        const failedChecks = requiredPermissions
            .map((check) => ({
            permission: check.permission,
            isNotAllowed: !isAllowed(check),
        }))
            .filter((check) => check.isNotAllowed);
        if (failedChecks.length > 0) {
            this.setState({
                failedChecks,
            });
            return null;
        }
        const gitRef = this.props.gitRef;
        const runbookProcess = repository.RunbookProcess.get(runbook.RunbookProcessId);
        const runbookProgression = repository.Progression.getRunbookProgression(runbook);
        const runbookTriggers = repository.Projects.getTriggers(project, gitRef, 0, 5, null!, TriggerActionCategory.Runbook, [runbook.Id]);
        const publishedRunbookSnapshot = runbook.PublishedRunbookSnapshotId ? await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : null!;
        const runbookRunTemplate = publishedRunbookSnapshot ? await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot) : null!;
        return {
            progression: await runbookProgression,
            tenants,
            environmentsById,
            hasSteps: (await runbookProcess).Steps.length > 0,
            publishedRunbookSnapshot,
            runbookRunTemplate,
            triggersResponse: await runbookTriggers,
        };
    };
    async reload() {
        this.setState(await timeOperation(timeOperationOptions.forInitialLoad(), () => this.getData()));
    }
    renderDashboard() {
        if (!this.state.progression || Object.keys(this.state.progression.RunbookRuns).length === 0) {
            const runbook = this.props.runbook;
            return (<>
                    <div className={styles.emptyCell}>
                        There are no runs for this Runbook yet.&nbsp;<InternalLink to={links.runbookRunNowPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: this.props.projectSlug, runbookId: runbook.Id })}>Run Now</InternalLink>
                    </div>
                    <NoResults />
                </>);
        }
        const progression = this.state.progression;
        const environments = progression.Environments;
        const environmentKeys = Object.keys(progression.RunbookRuns);
        const rowsByTenant: Record<string, RunbooksDashboardItemResource[]> = {};
        environmentKeys.map((i) => {
            const runbookRuns = progression.RunbookRuns[i];
            runbookRuns.forEach((r) => {
                const untenantedRowKey = "";
                const rowKey = r.TenantId ? this.state.tenants!.find((t) => t.Id === r.TenantId)!.Name : untenantedRowKey;
                if (!rowsByTenant[rowKey]) {
                    rowsByTenant[rowKey] = [];
                }
                rowsByTenant[rowKey].push(r);
            });
        });
        const rowsBySortedTenantKeys = Object.keys(rowsByTenant).sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        const isTenanted = this.props.runbook.MultiTenancyMode !== TenantedDeploymentMode.Untenanted;
        return (<div>
                <div className={styles.dashboardCards}>
                    {rowsBySortedTenantKeys.map((tenantName, tenantRowIndex) => {
                const tenant = this.state.tenants.find((t) => t.Name === tenantName);
                const tenantId = tenant ? tenant.Id : null;
                return (<div className={styles.bodyRow} key={tenantRowIndex}>
                                {isTenanted && (<Section className={cn(styles.tenantName, styles.groupHeader)} key={tenantRowIndex}>
                                        {tenant && this.tile(tenant.Links.Logo, tenant.Name, links.tenantOverviewPage.generateUrl({ spaceId: tenant.SpaceId, tenantId: tenant.Id }))}
                                        {!tenant && this.tile(untenantedDeploymentLogo, "Untenanted")}
                                    </Section>)}
                                {environmentKeys.map((x, environmentRowIndex) => {
                        const runbookRuns = progression.RunbookRuns[x];
                        const environment = environments.find((e) => e.Id === x);
                        const thisRunbookRun = runbookRuns.find((o) => o.TenantId === tenantId);
                        if (!environment || !thisRunbookRun) {
                            return null;
                        }
                        else {
                            const menuItems = [];
                            if (!isRunbookConsumerTryingToRunAnUnpublishedSnapshot(this.props.project, this.props.runbook, thisRunbookRun.RunbookSnapshotId)) {
                                menuItems.push(OverflowMenuItems.navItem("Run on...", links.runbookRunSnapshotNowPage.generateUrl({
                                    spaceId: this.props.spaceId,
                                    projectSlug: this.props.projectSlug,
                                    runbookId: this.props.runbookId,
                                    runbookSnapshotId: thisRunbookRun.RunbookSnapshotId,
                                }), {
                                    permission: Permission.RunbookRunCreate,
                                    project: this.props.project.Id,
                                    wildcard: true,
                                }));
                            }
                            menuItems.push(OverflowMenuItems.navItem("View snapshot", links.projectRunbookSnapshotInfoPage.generateUrl({
                                spaceId: this.props.project.SpaceId,
                                projectSlug: this.props.project.Slug,
                                runbookId: this.props.runbookId,
                                runbookSnapshotId: thisRunbookRun.RunbookSnapshotId,
                            }), {
                                permission: Permission.RunbookView,
                                project: this.props.project.Id,
                                wildcard: true,
                            }));
                            return (<Card key={environmentRowIndex} className={styles.cardContainer} contentClassName={styles.cardContentContainer} leftAlign={true} logo={null} header={<div className={styles.runTaskEnvironment}>{environment.Name}</div>} content={<div>
                                                        <div className={styles.runTaskDetails}>
                                                            <RunbookTaskStatusDetails project={this.props.project} item={thisRunbookRun}/>
                                                            <div className={styles.runActions}>
                                                                <OverflowMenu menuItems={menuItems} tabIndex={-1}/>
                                                            </div>
                                                        </div>
                                                        {thisRunbookRun.RunbookSnapshotNotes ? <div className={styles.runTaskNotes}>{thisRunbookRun.RunbookSnapshotNotes}</div> : null}
                                                    </div>}/>);
                        }
                    })}
                            </div>);
            })}
                </div>
            </div>);
    }
    tile(logoUrl: string, name: string, toUrl?: LinkHref) {
        if (toUrl) {
            return (<InternalLink to={toUrl} className={styles.rowHeader}>
                    <span>
                        <Logo url={logoUrl} size="2.25rem"/>
                    </span>
                    <span className={styles.tileName}>{name}</span>
                </InternalLink>);
        }
        else {
            return (<div className={styles.rowHeader}>
                    <span>
                        <Logo url={logoUrl} size="2.25rem"/>
                    </span>
                    <span className={styles.tileName}>{name}</span>
                </div>);
        }
    }
    render() {
        const runbook = this.props.runbook;
        const project = this.props.project;
        const breadcrumbTitle = "Runbooks";
        const breadcrumbPath = links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug });
        if (this.state.failedChecks.length > 0) {
            return (<RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} busy={this.state.busy} errors={this.errors}>
                    <Callout type={CalloutType.Information} title={"Permission required"}>
                        The {this.state.failedChecks[0].permission} permission is required to view project overview details
                    </Callout>
                </RunbooksPaperLayout>);
        }
        const nextScheduledElement = this.renderNextScheduled();
        if (!this.state.hasSteps) {
            const defineRunbookProcessPageAction: PrimaryPageAction = {
                type: "navigate",
                label: "Define your Runbook Process",
                path: links.projectRunbookProcessListPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, processId: runbook.RunbookProcessId }),
                hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
            };
            return (<RunbooksPaperLayout title={runbook.Name} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} busy={this.state.busy} errors={this.errors} primaryAction={defineRunbookProcessPageAction} sidebar={nextScheduledElement}>
                    <RunbookOnboarding />
                </RunbooksPaperLayout>);
        }
        const runbookRunTemplate = this.state.runbookRunTemplate;
        const publishedRunbookSnapshot = this.state.publishedRunbookSnapshot;
        const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
        const pageActions: PageAction[] = [];
        if (this.state.hasSteps) {
            pageActions.push({
                type: "custom",
                key: "Publish",
                content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
                hasPermissions: isAllowed({
                    permission: Permission.RunbookEdit,
                    project: this.props.project.Id,
                    wildcard: true,
                }),
            });
        }
        pageActions.push({
            type: "custom",
            key: "Run now",
            content: <RunNowButton spaceId={this.props.spaceId} isDisabled={!this.state.hasSteps}/>,
            hasPermissions: isAllowed({
                permission: Permission.RunbookRunCreate,
                project: this.props.project.Id,
                wildcard: true,
            }),
        });
        const overFlowActions: Array<MenuItem | MenuItem[]> = [];
        overFlowActions.push(OverflowMenuItems.navItem("View Snapshots", links.projectRunbookSnapshotsPage.generateUrl({ spaceId: runbook.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }), {
            permission: Permission.RunbookView,
            project: project.Id,
            projectGroup: project.ProjectGroupId,
            wildcard: true,
        }));
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }), {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
        const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
        return (<RunbooksPaperLayout title={runbook.Name} titleChip={titleChip} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} pageActions={pageActions} overflowActions={overFlowActions} sidebar={nextScheduledElement} busy={this.state.busy} errors={this.errors}>
                {this.renderDashboard()}
            </RunbooksPaperLayout>);
    }
    private renderNextScheduled() {
        const project = this.props.project;
        const runbook = this.props.runbook;
        if (!this.state.triggersResponse) {
            return null;
        }
        return (<NextScheduledRuns project={project} runbook={runbook} triggersResponse={this.state.triggersResponse} triggerActionCategory={TriggerActionCategory.Runbook} renderBuildTriggerRow={(renderProps) => {
                const trigger = renderProps.trigger;
                const description = this.getTriggerDescription(trigger);
                return (<div className={styles.nextScheduledRow}>
                            <div>
                                <InternalLink to={links.projectRunbookEditScheduledTriggerPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, triggerId: trigger.Id })}>{trigger.Name}</InternalLink>
                            </div>
                            <div>{description}</div>
                        </div>);
            }}/>);
    }
    private getTriggerDescription(trigger: TriggerResource) {
        const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(trigger.Filter as TriggerScheduleResource);
        const destinationEnvironmentIds = (trigger.Action as RunRunbookActionResource).EnvironmentIds;
        const environments = Object.values(this.state.environmentsById);
        const environmentChips = environmentChipListIncludingMissing(environments, destinationEnvironmentIds);
        return (<span>
                Run {scheduleDescription} on {environmentChips}.
            </span>);
    }
    static displayName = "RunbookOverviewPageInternal";
}
interface RunbookOverviewPageProps {
    spaceId: string;
    projectSlug: string;
    runbookId: string;
}
function RunbookOverviewPage(props: RunbookOverviewPageProps) {
    const projectContext = useProjectContext();
    const runbookContext = useRunbookContext();
    const project = projectContext.state.model;
    const gitRef = projectContext.state.gitRef;
    const runbook = runbookContext.state.runbook;
    if (!project || !runbook) {
        return <RunbooksPaperLayout busy={true}/>;
    }
    return <RunbookOverviewPageInternal project={project} runbook={runbook} refreshGitVariableErrors={projectContext.actions.refreshGitVariableErrors} gitRef={gitRef} {...props}/>;
}
export default RunbookOverviewPage;
