/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { CertificateResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { CertificateDataFormat, CertificateExportPemOptions } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note, Sensitive } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
enum CertificateDataFormatOption {
    Original = "Original",
    Pkcs12 = "Pkcs12",
    Der = "Der",
    Pem = "Pem"
}
interface CertificateDownloadFormProps {
    certificate: CertificateResource;
}
interface CertificateDownloadFormState extends DataBaseComponentState {
    format: CertificateDataFormatOption;
    password?: SensitiveValue;
    includePrivateKey: boolean;
    pemOptions: CertificateExportPemOptions;
}
export default class DownloadCertificate extends DataBaseComponent<CertificateDownloadFormProps, CertificateDownloadFormState> {
    constructor(props: CertificateDownloadFormProps) {
        super(props);
        this.state = {
            format: CertificateDataFormatOption.Original,
            password: null!,
            includePrivateKey: false,
            pemOptions: CertificateExportPemOptions.PrimaryOnly,
        };
    }
    download() {
        const options = {
            format: this.state.format === CertificateDataFormatOption.Original ? null : this.state.format,
            includePrivateKey: this.state.includePrivateKey,
            password: this.state.password && this.state.password.NewValue ? this.state.password.NewValue : null!,
            pemOptions: this.state.pemOptions,
        };
        const url = repository.resolve(this.props.certificate.Links["Export"], options);
        if (url) {
            window.location.href = url;
        }
        return true;
    }
    renderStandardOptions() {
        return (<div>
                <RadioButtonGroup value={this.state.format} onChange={(format: CertificateDataFormatOption) => this.setState({ format })}>
                    <RadioButton value={CertificateDataFormatOption.Original} label="Original"/>
                    <Note>
                        The certificate will be exported exactly as it was originally uploaded. If it was originally password protected, it will still be protected by the same password. In cases where the private-key was uploaded, to export the
                        public-key only, choose DER or PEM format below.
                    </Note>
                    <br />
                    <RadioButton value={CertificateDataFormatOption.Pkcs12} label="PFX (PKCS#12)"/>
                    <Note>
                        <ExternalLink href="PFXFiles">PKCS #12</ExternalLink> file. Will contain private-key (if present).
                    </Note>
                    <br />
                    <RadioButton value={CertificateDataFormatOption.Der} label="DER"/>
                    <Note>
                        <ExternalLink href="DERFiles">DER</ExternalLink> encoded. Will not contain private-key.
                    </Note>
                    <br />
                    <RadioButton value={CertificateDataFormatOption.Pem} label="PEM"/>
                    <Note>
                        <ExternalLink href="PEMFiles">PEM</ExternalLink> encoded. Optional inclusion of private-key.
                    </Note>
                    <br />
                </RadioButtonGroup>

                {this.state.format === CertificateDataFormatOption.Pkcs12 && this.props.certificate.HasPrivateKey && (<div>
                        <h3>Set Password</h3>
                        <Sensitive value={this.state.password!} onChange={(password) => this.setState({ password })} label="Password"/>
                        <Note>The PFX file will contain the private-key. Entering a password is strongly recommended.</Note>
                    </div>)}

                {this.state.format === CertificateDataFormatOption.Pem && (<div>
                        <h3>Included Certificates</h3>
                        {_.some(this.props.certificate.CertificateChain) && (<div>
                                <RadioButtonGroup value={this.state.pemOptions} onChange={(pemOptions: CertificateExportPemOptions) => this.setState({ pemOptions })}>
                                    <RadioButton value={CertificateExportPemOptions.PrimaryOnly} label="Primary Certificate"/>
                                    <RadioButton value={CertificateExportPemOptions.PrimaryAndChain} label="Primary and Chain Certificates"/>
                                    <RadioButton value={CertificateExportPemOptions.ChainOnly} label="Chain Certificates Only"/>
                                </RadioButtonGroup>
                            </div>)}

                        {this.props.certificate.HasPrivateKey && (this.state.pemOptions === CertificateExportPemOptions.PrimaryOnly || this.state.pemOptions === CertificateExportPemOptions.PrimaryAndChain) && (<div>
                                <h3>Private Key</h3>
                                <Checkbox value={this.state.includePrivateKey} onChange={(includePrivateKey) => this.setState({ includePrivateKey })} label="Include Private Key" note={<span>The PEM file will contain the private-key.</span>}/>
                            </div>)}
                    </div>)}
            </div>);
    }
    renderInvalidCertOptions() {
        return (<div>
                {" "}
                <RadioButtonGroup value={this.state.format} onChange={(format: CertificateDataFormatOption) => this.setState({ format })}>
                    <RadioButton value={CertificateDataFormatOption.Original} label="Original"/>
                    <Note>The certificate will be exported exactly as it was originally uploaded. If it was originally password protected, it will still be protected by the same password.</Note>
                    <br />
                </RadioButtonGroup>
            </div>);
    }
    render() {
        return (<OkDialogLayout title="Download Certificate" busy={this.state.busy} errors={this.errors} onOkClick={() => this.download()}>
                {this.props.certificate.CertificateDataFormat !== CertificateDataFormat.Unknown && <p>Export the certificate file in PFX, PEM, or DER format.</p>}
                <Callout title="Note" type={CalloutType.Information}>
                    See our <ExternalLink href="ExportCertificate">documentation</ExternalLink> for more information on exporting certificates.
                </Callout>

                {this.props.certificate.CertificateDataFormat === CertificateDataFormat.Unknown && (<div>
                        <br />
                        <Callout title="Note" type={CalloutType.Warning}>
                            Because the certificate cannot be parsed and it cannot be determined if a private key is present, then you must have the <code>CertificateExportPrivateKey</code> permission to download this certificate. Only the full
                            original certificate can be exported.
                        </Callout>
                    </div>)}

                <h4>Select certificate format</h4>
                {this.props.certificate.CertificateDataFormat === CertificateDataFormat.Unknown ? this.renderInvalidCertOptions() : this.renderStandardOptions()}
                <br />
            </OkDialogLayout>);
    }
    static displayName = "DownloadCertificate";
}
