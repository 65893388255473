/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import type { BreadcrumbItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import styles from "./style.module.less";
interface RawLogPageInternalProps extends RawLogPageProps {
    isPageHeaderVNextEnabled: boolean;
}
interface RawLogPageState extends DataBaseComponentState {
    task?: TaskResource<any>;
    raw?: string;
}
class RawLogPageInternal extends DataBaseComponent<RawLogPageInternalProps, RawLogPageState> {
    constructor(props: RawLogPageInternalProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        return this.refresh();
    }
    refresh() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.props.taskId);
            const raw = await repository.Tasks.getRaw(task);
            this.setState({ task, raw });
        });
    }
    render() {
        const task = this.state.task!;
        const pageActions: PageAction[] = task
            ? [
                {
                    type: "navigate",
                    buttonType: "secondary",
                    path: task.Links["Raw"],
                    label: "Download",
                    external: true,
                },
            ]
            : [];
        const refreshPageAction: PrimaryPageAction | undefined = task
            ? {
                type: "button",
                label: "Refresh",
                disabled: this.state.busy,
                onClick: () => this.refresh(),
            }
            : undefined;
        const breadcrumbsItems: BreadcrumbItem[] = [{ label: "Tasks", pageUrl: links.tasksPage.generateUrl() }];
        if (task) {
            breadcrumbsItems.push({ label: task.Description, pageUrl: links.taskPage.generateUrl({ taskId: task.Id }) });
        }
        return (<main>
                {!this.props.isPageHeaderVNextEnabled && <AreaTitle link={links.tasksPage.generateUrl()} title="Tasks"/>}

                <ContextualHelpLayout>
                    <div className={this.props.isPageHeaderVNextEnabled ? containerStylesVNext : containerStyles}>
                        <PaperLayout title={"Raw Log"} titleLogo={task ? <TaskStatusIcon item={task}/> : undefined} breadcrumbTitle={task && task.Description} breadcrumbPath={task ? links.taskPage.generateUrl({ taskId: task.Id }) : undefined} breadcrumbsItems={breadcrumbsItems} busy={this.state.busy} errors={this.errors} pageActions={pageActions} primaryAction={refreshPageAction} fullWidth={true}>
                            {task && <div className={styles.rawLogContent}>{this.state.raw}</div>}
                        </PaperLayout>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "RawLogPageInternal";
}
const containerStyles = css({
    margin: `${space[12]} ${space[16]} 0 ${space[16]}`,
});
const containerStylesVNext = css({
    margin: space[32],
});
interface RawLogPageProps {
    taskId: string;
}
export function RawLogPage({ taskId }: RawLogPageProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <RawLogPageInternal taskId={taskId} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
