import { LinearProgress } from "@octopusdeploy/design-system-components";
import { AccountType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useAnalyticTrackedActionDispatch, useCorrelationId } from "~/analytics/Analytics";
import { AccountEditInternal, type AccountInitialData } from "~/areas/infrastructure/components/AccountEdit/AccountPage";
import { useDispatchAccountInteraction } from "~/areas/infrastructure/components/AccountEdit/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import DataLoader from "~/components/DataLoader";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { AccountDrawerControlProps } from "./AccountEditBase";
interface AccountDrawerProps extends AccountDrawerControlProps {
    newAccountType: AccountType;
}
const AccountDrawerDataLoader = DataLoader<AccountInitialData>();
export function AccountEditDrawer({ newAccountType, isOpen, setIsOpen, onSuccess: onSave }: AccountDrawerProps) {
    const trackAction = useAnalyticTrackedActionDispatch();
    const analyticsDispatch = useDispatchAccountInteraction();
    const correlationId = useCorrelationId();
    const loadData = async (): Promise<AccountInitialData> => {
        const environments = repository.Environments.all();
        const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
        const azureEnvironments = repository.Accounts.getIsolatedAzureEnvironments();
        return {
            environments: await environments,
            tenants: await tenants,
            azureEnvironments: await azureEnvironments,
            accountType: newAccountType,
        };
    };
    return (<PermissionCheck permission={Permission.AccountCreate} environment="*" tenant="*" key="perms">
            <AccountDrawerDataLoader load={loadData} renderWhenLoaded={function (data: AccountInitialData): React.ReactNode {
            return (newAccountType !== AccountType.None && (<AccountEditInternal initialData={{ ...data, accountType: newAccountType }} trackAction={trackAction} analyticsDispatch={analyticsDispatch} correlationId={correlationId} type="drawer" isOpen={isOpen} setIsOpen={setIsOpen} onSuccess={onSave}></AccountEditInternal>));
        }} renderAlternate={({ busy, errors }) => <LinearProgress show={busy} variant="indeterminate"></LinearProgress>}></AccountDrawerDataLoader>
        </PermissionCheck>);
}
