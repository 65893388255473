import * as React from "react";
import { repository } from "~/clientInstance";
import PaperLayout from "~/components/PaperLayout/index";
import routeLinks from "~/routeLinks";
import { AsyncInternalRedirect } from "~/routing/AsyncInternalRedirect";
import StringHelper from "~/utils/StringHelper/index";
export function ScopedUserRoleToTeamRedirect({ scopedUserRoleId }: {
    scopedUserRoleId: string;
}) {
    return (<AsyncInternalRedirect loader={async () => {
            const scopedUserRole = await repository.ScopedUserRoles.get(scopedUserRoleId);
            return routeLinks.forSpace(scopedUserRole.SpaceId).configuration.team(scopedUserRole.TeamId);
        }} renderWhenLoading={<PaperLayout busy={true} title={StringHelper.ellipsis}/>}/>);
}
