import type { ParsedQueryParams, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { useQueryStringParams } from "@octopusdeploy/portal-routes";
import React from "react";
import type { RouteProps } from "react-router";
import ReloadableRoute from "~/components/ReloadableRoute";
import { Page } from "../Page";
import type { PageRegistration } from "./PageRegistration";
import { decodeRouteParams } from "./decodeRouteParams";
export interface RoutePageProps<RouteParams, QueryParams extends UnknownQueryParam[]> extends Omit<RouteProps, "render"> {
    path: string;
    exact?: boolean;
    pageRegistration: PageRegistration<RouteParams, QueryParams>;
    render: (routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => React.ReactNode;
}
export function RoutePage<RouteParams, QueryParams extends UnknownQueryParam[]>({ path, exact, pageRegistration, render, ...otherProps }: RoutePageProps<RouteParams, QueryParams>) {
    const [queryParams, setQueryParams] = useQueryStringParams<QueryParams>(pageRegistration.route.queryParameters);
    // The ReloadableRoute requires some props that directly come from react router, injected by the Switch (i.e. the `computedMatch`). The RoutePage sits in between these, so should forward any extra props to the ReloadableRoute.
    return <ReloadableRoute path={path} exact={exact} render={(routeProps) => <Page pageIdentity={pageRegistration.pageIdentity}>{render(decodeRouteParams(routeProps.match.params), queryParams, setQueryParams)}</Page>} {...otherProps}/>;
}
