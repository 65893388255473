import { css, cx } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { TenantsDetailProject, TenantsOverviewTenant, TenantTagSummary } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Action, useAnalyticDataTableExpansionDispatch } from "~/analytics/Analytics";
import { TenantDataTableAllEnvironmentsCell, TenantDataTableEnvironmentsSummaryCell, TenantDataTableMultipleProjectsEnvironmentsCell } from "~/areas/tenants/components/DataTable/Cells/TenantDataTableEnvironmentsCell";
import { TenantsDataTableNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableNameCell";
import { TenantsDataTableProjectCell, TenantsDataTableProjectsCell } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableProjectsCell";
import { TenantsDataTableTagsCell } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableTagsCell";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
interface TenantsDataTableProps {
    spaceId: string;
    tenants: TenantsOverviewTenant[];
    totalItems: number;
    includeMissingVariables: boolean;
    doBusyTask: DoBusyTask;
}
export default function TenantsDataTable(props: React.PropsWithChildren<TenantsDataTableProps>) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<table className={cx(tableStyles, { [tableMobileStyles]: !isLargerThanIpad })}>
            <thead>
                <tr>
                    <th>Tenant</th>
                    <th>Tenant tag</th>
                    <th>Project</th>
                    <th colSpan={2}>Environment</th>
                </tr>
            </thead>
            <tbody>
                {props.tenants.map((tenant) => (<TenantsDataTableRow key={tenant.Id} spaceId={props.spaceId} tenant={tenant} doBusyTask={props.doBusyTask} includeMissingVariables={props.includeMissingVariables}/>))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={5}>{props.children}</td>
                </tr>
            </tfoot>
        </table>);
}
interface TenantsDataTableRowProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    includeMissingVariables: boolean;
    doBusyTask: DoBusyTask;
}
function TenantsDataTableRow({ spaceId, tenant, includeMissingVariables, doBusyTask }: TenantsDataTableRowProps) {
    const [isTenantExpanded, setIsTenantExpanded] = useState(false);
    const [details, setDetails] = useState<TenantDetails | null>(null);
    const dispatchAction = useAnalyticDataTableExpansionDispatch();
    function dispatchViewMoreLessAction(category: string) {
        dispatchAction("View More/Less", { resource: "Content", action: Action.View, category });
    }
    async function loadTenantDetails() {
        const tenantDetail = await repository.Tenants.tenantDetail(tenant.Id, includeMissingVariables);
        setDetails({ allTags: tenantDetail.Tenant.Tags, allProjects: tenantDetail.Tenant.Projects });
    }
    async function loadDetailsWithBusyTask() {
        if (!details) {
            await doBusyTask(async () => await loadTenantDetails());
        }
    }
    async function onRowExpandCollapse() {
        await loadDetailsWithBusyTask();
        setIsTenantExpanded(!isTenantExpanded);
        dispatchViewMoreLessAction("Tenant");
    }
    async function onShowAll(category: string) {
        await loadDetailsWithBusyTask();
        dispatchViewMoreLessAction(category);
    }
    return <TenantRowInternal spaceId={spaceId} isTenantExpanded={isTenantExpanded} tenant={tenant} details={details} onRowExpandCollapse={onRowExpandCollapse} onShowAll={onShowAll}/>;
}
interface TenantDetails {
    allTags: TenantTagSummary[];
    allProjects: TenantsDetailProject[];
}
interface TenantRowInternalProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    details: TenantDetails | null;
    onRowExpandCollapse: () => Promise<void>;
    onShowAll: (category: string) => Promise<void>;
    isTenantExpanded: boolean;
}
function TenantRowInternal({ spaceId, tenant, details, onRowExpandCollapse, onShowAll, isTenantExpanded }: TenantRowInternalProps) {
    if (details && isTenantExpanded) {
        return (<React.Fragment>
                {details.allProjects.map((p, i) => i === 0 ? (<MultiProjectTenantParentRow key={p.Id} spaceId={spaceId} tenant={tenant} details={details} project={p} onRowExpandCollapse={onRowExpandCollapse} isTenantExpanded={isTenantExpanded}/>) : (<MultiProjectTenantChildRow key={p.Id} project={p}/>))}
            </React.Fragment>);
    }
    const hasMultipleProjects = tenant.Projects.Items.length > 1;
    if (hasMultipleProjects) {
        return <MultiProjectTenantParentRow spaceId={spaceId} tenant={tenant} details={details} onRowExpandCollapse={onRowExpandCollapse} isTenantExpanded={isTenantExpanded}/>;
    }
    return <NonExpandableTenantRow spaceId={spaceId} tenant={tenant} details={details} onShowAll={onShowAll}/>;
}
interface NonExpandableTenantRowTenantRowProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    details: TenantDetails | null;
    onShowAll: (category: string) => Promise<void>;
}
function NonExpandableTenantRow({ spaceId, tenant, details, onShowAll }: NonExpandableTenantRowTenantRowProps) {
    const project = tenant.Projects.Items[0];
    return (<tr>
            <td className={tenantColumnStyle}>
                <TenantsDataTableNameCellWithLink spaceId={spaceId} tenantId={tenant.Id} tenantName={tenant.Name} tenantLogoLink={tenant.LogoLink}/>
            </td>
            <td className={tagColumnStyle}>
                <TenantsDataTableTagsCell tenantId={tenant.Id} tags={tenant.Tags} allTags={details?.allTags} onShowAll={onShowAll}/>
            </td>
            <td className={projectColumnStyle}>
                <TenantsDataTableProjectCell project={project}/>
            </td>
            <td colSpan={2} className={environmentColumnStyle}>
                <TenantDataTableEnvironmentsSummaryCell environments={tenant.Projects.Items[0]?.Environments} allEnvironments={details?.allProjects && details?.allProjects.length > 0 ? details?.allProjects[0].Environments : undefined} hasMissingVariables={tenant.HasMissingVariables} onShowAll={onShowAll}/>
            </td>
        </tr>);
}
interface MultiProjectTenantParentRowProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    details: TenantDetails | null;
    project?: TenantsDetailProject;
    onRowExpandCollapse: () => void;
    isTenantExpanded: boolean;
}
function MultiProjectTenantParentRow({ spaceId, tenant, details, project, onRowExpandCollapse, isTenantExpanded }: MultiProjectTenantParentRowProps) {
    const uniqueEnvironments = new Set(tenant.Projects.Items.flatMap((p) => p.Environments.Items));
    const environments = Array.from(uniqueEnvironments);
    return (<tr>
            <td className={tenantColumnStyle}>
                <TenantsDataTableNameCellWithLink spaceId={spaceId} tenantId={tenant.Id} tenantName={tenant.Name} tenantLogoLink={tenant.LogoLink}/>
            </td>
            <td className={tagColumnStyle}>
                <TenantsDataTableTagsCell tenantId={tenant.Id} allTags={details?.allTags} isTenantExpanded={isTenantExpanded} tags={tenant.Tags}/>
            </td>
            <td className={projectColumnStyle}>{project ? <TenantsDataTableProjectCell project={project}/> : <TenantsDataTableProjectsCell projects={tenant.Projects}/>}</td>
            <td className={environmentColumnStyle}>
                {project ? (<TenantDataTableAllEnvironmentsCell environments={project.Environments} hasMissingVariables={project.HasMissingVariables}/>) : (<TenantDataTableMultipleProjectsEnvironmentsCell hasMissingVariables={tenant.HasMissingVariables} environments={environments}/>)}
            </td>
            <td>
                <IconButton accessibleName={`Toggle expansion for ${tenant.Name}`} icon={isTenantExpanded ? "Collapse" : "Expand"} onClick={onRowExpandCollapse}/>
            </td>
        </tr>);
}
interface MultiProjectTenantChildRowProps {
    project: TenantsDetailProject;
}
function MultiProjectTenantChildRow({ project }: MultiProjectTenantChildRowProps) {
    return (<tr>
            <td className={noBorderTop}></td>
            <td className={noBorderTop}></td>
            <td>
                <TenantsDataTableProjectCell project={project}/>
            </td>
            <td>
                <TenantDataTableAllEnvironmentsCell environments={project.Environments} hasMissingVariables={project.HasMissingVariables}/>
            </td>
            <td></td>
        </tr>);
}
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    backgroundColor: themeTokens.color.background.primary.default,
    border: tableBorder,
    borderRadius: borderRadius.large,
    borderSpacing: 0,
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.table.cell.default.medium,
        position: "relative",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "middle",
    },
});
const tableMobileStyles = css({
    display: "block",
    overflowX: "auto",
});
const tenantColumnStyle = css({ width: "20%" });
const tagColumnStyle = css({ width: "30%" });
const projectColumnStyle = css({ width: "20%" });
const environmentColumnStyle = css({ width: "30%" });
const noBorderTop = css({
    borderTop: "0 !important",
});
