import { HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { Switch } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { MigratedRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import AllVariables from "./AllVariables";
export const AllVariablesPage = withPage({ page: pageIds.project().variables.all })(AllVariables);
type AllVariablesRouteProps = {
    path?: string;
};
export function AllVariablesRoute({ path }: React.PropsWithChildren<AllVariablesRouteProps>) {
    return (<BranchAwareRedirect condition={(project) => HasVariablesInGit(project.PersistenceSettings)}>
            <ErrorContextProvider>
                <Switch>
                    <MigratedRoute path={path ? `${path}` : undefined} exact={true} render={(props) => <AllVariablesPage />}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>
        </BranchAwareRedirect>);
}
export const MigratedAllVariablesRoute = AllVariablesRoute;
