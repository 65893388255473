import { css, cx } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PageActions } from "~/components/PageActions/PageActions";
interface PageHeaderSecondaryMainWithActionsProps {
    /**
     * An optional primary action to display on the right side of the header
     */
    primaryAction?: PrimaryPageAction;
    /**
     * An optional list of actions to display on the right side of the header
     */
    pageActions?: PageAction[];
    /**
     * The items to show as part of the overflow menu on the right side of the header
     */
    overflowActions?: Array<MenuItem | MenuItem[]>;
}
export type PageHeaderSecondaryMainContentProps = PageHeaderSecondaryMainWithActionsProps & PageHeaderTitleProps;
export function PageHeaderSecondaryMainContent({ titleStatusIcon, title, titleChip, titleAccessory, ...actionProps }: PageHeaderSecondaryMainContentProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<div className={cx(mainContentStyles, { [mainContentMobileStyles]: !isLargerThanIpad })}>
            <PageHeaderTitle title={title} titleStatusIcon={titleStatusIcon} titleChip={titleChip} titleAccessory={titleAccessory}/>
            <PageActions primaryAction={actionProps.primaryAction} actions={actionProps.pageActions} overflowActions={actionProps.overflowActions}/>
        </div>);
}
interface PageHeaderTitleProps {
    /** The title of the HeaderSecondary. */
    title: ReactNode;
    /**
     * The status icon to show on the left side of the title.
     *
     * The status icon is only visible when this property is defined.
     */
    titleStatusIcon?: ReactElement;
    /**
     * The chip to show on the right side of the title.
     *
     * The status icon is only visible when this property is defined.
     */
    titleChip?: ReactElement;
    /**
     * An element which can be used to display in addition to the title. This is
     * displayed to the right of the title, but before any chips.
     */
    titleAccessory?: ReactElement;
}
function PageHeaderTitle({ titleStatusIcon, title, titleAccessory, titleChip }: PageHeaderTitleProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<div className={cx(titleSectionStyles, { [titleSectionMobileStyles]: !isLargerThanIpad })}>
            <span className={titleLabelStyles}>
                {titleStatusIcon}
                <h1 className={titleStyles}>{title}</h1>
            </span>
            {titleAccessory}
            <div className={chipAndLoadingIndicatorWrapperStyles}>{titleChip}</div>
        </div>);
}
const mainContentStyles = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: space["48"],
    minHeight: "2.25rem",
});
const mainContentMobileStyles = css({
    flexDirection: "column",
    gap: space["24"],
    alignItems: "flex-start",
});
const titleLabelStyles = css({
    display: "flex",
    gap: space["12"],
    alignItems: "center",
});
const titleSectionStyles = css({
    display: "flex",
    gap: space["12"],
    alignItems: "center",
});
const titleSectionMobileStyles = css({
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
});
const titleStyles = css({
    all: "unset",
    font: text.interface.heading.medium,
    color: themeTokens.color.text.primary,
});
const pageActionsWrapper = css({
    flexShrink: 0,
});
const chipAndLoadingIndicatorWrapperStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["12"],
});
