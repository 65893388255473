import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router";
import { EditVariableSetPage } from "~/areas/library/components/VariableSets/EditVariableSetPage";
import { VariableSetsPage } from "~/areas/library/components/VariableSets/VariableSetsPage";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "../../../../routeLinks";
const EditVariableSetPageWithPageDefinition = withPage({ page: pageIds.library.variableSet })(EditVariableSetPage);
const VariableSetsPageWithPageDefinition = withPage({ page: pageIds.library.variableSets.root })(VariableSetsPage);
export default class VariableSetsRoutes extends React.Component<RouteComponentProps> {
    render() {
        const libraryLinks = routeLinks.library;
        return (<Switch>
                <MigratedReloadableRoute path={libraryLinks.variableSet(":variableSetId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            variableSetId: string;
        }>) => <EditVariableSetPageWithPageDefinition spaceId={match.params.spaceId} variableSetId={match.params.variableSetId}/>}/>
                <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <VariableSetsPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
            </Switch>);
    }
    static displayName = "VariableSetsRoutes";
}
