import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
interface DeploymentFreezeProjectSummary {
    projectId: string;
    name: string;
}
interface DeploymentFreezeEnvironmentSummary {
    environmentId: string;
    name: string;
}
interface DeploymentFreezesSummary {
    name: string;
    start: string;
    end: string;
    projects: DeploymentFreezeProjectSummary[];
    environments: DeploymentFreezeEnvironmentSummary[];
}
interface DeploymentFreezesListState extends DataBaseComponentState {
    DeploymentFreezes: DeploymentFreezesSummary[];
}
// interface DeploymentFreezesListProps {}
class DeploymentFreezesListPage extends DataBaseComponent<{}, DeploymentFreezesListState> {
    render() {
        return <>Freezes</>;
    }
    static displayName = "DeploymentFreezesListPage";
}
export default DeploymentFreezesListPage;
