import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { CloseButton } from "~/components/Button";
export enum CalloutType {
    Information,
    Success,
    Warning,
    Danger,
    NewFeature,
    Generic
}
interface OptionalTitleProps {
    title?: React.ReactNode;
    hideTitle: true;
}
interface RequiredTitleProps {
    title: React.ReactNode;
    hideTitle?: false;
}
type TitleProps = OptionalTitleProps | RequiredTitleProps;
type CalloutTypeProps = {
    type: CalloutType.NewFeature;
    isExperimentalFeature?: boolean;
} | {
    type: CalloutType;
    isExperimentalFeature?: never;
};
type CalloutProps = TitleProps & CalloutTypeProps & {
    canClose?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
};
export const Callout = (props: CalloutProps) => {
    let iconName = null;
    const containerStyles = stylesByType[props.type].container;
    const iconContainerStyles = stylesByType[props.type].iconContainer;
    const titleStyles = stylesByType[props.type].title;
    switch (props.type) {
        case CalloutType.Information: // blue = information
            iconName = "info-circle";
            break;
        case CalloutType.Success: // green = success
            iconName = "check-circle";
            break;
        case CalloutType.Warning: // yellow = warning
            iconName = "exclamation-circle";
            break;
        case CalloutType.Danger: // red = danger
            iconName = "exclamation-triangle";
            break;
        case CalloutType.NewFeature: // cyan = primary
            iconName = props.isExperimentalFeature ? "flask" : "star";
            break;
        case CalloutType.Generic: // cyan = primary
            break;
    }
    const iconClass = iconName ? `fa-solid fa-${iconName}` : "";
    return (<div className={containerStyles} role="alert">
            <div className={iconContainerStyles}>
                <em className={iconClass} aria-hidden="true"/>
            </div>
            <div className={contentContainerStyles}>
                {!props.hideTitle && <div className={titleStyles}>{props.title}</div>}
                {props.children && <div className={bodyStyles}>{props.children}</div>}
            </div>
            {props.canClose && (<div>
                    <CloseButton size="1.5rem" onClose={props.onClose}/>
                </div>)}
        </div>);
};
const baseContainerStyles = css({
    display: "flex",
    padding: `${space[12]} ${space[16]}`,
    gap: space[8],
});
// This creates a background with a solid border on the left edge
function buildBackgroundGradient(borderColor: string, backgroundColor: string) {
    return `linear-gradient(90deg, ${borderColor} 0%, ${borderColor} ${space[4]}, ${backgroundColor} ${space[4]}, ${backgroundColor} 100%)`;
}
const baseIconContainerStyles = css({
    gridArea: "icon",
    height: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
const baseTitleStyles = css({
    font: text.interface.body.bold.base,
});
const stylesByType: Record<CalloutType, Record<"container" | "iconContainer" | "title", string>> = {
    [CalloutType.Information]: {
        container: css(baseContainerStyles, {
            background: buildBackgroundGradient(themeTokens.color.callout.border.info, themeTokens.color.callout.background.info.default),
        }),
        iconContainer: css(baseIconContainerStyles, {
            color: themeTokens.color.callout.icon.info,
        }),
        title: css(baseTitleStyles, {
            color: themeTokens.color.callout.text.info,
        }),
    },
    [CalloutType.Success]: {
        container: css(baseContainerStyles, {
            background: buildBackgroundGradient(themeTokens.color.callout.border.success, themeTokens.color.callout.background.success.default),
        }),
        iconContainer: css(baseIconContainerStyles, {
            color: themeTokens.color.callout.icon.success,
        }),
        title: css(baseTitleStyles, {
            color: themeTokens.color.callout.text.success,
        }),
    },
    [CalloutType.Warning]: {
        container: css(baseContainerStyles, {
            background: buildBackgroundGradient(themeTokens.color.callout.border.warning, themeTokens.color.callout.background.attention.default),
        }),
        iconContainer: css(baseIconContainerStyles, {
            color: themeTokens.color.callout.icon.warning,
        }),
        title: css(baseTitleStyles, {
            color: themeTokens.color.callout.text.warning,
        }),
    },
    [CalloutType.Danger]: {
        container: css(baseContainerStyles, {
            background: buildBackgroundGradient(themeTokens.color.callout.border.danger, themeTokens.color.callout.background.danger.default),
        }),
        iconContainer: css(baseIconContainerStyles, {
            color: themeTokens.color.callout.icon.danger,
        }),
        title: css(baseTitleStyles, {
            color: themeTokens.color.callout.text.danger,
        }),
    },
    [CalloutType.NewFeature]: {
        container: css(baseContainerStyles, {
            background: buildBackgroundGradient(themeTokens.color.callout.border.feature, themeTokens.color.callout.background.feature.default),
        }),
        iconContainer: css(baseIconContainerStyles, {
            color: themeTokens.color.callout.icon.feature,
        }),
        title: css(baseTitleStyles, {
            color: themeTokens.color.callout.text.feature,
        }),
    },
    [CalloutType.Generic]: {
        container: css(baseContainerStyles, {
            background: buildBackgroundGradient(themeTokens.color.callout.border.info, themeTokens.color.callout.background.info.default),
        }),
        iconContainer: css(baseIconContainerStyles, {
            color: themeTokens.color.callout.icon.info,
        }),
        title: css(baseTitleStyles, {
            color: themeTokens.color.callout.text.info,
        }),
    },
};
const contentContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[4],
    flex: 1,
});
const bodyStyles = css({
    font: text.interface.body.default.base,
    color: themeTokens.color.callout.text.body,
});
Callout.displayName = "Callout";
export default Callout;
