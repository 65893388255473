import { css, cx } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { useIsLargerThanIpadResolution } from "@octopusdeploy/design-system-components/src/hooks/useIsLargerThanIpadResolution";
import { fontSize, space, themeTokens, borderRadius, lineHeight, fontWeight } from "@octopusdeploy/design-system-tokens";
import { ActionHandlerCategory, type ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Chip from "~/components/Chips/Chip";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
interface StepTileProps {
    step: ActionTemplateSearchResource;
    isRecommended?: boolean;
    primaryActionButton: React.ReactNode;
    primaryRedirect?: LinkHref;
    secondaryLabel?: string;
    secondaryRedirect?: LinkHref;
    onClick?: () => void;
    hideChip?: boolean;
}
export const StepTile = (props: StepTileProps) => {
    const navigation = useSpaceAwareNavigation();
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const maxFeaturesToShow = 3;
    function getChip(step: ActionTemplateSearchResource) {
        if (props.hideChip) {
            return null;
        }
        if (props.isRecommended) {
            return (<Chip backgroundColor={themeTokens.color.background.success} labelColor={themeTokens.color.text.success} noMargin={true} noTooltip={true}>
                    Recommended
                </Chip>);
        }
        if (step.CommunityActionTemplateId) {
            return (<Chip backgroundColor={themeTokens.color.background.secondary.default} labelColor={themeTokens.color.text.muted} noMargin={true} noTooltip={true}>
                    Community Contributed
                </Chip>);
        }
        if (!step.CommunityActionTemplateId && step.Categories.includes(ActionHandlerCategory.StepTemplate)) {
            return (<Chip backgroundColor={themeTokens.color.background.info} labelColor={themeTokens.color.callout.text.info} noMargin={true} noTooltip={true}>
                    Custom Template
                </Chip>);
        }
        return null;
    }
    function getBylineText(step: ActionTemplateSearchResource) {
        if (step.IsBuiltIn) {
            return "By Octopus Deploy";
        }
        if (step.IsInstalled) {
            return "Installed";
        }
        return "By " + step.Author;
    }
    return (<li>
            <div className={styles.base}>
                <div>
                    <div className={styles.header}>
                        <Logo url={props.step.Links.Logo} size={space[40]} isSquare={true}/>
                        {getChip(props.step)}
                    </div>
                    <div className={styles.name}>{props.step.Name}</div>
                    <div className={styles.byline}>{getBylineText(props.step)}</div>
                    <div className={styles.description}>
                        <Markdown markup={props.step.Description} noMargins mutedTextColor/>
                    </div>
                    <div>
                        {props.step.Features &&
            props.step.Features.length > 0 &&
            props.step.Features.slice(0, maxFeaturesToShow).map((feature) => (<div key={feature} className={styles.featureContainer}>
                                    <div className={styles.featureIcon}>
                                        <i className="fa-regular fa-circle-check"/>
                                    </div>
                                    <div className={styles.featureText}>{feature}</div>
                                </div>))}
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <div onClick={() => {
            if (props.onClick)
                props.onClick();
            if (props.primaryRedirect)
                navigation.navigate(props.primaryRedirect);
        }}>
                        {props.primaryActionButton}
                    </div>
                    {props.secondaryRedirect && props.secondaryLabel && (<div className={cx(styles.secondaryActionButton, { [mobileStyles.secondaryActionButton]: !isLargerThanIpad })}>
                            <Button label={props.secondaryLabel} rightIcon={<i className="fa-solid fa-external-link"/>} onClick={() => {
                if (props.secondaryRedirect)
                    navigation.open(props.secondaryRedirect);
            }} importance={"quiet"} size={"small"}/>
                        </div>)}
                </div>
            </div>
        </li>);
};
StepTile.displayName = "StepTile";
const secondaryActionButtonLabel = "step-tile-secondary-action-button";
const styles = {
    base: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
        padding: space[16],
        backgroundColor: themeTokens.color.card.background.default,
        borderRadius: borderRadius.medium,
        boxShadow: themeTokens.shadow.small,
        WebkitFontSmoothing: "antialiased",
    }),
    header: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    }),
    name: css({
        display: "-webkit-box",
        lineHeight: lineHeight.small,
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        fontSize: fontSize.base,
        fontWeight: fontWeight["700"],
        color: themeTokens.color.text.primary,
        marginTop: space[8],
    }),
    byline: css({
        fontSize: fontSize.xSmall,
        color: themeTokens.color.text.muted,
    }),
    description: css({
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        fontSize: fontSize.small,
        color: themeTokens.color.text.muted,
        marginTop: space[8],
        marginBottom: space[8],
    }),
    featureContainer: css({
        display: "flex",
        alignItems: "center",
        marginBottom: space[8],
    }),
    featureIcon: css({
        marginRight: space[4],
        color: themeTokens.color.icon.muted,
    }),
    featureText: css({
        fontSize: fontSize.small,
        color: themeTokens.color.text.muted,
    }),
    buttonsContainer: css({
        display: "flex",
        alignItems: "center",
        marginTop: space[12],
    }),
    secondaryActionButton: css({
        label: secondaryActionButtonLabel,
        marginLeft: space[8],
        i: {
            color: themeTokens.color.button.icon.quiet,
            fontSize: fontSize.large,
        },
    }),
};
const mobileStyles = {
    secondaryActionButton: css({
        display: "block",
    }),
};
