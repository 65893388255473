import { CircularProgress } from "@octopusdeploy/design-system-components";
import { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import styles from "./TentacleDiscoveryProgress.module.less";
interface TentacleDiscoveryProgressProps {
    name: string;
    isFound: boolean;
    healthStatus: MachineModelHealthStatus;
}
export const TentacleDiscoveryProgress: React.StatelessComponent<TentacleDiscoveryProgressProps> = (props) => {
    let style = styles.none;
    let content: JSX.Element = <></>;
    if (props.healthStatus !== MachineModelHealthStatus.Unknown) {
        if (props.healthStatus === MachineModelHealthStatus.Unhealthy || props.healthStatus === MachineModelHealthStatus.Unavailable) {
            style = styles.failure;
            const iconClass = `${styles.icon} fa-solid fa-triangle-exclamation`;
            content = (<>
                    <em className={iconClass} aria-hidden="true"/>
                    <span>
                        Health check failed for <b>{props.name}</b>. Need help? <ExternalLink href={"kubernetes-agent"}>Read the docs</ExternalLink>
                    </span>
                </>);
        }
        else {
            style = styles.success;
            const iconClass = `${styles.icon} fa-solid fa-check-circle`;
            content = (<>
                    <em className={iconClass} aria-hidden="true"/>
                    <span>
                        Successful registration and health check for <b>{props.name}</b>
                    </span>
                </>);
        }
    }
    else {
        style = styles.waiting;
        content = (<>
                <div className={styles.waitingIcon}>
                    <CircularProgress size="container" aria-hidden="true"/>
                </div>
                {props.isFound ? (<span>
                        Performing health check on <b>{props.name}...</b>
                    </span>) : (<span>
                        Searching for deployment target <b>{props.name}...</b>
                    </span>)}
            </>);
    }
    return (<div className={style} role="alert">
            <div className={styles.title}>
                <div className={styles.contentContainer}>{content}</div>
            </div>
            {props.children && <div>{props.children}</div>}
        </div>);
};
TentacleDiscoveryProgress.displayName = "TentacleDiscoveryProgress"
TentacleDiscoveryProgress.displayName = "TentacleDiscoveryProgress";
export default TentacleDiscoveryProgress;
