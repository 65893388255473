import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import ActionTemplateParameterEditorDialog from "~/components/ActionTemplateParametersList/ActionTemplateParameterEditorDialog";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
interface AddProjectTemplateButtonProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
}
export function AddProjectTemplateButton({ project, doBusyTask }: AddProjectTemplateButtonProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const handleAddProjectTemplate = async (newProjectTemplate: ActionTemplateParameterResource) => {
        await doBusyTask(async () => {
            await repository.Projects.modify({ ...project, Templates: [...project.Templates, newProjectTemplate] });
            dispatchAction("Add Project Variable Template", { resource: "Variable", action: Action.Add });
        });
    };
    return (<OpenDialogButton label={"Add project template"} type={ActionButtonType.Primary}>
            <ActionTemplateParameterEditorDialog onOk={handleAddProjectTemplate} excludedControlTypes={[ControlType.StepName, ControlType.Package]} name={"template"} editPermission={{
            permission: Permission.VariableEdit,
            project: project?.Id,
            wildcard: true,
        }}/>
        </OpenDialogButton>);
}
