import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import { useFieldErrors } from "~/components/FieldErrorContext/FieldErrorContext";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note, Text, and, required, startsWith, url } from "~/components/form";
type ConfigureOtherIssuerTypeOidcIdentityProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    setOidcIdentity: (oidcIdentity: ServiceAccountOidcIdentityResource) => void;
};
export function ConfigureOtherIssuerOidcIdentity({ oidcIdentity, setOidcIdentity }: ConfigureOtherIssuerTypeOidcIdentityProps) {
    const { getFieldError } = useFieldErrors();
    return (<div>
            <Text key="Name" label="Name" value={oidcIdentity.Name} onChange={(name) => setOidcIdentity({
            ...oidcIdentity,
            Name: name,
        })} validate={required("Name is required")} error={getFieldError("Name")} autoFocus helperText="A unique name for the OIDC identity within this service account."/>
            <Text key="Issuer" label="Issuer" value={oidcIdentity.Issuer} onChange={(issuer) => setOidcIdentity({
            ...oidcIdentity,
            Issuer: issuer,
        })} validate={and([required("Issuer is required"), url("Issuer must be a valid url"), startsWith("Issuer must be a valid url using HTTPS", "https")])} error={getFieldError("Issuer")} helperText="The URL where OIDC tokens will be issued from. This must match exactly the issuer provided in the OIDC token."/>
            <Text key="Subject" label="Subject" value={oidcIdentity.Subject} onChange={(subject) => setOidcIdentity({
            ...oidcIdentity,
            Subject: subject,
        })} validate={required("Subject is required")} error={getFieldError("Subject")} helperText="The subject of the OIDC identity. This must match the subject provided in the OIDC token and can use '*' or '?' wildcard characters."/>
            <Note>
                <ExternalLink href="ServiceAccountOidcIdentitiesOtherIssuers">Learn more about configuring OIDC identities for other issuers.</ExternalLink>
            </Note>
        </div>);
}
