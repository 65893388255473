import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { allLegacyRoutes, links } from "@octopusdeploy/portal-routes";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import { RunCreateGoalValues } from "~/areas/projects/components/Runbooks/RunbookSnapshots/runbookSnapshotRouteLinks";
import { repository } from "~/clientInstance";
import { createLegacyRouteRegistrations } from "./createLegacyRouteRegistrations";
export const legacyRouteRegistrations = createLegacyRouteRegistrations<typeof allLegacyRoutes>(allLegacyRoutes, {
    newProject: () => getDefaultSpaceLink((defaultSpace) => links.projectsPage.generateUrl({ spaceId: defaultSpace.Id })),
    v3Certificates: () => links.thumbprintPage.generateUrl(),
    v3UserInvites: () => links.userInvitesPage.generateUrl(),
    v3TestPermissions: () => links.testPermissionsPage.generateUrl(),
    v3CreateCertificate: ({ spaceId }) => links.createCertificatePage.generateUrl({ spaceId }),
    v3CreateFeed: ({ spaceId }) => links.createFeedPage.generateUrl({ spaceId }),
    v3CreateLifecycle: ({ spaceId }) => links.createLifecyclePage.generateUrl({ spaceId }),
    v3CreateScript: ({ spaceId }) => links.createScriptModulePage.generateUrl({ spaceId }),
    v3CreateTagSet: ({ spaceId }) => links.createTagSetPage.generateUrl({ spaceId }),
    v3CreateVariableSet: ({ spaceId }) => links.variableSetsPage.generateUrl({ spaceId }),
    V3Environments: ({ spaceId }) => links.infrastructureEnvironmentsPage.generateUrl({ spaceId }),
    V3Accounts: ({ spaceId }) => links.infrastructureAccountsPage.generateUrl({ spaceId }),
    V3Machines: ({ spaceId }) => links.deploymentTargetsPage.generateUrl({ spaceId }),
    tenantRoot: ({ spaceId, tenantId }) => links.tenantOverviewPage.generateUrl({ spaceId, tenantId }),
    deploymentTargetRoot: ({ spaceId, machineId }) => links.deploymentTargetSettingsPage.generateUrl({ spaceId, machineId }),
    workerMachineRoot: ({ spaceId, machineId }) => links.workerMachineSettingsPage.generateUrl({ spaceId, machineId }),
    newTenant: () => getDefaultSpaceLink((defaultSpace) => links.tenantsPage.generateUrl({ spaceId: defaultSpace.Id })),
    communityTemplateDetails: ({ spaceId, templateId }) => links.communityStepTemplatePage.generateUrl({ spaceId, templateId }),
    runbookSnapshotRunCreateWithGoal: ({ spaceId, projectSlug, runbookId, runbookSnapshotId }) => {
        //We always need a previous runbook run id for retry, so the only goal we can have is `To` which is to create a new runbook snapshot
        return links.createRunbookRunForSnapshotPage.generateUrl({ spaceId, projectSlug, runbookId, runbookSnapshotId });
    },
    runbookSnapshotRunWithGoalAndPreviousId: ({ spaceId, projectSlug, runbookId, runbookSnapshotId, previousId, goal }) => {
        if (goal === RunCreateGoalValues.To) {
            return links.createRunbookRunForSnapshotPage.generateUrl({ spaceId, projectSlug, runbookId, runbookSnapshotId }, { environmentIds: previousId.split(",") });
        }
        if (goal === RunCreateGoalValues.TryAgain) {
            return links.createRunbookRunForSnapshotPage.generateUrl({ spaceId, projectSlug, runbookId, runbookSnapshotId }, { previousRunbookRunId: previousId });
        }
        //Unknown goal and parameters, just redirect to the create page
        return links.createRunbookRunForSnapshotPage.generateUrl({ spaceId, projectSlug, runbookId, runbookSnapshotId });
    },
    newProjectProcessStep: ({ spaceId, projectSlug }) => links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }),
    newProjectProcessStepInSpecificStep: ({ spaceId, projectSlug }) => links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }),
    specificProjectProcessStep: ({ spaceId, projectSlug }) => links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }),
    projectProcessStepTemplates: ({ spaceId, projectSlug }) => links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }),
    projectProcessChildStepTemplates: ({ spaceId, projectSlug }) => links.deploymentProcessPage.generateUrl({ spaceId, projectSlug }),
    manageConsole: () => links.scriptConsolePage.generateUrl(),
    projectCreateDeploymentWithGoal: ({ spaceId, projectSlug, goal, releaseVersion }) => {
        //We always need a previous deployment id for retry, so the only goal we can have is `To` which is to create a new deployment
        return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion });
    },
    projectCreateDeploymentWithGoalAndPreviousId: ({ spaceId, projectSlug, releaseVersion, previousId, goal }) => {
        if (goal === DeploymentCreateGoal.To) {
            return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion }, { environmentIds: previousId.split(",") });
        }
        if (goal === DeploymentCreateGoal.TryAgain) {
            return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion }, { previousDeploymentId: previousId });
        }
        //Unknown goal and parameters, just redirect to the create page
        return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion });
    },
    projectCreateDeploymentWithGoalAndPreviousIdAndTenants: ({ spaceId, projectSlug, releaseVersion, previousId, goal, tenantIds }) => {
        if (goal === DeploymentCreateGoal.To) {
            return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion }, { environmentIds: previousId.split(","), tenantIds: tenantIds.split(",") });
        }
        //Retrying pulls the tenant ids and environments from the previous deployment, so tenantIds and environmentIds don't make sense and will be ignored.
        if (goal === DeploymentCreateGoal.TryAgain) {
            return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion }, { previousDeploymentId: previousId });
        }
        //Unknown goal and parameters, just redirect to the create page
        return links.createDeploymentPage.generateUrl({ spaceId, projectSlug, releaseVersion }, { environmentIds: previousId.split(","), tenantIds: tenantIds.split(",") });
    },
});
async function getDefaultSpaceLink(generateLink: (defaultSpace: SpaceResource) => LinkHref) {
    const allSpaces = await repository.Spaces.all();
    const space = allSpaces.find((s) => s.IsDefault) ?? allSpaces[0];
    if (space) {
        return generateLink(space);
    }
}
