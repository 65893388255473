/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButtonType, RadioButton, RadioButtonGroup, Tooltip } from "@octopusdeploy/design-system-components";
import type { ResourcesById, FeedResource, FeedType, RunbookProcessResource, RunbookSnapshotResource, RunbookSnapshotTemplateResource, RunbookResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, toGitBranch } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import type { Dictionary } from "lodash";
import * as _ from "lodash";
import { cloneDeep, flatten, groupBy, isEqual, keys } from "lodash";
import * as React from "react";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { GitResourcesSelectorComponent } from "~/areas/projects/components/Releases/Edit/GitResourcesSelectorComponent";
import type { GitReferenceEditInfo } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitReferenceType } from "~/areas/projects/components/Releases/gitResourceModel";
import type { PackageEditInfo } from "~/areas/projects/components/Releases/packageModel";
import { VersionType } from "~/areas/projects/components/Releases/packageModel";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { useSetExpanderState } from "~/components/ControlExpanders/useSetExpanderState";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { default as FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import { Form } from "~/components/FormPaperLayout/Form";
import LoadMoreWrapper from "~/components/LoadMoreWrapper/LoadMoreWrapper";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { required } from "~/components/form/Validators";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import Text from "~/primitiveComponents/form/Text/Text";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import StringHelper from "~/utils/StringHelper/StringHelper";
import PackageListDialogContent from "../../Releases/PackageListDialog/PackageListDialogContent";
import { publishingExplainedElement } from "../PublishButton";
import { useRunbookContext } from "../RunbookContext";
import styles from "./RunbookSnapshotEditPage.module.less";
const versionExpanderKey = "version";
interface RunbookSnapshotModel {
    packages: PackageEditInfo[];
    gitResources: GitReferenceEditInfo[];
    runbookSnapshot: RunbookSnapshotResource;
}
interface RunbookSnapshotEditPageState extends OptionalFormBaseComponentState<RunbookSnapshotModel> {
    originalName: string;
    runbookProcess: RunbookProcessResource;
    template: RunbookSnapshotTemplateResource;
    seeVersionExample: boolean;
    isNew: boolean;
    redirect: boolean;
    deleted: boolean;
    defaultCheckModel: RunbookSnapshotModel;
    feeds: ResourcesById<FeedResource>;
    hasInitialModelUpdateCompleted: boolean; // To stop the user being able to interact with the runbookSnapshot version input before we've finished loading version rules.
}
const DebounceText = DebounceValue(Text);
interface RunbookSnapshotEditInternalPageProps extends RunbookSnapshotEditPageProps {
    runbook: RunbookResource;
    project: ProjectResource;
    setExpanderState(key: string, open: boolean): void;
    refreshRunbook: () => Promise<boolean>;
}
class RunbookSnapshotEditPageInternal extends FormBaseComponent<RunbookSnapshotEditInternalPageProps, RunbookSnapshotEditPageState, RunbookSnapshotModel> {
    textField: any = null;
    memoizedRepositoryChannelsRuleTest = _.memoize((version: string, versionRange: string, preReleaseTag: string, feedType: FeedType) => repository.Channels.ruleTest({
        version,
        versionRange,
        preReleaseTag,
        feedType,
    }));
    constructor(props: RunbookSnapshotEditInternalPageProps) {
        super(props);
        this.state = {
            originalName: null!,
            runbookProcess: null!,
            template: null!,
            seeVersionExample: false,
            isNew: true,
            redirect: false,
            deleted: false,
            defaultCheckModel: null!,
            feeds: null!,
            hasInitialModelUpdateCompleted: false,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookSnapshotEditInternalPageProps) {
        const nextRunbook = this.props.runbook;
        const currentRunbook = prevProps.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    async reload() {
        const project = this.props.project;
        const runbook = this.props.runbook;
        await this.doBusyTask(async () => {
            let runbookSnapshot = null;
            let originalName = null;
            let runbookProcessPromise = null;
            let isNew = true;
            let cleanModel: any = null;
            if (this.props.runbookSnapshotId) {
                runbookSnapshot = await repository.RunbookSnapshots.get(this.props.runbookSnapshotId);
                originalName = runbookSnapshot.Name;
                isNew = false;
                runbookProcessPromise = this.loadRunbookProcess(runbookSnapshot.FrozenRunbookProcessId);
            }
            else {
                runbookSnapshot = {
                    ProjectId: project.Id,
                    RunbookId: runbook.Id,
                };
                runbookProcessPromise = this.loadRunbookProcess(runbook.RunbookProcessId);
                cleanModel = {
                    version: null,
                    packages: [],
                    gitResources: [],
                    runbookSnapshotNotes: null,
                    runbookSnapshot: null,
                    options: null,
                };
            }
            const model = this.buildModel(runbookSnapshot as RunbookSnapshotResource, [], []);
            if (isNew) {
                model.runbookSnapshot.Notes = null!;
            }
            const [feeds, runbookProcess] = await Promise.all([repository.Feeds.allById(), runbookProcessPromise]);
            await this.loadTemplate(model, runbookProcess);
            this.setState({
                originalName: originalName!,
                runbookProcess,
                model,
                cleanModel: cleanModel ? cleanModel : cloneDeep(model),
                defaultCheckModel: cloneDeep(model),
                isNew,
                feeds,
                hasInitialModelUpdateCompleted: true,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        if (this.state.redirect) {
            return (<InternalRedirect to={links.projectRunbookSnapshotInfoPage.generateUrl({ spaceId: this.props.runbook.SpaceId, projectSlug: this.props.project.Slug, runbookId: this.props.runbook.Id, runbookSnapshotId: this.state.model!.runbookSnapshot.Id })} push={true}/>);
        }
        if (this.state.deleted) {
            return <InternalRedirect to={links.projectRunbookSnapshotsPage.generateUrl({ spaceId: this.props.runbook.SpaceId, projectSlug: this.props.project.Slug, runbookId: this.props.runbook.Id })} push={true}/>;
        }
        const { project, runbook } = this.props;
        const overflowActions = !this.state.isNew && !!this.state.model && !!this.state.model.runbookSnapshot
            ? [
                OverflowMenuItems.deleteItemDefault("snapshot", this.handleDeleteConfirm, {
                    permission: Permission.RunbookEdit,
                    project: project && project.Id,
                    wildcard: true,
                }, "The snapshot and any of its steps will be permanently deleted and they will disappear from all dashboards."),
                [
                    OverflowMenuItems.navItem("Audit Trail", links.auditPage.generateUrl({ regardingAny: [this.state.model.runbookSnapshot.Id] }), {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ],
            ]
            : [];
        let title = "Snapshot";
        if (project) {
            title = this.state.isNew ? "Publish new snapshot for " + project.Name : this.state.model && this.state.model.runbookSnapshot ? "Edit " + this.state.model.runbookSnapshot.Name : "Edit snapshot";
        }
        if (this.state.runbookProcess && this.state.runbookProcess.Steps.length === 0) {
            return (<ProjectPageLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbTitle={`${runbook && runbook.Name} snapshots`} breadcrumbPath={project ? links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) : null!} breadcrumbsItems={[
                    { label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) },
                    { label: runbook.Name, pageUrl: links.projectRunbookOverviewPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
                    { label: "Snapshots", pageUrl: links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
                ]}>
                    <Callout title="Note" type={CalloutType.Information}>
                        <div>The runbook does not have any steps.</div>
                    </Callout>
                </ProjectPageLayout>);
        }
        const hasLoaded = !!this.state.model;
        let breadcrumbTitle = hasLoaded ? "Snapshots" : StringHelper.ellipsis;
        let breadcrumbPath: LinkHref = links.projectRunbookSnapshotsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id });
        if (hasLoaded && this.state.isNew) {
            breadcrumbTitle = runbook.Name;
            breadcrumbPath = links.projectRunbookOverviewPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id });
        }
        return (<Form model={this.state.model} cleanModel={this.state.cleanModel} savePermission={{
                permission: Permission.RunbookEdit,
                project: project && project.Id,
                wildcard: true,
            }} disableDirtyFormChecking={this.state.isNew && this.disableDirtyFormCheck()} onSaveClick={this.handleSaveClick} saveText="Snapshot saved">
                {({ FormContent, createSaveAction }) => (<ProjectPageLayout busy={this.state.busy} errors={this.errors} title={title} breadcrumbTitle={breadcrumbTitle} breadcrumbPath={breadcrumbPath} breadcrumbsItems={[{ label: breadcrumbTitle, pageUrl: breadcrumbPath }]} overflowActions={overflowActions} primaryAction={createSaveAction({
                    saveButtonLabel: this.state.isNew ? "Publish" : undefined,
                    saveButtonBusyLabel: this.state.isNew ? "Publishing..." : undefined,
                })}>
                        {this.state.model && this.state.hasInitialModelUpdateCompleted && (<TransitionAnimation>
                                <FormContent>
                                    {this.state.isNew && (<Callout title={"Why publish?"} type={CalloutType.Information}>
                                            {publishingExplainedElement} A snapshot will be taken of the process, variables and packages, allowing changes to be made safely. <ExternalLink href="RunbookPublishing">Learn more</ExternalLink>
                                        </Callout>)}
                                    <ExpandableFormSection errorKey={versionExpanderKey} title="Name" summary={this.state.model.runbookSnapshot.Name ? Summary.summary(this.state.model.runbookSnapshot.Name) : Summary.placeholder("Please enter a name")} help="Enter a unique version number for this snapshot with at least two parts.">
                                        <Text value={this.state.model.runbookSnapshot.Name} onChange={(name) => this.setChildState2("model", "runbookSnapshot", { Name: name })} label="Name" validate={required("Please enter a name")}/>
                                    </ExpandableFormSection>
                                    {this.state.model.packages && this.state.model.packages.length > 0 && (<ExpandableFormSection errorKey="packages" title="Packages" fillCardWidth={CardFill.FillAll} summary={this.packagesSummary()} help="Select package(s) for this snapshot">
                                            <div className={styles.packageTableContainer}>
                                                <DataTable className={styles.packageTable}>
                                                    <DataTableHeader>
                                                        <DataTableRow>
                                                            <DataTableHeaderColumn>
                                                                <div className={styles.actionName}>Step</div>
                                                                Package
                                                            </DataTableHeaderColumn>
                                                            <DataTableHeaderColumn>
                                                                <Tooltip key="latest" content="The most recent package that we could find in the package feed that matches channel rules">
                                                                    <ExternalLink href="LatestPackage">Latest</ExternalLink>
                                                                    {this.state.model.packages && this.state.model.packages.length > 1 && (<React.Fragment>
                                                                            <br />
                                                                            <Note>
                                                                                <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.latest, null!, false)}>
                                                                                    Select all
                                                                                </a>
                                                                            </Note>
                                                                        </React.Fragment>)}
                                                                </Tooltip>
                                                            </DataTableHeaderColumn>
                                                            <DataTableHeaderColumn>
                                                                Specific
                                                                {this.state.model.packages && this.state.model.packages.length > 1 && this.state.model.runbookSnapshot && this.state.model.runbookSnapshot.Name && (<React.Fragment>
                                                                        <br />
                                                                        <Note>
                                                                            <a href="#" onClick={(e) => this.setAllPackageVersionsTo(e, VersionType.specific, this.state.model!.runbookSnapshot.Name, true)}>
                                                                                Select current snapshot version
                                                                            </a>
                                                                        </Note>
                                                                    </React.Fragment>)}
                                                            </DataTableHeaderColumn>
                                                        </DataTableRow>
                                                    </DataTableHeader>
                                                    <DataTableBody>
                                                        {this.state.model && this.state.model.packages && (<LoadMoreWrapper items={this.state.model.packages} renderLoadMore={(children) => {
                                return (<DataTableRow>
                                                                            <DataTableRowColumn colSpan={4}>{children}</DataTableRowColumn>
                                                                        </DataTableRow>);
                            }} renderItem={(pack, index) => (<DataTableRow key={pack.ActionName}>
                                                                        <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.packageColumn)}>
                                                                            <div className={styles.actionName}>
                                                                                {pack.ActionName}
                                                                                {!!pack.PackageReferenceName && <span>/{pack.PackageReferenceName}</span>}
                                                                            </div>
                                                                            <Tooltip key="packageId" content={pack.ProjectName ? pack.ProjectName : pack.PackageId + " from " + pack.FeedName}>
                                                                                {pack.ProjectName ? pack.ProjectName : pack.PackageId}
                                                                            </Tooltip>
                                                                        </DataTableRowColumn>
                                                                        <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.latestColumn)}>
                                                                            {this.buildRadioButton(pack, pack.LatestVersion, VersionType.latest, this.state.model!)}
                                                                        </DataTableRowColumn>
                                                                        <DataTableRowColumn className={cn(styles.packageTableRowColumn, styles.specificColumn)}>
                                                                            <div className={styles.specificVersionDiv}>
                                                                                <div className={styles.inlineDiv}>{this.buildRadioButton(pack, pack.SpecificVersion, VersionType.specific, this.state.model!)}</div>
                                                                                <div className={styles.inlineDiv}>
                                                                                    <div className={styles.editVersionArea}>
                                                                                        <DebounceText id={pack.ActionName} debounceDelay={500} className={styles.versionTextbox} placeholder="Enter a version" value={pack.SpecificVersion} onChange={(version) => {
                                    this.specificVersionSelected(this.state.model!, pack, version);
                                }}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={styles.inlineDiv}>{this.packageVersionsButton(pack)}</div>
                                                                            </div>
                                                                        </DataTableRowColumn>
                                                                    </DataTableRow>)}/>)}
                                                    </DataTableBody>
                                                </DataTable>
                                            </div>
                                        </ExpandableFormSection>)}

                                    {this.state.model.gitResources && this.state.model.gitResources.length > 0 && (<GitResourcesSelectorComponent forRelease={false} gitResources={this.state.model.gitResources} onUpdateGitRef={(gitRef: GitReferenceEditInfo) => {
                            this.setState((prevState) => {
                                const updatedGitRefs = prevState.model?.gitResources.map((gr) => (gr.ActionName === gitRef.ActionName ? gitRef : gr));
                                return {
                                    model: {
                                        ...prevState.model,
                                        gitResources: updatedGitRefs,
                                    },
                                };
                            });
                        }}/>)}
                                    <ExpandableFormSection errorKey="notes" title="Notes" summary={this.state.model.runbookSnapshot.Notes ? Summary.summary("Notes have been provided") : Summary.placeholder("No notes provided")} help={this.buildRunbookSnapshotNoteHelpInfo()}>
                                        <MarkdownEditor value={this.state.model.runbookSnapshot.Notes} label="Notes" onChange={(runbookSnapshotNotes) => this.setChildState2("model", "runbookSnapshot", { Notes: runbookSnapshotNotes })}/>
                                    </ExpandableFormSection>
                                </FormContent>
                            </TransitionAnimation>)}
                    </ProjectPageLayout>)}
            </Form>);
    }
    private setAllPackageVersionsTo = (e: React.MouseEvent, versionType: VersionType, specificVersion: string, includeConfirmation: boolean) => {
        e.preventDefault();
        if (includeConfirmation && !confirm(`This will set all packages to version ${specificVersion}. Are you sure this version exists for all the packages?`)) {
            return;
        }
        const model = this.state.model;
        const runbookSnapshot = model!.runbookSnapshot;
        runbookSnapshot.SelectedPackages = [];
        for (const selection of this.state.model!.packages) {
            selection.VersionType = versionType;
            selection.SpecificVersion = specificVersion;
            runbookSnapshot.SelectedPackages.push({
                ActionName: selection.ActionName,
                Version: specificVersion,
                PackageReferenceName: selection.PackageReferenceName,
            });
        }
        this.setState({ model });
    };
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const model = this.state.model;
            const runbookSnapshot = model!.runbookSnapshot;
            runbookSnapshot.SelectedPackages = [];
            for (const selection of this.state.model!.packages) {
                let selectedVersion = "";
                if (selection.VersionType === VersionType.latest) {
                    selectedVersion = selection.LatestVersion;
                }
                else if (selection.VersionType === VersionType.last) {
                    selectedVersion = selection.LastReleaseVersion;
                }
                else if (selection.VersionType === VersionType.specific) {
                    selectedVersion = selection.SpecificVersion;
                }
                runbookSnapshot.SelectedPackages.push({
                    ActionName: selection.ActionName,
                    Version: selectedVersion,
                    PackageReferenceName: selection.PackageReferenceName,
                });
            }
            runbookSnapshot.SelectedGitResources = this.state.model!.gitResources.map((r) => ({ ActionName: r.ActionName, GitReferenceResource: r.GitReferenceType === GitReferenceType.specific ? r.SpecificGitResource : r.LastGitResource! }));
            const newRunbookSnapshot = await save(runbookSnapshot);
            const newModel = this.buildModel(newRunbookSnapshot, this.state.model!.packages, this.state.model!.gitResources);
            await this.props.refreshRunbook();
            this.setState({
                model: newModel,
                cleanModel: cloneDeep(newModel),
                redirect: true,
            });
        });
        async function save(runbookSnapshot: RunbookSnapshotResource) {
            if (runbookSnapshot.Id) {
                return repository.RunbookSnapshots.modify(runbookSnapshot);
            }
            // New = publishing.
            return repository.RunbookSnapshots.create(runbookSnapshot, { publish: true });
        }
    };
    private packageVersionsButton = (pkg: PackageEditInfo) => {
        const feed = this.state.feeds ? this.state.feeds[pkg.FeedId] : undefined;
        const openDialog = (disabled: boolean) => {
            if (!feed) {
                throw Error(`The provided feed ${pkg.FeedId} could not be found`);
            }
            return (<OpenDialogButton type={ActionButtonType.Secondary} wideDialog={true} disabled={disabled} label="Select Version">
                    <PackageListDialogContent package={pkg} feed={feed} onVersionSelected={(version) => {
                    this.specificVersionSelected(this.state.model!, pkg, version);
                }} channelFilters={{}}/>
                </OpenDialogButton>);
        };
        if (this.state.feeds && this.state.feeds[pkg.FeedId]) {
            return openDialog(false);
        }
        return <Tooltip content="No feed available. Package step may be using a variable as feed.">{openDialog(true)}</Tooltip>;
    };
    private packagesSummary = () => {
        if (!this.state.model!.packages || this.state.model!.packages.length === 0) {
            return Summary.placeholder("No package is included");
        }
        const packageVersions = this.state.model!.packages.map((p) => this.getPackageInfoVersion(p));
        if (packageVersions.length === 1) {
            return Summary.summary(packageVersions[0] ? ("1 package included, at version " + packageVersions[0]) : (<span>
                        1 package included, <strong>no version specified</strong>
                    </span>));
        }
        const firstVersion = packageVersions.find((p) => !!p);
        const noneHaveVersion = !firstVersion;
        const allOnSameVersion = firstVersion && packageVersions.every((p) => p === firstVersion);
        const numberWithNoVersion = packageVersions.filter((p) => !p).length;
        const packagesIncluded = packageVersions.length + " packages included";
        const noVersionSummary = numberWithNoVersion ? (<span>
                ,{" "}
                <strong>
                    {numberWithNoVersion} {numberWithNoVersion === 1 ? "has" : "have"} no version selected
                </strong>
            </span>) : (<span />);
        const versionSummary = allOnSameVersion ? ", all at version " + firstVersion : noneHaveVersion ? "" : ", with a mix of versions";
        return Summary.summary(<span>
                {packagesIncluded}
                {versionSummary}
                {noVersionSummary}
            </span>);
    };
    private getPackageInfoVersion(info: PackageEditInfo): string {
        return info.VersionType === VersionType.specific ? info.SpecificVersion : info.LatestVersion;
    }
    private disableDirtyFormCheck = () => {
        // don't want "dirty" to be triggered by the version being auto populated or channel from route param
        return this.state.cleanModel && isEqual(this.state.defaultCheckModel, this.state.model);
    };
    private buildRadioButton(pack: PackageEditInfo, version: string, type: VersionType, model: RunbookSnapshotModel) {
        if (!pack.IsResolvable && type === VersionType.latest) {
            return <div />;
        }
        return (<RadioButtonGroup className={styles.radioButtonContainer} value={type} onChange={(item) => {
                this.packageVersionChanged(model, pack, version, type);
            }}>
                <RadioButton className={styles.myRadioButton} value={pack.VersionType} label={type === VersionType.specific ? "" : version}/>
            </RadioButtonGroup>);
    }
    private buildRunbookSnapshotNoteHelpInfo = () => {
        const helpInfo = "Enter a summary of what has changed, such as which features were added and which bugs were fixed. " + "These notes will be shown on the snapshot page. You can edit these notes later.";
        return helpInfo;
    };
    private specificVersionSelected = (model: RunbookSnapshotModel, pack: PackageEditInfo, version: string) => {
        pack.SpecificVersion = version;
        this.packageVersionChanged(model, pack, version, VersionType.specific);
    };
    private handleDeleteConfirm = async (): Promise<boolean> => {
        if (!this.state.isNew) {
            await repository.RunbookSnapshots.del(this.state.model!.runbookSnapshot);
            this.setState((state) => {
                return {
                    model: null,
                    cleanModel: null,
                    deleted: true,
                };
            });
            return true;
        }
        else {
            return false;
        }
    };
    private loadRunbookProcess = async (processId: string) => {
        const runbookProcess = await repository.RunbookProcess.get(processId);
        return runbookProcess;
    };
    private async loadTemplate(model: RunbookSnapshotModel, runbookProcess: RunbookProcessResource) {
        const template = await repository.RunbookProcess.getRunbookSnapshotTemplate(runbookProcess, null!);
        if (!model.runbookSnapshot.Id) {
            if (template.NextNameIncrement) {
                model.runbookSnapshot.Name = template.NextNameIncrement;
            }
        }
        const existingSelections: {
            [actionName: string]: string;
        } = {};
        if (model.runbookSnapshot.SelectedPackages) {
            for (const p of model.runbookSnapshot.SelectedPackages) {
                existingSelections[p.ActionName] = p.Version;
            }
        }
        const selectionByFeed: {
            [feedId: string]: PackageEditInfo[];
        } = {};
        const packageSelections = [];
        for (const p of template.Packages) {
            const specificVersion = existingSelections[p.ActionName] ? existingSelections[p.ActionName] : "";
            const isResolvable = p.IsResolvable;
            const lastReleaseVersion = p.VersionSelectedLastRelease;
            const selection: PackageEditInfo = {
                ActionName: p.ActionName,
                PackageReferenceName: p.PackageReferenceName,
                PackageId: p.PackageId,
                ProjectName: p.ProjectName,
                FeedId: p.FeedId,
                FeedName: p.FeedName,
                LatestVersion: "",
                SpecificVersion: specificVersion,
                IsResolvable: isResolvable,
                LastReleaseVersion: lastReleaseVersion,
                VersionType: specificVersion ? VersionType.specific : isResolvable ? VersionType.latest : lastReleaseVersion ? VersionType.last : VersionType.specific,
                IsLastReleaseVersionValid: !isBound(p.FeedId),
            };
            packageSelections.push(selection);
            if (selection.IsResolvable) {
                if (!selectionByFeed[selection.FeedId]) {
                    selectionByFeed[selection.FeedId] = [];
                }
                selectionByFeed[selection.FeedId].push(selection);
            }
        }
        const gitResourceSelections: GitReferenceEditInfo[] = template.GitResources
            ? template.GitResources.map((g) => ({
                ActionName: g.ActionName,
                GitCredentialId: g.GitCredentialId,
                RepositoryUri: g.RepositoryUri,
                DefaultBranch: g.DefaultBranch,
                IsResolvable: g.IsResolvable,
                FilePathFilters: g.FilePathFilters,
                LastGitResource: g.GitResourceSelectedLastRelease,
                SpecificGitResource: { GitRef: toGitBranch(g.DefaultBranch) },
                GitReferenceType: GitReferenceType.specific,
            }))
            : [];
        await this.setStateAsync({ ...this.state, template });
        await this.loadVersions(model, selectionByFeed); // This function depends on template being in state.
        model.packages = packageSelections;
        model.gitResources = gitResourceSelections;
        this.setState({ model });
        if (!model.runbookSnapshot.Name) {
            this.props.setExpanderState(versionExpanderKey, true);
        }
    }
    private setVersionSatisfaction = (pkg: PackageEditInfo, versionType: VersionType) => {
        if (versionType) {
            pkg.VersionType = versionType;
        }
    };
    private loadVersions(model: RunbookSnapshotModel, selectionsByFeed: Dictionary<PackageEditInfo[]>): Promise<boolean> {
        const memoizedRepositoryFeedsGet = _.memoize((id: string) => repository.Feeds.get(id));
        const checkForRuleSatisfaction = async (selection: PackageEditInfo, filters: {
            versionRange?: string;
            preReleaseTag?: string;
        }, feedType: FeedType) => {
            if (selection.LastReleaseVersion) {
                const result = await this.memoizedRepositoryChannelsRuleTest(selection.LastReleaseVersion, filters.versionRange!, filters.preReleaseTag!, feedType!);
                selection.IsLastReleaseVersionValid = result.SatisfiesVersionRange && result.SatisfiesPreReleaseTag;
            }
            else {
                selection.IsLastReleaseVersionValid = false;
            }
        };
        const getPackageVersion = async (feedId: string): Promise<any> => {
            const feed = await memoizedRepositoryFeedsGet(feedId);
            const selections = selectionsByFeed[feedId];
            const packageSearchGroups = groupBy(selections.map((selection) => ({ selection, filter: {} })), ({ selection, filter }) => selection.PackageId + JSON.stringify(filter || {}));
            const t = Object.values(packageSearchGroups).map(async (sameFilteredPackages) => {
                const runbookSnapshots = (await repository.Feeds.searchPackageVersions(feed, sameFilteredPackages[0].selection.PackageId, {
                    ...sameFilteredPackages[0].filter,
                    take: 1,
                })).Items;
                return sameFilteredPackages.map(async ({ selection, filter }) => {
                    await checkForRuleSatisfaction(selection, filter, feed.FeedType);
                    if (runbookSnapshots.length === 0) {
                        // no latest version found
                        selection.IsResolvable = false;
                        // Docker feeds may not conform to semver, in which case there will be no valid versions.
                        // However you can manually enter a version like "latest", and this will be shown as the
                        // last version. It is convenient to select that last version rather than default to
                        // the specific version field.
                        selection.VersionType = VersionType.specific;
                        return this.setVersionSatisfaction(selection, null!);
                    }
                    const pkg = runbookSnapshots[0];
                    selection.LatestVersion = pkg.Version;
                    if (!model.runbookSnapshot.Id) {
                        return this.packageVersionChanged(model, selection, pkg.Version, null!);
                    }
                    return this.setVersionSatisfaction(selection, null!);
                });
            });
            return Promise.all(flatten(await Promise.all(t)));
        };
        return this.doBusyTask(async () => {
            return Promise.all(keys(selectionsByFeed)
                .filter((f) => !isBound(f))
                .map((f) => getPackageVersion(f)));
        });
    }
    private packageVersionChanged = (m: RunbookSnapshotModel, pkg: PackageEditInfo, version: string, versionType: VersionType) => {
        const model = { ...m };
        if (versionType) {
            pkg.VersionType = versionType;
            if (versionType === VersionType.specific) {
                pkg.SpecificVersion = version;
            }
        }
        if (!isBound(pkg.FeedId) && this.state.feeds) {
            const feed = this.state.feeds[pkg.FeedId];
            if (feed) {
                this.setVersionSatisfaction(pkg, versionType);
            }
        }
        this.setState({ model });
    };
    private buildModel(runbookSnapshot: RunbookSnapshotResource, packageSelections: PackageEditInfo[], gitResourceSelections: GitReferenceEditInfo[]): RunbookSnapshotModel {
        const model: RunbookSnapshotModel = {
            packages: packageSelections,
            gitResources: gitResourceSelections,
            runbookSnapshot,
        };
        return model;
    }
    static displayName = "RunbookSnapshotEditPageInternal";
}
interface RunbookSnapshotEditPageProps {
    runbookSnapshotId?: string;
}
export function RunbookSnapshotEditPage(props: RunbookSnapshotEditPageProps) {
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const setExpanders = useSetExpanderState();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;
    if (!runbook || !project) {
        return <ProjectPageLayout busy={true}/>;
    }
    const refreshRunbook = runbookContext.actions.refreshRunbook;
    return <RunbookSnapshotEditPageInternal runbook={runbook} project={project} setExpanderState={setExpanders} runbookSnapshotId={props.runbookSnapshotId} refreshRunbook={refreshRunbook}/>;
}
