import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { useCallback } from "react";
interface AddAccountInteractionEvent {
    type: "Add Account";
    Location: "Drawer" | "Page";
    "Account Type": string;
    "Correlation Id"?: string;
}
interface SaveAccountInteractionEvent {
    type: "Save Account";
    Location: "Drawer" | "Page";
    "Account Type": string;
    "Entity Type": "New" | "Existing";
    Status: "Success" | "Failure";
    "Correlation Id"?: string;
}
interface SaveAndTestAccountInteractionEvent {
    type: "Save and Test Account";
    Location: "Drawer" | "Page";
    "Account Type": string;
    "Entity Type": "New" | "Existing";
    Status: "Success" | "Failure";
    "Correlation Id"?: string;
}
type AccountInteractionEvent = AddAccountInteractionEvent | SaveAccountInteractionEvent | SaveAndTestAccountInteractionEvent;
export function useDispatchAccountInteraction() {
    const session = useAnalyticSession();
    return useCallback((accountInteractionEvent: AccountInteractionEvent) => {
        session.track(accountInteractionEvent.type, { ...accountInteractionEvent, type: undefined });
    }, [session]);
}
