import { useSetPageEffect } from "@octopusdeploy/portal-routes";
import type { PageDetailProps } from "@octopusdeploy/portal-routes";
import React from "react";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
export const withPage = (pageProps: PageDetailProps) => {
    return <TComponentProps>(Component: React.ComponentType<TComponentProps>) => {
        const RenderWithPage: React.FC<TComponentProps> = (componentProps) => {
            useSetPageEffect({
                Area: pageProps.page.Area,
                Name: pageProps.page.Name,
                Id: pageProps.page.Id,
            });
            return (<ErrorContextProvider>
                    <Component {...componentProps}/>
                </ErrorContextProvider>);
        };
        RenderWithPage.displayName = "RenderWithPage"
        return RenderWithPage;
    };
};
