import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { RunbookProcessListPage } from "./RunbookProcessListPage";
import { RunbookProcessStepsPage } from "./RunbookProcessStepsPage";
export const RunbookProcessStepsPageWithPageDefinition = withPage({ page: pageIds.project().runbook.process.root })(RunbookProcessStepsPage);
export const RunbookProcessListPageWithPageDefinition = withPage({ page: pageIds.project().runbook.process.root })(RunbookProcessListPage);
type ProcessRouteProps = {
    path: string;
};
type RunbookProcessRouteProps = RouteComponentProps<{
    projectSlug: string;
    runbookId: string;
}>;
type Props = ProcessRouteProps & RunbookProcessRouteProps;
class RunbookProcessRoute extends React.Component<Props> {
    render() {
        return (<ErrorContextProvider>
                <Switch>
                    <MigratedReloadableRoute path={`${this.props.path}/steps`} exact={true} render={() => <RunbookProcessStepsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={`${this.props.path}`} render={() => <RunbookProcessListPageWithPageDefinition />}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>);
    }
    static displayName = "RunbookProcessRoute";
}
const EnhancedRunbookProcessRoute = withRouter(RunbookProcessRoute);
export default EnhancedRunbookProcessRoute;
