import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import routeLinks from "../../routeLinks";
import RunbookRunToRunbookTaskRedirect from "./RunbookRunToRunbookTaskRedirect";
type RunbookRunRouteProps = RouteComponentProps<{
    spaceId: string;
    runbookRunId: string;
}>;
export default class RunbookRunRoutes extends React.Component<RouteComponentProps> {
    render() {
        return (<Switch>
                <MigratedReloadableRoute path={routeLinks.runbookRun(":runbookRunId").root} render={(p: RunbookRunRouteProps) => <RunbookRunToRunbookTaskRedirect spaceId={p.match.params.spaceId} runbookRunId={p.match.params.runbookRunId}/>}/>
            </Switch>);
    }
    static displayName = "RunbookRunRoutes";
}
