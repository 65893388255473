import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import type { RunGoal } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import type { RunCreateGoal } from "~/areas/projects/components/Runbooks/RunbookSnapshots/runbookSnapshotRouteLinks";
import runbookSnapshotRouteLinks, { RunCreateGoalValues } from "~/areas/projects/components/Runbooks/RunbookSnapshots/runbookSnapshotRouteLinks";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { RunbookRunDetailsPage } from "../RunbookRunDetailsPage";
import { CreateRunbookRunForSnapshotPage } from "./CreateRunbookRunForSnapshotPage";
import { RunbookSnapshotEditPage } from "./RunbookSnapshotEditPage";
import { RunbookSnapshotInfoPage } from "./RunbookSnapshotInfoPage";
import { RunbookSnapshotsPage } from "./RunbookSnapshotsPage";
const runbookSnapshotInstancePages = pageIds.project().runbook.runbookSnapshot();
const RunbookSnapshotInfoPageWithPageDefinition = withPage({ page: runbookSnapshotInstancePages.root })(RunbookSnapshotInfoPage);
const CreateRunbookRunForSnapshotPageWithPageDefinition = withPage({ page: runbookSnapshotInstancePages.runbookRuns.create })(CreateRunbookRunForSnapshotPage);
const CreateRunbookSnapshotPageWithPageDefinition = withPage({ page: pageIds.project().runbook.runbookSnapshotCreate })(RunbookSnapshotEditPage);
const RunbookSnapshotsPageWithPageDefinition = withPage({ page: pageIds.project().runbook.runbookSnapshots })(RunbookSnapshotsPage);
const EditRunbookSnapshotPageWithPageDefinition = withPage({ page: runbookSnapshotInstancePages.edit })(RunbookSnapshotEditPage);
const RunbookRunDetailPageWithPageDefinition = withPage({ page: runbookSnapshotInstancePages.runbookRuns.specific })(RunbookRunDetailsPage);
interface RunbookSnapshotsRouteProps {
    path: string;
}
export class RunbookSnapshotsRoute extends React.Component<RunbookSnapshotsRouteProps> {
    render() {
        const runbookSnapshotsRootLinks = runbookSnapshotRouteLinks(this.props.path);
        const runbookSnapshotsLinks = runbookSnapshotsRootLinks.runbookSnapshots;
        const runbookSnapshotInstanceLinks = runbookSnapshotsRootLinks.runbookSnapshot(`:runbookSnapshotId`);
        return (<Switch>
                <MigratedReloadableRoute path={runbookSnapshotsRootLinks.runbookSnapshotCreate} render={() => <CreateRunbookSnapshotPageWithPageDefinition />}/>
                <MigratedReloadableRoute path={runbookSnapshotInstanceLinks.root}>
                    <Switch>
                        <MigratedReloadableRoute path={runbookSnapshotInstanceLinks.edit} render={({ match }: RouteComponentProps<{
            runbookSnapshotId: string;
        }>) => <EditRunbookSnapshotPageWithPageDefinition runbookSnapshotId={match.params.runbookSnapshotId}/>}/>
                        <MigratedReloadableRoute path={runbookSnapshotInstanceLinks.runbookRuns.create(":goal?", ":previousId?")} render={({ match }: RouteComponentProps<{
                goal?: RunCreateGoal;
                previousId?: string;
                runbookSnapshotId: string;
            }>) => (<CreateRunbookRunForSnapshotPageWithPageDefinition goal={getRunGoal(match.params)} runbookSnapshotId={match.params.runbookSnapshotId}/>)}/>
                        <MigratedReloadableRoute path={runbookSnapshotInstanceLinks.runbookRuns.specific(":runbookRunId")} render={({ match }: RouteComponentProps<{
            runbookRunId: string;
        }>) => <RunbookRunDetailPageWithPageDefinition runbookRunId={match.params.runbookRunId}/>}/>
                        <MigratedReloadableRoute exact path={runbookSnapshotInstanceLinks.root} render={({ match }: RouteComponentProps<{
            runbookSnapshotId: string;
        }>) => <RunbookSnapshotInfoPageWithPageDefinition runbookSnapshotId={match.params.runbookSnapshotId}/>}/>
                    </Switch>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={runbookSnapshotsLinks} render={({ match }: RouteComponentProps<{
            projectSlug: string;
        }>) => <RunbookSnapshotsPageWithPageDefinition projectSlug={match.params.projectSlug}/>}/>
            </Switch>);
    }
    static displayName = "RunbookSnapshotsRoute";
}
function getRunGoal({ goal, previousId, tenantIds }: {
    tenantIds?: string;
    previousId?: string;
    goal?: RunCreateGoal;
}): RunGoal {
    if (goal === RunCreateGoalValues.To) {
        return {
            environmentIds: previousId?.split(",").filter((id: string) => Boolean(id)),
            tenantIds: tenantIds?.split(",").filter((id: string) => Boolean(id)),
        };
    }
    if (goal == RunCreateGoalValues.TryAgain && !previousId) {
        throw new Error("Previous runbook run id needs to be provided when retrying a run.");
    }
    if (goal == RunCreateGoalValues.TryAgain && previousId) {
        return {
            previousRunbookRunId: previousId,
        };
    }
    return {};
}
