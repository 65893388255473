import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, InlineSnackbar } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { FeedType, getFeedTypeLabel, isOctopusProjectFeed, Permission } from "@octopusdeploy/octopus-server-client";
import type { FeedResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { useCallback } from "react";
import * as React from "react";
import { useCorrelationId } from "~/analytics/Analytics";
import { ExternalFeedDrawer } from "~/areas/library/components/ExternalFeeds/ExternalFeedDrawer";
import { useDispatchFeedInteraction } from "~/areas/library/components/ExternalFeeds/amplitudeTracking";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SelectWithAddRefresh, { BoundSelectWithAddRefresh } from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
interface FeedSelectorProps {
    localNames: string[] | undefined;
    allowBoundVariables?: boolean;
    feeds: FeedResource[];
    feedId: string | undefined;
    refreshFeeds: () => Promise<void>;
    onChange: (selectedFeed: FeedResource | string) => void;
    feedSelectLabel?: string;
    autoFocus?: boolean;
    feedIdError?: string;
    feedType?: FeedType[];
}
export const getDefaultFeedId = (feeds: FeedResource[]): string | undefined => {
    if (feeds.length === 0) {
        return undefined;
    }
    const buildtIn = feeds.find((f) => f.FeedType === FeedType.BuiltIn);
    if (buildtIn) {
        return buildtIn.Id;
    }
    return feeds[0].Id;
};
const getSelectedFeed = (feedId: string | undefined, feeds: FeedResource[] | null) => {
    if (feeds === null || feeds.length === 0) {
        return null;
    }
    if (feedId) {
        const feed = feeds.find((f) => f.Id === feedId);
        if (feed) {
            return feed;
        }
    }
    return null;
};
const noFeedsWarningContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[16],
});
const noFeedsWarningTitleStyles = css({
    fontWeight: "bold",
});
const snackbarContainerStyles = css({
    marginBottom: space[16],
});
const NoFeedsWarning = ({ feedType, refreshFeeds, handleAddFeed }: {
    feedType?: FeedType[];
    refreshFeeds?: () => Promise<void>;
    handleAddFeed?: () => void;
}) => {
    const areDrawersEnabled = isFeatureToggleEnabled("ContextualDrawersFeatureToggle");
    //We can't just use the passed in refresh feeds handler since it may not expect an event - it's probably more likely to expect a callback
    const handleRefresh = async () => {
        if (refreshFeeds) {
            return refreshFeeds();
        }
    };
    const actions: JSX.Element[] = [];
    if (areDrawersEnabled) {
        actions.push(<ActionButton type={ActionButtonType.Secondary} label={`Add External Feed`} onClick={handleAddFeed}/>);
        actions.push(<IconButtonWithTooltip onClick={handleRefresh} toolTipContent="Refresh" icon="Refresh"/>);
    }
    else {
        actions.push(<ActionButton type={ActionButtonType.Ternary} label="Refresh" onClick={handleRefresh}/>);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const feedsPageLink = links.feedsPage.generateUrl({ spaceId: repository.spaceId! });
    return (<div className={noFeedsWarningContainerStyles}>
            {areDrawersEnabled && <div>Add a {`${getFeedTypeLabel(feedType)}`} as an external package feed</div>}
            {!areDrawersEnabled && (<>
                    <div className={noFeedsWarningTitleStyles}>{`${getFeedTypeLabel(feedType)} Feed required`}</div>
                    <div>
                        Please add an appropriate feed in the
                        <InternalLink to={feedsPageLink} openInSelf={false}>
                            External Feeds
                        </InternalLink>{" "}
                        area and try again.
                    </div>
                </>)}
            <ActionList alignStart={true} actions={actions}/>
        </div>);
};
export const possibleFeeds = (feeds: FeedResource[], feedType?: FeedType[]) => {
    return feeds.filter((f) => !isOctopusProjectFeed(f.FeedType) && // We will never show the OctopusProject feed when selecting packages
        (!feedType || feedType.length === 0 || feedType.some((feedTypeToCheck) => feedTypeToCheck === f.FeedType)));
};
export const FeedSelector = ({ onChange, refreshFeeds, allowBoundVariables = true, ...props }: FeedSelectorProps) => {
    const isFeatureEnabled = isFeatureToggleEnabled("ContextualDrawersFeatureToggle");
    const [isOpen, setIsOpen] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const analyticsDispatch = useDispatchFeedInteraction();
    const analyticsCorrelationId = useCorrelationId();
    const defaultFeedId = getDefaultFeedId(props.feeds);
    let selectedFeedId = props.feedId;
    if (selectedFeedId === null) {
        selectedFeedId = defaultFeedId;
    }
    const feeds = possibleFeeds(props.feeds, props.feedType);
    const feedSelectLabel = props.feedSelectLabel || "Package feed";
    const handleOnChange = (feedId: string | undefined) => {
        const feed = props.feeds.find((f) => f.Id === feedId);
        if (feed) {
            onChange(feed);
        }
        else if (feedId !== undefined) {
            onChange(feedId);
        }
    };
    const onClose = () => {
        setIsOpen(false);
    };
    const handleOnSave = useCallback(async (feed: FeedResource) => {
        await refreshFeeds();
        onChange(feed);
        setIsOpen(false);
        setTimeout(() => setShowSnackbar(true), 300);
    }, [refreshFeeds, onChange]);
    const handleAddFeed = () => {
        setIsOpen(true);
        analyticsDispatch({
            type: "Add Feed",
            Location: "Drawer",
            "Feed Type": getFeedTypeLabel(props.feedType),
            "Correlation Id": analyticsCorrelationId,
        });
    };
    if (feeds.length === 0) {
        return (<>
                <NoFeedsWarning feedType={props.feedType} refreshFeeds={refreshFeeds} handleAddFeed={handleAddFeed}/>
                <ExternalFeedDrawer isOpen={isOpen} onClose={onClose} onSave={handleOnSave} allowedFeedTypes={props.feedType} analyticsCorrelationId={analyticsCorrelationId}/>
            </>);
    }
    const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
    const feedEditPermissionGranted = isAllowed({ permission: Permission.FeedEdit, wildcard: true });
    if (!feedViewPermissionGranted) {
        return (<Callout type={CalloutType.Warning} title={"Permission required"}>
                You do not have permission to perform this action. Please contact your Octopus administrator. Missing permission: {Permission.FeedView}
            </Callout>);
    }
    const commonSelectorProps = {
        items: feeds.map((f) => ({ value: f.Id, text: f.Name })),
        value: selectedFeedId,
        onRequestRefresh: refreshFeeds,
        onChange: handleOnChange,
        autoFocus: props.autoFocus,
        error: props.feedIdError,
        label: feedSelectLabel,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        addUrl: links.feedsPage.generateUrl({ spaceId: repository.spaceId! }),
        addAction: isFeatureEnabled ? handleAddFeed : undefined,
        disableAdd: !feedEditPermissionGranted,
        addButtonTooltipText: feedEditPermissionGranted ? undefined : "FeedEdit permissions required to add or edit a new feed",
    };
    return (<>
            {allowBoundVariables && (<BoundSelectWithAddRefresh {...commonSelectorProps} variableLookup={{
                localNames: props.localNames,
            }} resetValue={defaultFeedId} label={feedSelectLabel}/>)}
            {!allowBoundVariables && <SelectWithAddRefresh {...commonSelectorProps}/>}
            <div className={cn({ snackbarContainerStyles: showSnackbar })}>
                <InlineSnackbar variant="success" show={showSnackbar} content={"Feed Created"} onClose={() => setShowSnackbar(false)} autoHideDuration={3000}/>
            </div>
            <ExternalFeedDrawer isOpen={isOpen} onClose={onClose} onSave={handleOnSave} allowedFeedTypes={props.feedType} analyticsCorrelationId={analyticsCorrelationId}/>
        </>);
};
