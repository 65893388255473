import { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { RouteTemplate, ParsedQueryParams, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { links, allRoutes } from "@octopusdeploy/portal-routes";
import type { ComponentType } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch, useLocation } from "react-router-dom";
import ConfigurationLayout from "~/areas/configuration/components/ConfigurationLayout/ConfigurationLayout";
import DeploymentToProjectTaskRedirect from "~/areas/deployments/DeploymentToProjectTaskRedirect";
import { InsightsReportLoader } from "~/areas/insights/components/InsightsReportLayout/InsightsReportLoader";
import LicenseChecker from "~/areas/insights/components/InsightsRoutes/LicenseChecker";
import { RedirectFeedBasedOnType } from "~/areas/library/components/ExternalFeeds/RedirectFeedBasedOnType";
import { ProjectLayout } from "~/areas/projects/components/ProjectLayout/ProjectLayout";
import BranchAwareRedirect from "~/areas/projects/components/ProjectsRoutes/BranchAwareRedirect";
import { ProjectToOverviewRedirect } from "~/areas/projects/components/ProjectsRoutes/ProjectToOverviewRedirect";
import { VariablesBranchAwareRedirect } from "~/areas/projects/components/ProjectsRoutes/VariablesBranchAwareRedirect";
import RunbookContextLayout from "~/areas/projects/components/Runbooks/RunbookContextLayout";
import RunbookRunToRunbookTaskRedirect from "~/areas/runbookRuns/RunbookRunToRunbookTaskRedirect";
import { InterruptionToProjectRedirect } from "~/areas/tasks/components/TaskRoutes/InterruptionToTaskRedirect";
import TenantLayout from "~/areas/tenants/TenantLayout/TenantLayout";
import UserProfileLayout from "~/areas/users/UserProfileLayout";
import { session } from "~/clientInstance";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { NoPermissionsPage } from "~/components/NoPermissionsPage/NoPermissionsPage";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import RedirectToProcessEditorViaSlug from "~/components/RedirectToProcessEditorViaSlug/RedirectToProcessEditorViaSlug";
import ReloadableRoute from "~/components/ReloadableRoute/ReloadableRoute";
import { ChannelRedirect, ReleaseRedirect, RunbookRedirect, TriggerRedirect } from "~/components/RootRoutes/ProjectChildRedirect";
import { RunbookSnapshotRedirect } from "~/components/RootRoutes/ProjectRunbookChildRedirect";
import { RedirectProjectIfNotSlug, RedirectReleaseVersion } from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { SpaceNotFound } from "~/components/SpaceNotFound/SpaceNotFound";
import type { InsightsReportPageRegistration } from "~/routing/pageRegistrations/InsightsPageRegistration";
import type { Level1PageLayoutProps } from "~/routing/pageRegistrations/Level1PageLayoutProps";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import { formatRoutePath } from "~/routing/pageRegistrations/formatRoutePath";
import { routeSegment } from "~/routing/pageRegistrations/routeSegment";
import { DefaultConfigurationPageRedirect } from "~/routing/redirects/DefaultConfigurationPageRedirect";
import { ScopedUserRoleToTeamRedirect } from "~/routing/redirects/ScopedUserRoleToTeamRedirect";
import type { PageRegistration } from "./pageRegistrations/PageRegistration";
import { RoutePage } from "./pageRegistrations/RoutePage";
import { allPages } from "./pageRegistrations/allPageRegistrations";
import { DefaultLibraryPageRedirect } from "./redirects/DefaultLibraryPageRedirect";
interface AllPageRoutesProps {
    spaceContext: SpaceContext;
}
export function AllPageRoutes({ spaceContext }: AllPageRoutesProps) {
    const { scriptConsolePage, taskPage, ...systemPages } = allPages.childGroups.system.pages;
    return (<Switch>
            {getDefaultRootRedirectRoute(spaceContext)}

            {renderPages(allPages.childGroups.space.pages)}

            {/* Render the script console page before the task page so /console is not interpreted as a taskId */}
            {renderPage(scriptConsolePage)}
            {renderPage(taskPage)}
            {renderPages(systemPages)}

            {routeSegment(allPages.childGroups.space.childGroups.projects, (routeProps, pages, childGroups) => (<ProjectsRoutes projectsPages={pages} projectsPageGroups={childGroups}/>))}
            {routeSegment(allPages.childGroups.space.childGroups.infrastructure, (routeProps, pages) => (<InfrastructureRoutes pages={pages}/>))}
            {routeSegment(allPages.childGroups.space.childGroups.library, (routeProps, pages) => (<LibraryRoutes pages={pages}/>))}
            {routeSegment(allPages.childGroups.system.childGroups.configuration, (routeProps, pages, childGroups) => (<ConfigurationRoutes pages={pages} childGroups={childGroups}/>))}
            {routeSegment(allPages.childGroups.space.childGroups.tenants, (routeProps, pages, childGroups) => (<TenantRoutes tenantsPages={pages} tenantsPageGroups={childGroups}/>))}
            {routeSegment(allPages.childGroups.system.childGroups.currentUser, (routeProps, pages) => (<CurrentUserRoutes pages={pages}/>))}
            {routeSegment(allPages.childGroups.space.childGroups.insights, (routeProps, pages, childGroups) => (<InsightsRoutes pages={pages} childGroups={childGroups} spaceId={routeProps.spaceId}/>))}

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.deploymentToProjectTaskRedirect.completeRoute.template)} exact={true} render={(props: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.deploymentToProjectTaskRedirect.completeRoute.template>>) => (<DeploymentToProjectTaskRedirect spaceId={props.match.params.spaceId} deploymentId={props.match.params.deploymentId}/>)}/>

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.spaceRootRedirect.completeRoute.template)} exact={true} render={(props: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.spaceRootRedirect.completeRoute.template>>) => (<InternalRedirect to={links.dashboardPage.generateUrl({ spaceId: props.match.params.spaceId })}/>)}/>

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.releaseRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.releaseRedirect.completeRoute.template>>) => (<ReleaseRedirect spaceId={match.params.spaceId} releaseId={match.params.releaseId}/>)}/>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.triggerRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.triggerRedirect.completeRoute.template>>) => (<TriggerRedirect spaceId={match.params.spaceId} triggerId={match.params.triggerId}/>)}/>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.channelRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.channelRedirect.completeRoute.template>>) => (<ChannelRedirect spaceId={match.params.spaceId} channelId={match.params.channelId}/>)}/>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.runbookSnapshotRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.runbookSnapshotRedirect.completeRoute.template>>) => (<RunbookSnapshotRedirect spaceId={match.params.spaceId} runbookSnapshotId={match.params.runbookSnapshotId}/>)}/>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.runbookRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.runbookRedirect.completeRoute.template>>) => (<RunbookRedirect spaceId={match.params.spaceId} runbookId={match.params.runbookId}/>)}/>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.redirects.runbookRunRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.redirects.runbookRunRedirect.completeRoute.template>>) => (<RunbookRunToRunbookTaskRedirect spaceId={match.params.spaceId} runbookRunId={match.params.runbookRunId}/>)}/>

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.system.redirects.interruptionToTaskRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.system.redirects.interruptionToTaskRedirect.completeRoute.template>>) => (<InterruptionToProjectRedirect interruptionId={match.params.interruptionId}/>)}/>

            {getNoMatchRoute(spaceContext)}
        </Switch>);
}
function getDefaultRootRedirectRoute(spaceContext: SpaceContext) {
    if (isSpecificSpaceContext(spaceContext)) {
        // The SpaceLoader redirects to the root of a space, means that this route is never hit. We should consider whether this is still needed.
        return <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.redirects.rootRedirect.completeRoute.template)} render={() => <InternalRedirect to={links.dashboardPage.generateUrl({ spaceId: spaceContext.Id })}/>}/>;
    }
    if (session.currentPermissions?.hasAnyPermissions()) {
        return <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.redirects.rootRedirect.completeRoute.template)} render={() => <InternalRedirect to={links.configurationRootRedirect.generateUrl()}/>}/>;
    }
    return undefined;
}
function getNoMatchRoute(spaceContext: SpaceContext) {
    if (isSpaceNotFound(spaceContext)) {
        return <ReloadableRoute key="space not found" render={() => <SpaceNotFound spaceNotFoundContext={spaceContext}/>}/>;
    }
    else if (!session.currentPermissions?.hasAnyPermissions()) {
        return <ReloadableRoute key="no permissions" component={NoPermissionsPage}/>;
    }
    return <RedirectAs404 key="404 redirect"/>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteParamsFromRouteTemplate<Template extends RouteTemplate<any>> = Template extends RouteTemplate<infer RouteParams> ? RouteParams : never;
const branchNameKey: Extract<keyof RouteParamsFromRouteTemplate<typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.branch.partialRoute>, "branchName"> = "branchName";
function InfrastructureRoutes({ pages }: {
    pages: typeof allPages.childGroups.space.childGroups.infrastructure.pages;
}) {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const { createProxyPage, editProxyPage, createMachinePolicyPage, editMachinePolicyPage, createInfrastructureAccountPage, editInfrastructureAccountPage, dynamicEnvironmentsPage, dynamicEnvironmentPage, ...otherPages } = pages;
    return (<Switch>
            {renderPage(createProxyPage)}
            {renderPage(editProxyPage)}
            {renderPage(createMachinePolicyPage)}
            {renderPage(editMachinePolicyPage)}
            {renderPage(createInfrastructureAccountPage)}
            {renderPage(editInfrastructureAccountPage)}
            {dynamicEnvironmentsEnabled && renderPages({ dynamicEnvironmentsPage, dynamicEnvironmentPage })}
            {renderPages(otherPages)}

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.infrastructure.redirects.infrastructureRootRedirect.completeRoute.template)} exact={true} render={(props: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.infrastructure.redirects.infrastructureRootRedirect.completeRoute.template>>) => (<InternalRedirect to={links.infrastructureOverviewPage.generateUrl({ spaceId: props.match.params.spaceId })}/>)}/>
        </Switch>);
}
function LibraryRoutes({ pages }: {
    pages: typeof allPages.childGroups.space.childGroups.library.pages;
}) {
    const { archiveCertificatePage, createCertificatePage, editCertificatePage, createFeedPage, editFeedPage, createLifecyclePage, editLifecyclePage, createScriptModulePage, editScriptModulePage, createTagSetPage, editTagSetPage, createGitCredentialPage, editGitCredentialPage, gitConnectionsPage, createGitHubConnectionPage, editGitHubConnectionPage, ...otherPages } = pages;
    const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const gitHubConnectionPages = {
        gitConnectionsPage,
        createGitHubConnectionPage,
        editGitHubConnectionPage,
    };
    return (<Switch>
            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.libraryRootRedirect.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.libraryRootRedirect.completeRoute.template>>) => (<DefaultLibraryPageRedirect spaceId={match.params.spaceId}/>)}/>

            <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.redirectFeedBasedOnType.completeRoute.template)} exact={true} render={({ match }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.library.redirects.redirectFeedBasedOnType.completeRoute.template>>) => (<RedirectFeedBasedOnType spaceId={match.params.spaceId} feedId={match.params.feedId}/>)}/>

            {/* Explicit ordering here, so /archive and /create doesn't get caught by the /{id} route. */}
            {renderPage(archiveCertificatePage)}
            {renderPage(createCertificatePage)}
            {renderPage(editCertificatePage)}
            {renderPage(createFeedPage)}
            {renderPage(editFeedPage)}
            {renderPage(createLifecyclePage)}
            {renderPage(editLifecyclePage)}
            {renderPage(createScriptModulePage)}
            {renderPage(editScriptModulePage)}
            {renderPage(createTagSetPage)}
            {renderPage(editTagSetPage)}
            {renderPage(createGitCredentialPage)}
            {renderPage(editGitCredentialPage)}
            {gitHubConnectionsEnabled && renderPages(gitHubConnectionPages)}
            {renderPages(otherPages)}
        </Switch>);
}
function ProjectsRoutes({ projectsPages, projectsPageGroups }: {
    projectsPages: typeof allPages.childGroups.space.childGroups.projects.pages;
    projectsPageGroups: typeof allPages.childGroups.space.childGroups.projects.childGroups;
}) {
    const location = useLocation();
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const { projectsPage, ...otherProjectsPages } = projectsPages;
    return (<Switch>
            {/*The projectsPage has a level 1 layout so is rendered separately to the other root project pages*/}
            {renderPageWithLevel1Layout(projectsPage, FullWidthPageLayout)}
            {renderPages(otherProjectsPages)}

            {routeSegment(projectsPageGroups.specificProject, (specificProjectRouteProps, specificProjectPages, specificProjectChildGroups) => (<RedirectProjectIfNotSlug spaceId={specificProjectRouteProps.spaceId}>
                    <Switch>
                        {routeSegment(specificProjectChildGroups.branch, (branchRouteProps, branchPages, branchChildGroups) => (<ProjectLayout spaceId={branchRouteProps.spaceId} projectSlug={branchRouteProps.projectSlug} branchName={branchRouteProps.branchName} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                    <Switch>
                                        {routeSegment(branchChildGroups.deployments, (_, branchDeploymentPages) => (<ErrorContextProvider>
                                                <Switch>
                                                    <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.deployments.redirects
                        .branchDeploymentProcessStepByStepSlugRedirect.completeRoute.template)} exact={true} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.branch.childRouteSegments.deployments.redirects.branchDeploymentProcessStepByStepSlugRedirect.completeRoute.template>>) => <RedirectToProcessEditorViaSlug processType={ProcessType.Deployment} projectSlug={match.params.projectSlug} stepSlug={match.params.stepSlug}/>}/>

                                                    {renderPages(branchDeploymentPages)}
                                                </Switch>
                                            </ErrorContextProvider>))}

                                        {routeSegment(branchChildGroups.variables, (_, branchVariablesPages) => (<ErrorContextProvider>
                                                <Switch>{renderPages(branchVariablesPages)}</Switch>
                                            </ErrorContextProvider>))}

                                        {renderPages(branchPages)}
                                    </Switch>
                                </ProjectLayout>), undefined, [branchNameKey])}
                        {routeSegment(specificProjectChildGroups.withoutBranch, (branchRouteParams, nonBranchPages, nonBranchChildGroups) => {
                const { projectDynamicEnvironmentSettingsPage, ...otherNonBranchPages } = nonBranchPages;
                return (<ProjectLayout spaceId={branchRouteParams.spaceId} projectSlug={branchRouteParams.projectSlug} branchName={undefined} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                    <Switch>
                                        <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectRootRedirect.completeRoute.template)} exact={true} render={() => <ProjectToOverviewRedirect />}/>
                                        <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectOverviewRedirect.completeRoute.template)} exact={true} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.redirects.projectOverviewRedirect.completeRoute.template>>) => <InternalRedirect to={links.deploymentsPage.generateUrl({ spaceId: match.params.spaceId, projectSlug: match.params.projectSlug })}/>}/>

                                        {routeSegment(nonBranchChildGroups.deployments, (deploymentRouteParams, { releasesPage, createReleasePage, ...otherDeploymentPages }, deploymentChildGroups) => (<Switch>
                                                {routeSegment(deploymentChildGroups.process, (_, deploymentProcessPages) => (<BranchAwareRedirect>
                                                        <ErrorContextProvider>
                                                            <Switch>
                                                                <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.deployments
                                .childRouteSegments.process.redirects.deploymentProcessStepByStepSlugRedirect.completeRoute.template)} exact={true} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.deployments.childRouteSegments.process.redirects.deploymentProcessStepByStepSlugRedirect.completeRoute.template>>) => <RedirectToProcessEditorViaSlug processType={ProcessType.Deployment} projectSlug={match.params.projectSlug} stepSlug={match.params.stepSlug}/>}/>

                                                                {renderPages(deploymentProcessPages)}
                                                            </Switch>
                                                        </ErrorContextProvider>
                                                    </BranchAwareRedirect>))}
                                                {routeSegment(deploymentChildGroups.settings, (_, deploymentSettingsPages) => (<BranchAwareRedirect>
                                                        <ErrorContextProvider>
                                                            <Switch>{renderPages(deploymentSettingsPages)}</Switch>
                                                        </ErrorContextProvider>
                                                    </BranchAwareRedirect>))}

                                                {renderPage(createReleasePage)}

                                                {routeSegment(deploymentChildGroups.specificRelease, (_, { editReleasePage, releasePage, createDeploymentPage, deploymentDetailsPage, ...otherReleasePages }) => (<RedirectReleaseVersion>
                                                        <Switch>
                                                            {renderPage(editReleasePage)}
                                                            {renderPage(releasePage)}
                                                            {renderPage(createDeploymentPage)}
                                                            {renderPage(deploymentDetailsPage)}
                                                            {renderPages(otherReleasePages)}
                                                        </Switch>
                                                    </RedirectReleaseVersion>))}

                                                {renderPage(releasesPage)}
                                                {renderPages(otherDeploymentPages)}
                                            </Switch>))}
                                        {routeSegment(nonBranchChildGroups.variables, (_, variablesPages, variablesChildGroups) => (<ErrorContextProvider>
                                                <Switch>
                                                    {routeSegment(variablesChildGroups.wrapper, (_, variablesWrapperPages) => (<VariablesBranchAwareRedirect>
                                                                <Switch>{renderPages(variablesWrapperPages)}</Switch>
                                                            </VariablesBranchAwareRedirect>), true)}

                                                    {routeSegment(variablesChildGroups.all, (_, allVariablesPages) => (<VariablesBranchAwareRedirect>
                                                            <Switch>{renderPages(allVariablesPages)}</Switch>
                                                        </VariablesBranchAwareRedirect>))}

                                                    {routeSegment(variablesChildGroups.preview, (_, variablePreviewPages) => (<VariablesBranchAwareRedirect>
                                                            <Switch>{renderPages(variablePreviewPages)}</Switch>
                                                        </VariablesBranchAwareRedirect>))}

                                                    {renderPages(variablesPages)}
                                                </Switch>
                                            </ErrorContextProvider>))}
                                        {routeSegment(nonBranchChildGroups.specificRunbook, ({ runbookId }, runbookPages, runbookChildGroups) => (<SpecificRunbookRoutes runbookId={runbookId} pages={runbookPages} childGroups={runbookChildGroups}/>))}
                                        {dynamicEnvironmentsEnabled && renderPages({ projectDynamicEnvironmentSettingsPage })}
                                        {renderPages(otherNonBranchPages)}
                                    </Switch>
                                </ProjectLayout>);
            })}
                    </Switch>
                </RedirectProjectIfNotSlug>))}
        </Switch>);
}
type SpecificRunbookPages = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.withoutBranch.childGroups.specificRunbook.pages;
type SpecificRunbookChildGroups = typeof allPages.childGroups.space.childGroups.projects.childGroups.specificProject.childGroups.withoutBranch.childGroups.specificRunbook.childGroups;
function SpecificRunbookRoutes({ runbookId, pages, childGroups }: {
    runbookId: string;
    pages: SpecificRunbookPages;
    childGroups: SpecificRunbookChildGroups;
}) {
    const { projectRunbookSnapshotInfoPage, projectRunbookSnapshotEditPage, projectRunbookSnapshotCreatePage, projectRunbookSnapshotsPage, runbookRunNowPage, runbookRunSnapshotNowPage, createRunbookRunForSnapshotPage, projectRunbookRunDetailPage, ...otherSpecificRunbookPages } = pages;
    return (<RunbookContextLayout runbookId={runbookId}>
            {(_) => (<Switch>
                    <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.redirects.runbookRootRedirect.completeRoute.template)} render={({ match, }: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.space.childRouteSegments.projects.childRouteSegments.specificProject.childRouteSegments.withoutBranch.childRouteSegments.specificRunbook.redirects.runbookRootRedirect.completeRoute.template>>) => <InternalRedirect to={links.projectRunbookOverviewPage.generateUrl({ spaceId: match.params.spaceId, projectSlug: match.params.projectSlug, runbookId: match.params.runbookId })}/>}/>

                    {routeSegment(childGroups.process, (_, runbookProcessPages) => (<ErrorContextProvider>
                            <Switch>{renderPages(runbookProcessPages)}</Switch>
                        </ErrorContextProvider>))}

                    {/* Explicit ordering here, so that snapshot routes don't interfere with each other. */}
                    {renderPage(projectRunbookSnapshotCreatePage)}
                    {renderPage(projectRunbookSnapshotEditPage)}
                    {renderPage(projectRunbookSnapshotInfoPage)}
                    {renderPage(projectRunbookSnapshotsPage)}
                    {renderPage(runbookRunSnapshotNowPage)}
                    {renderPage(runbookRunNowPage)}
                    {renderPage(createRunbookRunForSnapshotPage)}
                    {renderPage(projectRunbookRunDetailPage)}
                    {renderPages(otherSpecificRunbookPages)}
                </Switch>)}
        </RunbookContextLayout>);
}
function ConfigurationRoutes({ pages, childGroups }: {
    pages: typeof allPages.childGroups.system.childGroups.configuration.pages;
    childGroups: typeof allPages.childGroups.system.childGroups.configuration.childGroups;
}) {
    const allPages = {
        ...pages,
        ...childGroups.settings.pages,
    };
    return (<ConfigurationLayout>
            {(PageLayout) => {
            const { createSubscriptionPage, editSubscriptionPage, createUserRolePage, editUserRolePage, createUserPage, editUserPage, ...otherPages } = allPages;
            return (<Switch>
                        <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.configurationRootRedirect.completeRoute.template)} render={() => <DefaultConfigurationPageRedirect />}/>
                        <ReloadableRoute path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.scopedUserRoleToTeamRedirect.completeRoute.template)} exact={true} render={(props: RouteComponentProps<RouteParamsFromRouteTemplate<typeof allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.scopedUserRoleToTeamRedirect.completeRoute.template>>) => (<ScopedUserRoleToTeamRedirect scopedUserRoleId={props.match.params.scopedRoleId}/>)}/>
                        <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.configuration.redirects.diagnosticMachineCleanupRedirect.completeRoute.template)} render={() => <InternalRedirect to={links.auditPage.generateUrl({ documentTypes: ["Machines"], users: ["users-system"], eventCategories: ["Deleted"] })}/>}/>
                        {/* The routes for some create and edit pages overlap.
                 Depending on the order in which they are rendered, the /create segment of the create page
                 could be interpreted as the route parameter of the edit page.
                 Here we need to explicitly render the create page first to avoid this. */}
                        {renderPageWithLevel2Layout(createSubscriptionPage, PageLayout)}
                        {renderPageWithLevel2Layout(editSubscriptionPage, PageLayout)}
                        {renderPageWithLevel2Layout(createUserRolePage, PageLayout)}
                        {renderPageWithLevel2Layout(editUserRolePage, PageLayout)}
                        {renderPageWithLevel2Layout(createUserPage, PageLayout)}
                        {renderPageWithLevel2Layout(editUserPage, PageLayout)}
                        {renderPagesWithLevel2Layout(otherPages, PageLayout)}
                    </Switch>);
        }}
        </ConfigurationLayout>);
}
function CurrentUserRoutes({ pages }: {
    pages: typeof allPages.childGroups.system.childGroups.currentUser.pages;
}) {
    return (<UserProfileLayout>
            <Switch>
                <ReloadableRoute exact={true} path={formatRoutePath(allRoutes.childRouteSegments.system.childRouteSegments.currentUser.redirects.currentUserRootRedirect.completeRoute.template)} render={() => <InternalRedirect to={links.currentUserDetailsPage.generateUrl()}/>}></ReloadableRoute>
                {renderPages(pages)}
            </Switch>
        </UserProfileLayout>);
}
function InsightsRoutes({ pages, childGroups, spaceId }: {
    pages: typeof allPages.childGroups.space.childGroups.insights.pages;
    childGroups: typeof allPages.childGroups.space.childGroups.insights.childGroups;
    spaceId: string;
}) {
    return (<LicenseChecker spaceId={spaceId}>
            <Switch>
                {renderPages(pages)}
                {routeSegment(childGroups.specificReport, ({ spaceId, reportId }, reportPages) => (<InsightsReportLoader spaceId={spaceId} reportId={reportId}>
                        {({ report, refreshReport }) => <Switch>{renderInsightsReportPages(reportPages, report, refreshReport)}</Switch>}
                    </InsightsReportLoader>))}
            </Switch>
        </LicenseChecker>);
}
function renderInsightsReportPages(pages: typeof allPages.childGroups.space.childGroups.insights.childGroups.specificReport.pages, report: InsightsReportResource, refreshReport: () => Promise<void>) {
    return Object.values(pages).map((p: UnknownInsightsReportPageRegistration) => renderInsightsReportPage(p, report, refreshReport));
}
function renderInsightsReportPage<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: InsightsReportPageRegistration<RouteParams, QueryParams>, report: InsightsReportResource, refreshReport: () => Promise<void>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return (<RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(report, refreshReport, routeParams, queryParams, setQueryParams)}/>);
}
function TenantRoutes({ tenantsPages, tenantsPageGroups }: {
    tenantsPages: typeof allPages.childGroups.space.childGroups.tenants.pages;
    tenantsPageGroups: typeof allPages.childGroups.space.childGroups.tenants.childGroups;
}) {
    return (<Switch>
            {routeSegment(tenantsPageGroups.specificTenant, ({ spaceId, tenantId }, specificTenantPages) => (<TenantLayout spaceId={spaceId} tenantId={tenantId}>
                    <Switch>{renderPages(specificTenantPages)}</Switch>
                </TenantLayout>))}
            {/*The tenant root pages need to be rendered after more specific pages to allow those to be matched first. */}
            {renderPages(tenantsPages)}
        </Switch>);
}
function renderPage<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: PageRegistrationWithoutLayout<RouteParams, QueryParams>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(routeParams, queryParams, setQueryParams)}/>;
}
function renderPageWithLevel1Layout<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: PageRegistrationWithLevel1Layout<RouteParams, QueryParams>, PageLayout: ComponentType<Level1PageLayoutProps>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(PageLayout, routeParams, queryParams, setQueryParams)}/>;
}
function renderPageWithLevel2Layout<RouteParams, QueryParams extends UnknownQueryParam[]>(pageRegistration: PageRegistrationWithLevel2Layout<RouteParams, QueryParams>, PageLayout: ComponentType<Level2PageLayoutProps>) {
    const routePath = formatRoutePath(pageRegistration.route.template);
    return <RoutePage key={routePath} path={routePath} exact={true} pageRegistration={pageRegistration} render={(routeParams, queryParams, setQueryParams) => pageRegistration.render(PageLayout, routeParams, queryParams, setQueryParams)}/>;
}
function renderPages(pageRegistrations: Record<string, UnknownPageRegistrationWithoutLayout>) {
    return Object.values(pageRegistrations).map((p) => renderPage(p));
}
function renderPagesWithLevel1Layout(pageRegistrations: Record<string, UnknownPageRegistrationWithLevel1Layout>, PageLayout: ComponentType<Level1PageLayoutProps>) {
    return Object.values(pageRegistrations).map((p) => renderPageWithLevel1Layout(p, PageLayout));
}
function renderPagesWithLevel2Layout(pageRegistrations: Record<string, UnknownPageRegistrationWithLevel2Layout>, PageLayout: ComponentType<Level2PageLayoutProps>) {
    return Object.values(pageRegistrations).map((p) => renderPageWithLevel2Layout(p, PageLayout));
}
interface PageRegistrationWithoutLayout<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => React.ReactNode;
}
interface PageRegistrationWithLevel1Layout<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (PageLayout: ComponentType<Level1PageLayoutProps>, routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => React.ReactNode;
}
interface PageRegistrationWithLevel2Layout<RouteParams, QueryParams extends UnknownQueryParam[]> extends PageRegistration<RouteParams, QueryParams> {
    render: (PageLayout: ComponentType<Level2PageLayoutProps>, routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => React.ReactNode;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistrationWithLevel2Layout = PageRegistrationWithLevel2Layout<any, UnknownQueryParam[]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistrationWithLevel1Layout = PageRegistrationWithLevel1Layout<any, UnknownQueryParam[]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownPageRegistrationWithoutLayout = PageRegistrationWithoutLayout<any, UnknownQueryParam[]>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownInsightsReportPageRegistration = InsightsReportPageRegistration<any, UnknownQueryParam[]>;
