import { css, cx } from "@emotion/css";
import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import { getLastRouteSegment } from "~/components/ContextualHelpLayout/getLastRouteSegment";
import { useQueryParams } from "~/components/ContextualHelpLayout/useQueryParams";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import HelpImageTargets from "../../Images/DeploymentTargets.svg";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer } from "./CommonViews";
const video = {
    youtubeId: "CBws8yDaN4w",
    shortlink: "OnboardingDeploymentTargetsVideo",
};
export const CommonDeploymentTargetOverview = () => {
    const getQueryParam = useQueryParams();
    const lastSegment = getLastRouteSegment(window.location.href);
    const type = getQueryParam("type");
    const deploymentTargetTypeId = getQueryParam("deploymentTargetTypeId");
    const category = getQueryParam("category");
    if (lastSegment === "TentaclePassive" && (category === "Windows" || category === "Linux")) {
        return <ListeningTentaclesOverview category={category}/>;
    }
    if (lastSegment === "Ssh" && (category === "Mac" || category === "Linux")) {
        return <SshOverview category={category}/>;
    }
    if (lastSegment === "create" && type === "AzureWebApp") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    An <strong>Azure Web App</strong> deployment target lets you reference an existing Web App in your Azure subscription.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    if (lastSegment === "create" && type === "AzureServiceFabricCluster") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    An <strong>Azure Service Fabric Cluster</strong> lets you reference an existing Service Fabric Cluster app in your Azure subscription.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    if (lastSegment === "create" && type === "AzureCloudService") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    An <strong>Azure Cloud Service</strong> deployment target lets you reference an existing Cloud Service in your Azure subscription.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    if (lastSegment === "create" && type === "StepPackage" && deploymentTargetTypeId === "aws-ecs-target") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    An <strong>Amazon ECS Cluster</strong> deployment target lets you reference an existing ECS Cluster in your AWS account.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    if (lastSegment === "create" && type === "Kubernetes") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    A <strong>Kubernetes API</strong> deployment target lets you reference an existing Kubernetes service.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    if (lastSegment === "create" && type === "OfflineDrop") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    The <strong>Offline Package Drop</strong> deployment target makes it possible for Octopus to bundle all the files needed to perform a deployment to a deployment target, even when a direct connection to the deployment target isn’t
                    always possible, for instance, if a security policy, compliance control, or network topology make a direct connection impossible.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    if (lastSegment === "create" && type === "None") {
        return (<CommonTargetRoleTypeOverview>
                <p>
                    A <strong>cloud region</strong> deployment target lets steps that execute on the Octopus Server execute multiple times, with variables scoped for each iteration.
                </p>
            </CommonTargetRoleTypeOverview>);
    }
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Deployment targets</ExternalLink> represent the servers, machines and cloud services where your software and services will be deployed.
                </p>

                <ImageWithPlaceholder src={HelpImageTargets} alt={"Deployment targets"}/>
            </OverviewHighlightText>
            <TargetRolesTip />
        </OverviewContainer>);
};
export const CommonDeploymentTargetTopics = () => {
    const getQueryParam = useQueryParams();
    const lastSegment = getLastRouteSegment(window.location.href);
    const type = getQueryParam("type");
    const deploymentTargetTypeId = getQueryParam("deploymentTargetTypeId");
    const category = getQueryParam("category");
    if (lastSegment === "TentaclePassive" && (category === "Windows" || category === "Linux")) {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href={`${category}ListeningTentacle`}>{category} Listening Tentacle setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "Ssh") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="SSHTarget">SSH target setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "AzureWebApp") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="AzureWebAppTarget">Azure Web App target setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "AzureServiceFabricCluster") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="AzureServiceFabricClusterTarget">Azure Service Fabric Cluster target setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "AzureCloudService") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="AzureServiceTargets">Azure Cloud Service target setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "StepPackage" && deploymentTargetTypeId === "aws-ecs-target") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="AmazonECSCluster">Amazon ECS Cluster target setup guide</ExternalLink>
                <ExternalLink href="CloudTargetDiscovery">Cloud Target Discovery setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "Kubernetes") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="KubernetesTarget">Kubernetes cluster setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "OfflineDrop") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="OfflinePackageDrop">Offline package drop setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    if (lastSegment === "create" && type === "None") {
        return (<CommonDeploymentTargetsTopics>
                <ExternalLink href="CloudRegionTargets">Cloud region setup guide</ExternalLink>
            </CommonDeploymentTargetsTopics>);
    }
    return (<TopicsContainer>
            <LearnMoreResources>
                <Stack>
                    <ExternalLink href="ListeningTentacle">Windows targets</ExternalLink>
                    <ExternalLink href="SSHTargets">Linux targets</ExternalLink>
                    <ExternalLink href="AzureTargets">Azure targets</ExternalLink>
                    <ExternalLink href="AmazonECSCluster">AWS targets</ExternalLink>
                    <ExternalLink href="KubernetesTarget">Kubernetes cluster</ExternalLink>
                    <ExternalLink href="OfflinePackageDrop">Offline package drops</ExternalLink>
                    <ExternalLink href="CloudRegionTargets">Cloud regions</ExternalLink>
                </Stack>
            </LearnMoreResources>
        </TopicsContainer>);
};
const CommonTargetRoleTypeOverview = ({ children }: {
    children: React.ReactNode;
}) => (<OverviewContainer>
        <OverviewHighlightText>
            {children}
            <p>To let your deployment or runbook process reference this target, use target roles.</p>
        </OverviewHighlightText>
        <TargetRolesTip />
    </OverviewContainer>);
const CommonDeploymentTargetsTopics = ({ children }: {
    children: React.ReactNode;
}) => (<TopicsContainer>
        <LearnMoreResources>
            <Stack>
                {children}
                <ExternalLink href="OnboardingDeploymentTargetsLearnMore">Deployment targets</ExternalLink>
            </Stack>
        </LearnMoreResources>
    </TopicsContainer>);
const TargetRolesTip = () => (<TermsText>
        <Note heading={"Target roles"}>
            Multiple deployment targets can exist with an environment and these targets can be tagged with <ExternalLink href="TargetRoles">target roles</ExternalLink>. Target roles are used during the deployment process to specify which targets are
            to be deployed to.
        </Note>
    </TermsText>);
type ListeningTentacleCategories = {
    category: "Windows" | "Linux";
};
const ListeningTentaclesOverview = ({ category }: ListeningTentacleCategories) => (<OverviewContainer>
        <YouTubePlayer videoId={category === "Windows" ? "HLpCodiH3Bc" : "aRV7Iyxm880"}/>
        <OverviewHighlightText>
            An <ExternalLink href="OctopusTentacle">Octopus Tentacle</ExternalLink> is a lightweight agent that lets servers securely communicate with the Octopus Server.
        </OverviewHighlightText>
    </OverviewContainer>);
type SshCategories = {
    category: "Mac" | "Linux";
};
const SshOverview = ({ category }: SshCategories) => (<OverviewContainer>
        <OverviewHighlightText>
            An <strong>SSH</strong> deployment target lets Octopus deploy directly to {category} servers using SSH.
        </OverviewHighlightText>
    </OverviewContainer>);
const Stack = ({ children }: {
    children: React.ReactNode;
}) => <div className={cx(stackStyles)}>{children}</div>;
const stackStyles = css({
    display: "flex",
    flexDirection: "column",
});
