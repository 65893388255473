/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import type { FeedResource, ArtifactoryGenericFeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import AdvancedFeedCredentialsFormSection, { AuthenticationType } from "~/areas/library/components/ExternalFeeds/AdvancedFeedCredentialsFormSection";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import Note from "~/primitiveComponents/form/Note/Note";
import { Summary, Text } from "../../../../../components/form";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";
class ArtifactoryGenericFeed extends React.Component<FeedEditProps<ArtifactoryGenericFeedResource>> {
    render() {
        return (<React.Fragment>
                <ExpandableFormSection key="repository" errorKey="repository" title="Repository" summary={Summary.summary(this.props.feed.Repository)} help="The name of the artifactory generic repository">
                    <Text value={this.props.feed.Repository} onChange={(Repository) => this.props.onChange({ ...this.props.feed, Repository })} label="Repository" error={this.props.getFieldError("Repository")}/>
                </ExpandableFormSection>
                <ExpandableFormSection key="layoutRegex" errorKey="layoutRegex" title="Layout Regex" summary={Summary.summary(this.props.feed.LayoutRegex)} help="The Artifact Path Pattern Regex for the repository.">
                    <Text value={this.props.feed.LayoutRegex} onChange={(LayoutRegex) => this.props.onChange({ ...this.props.feed, LayoutRegex })} label="Layout Regex" error={this.props.getFieldError("LayoutRegex")}/>
                    <Note>
                        This can be found in Artifactory under (Administration ➜ Layouts ➜ Regular Expression View ➜ Resolve)
                        <br />
                        Default path pattern: <code>{this.defaultPattern}</code>
                        <br />
                        For more information see <ExternalLink href="ArtifactoryGenericLayouts">Artifactory Generic Layouts</ExternalLink>
                    </Note>
                </ExpandableFormSection>
                <AdvancedFeedCredentialsFormSection model={{ username: this.props.feed.Username, password: this.props.feed.Password }} isNew={!this.props.feed.Id} onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })} supportedAuthenticationTypes={[AuthenticationType.UsernameAndPassword, AuthenticationType.Token]} notes={<Note>
                            Artifactory Generic Feeds use the Artifactory Query Language (AQL) which requires admin access. <br />
                            For more information on Token authentication see <ExternalLink href="ArtifactoryAccessToken">Artifactory Access Tokens.</ExternalLink>
                        </Note>}/>
            </React.Fragment>);
    }
    defaultPattern = "(?<orgPath>.+?)/(?<module>[^/]+)/(?<module>\\2)-(?<baseRev>[^/]+?)\\.(?<ext>(?:(?!\\d))[^\\-/]+|7z)";
    static displayName = "ArtifactoryGenericFeed";
}
feedTypeRegistry.registerFeedType({
    type: FeedType.ArtifactoryGeneric,
    edit: ArtifactoryGenericFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "Artifactory Generic Feed",
    uriDefault: "https://{my-artifactory-instance}.jfrog.io/",
    callout: (<Callout title="Early Access" type={CalloutType.Warning}>
            This feature is still in development and is subject to change in the future. We'd love to hear <ExternalLink href={"ArtifactoryGenericFeedsFeedbackForm"}> your feedback</ExternalLink>.
        </Callout>),
});
