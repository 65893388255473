import * as React from "react";
import { Switch } from "react-router-dom";
import { MigratedNonVcsDeploymentSettingsRoute } from "~/areas/projects/components/DeploymentProcessSettings/Routing/DeploymentSettingsRoute";
import BranchAwareRedirect from "~/areas/projects/components/ProjectsRoutes/BranchAwareRedirect";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { MigratedChannelsRoute } from "../Channels/ChannelsRoute";
import { DeploymentsOverview } from "../DeploymentsOverview";
import { ProjectDeploymentProcessRoutes } from "../Process/Routing/ProcessRoute";
import { ReleasesRoute } from "../Releases";
export const DeploymentsOverviewPage = withPage({ page: pageIds.project().overview })(DeploymentsOverview);
export function DeploymentsRoute() {
    const projectRouteLinks = routeLinks.projectRoutes();
    return (<Switch>
            <MigratedChannelsRoute path={projectRouteLinks.deployments.channels}/>
            <MigratedReloadableRoute path={projectRouteLinks.deployments.releases} render={() => <ReleasesRoute />}/>
            <MigratedReloadableRoute path={projectRouteLinks.deployments.process.root} render={() => (<BranchAwareRedirect>
                        <ErrorContextProvider>
                            <ProjectDeploymentProcessRoutes />
                        </ErrorContextProvider>
                    </BranchAwareRedirect>)}/>
            <MigratedReloadableRoute path={projectRouteLinks.deployments.settings} render={() => (<BranchAwareRedirect>
                        <ErrorContextProvider>
                            <MigratedNonVcsDeploymentSettingsRoute />
                        </ErrorContextProvider>
                    </BranchAwareRedirect>)}/>
            <MigratedReloadableRoute path={projectRouteLinks.deployments.root} render={() => <DeploymentsOverviewPage />}/>
        </Switch>);
}
export default DeploymentsRoute;
