import type { SigningKeyConfigurationResource, SigningKeyResource } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout";
import SidebarLayout from "~/components/SidebarLayout";
import SimpleDataTable from "~/components/SimpleDataTable";
import { Note } from "~/components/form";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import DateFormatter from "~/utils/DateFormatter";
import { EditSigningKeyConfiguration } from "./EditSigningKeyConfiguration";
interface SigningKeysPageState extends DataBaseComponentState {
    signingKeys?: SigningKeyResource[];
    showRotateConfirmation: boolean;
    configuration?: SigningKeyConfigurationResource;
}
class SigningKeyTable extends SimpleDataTable<SigningKeyResource> {
}
export class SigningKeysPage extends DataBaseComponent<{}, SigningKeysPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            showRotateConfirmation: false,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            this.loadingConfiguration();
            this.loadSigningKeys();
        });
    }
    loadingConfiguration = async () => {
        const getSigningKeyConfiguration = repository.SigningKeyConfiguration.get();
        this.setState({ configuration: await getSigningKeyConfiguration });
    };
    loadSigningKeys = async () => {
        const signingKeys = await repository.SigningKeys.all();
        this.setState({ signingKeys });
    };
    handleRotate = async () => {
        this.doBusyTask(async () => {
            await repository.SigningKeys.rotate();
            await this.loadSigningKeys();
        });
    };
    handleRevoke = async (signingKey: SigningKeyResource) => {
        this.doBusyTask(async () => {
            await repository.SigningKeys.revoke(signingKey.Id);
            await this.loadSigningKeys();
        });
    };
    getOverflowMenuItems = (signingKey: SigningKeyResource) => {
        if (!signingKey.Expired) {
            return;
        }
        const revoke = OverflowMenuItems.dialogItem("Revoke", <OkDialogLayout title={"Revoke Signing Key"} errors={this.errors} okButtonLabel="Revoke" onOkClick={() => {
                this.handleRevoke(signingKey);
                return true;
            }}>
                <p>Are you sure you would like to revoke this signing key?</p>
                <Callout title={"Revoking a signing key is permanent. There is no going back."} type={CalloutType.Warning}/>
            </OkDialogLayout>);
        return <OverflowMenu menuItems={compact([revoke])}/>;
    };
    renderRow = (signingKey: SigningKeyResource) => {
        return [
            signingKey.Expired ? "Expired" : "Active",
            signingKey.Id,
            DateFormatter.dateToShortFormat(signingKey.Created),
            signingKey.Expired ? DateFormatter.dateToShortFormat(signingKey.Expired) : <i>(Rotating on {this.calculateNextRotation()})</i>,
            this.getOverflowMenuItems(signingKey),
        ];
    };
    calculateNextRotation = () => {
        const { signingKeys, configuration } = this.state;
        const activeKey = (signingKeys ?? []).find((k) => !k.Expired);
        if (!activeKey || !configuration) {
            return null;
        }
        const date = new Date(activeKey.Created);
        date.setDate(date.getDate() + configuration.ExpireAfterDays);
        return DateFormatter.dateToShortFormat(date.toLocaleString());
    };
    toDaysString = (days: number) => {
        return `${days} ${days > 1 ? "days" : "day"}`;
    };
    renderSideBar = () => {
        const { configuration } = this.state;
        return (configuration && (<div>
                    <h4>Configuration</h4>
                    <p>
                        The active signing key will be rotated every <b>{this.toDaysString(configuration.ExpireAfterDays)}</b>.
                    </p>
                    <p>
                        Expired keys will be revoked <b>{this.toDaysString(configuration.RevokeAfterDays)}</b> after they expire.
                        <Note>Until then they will continue to validate incoming requests.</Note>
                    </p>
                    <OpenDialogButton label="Change">
                        <EditSigningKeyConfiguration onSaveDone={(configuration) => this.setState({ configuration })}/>
                    </OpenDialogButton>
                </div>));
    };
    getPrimaryAction = (): PrimaryPageAction => {
        return {
            type: "dialog",
            disabled: this.state.busy,
            label: "Rotate Signing Key",
            renderDialog: ({ isOpen, closeDialog }) => (<ConfirmationDialog title="Rotate Signing Key" continueButtonLabel="Rotate" open={isOpen} onClose={closeDialog} onContinueClick={async () => {
                    await this.handleRotate();
                    return true;
                }}>
                    <p>Are you sure you want to continue?</p>
                    <Callout title="This will expire the active signing key and create a new one" type={CalloutType.Information}>
                        <p>Expired signing keys will continue to validate incoming requests until revoked.</p>
                    </Callout>
                </ConfirmationDialog>),
        };
    };
    render() {
        return (<PaperLayout title="Signing Keys" primaryAction={this.getPrimaryAction()} busy={this.state.busy} errors={this.errors}>
                <SidebarLayout sideBar={this.renderSideBar()}>{this.state.signingKeys && <SigningKeyTable data={this.state.signingKeys} headerColumns={["State", "Id", "Created", "Expired", ""]} onRow={this.renderRow}/>}</SidebarLayout>
            </PaperLayout>);
    }
    static displayName = "SigningKeysPage";
}
