import { css, cx } from "@emotion/css";
import { LinearProgress, MenuItemButton, MenuList } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import BusyFromPromise from "~/components/BusyFromPromise";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import { ErrorPanel } from "~/components/form";
import { useNotifyGlobalSearchItemSelected } from "~/globalSearch/analytics/useNotifyGlobalSearchOptionSelected";
import useLocalStorage from "~/hooks/useLocalStorage";
import styles from "./GlobalSearch.module.less";
import type { GlobalSearchResult } from "./GlobalSearchHelpers";
import { EmptyGlobalSearchResults, getRedirectUrl, IsPageSearchResult, NoKeywordGuidance, PageSearchResultListItem, SearchItemCount, SpaceSearchResultListItem, usePageSearch, useSpaceSearch } from "./GlobalSearchHelpers";
interface GlobalSearchListProps {
    items: GlobalSearchResult[];
    keyword: string;
    onClick: (item: GlobalSearchResult) => void;
    busy?: Promise<unknown> | boolean;
}
export interface GlobalSearchRef {
    focusFirstItem: () => void;
}
const GlobalSearchList = forwardRef<GlobalSearchRef, GlobalSearchListProps>(({ items, keyword, onClick, busy }, ref) => {
    const urlResolver = useUrlResolver();
    const notifyGlobalSearchItemSelected = useNotifyGlobalSearchItemSelected();
    const firstItemRef = useRef<HTMLButtonElement | null>(null);
    useImperativeHandle(ref, () => ({ focusFirstItem: () => firstItemRef.current?.focus() }));
    if (items.length === 0)
        return <EmptyGlobalSearchResults busy={busy} keyword={keyword}/>;
    return (<div className={styles.listContainer}>
            <MenuList accessibleName={"Global search"}>
                {items.map((item, index) => {
            if (IsPageSearchResult(item)) {
                return (<MenuItemButton key={item.page.Id} ref={index === 0 ? firstItemRef : undefined} onClick={() => {
                        notifyGlobalSearchItemSelected(item);
                        const redirectTo = getRedirectUrl(item, urlResolver);
                        if (typeof redirectTo === "string") {
                            const goTo = `#${redirectTo}`;
                            window.location.href = goTo;
                            onClick(item);
                        }
                    }}>
                                <PageSearchResultListItem result={item}/>
                            </MenuItemButton>);
            }
            return (<MenuItemButton key={item.Id} ref={index === 0 ? firstItemRef : undefined} onClick={() => {
                    notifyGlobalSearchItemSelected(item);
                    const redirectTo = getRedirectUrl(item, urlResolver);
                    if (typeof redirectTo === "string") {
                        const goTo = `#${redirectTo}`;
                        window.location.href = goTo;
                        onClick(item);
                    }
                    else {
                        logger.error("Attempted to link search result of non-linkable document type for '{item}' in global search.", { item });
                    }
                }}>
                            <SpaceSearchResultListItem result={item} keyword={keyword}/>
                        </MenuItemButton>);
        })}
            </MenuList>
        </div>);
});
interface GlobalSearchProps {
    keyword: string;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
    handleClose: () => void;
}
type Props = GlobalSearchProps;
const GlobalSearchInternal = forwardRef<GlobalSearchRef, Props>(({ keyword, doBusyTask, busy, errors, handleClose }, ref) => {
    const pageSearchResults = usePageSearch(keyword);
    const spaceSearchResults = useSpaceSearch(keyword, doBusyTask);
    const [showServerResultsOnly, setShowServerResultsOnly] = useLocalStorage("GlobalSearch.showServerResultsOnly", false);
    const allResults = React.useMemo(() => {
        if (showServerResultsOnly) {
            return [...spaceSearchResults];
        }
        // We put page search results first because they are in-memory searches and won't cause
        // jumping-of-the-UI (like the spaceSearchResults will if they go first).
        return [...pageSearchResults, ...spaceSearchResults];
    }, [pageSearchResults, spaceSearchResults, showServerResultsOnly]);
    return (<div className={cx(styles.root, rootShadowStyles)}>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} scrollToPanel={false}/>}
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>
            {keyword ? <SearchItemCount items={allResults} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly}/> : <NoKeywordGuidance />}
            <div className={styles.tabContainer}>
                <GlobalSearchList ref={ref} keyword={keyword} items={allResults} onClick={(item) => {
            handleClose();
        }} busy={busy}/>
            </div>
        </div>);
});
const GlobalSearch = GlobalSearchInternal;
const rootShadowStyles = css({
    boxShadow: themeTokens.shadow.medium,
});
export default GlobalSearch;
