import type { TenantResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Card from "~/components/Card/Card";
import Logo from "~/components/Logo/Logo";
import Tag from "~/components/Tag/index";
import type { TagIndex } from "~/components/tenantTagsets";
import InternalLink from "../../../components/Navigation/InternalLink/InternalLink";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import styles from "./style.module.less";
interface TenantCardProps {
    tenant: TenantResource;
    hasMissingVariables: boolean;
    tagIndex: TagIndex;
}
const TenantCard: React.StatelessComponent<TenantCardProps> = (props) => {
    const tenant = props.tenant;
    return (<Card logo={<div className={styles.logo}>
                    <Logo url={tenant.Links.Logo}/>
                    <div className={styles.missingIconContainer}>
                        <div className={styles.missingIconInner}>
                            <MissingVariablesIcon show={props.hasMissingVariables}/>
                        </div>
                    </div>
                </div>} header={tenant.Name} content={tenant.TenantTags && tenant.TenantTags.map((tt) => <Tag key={tt} description={props.tagIndex[tt].Description} tagName={props.tagIndex[tt].Name} tagColor={props.tagIndex[tt].Color} small={true}/>)} link={<InternalLink to={links.tenantOverviewPage.generateUrl({ spaceId: tenant.SpaceId, tenantId: tenant.Id })}/>}/>);
};
TenantCard.displayName = "TenantCard"
export default TenantCard;
