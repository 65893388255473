import React from "react";
import { Switch, type RouteComponentProps } from "react-router";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { AddGitHubConnectionPage } from "./AddGitHubConnectionPage";
import { GitConnectionsPage } from "./GitConnectionsPage";
import { GitHubConnectionPage } from "./GitHubConnectionPage";
const GitHubAppConnectionPageWithPageDefinition = withPage({ page: pageIds.library.gitConnections.getGitHub })(GitHubConnectionPage);
const AddGitHubAppConnectionPageWithPageDefinition = withPage({ page: pageIds.library.gitConnections.addGitHub })(AddGitHubConnectionPage);
const GitConnectionListPageWithPageDefinition = withPage({ page: pageIds.library.gitConnections.root })(GitConnectionsPage);
export class GitConnectionRoutes extends React.Component<RouteComponentProps> {
    render() {
        const libraryRouteLinks = routeLinks.library;
        return (<Switch>
                <MigratedReloadableRoute path={libraryRouteLinks.gitConnections.getGitHubConnection(":connectionId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            connectionId: string;
        }>) => <GitHubAppConnectionPageWithPageDefinition spaceId={match.params.spaceId} connectionId={match.params.connectionId}/>}/>
                <MigratedReloadableRoute path={libraryRouteLinks.gitConnections.addGitHubConnection(":installationId")} render={({ match }: RouteComponentProps<{
            spaceId: string;
            installationId: string;
        }>) => <AddGitHubAppConnectionPageWithPageDefinition spaceId={match.params.spaceId} installationId={match.params.installationId}/>}/>
                <MigratedReloadableRoute render={({ match }: RouteComponentProps<{
            spaceId: string;
        }>) => <GitConnectionListPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
            </Switch>);
    }
    static displayName = "GitConnectionRoutes";
}
export default GitConnectionRoutes;
