/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { NavigationSideBarLayoutVNext } from "~/components/NavigationSideBarLayoutVNext/NavigationSideBarLayoutVNext";
import type { NavItem, NavLink } from "~/components/NavigationSidebarLayout";
import NavigationSidebarLayout, { Navigation } from "~/components/NavigationSidebarLayout";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import PaperLayout from "~/components/PaperLayout";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import styles from "./style.module.less";
export function InfrastructureLayout({ children }: React.PropsWithChildren<{}>) {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const isPageHeaderVNextEnabledOutsideOfProjects = useIsPageHeaderVNextEnabledOutsideOfProjects();
    if (isPageHeaderVNextEnabledOutsideOfProjects) {
        return (<main>
                <NavigationSideBarLayoutVNext navItems={getVNextNavItems(dynamicEnvironmentsEnabled)} content={children} header={<PageHeaderPrimary title={"Infrastructure"}/>}/>
            </main>);
    }
    return (<main>
            <AreaTitle link={links.infrastructureRootRedirect.generateUrl({ spaceId: repository.spaceId! })} title="Infrastructure"/>
            <NavigationSidebarLayout navLinks={getNavItems(dynamicEnvironmentsEnabled)} content={children}/>
        </main>);
}
function getVNextNavItems(dynamicEnvironmentsEnabled: boolean): NavLink[] {
    const navLinks: Array<NavLink | null> = [];
    navLinks.push(...overviewAndDeploymentTargetLinks());
    if (dynamicEnvironmentsEnabled) {
        navLinks.push(createStaticEnvironmentsNavItem("Environments"));
        navLinks.push(createDynamicEnvironmentsNavItem("Dynamic Environments"));
    }
    else {
        navLinks.push(createStaticEnvironmentsNavItem("Environments"));
    }
    navLinks.push(...extraLinks());
    return compact(navLinks);
}
function getNavItems(dynamicEnvironmentsEnabled: boolean): NavItem[] {
    const navLinks: Array<NavItem | null> = [];
    navLinks.push(...overviewAndDeploymentTargetLinks());
    if (dynamicEnvironmentsEnabled) {
        const environmentsNavItems = compact([createStaticEnvironmentsNavItem(<IndentedNavItemLink label="Static"/>), createDynamicEnvironmentsNavItem(<IndentedNavItemLink label="Dynamic"/>)]);
        const environmentsGroup = Navigation.navGroup("Environments", links.infrastructureEnvironmentsPage.generateUrl({ spaceId: repository.spaceId! }), environmentsNavItems, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true }, true);
        navLinks.push(environmentsGroup);
    }
    else {
        navLinks.push(createStaticEnvironmentsNavItem("Environments"));
    }
    navLinks.push(...extraLinks());
    return compact(navLinks);
}
function overviewAndDeploymentTargetLinks(): (NavLink | null)[] {
    return [
        Navigation.navItem("Overview", links.infrastructureOverviewPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true }),
        Navigation.navItem("Deployment Targets", links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.MachineView], wildcard: true }),
    ];
}
function extraLinks(): (NavLink | null)[] {
    return [
        Navigation.navItem("Workers", links.workerMachinesPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.WorkerView], wildcard: true }),
        Navigation.navItem("Worker Pools", links.workerPoolsPage.generateUrl({ spaceId: repository.spaceId! }), undefined),
        Navigation.navItem("Machine Policies", links.machinePoliciesPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: Permission.MachinePolicyView, wildcard: true }),
        Navigation.navItem("Machine Proxies", links.proxiesPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: Permission.ProxyView, wildcard: true }),
        Navigation.navItem("Accounts", links.infrastructureAccountsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: Permission.AccountView, wildcard: true }),
    ];
}
function IndentedNavItemLink(props: {
    label: string | JSX.Element;
}) {
    return <div className={styles.indentedNavItemLinkContainer}>{props.label}</div>;
}
function createStaticEnvironmentsNavItem(label: string | JSX.Element) {
    return Navigation.navItem(label, links.infrastructureEnvironmentsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true });
}
function createDynamicEnvironmentsNavItem(label: string | JSX.Element) {
    return Navigation.navItem(label, links.dynamicEnvironmentsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true });
}
export const InfrastructureLayoutBusy: React.FC<PaperLayoutProps> = (props: PaperLayoutProps) => {
    return (<InfrastructureLayout>
            <PaperLayout {...props}/>
        </InfrastructureLayout>);
};
InfrastructureLayoutBusy.displayName = "InfrastructureLayoutBusy"
