import { SpotlightOverlay } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import * as React from "react";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import type { SampleProjectTourEvent } from "~/analytics/Analytics";
import { Action, useAnalyticSampleProjectTourDispatch } from "~/analytics/Analytics";
import { SampleProjectCompleteDialog } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectCompleteDialog";
import SampleProjectTourPopover from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourPopover";
import type { SampleProjectTourStep, SampleProjectStepId } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import { GetSampleProjectTourCurrentStepKey } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourUtils";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { useIsPortalUrlActive } from "~/components/Navigation/useIsPortalUrlActive";
import useLocalStorage from "~/hooks/useLocalStorage";
interface SampleProjectTourProps {
    children: ReactNode;
    project: ProjectResource;
}
export type SampleProjectTourContextProps = {
    continueSampleProjectTour: () => void;
    variablePageLoadedWithNoDataLeft: () => void;
    deploymentProcessSaved: (successful: boolean) => void;
    startTour: () => void;
    focusTour: () => void;
    resumeTour: () => void;
    dismissTour: () => void;
    registerStep: (stepId: SampleProjectStepId, ref: HTMLDivElement) => void;
    removeRegisteredStep: (stepId: SampleProjectStepId) => void;
    tourState: SampleProjectTourState;
    stepIndex: number;
    totalStepCount: number;
    dispatchAnalyticsEventForCurrentState: (eventName: string, action: Action, resource: string) => void;
};
const getInitialSampleProjectTourState = (savedTourStep: number | undefined): SampleProjectTourState => {
    if (savedTourStep === undefined) {
        return "Uninitialized";
    }
    return savedTourStep === 0 ? "NotStarted" : "Suspended";
};
export const SampleProjectTourContext = createContext<SampleProjectTourContextProps | undefined>(undefined);
type SampleProjectTourState = "Uninitialized" | "NotStarted" | "Focused" | "Transition" | "Unfocused" | "Suspended" | "Dismissed" | "EndDialog" | "Finished";
const InProgressStates: SampleProjectTourState[] = ["Focused", "Unfocused", "Transition", "Suspended"];
function SampleProjectTour({ project, children }: SampleProjectTourProps) {
    const navigation = useSpaceAwareNavigation();
    const location = useLocation();
    const dispatchAction = useAnalyticSampleProjectTourDispatch(project.Id);
    const isUrlActive = useIsPortalUrlActive();
    const [sampleProjectTourSavedCurrentStep, setSampleProjectTourSavedCurrentStep] = useLocalStorage<number | undefined>(GetSampleProjectTourCurrentStepKey(project.Slug), undefined);
    const [stepRefs, setStepRefs] = useState<Map<SampleProjectStepId, HTMLDivElement>>(new Map<SampleProjectStepId, HTMLDivElement>());
    const [hasStepRefs, setHasStepRefs] = useState<boolean>(false);
    const [tourState, setTourState] = useState<SampleProjectTourState>(getInitialSampleProjectTourState(sampleProjectTourSavedCurrentStep));
    const [stepIndex, setStepIndex] = useState<number>(sampleProjectTourSavedCurrentStep ?? 0);
    const steps: SampleProjectTourStep[] = [
        {
            title: "Get visibility on your software releases",
            content: "This dashboard shows your recent software releases. Track what version of your software is running where and quickly spot deployment failures.",
            infoCallout: (<>
                    <b>Tip:</b> Click a successful or failed release to view its task summary and log.
                </>),
            popoverPlacement: {
                placementWideLongScreen: "bottom-start",
                placementWideShortScreen: "right-start",
                placementNarrowLongScreen: "bottom-start",
                placementNarrowShortScreen: "top-end",
            },
            target: `DeploymentOverview`,
            scrollOffset: () => 0,
            beaconOffset: () => ({ x: 0, y: 0 }),
            route: links.deploymentsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        },
        {
            title: "Deploy the same way to all your environments",
            content: "Consistently and confidently deploy to all environments in your software's lifecycle with one repeatable deployment process.",
            infoCallout: (<>
                    <b>Tip:</b> You can have any number of environments and deploy to any target type - cloud, data centers, Kubernetes, and on-premises servers.
                </>),
            popoverPlacement: {
                placementWideLongScreen: "bottom-start",
                placementWideShortScreen: "bottom-start",
                placementNarrowLongScreen: "bottom-start",
                placementNarrowShortScreen: "bottom-start",
            },
            target: "DeploymentProcessEnvironments",
            scrollOffset: () => 0,
            beaconOffset: () => {
                if (window.innerWidth < 1500) {
                    return { x: 0, y: -24 };
                }
                return { x: 0, y: 0 };
            },
            route: links.deploymentsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        },
        {
            title: "Define your deployment process",
            content: "Our extensive step template library lets you define how you deploy your software across your infrastructure without writing and sifting through thousands of lines of code.",
            infoCallout: (<>
                    <b>Tip:</b> Try adding a step to browse our library of over 500 step templates.
                </>),
            popoverPlacement: {
                placementWideLongScreen: "right-end",
                placementWideShortScreen: "right-end",
                placementNarrowLongScreen: "right-end",
                placementNarrowShortScreen: "top-end",
            },
            target: "DeploymentProcess",
            scrollOffset: () => {
                if (window.innerWidth > 1550)
                    return 0;
                return 150;
            },
            beaconOffset: () => ({ x: 0, y: -12 }),
            route: links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        },
        {
            title: "Manage variables across your infrastructure",
            content: "Variables are a powerful tool that lets you securely store and manage sensitive keys and configuration values across your infrastructure.",
            infoCallout: (<>
                    <b>Tip:</b> Use variables to avoid manually updating hard-coded configuration settings as you deploy across environments.
                </>),
            popoverPlacement: {
                placementWideLongScreen: "bottom-start",
                placementWideShortScreen: "top-end",
                placementNarrowLongScreen: "top-end",
                placementNarrowShortScreen: "top-end",
            },
            target: "Variable",
            scrollOffset: () => {
                if (window.innerWidth < 1500)
                    return 0;
                return 340;
            },
            beaconOffset: () => ({ x: 0, y: 0 }),
            route: links.variablesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        },
        {
            title: "Perform routine and on-demand operations tasks",
            content: "Runbooks use our powerful step template library and let you perform routine and emergency operations tasks with one click. You can schedule runbooks or start them on-demand.",
            infoCallout: (<>
                    <b>Tip:</b> You can set permissions so anyone on your team can start a runbook without access to credentials.
                </>),
            popoverPlacement: {
                placementWideLongScreen: "bottom-start",
                placementWideShortScreen: "top-end",
                placementNarrowLongScreen: "bottom-start",
                placementNarrowShortScreen: "top-end",
            },
            target: "Runbooks",
            scrollOffset: () => 120,
            beaconOffset: () => ({ x: 0, y: 0 }),
            route: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
        },
    ];
    const dispatchAnalyticsEventForCurrentState = (eventName: string, action: Action, resource: string) => {
        const currentStep = steps[stepIndex];
        const eventData: SampleProjectTourEvent = {
            action,
            resource,
            tourState,
            tourStepIndex: stepIndex,
            tourStepTitle: currentStep.title,
            tourStepTarget: currentStep.target,
        };
        dispatchAction(eventName, eventData);
    };
    useEffect(() => {
        if (!InProgressStates.find((state) => tourState === state)) {
            return;
        }
        const isOnStepRoute = isUrlActive(steps[stepIndex].route, true);
        if (!isOnStepRoute) {
            setTourState("Suspended");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, stepIndex]);
    useEffect(() => {
        if (tourState === "Focused") {
            dispatchAnalyticsEventForCurrentState("Sample Project Tour: Popover Viewed", Action.View, "Sample Project Tour Viewed");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourState, stepIndex]);
    const startTour = () => {
        if (tourState !== "NotStarted")
            return;
        setTourState("Focused");
        setStepIndex(0);
        calculateScrollOffset();
        checkIfRefExistsForStep(0);
    };
    const continueSampleProjectTour = () => {
        if (tourState !== "Transition")
            return;
        setTourState("Focused");
        setStepIndex(stepIndex);
        checkIfRefExistsForStep(stepIndex);
        calculateScrollOffset();
    };
    const variablePageLoadedWithNoDataLeft = () => {
        if (!InProgressStates.find((state) => tourState === state)) {
            return;
        }
        if (stepIndex !== steps.findIndex((step) => step.target === "Variable")) {
            return;
        }
        dispatchAnalyticsEventForCurrentState("Sample Project Tour: No Data for Step", Action.Toggle, "Sample Project Tour");
        finishTour();
    };
    const deploymentProcessSaved = (successful: boolean) => {
        if (tourState !== "Transition")
            return;
        if (successful) {
            navigation.navigate(steps[stepIndex].route);
        }
        else {
            setTourState("Suspended");
        }
    };
    const checkIfRefExistsForStep = (stepIndexToCheck: number) => {
        setTimeout(() => {
            const highlightStepRef = stepRefs.get(steps[stepIndexToCheck].target);
            if (highlightStepRef === undefined) {
                dispatchAnalyticsEventForCurrentState("Sample Project Tour: No Data for Step", Action.Toggle, "Sample Project Tour");
                finishTour();
            }
        }, 100);
    };
    const nextStep = () => {
        if (stepIndex < steps.length - 1) {
            dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click Next", Action.Toggle, "Sample Project Tour Next");
            const nextStepIndex = stepIndex + 1;
            setStepIndex(nextStepIndex);
            handleTransitionToStep(nextStepIndex);
            setSampleProjectTourSavedCurrentStep(nextStepIndex);
            const nextStepRefElement = stepRefs.get(steps[nextStepIndex].target);
            if (nextStepRefElement !== undefined) {
                calculateScrollOffset();
            }
        }
        else {
            finishTour();
        }
    };
    const previousStep = () => {
        if (stepIndex > 0) {
            dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click Back", Action.Toggle, "Sample Project Tour Back");
            const nextStepIndex = stepIndex - 1;
            setStepIndex(nextStepIndex);
            handleTransitionToStep(nextStepIndex);
            setSampleProjectTourSavedCurrentStep(nextStepIndex);
        }
    };
    const handleTransitionToStep = (nextStepIndex: number) => {
        const nextStepRefElement = stepRefs.get(steps[nextStepIndex].target);
        // will be undefined if the element we need to highlight isn't on the current page, so we need to navigate to that page first
        if (nextStepRefElement === undefined) {
            setTourState("Transition");
            // Remove all the registered elements, as they will no longer be on the page. This will support navigating back.
            setStepRefs((stepRefs) => {
                stepRefs.clear();
                return stepRefs;
            });
            setHasStepRefs(false);
            navigation.navigate(steps[nextStepIndex].route);
        }
    };
    const calculateScrollOffset = () => {
        const scrollOffset = steps[stepIndex].scrollOffset() ?? 0;
        document.documentElement.scrollTop = scrollOffset;
        document.body.scrollTop = scrollOffset;
    };
    const unfocusTour = () => {
        setTourState("Unfocused");
    };
    const focusTour = () => {
        setTourState("Focused");
    };
    const pauseTour = () => {
        dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click 'X' to Dismiss Popover", Action.Dismiss, "Sample Project Tour Dismiss");
        setTourState("Suspended");
    };
    const resumeTour = () => {
        const currentElementRef = stepRefs.get(steps[stepIndex].target);
        if (currentElementRef === undefined) {
            handleTransitionToStep(stepIndex);
        }
        else {
            setTourState("Focused");
            calculateScrollOffset();
        }
    };
    const dismissTour = () => {
        setTourState("Dismissed");
        localStorage.removeItem(GetSampleProjectTourCurrentStepKey(project.Slug));
    };
    const finishTour = () => {
        setTourState("EndDialog");
        localStorage.removeItem(GetSampleProjectTourCurrentStepKey(project.Slug));
    };
    const closeCompleteDialog = () => {
        setTourState("Finished");
        localStorage.removeItem(GetSampleProjectTourCurrentStepKey(project.Slug));
    };
    const registerStep = (stepId: SampleProjectStepId, ref: HTMLDivElement) => {
        setStepRefs((refs) => {
            refs.set(stepId, ref);
            return refs;
        });
        setHasStepRefs(true);
    };
    const removeRegisteredStep = (stepId: SampleProjectStepId) => {
        setStepRefs((refs) => {
            refs.clear();
            return refs;
        });
        setHasStepRefs(false);
    };
    const onOverlayClick = () => {
        dispatchAnalyticsEventForCurrentState("Sample Project Tour: Click Away From Popover", Action.Toggle, "Sample Project Tour Overlay");
        unfocusTour();
    };
    return (<SampleProjectTourContext.Provider value={{
            startTour,
            registerStep,
            removeRegisteredStep,
            resumeTour,
            dismissTour,
            continueSampleProjectTour,
            variablePageLoadedWithNoDataLeft,
            tourState,
            stepIndex,
            totalStepCount: steps.length,
            focusTour,
            dispatchAnalyticsEventForCurrentState,
            deploymentProcessSaved,
        }}>
            <FeatureToggleVisibility toggle={"SampleProjectTourToggle"} disabledContent={children}>
                {tourState === "Uninitialized" ? (children) : (<>
                        <SampleProjectCompleteDialog project={project} open={tourState === "EndDialog"} onCloseDialog={closeCompleteDialog}/>
                        <SpotlightOverlay target={stepRefs.get(steps[stepIndex].target)} onOverlayClick={onOverlayClick} show={tourState === "Focused"}/>
                        {(tourState === "Focused" || tourState === "Unfocused") && (<SampleProjectTourPopover anchorEl={stepRefs.get(steps[stepIndex].target) ?? null} step={steps[stepIndex]} onNext={nextStep} onBack={previousStep} currentStep={stepIndex + 1} totalSteps={steps.length} open={tourState === "Focused"} onClose={pauseTour} onFinish={finishTour}/>)}
                        {children}
                    </>)}
            </FeatureToggleVisibility>
        </SampleProjectTourContext.Provider>);
}
export default SampleProjectTour;
