/* eslint-disable @typescript-eslint/init-declarations */
import type { SettingsId } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ReactElement } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import ConfigurationLayout from "~/areas/configuration/components/ConfigurationLayout/ConfigurationLayout";
import DeploymentFreezesListPage from "~/areas/configuration/components/DeploymentFreezes/DeploymentFreezesListPage";
import { DetailedServerLogsPage } from "~/areas/configuration/components/Diagnostics/DetailedServerLogsPage";
import { DiagnosticsPage } from "~/areas/configuration/components/Diagnostics/DiagnosticsPage";
import { AutoDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/AutoDeployLogsPage";
import { ScheduledDeployLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/ScheduledDeployLogsPage";
import { SubscriptionLogsPage } from "~/areas/configuration/components/Diagnostics/Logs/SubscriptionLogsPage";
import { GitPage } from "~/areas/configuration/components/Git/GitPage";
import { ConfigureLetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/ConfigureLetsEncryptPage";
import { LetsEncryptPage } from "~/areas/configuration/components/LetsEncrypt/LetsEncryptPage";
import { LicensePage } from "~/areas/configuration/components/License/LicensePage";
import { MaintenancePage } from "~/areas/configuration/components/Maintenance/MaintenancePage";
import { NodesPage } from "~/areas/configuration/components/Nodes/NodesPage";
import { ServerSettingsPage } from "~/areas/configuration/components/Nodes/ServerSettingsPage";
import { PerformancePage } from "~/areas/configuration/components/Performance/PerformancePage";
import { EditSettingsPage } from "~/areas/configuration/components/Settings/EditSettingsPage";
import { SettingsListPage } from "~/areas/configuration/components/Settings/SettingsListPage";
import type { TestPermissionsPageProps } from "~/areas/configuration/components/TestPermissions/TestPermissionsPage";
import { TestPermissionsPage } from "~/areas/configuration/components/TestPermissions/TestPermissionsPage";
import { ThumbprintPage } from "~/areas/configuration/components/Thumbprint/ThumbprintPage";
import { client } from "~/clientInstance";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import { withPageFromProps } from "~/components/Page/WithPageFromProps";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { firstAuthorized } from "~/components/PermissionCheck/PermissionCheck";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { AuditArchivePage } from "../AuditLayout/AuditArchivePage";
import { AuditPage } from "../AuditLayout/AuditPage";
import { BackupPage } from "../Backup/BackupPage";
import { ExtensionsPage } from "../Extensions/ExtensionsPage";
import { FeaturesPage } from "../FeaturesLayout/FeaturesPage";
import { UserRolePage } from "../Roles/UserRolePage";
import { UserRolesPage } from "../Roles/UserRolesPage";
import { SigningKeysPage } from "../SigningKeys/SigningKeysPage";
import { SmtpPage } from "../Smtp/SmtpPage";
import { EditSpacePage } from "../Spaces/EditSpacePage";
import { SpacesPage } from "../Spaces/SpacesPage";
import { SubscriptionPage } from "../Subscription/SubscriptionPage";
import { SubscriptionsPage } from "../Subscriptions/SubscriptionsPage";
import { EditTeamPage } from "../Teams/EditTeamPage";
import { RedirectFromScopedRole } from "../Teams/RedirectFromScopedRole";
import { TeamsPage } from "../Teams/TeamsPage";
import { TelemetryPage } from "../Telemetry/TelemetryPage";
import { UserInvitePage } from "../Users/UserInvitePage";
import { EditUserPage, CreateUserPage } from "../Users/UserPage";
import { UsersPage } from "../Users/UsersPage";
export const AuditArchivePageWithPageDefinition = withPage({ page: pageIds.configuration.auditArchive })(AuditArchivePage);
export const AuditPageWithPageDefinition = withPage({ page: pageIds.configuration.auditBaseRoute })(AuditPage);
export const BackupPageWithPageDefinition = withPage({ page: pageIds.configuration.backup })(BackupPage);
export const AutoDeployLogsPageWithPageDefinition = withPage({ page: pageIds.configuration.diagnostics.logs.autoDeploy })(AutoDeployLogsPage);
export const ScheduledDeployLogsPageWithPageDefinition = withPage({ page: pageIds.configuration.diagnostics.logs.scheduledDeploy })(ScheduledDeployLogsPage);
export const SubscriptionLogsPageWithPageDefinition = withPage({ page: pageIds.configuration.diagnostics.logs.subscription })(SubscriptionLogsPage);
export const DetailedServerLogsPageWithPageDefinition = withPage({ page: pageIds.configuration.diagnostics.logs.root })(DetailedServerLogsPage);
export const DiagnosticsPageWithPageDefinition = withPage({ page: pageIds.configuration.diagnostics.root })(DiagnosticsPage);
export const FeaturesPageWithPageDefinition = withPage({ page: pageIds.configuration.features })(FeaturesPage);
export const GitPageWithPageDefinition = withPage({ page: pageIds.configuration.git })(GitPage);
export const LicensePageWithPageDefinition = withPage({ page: pageIds.configuration.license })(LicensePage);
export const ConfigureLetsEncryptPageWithPageDefinition = withPage({ page: pageIds.configuration.letsEncrypt.configure })(ConfigureLetsEncryptPage);
export const LetsEncryptPageWithPageDefinition = withPage({ page: pageIds.configuration.letsEncrypt.root })(LetsEncryptPage);
export const MaintenancePageWithPageDefinition = withPage({ page: pageIds.configuration.maintenance })(MaintenancePage);
export const PerformancePageWithPageDefinition = withPage({ page: pageIds.configuration.performance })(PerformancePage);
export const ServerConfigurationSettingsPageWithPageDefinition = withPage({ page: pageIds.configuration.nodes.serverSettings })(ServerSettingsPage);
export const NodesPageWithPageDefinition = withPage({ page: pageIds.configuration.nodes.root })(NodesPage);
export const SmtpPageWithPageDefinition = withPage({ page: pageIds.configuration.smtp })(SmtpPage);
export const CreateSubscriptionPageWithPageDefinition = withPage({ page: pageIds.configuration.subscriptions.create })(SubscriptionPage);
export const SubscriptionPageWithPageDefinition = withPage({ page: pageIds.configuration.subscription })(SubscriptionPage);
export const SubscriptionsPageWithPageDefinition = withPage({ page: pageIds.configuration.subscriptions.root })(SubscriptionsPage);
export const TestPermissionPageWithPageDefinition = withPage({ page: pageIds.configuration.testPermission })(TestPermissionsPage);
export const TestPermissionsPageWithPageDefinition = withPage({ page: pageIds.configuration.testPermissions })(TestPermissionsPage);
export const ThumbprintPageWithPageDefinition = withPage({ page: pageIds.configuration.thumbprint })(ThumbprintPage);
export const RoleCreatePageWithPageDefinition = withPage({ page: pageIds.configuration.roles.create })(UserRolePage);
export const RoleEditPageWithPageDefinition = withPage({ page: pageIds.configuration.role })(UserRolePage);
export const RolesPageWithPageDefinition = withPage({ page: pageIds.configuration.roles.root })(UserRolesPage);
export const EditSpacePageWithPageDefinition = withPage({ page: pageIds.configuration.space })(EditSpacePage);
export const SpacesPageWithPageDefinition = withPage({ page: pageIds.configuration.spaces.root })(SpacesPage);
export const TeamsPageWithPageDefinition = withPage({ page: pageIds.configuration.teams.root })(TeamsPage);
export const SettingPageWithPageDefinition = withPageFromProps(EditSettingsPage, (props) => ({ page: pageIds.configuration.setting(props.settingId).root }));
export const SettingsPageWithPageDefinition = withPage({ page: pageIds.configuration.settings.root })(SettingsListPage);
export const DeploymentFreezesWithPageDefinition = withPage({ page: pageIds.configuration.deploymentFreeze.root })(DeploymentFreezesListPage);
export const ExtensionsPageWithPageDefinition = withPage({ page: pageIds.configuration.extensions.root })(ExtensionsPage);
export const CreateUserPageWithPageDefinition = withPage({ page: pageIds.configuration.users.create })(CreateUserPage);
export const EditUserPageWithPageDefinition = withPage({ page: pageIds.configuration.user })(EditUserPage);
export const UsersPageWithPageDefinition = withPage({ page: pageIds.configuration.users.root })(UsersPage);
export const SigningKeysPageWithPageDefinition = withPage({ page: pageIds.configuration.signingKeys })(SigningKeysPage);
export const UserInvitePageWithPageDefinition = withPage({ page: pageIds.configuration.userInvites })(UserInvitePage);
export const EditTeamPageWithPageDefinition = withPage({ page: pageIds.configuration.team })(EditTeamPage);
export const TelemetryPageWithPageDefinition = withPage({ page: pageIds.configuration.telemetry })(TelemetryPage);
const ConfigurationRoutes: React.StatelessComponent = (props) => {
    let defaultRoute: ReactElement;
    switch (firstAuthorized([Permission.AdministerSystem, Permission.EventView, Permission.TeamView])) {
        case Permission.AdministerSystem:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.features}/>;
            break;
        case Permission.EventView:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.audit(client.spaceId)}/>;
            break;
        case Permission.TeamView:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.teams.root()}/>;
            break;
        default:
            defaultRoute = <InternalRedirect to={routeLinks.configuration.spaces.root}/>;
    }
    return (<ConfigurationLayout>
            {() => (<Switch>
                    <MigratedReloadableRoute path={routeLinks.configuration.auditArchive} render={() => <AuditArchivePageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.auditBaseRoute} render={() => <AuditPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.backup} render={() => <BackupPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.v3route.certificates}>
                        <InternalRedirect to={routeLinks.configuration.thumbprint}/>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.v3route.usersInvite}>
                        <InternalRedirect to={routeLinks.configuration.userInvites}/>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.v3route.testPermissions}>
                        <InternalRedirect to={routeLinks.configuration.testPermissions}/>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.diagnostics.logs.autoDeploy} render={() => <AutoDeployLogsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.diagnostics.logs.scheduledDeploy} render={() => <ScheduledDeployLogsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.diagnostics.logs.subscription} render={() => <SubscriptionLogsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.diagnostics.logs.root} render={() => <DetailedServerLogsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.diagnostics.logs.machineCleanup}>
                        <InternalRedirect to={routeLinks.configuration.machineEventsForUser("users-system", "Deleted")}/>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.diagnostics.root} render={() => <DiagnosticsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.extensions.root} render={() => <ExtensionsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.features} render={() => <FeaturesPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.letsEncrypt.configure} render={() => <ConfigureLetsEncryptPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.letsEncrypt.root} render={() => <LetsEncryptPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.maintenance} render={() => <MaintenancePageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.performance} render={() => <PerformancePageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.nodes.serverSettings} render={() => <ServerConfigurationSettingsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.nodes.root} render={() => <NodesPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.smtp} render={() => <SmtpPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.subscriptions.create()} render={() => <CreateSubscriptionPageWithPageDefinition create/>}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.subscription(":subscriptionId")} render={(routeProps: RouteComponentProps<{
            subscriptionId: string;
        }>) => <SubscriptionPageWithPageDefinition subscriptionId={routeProps.match.params.subscriptionId}/>}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.subscriptions.root} render={() => <SubscriptionsPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.testPermissions}>
                        <Switch>
                            <MigratedReloadableRoute path={routeLinks.configuration.testPermission(":userId")} render={(routeProps: RouteComponentProps<TestPermissionsPageProps>) => <TestPermissionPageWithPageDefinition userId={routeProps.match.params.userId}/>}/>
                            <MigratedReloadableRoute render={() => <TestPermissionsPageWithPageDefinition userId={undefined}/>}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.thumbprint} render={() => <ThumbprintPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.roles.root}>
                        <Switch>
                            <MigratedReloadableRoute path={routeLinks.configuration.roles.create} render={() => <RoleCreatePageWithPageDefinition roleId={undefined}/>}/>
                            <MigratedReloadableRoute path={routeLinks.configuration.role(":roleId")} render={(routeProps: RouteComponentProps<{
            roleId: string;
        }>) => <RoleEditPageWithPageDefinition roleId={routeProps.match.params.roleId}/>}/>
                            <MigratedReloadableRoute render={() => <RolesPageWithPageDefinition />}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.spaces.root}>
                        <Switch>
                            <MigratedReloadableRoute path={routeLinks.configuration.space(":currentSpaceId")} render={(routeProps: RouteComponentProps<{
            currentSpaceId: string;
        }>) => <EditSpacePageWithPageDefinition currentSpaceId={routeProps.match.params.currentSpaceId}/>}/>
                            <MigratedReloadableRoute render={() => <SpacesPageWithPageDefinition />}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.teams.baseRoute}>
                        <Switch>
                            <MigratedReloadableRoute exact={true} path={routeLinks.configuration.teams.redirect(":scopedRoleId")} render={(routeProps: RouteComponentProps<{
            scopedRoleId: string;
        }>) => <RedirectFromScopedRole scopedRoleId={routeProps.match.params.scopedRoleId}/>}/>
                            <MigratedReloadableRoute path={routeLinks.configuration.team(":teamId")} render={(routeProps: RouteComponentProps<{
            teamId: string;
        }>) => <EditTeamPageWithPageDefinition teamId={routeProps.match.params.teamId}/>}/>
                            <MigratedReloadableRoute render={() => <TeamsPageWithPageDefinition />}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.settings.root}>
                        <Switch>
                            <MigratedReloadableRoute path={routeLinks.configuration.setting(":settingId")} render={(routeProps: RouteComponentProps<{
            settingId: SettingsId;
        }>) => <SettingPageWithPageDefinition settingId={routeProps.match.params.settingId}/>}/>
                            <MigratedReloadableRoute render={() => <SettingsPageWithPageDefinition />}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.deploymentFreezes.root}>
                        <Switch>
                            <MigratedReloadableRoute render={() => <DeploymentFreezesWithPageDefinition />}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.signingKeys} render={() => <SigningKeysPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.users.root}>
                        <Switch>
                            <MigratedReloadableRoute path={routeLinks.configuration.users.create} render={() => <CreateUserPageWithPageDefinition />}/>
                            <MigratedReloadableRoute path={routeLinks.configuration.user(":userId")} render={(routeProps: RouteComponentProps<{
            userId: string;
        }>) => <EditUserPageWithPageDefinition userId={routeProps.match.params.userId}/>}/>
                            <MigratedReloadableRoute render={() => <UsersPageWithPageDefinition />}/>
                        </Switch>
                    </MigratedReloadableRoute>
                    <MigratedReloadableRoute path={routeLinks.configuration.telemetry} render={() => <TelemetryPageWithPageDefinition />}/>
                    <MigratedReloadableRoute path={routeLinks.configuration.userInvites} render={() => <UserInvitePageWithPageDefinition />}/>
                    <MigratedReloadableRoute key="git" path={routeLinks.configuration.git} render={() => <GitPageWithPageDefinition PageLayout={PaperLayout}/>}/>
                    <MigratedReloadableRoute key="license" path={routeLinks.configuration.license} render={() => <LicensePageWithPageDefinition PageLayout={PaperLayout}/>}/>
                    <MigratedReloadableRoute exact={true} path={routeLinks.configuration.root}>
                        {defaultRoute}
                    </MigratedReloadableRoute>
                    <RedirectAs404 />
                </Switch>)}
        </ConfigurationLayout>);
};
ConfigurationRoutes.displayName = "ConfigurationRoutes"
export default ConfigurationRoutes;
