import { css } from "@emotion/css";
import * as React from "react";
interface TenantVariableNameProps {
    name: string;
}
export function TenantVariableName({ name }: TenantVariableNameProps) {
    return <code className={styles.name}>#{`{${name}}`}</code>;
}
const styles = {
    name: css({
        textWrap: "nowrap",
    }),
};
