import type { LegacyRoute, ParsedQueryParams, UnknownQueryParam, Url } from "@octopusdeploy/portal-routes";
export function createLegacyRouteRegistrations<LegacyRoutes extends LegacyRouteMap>(legacyRoutes: LegacyRouteMap, legacyRouteRegistrations: LegacyRouteRegistrationDefinitions<LegacyRoutes>): LegacyRouteRegistrations<LegacyRoutes> {
    const result = Object.entries(legacyRoutes).reduce<Partial<LegacyRouteRegistrations<LegacyRoutes>>>((p, [key, route]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const legacyRouteRegistration: LegacyRouteRegistration<any, any> = {
            route,
            redirect: legacyRouteRegistrations[key],
        };
        return {
            ...p,
            [key]: legacyRouteRegistration,
        };
    }, {});
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return result as LegacyRouteRegistrations<LegacyRoutes>;
}
type LegacyRouteRegistrationDefinitions<LegacyRoutes extends LegacyRouteMap> = {
    [K in keyof LegacyRoutes]: LegacyRoutes[K] extends LegacyRoute<infer RouteParams, infer QueryParams> ? LegacyRouteRegistrationDefinition<RouteParams, QueryParams> : never;
};
type LegacyRouteRegistrationDefinition<RouteParams, QueryParams extends UnknownQueryParam[]> = (routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>) => RedirectTarget;
type LegacyRouteRegistrations<LegacyRoutes extends LegacyRouteMap> = {
    [K in keyof LegacyRoutes]: LegacyRoutes[K] extends LegacyRoute<infer RouteParams, infer QueryParams> ? LegacyRouteRegistration<RouteParams, QueryParams> : never;
};
export interface LegacyRouteRegistration<RouteParams, QueryParams extends UnknownQueryParam[]> {
    route: LegacyRoute<RouteParams, QueryParams>;
    redirect: (routeParams: RouteParams, queryParams: ParsedQueryParams<QueryParams>) => RedirectTarget;
}
// Undefined is interpreted as there being no valid page to redirect to. The system will show a 404 in this case.
export type RedirectTarget = Promise<string | Url | undefined> | string | Url | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownLegacyRoute = LegacyRoute<any, any>;
type LegacyRouteMap = Record<string, UnknownLegacyRoute>;
