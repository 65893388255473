import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer } from "./CommonViews";
export const CommonProjectDeploymentOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                When you <ExternalLink href="OnboardingReleasesLearnMore">Deploy a release</ExternalLink>, you are executing the deployment process with all the associated details, as they existed when the release was created. You can Deploy a
                Release as many times as you want to.
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonProjectDeploymentTopics = () => {
    return <TopicsContainer />;
};
