import { NavigationBar, RadioButton, RadioButtonGroup, SlowIcon, Snackbar, Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticPerformanceFeedbackDispatch } from "~/analytics/Analytics";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { Text } from "~/components/form/index";
export enum FeedbackArea {
    InitialPageLoad = "Initial page load",
    ContentLoad = "Content load",
    Other = "Other",
    NotSelected = "Not selected"
}
export default function PerformanceFeedbackMenu() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { closeDialog, openDialog, isOpen } = useDialogTrigger();
    const onFeedbackSubmitted = () => {
        closeDialog();
        setSnackbarOpen(true);
    };
    return (<>
            <Tooltip content="Report Slowness">
                <NavigationBar.IconButton accessibleName="Report Slowness" icon={<SlowIcon />} onClick={openDialog}/>
            </Tooltip>
            <Dialog open={isOpen}>
                <ReportSlownessDialogLayout onFeedbackSubmitted={onFeedbackSubmitted}/>
            </Dialog>
            <Snackbar open={snackbarOpen} content="Feedback submitted" textAlign="center" autoHideDuration={3500} onClose={() => setSnackbarOpen(false)}/>
        </>);
}
interface ReportSlownessDialogLayoutProps {
    onFeedbackSubmitted: () => void;
}
function ReportSlownessDialogLayout({ onFeedbackSubmitted }: ReportSlownessDialogLayoutProps) {
    const [description, setDescription] = useState("");
    const [slowArea, setSlowArea] = useState<FeedbackArea>(FeedbackArea.NotSelected);
    const [slowAreaError, setSlowAreaError] = useState<string | undefined>(undefined);
    const dispatchFeedback = useAnalyticPerformanceFeedbackDispatch();
    const onSubmitClick = () => {
        if (slowArea === FeedbackArea.NotSelected) {
            setSlowAreaError("Slow area is required");
            return;
        }
        dispatchFeedback("Report Slowness", { resource: "Feedback", slowArea, action: Action.Submit, description });
        onFeedbackSubmitted();
    };
    return (<OkDialogLayout title={"Report Slowness"} onOkClick={onSubmitClick} okButtonLabel="Submit">
            Your feedback helps make Octopus faster and more performant.
            <RadioButtonGroup value={slowArea} onChange={(value) => setSlowArea(value)} error={slowAreaError}>
                <RadioButton value={FeedbackArea.InitialPageLoad} label={<span>{FeedbackArea.InitialPageLoad}</span>}/>
                <RadioButton value={FeedbackArea.ContentLoad} label={<span>{FeedbackArea.ContentLoad}</span>}/>
                <RadioButton value={FeedbackArea.Other} label={<span>{FeedbackArea.Other} (please elaborate)</span>}/>
            </RadioButtonGroup>
            <Text value={description} onChange={(value) => setDescription(value)} multiline={true} placeholder="Please provide any specific details about the slowness you are experiencing" label="Description"/>
        </OkDialogLayout>);
}
