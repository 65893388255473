import { css, cx } from "@emotion/css";
import { Divider, List, RadioButton, RadioButtonGroup, SmsIcon, Tooltip } from "@octopusdeploy/design-system-components";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { ActionTemplateParameterResource, CommonTemplateTenantValue, GetCommonTemplateWithValuesBffResponse, GetProjectTemplatesWithValuesBffResponse, LibraryVariableSetInfo, ProjectResource, ProjectTemplateTenantValue, ValueStatus, WorkerPoolResource, } from "@octopusdeploy/octopus-server-client";
import { isProjectTemplateTenantValue, VariableSetContentType, ControlType, Permission, ValueStatusValues, VariableType } from "@octopusdeploy/octopus-server-client";
import { links, stringQueryParam, useQueryStringParam, VariableSetTabValues } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import cn from "classnames";
import type { Dictionary } from "lodash";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import URI from "urijs";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { AddProjectTemplateButton } from "~/areas/projects/components/Variables/TenantVariables/AddProjectTemplateButton";
import { IncludeLibraryVariableSetsButton } from "~/areas/projects/components/Variables/TenantVariables/IncludeLibraryVariableSetsButton";
import NoCommonTemplateValuesOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoCommonTemplateValuesOnboarding";
import NoTemplatesOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoTemplatesOnboarding";
import NoValuesOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoValuesOnboarding";
import NoVariableSetsOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoVariableSetsOnboarding";
import { TenantVariableName } from "~/areas/projects/components/Variables/TenantVariables/TenantVariableName";
import UpdateTenantVariableDialog from "~/areas/projects/components/Variables/TenantVariables/UpdateTenantVariableDialog";
import { useProjectTenantVariablesFeatureFlag } from "~/areas/projects/components/Variables/TenantVariables/useIsProjectTenantVariablesEnabled";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariablesFilterState";
import { useProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariablesFilterState";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useTenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useProjectContext } from "~/areas/projects/context/index";
import MissingVariablesIcon from "~/areas/tenants/MissingVariablesIcon/MissingVariablesIcon";
import { TenantsDataTableNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableNameCell";
import { FilterBuilder } from "~/areas/tenants/components/Filtering/FilterBuilder/FilterBuilder";
import { createTenantFilter, getExcludedTenantValue, getIncludedTenantValue, createEnvironmentFilter, getIncludedEnvironmentValue, getExcludedEnvironmentValue, createTagSetFilters, getIncludedTagSetValues, getExcludedTagSetValues, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import type { FilterValue, Filter } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import CollapsibleFilter from "~/areas/tenants/components/HeaderBar/CollapsibleFilter";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import type { PageState } from "~/areas/tenants/components/Paging/usePageState";
import { usePageState } from "~/areas/tenants/components/Paging/usePageState";
import { isAccountType } from "~/areas/variables/isAccountType";
import { repository } from "~/clientInstance";
import ActionTemplateParameterEditorDialog from "~/components/ActionTemplateParametersList/ActionTemplateParameterEditorDialog";
import Chip from "~/components/Chips/Chip";
import { EnvironmentChip } from "~/components/Chips/index";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ReadonlyAccount from "~/components/ReadonlyAccount";
import ReadonlyCertificate from "~/components/ReadonlyCertificate";
import ReadonlySensitive from "~/components/ReadonlySensitive/ReadonlySensitive";
import ReadonlyText from "~/components/ReadonlyText";
import ReadonlyWorkerPool from "~/components/ReadonlyWorkerPool";
import SidebarLayout, { SidebarSide } from "~/components/SidebarLayout/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { Text } from "~/components/form/index";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/index";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
export default function ProjectTenantVariables() {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return <ProjectTenantVariablesInternal project={project}/>;
}
interface ProjectTenantVariablesInternalProps {
    project: ProjectResource;
}
interface ProjectTenantVariablesInternalState {
    showPrimaryAction: boolean;
}
class ProjectTenantVariablesInternal extends DataBaseComponent<ProjectTenantVariablesInternalProps, ProjectTenantVariablesInternalState> {
    constructor(props: ProjectTenantVariablesInternalProps) {
        super(props);
        this.state = {
            showPrimaryAction: true,
        };
    }
    updateShowPrimaryAction(showActions: boolean) {
        this.setState((state) => ({
            ...state,
            showPrimaryAction: showActions,
        }));
    }
    render() {
        const { project } = this.props;
        return (<ProjectTenantVariablesLayout busy={this.state.busy} errors={this.errors} project={project} doBusyTask={this.doBusyTask} showPrimaryAction={this.state.showPrimaryAction}>
                {project ? <ProjectTenantVariablesContent project={project} doBusyTask={this.doBusyTask} updateShowPrimaryActions={this.updateShowPrimaryAction.bind(this)}/> : null}
            </ProjectTenantVariablesLayout>);
    }
    static displayName = "ProjectTenantVariablesInternal";
}
interface ProjectTenantVariablesLayoutProps {
    project: ProjectResource | null;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
    showPrimaryAction: boolean;
}
function ProjectTenantVariablesLayout({ children, project, busy, errors, doBusyTask, showPrimaryAction }: PropsWithChildren<ProjectTenantVariablesLayoutProps>) {
    const activeTabParameter = stringQueryParam("activeTab");
    const [activeTab, _] = useQueryStringParam(activeTabParameter);
    const primaryAction = showPrimaryAction ? buildPrimaryAction(activeTab, project, doBusyTask) : undefined;
    return (<ProjectPageLayout title={"Tenant Variables"} breadcrumbTitle={project?.Name} busy={busy} errors={errors} fullWidth={true} primaryAction={primaryAction}>
            {children}
        </ProjectPageLayout>);
}
function buildPrimaryAction(activeTab: string | undefined, project: ProjectResource | null, doBusyTask: DoBusyTask): PrimaryPageAction | undefined {
    if (!activeTab || activeTab === ProjectTenantVariablesTabKeys.ProjectTemplates) {
        const showOnboardingPage = !project?.Templates || project.Templates.length === 0;
        if (showOnboardingPage) {
            return undefined;
        }
        return {
            type: "custom",
            key: "Add project template",
            hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project?.Id }),
            content: project === null ? undefined : <AddProjectTemplateButton project={project} doBusyTask={doBusyTask}/>,
        };
    }
    if (activeTab === ProjectTenantVariablesTabKeys.CommonTemplates) {
        return {
            type: "custom",
            key: "Include Library Variable sets",
            hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project?.Id }),
            content: project === null ? undefined : <IncludeLibraryVariableSetsButton project={project} doBusyTask={doBusyTask}/>,
        };
    }
    return undefined;
}
function FeedbackExternalLink() {
    if (new Date() > new Date("2024-05-01")) {
        return null;
    }
    return (<div>
            We'd love to <ExternalLink href={"tenant-variables-feedback"}>hear about your experience</ExternalLink>.
        </div>);
}
interface ProjectTenantVariablesContentProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
}
interface ProjectTenantVariableActionProps {
    updateShowPrimaryActions: (showActions: boolean) => void;
}
export enum ProjectTenantVariablesTabKeys {
    ProjectTemplates = "projectTemplates",
    CommonTemplates = "commonTemplates"
}
function ProjectTenantVariablesContent({ project, doBusyTask, updateShowPrimaryActions }: ProjectTenantVariablesContentProps & ProjectTenantVariableActionProps) {
    const [filterState, setFilterState] = useProjectTenantVariablesFilterState();
    const [pageState, setPageState] = usePageState(pagingSelectionKey);
    const [projectTemplatesWithValues, setProjectTemplatesWithValues] = useState<GetProjectTemplatesWithValuesBffResponse | null>(null);
    const [commonTemplatesWithValues, setCommonTemplatesWithValues] = useState<GetCommonTemplateWithValuesBffResponse | null>(null);
    const [projectTemplatesAnyMissingValues, setProjectTemplatesAnyMissingValues] = useState<boolean>(false);
    const [commonTemplatesAnyMissingValues, setCommonTemplatesAnyMissingValues] = useState<boolean>(false);
    const [reloadState, setReloadState] = useState<boolean>(false);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    const [, setProjectTenantVariablesFeatureFlag] = useProjectTenantVariablesFeatureFlag();
    const dispatchAction = useAnalyticActionDispatch();
    const handleChangeTab = (value: string) => {
        setFilterState({
            activeTab: value,
            filterByName: undefined,
            filterByEnvironment: undefined,
            filterByExcludedEnvironment: undefined,
            filterByTenant: undefined,
            filterByExcludedTenant: undefined,
            filterByTags: [],
            filterByExcludedTags: [],
            filterByValueStatus: undefined,
            filterByTemplateId: undefined,
            filterByLibraryVariableSetId: undefined,
            pageNumber: 1,
        });
    };
    const handleReload = () => {
        setReloadState(!reloadState);
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        const loadCommonTemplates = async () => {
            const response = await repository.Projects.getCommonTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState);
            setCommonTemplatesWithValues(response);
            setCommonTemplatesAnyMissingValues(response.AnyMissingValues);
        };
        const loadProjectTemplates = async () => {
            const response = await repository.Projects.getProjectTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState);
            setProjectTemplatesWithValues(response);
            setProjectTemplatesAnyMissingValues(response.AnyMissingValues);
        };
        if (isInitialPageLoad) {
            await Promise.all([loadProjectTemplates(), loadCommonTemplates()]);
        }
        if (filterState.activeTab === ProjectTenantVariablesTabKeys.ProjectTemplates) {
            updateShowPrimaryActions(true);
            if (!isInitialPageLoad) {
                await loadProjectTemplates();
            }
            setCommonTemplatesWithValues(null);
        }
        if (filterState.activeTab === ProjectTenantVariablesTabKeys.CommonTemplates) {
            updateShowPrimaryActions(false);
            if (!isInitialPageLoad) {
                await loadCommonTemplates();
            }
            setProjectTemplatesWithValues(null);
        }
        setIsInitialPageLoad(false);
    }, [project, filterState, pageState.pageSize, reloadState]);
    const handleOptOut = () => {
        setProjectTenantVariablesFeatureFlag(false);
        dispatchAction("Opt Out of Project Tenant Variables", { resource: "Tenant", action: Action.OptOut });
    };
    const missingValuesWarning = `Some of the required variable values have not been set`;
    return (<>
            <Callout title={"Improving variable management with multi-tenancy"} type={CalloutType.NewFeature}>
                This page is currently in an early-access phase and we'll be enhancing the experience over time. You can{" "}
                <InternalLink to={links.variableTemplatesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })} onClick={handleOptOut}>
                    switch back to the old design{" "}
                </InternalLink>
                at any time.
                <FeedbackExternalLink />
            </Callout>
            <ControlledTabsContainer value={filterState.activeTab} onChange={handleChangeTab}>
                <TabItem label={"Project Templates"} value={ProjectTenantVariablesTabKeys.ProjectTemplates} warning={projectTemplatesAnyMissingValues ? missingValuesWarning : undefined}>
                    {projectTemplatesWithValues && (<ProjectTemplatesTabContent project={project} doBusyTask={doBusyTask} filterState={filterState} filterData={filterData} setFilterState={setFilterState} pageState={pageState} setPageState={setPageState} handleReload={handleReload} projectTemplatesWithValues={projectTemplatesWithValues}/>)}
                </TabItem>
                <TabItem label={"Common Templates"} value={ProjectTenantVariablesTabKeys.CommonTemplates} warning={commonTemplatesAnyMissingValues ? missingValuesWarning : undefined}>
                    {commonTemplatesWithValues && (<CommonTemplatesTabContent project={project} doBusyTask={doBusyTask} filterState={filterState} filterData={filterData} setFilterState={setFilterState} pageState={pageState} setPageState={setPageState} handleReload={handleReload} commonTemplatesWithValues={commonTemplatesWithValues} updateShowPrimaryActions={updateShowPrimaryActions}/>)}
                </TabItem>
            </ControlledTabsContainer>
        </>);
}
interface CommonTemplateTabContentProps extends ProjectTenantVariablesContentProps {
    filterState: ProjectTenantVariablesFilterState;
    filterData: TenantVariableFiltersData;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
    pageState: PageState;
    setPageState: (newPageNumber: number, newPageSize: number) => void;
    handleReload: () => void;
    commonTemplatesWithValues: GetCommonTemplateWithValuesBffResponse;
}
function CommonTemplatesTabContent({ project, doBusyTask, filterState, filterData, setFilterState, pageState, setPageState, handleReload, commonTemplatesWithValues, updateShowPrimaryActions, }: CommonTemplateTabContentProps & ProjectTenantVariableActionProps) {
    const [variableSets, setVariableSets] = useState<LibraryVariableSetInfo[] | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const libraryVariableSets = await repository.LibraryVariableSets.all({
            ids: project.IncludedLibraryVariableSetIds,
            contentType: VariableSetContentType.Variables,
        });
        if (libraryVariableSets.length > 0) {
            updateShowPrimaryActions(true);
        }
        setVariableSets(libraryVariableSets);
    }, [project]);
    const templatesVariableType: Dictionary<VariableType> = {};
    const variableValues: CommonTemplateVariableValue[] = [];
    const missingValueVariableSetIds = new Set<string>(commonTemplatesWithValues.MissingValueLibraryVariableSetIds);
    for (const variableSet of commonTemplatesWithValues.VariableSets) {
        for (const template of variableSet.Templates) {
            for (const variable of template.Values) {
                let templateInfo: TemplateInfo | null = null;
                let variableSetInfo: LibraryVariableSetInfo | null = null;
                if (!templatesVariableType[template.Template.Id]) {
                    templatesVariableType[template.Template.Id] = getVariableTypeFromDisplaySettings(template.Template.DisplaySettings["Octopus.ControlType"]);
                    templateInfo = {
                        Id: template.Template.Id,
                        HelpText: template.Template.HelpText,
                        Label: template.Template.Label,
                        Name: template.Template.Name,
                    };
                    variableSetInfo = variableSet.LibraryVariableSet;
                }
                variableValues.push({
                    Template: templateInfo,
                    VariableType: templatesVariableType[template.Template.Id],
                    Variable: variable,
                    VariableSetId: variableSet.LibraryVariableSet.Id,
                    VariableSet: variableSetInfo,
                    RowKey: `${template.Template.Id}${variableSet.LibraryVariableSet.Id}${variable.TenantId}`,
                });
            }
        }
    }
    if (variableSets !== null && variableSets.length === 0) {
        return <NoVariableSetsOnboarding project={project} doBusyTask={doBusyTask}/>;
    }
    if (commonTemplatesWithValues.TotalValuesCount === 0) {
        return (<div style={{ height: "100%" }}>
                <SidebarLayout sideBar={<CommonTemplateSidebarContent variableSets={variableSets ?? []} spaceId={project.SpaceId} pageState={pageState} onRowClick={(id) => {
                    setFilterState({
                        ...filterState,
                        filterByLibraryVariableSetId: id,
                        pageNumber: 1,
                    });
                }} filterState={filterState} missingValueVariableSetIds={missingValueVariableSetIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
                    <NoCommonTemplateValuesOnboarding project={project}/>
                </SidebarLayout>
            </div>);
    }
    return (<SidebarLayout sideBar={<CommonTemplateSidebarContent variableSets={variableSets ?? []} spaceId={project.SpaceId} pageState={pageState} onRowClick={(id) => {
                setFilterState({
                    ...filterState,
                    filterByLibraryVariableSetId: id,
                    pageNumber: 1,
                });
            }} filterState={filterState} missingValueVariableSetIds={missingValueVariableSetIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
            <div className={styles.mainContentContainer}>
                {commonTemplatesWithValues.AnyMissingValues && <MissingValuesCallout />}
                <FiltersBar totalVariablesCount={commonTemplatesWithValues.TotalValuesCount} filteredVariablesCount={commonTemplatesWithValues.FilteredValuesCount} filterState={filterState} onFilterChange={(newFilterState: ProjectTenantVariablesFilterState) => setFilterState(newFilterState)} filterData={filterData}/>
                <CommonTemplatesDataTable values={variableValues} project={project} accounts={commonTemplatesWithValues.Accounts} certificates={commonTemplatesWithValues.Certificates} workerPools={commonTemplatesWithValues.WorkerPools} onVariableSaved={() => handleReload()}/>
                <div className={pagingBarStyles}>
                    <NumberedPagingBar totalItems={commonTemplatesWithValues.FilteredValuesCount} pageNumber={filterState.pageNumber} pageSize={pageState.pageSize} pageSizeOptions={[30, 50, 100]} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setFilterState({ ...filterState, pageNumber: newPageNumber });
            setPageState(newPageNumber, newPageSize);
        }}/>
                </div>
            </div>
        </SidebarLayout>);
}
const pagingSelectionKey = "octoProjectTenantVariablesPagingSelection";
interface ProjectTemplatesTabContentProps extends ProjectTenantVariablesContentProps {
    filterState: ProjectTenantVariablesFilterState;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
    filterData: TenantVariableFiltersData;
    pageState: PageState;
    setPageState: (newPageNumber: number, newPageSize: number) => void;
    handleReload: () => void;
    projectTemplatesWithValues: GetProjectTemplatesWithValuesBffResponse;
}
function ProjectTemplatesTabContent({ project, doBusyTask, filterState, filterData, setFilterState, handleReload, pageState, setPageState, projectTemplatesWithValues }: ProjectTemplatesTabContentProps) {
    const templatesVariableType: Dictionary<VariableType> = {};
    const variableValues: ProjectTemplateVariableValue[] = [];
    const projectTemplates = new Map(project.Templates.map((pt) => [pt.Id, pt]));
    const missingValuesTemplateIds = new Set<string>(projectTemplatesWithValues.MissingValueProjectTemplateIds);
    for (const variable of projectTemplatesWithValues.Variables) {
        const projectTemplate = projectTemplates.get(variable.TemplateId);
        if (!projectTemplate) {
            logger.warn("Could not find project template with ID {id}", { id: variable.TemplateId });
            continue;
        }
        let templateInfo: TemplateInfo | null = null;
        if (!templatesVariableType[variable.TemplateId]) {
            const projectTemplate = projectTemplates.get(variable.TemplateId);
            if (projectTemplate) {
                templatesVariableType[variable.TemplateId] = getVariableTypeFromDisplaySettings(projectTemplate.DisplaySettings["Octopus.ControlType"]);
                templateInfo = {
                    Id: projectTemplate.Id,
                    HelpText: projectTemplate.HelpText,
                    Label: projectTemplate.Label,
                    Name: projectTemplate.Name,
                };
            }
        }
        variableValues.push({
            Template: templateInfo,
            VariableType: templatesVariableType[variable.TemplateId],
            Variable: variable,
            RowKey: `${projectTemplate.Id}${variable.TenantId}${variable.EnvironmentId}`,
        });
    }
    if (project.Templates.length === 0) {
        return <NoTemplatesOnboarding project={project} doBusyTask={doBusyTask}/>;
    }
    if (projectTemplatesWithValues.TotalValuesCount === 0) {
        return (<div style={{ height: "100%" }}>
                <SidebarLayout sideBar={<ProjectTemplatesSidebarContent project={project} pageState={pageState} doBusyTask={doBusyTask} filterState={filterState} onRowClick={(id) => {
                    setFilterState({
                        ...filterState,
                        filterByTemplateId: id,
                        pageNumber: 1,
                    });
                }} missingValueTemplateIds={missingValuesTemplateIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
                    <NoValuesOnboarding project={project}/>
                </SidebarLayout>
            </div>);
    }
    return (<SidebarLayout sideBar={<ProjectTemplatesSidebarContent project={project} pageState={pageState} doBusyTask={doBusyTask} onRowClick={(id) => {
                setFilterState({
                    ...filterState,
                    filterByTemplateId: id,
                    pageNumber: 1,
                });
            }} filterState={filterState} missingValueTemplateIds={missingValuesTemplateIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
            <div className={styles.mainContentContainer}>
                {projectTemplatesWithValues.AnyMissingValues && <MissingValuesCallout />}
                <FiltersBar totalVariablesCount={projectTemplatesWithValues.TotalValuesCount} filteredVariablesCount={projectTemplatesWithValues.FilteredValuesCount} filterState={filterState} onFilterChange={(newFilterState: ProjectTenantVariablesFilterState) => setFilterState(newFilterState)} filterData={filterData}/>
                <ProjectTemplatesDataTable values={variableValues} project={project} accounts={projectTemplatesWithValues.Accounts} certificates={projectTemplatesWithValues.Certificates} workerPools={projectTemplatesWithValues.WorkerPools} onVariableSaved={() => handleReload()}/>
                <div className={pagingBarStyles}>
                    <NumberedPagingBar totalItems={projectTemplatesWithValues.FilteredValuesCount} pageNumber={filterState.pageNumber} pageSize={pageState.pageSize} pageSizeOptions={[30, 50, 100]} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setFilterState({ ...filterState, pageNumber: newPageNumber });
            setPageState(newPageNumber, newPageSize);
        }}/>
                </div>
            </div>
        </SidebarLayout>);
}
interface ProjectTemplatesSidebarContentProps {
    project: ProjectResource;
    pageState: PageState;
    doBusyTask: DoBusyTask;
    onRowClick: (projectTemplateName: string | undefined) => void;
    filterState: ProjectTenantVariablesFilterState;
    missingValueTemplateIds: Set<string>;
}
function ProjectTemplatesSidebarContent({ project, pageState, doBusyTask, onRowClick, filterState, missingValueTemplateIds }: ProjectTemplatesSidebarContentProps) {
    const [activeTemplate, setActiveTemplate] = useState<ActionTemplateParameterResource | undefined>(undefined);
    const { isOpen: isViewEditTemplateDialogOpen, openDialog: openViewEditTemplateDialog } = useDialogTrigger();
    const { isOpen: isDeleteTemplateDialogOpen, openDialog: openDeleteTemplateDialog } = useDialogTrigger();
    const sidebarMaxHeight = pageState.pageSize === 30 ? styles.sidebar30Height : pageState.pageSize === 50 ? styles.sidebar50Height : styles.sidebar100Height;
    return (<div className={cx(styles.sidebarContainer, sidebarMaxHeight)}>
            <div className={cx(styles.sidebarName, styles.sidebarHeadingContainer, filterState.filterByTemplateId ? undefined : styles.sidebarSelectedName)} onClick={() => onRowClick(undefined)}>
                All project templates
            </div>

            <List items={project.Templates.sort((t1, t2) => t1.Name.localeCompare(t2.Name))} renderRow={({ item: template }) => {
            const menuItems = [
                OverflowMenuItems.item(isAllowed({
                    permission: Permission.ProjectEdit,
                    project: project.Id,
                    wildcard: true,
                })
                    ? "Edit template"
                    : "View template", () => {
                    setActiveTemplate(template);
                    openViewEditTemplateDialog();
                }),
                OverflowMenuItems.item("Delete template", () => {
                    setActiveTemplate(template);
                    openDeleteTemplateDialog();
                }, {
                    permission: Permission.ProjectEdit,
                    project: project.Id,
                    wildcard: true,
                }),
            ];
            return (<div className={styles.sidebarRow} onClick={() => onRowClick(template.Id)}>
                            <div className={styles.sidebarTextContainer}>
                                <div className={cx(styles.sidebarName, template.Id === filterState.filterByTemplateId ? styles.sidebarSelectedName : undefined)}>
                                    {template.Name}
                                    <ProjectTemplateHelpText text={template.HelpText}/>
                                    <MissingVariablesIcon show={missingValueTemplateIds.has(template.Id)}/>
                                </div>
                                <div className={styles.sidebarLabel}>{template.Label}</div>
                            </div>
                            <div>
                                <OverflowMenu menuItems={menuItems}/>
                            </div>
                        </div>);
        }}/>
            <UpdateTemplateDialog open={isViewEditTemplateDialogOpen} template={activeTemplate} project={project} doBusyTask={doBusyTask}/>
            <DeleteTemplateDialog open={isDeleteTemplateDialogOpen} template={activeTemplate} project={project} doBusyTask={doBusyTask}/>
        </div>);
}
interface CommonTemplateSidebarContentProps {
    variableSets: LibraryVariableSetInfo[];
    spaceId: string;
    pageState: PageState;
    onRowClick: (projectTemplateName: string | undefined) => void;
    filterState: ProjectTenantVariablesFilterState;
    missingValueVariableSetIds: Set<string>;
}
function CommonTemplateSidebarContent({ variableSets, spaceId, pageState, onRowClick, filterState, missingValueVariableSetIds }: CommonTemplateSidebarContentProps) {
    const sidebarMaxHeight = pageState.pageSize === 30 ? styles.sidebar30Height : pageState.pageSize === 50 ? styles.sidebar50Height : styles.sidebar100Height;
    return (<div className={cx(styles.sidebarContainer, sidebarMaxHeight)}>
            <div className={cn(styles.sidebarName, styles.sidebarHeadingContainer, filterState.filterByLibraryVariableSetId ? undefined : styles.sidebarSelectedName)} onClick={() => onRowClick(undefined)}>
                All variable sets
            </div>

            <List items={variableSets.sort((vs1, vs2) => vs1.Name.localeCompare(vs2.Name))} renderRow={({ item: variableSet }) => {
            const menuItems = [
                OverflowMenuItems.navItem("Edit in library", links.editVariableSetPage.generateUrl({ spaceId: spaceId, variableSetId: variableSet.Id }, { activeTab: VariableSetTabValues.VariableTemplates }), {
                    permission: Permission.LibraryVariableSetEdit,
                }),
            ];
            return (<div className={styles.sidebarRow} onClick={() => onRowClick(variableSet.Id)}>
                            <div className={styles.sidebarTextContainer}>
                                <div className={cn(styles.sidebarName, variableSet.Id === filterState.filterByLibraryVariableSetId ? styles.sidebarSelectedName : undefined)}>
                                    {variableSet.Name}
                                    <MissingVariablesIcon show={missingValueVariableSetIds.has(variableSet.Id)}/>
                                </div>
                                <div className={styles.sidebarLabel}>{variableSet.Description}</div>
                            </div>
                            <div>
                                <OverflowMenu menuItems={menuItems}/>
                            </div>
                        </div>);
        }}/>
        </div>);
}
interface OverflowMenuDialogProps {
    open: boolean;
    template: ActionTemplateParameterResource | undefined;
    project: ProjectResource;
    doBusyTask: DoBusyTask;
}
function UpdateTemplateDialog({ open, template, project, doBusyTask }: OverflowMenuDialogProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const handleUpdateTemplate = async (updated: ActionTemplateParameterResource) => {
        const resourceIndex = project.Templates.findIndex((t) => t.Id === updated.Id);
        const templatesBeforeUpdated = project.Templates.slice(0, resourceIndex);
        const templatesAfterUpdated = project.Templates.slice(resourceIndex + 1);
        await doBusyTask(async () => {
            await repository.Projects.modify({ ...project, Templates: [...templatesBeforeUpdated, updated, ...templatesAfterUpdated] });
            dispatchAction("Edit Project Variable Template", { resource: "Variable", action: Action.Update });
        });
    };
    return (<Dialog open={open}>
            <ActionTemplateParameterEditorDialog parameter={template} excludedControlTypes={[ControlType.StepName, ControlType.Package]} onOk={handleUpdateTemplate} name={"template"} editPermission={{
            permission: Permission.ProjectEdit,
            project: project.Id,
            wildcard: true,
        }}/>
        </Dialog>);
}
function DeleteTemplateDialog({ open, template, project, doBusyTask }: OverflowMenuDialogProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const handleDeleteTemplate = async () => {
        const templatesWithoutRemovedOne = project.Templates.filter((t) => t.Id !== template?.Id);
        await doBusyTask(async () => {
            await repository.Projects.modify({ ...project, Templates: templatesWithoutRemovedOne });
            dispatchAction("Delete Project Variable Template", { resource: "Variable", action: Action.Delete });
        });
        return true;
    };
    return (<Dialog open={open}>
            <DeleteDialogLayout title={"Are you sure you want to delete this template?"} onDeleteClick={() => handleDeleteTemplate()} renderContent={() => (<div>
                        <Callout title="This is a destructive action" type={CalloutType.Danger}>
                            This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{template?.Name}</strong> template from the project.
                        </Callout>
                        <p>Do you wish to continue?</p>
                    </div>)}/>
        </Dialog>);
}
interface ProjectsTemplatesFiltersBarProps {
    totalVariablesCount: number;
    filteredVariablesCount: number | null;
    filterState: ProjectTenantVariablesFilterState;
    onFilterChange: (newFilterState: ProjectTenantVariablesFilterState) => void;
    filterData: TenantVariableFiltersData;
}
function FiltersBar({ filterState, onFilterChange, totalVariablesCount, filteredVariablesCount, filterData }: ProjectsTemplatesFiltersBarProps) {
    const filters = buildVariableFilters(filterState, filterData);
    const handleFilterChange = (newFilters: FilterValue[]) => {
        onFilterChange({
            ...filterState,
            filterByTenant: getIncludedTenantValue(newFilters),
            filterByExcludedTenant: getExcludedTenantValue(newFilters),
            filterByEnvironment: getIncludedEnvironmentValue(newFilters),
            filterByExcludedEnvironment: getExcludedEnvironmentValue(newFilters),
            filterByTags: getIncludedTagSetValues(newFilters),
            filterByExcludedTags: getExcludedTagSetValues(newFilters),
            pageNumber: 1,
        });
    };
    const handleNameFilterChange = (newName: string) => {
        onFilterChange({
            ...filterState,
            filterByName: newName,
            pageNumber: 1,
        });
    };
    const handleValueStatusFilterChange = (newValueStatus: ValueStatus) => {
        onFilterChange({
            ...filterState,
            filterByValueStatus: newValueStatus,
            pageNumber: 1,
        });
    };
    return (<CollapsibleFilter totalCount={totalVariablesCount} filteredCount={filteredVariablesCount === totalVariablesCount ? null : filteredVariablesCount} entityName={"value"} actions={<FilterActions />} secondaryContent={<FilterSearchBox placeholder={"Filter by variable name..."} value={filterState.filterByName} onChange={handleNameFilterChange} autoFocus={true}/>}>
            {filters.length > 0 && (<div className={styles.filterContainer}>
                    <div className={styles.filterSidebar}>
                        <RadioButtonGroup value={filterState.filterByValueStatus ?? ValueStatusValues.All} onChange={handleValueStatusFilterChange} title="Show">
                            <RadioButton label="All" value={ValueStatusValues.All}/>
                            <RadioButton label="Non-default" value={ValueStatusValues.Overridden}/>
                            <RadioButton label="Missing" value={ValueStatusValues.Missing}/>
                        </RadioButtonGroup>
                    </div>
                    <Divider orientation={"vertical"}/>
                    <FilterBuilder filters={filters} onChange={handleFilterChange}/>
                </div>)}
        </CollapsibleFilter>);
}
function buildVariableFilters(currentState: ProjectTenantVariablesFilterState, data: TenantVariableFiltersData): Filter[] {
    const filters = [];
    if (data.environments && currentState.activeTab === ProjectTenantVariablesTabKeys.ProjectTemplates) {
        filters.push(createEnvironmentFilter(data.environments, currentState.filterByEnvironment, currentState.filterByExcludedEnvironment));
    }
    if (data.tenants) {
        filters.push(createTenantFilter(Array.from(data.tenants.values()), currentState.filterByTenant, currentState.filterByExcludedTenant));
    }
    if (data.tagSets) {
        filters.push(...createTagSetFilters(data.tagSets, currentState.filterByTags, currentState.filterByExcludedTags));
    }
    return filters;
}
interface CommonTemplateVariableValue {
    VariableSetId: string;
    VariableSet: LibraryVariableSetInfo | null;
    Variable: CommonTemplateTenantValue;
    Template: TemplateInfo | null;
    VariableType: VariableType;
    RowKey: string;
}
interface ProjectTemplateVariableValue {
    Variable: ProjectTemplateTenantValue;
    Template: TemplateInfo | null;
    VariableType: VariableType;
    RowKey: string;
}
interface TemplateInfo {
    Id: string;
    Name: string;
    Label: string;
    HelpText: string;
}
interface ProjectTemplatesDataTableProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    project: ProjectResource;
    values: ProjectTemplateVariableValue[];
    onVariableSaved: () => void;
}
function ProjectTemplatesDataTable({ values, project, accounts, certificates, onVariableSaved, workerPools }: ProjectTemplatesDataTableProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const editingPermissions = {
        permission: Permission.VariableEdit,
        project: project.Id,
        wildcard: true,
    };
    const editVariable: EditVariableData | null = isAllowed(editingPermissions) ? { templateOwnerId: project.Id, onVariableSaved } : null;
    return (<table className={cx(tableStyles, { [tableMobileStyles]: !isLargerThanIpad })}>
            <thead>
                <tr>
                    <th className={nameColumnStyle}>Name</th>
                    <th className={tenantColumnStyle}>Tenant</th>
                    <th className={environmentColumnStyle}>Environment</th>
                    <th className={valueColumnStyle}>Value</th>
                </tr>
            </thead>
            <tbody>
                {values.map((value) => (<ProjectTemplateRow value={value} project={project} key={value.RowKey} accounts={accounts} certificates={certificates} workerPools={workerPools} editVariable={editVariable}/>))}
            </tbody>
        </table>);
}
interface CommonTemplatesDataTableProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    project: ProjectResource;
    values: CommonTemplateVariableValue[];
    onVariableSaved: () => void;
}
function CommonTemplatesDataTable({ values, project, accounts, certificates, workerPools, onVariableSaved }: CommonTemplatesDataTableProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const editingPermissions = {
        permission: Permission.VariableEdit,
        project: project.Id,
        wildcard: true,
    };
    return (<>
            <table className={cx(tableStyles, { [tableMobileStyles]: !isLargerThanIpad })}>
                <thead>
                    <tr>
                        <th className={setColumnStyle}>Set</th>
                        <th className={nameColumnStyle}>Name</th>
                        <th className={tenantColumnStyle}>Tenant</th>
                        <th className={valueColumnStyle}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {values.map((value) => {
            const editVariable: EditVariableData | null = isAllowed(editingPermissions) ? { templateOwnerId: value.VariableSetId, onVariableSaved } : null;
            return <CommonTemplateRow value={value} project={project} key={value.RowKey} accounts={accounts} certificates={certificates} workerPools={workerPools} editVariable={editVariable}/>;
        })}
                </tbody>
            </table>
        </>);
}
interface CommonTemplateRowProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    project: ProjectResource;
    value: CommonTemplateVariableValue;
    editVariable: EditVariableData | null;
}
function CommonTemplateRow({ value, project, accounts, certificates, workerPools, editVariable }: CommonTemplateRowProps) {
    return (<tr>
            <CommonTemplateLibraryCell variableSet={value.VariableSet}/>
            <TemplateNameCell template={value.Template}/>
            <TemplateTenantCell variable={value.Variable} spaceId={project.SpaceId}/>
            <CommonTemplateValueCell value={value} accounts={accounts} certificates={certificates} workerPools={workerPools} editVariable={editVariable}/>
        </tr>);
}
interface CommonTemplateValueCellProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    value: CommonTemplateVariableValue;
    editVariable: EditVariableData | null;
}
function CommonTemplateValueCell({ value, accounts, certificates, workerPools, editVariable }: CommonTemplateValueCellProps) {
    return (<td>
            <div className={styles.templateValueCell}>
                <div className={styles.templateValue}>
                    {value.Variable.ValueStatus === "missing" ? <VariableMissingValue /> : <VariableValue value={value} accounts={accounts} certificates={certificates} workerPools={workerPools}/>}
                    {value.Variable.ValueStatus === "default" && <Chip>Inherited default value</Chip>}
                </div>
                <div className={styles.actionButtonsContainer}>
                    <EditVariableIconButton info={editVariable} variable={value.Variable} variableType={value.VariableType}/>
                </div>
            </div>
        </td>);
}
interface CommonTemplateVariableSetCellProps {
    variableSet: LibraryVariableSetInfo | null;
}
function CommonTemplateLibraryCell({ variableSet }: CommonTemplateVariableSetCellProps) {
    if (!variableSet) {
        return <td style={{ borderTop: 0 }}></td>;
    }
    return <td>{variableSet.Name}</td>;
}
interface ProjectTemplateRowProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    project: ProjectResource;
    value: ProjectTemplateVariableValue;
    editVariable: EditVariableData | null;
}
function ProjectTemplateRow({ value, project, accounts, certificates, editVariable, workerPools }: ProjectTemplateRowProps) {
    return (<tr>
            <TemplateNameCell template={value.Template}/>
            <TemplateTenantCell variable={value.Variable} spaceId={project.SpaceId}/>
            <ProjectTemplateEnvironmentCell variable={value.Variable}/>
            <ProjectTemplateValueCell value={value} accounts={accounts} certificates={certificates} workerPools={workerPools} editVariable={editVariable}/>
        </tr>);
}
interface ProjectTemplateNameCellProps {
    template: TemplateInfo | null;
}
function TemplateNameCell({ template }: ProjectTemplateNameCellProps) {
    if (!template) {
        return <td style={{ borderTop: 0 }}></td>;
    }
    return (<td>
            <div className={styles.templateInfo}>
                <div className={styles.templateName}>
                    <TenantVariableName name={template.Name}/>
                    <ProjectTemplateHelpText text={template.HelpText}/>
                </div>
                <div className={styles.cellLabel}>{template.Label}</div>
            </div>
        </td>);
}
interface ProjectTemplateHelpTextProps {
    text: string | null;
}
function ProjectTemplateHelpText({ text }: ProjectTemplateHelpTextProps) {
    if (!text) {
        return null;
    }
    return (<Tooltip content={text}>
            <SmsIcon />
        </Tooltip>);
}
interface ProjectTemplateTenantCellProps {
    variable: {
        TenantId: string;
        TenantName: string;
        TenantLogoLink: string;
    };
    spaceId: string;
}
function TemplateTenantCell({ variable, spaceId }: ProjectTemplateTenantCellProps) {
    return (<td>
            <TenantsDataTableNameCellWithLink spaceId={spaceId} tenantId={variable.TenantId} tenantName={variable.TenantName} tenantLogoLink={variable.TenantLogoLink} linkToPage="Variables"/>
        </td>);
}
interface ProjectTemplateEnvironmentCellProps {
    variable: ProjectTemplateTenantValue;
}
function ProjectTemplateEnvironmentCell({ variable }: ProjectTemplateEnvironmentCellProps) {
    return (<td>
            <EnvironmentChip environmentName={variable.EnvironmentName} key={variable.EnvironmentId}/>
        </td>);
}
function getVariableTypeFromDisplaySettings(type?: ControlType): VariableType {
    switch (type) {
        case ControlType.Sensitive:
            return VariableType.Sensitive;
        case ControlType.Certificate:
            return VariableType.Certificate;
        case ControlType.AmazonWebServicesAccount:
            return VariableType.AmazonWebServicesAccount;
        case ControlType.AzureAccount:
            return VariableType.AzureAccount;
        case ControlType.GoogleCloudAccount:
            return VariableType.GoogleCloudAccount;
        case ControlType.WorkerPool:
            return VariableType.WorkerPool;
        case ControlType.UsernamePasswordAccount:
            return VariableType.UsernamePasswordAccount;
        default:
            return VariableType.String;
    }
}
interface EditVariableData {
    templateOwnerId: string;
    onVariableSaved: () => void;
}
interface ProjectTemplateValueCellProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    value: ProjectTemplateVariableValue;
    editVariable: EditVariableData | null;
}
function ProjectTemplateValueCell({ value, accounts, certificates, workerPools, editVariable }: ProjectTemplateValueCellProps) {
    return (<td>
            <div className={styles.templateValueCell}>
                <div className={styles.templateValue}>
                    {value.Variable.ValueStatus === "missing" ? <VariableMissingValue /> : <VariableValue value={value} accounts={accounts} certificates={certificates} workerPools={workerPools}/>}
                    {value.Variable.ValueStatus === "default" && <Chip>Inherited default value</Chip>}
                </div>
                <div className={styles.actionButtonsContainer}>
                    <EditVariableIconButton info={editVariable} variable={value.Variable} variableType={value.VariableType}/>
                </div>
            </div>
        </td>);
}
function VariableMissingValue() {
    return (<div className={styles.missingValue}>
            <MissingVariablesIcon show={true} withoutTooltip={true}/>
            <span>Missing value</span>
        </div>);
}
interface VariableValueProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    value: ProjectTemplateVariableValue | CommonTemplateVariableValue;
}
function VariableValue({ value, accounts, certificates, workerPools }: VariableValueProps) {
    const variableValue = convertPropertyValueResourceToString(value.Variable.Value);
    if (isAccountType(value.VariableType)) {
        return <ReadonlyAccount accountId={variableValue} accountName={accounts[variableValue]}/>;
    }
    if (value.VariableType === VariableType.Sensitive) {
        return <ReadonlySensitive hasValue={true} monoSpacedFont={false}/>;
    }
    if (value.VariableType === VariableType.String) {
        return <ReadonlyText text={variableValue} monoSpacedFont={false}/>;
    }
    if (value.VariableType === VariableType.Certificate) {
        return <ReadonlyCertificate certificateName={certificates[variableValue]} certificate={variableValue}/>;
    }
    if (value.VariableType === VariableType.WorkerPool) {
        return <ReadonlyWorkerPool poolName={workerPools[variableValue]?.Name} poolType={workerPools[variableValue]?.WorkerPoolType} poolId={variableValue}/>;
    }
    return null;
}
interface EditVariableIconButtonProps {
    info: EditVariableData | null;
    variable: ProjectTemplateTenantValue | CommonTemplateTenantValue;
    variableType: VariableType;
}
function EditVariableIconButton({ info, variable, variableType }: EditVariableIconButtonProps) {
    const dispatchAction = useAnalyticActionDispatch();
    if (!info) {
        return null;
    }
    if (!canEditTemplateVariable(info, variableType)) {
        return null;
    }
    const onSave = () => {
        if (isProjectTemplateTenantValue(variable)) {
            dispatchAction("Save Project Template Variable", { resource: "Variable", action: Action.Save });
        }
        else {
            dispatchAction("Save Common Template Variable", { resource: "Variable", action: Action.Save });
        }
        info.onVariableSaved();
        return Promise.resolve(true);
    };
    return (<OpenDialogIconButton toolTipContent="Edit variable" icon="Edit">
            <UpdateTenantVariableDialog templateOwnerId={info.templateOwnerId} variable={variable} onSave={onSave}/>
        </OpenDialogIconButton>);
}
function canEditTemplateVariable(editInfo: EditVariableData, type: VariableType) {
    const permission = ControlTypeRequiredPermissions.get(type);
    if (!permission) {
        return true; // Allow when permission is not required
    }
    const permissionCheck = {
        permission: permission,
        project: editInfo.templateOwnerId,
        wildcard: true,
    };
    return isAllowed(permissionCheck);
}
const ControlTypeRequiredPermissions = new Map([
    [VariableType.AmazonWebServicesAccount, Permission.AccountView],
    [VariableType.AzureAccount, Permission.AccountView],
    [VariableType.GoogleCloudAccount, Permission.AccountView],
    [VariableType.UsernamePasswordAccount, Permission.AccountView],
]);
export function FilterActions() {
    return (<div className={styles.toolbarIconsContainer}>
            <ShareFiltersButton />
        </div>);
}
function ShareFiltersButton() {
    return (<OpenDialogIconButton icon={"Share"} toolTipContent={"Share filter"}>
            <ShareDialogLayout />
        </OpenDialogIconButton>);
}
function ShareDialogLayout() {
    const currentUri = URI(window.location).valueOf();
    return (<InfoDialogLayout title={"Share filter"}>
            All users who have view permission to this page can view this filter.
            <div className={styles.shareContainer}>
                <Text value={currentUri} onChange={noOp} readOnly={true}/>
                <CopyToClipboardButton value={currentUri}/>
            </div>
        </InfoDialogLayout>);
}
function MissingValuesCallout() {
    return (<Callout type={CalloutType.Warning} title="Provide missing variable values">
            If required variable values are not set it could cause deployments to fail.
        </Callout>);
}
const styles = {
    loading: css({
        display: "flex",
        justifyContent: "center",
    }),
    templateInfo: css({
        display: "flex",
        flexDirection: "column",
        gap: space[6],
    }),
    templateName: css({
        display: "flex",
        gap: space[8],
        alignItems: "center",
    }),
    templateValueCell: css({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }),
    templateValue: css({
        display: "flex",
        alignItems: "center",
    }),
    value: css({
        color: themeTokens.color.text.disabled,
        display: "flex",
        alignItems: "center",
        gap: space[8],
    }),
    missingValue: css({
        display: "flex",
        alignItems: "center",
        gap: space[8],
        color: "#ea7325", // TODO: This should be a design system token. Checking with BPE
    }),
    sidebarContainer: css({
        paddingTop: space[16],
        overflowY: "auto",
    }),
    sidebar30Height: css({
        maxHeight: "137rem",
    }),
    sidebar50Height: css({
        maxHeight: "222rem",
    }),
    sidebar100Height: css({
        maxHeight: "430rem",
    }),
    sidebarRow: css({
        paddingBottom: space[16],
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
    }),
    sidebarTextContainer: css({
        marginLeft: space[16],
        marginTop: space[6],
    }),
    sidebarHeadingContainer: css({
        paddingLeft: space[16],
        paddingTop: space[6],
        paddingBottom: space[16],
        cursor: "pointer",
        "&:hover": {
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
    }),
    sidebarName: css({
        display: "flex",
        gap: space[8],
        font: text.interface.body.bold.base,
    }),
    sidebarSelectedName: css({
        color: themeTokens.color.text.selected,
    }),
    sidebarLabel: css({
        font: text.interface.body.default.small,
        color: themeTokens.color.text.subtle,
    }),
    filterContainer: css({
        display: "flex",
        gap: space[8],
        padding: space[16],
    }),
    actionButtonsContainer: css({
        paddingRight: space[4],
    }),
    filterSidebar: css({
        whiteSpace: "nowrap",
        padding: `0 ${space[4]}`,
    }),
    cellLabel: css({
        font: text.interface.body.default.small,
        color: themeTokens.color.text.subtle,
    }),
    shareContainer: css({
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
    }),
    toolbarIconsContainer: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
    }),
    mainContentContainer: css({
        overflowX: "auto",
    }),
};
const tableBorder = `${borderWidth[1]} solid ${themeTokens.color.border.primary}`;
const tableStyles = css({
    width: "100%",
    backgroundColor: themeTokens.color.background.primary.default,
    borderSpacing: 0,
    th: {
        padding: `${space[12]} ${space[16]}`,
        paddingRight: 0,
        textAlign: "left",
        font: text.table.cell.default.medium,
        position: "relative",
        ":not(:last-child)": {
            ":after": {
                content: "''",
                position: "absolute",
                height: "50%",
                right: 0,
                top: "25%",
                borderRight: tableBorder,
            },
        },
    },
    td: {
        font: text.table.cell.default.base,
        padding: `${space[8]} ${space[12]}`,
        borderTop: tableBorder,
        verticalAlign: "middle",
    },
});
const setColumnStyle = css({ width: "20%" });
const nameColumnStyle = css({ width: "25%" });
const tenantColumnStyle = css({ width: "20%" });
const environmentColumnStyle = css({ width: "20%" });
const valueColumnStyle = css({ width: "35%" });
const tableMobileStyles = css({
    display: "block",
    overflowX: "auto",
});
const pagingBarStyles = css({
    padding: "0 1rem",
});
