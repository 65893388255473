/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports,@typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { Language, TextFormat } from "~/components/CodeEditor/CodeEditor";
import { codeEditorToolbarSelectStyles } from "~/components/CodeEditor/codeEditorStyles";
interface CodeEditorSelectProps {
    supportedLanguages: Array<ScriptingLanguage | Language | TextFormat>;
    scriptingLanguage: ScriptingLanguage | Language | TextFormat;
    onChange: (value: ScriptingLanguage | Language | TextFormat) => void;
    onFocus: () => void;
    onClose?: () => void;
    onOpen?: () => void;
}
const selectRoot = css({
    border: `1px solid ${themeTokens.color.border.subtle}`,
    borderRadius: borderRadius["medium"],
    background: themeTokens.color.codeEditor.toolbar.background,
    width: "180px",
    ".MuiSelect-root": {
        paddingLeft: "8px",
        paddingTop: "8px",
    },
    ".MuiSelect-icon": {
        color: themeTokens.color.icon.primary,
    },
    "&.MuiInput-underline::before, &.MuiInput-underline:hover:not(.Mui-disabled)::before": {
        borderBottom: "none",
    },
    "&.MuiInput-underline::after": {
        borderBottom: "none",
    },
});
export const CodeEditorSelect = (props: CodeEditorSelectProps) => {
    return (<Select value={props.scriptingLanguage} className={`${codeEditorToolbarSelectStyles} ${selectRoot}`} onFocus={() => props.onFocus()} onChange={(event) => props.onChange(event.target.value as ScriptingLanguage | Language | TextFormat)} onClose={() => props.onClose?.()} onOpen={() => props.onOpen?.()}>
            {props.supportedLanguages.map((language) => (<MenuItem key={language} value={language}>
                    {language}
                </MenuItem>))}
        </Select>);
};
