import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { RedirectAs404 } from "../../../../components/NotFound/NotFound";
import { Channels } from "./Channels";
import { Edit } from "./Edit";
export const EditChannelPage = withPage({ page: pageIds.project().channel })(Edit);
export const CreateChannelPage = withPage({ page: pageIds.project().channelNew })(Edit);
export const ChannelsPage = withPage({ page: pageIds.project().channel })(Channels);
type ChannelsRouteProps = {
    path: string;
};
type Props = ChannelsRouteProps & RouteComponentProps;
class ChannelsRoute extends React.Component<Props> {
    render() {
        return (<ErrorContextProvider>
                <Switch>
                    <MigratedReloadableRoute path={`${this.props.path}/edit/:channelId`} exact={true} render={(routeProps: RouteComponentProps<{
            channelId: string;
        }>) => <EditChannelPage create={false} channelId={routeProps.match.params.channelId}/>}/>
                    <MigratedReloadableRoute path={`${this.props.path}/create`} exact={true} render={() => <CreateChannelPage create={true}/>}/>
                    <MigratedReloadableRoute path={`${this.props.path}`} exact={true} component={ChannelsPage}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>);
    }
    static displayName = "ChannelsRoute";
}
const EnhancedChannelsRoute = withRouter(ChannelsRoute);
export default EnhancedChannelsRoute;
export const MigratedChannelsRoute = EnhancedChannelsRoute;
