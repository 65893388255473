import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import { useState } from "react";
import * as React from "react";
import type { StepEditorEvent } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import MaxParallelism from "~/areas/projects/components/Process/Common/MaxParallelism";
import styles from "~/areas/projects/components/Process/Common/style.module.less";
import type { RunOn } from "~/areas/projects/components/Process/types";
import { ExecutionLocation } from "~/areas/projects/components/Process/types";
import { repository, session } from "~/clientInstance";
import { RoleChip } from "~/components/Chips/index";
import { TargetTagsContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { RoleMultiSelect } from "~/components/MultiSelect/RoleMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
import ParseHelper from "~/utils/ParseHelper/index";
type TargetRolesFormSectionProps = {
    projectId: string;
    expandedByDefault: boolean;
    title?: string;
    availableRoles: string[];
    targetRoles: string | undefined;
    onTargetRolesChanged: (roles: string[]) => void;
    runOn: RunOn;
    errorMessage: string | undefined;
    maxParallelism: string;
    onMaxParallelismChanged: (max: string) => void;
    analyticsStepEditorDispatch: (name: string, event: Omit<StepEditorEvent, "stepTemplate">) => void;
};
const RollingDeploymentSummary = (props: {
    showWindowSize: boolean;
    maxParallelism: string;
}) => props.showWindowSize ? (<span>
            This is a rolling deployments step that will run on{" "}
            <strong>
                {props.maxParallelism} target{props.maxParallelism === "1" ? "" : "s"}
            </strong>{" "}
            at once.
        </span>) : (<span>This step will be run on all deployment targets in parallel.</span>);
const RunOnRolesHelp = (props: {
    executionLocation: ExecutionLocation;
}) => (<span>
        Target roles are used as tags to select the deployment targets this step will execute {props.executionLocation === ExecutionLocation.DeploymentTarget ? " on" : " on behalf of"}. Deployment targets are configured in{" "}
        {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
        <InternalLink to={links.infrastructureRootRedirect.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false}>
            Infrastructure
        </InternalLink>
        .
    </span>);
const SuggestedTargetRoles = (props: Pick<TargetRolesFormSectionProps, "targetRoles" | "onTargetRolesChanged" | "analyticsStepEditorDispatch">) => {
    const { targetRoles, onTargetRolesChanged, analyticsStepEditorDispatch } = props;
    const suggestedRoles = ["web-server", "app-server", "db-server"];
    const onSelectSuggestedRole = (e: React.MouseEvent<HTMLAnchorElement>, targetName: string) => {
        e.preventDefault();
        const prevTargets = ParseHelper.parseCSV(targetRoles || "");
        if (prevTargets.includes(targetName)) {
            return;
        }
        analyticsStepEditorDispatch?.("Select Suggested Target", { action: Action.Select, resource: "Target Role", value: targetName });
        onTargetRolesChanged(prevTargets.concat(targetName));
    };
    return (<ul className={styles.suggestedTargetRoles}>
            {suggestedRoles.map((name) => (<li key={name}>
                    <a href="#" onClick={(e) => onSelectSuggestedRole(e, name)}>
                        {name}
                    </a>
                </li>))}
        </ul>);
};
export const TargetRolesFormSection = (props: TargetRolesFormSectionProps) => {
    const { projectId, title, expandedByDefault, targetRoles, runOn, onTargetRolesChanged, errorMessage, availableRoles, maxParallelism, onMaxParallelismChanged } = props;
    const [showWindowSize, setShowWindowSize] = useState<boolean>(maxParallelism ? maxParallelism.length > 0 : false);
    // used to make sure the rolling deployment section is expanded when it's shown (irrespective of expandedByDefault)
    const [togglingWindowSize, setTogglingWindowSize] = useState(false);
    const suggestedTargetRolesFeatureToggleEnabled = session.featureToggles?.includes("SuggestedTargetRolesFeatureToggle");
    const roleSummary = () => {
        if (!targetRoles) {
            return Summary.placeholder("No roles selected");
        }
        const list = ParseHelper.parseCSV(targetRoles);
        const roleChips = list.map((r) => <RoleChip role={r} key={`role-${r}`} showContextualHelp/>);
        return Summary.summary(roleChips);
    };
    return (<>
            <div>
                <ExpandableFormSection isExpandedByDefault={expandedByDefault} summary={roleSummary()} title={title || (runOn.executionLocation === ExecutionLocation.DeploymentTarget ? "On Targets in Roles" : "On Behalf Of")} errorKey="Octopus.Action.TargetRoles" help={<RunOnRolesHelp executionLocation={runOn.executionLocation}/>} contextualHelp={<TargetTagsContextualHelp />}>
                    <Note>
                        Learn more about <ExternalLink href="TargetRoles">target roles</ExternalLink>.
                    </Note>
                    <RoleMultiSelect onChange={onTargetRolesChanged} value={ParseHelper.parseCSV(targetRoles || "")} label={runOn.executionLocation === ExecutionLocation.DeploymentTarget ? `Runs on targets in roles (type to add new)` : `On behalf of target roles (type to add new)`} validate={(roles) => (roles.length === 0 ? "Please enter one or more roles" : "")} error={errorMessage} items={availableRoles} canAdd={true} empty={suggestedTargetRolesFeatureToggleEnabled && (<div className={styles.targetRolesEmpty}>
                                    Type to create {targetRoles ? "another" : "your first"} target role or choose from common target role names: {<SuggestedTargetRoles {...props}/>}.
                                </div>)} accessibleName={"Target roles selector"}/>
                    {suggestedTargetRolesFeatureToggleEnabled ? <Note>Common target role names: {<SuggestedTargetRoles {...props}/>}</Note> : <Note>This step will run on all deployment targets with these roles.</Note>}
                    {!showWindowSize && (<>
                            <ActionButton label="Configure a rolling deployment" type={ActionButtonType.Ternary} onClick={() => {
                setShowWindowSize(true);
                setTogglingWindowSize(true);
                onMaxParallelismChanged("1");
            }}/>
                        </>)}
                    {showWindowSize && (<>
                            <ActionButton label="Deploy to all deployment targets in parallel" type={ActionButtonType.Ternary} onClick={() => {
                setShowWindowSize(false);
                onMaxParallelismChanged("");
            }}/>
                        </>)}
                </ExpandableFormSection>
            </div>
            {showWindowSize && (<div>
                    <ExpandableFormSection isExpandedByDefault={expandedByDefault || togglingWindowSize} summary={Summary.summary(<RollingDeploymentSummary showWindowSize={showWindowSize} maxParallelism={maxParallelism}/>)} title="Rolling Deployment" errorKey="Octopus.Action.RollingDeployment" help="Select the rolling window Octopus should use for this step. ">
                        <Note>
                            Learn more about <ExternalLink href="rolling-deployments">Rolling Deployments</ExternalLink>
                        </Note>
                        <MaxParallelism key="maxparallelism" projectId={projectId} value={maxParallelism} onChange={(x) => onMaxParallelismChanged(x)}/>
                    </ExpandableFormSection>
                </div>)}
        </>);
};
