import { css } from "@emotion/css";
import { ActionButtonType, CircularProgress, SimpleDataTable } from "@octopusdeploy/design-system-components";
import type { ConnectTenantsToProjectTaskResource, ProjectResource, ProjectTenant, TenantResource } from "@octopusdeploy/octopus-server-client";
import { BusinessProcessState, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticConnectTenantsDispatch } from "~/analytics/Analytics";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import { ProjectStatus } from "~/areas/projects/components/ProjectStatus/ProjectStatus";
import ConnectTenantsButton from "~/areas/projects/components/ProjectTenants/ConnectTenantsButton";
import Onboarding from "~/areas/projects/components/ProjectTenants/Onboarding";
import { useConnectionTaskLoop } from "~/areas/projects/components/ProjectTenants/hooks/useConnectionTaskLoop";
import { useProjectTenantsState } from "~/areas/projects/components/ProjectTenants/hooks/useProjectTenantsState";
import { useProjectContext } from "~/areas/projects/context/index";
import { UpdateConnectionForTenantDialog } from "~/areas/tenants/TenantOverview/UpdateConnectionForTenantDialog";
import { AddOrCloneTenant } from "~/areas/tenants/Tenants/AddOrCloneTenant";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { TenantDataTableAllEnvironmentsCell } from "~/areas/tenants/components/DataTable/Cells/TenantDataTableEnvironmentsCell";
import { TenantsDataTableNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableNameCell";
import CollapsibleFilter from "~/areas/tenants/components/HeaderBar/CollapsibleFilter";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import DataBaseComponent from "~/components/DataBaseComponent/index";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction, PrimaryPageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import useLocalStorage from "~/hooks/useLocalStorage";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
interface ProjectTenantsProps {
    spaceId: string;
}
export default class ProjectTenantsPage extends DataBaseComponent<ProjectTenantsProps> {
    constructor(props: ProjectTenantsProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <ProjectTenantsLayout spaceId={this.props.spaceId} busy={this.state.busy} errors={this.errors} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "ProjectTenantsPage";
}
interface ProjectTenantsLayoutProps {
    spaceId: string;
    busy?: Promise<void>;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
}
function ProjectTenantsLayout({ spaceId, busy, errors, doBusyTask }: ProjectTenantsLayoutProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    if (!project) {
        throw new Error("Failed to find project from context. This should not happen.");
    }
    const dispatchAction = useAnalyticConnectTenantsDispatch();
    const [latestConnectionTask, loop] = useConnectionTaskLoop(doBusyTask, project.Id);
    const [showActionButtons, setShowActionButtons] = useState(false);
    const onTenantConnected = async (numberOfTenantsConnected: number) => {
        dispatchAction("Connect Tenants", { action: Action.Save, resource: "Tenant", numTenants: numberOfTenantsConnected });
        await loop.refresh();
    };
    const addOrCloneTenantPageAction: PageAction = {
        type: "custom",
        key: "Add or clone tenant",
        hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project.Id }),
        content: <AddOrCloneTenant type={ActionButtonType.Secondary} project={project}/>,
    };
    const isConnectTenantsDisabled = latestConnectionTask?.State === BusinessProcessState.InProgress;
    const connectTenantsPageAction: PrimaryPageAction = {
        type: "custom",
        key: "Connect tenants",
        hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project.Id }),
        content: <ConnectTenantsButton project={project} onConnected={onTenantConnected} disabled={isConnectTenantsDisabled}/>,
    };
    return (<ProjectPageLayout busy={busy} errors={errors} title="Tenants" fullWidth={true} statusSection={<ProjectStatus doBusyTask={doBusyTask}/>} pageActions={showActionButtons ? [addOrCloneTenantPageAction] : undefined} primaryAction={showActionButtons ? connectTenantsPageAction : undefined}>
            <ProjectTenantsContent spaceId={spaceId} latestConnectionTask={latestConnectionTask} doBusyTask={doBusyTask} project={project} updateShowActionButtons={(show) => setShowActionButtons(show)} onTenantConnected={onTenantConnected}/>
        </ProjectPageLayout>);
}
interface ProjectTenantsContentProps {
    spaceId: string;
    doBusyTask: DoBusyTask;
    project: ProjectResource;
    updateShowActionButtons: (show: boolean) => void;
    onTenantConnected: (numberOfTenantsConnected: number) => void;
    latestConnectionTask: ConnectTenantsToProjectTaskResource | null;
}
function ProjectTenantsContent({ spaceId, doBusyTask, project, updateShowActionButtons, onTenantConnected, latestConnectionTask }: ProjectTenantsContentProps) {
    const { searchName, tableState, pageState, actions } = useProjectTenantsState(project.Id, doBusyTask, updateShowActionButtons, latestConnectionTask);
    if (!tableState) {
        return null;
    }
    if (tableState.totalTenantCount === 0) {
        return (<React.Fragment>
                {latestConnectionTask ? <ConnectionTaskCallout latestConnectionTask={latestConnectionTask}/> : null}
                <Onboarding project={project} onConnected={onTenantConnected}/>
            </React.Fragment>);
    }
    const handleRemoveConfirm = async (tenantId: string) => {
        await doBusyTask(async () => {
            const tenant = await repository.Tenants.get(tenantId);
            delete tenant.ProjectEnvironments[project.Id];
            await repository.Tenants.save(tenant);
            actions.refreshTable();
        });
    };
    const onTenantUpdated = () => {
        actions.refreshTable();
    };
    return (<>
            {latestConnectionTask ? <ConnectionTaskCallout latestConnectionTask={latestConnectionTask}/> : null}
            <TenantFiltersBar totalTenantCount={tableState.totalTenantCount} filteredTenantsCount={tableState.filteredTenantsCount} searchName={searchName} onSearchNameChanged={actions.setSearchName}/>
            <SimpleDataTable columns={[
            {
                accessibleName: "Tenant Name",
                columnSize: "medium",
                title: "Tenant",
                render: (tenant) => <TenantsDataTableNameCellWithLink spaceId={spaceId} tenantId={tenant.Id} tenantName={tenant.Name} tenantLogoLink={tenant.LogoLink}/>,
            },
            {
                accessibleName: "Connected Environments",
                columnSize: "large",
                title: "Environment",
                render: (tenant) => <LocalEnvironmentsSummaryCell tenant={tenant} summaryCount={3} handleRemoveConfirm={handleRemoveConfirm} onUpdated={onTenantUpdated} project={project}/>,
            },
        ]} data={tableState.tenants} getRowKey={(tenant) => tenant.Id} accessibleName={"Connected Tenants"}/>
            <div className={styles.pagingBar}>
                <NumberedPagingBar totalItems={tableState.filteredTenantsCount} pageNumber={pageState.pageNumber} pageSize={pageState.pageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            actions.setPageNumber(newPageNumber);
            actions.setPageSize(newPageSize);
        }} pageSizeOptions={[30, 50, 100]}/>
            </div>
        </>);
}
interface ConnectionTaskCalloutProps {
    latestConnectionTask: ConnectTenantsToProjectTaskResource;
}
function ConnectionTaskCallout({ latestConnectionTask }: ConnectionTaskCalloutProps) {
    const [taskIdDismissed, setTaskIdDismissed] = useLocalStorage<string | undefined>("Octopus.Callout.TenantConnection.TaskIdDismissed", undefined);
    const onCloseCallout = () => {
        setTaskIdDismissed(latestConnectionTask.Id);
    };
    if (latestConnectionTask.State === BusinessProcessState.Completed) {
        if (taskIdDismissed && taskIdDismissed === latestConnectionTask.Id) {
            return null;
        }
        const successfulTenantWording = latestConnectionTask.ConnectionsCompleted === 1 ? "tenant has" : "tenants have";
        if (latestConnectionTask.ConnectionsFailed === 0) {
            return (<Callout title={"Successful tenant connection"} type={CalloutType.Success} canClose={true} onClose={onCloseCallout}>
                    <div>
                        {latestConnectionTask.ConnectionsCompleted} {successfulTenantWording} recently been connected
                    </div>
                </Callout>);
        }
        return (<Callout title={"Latest tenant connection completed with errors"} type={CalloutType.Danger} canClose={true} onClose={onCloseCallout}>
                <div>
                    During the last connection request {latestConnectionTask.ConnectionsFailed} out of {latestConnectionTask.ConnectionsRequested} tenants failed to connect to this project.
                    <br />
                    Please review these connection errors in the <ExternalLink href="LogFiles">Octopus Server logs.</ExternalLink>
                </div>
            </Callout>);
    }
    return (<Callout title={"Connecting tenants..."} type={CalloutType.Information}>
            <div className={styles.connectionTaskInfo}>
                Connecting tenant {latestConnectionTask.ConnectionsCompleted + 1} of {latestConnectionTask.ConnectionsRequested}
                <CircularProgress size={"small"}/>
            </div>
        </Callout>);
}
interface LocalEnvironmentsSummaryCellProps {
    summaryCount: number;
    tenant: ProjectTenant;
    project: ProjectResource;
    handleRemoveConfirm: (tenantId: string) => void;
    onUpdated: (tenant: TenantResource) => void;
}
function LocalEnvironmentsSummaryCell({ summaryCount, tenant, project, handleRemoveConfirm, onUpdated }: LocalEnvironmentsSummaryCellProps) {
    const [showAll, setShowAll] = useState<boolean>(false);
    const environmentNames = tenant.Environments.map((e) => e.Name);
    const hasMissingVariables = tenant.HasMissingVariables;
    const change = () => {
        setShowAll(!showAll);
    };
    return (<div className={styles.environmentsCell}>
            <div className={styles.environments}>
                <TenantDataTableAllEnvironmentsCell environments={showAll ? environmentNames : environmentNames.slice(0, summaryCount)} hasMissingVariables={hasMissingVariables}/>
                {environmentNames.length <= summaryCount ? <></> : <Leftovers count={environmentNames.length - summaryCount} name={"environment"} onShowAllChanged={change} showAll={showAll}/>}
            </div>
            <OverflowMenu menuItems={[
            OverflowMenuItems.dialogItem("Edit", <UpdateConnectionForTenantDialog onUpdated={onUpdated} projectId={project.Id} projectName={project.Name} tenantId={tenant.Id} selectedEnvironmentIds={tenant.Environments.map((e) => e.Id)}/>, {
                permission: Permission.TenantEdit,
                tenant: tenant.Id,
            }),
            OverflowMenuItems.removeItem("Remove", `Unlink Tenant from Project`, () => handleRemoveConfirm(tenant.Id), <div>
                            Are you sure you want to unlink {tenant.Name} from {project.Name}?
                        </div>, {
                permission: Permission.TenantEdit,
                tenant: tenant.Id,
            }),
        ]}/>
        </div>);
}
interface TenantFiltersBarProps {
    totalTenantCount: number;
    filteredTenantsCount: number | null;
    searchName: string;
    onSearchNameChanged: (newName: string) => void;
}
function TenantFiltersBar({ searchName, onSearchNameChanged, totalTenantCount, filteredTenantsCount }: TenantFiltersBarProps) {
    return (<CollapsibleFilter totalCount={totalTenantCount} filteredCount={filteredTenantsCount === totalTenantCount ? null : filteredTenantsCount} entityName={"tenant"} secondaryContent={<FilterSearchBox placeholder={"Filter by name..."} value={searchName} onChange={onSearchNameChanged} autoFocus={true}/>}/>);
}
const styles = {
    pagingBar: css({
        padding: "0 1rem",
    }),
    environmentsCell: css({
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
    }),
    environments: css({
        display: "flex",
        alignItems: "center",
    }),
    connectionTaskInfo: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "0.5rem",
    }),
};
