/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { RunbookTriggersPage } from "../Triggers/RunbookTriggersPage";
import { CreateRunbookScheduledTriggersPage } from "./CreateRunbookScheduledTriggersPage";
import { EditRunbookScheduledTriggersPage } from "./EditRunbookScheduledTriggersPage";
export const EditRunbookScheduledTriggerPageWithPageDefinition = withPage({ page: pageIds.project().runbook.scheduledTrigger })(EditRunbookScheduledTriggersPage);
export const CreateRunbookScheduledTriggerPageWithPageDefinition = withPage({ page: pageIds.project().runbook.scheduledTriggerNew })(CreateRunbookScheduledTriggersPage);
export const TriggersPageWithPageDefinition = withPage({ page: pageIds.project().runbook.triggers })(RunbookTriggersPage);
type RunbookTriggersRouteProps = {
    path: string;
} & RouteComponentProps<ProjectRouteParams>;
type CreateScheduledTriggerRouteProps = RouteComponentProps<{
    spaceId: string;
    projectSlug: string;
    runbookId?: string;
}>;
type EditScheduledTriggerRouteProps = RouteComponentProps<{
    triggerId: string;
    runbookId?: string;
    spaceId: string;
    projectSlug: string;
}>;
function RunbookTriggersInternalRoute({ path, match }: RunbookTriggersRouteProps) {
    const { spaceId, projectSlug } = match.params;
    return (<Switch>
            <MigratedReloadableRoute path={`${path}/scheduled/edit/:triggerId`} render={({ match }: EditScheduledTriggerRouteProps) => <EditRunbookScheduledTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug} triggerId={match.params.triggerId}/>}/>
            <MigratedReloadableRoute path={`${path}/scheduled/create/runbooks/:runbookId?`} render={({ match }: CreateScheduledTriggerRouteProps) => <CreateRunbookScheduledTriggerPageWithPageDefinition runbookId={match.params.runbookId} projectSlug={match.params.projectSlug} spaceId={match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={path} render={() => <TriggersPageWithPageDefinition spaceId={spaceId} projectSlug={projectSlug}/>}/>
        </Switch>);
}
export const RunbookTriggersRoute = withRouter(RunbookTriggersInternalRoute);
