import { css } from "@emotion/css";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
type ResourceLinkProps = {
    icon: React.ReactNode;
    label: string;
    href: string;
    info?: boolean;
};
const styles = {
    button: css({
        WebkitFontSmoothing: "antialiased",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        transitionTimingFunction: "ease-in-out",
        transitionProperty: "background-color, border-color, outline, color, fill",
        transitionDuration: "0.2s",
        borderRadius: borderRadius.medium,
        paddingInlineStart: space[12],
        paddingInlineEnd: space[12],
        whiteSpace: "nowrap",
        color: themeTokens.color.button.text.neutral,
        background: themeTokens.color.button.background.neutral.default,
        font: text.button.default.small,
        padding: space[6],
        svg: {
            fill: themeTokens.color.button.icon.neutral,
        },
        ":hover": {
            background: themeTokens.color.button.background.neutral.hover,
            color: themeTokens.color.button.text.neutral,
        },
        ":focus-visible": {
            background: themeTokens.color.button.background.neutral.hover,
        },
        ":active": {
            background: themeTokens.color.button.background.neutral.pressed,
            color: themeTokens.color.button.text.neutral,
        },
    }),
    label: css({
        marginRight: space[4],
        marginLeft: space[4],
    }),
};
export function ResourceLink({ label, href, icon, info = false }: ResourceLinkProps) {
    const dispatchAction = useAnalyticActionDispatch();
    function handleClick(e: React.MouseEvent<HTMLAnchorElement, {}>) {
        dispatchAction("Help Sidebar: Resource Link Clicked", { action: Action.View, resource: label });
        e.stopPropagation();
    }
    return (<a className={styles.button} target="_blank" rel="noopener, noreferrer" href={href} onClick={handleClick}>
            {icon}
            <span className={styles.label}>{label}</span>
        </a>);
}
