/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { generatePath } from "react-router";
import type { RouteComponentProps } from "react-router-dom";
import { Switch, withRouter } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect";
import pageIds from "../../../../pageIds";
import { runbookLinks } from "../OperationsRoute";
import RunbookContextLayout from "./RunbookContextLayout";
import RunbookOverviewLayout from "./RunbookOverviewPage";
import { RunbookSettingsAndProcessRoutes } from "./RunbookRoutesWithBranching";
import type { RunbookRoutingProps } from "./RunbookRoutingProps";
import { RunbookRunNowPage } from "./RunbookRunNowPage";
import { RunbookRunsListPage } from "./RunbookRunsListPage";
import { RunbookSnapshotsRoute } from "./RunbookSnapshots/RunbookSnapshotsRoute";
export const RunbookOverviewPageWithPageDefinition = withPage({ page: pageIds.project().runbook.root })(RunbookOverviewLayout);
export const RunbookRunsListPageWithPageDefinition = withPage({ page: pageIds.project().runbook.runsList })(RunbookRunsListPage);
export const RunbookRunNowPageWithPageDefinition = withPage({ page: pageIds.project().runbook.runNow })(RunbookRunNowPage);
type SpecificRunbookRouteProps = RouteComponentProps<{
    spaceId: string;
    projectSlug: string;
    runbookId: string;
}>;
class RunbookRoutes extends React.Component<RunbookRoutingProps> {
    render() {
        const links = runbookLinks(this.props.path);
        return (<Switch>
                <MigratedReloadableRoute path={links.root} render={(props: SpecificRunbookRouteProps) => (<RunbookContextLayout runbookId={props.match.params.runbookId}>
                            {(context) => (<Switch>
                                    <MigratedReloadableRoute exact={true} path={links.root} render={(props: RouteComponentProps<any>) => {
                        return <InternalRedirect to={`${generatePath(links.overview, props.match.params)}${props.location.search}`}/>;
                    }}/>
                                    <MigratedReloadableRoute path={links.overview} render={({ match }: SpecificRunbookRouteProps) => <RunbookOverviewPageWithPageDefinition spaceId={match.params.spaceId} runbookId={match.params.runbookId} projectSlug={match.params.projectSlug}/>}/>
                                    <MigratedReloadableRoute path={links.runslist} render={() => <RunbookRunsListPageWithPageDefinition />}/>
                                    <MigratedReloadableRoute path={links.runNow.root} exact={true} render={(props) => <RunbookRunNowPageWithPageDefinition />}/>
                                    <MigratedReloadableRoute path={links.runNow.runbookSnapshot(":runbookSnapshotId")} exact={true} render={({ match }: RouteComponentProps<{
                    runbookSnapshotId: string;
                }>) => <RunbookRunNowPageWithPageDefinition runbookSnapshotId={match.params.runbookSnapshotId}/>}/>
                                    <MigratedRunbookSnapshotsRoute path={links.runbookSnapshots}/>
                                    <MigratedRunbookSettingsAndProcessRoutes path={links.root}/>
                                    <RedirectAs404 />
                                </Switch>)}
                        </RunbookContextLayout>)}/>
            </Switch>);
    }
    static displayName = "RunbookRoutes";
}
const MigratedRunbookSnapshotsRoute = RunbookSnapshotsRoute;
const MigratedRunbookSettingsAndProcessRoutes = RunbookSettingsAndProcessRoutes;
const EnhancedRunbookRoutes = withRouter(RunbookRoutes);
export { EnhancedRunbookRoutes };
