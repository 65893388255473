/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { DynamicEnvironmentSettingsPage } from "~/areas/projects/components/ProjectSettings/DynamicEnvironments/DynamicEnvironmentSettingsPage";
import { ProjectSettingsPage } from "~/areas/projects/components/ProjectSettings/ProjectSettingsPage";
import { VersionControlSettingsPage } from "~/areas/projects/components/ProjectSettings/VersionControl/VersionControlSettingsPage";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect/index";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
const ProjectSettingsPageWithPageDefinition = withPage({ page: pageIds.project().settings.root })(ProjectSettingsPage);
const VersionControlSettingsPageWithPageDefinition = withPage({ page: pageIds.project().settings.versionControl })(VersionControlSettingsPage);
const DynamicEnvironmentSettingsPageWithPageDefinition = withPage({ page: pageIds.project().settings.dynamicEnvironments })(DynamicEnvironmentSettingsPage);
const ProjectSettingsRoute: React.FC = (props: any) => {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const match = props.match;
    return (<Switch>
            <MigratedReloadableRoute path={match.path} exact render={() => <ProjectSettingsPageWithPageDefinition />}/>
            <MigratedReloadableRoute path={`${match.path}/versioncontrol`} render={({ match }: RouteComponentProps<{
        spaceId: string;
        projectSlug: string;
    }>) => <VersionControlSettingsPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug} branchName={undefined}/>}/>
            {dynamicEnvironmentsEnabled && <MigratedReloadableRoute path={`${match.path}/dynamicenvironments`} render={() => <DynamicEnvironmentSettingsPageWithPageDefinition />}/>}
            <InternalRedirect from={match.path} to={match.url}/>
        </Switch>);
};
ProjectSettingsRoute.displayName = "ProjectSettingsRoute"
export default ProjectSettingsRoute;
