import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import EditFeedTrigger from "./Release/EditReleaseCreationFeedTrigger";
interface EditReleaseCreationFeedTriggersPageProps {
    spaceId: string;
    projectSlug: string;
    triggerId: string;
}
export function EditReleaseCreationFeedTriggersPage({ spaceId, projectSlug, triggerId }: EditReleaseCreationFeedTriggersPageProps) {
    return <EditFeedTrigger create={false} triggerActionCategory={TriggerActionCategory.Release} spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>;
}
