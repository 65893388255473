/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { AccountType, CertificateResource, PropertyValueResource, SensitiveValue, TenantVariableTemplateResource, WorkerPoolResource, AccountResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, isSensitiveValue } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import AmazonWebServicesAccountInput from "~/components/ControlInputs/AmazonWebServicesAccountInput";
import AzureAccountInput from "~/components/ControlInputs/AzureAccountInput";
import CertificateInput from "~/components/ControlInputs/CertificateInput";
import CheckboxInput from "~/components/ControlInputs/CheckboxInput";
import GoogleCloudAccountInput from "~/components/ControlInputs/GoogleCloudAccountInput";
import MultiLineTextInput from "~/components/ControlInputs/MultiLineTextInput";
import SelectInput from "~/components/ControlInputs/SelectInput";
import SensitiveInput from "~/components/ControlInputs/SensitiveInput";
import SingleLineTextInput from "~/components/ControlInputs/SingleLineTextInput";
import UsernamePasswordAccountInput from "~/components/ControlInputs/UsernamePasswordAccountInput";
import WorkerPoolInput from "~/components/ControlInputs/WorkerPoolInput";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import selectOptionsToItems from "../../primitiveComponents/form/Select/Options";
import type FormFieldProps from "../form/FormFieldProps";
import DefaultValueIndicator from "./DefaultValueIndicator";
import styles from "./style.module.less";
export interface SourceItems {
    accounts: {
        type: AccountType | AccountType[];
        items: AccountResource[];
        onRequestRefresh(): Promise<void>;
    };
    certificates: {
        items(): Promise<CertificateResource[]>;
        onRequestRefresh(): Promise<boolean>;
    };
    workerPools: {
        items(): Promise<WorkerPoolResource[]>;
        onRequestRefresh(): Promise<boolean>;
    };
    tenantId: string;
}
interface TenantVariableInputProps extends FormFieldProps<PropertyValueResource | undefined> {
    variableTemplate: TenantVariableTemplateResource;
    localNames?: string[];
    sourceItems: SourceItems;
    disabled?: boolean;
    error?: string;
    warning?: string;
    actionType?: string;
    hideSearch?: boolean; // where applicable, hide search button which opens a new dialog
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    withoutDebounce?: boolean;
}
class TenantVariableInput extends React.Component<TenantVariableInputProps, never> {
    render() {
        return <div className={styles.container}>{this.getInputControl()}</div>;
    }
    private getInputControl = () => {
        const { value, localNames, sourceItems, error, warning, actionType, doBusyTask, variableTemplate, hideSearch, withoutDebounce, ...rest } = this.props;
        const type = variableTemplate.DisplaySettings["Octopus.ControlType"];
        const label = variableTemplate.Label || variableTemplate.Name;
        const formProps = { ...rest, label };
        const defaultValue = variableTemplate.DefaultValue;
        const defaultValueIndicator = <DefaultValueIndicator value={value} defaultValue={defaultValue} onChange={this.props.onChange}/>;
        const valueOrDefault = this.getValueOrDefault(value, defaultValue) as string;
        switch (type) {
            case ControlType.SingleLineText:
                return <SingleLineTextInput value={valueOrDefault} localNames={localNames} warning={warning} defaultValueIndicator={defaultValueIndicator} withoutDebounce={withoutDebounce} {...formProps}/>;
            case ControlType.MultiLineText:
                return <MultiLineTextInput value={valueOrDefault} localNames={localNames} warning={warning} defaultValueIndicator={defaultValueIndicator} {...formProps}/>;
            case ControlType.Select: {
                const options = selectOptionsToItems(variableTemplate.DisplaySettings["Octopus.SelectOptions"]);
                const allowClear = variableTemplate.AllowClear !== undefined ? variableTemplate.AllowClear : true;
                return (<SelectInput value={valueOrDefault} resetValue={defaultValue as string} options={options} localNames={localNames} warning={warning} allowClear={allowClear} defaultValueIndicator={defaultValueIndicator} doBusyTask={doBusyTask} {...formProps}/>);
            }
            case ControlType.Checkbox:
                return <CheckboxInput value={valueOrDefault} resetValue={defaultValue as string} localNames={localNames} warning={warning} {...formProps} defaultValueIndicator={defaultValueIndicator} doBusyTask={doBusyTask}/>;
            case ControlType.Sensitive:
                return <SensitiveInput value={value as SensitiveValue} localNames={localNames} warning={warning} parameter={variableTemplate} defaultValueIndicator={defaultValueIndicator} {...formProps}/>;
            case ControlType.Certificate:
                return (<CertificateInput value={valueOrDefault as string} items={sourceItems.certificates.items} tenantId={sourceItems.tenantId} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={sourceItems.certificates.onRequestRefresh} doBusyTask={doBusyTask} hideSearch={hideSearch} {...formProps}/>);
            case ControlType.WorkerPool:
                return (<WorkerPoolInput value={valueOrDefault} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} doBusyTask={doBusyTask} items={sourceItems.workerPools.items} onRequestRefresh={sourceItems.workerPools.onRequestRefresh} hideSearch={hideSearch} {...formProps}/>);
            case ControlType.AmazonWebServicesAccount:
                return (<AmazonWebServicesAccountInput value={valueOrDefault} resetValue={defaultValue as string} items={sourceItems.accounts.items} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={sourceItems.accounts.onRequestRefresh} {...formProps}/>);
            case ControlType.AzureAccount:
                return (<AzureAccountInput value={valueOrDefault} resetValue={defaultValue as string} items={sourceItems.accounts.items} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={sourceItems.accounts.onRequestRefresh} {...formProps}/>);
            case ControlType.GoogleCloudAccount:
                return (<GoogleCloudAccountInput value={valueOrDefault} resetValue={defaultValue as string} items={sourceItems.accounts.items} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={sourceItems.accounts.onRequestRefresh} {...formProps}/>);
            case ControlType.UsernamePasswordAccount:
                return (<UsernamePasswordAccountInput value={valueOrDefault} resetValue={defaultValue as string} items={sourceItems.accounts.items} variableLookup={{
                        localNames,
                    }} warning={warning} allowClear={true} defaultValueIndicator={defaultValueIndicator} onRequestRefresh={sourceItems.accounts.onRequestRefresh} {...formProps}/>);
            default:
                return (<React.Fragment>
                        <DebounceText value={valueOrDefault} warning={warning} {...formProps}/>
                        {defaultValueIndicator}
                    </React.Fragment>);
        }
    };
    private getValueOrDefault(value: PropertyValueResource | undefined, defaultValue: PropertyValueResource | undefined): PropertyValueResource | undefined {
        if (isSensitiveValue(defaultValue))
            return value;
        return value !== undefined && value !== null ? value : defaultValue;
    }
    static displayName = "TenantVariableInput";
}
export default TenantVariableInput;
export { TenantVariableInputProps };
