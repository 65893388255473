/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { BasicRepository, MachineConnectionStatus, MachineResource, NewMachineResource } from "@octopusdeploy/octopus-server-client";
import { CommunicationStyle, MachineModelHealthStatus, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { Section } from "~/components/Section/Section";
import TaskLogLines from "~/components/TaskLogLines/TaskLogLines";
import { Note } from "~/components/form";
import { Callout, CalloutType } from "~/primitiveComponents/dataDisplay/Callout/Callout";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import { DataTableRowHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowHeaderColumn";
import DateFormatter from "~/utils/DateFormatter";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface MachineConnectionsBaseProps {
    repository: BasicRepository<MachineResource, NewMachineResource>;
    itemDescription: string;
    getConnectionStatus(machine: MachineResource): Promise<MachineConnectionStatus>;
}
interface MachineConnectionsProps {
    machineId: string;
}
type MachineConnectionsPageProps = MachineConnectionsBaseProps & MachineConnectionsProps;
interface MachineConnectionsInternalProps extends MachineConnectionsPageProps {
    initialData: InitialData;
}
interface MachineConnectionsState extends DataBaseComponentState {
    redirectToTaskId?: string;
    machine: MachineResource;
}
interface InitialData {
    machine: MachineResource;
    connectionStatus: MachineConnectionStatus;
}
export function DeploymentTargetConnectionsPage({ machineId }: MachineConnectionsProps) {
    return <MachineConnectionsPage repository={repository.Machines} itemDescription={"deployment target"} getConnectionStatus={(machine: MachineResource) => repository.Machines.getConnectionStatus(machine)} machineId={machineId}/>;
}
export function WorkerMachineConnectionsPage({ machineId }: MachineConnectionsProps) {
    return <MachineConnectionsPage repository={repository.Workers} itemDescription={"worker machine"} getConnectionStatus={(machine: MachineResource) => repository.Workers.getConnectionStatus(machine)} machineId={machineId}/>;
}
const Title = "Connectivity";
const MachineConnectionsFormPage = FormPage<InitialData>();
const MachineConnectionsPage: React.FC<MachineConnectionsPageProps> = (props: MachineConnectionsPageProps) => {
    return (<MachineConnectionsFormPage title={Title} load={async () => {
            const machineId = props.machineId;
            const machine = await props.repository.get(machineId);
            const connectionStatus = await props.getConnectionStatus(machine);
            return { machineId, machine, connectionStatus };
        }} renderWhenLoaded={(data) => <MachineConnectionsLayoutInternal initialData={data} {...props}/>}/>);
};
MachineConnectionsPage.displayName = "MachineConnectionsPage"
class MachineConnectionsLayoutInternal extends DataBaseComponent<MachineConnectionsInternalProps, MachineConnectionsState> {
    constructor(props: MachineConnectionsInternalProps) {
        super(props);
        this.state = {
            machine: props.initialData.machine,
        };
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const checkHealthPageAction: PrimaryPageAction = {
            type: "button",
            label: "Check health",
            disabled: this.state.busy,
            onClick: () => this.performHealthCheck(),
        };
        const communicationsSection = this.renderCommunicationStyleInfo();
        const calamariUpgradeSection = this.renderCalamariUpgradeInfo();
        const tentacleSection = this.renderTentacleInfo();
        const logsSection = this.renderLogsInfo();
        return (<PaperLayout title={Title} primaryAction={checkHealthPageAction} busy={this.state.busy} errors={this.errors}>
                {communicationsSection && <Section>{communicationsSection}</Section>}

                {calamariUpgradeSection && <Section sectionHeader="Calamari Upgrade Recommended">{calamariUpgradeSection}</Section>}

                {tentacleSection && <Section sectionHeader="Tentacle">{tentacleSection}</Section>}

                {logsSection && <Section sectionHeader="Recent Communication Logs">{logsSection}</Section>}
            </PaperLayout>);
    }
    private renderLogsInfo() {
        const connectionStatus = this.props.initialData.connectionStatus;
        if (!connectionStatus) {
            return null;
        }
        return <TaskLogLines lines={connectionStatus.Logs} showAdditional={noOp}/>;
    }
    private renderCommunicationStyleInfo() {
        const machine = this.state.machine;
        const connectionStatus = this.props.initialData.connectionStatus;
        if (machine.Endpoint.CommunicationStyle === CommunicationStyle.None) {
            return (<Callout type={CalloutType.Success} title="Healthy">
                    <p>Cloud Regions are always healthy. Hooray!</p>
                </Callout>);
        }
        else {
            switch (connectionStatus.Status) {
                case MachineModelHealthStatus.Healthy:
                    return (<Callout type={CalloutType.Success} title="Healthy">
                            <p>The last health check completed successfully</p>
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.Unhealthy:
                    return (<Callout type={CalloutType.Danger} title="Unhealthy">
                            <p>The last health check encountered errors</p>
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.HasWarnings:
                    return (<Callout type={CalloutType.Warning} title="Healthy with warnings">
                            <p>The last health check encountered warnings</p>
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.Unavailable:
                    return (<Callout type={CalloutType.Danger} title="Unavailable">
                            <Note>Last health check {DateFormatter.momentAgo(connectionStatus.LastChecked as string)}</Note>
                        </Callout>);
                case MachineModelHealthStatus.Unknown:
                    return (<Callout type={CalloutType.Warning} title="Unknown">
                            <p>This {this.props.itemDescription} was just added, and a health check has not been performed.</p>
                        </Callout>);
            }
        }
    }
    private renderCalamariUpgradeInfo() {
        const machine = this.state.machine;
        if (!machine.HasLatestCalamari) {
            const calamariUpgradeButton = <ActionButton type={ActionButtonType.Secondary} label={"Upgrade Calamari"} disabled={this.state.busy} onClick={() => this.performCalamariUpgrade()}/>;
            return calamariUpgradeButton;
        }
    }
    private renderTentacleInfo() {
        const machine = this.state.machine;
        if (EndpointsHelper.isTentacle(machine.Endpoint)) {
            const tentacleDetails = machine.Endpoint.TentacleVersionDetails;
            if (!tentacleDetails || !tentacleDetails.Version) {
                return null;
            }
            return (<div>
                    {tentacleDetails.UpgradeAvailable && !tentacleDetails.UpgradeRequired && <Callout type={CalloutType.Information} title="Upgrade available"/>}
                    {tentacleDetails.UpgradeSuggested && !tentacleDetails.UpgradeRequired && <Callout type={CalloutType.Information} title="Upgrade suggested"/>}
                    {tentacleDetails.UpgradeRequired && <Callout type={CalloutType.Danger} title="Upgrade required"/>}
                    <DataTable>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>Current Version</DataTableRowHeaderColumn>
                                <DataTableRowColumn>{tentacleDetails.Version}</DataTableRowColumn>
                            </DataTableRow>
                            <DataTableRow>
                                <DataTableRowHeaderColumn>&nbsp;</DataTableRowHeaderColumn>
                                <DataTableRowColumn>
                                    {(tentacleDetails.UpgradeAvailable || tentacleDetails.UpgradeRequired || tentacleDetails.UpgradeSuggested) && (<ActionButton label={"Upgrade To Latest"} disabled={this.state.busy} onClick={() => this.upgradeTentacle()}/>)}
                                    {tentacleDetails.UpgradeLocked && <ActionButton label={"Unlock Current Version"} disabled={this.state.busy} onClick={() => this.lockVersion(false)}/>}
                                    {!tentacleDetails.UpgradeLocked && (<div>
                                            <ActionButton label={"Lock Current Version"} disabled={this.state.busy} onClick={() => this.lockVersion(true)}/>
                                            <Note>Ensures this Tentacle does not get updated past currently installed version or get prompted when newer versions are available.</Note>
                                        </div>)}
                                </DataTableRowColumn>
                            </DataTableRow>
                        </DataTableBody>
                    </DataTable>
                </div>);
        }
    }
    private async lockVersion(isToBeLocked: boolean) {
        const machine = this.state.machine;
        if (EndpointsHelper.isTentacle(machine.Endpoint)) {
            machine.Endpoint.TentacleVersionDetails.UpgradeLocked = isToBeLocked;
            await this.doBusyTask(async () => {
                await this.props.repository.save(machine);
                this.setState({ machine });
            });
        }
    }
    private async upgradeTentacle() {
        return this.doBusyTask(async () => {
            const res = await repository.Machines.upgradeTentacle(this.state.machine.Id);
            if (res.Tasks.length > 0) {
                this.setState({ redirectToTaskId: res.Tasks[0].Id });
            }
        });
    }
    private async performHealthCheck() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createHealthCheckTaskForMachine(this.state.machine);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async performCalamariUpgrade() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetTask(this.state.machine);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    static displayName = "MachineConnectionsLayoutInternal";
}
