import * as React from "react";
import { Switch, withRouter } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "../../../../pageIds";
import { RunbooksPage } from ".//RunbooksPage";
import type { RunbookRoutingProps } from "./RunbookRoutingProps";
export const OperationsRunbooksPageWithPageDefinition = withPage({ page: pageIds.project().operations.runbooks })(RunbooksPage);
class RunbooksRoute extends React.Component<RunbookRoutingProps> {
    render() {
        return (<Switch>
                <MigratedReloadableRoute path={this.props.path} exact={true} render={() => <OperationsRunbooksPageWithPageDefinition />}/>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "RunbooksRoute";
}
const EnhancedRunbooksRoute = withRouter(RunbooksRoute);
export const MigratedRunbooksRoute = EnhancedRunbooksRoute;
