import { ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute, MigratedRoute } from "~/components/MigratedRoute/MigratedRoute";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import RedirectToProcessEditorViaSlug from "~/components/RedirectToProcessEditorViaSlug/RedirectToProcessEditorViaSlug";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import type { ProjectRouteParams } from "../../ProjectsRoutes/ProjectRouteParams";
import { DeploymentProcessCodePage } from "../DeploymentProcessCodePage";
import { DeploymentProcessListPage } from "../DeploymentProcessListPage";
import { DeploymentProcessStepsPage } from "../DeploymentProcessStepsPage";
const DeploymentProcessListPageWithPageDefinition = withPage({ page: pageIds.project().process.root })(DeploymentProcessListPage);
const DeploymentProcessStepsPageWithPageDefinition = withPage({ page: pageIds.project().process.root })(DeploymentProcessStepsPage);
const DeploymentProcessCodePageWithPageDefinition = withPage({ page: pageIds.project().process.code })(DeploymentProcessCodePage);
type ProcessRouteProps = {
    path: string;
    processType: ProcessType;
};
function ProcessRoute({ path, processType }: ProcessRouteProps) {
    return (<Switch>
            <MigratedRoute path={`${path}/steps/:stepSlug`} exact={true} render={(routeProps) => <RedirectToProcessEditorViaSlug processType={processType} projectSlug={routeProps.match.params.projectSlug} stepSlug={routeProps.match.params.stepSlug}/>}/>
            <MigratedRoute path={`${path}/steps`} exact={true} render={(routeProps) => <DeploymentProcessStepsPageWithPageDefinition />}/>
            <MigratedRoute path={`${path}/edit`} exact={true} render={(routeProps) => <DeploymentProcessCodePageWithPageDefinition />}/>
            <MigratedRoute path={`${path}`} exact={true} render={() => <DeploymentProcessListPageWithPageDefinition />}/>
            <OldProcessRedirectRoute path={path} processType={processType}/>
            <RedirectAs404 />
        </Switch>);
}
class OldProcessRedirectRoute extends React.Component<ProcessRouteProps> {
    render() {
        // If anyone has bookmarked an older step route, we redirect them back to the list. We want to K.I.S.S. and avoid mapping the old route parameters to the new query-string parameters (that felt like overkill).
        const renderRedirectToProcessList = (props: RouteComponentProps<ProjectRouteParams>) => (<InternalRedirect to={links.deploymentProcessPage.generateUrl({
                spaceId: props.match.params.spaceId,
                projectSlug: props.match.params.projectSlug,
            })}/>);
        return (<Switch>
                <MigratedReloadableRoute path={`${this.props.path}/step/new/:actionType/:template?`} render={renderRedirectToProcessList}/>
                <MigratedReloadableRoute path={`${this.props.path}/step/:stepId/new/:actionType/:template?`} render={renderRedirectToProcessList}/>
                <MigratedReloadableRoute path={`${this.props.path}/step/:stepId/:reloadKey?`} render={renderRedirectToProcessList}/>
                <MigratedReloadableRoute path={`${this.props.path}/steptemplates`} render={renderRedirectToProcessList}/>
                <MigratedReloadableRoute path={`${this.props.path}/childsteptemplates/:parentStepId`} render={renderRedirectToProcessList}/>
            </Switch>);
    }
    static displayName = "OldProcessRedirectRoute";
}
export function ProjectDeploymentProcessRoutes() {
    return <MigratedProcessRoute path={routeLinks.projectRoutes().deployments.process.root} processType={ProcessType.Deployment}/>;
}
export const MigratedProcessRoute = ProcessRoute;
