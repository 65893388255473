/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Divider, List } from "@octopusdeploy/design-system-components";
import type { ProjectResource, LibraryVariableSetResource } from "@octopusdeploy/octopus-server-client";
import { VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import { links, VariableSetTabValues } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { ProjectPageLayout } from "~/areas/projects/components/ProjectPageLayout";
import OptInProjectTenantVariablesCallout from "~/areas/projects/components/Variables/OptInProjectTenantVariablesCallout";
import VariableSetSectionHeading from "~/areas/variables/VariableSetSectionHeading/VariableDisplayerSectionHeading";
import { repository } from "~/clientInstance";
import ActionTemplateParameterResourceSummary from "~/components/ActionTemplateParametersList/ActionTemplateParameterResourceSummary";
import BorderedListItem, { ListItemContentWithButtons } from "~/components/BorderedListItem";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { GroupedExpandableProps } from "~/components/GroupedExpandable";
import { default as GroupedExpandable } from "~/components/GroupedExpandable";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { Section } from "~/components/Section/Section";
import SectionNote from "~/components/SectionNote/SectionNote";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface CommonVariableTemplatesState extends DataBaseComponentState {
    project?: ProjectResource;
    libraryVariableSets?: ReadonlyArray<LibraryVariableSetResource>;
}
interface Props extends GroupedExpandableProps {
    projectSlug: string;
}
class CommonVariableTemplatesInternal extends DataBaseComponent<Props, CommonVariableTemplatesState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectSlug);
            const libraryVariableSets = (await repository.LibraryVariableSets.all({
                ids: project.IncludedLibraryVariableSetIds,
                contentType: VariableSetContentType.Variables,
            })).filter((libraryVariable) => libraryVariable.Templates.length > 0);
            this.setState({
                project,
                libraryVariableSets,
            });
            this.props.registerAllExpanders(libraryVariableSets.map((v) => v.Id));
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const { project, libraryVariableSets } = this.state;
        return (<ProjectPageLayout title="Common Variable Templates" breadcrumbTitle={this.state.project?.Name} busy={this.state.busy} errors={this.errors} callout={project ? <OptInProjectTenantVariablesCallout project={project}/> : null}>
                {project && libraryVariableSets ? <CommonVariableTemplatesContent project={project} libraryVariableSets={libraryVariableSets} {...this.props}/> : null}
            </ProjectPageLayout>);
    }
    static displayName = "CommonVariableTemplatesInternal";
}
interface CommonVariableTemplatesContentProps extends GroupedExpandableProps {
    project: ProjectResource;
    libraryVariableSets: ReadonlyArray<LibraryVariableSetResource>;
}
class CommonVariableTemplatesContentInternal extends DataBaseComponent<CommonVariableTemplatesContentProps> {
    constructor(props: CommonVariableTemplatesContentProps) {
        super(props);
        this.state = {};
    }
    render() {
        const { project, libraryVariableSets } = this.props;
        return (<>
                <SectionNote>
                    Variable templates define which variable values are required for each tenant connected to this project. They are defined in library variable sets and allow tenants to provide a common variable value that is shared across projects
                    and environments. Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
                {libraryVariableSets.length > 0 && (<Section>
                        To change the common variable templates required by this project{" "}
                        <InternalLink to={links.libraryVariablesPage.generateUrl({ projectSlug: project.Slug, spaceId: project.SpaceId })}>choose different library variable sets</InternalLink>.
                    </Section>)}
                <ExpansionButtons />
                {libraryVariableSets.map((lvs, index) => {
                return (<div key={lvs.Id}>
                            <Divider />
                            <VariableSetSectionHeading spaceId={lvs.SpaceId} variableSetId={lvs.Id} variableSetName={lvs.Name} isExpanded={this.props.getExpanderValueForKey(lvs.Id)!} onExpandedChanged={(expanded) => this.props.onExpandedChanged(lvs.Id, expanded)} variableSetTab={VariableSetTabValues.VariableTemplates}>
                                <List includeVerticalPadding items={lvs.Templates} renderRow={({ item: template, index: templateIndex }) => (<BorderedListItem key={templateIndex}>
                                            <ListItemContentWithButtons content={<ActionTemplateParameterResourceSummary parameter={template}/>}/>
                                        </BorderedListItem>)}/>
                            </VariableSetSectionHeading>
                        </div>);
            })}
                <Divider />
            </>);
    }
    static displayName = "CommonVariableTemplatesContentInternal";
}
export default GroupedExpandable("common_variable_templates", CommonVariableTemplatesInternal);
export const CommonVariableTemplatesContent = GroupedExpandable("common_variable_templates", CommonVariableTemplatesContentInternal);
