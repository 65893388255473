import type { ResourceCollection, MachinePolicyResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import List from "~/components/PagingList";
import PaperLayout from "~/components/PaperLayout/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import InfrastructureLayout from "../InfrastructureLayout";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import MachinePolicySummary from "../MachinePolicySummary";
class MachinePolicyList extends List<MachinePolicyResource> {
}
interface MachinePoliciesPageProps {
    spaceId: string;
}
interface MachinePoliciesPageInnerProps extends MachinePoliciesPageProps {
    initialData: InitialData;
}
interface InitialData {
    machinePoliciesResponse: ResourceCollection<MachinePolicyResource>;
}
const Title = "Machine Policies";
const MachinePoliciesFormPage = FormPage<InitialData>();
export function MachinePoliciesPage({ spaceId }: MachinePoliciesPageProps) {
    return (<MachinePoliciesFormPage title={Title} load={async () => {
            const machinePoliciesResponse = repository.MachinePolicies.list();
            return { machinePoliciesResponse: await machinePoliciesResponse };
        }} renderWhenLoaded={(data) => <MachinePoliciesPageInner initialData={data} spaceId={spaceId}/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
}
class MachinePoliciesPageInner extends DataBaseComponent<MachinePoliciesPageInnerProps, DataBaseComponentState> {
    constructor(props: MachinePoliciesPageInnerProps) {
        super(props);
        this.state = {};
    }
    render() {
        const addMachinePolicyPageAction: PrimaryPageAction = {
            type: "navigate",
            label: "Add machine policy",
            path: links.createMachinePolicyPage.generateUrl({ spaceId: this.props.spaceId }),
            hasPermissions: isAllowed({
                permission: Permission.MachinePolicyCreate,
            }),
        };
        return (<InfrastructureLayout {...this.props}>
                <PaperLayout busy={this.state.busy} errors={this.errors} title={Title} primaryAction={addMachinePolicyPageAction}>
                    <MachinePolicyList initialData={this.props.initialData.machinePoliciesResponse} onRow={(item: MachinePolicyResource) => this.buildMachinePolicyRow(item)} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} onRowRedirectUrl={(item: MachinePolicyResource) => links.editMachinePolicyPage.generateUrl({ spaceId: this.props.spaceId, machinePolicyId: item.Id })} filterHintText="Filter by name..." showPagingInNumberedStyle={false}/>
                </PaperLayout>
            </InfrastructureLayout>);
    }
    private filter(filter: string, resource: MachinePolicyResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildMachinePolicyRow(machinePolicy: MachinePolicyResource) {
        return (<div>
                <ListTitle>
                    {machinePolicy.Name}
                    {machinePolicy.IsDefault && (<span>
                            {" "}
                            <Chip>default</Chip>
                        </span>)}
                </ListTitle>
                <MachinePolicySummary machinePolicy={machinePolicy} hideDescription={false} conciseView={false}/>
            </div>);
    }
    static displayName = "MachinePoliciesPageInner";
}
