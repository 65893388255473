/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { UserChangePasswordDialogLayout } from "~/areas/configuration/components/Users/UserChangePasswordDialogLayout";
import { client, repository, session } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout/FormPaperLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { useIsPageHeaderVNextEnabled } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { Text, Summary, ExpandableFormSection, FormSection } from "~/components/form";
import Avatar from "~/primitiveComponents/dataDisplay/Avatar";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface UserEditModel {
    displayName: string;
    username: string;
    emailAddress: string;
    password?: string;
    confirmPassword?: string;
}
interface UserProfileInternalProps {
    isPageHeaderVNextEnabled: boolean;
}
interface UserProfileState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
}
class UserProfileInternal extends FormBaseComponent<UserProfileInternalProps, UserProfileState, UserEditModel> {
    tenantId: string = null!;
    constructor(props: UserProfileInternalProps) {
        super(props);
        this.state = {
            user: null!,
            model: null!,
            cleanModel: null!,
        };
    }
    buildModel(user: UserResource): UserEditModel {
        return user
            ? {
                displayName: user.DisplayName!,
                username: user.Username!,
                emailAddress: user.EmailAddress!,
            }
            : {
                displayName: "",
                username: "",
                emailAddress: "",
            };
    }
    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null!;
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;
            this.setState({
                user: user!,
                model: this.buildModel(user!),
                cleanModel: this.buildModel(user!),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    handleSaveClick = async () => {
        const userDisplaNameChanged = this.state.cleanModel!.displayName !== this.state.model!.displayName;
        const user: UserResource = {
            ...this.state.user,
            DisplayName: this.state.model!.displayName,
            EmailAddress: this.state.model!.emailAddress,
            Username: this.state.model!.username,
        };
        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);
            this.setState({
                cleanModel: this.buildModel(user),
                model: this.buildModel(user),
            });
            if (userDisplaNameChanged) {
                // we did this in old portal, and we do it for the feature toggles
                window.location.reload();
            }
        }, { timeOperationOptions: timeOperationOptions.forSave() });
    };
    render() {
        return (<FormPaperLayout title="My Profile" busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.handleSaveClick} overFlowActions={this.createOverflowMenuItems()} pageActions={this.getPageActions()}>
                {this.state.model && (<TransitionAnimation>
                        {this.props.isPageHeaderVNextEnabled && (<FormSection title="Profile Picture" help={<div className={profilePictureHelpStyles}>
                                        <Avatar avatarLink={this.state.user.Links.Avatar} isService={this.state.user.IsService} size={36}/>
                                        <span>
                                            You can change your picture at <ExternalLink href="Gravatar">Gravatar.com</ExternalLink>
                                        </span>
                                    </div>}></FormSection>)}
                        <ExpandableFormSection errorKey="displayName" title="Display Name" focusOnExpandAll summary={Summary.summary(this.state.model.displayName ? this.state.model.displayName : "No display name specified yet.")} help="Your name. This does not need to be unique.">
                            <Text value={this.state.model.displayName} onChange={(displayName) => this.setModelState({ displayName })} label="Display name" autoFocus={true}/>
                        </ExpandableFormSection>

                        <ExpandableFormSection errorKey="emailAddress" title="Email Address" summary={Summary.summary(this.state.model.emailAddress ? this.state.model.emailAddress : "Your email is not specified yet.")} help={this.state.model.emailAddress ? "Your email address." : "Enter an email address."}>
                            <Text value={this.state.model.emailAddress} onChange={(emailAddress) => this.setModelState({ emailAddress })} label="Email address"/>
                        </ExpandableFormSection>
                    </TransitionAnimation>)}
            </FormPaperLayout>);
    }
    createOverflowMenuItems() {
        const items = [];
        if (this.state.user && this.state.user.Id) {
            items.push(OverflowMenuItems.downloadItem("Download my personal details", this.state.model!.username + "-user.json", client.resolveLinkTemplate("Users", { id: `${this.state.user.Id}` })));
            items.push(OverflowMenuItems.navItem("Test Permissions", links.testPermissionsForUserPage.generateUrl({ userId: this.state.user.Id }), {
                permission: [Permission.TeamEdit, Permission.UserView],
            }));
            items.push(OverflowMenuItems.item("Revoke Sessions", () => repository.Users.revokeSessions(this.state.user)));
        }
        return items;
    }
    getPageActions(): PageAction[] {
        if (!this.state.model) {
            return [];
        }
        return [
            {
                type: "dialog",
                buttonType: "secondary",
                label: "Change Password",
                renderDialog: ({ closeDialog, isOpen }) => (<Dialog open={isOpen} onRequestClose={closeDialog}>
                        <UserChangePasswordDialogLayout userId={this.currentUserId()}/>
                    </Dialog>),
            },
        ];
    }
    static displayName = "UserProfileInternal";
}
export default function UserProfilePage() {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabled();
    return <UserProfileInternal isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
const profilePictureHelpStyles = css({
    display: "flex",
    gap: space[16],
    alignItems: "center",
});
