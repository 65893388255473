import type { BranchSpecifier, DashboardProjectResource, GitRefResource, ProjectResource, ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import { ShouldUseDefaultBranch, getURISafeGitRef } from "@octopusdeploy/octopus-server-client";
import { deploymentLinks } from "../DeploymentsRoute/deploymentRouteLinks";
import { opsRouteLinks } from "../OperationsRoute/opsRouteLinks";
import { projectInsightsLinks } from "../ProjectInsights/ProjectInsightsRoutes/projectInsightsLinks";
import variablesLinks from "../Variables/variablesRouteLinks";
import { channel, trigger, scheduledTrigger, runbook, feedTrigger, builtInFeedTrigger } from "../routeLinkHelpers";
import { getURISafeBranchSpecifier } from "./BranchSpecifier";
export default (root: string) => ({
    v3projectRoutes: {
        newProject: `${root}/projects/new`,
    },
    projects: {
        root: `${root}/projects`,
        filteredByGroup: (projectGroupId: string) => `${root}/projects?projectGroupId=${projectGroupId}`,
    },
    triggerRedirect: (triggerId: string) => `${root}/triggers/${triggerId}`,
    channelRedirect: (channelId: string) => `${root}/channels/${channelId}`,
    releaseRedirect: (releaseId: string) => `${root}/releases/${releaseId}`,
    runbookSnapshotRedirect: (runbookSnapshotId: string) => `${root}/snapshots/${runbookSnapshotId}`,
    runbookRedirect: runbook(root),
    project: (projectSlug: string | ProjectResource | ProjectSummaryResource | DashboardProjectResource) => projectRoutes(root, projectSlug),
    projectRoutes: () => projectRoutes(root, ":projectSlug"),
    projectBranch: (projectSlug: string, gitRef: BranchSpecifier | GitRefResource | undefined) => branchRoutes(root, projectSlug, gitRef),
    projectBranchRoutes: () => branchRoutes(root, ":projectSlug", ":branchName"),
    get importExport() {
        const importExportRoot = `${root}/projects/importExport`;
        return {
            root: importExportRoot,
            overview: `${importExportRoot}/overview`,
            importTask: (taskId: string) => {
                return `${importExportRoot}/import/${taskId}`;
            },
            exportTask: (taskId: string) => {
                return `${importExportRoot}/export/${taskId}`;
            },
            import: `${importExportRoot}/import`,
            export: `${importExportRoot}/export`,
        };
    },
});
const isGitRefResource = (branch: unknown): branch is GitRefResource => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (branch as GitRefResource).CanonicalName !== undefined;
};
const rootForBranchResource = (projectRootLink: string, gitRef: GitRefResource) => {
    return `${projectRootLink}/branches/${getURISafeGitRef(gitRef.CanonicalName)}`;
};
const rootForBranchSpecifier = (projectRootLink: string, branch: BranchSpecifier) => {
    return ShouldUseDefaultBranch(branch) ? projectRootLink : `${projectRootLink}/branches/${getURISafeBranchSpecifier(branch)}`;
};
const branchRoutes = (root: string, projectRoot: string, gitRef: BranchSpecifier | GitRefResource | undefined) => {
    const projectRootLink = projectRoutes(root, projectRoot).root;
    let branchRoot = projectRootLink;
    if (gitRef) {
        if (isGitRefResource(gitRef)) {
            branchRoot = rootForBranchResource(projectRootLink, gitRef);
        }
        else {
            branchRoot = rootForBranchSpecifier(projectRootLink, gitRef);
        }
    }
    return {
        root: branchRoot,
        deployments: deploymentLinks(`${branchRoot}/deployments`),
        variables: variablesLinks(`${branchRoot}/variables`),
        runbooks: opsRouteLinks(`${branchRoot}/operations`).runbooks,
        runbook: opsRouteLinks(`${branchRoot}/operations`).runbook(":runbookId").root,
    };
};
export type ProjectRouteLinks = ReturnType<typeof projectRoutes>;
function projectRoutes(root: string, projectSlug: string | ProjectResource | ProjectSummaryResource | DashboardProjectResource) {
    if (!!projectSlug && typeof projectSlug !== "string") {
        projectSlug = projectSlug.Slug;
    }
    const projectRoot = `${root}/projects/${projectSlug}`;
    return {
        root: projectRoot,
        overview: `${projectRoot}/overview`,
        settings: {
            root: `${projectRoot}/settings`,
            versionControl: `${projectRoot}/settings/versioncontrol`,
            dynamicEnvironments: `${projectRoot}/settings/dynamicenvironments`,
        },
        tasks: `${projectRoot}/tasks`,
        tenants: `${projectRoot}/tenants`,
        insights: projectInsightsLinks(`${projectRoot}/insights`),
        channel: channel(`${projectRoot}/channels`),
        operations: opsRouteLinks(`${projectRoot}/operations`),
        triggers: {
            root: `${projectRoot}/triggers`,
            new: `${projectRoot}/triggers/create`,
        },
        trigger: trigger(`${projectRoot}/triggers`),
        scheduledTrigger: scheduledTrigger(`${projectRoot}/triggers`),
        scheduledTriggers: {
            root: `${projectRoot}/triggers`,
            new: `${projectRoot}/triggers/scheduled/create`,
        },
        feedTrigger: feedTrigger(`${projectRoot}/triggers`),
        feedTriggers: {
            root: `${projectRoot}/triggers/feed`,
            new: `${projectRoot}/triggers/feed/create`,
        },
        builtInFeedTrigger: builtInFeedTrigger(`${projectRoot}/triggers`),
        builtInFeedTriggers: {
            root: `${projectRoot}/triggers/built-in-feed`,
            new: `${projectRoot}/triggers/built-in-feed/create`,
        },
        variables: variablesLinks(`${projectRoot}/variables`),
        vcsConnectivityCheck: `${projectRoot}/vcs/connectivityCheck`,
        deployments: deploymentLinks(`${projectRoot}/deployments`),
    };
}
