import { css } from "@emotion/css";
import { space, text } from "@octopusdeploy/design-system-tokens";
import type { BasicRepository, MachineResource, NewMachineResource, VariablesScopedToDocumentResource } from "@octopusdeploy/octopus-server-client";
import { CommunicationStyle, isDeploymentTarget } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { getNamespace, getReleaseName } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentHelmCommand";
import { SinglyScopedVariableWarnings } from "~/areas/variables/SinglyScopedVariableWarnings/SinglyScopedVariableWarnings";
import { ScopeType } from "~/areas/variables/VariableSorting/sortVariables";
import { repository } from "~/clientInstance";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
type DeleteMachineDialogLayoutProps<TResource extends MachineResource, TNewResource extends NewMachineResource> = React.PropsWithChildren<{
    machine: TResource;
    machineTypeFriendlyName: string;
    repository: BasicRepository<TResource, TNewResource>;
    onDelete(): void;
}>;
const styles = {
    container: css({
        marginTop: space[24],
    }),
    boldText: css({
        font: text.interface.body.bold.medium,
    }),
};
function customMachineSpecificContent<TResource extends MachineResource>(machine: TResource): React.ReactNode | null {
    if (!isDeploymentTarget(machine)) {
        return null;
    }
    if (machine.Endpoint.CommunicationStyle === CommunicationStyle.KubernetesTentacle) {
        const namespace = getNamespace(machine.Name);
        const helmReleaseName = getReleaseName(machine.Name);
        return (<div className={styles.container}>
                <p className={styles.boldText}>Additional steps required:</p>
                <p>To fully remove the Kubernetes Agent from your cluster, execute the following commands:</p>
                <p>
                    1.{" "}
                    <code>
                        helm uninstall --namespace {namespace} {helmReleaseName}
                    </code>
                </p>
                <p>
                    2. <code>kubectl delete namespace {namespace}</code>
                </p>
            </div>);
    }
    return null;
}
export const DeleteMachineDialogLayout = <TResource extends MachineResource, TNewResource extends NewMachineResource>(props: DeleteMachineDialogLayoutProps<TResource, TNewResource>) => {
    const [variablesScopedToThisMachine, setVariablesScopedToThisMachine] = useState<VariablesScopedToDocumentResource>();
    const [isLoading, setIsLoading] = useState(true);
    const onLoad = async () => {
        setVariablesScopedToThisMachine(variablesScopedToThisMachine);
        if (isDeploymentTarget(props.machine)) {
            const variablesScopedToThisMachine = (await repository.Machines.variablesScopedOnlyToThisDeploymentTarget(props.machine)).Resource;
            setVariablesScopedToThisMachine(variablesScopedToThisMachine);
        }
        setIsLoading(false);
    };
    const onDeleteClick = async () => {
        await props.repository.del(props.machine);
        props.onDelete();
        return true;
    };
    const machineType = isDeploymentTarget(props.machine) ? "deployment target" : "worker";
    return (<DeleteDialogLayout title={`Are you sure you want to delete this ${props.machineTypeFriendlyName} ${machineType}?`} onDeleteClick={onDeleteClick} onLoad={onLoad} renderContent={() => (<>
                    {variablesScopedToThisMachine && <SinglyScopedVariableWarnings variablesScopedToThisDocument={variablesScopedToThisMachine} documentType={ScopeType.Machine}/>}
                    {!isLoading && (<>
                            <p>You're about to permanently delete this {machineType} in Octopus. This action can't be undone.</p>
                            {customMachineSpecificContent(props.machine)}
                        </>)}
                </>)}></DeleteDialogLayout>);
};
