import type { GitHubAppConnectionResource, GitHubRepository } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { Action } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import FormPaperLayout from "~/components/FormPaperLayout";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageAction } from "~/components/PageActions/PageActions";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { FormSectionHeading, UnstructuredFormSection } from "~/components/form";
import StringHelper from "~/utils/StringHelper";
import LibraryLayout from "../LibraryLayout";
import { GitConnectionAnalyticView } from "./Analytics/GitConnectionAnalyticView";
import { useNotifyGitConnectionEvent } from "./Analytics/useNotifyGitConnectionEvent";
import { useTrackedGitConnectionEvent } from "./Analytics/useTrackedGitConnectionEvent";
import GitHubAppAuthCheck from "./GitHubAppAuthCheck";
import GitHubAppRepositoryList, { GitHubAppRepositoryListWithNonAdminReposDisabled, GitHubAppUnknownRepositoryList } from "./GitHubAppRepositoryList";
interface GitHubConnectionPageInternalProps extends GitHubConnectionPageProps {
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
function GitHubConnectionPageInternal({ spaceId, connectionId, doBusyTask, busy, errors }: GitHubConnectionPageInternalProps) {
    const [editing, setEditing] = useState<boolean>(false);
    const [connection, setConnection] = useState<GitHubAppConnectionResource | undefined>(undefined);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [otherRepositories, setOtherRepositories] = useState<GitHubRepository[] | undefined>(undefined);
    const [selectedRepositories, setSelectedRepositories] = useState<string[]>([]);
    const [cleanSelectedRepositories, setCleanSelectedRepositories] = useState<string[]>([]);
    const trackAction = useTrackedGitConnectionEvent();
    const recordAction = useNotifyGitConnectionEvent();
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        const connection = await repository.GitHubApp.getConnection(connectionId);
        setConnection(connection);
        const initialSelectedRepositories = connection.Repositories.map((r) => r.RepositoryId);
        initialSelectedRepositories.push(...connection.UnknownRepositories.map((r) => r.RepositoryId));
        initialSelectedRepositories.sort();
        setSelectedRepositories(initialSelectedRepositories);
        setCleanSelectedRepositories(initialSelectedRepositories);
    }, [connectionId]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (editing) {
            if (connection) {
                const repositories = await repository.GitHubApp.getRepositories(connection.Installation.InstallationId);
                // This will return the full list of repositories on the installation, we only want to show
                // repositories at the bottom of the list if they're not already selected.
                const additionalRepositories = repositories.Repositories.filter((r) => !cleanSelectedRepositories.includes(r.RepositoryId));
                setOtherRepositories(additionalRepositories);
            }
            // If we don't have a connection there is nothing to do yet. This will be called again once
            // we have a connection
        }
        else {
            setOtherRepositories(undefined);
        }
    }, [editing, connection?.Installation.InstallationId]);
    function setRepositorySelected(selected: boolean, repositoryId: string) {
        if (selected) {
            setSelectedRepositories((prev) => [...prev, repositoryId].sort());
        }
        else {
            setSelectedRepositories((prev) => prev.filter((id) => id !== repositoryId).sort());
        }
    }
    async function save() {
        await doBusyTask(async () => {
            await trackAction("Save GitHub connection", Action.Save, async () => {
                await repository.GitHubApp.modifyConnection(connectionId, { RepositoryIds: selectedRepositories });
                setEditing(false);
                await refresh();
            });
        });
    }
    function toggleEditing() {
        setEditing(!editing);
        setSelectedRepositories(cleanSelectedRepositories);
        if (editing) {
            recordAction("Edit GitHub connection");
        }
        else {
            recordAction("Cancel editing GitHub connection");
        }
    }
    const button: PageAction = {
        type: "button",
        buttonType: "secondary",
        hasPermissions: isAllowed({ permission: Permission.GitCredentialEdit }),
        label: editing ? "Cancel" : "Edit",
        onClick: toggleEditing,
    };
    const handleDeleteConfirm = async () => {
        if (!connection) {
            // If there is some issue with the connection (can't be found in the GitHub app,
            // installation has errors, etc) the get connection endpoint will error and we
            // won't have a connection at this point. We still want to be abel to delete it
            // to allow the user to re-configure it, so just try deleting using the connection
            // id.
            await repository.GitHubApp.deleteConnection(connectionId);
        }
        else {
            await repository.GitHubApp.deleteConnection(connection.Id);
        }
        setConnection(undefined);
        setDeleted(true);
        return true;
    };
    const overFlowActions = [OverflowMenuItems.deleteItemDefault("Git Connection", handleDeleteConfirm, { permission: Permission.GitCredentialEdit })];
    return (<LibraryLayout spaceId={spaceId}>
            <GitConnectionAnalyticView name="View GitHub connection page"/>
            <FormPaperLayout model={{ selected: selectedRepositories }} cleanModel={{ selected: cleanSelectedRepositories }} pageActions={[button]} onSaveClick={save} errors={errors} title={connection ? connection.Installation.AccountLogin : StringHelper.ellipsis} breadcrumbTitle={"Git Connections"} breadcrumbPath={links.gitConnectionsPage.generateUrl({ spaceId })} busy={busy} savePermission={{ permission: Permission.GitCredentialEdit }} overFlowActions={overFlowActions}>
                {deleted && <InternalRedirect to={links.gitConnectionsPage.generateUrl({ spaceId })}/>}
                <GitHubAppAuthCheck errors={errors}/>
                {connection && (<>
                        <FormSectionHeading title="Connected Repositories"/>
                        <UnstructuredFormSection stretchContent>
                            <GitHubAppRepositoryList hideConfigureInstallation disableInteraction={!editing} repositories={connection.Repositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                        </UnstructuredFormSection>
                        {connection.UnknownRepositories.length > 0 && (<>
                                <FormSectionHeading title={"Missing Repositories"}/>
                                <UnstructuredFormSection stretchContent>
                                    <GitHubAppUnknownRepositoryList disableInteraction={!editing} repositories={connection.UnknownRepositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>
                                </UnstructuredFormSection>
                            </>)}
                        {editing && (<>
                                <FormSectionHeading title="Add Repositories"/>
                                <UnstructuredFormSection stretchContent>
                                    {otherRepositories && <GitHubAppRepositoryListWithNonAdminReposDisabled repositories={otherRepositories} selectedRepositories={selectedRepositories} setRepositorySelected={setRepositorySelected}/>}
                                </UnstructuredFormSection>
                            </>)}
                    </>)}
            </FormPaperLayout>
        </LibraryLayout>);
}
interface GitHubConnectionPageProps {
    spaceId: string;
    connectionId: string;
}
export class GitHubConnectionPage extends DataBaseComponent<GitHubConnectionPageProps> {
    constructor(props: GitHubConnectionPageProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitHubConnectionPageInternal spaceId={this.props.spaceId} connectionId={this.props.connectionId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "GitHubConnectionPage";
}
