/* eslint-disable @typescript-eslint/no-explicit-any */
import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch, withRouter } from "react-router-dom";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import { DeploymentTriggerPage } from "~/areas/projects/components/Triggers/DeploymentTriggerPage";
import { TriggersPage } from "~/areas/projects/components/Triggers/TriggersPage";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { CreateBuiltInFeedTriggerPage } from "./CreateBuiltInFeedTriggerPage";
import { CreateReleaseCreationFeedTriggersPage } from "./CreateReleaseCreationFeedTriggersPage";
import { EditBuiltInFeedTriggerPage } from "./EditBuiltInFeedTriggerPage";
import { EditReleaseCreationFeedTriggersPage } from "./EditReleaseCreationFeedTriggersPage";
import { ReleaseTriggersPage } from "./ReleaseTriggersPage";
import { CreateDeploymentScheduledTriggerPage } from "./Scheduled/CreateDeploymentScheduledTriggerPage";
import { EditDeploymentScheduledTriggerPage } from "./Scheduled/EditDeploymentScheduledTriggerPage";
export const ScheduledTriggerPageWithPageDefinition = withPage({ page: pageIds.project().scheduledTrigger })(EditDeploymentScheduledTriggerPage);
export const CreateScheduledTriggerPageWithPageDefinition = withPage({ page: pageIds.project().scheduledTriggerNew })(CreateDeploymentScheduledTriggerPage);
export const EditDeploymentTriggerPageWithPageDefinition = withPage({ page: pageIds.project().deploymentTargetTrigger })(DeploymentTriggerPage);
export const CreateDeploymentTriggerPageWithPageDefinition = withPage({ page: pageIds.project().deploymentTargetTriggerNew })(DeploymentTriggerPage);
export const TriggersPageWithPageDefinition = withPage({ page: pageIds.project().triggers })(TriggersPage);
export const CreateReleaseCreationFeedTriggerPageWithPageDefinition = withPage({ page: pageIds.project().releaseTriggerNew })(CreateReleaseCreationFeedTriggersPage);
export const EditReleaseCreationFeedTriggerPageWithPageDefinition = withPage({ page: pageIds.project().releaseTrigger })(EditReleaseCreationFeedTriggersPage);
export const CreateBuiltInFeedTriggerPageWithPageDefinition = withPage({ page: pageIds.project().builtInFeedTriggerNew })(CreateBuiltInFeedTriggerPage);
export const EditBuiltInFeedTriggerPageWithPageDefinition = withPage({ page: pageIds.project().builtInFeedTrigger })(EditBuiltInFeedTriggerPage);
export const ReleaseTriggersPageWithPageDefinition = withPage({ page: pageIds.project().triggers })(ReleaseTriggersPage);
type TriggersRouteProps = {
    path: string;
} & RouteComponentProps<ProjectRouteParams>;
type CreateScheduledTriggerRouteProps = RouteComponentProps<{
    spaceId: string;
    projectSlug: string;
}>;
type EditScheduledTriggerRouteProps = RouteComponentProps<{
    triggerId: string;
    spaceId: string;
    projectSlug: string;
}>;
type CreateFeedTriggerRouteProps = RouteComponentProps<{
    spaceId: string;
    projectSlug: string;
}>;
type EditFeedTriggerRouteProps = RouteComponentProps<{
    triggerId: string;
    spaceId: string;
    projectSlug: string;
}>;
type BuiltInFeedTriggerRouteProps = RouteComponentProps<{
    spaceId: string;
    projectSlug: string;
}>;
function TriggersRouteInternal({ path, match }: TriggersRouteProps) {
    const { spaceId, projectSlug } = match.params;
    const externalFeedTriggersEnabled = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle");
    return (<Switch>
            <MigratedReloadableRoute path={`${path}/scheduled/edit/:triggerId`} render={({ match }: EditScheduledTriggerRouteProps) => <ScheduledTriggerPageWithPageDefinition triggerId={match.params.triggerId} projectSlug={match.params.projectSlug} spaceId={match.params.spaceId}/>}/>
            <MigratedReloadableRoute path={`${path}/scheduled/create`} render={({ match }: CreateScheduledTriggerRouteProps) => <CreateScheduledTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug}/>}/>
            <MigratedReloadableRoute path={`${path}/edit/:triggerId`} render={({ match }: RouteComponentProps<{
            spaceId: string;
            projectSlug: string;
            branchName: string;
            triggerId: string;
        }>) => (<EditDeploymentTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug} branchName={match.params.branchName} triggerId={match.params.triggerId}/>)}/>
            <MigratedReloadableRoute path={`${path}/create`} render={({ match }: RouteComponentProps<{
            spaceId: string;
            projectSlug: string;
            branchName: string;
            triggerId: string;
        }>) => (<CreateDeploymentTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug} branchName={match.params.branchName} create={true}/>)}/>
            {externalFeedTriggersEnabled && (<MigratedReloadableRoute path={`${path}/feed/create`} render={({ match }: CreateFeedTriggerRouteProps) => <CreateReleaseCreationFeedTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug}/>}/>)}
            {externalFeedTriggersEnabled && (<MigratedReloadableRoute path={`${path}/feed/edit/:triggerId`} render={({ match }: EditFeedTriggerRouteProps) => <EditReleaseCreationFeedTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug} triggerId={match.params.triggerId}/>}/>)}
            {externalFeedTriggersEnabled && (<MigratedReloadableRoute path={`${path}/built-in-feed/create`} render={({ match }: BuiltInFeedTriggerRouteProps) => <CreateBuiltInFeedTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug}/>}/>)}
            {externalFeedTriggersEnabled && (<MigratedReloadableRoute path={`${path}/built-in-feed/edit`} render={({ match }: BuiltInFeedTriggerRouteProps) => <EditBuiltInFeedTriggerPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug}/>}/>)}
            {externalFeedTriggersEnabled && (<MigratedReloadableRoute path={`${path}/feed`} render={({ match }: CreateFeedTriggerRouteProps) => <ReleaseTriggersPageWithPageDefinition spaceId={match.params.spaceId} projectSlug={match.params.projectSlug}/>}/>)}
            <MigratedReloadableRoute path={`${path}`} render={(r) => <TriggersPageWithPageDefinition spaceId={spaceId} projectSlug={projectSlug} triggerActionCategory={TriggerActionCategory.Deployment}/>}/>
        </Switch>);
}
export const TriggersRoute = withRouter(TriggersRouteInternal);
export const MigratedTriggersRoute = TriggersRoute;
