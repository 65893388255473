import { css } from "@emotion/css";
import { Carousel } from "@octopusdeploy/design-system-components";
import { colorScales, space, text } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { Action } from "~/analytics/Analytics";
import { useAnalyticWelcomeDialogDispatch } from "./Analytics/WelcomeDialogAnalytics";
const ImageConfigure = require("./Images/Configure.svg");
const ImageDeploy = require("./Images/Deploy.svg");
const ImageMeasure = require("./Images/Measure.svg");
const ImageOperate = require("./Images/Operate.svg");
const ImageRelease = require("./Images/Release.svg");
const helpPanelCarouselSteps = [
    <HelpPanelStep key="configure" title="Simplify your deployments" image={ImageConfigure} contentHeader="Easy setup" content="Configure your deployment process with our library of 500+ free step templates. Or you can use command lines and scripts. Store your deployment process in Git using Config as Code."/>,
    <HelpPanelStep key="release" title="Simplify your deployments" image={ImageRelease} contentHeader="Repeatable deployments" content="Set once, deploy often. Define your deployment steps and create a new release to snapshot the process. You then use that same process consistently across your environments."/>,
    <HelpPanelStep key="deploy" title="Simplify your deployments" image={ImageDeploy} contentHeader="Deploy anywhere" content="Octopus seamlessly connects to your deployment pipeline, letting you pull code from any build server or CI platform. Deploy anywhere – cloud, data centers, Kubernetes, on-premises servers."/>,
    <HelpPanelStep key="operate" title="Simplify your deployments" image={ImageOperate} contentHeader="Automate ops tasks" content="Use Runbooks to automate routine and emergency operations tasks like infrastructure provisioning, database management, and website failover and restoration with one click."/>,
    <HelpPanelStep key="measure" title="Simplify your deployments" image={ImageMeasure} contentHeader="Measure performance" content="Octopus's DevOps Insights uses DORA metrics to tell you exactly how you're performing so you can find areas for improvement in your deployment processes."/>,
];
export function WelcomeDialogHelpPanelContent() {
    const dispatch = useAnalyticWelcomeDialogDispatch();
    const onInteraction = (slideKey: string) => {
        dispatch("Change Welcome Dialog Help Panel", {
            action: Action.View,
            selection: slideKey,
        });
    };
    return (<div className={styles.carouselWrapper}>
            <Carousel slides={helpPanelCarouselSteps} onInteraction={onInteraction}/>
        </div>);
}
interface HelpPanelStepProps {
    title: string;
    image: string;
    contentHeader: string;
    content: string;
}
function HelpPanelStep({ title, image, contentHeader, content }: HelpPanelStepProps) {
    return (<div key={contentHeader} className={styles.helpPanelStep}>
            <div className={styles.title}>{title}</div>
            <img className={styles.image} src={image} alt={contentHeader}/>
            <div className={styles.contentContainer}>
                <div className={styles.contentHeader}>{contentHeader}</div>
                <div className={styles.content}>{content}</div>
            </div>
        </div>);
}
const styles = {
    carouselWrapper: css({ paddingBottom: space[24] }),
    helpPanelStep: css({ display: "flex", flexDirection: "column", gap: space[24] }),
    title: css({ font: text.interface.body.default.base, color: colorScales.white }),
    image: css({ maxWidth: "310px", margin: "0 auto" }),
    contentContainer: css({ display: "flex", flexDirection: "column", gap: space[4] }),
    contentHeader: css({ font: text.interface.heading.small, color: colorScales.white }),
    content: css({ font: text.interface.body.default.medium, color: colorScales.white }),
};
