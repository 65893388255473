import { css } from "@emotion/css";
import { ActionButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppConnectionSummary, GitHubAppConnections, GitHubAppRepositories, GitHubRepository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React, { useCallback, useEffect, useState } from "react";
import { GitHubInstallationLogo } from "~/areas/library/components/GitConnections/GitHubInstallationLogo";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary } from "~/components/form";
import SelectWithAddRefresh from "~/components/form/SelectWithAddRefresh/SelectWithAddRefresh";
const placeholderText = "Select a GitHub repository to store the configuration for this project";
export interface GitHubVersionControlSettingsDetailsProps {
    spaceId: string;
    connections: GitHubAppConnections | undefined;
    refreshGitConnections: () => Promise<void>;
    getRepositoriesForConnection: (connection: GitHubAppConnectionSummary) => Promise<GitHubAppRepositories | undefined>;
    url: string | undefined;
    connectionId: string | undefined;
    setGitHubConnectionDetails: (connectionId: string | undefined, url: string | undefined) => void;
}
export function GitHubVersionControlSettingsDetails({ spaceId, connections, refreshGitConnections, getRepositoriesForConnection, url, connectionId, setGitHubConnectionDetails }: GitHubVersionControlSettingsDetailsProps) {
    const styles = {
        buttonContainer: css({
            display: "flex",
            alignItems: "center",
            gap: space[8],
        }),
    };
    const spaceAwareNavigation = useSpaceAwareNavigation();
    const [repositories, setRepositories] = useState<GitHubAppRepositories | undefined>(undefined);
    const refreshRepositories = useCallback(async (connectionId: string | undefined, connections: GitHubAppConnectionSummary[] | undefined) => {
        setRepositories(undefined);
        if (connectionId) {
            const newSelectedConnection = connections?.find((value) => value.Id === connectionId);
            if (newSelectedConnection) {
                const repositories = await getRepositoriesForConnection(newSelectedConnection);
                setRepositories(repositories);
            }
        }
    }, [getRepositoriesForConnection, setRepositories]);
    useEffect(() => {
        refreshRepositories(connectionId, connections?.Connections);
    }, [connectionId, connections?.Connections, refreshRepositories]);
    const selectedConnection = connections?.Connections.find((value) => value.Id === connectionId);
    const selectedRepository = repositories?.Repositories.find((value) => value.GitUrl === url);
    const connectionOptions = SelectOptionsMap(connections?.Connections, (value) => value.Id, (value) => value.Installation?.AccountLogin ?? value.Id);
    const repositoryOptions = SelectOptionsMap(repositories?.Repositories ?? [], (value) => value.RepositoryId, (value) => value.RepositoryName);
    const onConnectionChanged = async (newConnectionId: string | undefined) => {
        // If the connection hasn't changed (someone's just re-selecting the current one), we don't want to clear the repository.
        if (newConnectionId !== connectionId) {
            setGitHubConnectionDetails(newConnectionId, undefined);
        }
    };
    const onRepositoryChanged = async (newRepositoryId: string | undefined) => {
        const newRepository = repositories?.Repositories.find((value) => value.RepositoryId === newRepositoryId);
        setGitHubConnectionDetails(connectionId, newRepository?.GitUrl);
    };
    return (<>
            <ExpandableFormSection errorKey="GitHubRepository" title="GitHub Repository" summary={GitHubVersionControlSettingsDetailsSummary(connectionId, url, selectedConnection, selectedRepository, repositories !== undefined)} help={placeholderText} isExpandedByDefault={true}>
                {!connections || connections.Connections.length > 0 ? (<SelectWithAddRefresh label="GitHub account" items={connectionOptions} value={selectedConnection?.Id} onChange={onConnectionChanged} sortItems={false} autoFocus={true} disabled={!connections} placeholder={connections ? "Select a GitHub account" : "Loading..."} addUrl={links.gitConnectionsPage.generateUrl({ spaceId })} onRequestRefresh={refreshGitConnections} allowFilter={true}/>) : (<div className={styles.buttonContainer}>
                        <ActionButton label="Connect to GitHub" accessibleName="Connect to GitHub" onClick={() => spaceAwareNavigation.open(links.gitConnectionsPage.generateUrl({ spaceId }))}/>
                        <IconButtonWithTooltip onClick={refreshGitConnections} toolTipContent="Refresh" icon="Refresh"/>
                    </div>)}
                {selectedConnection && (<SelectWithAddRefresh label="GitHub repository" items={repositoryOptions} value={selectedRepository?.RepositoryId} onChange={onRepositoryChanged} sortItems={false} disabled={!repositories} placeholder={repositories ? "Select a GitHub repository" : "Loading..."} addUrl={links.editGitHubConnectionPage.generateUrl({ spaceId, connectionId: selectedConnection.Id })} onRequestRefresh={async () => {
                refreshRepositories(connectionId, connections?.Connections);
            }} allowFilter={true}/>)}
            </ExpandableFormSection>
        </>);
}
function SelectOptionsMap<T>(items: T[] | undefined, valueSelector: (item: T) => string, textSelector: (item: T) => string) {
    if (!items) {
        return [];
    }
    return items.map((value) => {
        return {
            value: valueSelector(value),
            text: textSelector(value),
        };
    });
}
export function GitHubVersionControlSettingsDetailsSummary(connectionId: string | undefined, url: string | undefined, connection: GitHubAppConnectionSummary | undefined, repository: GitHubRepository | undefined, repositoriesLoaded: boolean): SummaryNode {
    if (!connectionId || !url) {
        // If they haven't finished selecting an account or repository, show a placeholder prompting them to do so.
        return Summary.placeholder(placeholderText);
    }
    if (connection && repository) {
        if (connection.Installation) {
            const connectionText = connection.Installation.AccountLogin;
            const repositoryText = repository.RepositoryName;
            const styles = {
                container: css({
                    display: "flex",
                    flexDirection: "row",
                    gap: space[8],
                    alignItems: "center",
                }),
            };
            return Summary.summary(<span className={styles.container}>
                    <GitHubInstallationLogo fill installation={connection.Installation}/> {connectionText}/{repositoryText}
                </span>);
        }
        else {
            // We have a connection, but it's missing the installation details. This is rare, but possible if we
            // have a connection that's to an installation that's been removed, and don't have the details cached.
            return Summary.summary(url);
        }
    }
    // Even if they have a connectionId and repository URL selected, it's possible that we can't load it anymore. This
    // could be because we're still loading the details or the repository has been removed from the installation.
    if (repositoriesLoaded) {
        // If we've loaded the repositories but we can't find the one we're looking for, just show the URL. This could
        // be because it's been removed from the connection or renamed. Either way, this should be enough for the user
        // to troubleshoot what's going on.
        return Summary.summary(url);
    }
    else {
        return Summary.placeholder("Loading...");
    }
}
