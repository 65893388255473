/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository, client } from "~/clientInstance";
import AreaTitle from "~/components/AreaTitle";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import routeLinks from "~/routeLinks";
import Task, { BuiltInTask } from "./Task";
interface TaskPageInternalProps extends TaskPageProps {
    taskId: string;
    isPageHeaderVNextEnabled: boolean;
}
interface TaskPageState extends DataBaseComponentState {
    task?: TaskResource<any>;
    redirectTo?: LinkHref;
}
class TaskPageInternal extends DataBaseComponent<TaskPageInternalProps, TaskPageState> {
    constructor(props: TaskPageInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.props.taskId, { spaces: ["all"] });
            if (task.SpaceId && task.SpaceId != client.spaceId) {
                this.setState({ redirectTo: routeLinks.forSpace(task.SpaceId).task(task).root });
                return;
            }
            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.setState({ redirectTo: links.deploymentToProjectTaskRedirect.generateUrl({ spaceId: task.SpaceId!, deploymentId: task.Arguments.DeploymentId }) });
                return;
            }
            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.setState({ redirectTo: links.runbookRunRedirect.generateUrl({ spaceId: task.SpaceId!, runbookRunId: task.Arguments.RunbookRunId }), task });
                return;
            }
            if (task.Name === BuiltInTask.ExportProjects) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.setState({ redirectTo: links.exportProjectTaskPage.generateUrl({ spaceId: task.SpaceId!, taskId: task.Id }), task });
                return;
            }
            if (task.Name === BuiltInTask.ImportProjects) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.setState({ redirectTo: links.importProjectTaskPage.generateUrl({ spaceId: task.SpaceId!, taskId: task.Id }), task });
                return;
            }
            this.setState({ task });
        });
    }
    render() {
        const { redirectTo, task } = this.state;
        if (redirectTo) {
            return <InternalRedirect to={redirectTo} push={false}/>;
        }
        if (this.props.isPageHeaderVNextEnabled && !task) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        return (<main>
                {!this.props.isPageHeaderVNextEnabled && <AreaTitle link={links.tasksPage.generateUrl()} title="Tasks"/>}
                <ContextualHelpLayout>
                    <div className={this.props.isPageHeaderVNextEnabled ? containerStylesVNext : containerStyles}>
                        <Task task={task} taskId={this.props.taskId} delayRender={() => task == null} defaultBreadcrumbItems={[{ label: "Tasks", pageUrl: links.tasksPage.generateUrl() }]} showBreadcrumbBackIcon={true}/>
                    </div>
                </ContextualHelpLayout>
            </main>);
    }
    static displayName = "TaskPageInternal";
}
const containerStyles = css({
    margin: `${space[12]} ${space[16]} 0`,
});
const containerStylesVNext = css({
    margin: space[32],
});
interface TaskPageProps {
    taskId: string;
}
export function TaskPage({ taskId }: TaskPageProps) {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    return <TaskPageInternal taskId={taskId} isPageHeaderVNextEnabled={isPageHeaderVNextEnabled}/>;
}
