import React from "react";
import { Route, Switch } from "react-router-dom";
import { MainNavbarLayout } from "~/components/MainNavbarLayout/MainNavbarLayout";
import RootRoutes from "~/components/RootRoutes/index";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import SpaceLoader from "~/components/SpaceLoader/SpaceLoader";
import { allLegacyRedirectRoutes } from "~/routing/allLegacyRedirectRoutes";
export function AuthenticatedRoutes({ path }: {
    path: string;
}) {
    const legacyRedirectRoutes = useLegacyRedirectRoutes();
    return (<Switch>
            {/*We try to match legacy redirects first, because: */}
            {/*- They might partially match against other route segments and we don't want to interleave them with route segments because this adds more complexity. */}
            {/*- If there's ambiguity between a non-legacy route and a legacy route, we prefer the legacy route. For example:*/}
            {/*    - /projects/new vs /projects/{projectSlug} where {projectSlug} is 'new'. If we preferred the non-legacy route, then we would never match against the legacy route, so why even define it?  */}
            {legacyRedirectRoutes}
            <Route path={path} render={() => (<SpaceLoader render={(spaceContext) => (<MainNavbarLayout spaceContext={spaceContext}>
                                <RootRoutes spaceContext={spaceContext}/>
                            </MainNavbarLayout>)}/>)}></Route>
        </Switch>);
}
function useLegacyRedirectRoutes() {
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    if (isRoutingVNextEnabled) {
        return allLegacyRedirectRoutes();
    }
    return [];
}
