//TODO: @team-config-as-code: Re-introduce this only when we need branch support for runbook processes.
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "../../../../pageIds";
import { runbookLinks } from "../OperationsRoute";
import RunbookProcessRoute from "./RunbookProcessRoute";
import { RunbookSettingsPage } from "./RunbookSettingsPage";
export const RunbookSettingsPageWithPageDefinition = withPage({ page: pageIds.project().runbook.settings })(RunbookSettingsPage);
class RunbookSettingsAndProcessRoutes extends React.Component<{
    path: string;
}> {
    render() {
        const links = runbookLinks(this.props.path);
        return (<Switch>
                <MigratedReloadableRoute path={links.settings} render={(routeProps: RouteComponentProps<{
            runbookId: string;
        }>) => <RunbookSettingsPageWithPageDefinition runbookId={routeProps.match.params.runbookId}/>}/>
                <MigratedRunbookProcessRoute path={links.runbookProcess.runbookProcess(":processId").root}/>
            </Switch>);
    }
    static displayName = "RunbookSettingsAndProcessRoutes";
}
const MigratedRunbookProcessRoute = RunbookProcessRoute;
export { RunbookSettingsAndProcessRoutes };
