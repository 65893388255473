import { InsightsReportLoader } from "app/areas/insights/components/InsightsReportLayout/InsightsReportLoader";
import { InsightsReportDeploymentDurationPage } from "app/areas/insights/components/Reports/InsightsReportDeploymentDurationPage";
import { InsightsReportDeploymentFailureRatePage } from "app/areas/insights/components/Reports/InsightsReportDeploymentFailureRatePage";
import { InsightsReportDeploymentFrequencyPage } from "app/areas/insights/components/Reports/InsightsReportDeploymentFrequencyPage";
import { InsightsReportDeploymentLeadTimePage } from "app/areas/insights/components/Reports/InsightsReportDeploymentLeadTimePage";
import { InsightsReportDeploymentMeanTimeToRecoveryPage } from "app/areas/insights/components/Reports/InsightsReportDeploymentMeanTimeToRecoveryPage";
import { InsightsReportSettingsPage } from "app/areas/insights/components/Reports/InsightsReportSettingsPage";
import { InsightsReportListPage } from "app/areas/insights/components/Reports/ReportList/InsightsReportListPage";
import { InsightsReportOverviewPage } from "app/areas/insights/components/Reports/ReportOverview/InsightsReportOverviewPage";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import LicenseChecker from "~/areas/insights/components/InsightsRoutes/LicenseChecker";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "../../../../routeLinks";
const InsightsReportListPageWithPageDefinition = withPage({ page: pageIds.insights.reports })(InsightsReportListPage);
const InsightsReportOverviewPageWithPageDefinition = withPage({ page: pageIds.insights.report.overview })(InsightsReportOverviewPage);
const InsightsReportDeploymentFrequencyPageWithPageDefinition = withPage({ page: pageIds.insights.report.frequency })(InsightsReportDeploymentFrequencyPage);
const InsightsReportDeploymentLeadTimePageWithPageDefinition = withPage({ page: pageIds.insights.report.leadTime })(InsightsReportDeploymentLeadTimePage);
const InsightsReportDeploymentFailureRatePageWithPageDefinition = withPage({ page: pageIds.insights.report.failureRate })(InsightsReportDeploymentFailureRatePage);
const InsightsReportDeploymentMeanTimeToRecoveryPageWithPageDefinition = withPage({ page: pageIds.insights.report.timeToRecovery })(InsightsReportDeploymentMeanTimeToRecoveryPage);
const InsightsReportSettingsPageWithPageDefinition = withPage({ page: pageIds.insights.report.settings })(InsightsReportSettingsPage);
const InsightsReportDeploymentDurationPageWithPageDefinition = withPage({ page: pageIds.insights.report.deploymentDuration })(InsightsReportDeploymentDurationPage);
const InsightsRoutes = (props: {
    spaceId: string;
}) => {
    const reportLinks = routeLinks.insights.report(":reportId");
    return (<Switch>
            <MigratedReloadableRoute path={routeLinks.insights.root}>
                <LicenseChecker spaceId={props.spaceId}>
                    <Switch>
                        <MigratedReloadableRoute path={routeLinks.insights.reports} exact render={(routeProps: RouteComponentProps<{
        spaceId: string;
    }>) => <InsightsReportListPageWithPageDefinition spaceId={routeProps.match.params.spaceId}/>}/>
                        <MigratedReloadableRoute path={reportLinks.root} render={(routeProps: RouteComponentProps<{
            spaceId: string;
            reportId: string;
        }>) => (<InsightsReportLoader spaceId={routeProps.match.params.spaceId} reportId={routeProps.match.params.reportId}>
                                    {({ report, refreshReport }) => (<Switch>
                                            <MigratedReloadableRoute path={reportLinks.overview} render={() => <InsightsReportOverviewPageWithPageDefinition report={report}/>}/>
                                            <MigratedReloadableRoute path={reportLinks.frequency} exact render={() => <InsightsReportDeploymentFrequencyPageWithPageDefinition report={report}/>}/>
                                            <MigratedReloadableRoute path={reportLinks.leadTime} exact render={() => <InsightsReportDeploymentLeadTimePageWithPageDefinition report={report}/>}/>
                                            <MigratedReloadableRoute path={reportLinks.failureRate} exact render={() => <InsightsReportDeploymentFailureRatePageWithPageDefinition report={report}/>}/>
                                            <MigratedReloadableRoute path={reportLinks.timeToRecovery} exact render={() => <InsightsReportDeploymentMeanTimeToRecoveryPageWithPageDefinition report={report}/>}/>
                                            <MigratedReloadableRoute path={reportLinks.settings} exact render={() => <InsightsReportSettingsPageWithPageDefinition report={report} refreshReport={refreshReport}/>}/>
                                            <MigratedReloadableRoute path={reportLinks.deploymentDuration} exact render={() => <InsightsReportDeploymentDurationPageWithPageDefinition report={report}/>}/>
                                            <RedirectAs404 />
                                        </Switch>)}
                                </InsightsReportLoader>)}></MigratedReloadableRoute>
                        <RedirectAs404 />
                    </Switch>
                </LicenseChecker>
            </MigratedReloadableRoute>
        </Switch>);
};
export default InsightsRoutes;
