import React from "react";
export function ContainerImageIcon() {
    return (<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_497_933)">
                <path d="M7.16998 9.51318C6.3403 9.51318 5.66998 10.1827 5.66998 11.0113C5.66998 11.8399 6.3403 12.5094 7.16998 12.5094V27.4906C6.3403 27.4906 5.66998 28.16 5.66998 28.9887C5.66998 29.8173 6.3403 30.4868 7.16998 30.4868H34.17C34.9997 30.4868 35.67 29.8173 35.67 28.9887C35.67 28.16 34.9997 27.4906 34.17 27.4906V12.5094C34.9997 12.5094 35.67 11.8399 35.67 11.0113C35.67 10.1827 34.9997 9.51318 34.17 9.51318H7.16998ZM13.92 15.1311V24.8689C13.92 25.4915 13.4184 25.9925 12.795 25.9925C12.1715 25.9925 11.67 25.4915 11.67 24.8689V15.1311C11.67 14.5085 12.1715 14.0075 12.795 14.0075C13.4184 14.0075 13.92 14.5085 13.92 15.1311ZM19.17 15.1311V24.8689C19.17 25.4915 18.6684 25.9925 18.045 25.9925C17.4215 25.9925 16.92 25.4915 16.92 24.8689V15.1311C16.92 14.5085 17.4215 14.0075 18.045 14.0075C18.6684 14.0075 19.17 14.5085 19.17 15.1311ZM24.42 15.1311V24.8689C24.42 25.4915 23.9184 25.9925 23.295 25.9925C22.6715 25.9925 22.17 25.4915 22.17 24.8689V15.1311C22.17 14.5085 22.6715 14.0075 23.295 14.0075C23.9184 14.0075 24.42 14.5085 24.42 15.1311ZM29.67 15.1311V24.8689C29.67 25.4915 29.1684 25.9925 28.545 25.9925C27.9215 25.9925 27.42 25.4915 27.42 24.8689V15.1311C27.42 14.5085 27.9215 14.0075 28.545 14.0075C29.1684 14.0075 29.67 14.5085 29.67 15.1311Z" fill="#1A77CA"/>
            </g>
            <defs>
                <clipPath id="clip0_497_933">
                    <rect width="30" height="23.9699" fill="white" transform="translate(5.66998 8.01501)"/>
                </clipPath>
            </defs>
        </svg>);
}
