import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { ProjectInsightsDeploymentDurationPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsDeploymentDuration/ProjectInsightsDeploymentDurationPage";
import { ProjectInsightsFailureRatePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFailureRate/ProjectInsightsFailureRatePage";
import { ProjectInsightsFrequencyPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsFrequency/ProjectInsightsFrequencyPage";
import { ProjectInsightsLeadTimePage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsLeadTime/ProjectInsightsLeadTimePage";
import { ProjectInsightsOverviewPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsOverview/ProjectInsightsOverviewPage";
import { ProjectInsightsTimeToRecoveryPage } from "~/areas/projects/components/ProjectInsights/ProjectInsightsTimeToRecovery/ProjectInsightsTimeToRecoveryPage";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import { projectInsightsLinks } from "./projectInsightsLinks";
const ProjectInsightsOverviewPageWithPageDefinition = withPage({ page: pageIds.project().insights.overview })(ProjectInsightsOverviewPage);
const ProjectInsightsLeadTimePageWithPageDefinition = withPage({ page: pageIds.project().insights.leadTime })(ProjectInsightsLeadTimePage);
const ProjectInsightsFrequencyPageWithPageDefinition = withPage({ page: pageIds.project().insights.frequency })(ProjectInsightsFrequencyPage);
const ProjectInsightsFailureRatePageWithPageDefinition = withPage({ page: pageIds.project().insights.failureRate })(ProjectInsightsFailureRatePage);
const ProjectInsightsMTTRPageWithPageDefinition = withPage({ page: pageIds.project().insights.timeToRecovery })(ProjectInsightsTimeToRecoveryPage);
const ProjectInsightsDeploymentDurationPageWithPageDefinition = withPage({ page: pageIds.project().insights.deploymentDuration })(ProjectInsightsDeploymentDurationPage);
const ProjectInsightsRoutes = () => {
    const match = useRouteMatch();
    if (!match)
        return <InternalRedirect to={routeLinks.root}/>;
    const links = projectInsightsLinks(match.path);
    return (<Switch>
            <MigratedReloadableRoute path={links.leadTime} render={() => <ProjectInsightsLeadTimePageWithPageDefinition />}/>
            <MigratedReloadableRoute path={links.frequency} render={() => <ProjectInsightsFrequencyPageWithPageDefinition />}/>
            <MigratedReloadableRoute path={links.failureRate} render={() => <ProjectInsightsFailureRatePageWithPageDefinition />}/>
            <MigratedReloadableRoute path={links.timeToRecovery} render={() => <ProjectInsightsMTTRPageWithPageDefinition />}/>
            <MigratedReloadableRoute path={links.deploymentDuration} render={() => <ProjectInsightsDeploymentDurationPageWithPageDefinition />}/>
            <MigratedReloadableRoute path={links.overview} render={() => <ProjectInsightsOverviewPageWithPageDefinition />}/>
            <RedirectAs404 />
        </Switch>);
};
export default ProjectInsightsRoutes;
