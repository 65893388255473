import { UnauthenticatedAnalyticSessionProvider } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { PageProvider } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Provider } from "react-redux";
import { PageChangedCallbacks } from "~/PageChangedCallbacks";
import DynamicExtensionsLoader from "~/areas/dynamicExtensions/dynamicExtensionsLoader";
import { ClientConnector } from "~/areas/infrastructure/components/ClientConnector/ClientConnector";
import { DevToolsContextProvider } from "~/components/DevTools/DevToolsContext";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary";
import { ErrorContextProvider } from "~/components/ErrorContext/ErrorContext";
import { LogErrorDisplayer } from "~/components/LogErrors/LogErrorDisplayer";
import { LogEventCapturer } from "~/components/LogErrors/LogEventCapturer";
import { OctopusRouter } from "~/components/OctopusRouter/OctopusRouter";
import store from "~/store";
import { ThemeFromLocalStorage } from "./components/Theme/ThemeFromLocalStorage";
const initialPage: PageDefinition = { Area: "App", Id: "App", Name: "App" };
const App: React.FC = () => {
    return (<LogEventCapturer>
            <UnauthenticatedAnalyticSessionProvider>
                <ErrorBoundary>
                    <ErrorContextProvider>
                        <Provider store={store}>
                            <PageProvider initialPage={initialPage}>
                                <PageChangedCallbacks />
                                <ThemeFromLocalStorage>
                                    <LogErrorDisplayer />
                                    <ClientConnector renderWhenConnected={() => (<DevToolsContextProvider>
                                                <OctopusRouter />
                                                <DynamicExtensionsLoader />
                                            </DevToolsContextProvider>)}/>
                                </ThemeFromLocalStorage>
                            </PageProvider>
                        </Provider>
                    </ErrorContextProvider>
                </ErrorBoundary>
            </UnauthenticatedAnalyticSessionProvider>
        </LogEventCapturer>);
};
App.displayName = "App"
export default App;
