import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import { isUrl } from "app/components/Navigation/InternalLink/isUrl";
import { useUrlResolver } from "app/components/Navigation/useUrlResolver";
import { useLocation, useParams } from "react-router";
export function useIsPortalUrlActive(): (targetUrl: LinkHref, exact: boolean | undefined) => boolean {
    const urlResolver = useUrlResolver();
    const location = useLocation();
    const routeParams = useParams<{
        spaceId: string | undefined;
    }>();
    const spaceId = routeParams.spaceId ?? undefined;
    const path = location.pathname;
    return (targetUrl: LinkHref, exact: boolean | undefined) => {
        if (!targetUrl) {
            return false;
        }
        const resolvedToUrl = isUrl(targetUrl) ? urlResolver.resolve(targetUrl) : resolveStringPathWithSpaceId(targetUrl, spaceId);
        const toUrlWithoutQueryStrings = resolvedToUrl === null ? "" : resolvedToUrl.split("?")[0];
        return exact ? path === toUrlWithoutQueryStrings : path.startsWith(toUrlWithoutQueryStrings);
    };
}
