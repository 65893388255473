/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import AreaTitle from "~/components/AreaTitle";
import BusyFromPromise from "~/components/BusyFromPromise";
import { ContextualHelpLayout } from "~/components/ContextualHelpLayout/ContextualHelpLayout";
import type { PrimaryPageAction } from "~/components/PageActions/PageActions";
import { PageHeaderPrimary } from "~/components/PageHeaderPrimary";
import PaperLayout from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useIsPageHeaderVNextEnabledOutsideOfProjects } from "~/components/RootRoutes/useIsPageHeaderVNextEnabled";
export const TasksPage = () => {
    const isPageHeaderVNextEnabled = useIsPageHeaderVNextEnabledOutsideOfProjects();
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const scriptConsoleAction: PrimaryPageAction = {
        type: "navigate",
        label: "Script Console",
        path: links.scriptConsolePage.generateUrl(),
        hasPermissions: isAllowed({
            permission: [Permission.AdministerSystem, Permission.TaskCreate],
            wildcard: true,
        }),
    };
    return (<main>
            {!isPageHeaderVNextEnabled && <AreaTitle link={links.tasksPage.generateUrl()} title="Tasks"/>}
            <Tasks renderLayout={({ busy, errors, hasLoadedOnce, children }) => {
            return (<BusyFromPromise promise={busy}>
                            {(busy: boolean) => (<ContextualHelpLayout>
                                    {isPageHeaderVNextEnabled && <PageHeaderPrimary title="Tasks" showLoadingIndicator={busy && hasLoadedOnce} primaryAction={scriptConsoleAction}/>}
                                    <div className={isPageHeaderVNextEnabled ? cx(containerStylesVNext, { [containerMobileStylesVNext]: !isLargerThanIpad }) : containerStyles}>
                                        <PaperLayout title={isPageHeaderVNextEnabled ? undefined : "Tasks"} primaryAction={isPageHeaderVNextEnabled ? undefined : scriptConsoleAction} busy={busy} enableLessIntrusiveLoadingIndicator={hasLoadedOnce} errors={errors} fullWidth={true}>
                                            {children}
                                        </PaperLayout>
                                    </div>
                                </ContextualHelpLayout>)}
                        </BusyFromPromise>);
        }}/>
        </main>);
};
const containerStyles = css({
    margin: `${space[12]} ${space[16]} 0`,
});
const containerStylesVNext = css({
    margin: `${space[16]} ${space[32]} 0`,
});
const containerMobileStylesVNext = css({
    margin: `${space[16]} ${space[16]} 0`,
});
