import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "../../../../routeLinks";
import { ExternalFeedPage } from "./ExternalFeedPage";
import { ExternalFeedTestPage } from "./ExternalFeedTestPage";
import { ExternalFeedsPage } from "./ExternalFeedsPage";
import { RedirectFeedBasedOnType } from "./RedirectFeedBasedOnType";
const ExternalFeedTestPageWithPageDefinition = withPage({ page: pageIds.library.feed.test })(ExternalFeedTestPage);
const ExternalFeedPageWithPageDefinition = withPage({ page: pageIds.library.feed.root })(ExternalFeedPage);
const CreateExternalFeedPageWithPageDefinition = withPage({ page: pageIds.library.feedCreate })(ExternalFeedPage);
const ListExternalFeedPageWithPageDefinition = withPage({ page: pageIds.library.feeds })(ExternalFeedsPage);
type ExternalFeedRouteProps = RouteComponentProps<{
    spaceId: string;
}>;
type SpecificExternalFeedRouteProps = RouteComponentProps<{
    spaceId: string;
    feedId: string;
}>;
export class ExternalFeedRoutes extends React.Component<RouteComponentProps> {
    render() {
        const libraryRouteLinks = routeLinks.library;
        return (<Switch>
                <MigratedReloadableRoute path={libraryRouteLinks.feedCreate} render={({ match }: ExternalFeedRouteProps) => <CreateExternalFeedPageWithPageDefinition spaceId={match.params.spaceId} create/>}/>
                <MigratedReloadableRoute path={libraryRouteLinks.feed(":feedId").redirect} render={({ match }: SpecificExternalFeedRouteProps) => <RedirectFeedBasedOnType spaceId={match.params.spaceId} feedId={match.params.feedId}/>}/>
                <MigratedReloadableRoute path={libraryRouteLinks.feed(":feedId").test} render={({ match }: SpecificExternalFeedRouteProps) => <ExternalFeedTestPageWithPageDefinition spaceId={match.params.spaceId} feedId={match.params.feedId}/>}/>
                <MigratedReloadableRoute path={libraryRouteLinks.feed(":feedId").root} render={({ match }: SpecificExternalFeedRouteProps) => <ExternalFeedPageWithPageDefinition spaceId={match.params.spaceId} feedId={match.params.feedId}/>}/>
                <MigratedReloadableRoute render={({ match }: ExternalFeedRouteProps) => <ListExternalFeedPageWithPageDefinition spaceId={match.params.spaceId}/>}/>
            </Switch>);
    }
    static displayName = "ExternalFeedRoutes";
}
export default ExternalFeedRoutes;
