import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Switch } from "react-router-dom";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import TenantLayout from "../TenantLayout/TenantLayout";
import { TenantOverviewPage } from "../TenantOverview/TenantOverviewPage";
import { TenantSettingsPage } from "../TenantSettings/TenantSettingsPage";
import { TenantTasksPage } from "../TenantTasks/TenantTasksPage";
import { TenantVariablesPage } from "../TenantVariables/TenantVariablesPage";
import { TenantsPage } from "../Tenants";
const TenantOverviewWithPageDefinition = withPage({ page: pageIds.tenant.overview })(TenantOverviewPage);
const TenantVariablesWithPageDefinition = withPage({ page: pageIds.tenant.variables })(TenantVariablesPage);
const TenantSettingsWithPageDefinition = withPage({ page: pageIds.tenant.settings })(TenantSettingsPage);
const TenantTasksWithPageDefinition = withPage({ page: pageIds.tenant.tasks })(TenantTasksPage);
const TenantsListPageWithPageDefinition = withPage({ page: pageIds.tenants })(TenantsPage);
export class TenantRoutes extends React.Component {
    render() {
        const tenantLink = routeLinks.tenant(`:tenantId`);
        return (<Switch>
                <MigratedReloadableRoute path={routeLinks.v3tenantRoutes.newTenant}>
                    <InternalRedirect to={routeLinks.tenants}/>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={tenantLink.root} render={({ match }: RouteComponentProps<{
                tenantId: string;
                spaceId: string;
            }>) => (<TenantLayout tenantId={match.params.tenantId} spaceId={match.params.spaceId}>
                            <Switch>
                                <MigratedReloadableRoute exact path={tenantLink.overview} render={() => <TenantOverviewWithPageDefinition tenantId={match.params.tenantId}/>}/>
                                <MigratedReloadableRoute exact path={tenantLink.variables().pathname} render={() => <TenantVariablesWithPageDefinition tenantId={match.params.tenantId}/>}/>
                                <MigratedReloadableRoute exact path={tenantLink.tasks} render={() => <TenantTasksWithPageDefinition tenantId={match.params.tenantId}/>}/>
                                <MigratedReloadableRoute exact path={tenantLink.settings} render={() => <TenantSettingsWithPageDefinition tenantId={match.params.tenantId}/>}/>
                                <MigratedReloadableRoute exact path={tenantLink.root} render={() => <InternalRedirect to={routeLinks.tenant(match.params.tenantId).overview}/>}/>
                                <RedirectAs404 />
                            </Switch>
                        </TenantLayout>)}></MigratedReloadableRoute>
                <MigratedReloadableRoute path={routeLinks.tenants} render={(props: RouteComponentProps<{
            spaceId: string;
        }>) => <TenantsListPageWithPageDefinition spaceId={props.match.params.spaceId}/>}/>
                <RedirectAs404 />
            </Switch>);
    }
    static displayName = "TenantRoutes";
}
export default TenantRoutes;
