import { resolveStringPathWithSpaceId } from "@octopusdeploy/portal-routes";
import type { Url } from "@octopusdeploy/portal-routes";
import { useParams } from "react-router";
import { client } from "~/clientInstance";
import { useIsRoutingVNextEnabled } from "~/components/RootRoutes/useIsRoutingVNextEnabled";
import { useLegacyRouteResolver } from "~/routing/LegacyRouteResolverContext";
export function useUrlResolver(): UrlResolver {
    const legacyRouteResolver = useLegacyRouteResolver();
    const spaceId = useSpaceId();
    const isRoutingVNextEnabled = useIsRoutingVNextEnabled();
    return {
        resolve(url: Url): string {
            if (isRoutingVNextEnabled) {
                return url.resolveWithSpaceId(spaceId);
            }
            const legacyUrl = url.resolveLegacyRoute(legacyRouteResolver);
            return resolveStringPathWithSpaceId(legacyUrl, spaceId);
        },
    };
}
function useSpaceId() {
    const { spaceId } = useParams<{
        spaceId?: string;
    }>();
    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    return (spaceId || client.spaceId) ?? null;
}
export interface UrlResolver {
    resolve(url: Url): string;
}
