import { HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { Switch } from "react-router-dom";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { MigratedRoute } from "~/components/MigratedRoute/MigratedRoute";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import { withPage } from "~/components/Page/WithPage";
import pageIds from "~/pageIds";
import { BranchAwareRedirect } from "../../ProjectsRoutes/BranchAwareRedirect";
import ProjectVariables from "./ProjectVariables";
export const ProjectVariablesPage = withPage({ page: pageIds.project().variables.root })(ProjectVariables);
type ProjectVariablesRouteProps = {
    path?: string;
};
export function ProjectVariablesRoute({ path }: React.PropsWithChildren<ProjectVariablesRouteProps>) {
    return (<BranchAwareRedirect condition={(project) => HasVariablesInGit(project.PersistenceSettings)}>
            <ErrorContextProvider>
                <Switch>
                    <MigratedRoute path={path ? `${path}` : undefined} exact={true} render={() => <ProjectVariablesPage />}/>
                    <RedirectAs404 />
                </Switch>
            </ErrorContextProvider>
        </BranchAwareRedirect>);
}
export const MigratedProjectVariablesRoute = ProjectVariablesRoute;
