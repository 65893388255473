import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import type { ButtonImportance } from "@octopusdeploy/design-system-components";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import { DialogTrigger } from "../Dialog/DialogTrigger";
import type { PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
interface RenderDialogProps {
    open: boolean;
    closeDialog(): void;
    openDialog(): void;
}
interface StepTileOpenDialogButtonProps {
    label: string;
    importance: ButtonImportance;
    disabled?: boolean;
    permission?: PermissionCheckProps;
    onClick?: () => void; // Track analytics
    wideDialog?: boolean;
    renderDialog?: (props: RenderDialogProps) => React.ReactNode;
    children?: React.ReactNode;
}
export const StepTileOpenDialogButton = (props: StepTileOpenDialogButtonProps) => {
    const getPermissionLabel = () => {
        if (props.permission === undefined) {
            return "No";
        }
        if (Array.isArray(props.permission.permission)) {
            return props.permission.permission.join(", ");
        }
        return props.permission.permission;
    };
    const disabledDueToPermission = !!props.permission ? !isAllowed(props.permission) : false;
    const permissionLabel = getPermissionLabel();
    const label = disabledDueToPermission ? `${permissionLabel} permission required` : props.label;
    return (<DialogTrigger render={({ open, openDialog, closeDialog }) => {
            const wrappedOpenDialog = () => {
                if (props.onClick) {
                    props.onClick();
                }
                openDialog();
            };
            return (<div className={styles.base}>
                        <Button label={label} onClick={wrappedOpenDialog} importance={props.importance} disabled={disabledDueToPermission || props.disabled} size={"small"}/>
                        {props.renderDialog ? (props.renderDialog({ open, openDialog: wrappedOpenDialog, closeDialog })) : (<Dialog open={open} wide={props.wideDialog}>
                                {props.children}
                            </Dialog>)}
                    </div>);
        }}/>);
};
StepTileOpenDialogButton.displayName = "StepTileOpenDialogButton";
const styles = {
    base: css({
        display: "inline-block",
    }),
};
