import { css } from "@emotion/css";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import Logo from "~/components/Logo/Logo";
import InternalLink from "~/components/Navigation/InternalLink/index";
type TenantPageLink = "Overview" | "Variables";
interface TenantsDataTableTenantNameCellWithLinkProps {
    spaceId: string;
    tenantId: string;
    tenantName: string;
    tenantLogoLink: string;
    linkToPage?: TenantPageLink;
}
export function TenantsDataTableNameCellWithLink({ spaceId, tenantId, tenantName, tenantLogoLink, linkToPage = "Overview" }: TenantsDataTableTenantNameCellWithLinkProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const linkTarget = linkToPage === "Variables" ? links.tenantVariablesPage.generateUrl({ tenantId, spaceId }) : links.tenantOverviewPage.generateUrl({ spaceId, tenantId });
    function handleClick() {
        const resource = linkToPage === "Variables" ? "Tenant Variables" : "Tenant";
        dispatchAction(`View ${resource}`, { resource: resource, action: Action.View });
    }
    return (<InternalLink to={linkTarget} onClick={handleClick}>
            <div className={style}>
                <div className={logoStyle}>
                    <Logo url={tenantLogoLink}/>
                </div>
                <div>{tenantName}</div>
            </div>
        </InternalLink>);
}
interface TenantsDataTableTenantNameCellProps {
    tenantName: string;
    tenantLogoLink: string;
    overflow?: boolean;
}
export function TenantsDataTableNameCell({ tenantName, tenantLogoLink, overflow }: TenantsDataTableTenantNameCellProps) {
    return (<div className={style}>
            <div className={logoStyle}>
                <Logo url={tenantLogoLink} overflow={overflow}/>
            </div>
            <div>{tenantName}</div>
        </div>);
}
const centeredFlex = css({
    display: "flex",
    alignItems: "center",
});
const logoScale = css({
    scale: "0.7",
});
const style = css(centeredFlex);
const logoStyle = css(logoScale);
