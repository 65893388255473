import type { RouteTemplate } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ReactNode } from "react";
import ReloadableRoute from "~/components/ReloadableRoute/index";
import type { PageGroup } from "~/routing/pageRegistrations/createPageMap";
import { formatRoutePath } from "~/routing/pageRegistrations/formatRoutePath";
import { decodeRouteParams } from "./decodeRouteParams";
export function routeSegment<PageGroupType extends PageGroupConstraint>(pageGroup: PageGroupType, render: (routeProps: ExtractRouteParamsFromRouteTemplate<PageGroupType["partialRoute"]>, pages: PageGroupType["pages"], childGroups: PageGroupType["childGroups"]) => ReactNode, exact?: boolean, doNotReloadWhenTheseKeysChange?: string[]) {
    return (<ReloadableRoute exact={exact} doNotReloadWhenTheseKeysChange={doNotReloadWhenTheseKeysChange} path={formatRoutePath(pageGroup.partialRoute)} render={(props) => render(decodeRouteParams(props.match.params), pageGroup.pages, pageGroup.childGroups)}/>);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractRouteParamsFromRouteTemplate<Template extends RouteTemplate<any>> = Template extends RouteTemplate<infer RouteParams> ? RouteParams : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageGroupConstraint = PageGroup<any, any, any>;
