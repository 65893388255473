import { css } from "@emotion/css";
import { Button, FeedbackIcon } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { useState } from "react";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
import { CodeEditorFeedbackDialog } from "./CodeEditorFeedbackDialog";
import { useProcessCodeEditorState } from "./isProcessCodeEditorEnabled";
type ProcessCodeEditorCalloutProps = {
    onFeedbackSubmitted: (feedback: string) => void;
};
export function ProcessCodeEditorCallout({ onFeedbackSubmitted }: ProcessCodeEditorCalloutProps) {
    const { showCallout, calloutDismissed } = useProcessCodeEditorState();
    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState<boolean>(false);
    if (!showCallout)
        return null;
    const onFeedbackClose = () => {
        setFeedbackDialogOpen(false);
    };
    const onSubmit = (feedback: string) => {
        onFeedbackSubmitted(feedback);
    };
    const styles = {
        container: css({
            paddingLeft: space[16],
            paddingRight: space[16],
            paddingBottom: space[16],
        }),
        content: css({
            display: "flex",
            flexDirection: "column",
            gap: space[4],
        }),
    };
    return (<div className={styles.container}>
            <Callout title={"Editing deployment processes in code"} type={CalloutType.NewFeature} isExperimentalFeature={true} canClose={true} onClose={() => calloutDismissed()}>
                <div className={styles.content}>
                    <div>
                        <p>Team DevEx are exploring a new code editing experience for deployment processes and would appreciate your feedback!</p>
                        <p>To help us out with this, please try using the code editor to make changes to the OCL of your deployment process directly. You can switch back to form editing at any time using the Form button above.</p>
                    </div>
                    <div>
                        <Button label="Provide Feedback" importance="neutral" leftIcon={<FeedbackIcon />} onClick={() => setFeedbackDialogOpen(true)}/>
                    </div>
                </div>
            </Callout>
            <CodeEditorFeedbackDialog open={feedbackDialogOpen} onClose={onFeedbackClose} onSubmit={onSubmit}/>
        </div>);
}
