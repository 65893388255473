import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { HasGitPersistenceSettings, IsUsingGitHubAuth } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, GitHubAppAuthorizationStatus, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Callout, { CalloutType } from "~/primitiveComponents/dataDisplay/Callout";
interface GitHubAppAuthCheckCalloutProps {
    authorizationStatus: GitHubAppAuthorizationStatus;
    onAuthorizeClick: (location: string) => void;
}
export function GitHubAppAuthCheckCallout({ authorizationStatus, onAuthorizeClick }: GitHubAppAuthCheckCalloutProps) {
    const styles = {
        authMessageContainer: css({
            display: "flex",
            flexDirection: "row",
            padding: space["16"],
            justifyContent: "space-between",
        }),
        authMessageInstructions: css({
            alignSelf: "center",
        }),
    };
    if (authorizationStatus.IsAuthorized)
        return null;
    return (<Callout title={"GitHub Authorization"} type={CalloutType.Information} canClose={false}>
            <div className={styles.authMessageContainer}>
                <div className={styles.authMessageInstructions}>In order for Octopus Deploy to communicate with GitHub on your behalf you must Authorize the Octopus Deploy GitHub Application.</div>
                <Button label="Authorize" onClick={() => {
            const route = repository.GitHubApp.getAuthorizeAppUrl(window.location.href);
            onAuthorizeClick(route);
        }} importance={"primary"}/>
            </div>
        </Callout>);
}
interface GitHubAppAuthCheckInternalProps {
    doBusyTask: DoBusyTask;
    forceRefresh?: boolean;
}
function GitHubAppAuthCheckInternal({ doBusyTask, forceRefresh }: GitHubAppAuthCheckInternalProps) {
    const [authorizationStatus, setAuthorizationStatus] = useState<GitHubAppAuthorizationStatus | undefined>(undefined);
    const clickAction = (route: string) => window.location.replace(route);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const authorizationStatus = await repository.GitHubApp.getAuthorizationStatus();
        setAuthorizationStatus(authorizationStatus);
    }, [forceRefresh]);
    return <>{authorizationStatus && <GitHubAppAuthCheckCallout authorizationStatus={authorizationStatus} onAuthorizeClick={clickAction}/>}</>;
}
interface GitHubAppAuthCheckDatabaseComponentProps {
    forceRefresh?: boolean;
}
class GitHubAppAuthCheckDatabaseComponent extends DataBaseComponent<GitHubAppAuthCheckDatabaseComponentProps> {
    constructor(props: GitHubAppAuthCheckDatabaseComponentProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return <GitHubAppAuthCheckInternal doBusyTask={this.doBusyTask} forceRefresh={this.props.forceRefresh}/>;
    }
    static displayName = "GitHubAppAuthCheckDatabaseComponent";
}
interface GitHubAppAuthCheckProps {
    errors?: Errors;
}
function GitHubAppAuthCheck(props: GitHubAppAuthCheckProps) {
    return (<ErrorContextProvider>
            <GitHubAppAuthCheckDatabaseComponent forceRefresh={props.errors != undefined}/>
        </ErrorContextProvider>);
}
interface SecuredGitHubAuthCheckProps {
    project: ProjectResource | undefined;
    permissions: Permission[];
}
export function SecuredGitHubAuthCheck(props: SecuredGitHubAuthCheckProps) {
    const { project, permissions } = props;
    const gitHubConnectionsEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    return (<>
            {gitHubConnectionsEnabled &&
            project &&
            isAllowed({ permission: permissions, project: project.Id, wildcard: true }) &&
            HasGitPersistenceSettings(project.PersistenceSettings) &&
            IsUsingGitHubAuth(project.PersistenceSettings.Credentials) && <GitHubAppAuthCheck />}
        </>);
}
export default GitHubAppAuthCheck;
