import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Switch } from "react-router-dom";
import { DeploymentCreateGoal } from "~/areas/projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import { MigratedReloadableRoute } from "~/components/MigratedRoute/MigratedRoute";
import { withPage } from "~/components/Page/WithPage";
import { RedirectReleaseVersion } from "~/components/SlugSafeRedirect/SlugSafeRedirect";
import pageIds from "~/pageIds";
import routeLinks from "~/routeLinks";
import type { CreateDeploymentGoal } from "../Deployments/CreateDeploymentPage";
import { CreateDeploymentPage } from "../Deployments/CreateDeploymentPage";
import { DeploymentDetailsPage } from "../Deployments/DeploymentDetailsPage";
import { CreateOrEditReleasePage } from "../Edit/CreateOrEditReleasePage";
import { ReleasePage } from "../ReleasePage";
import { ReleasesPage } from "../ReleasesPage";
const releaseInstancePages = pageIds.project().release();
export const ReleasePageWithPageDefinition = withPage({ page: releaseInstancePages.root })(ReleasePage);
export const ReleaseDeploymentDetailsPageWithPageDefinition = withPage({ page: releaseInstancePages.deployments.specific })(DeploymentDetailsPage);
export const CreateDeploymentPageWithPageDefinition = withPage({ page: releaseInstancePages.deployments.create })(CreateDeploymentPage);
export const CreateReleasePageWithPageDefinition = withPage({ page: pageIds.project().releaseCreate })(CreateOrEditReleasePage);
export const ReleasesPageWithPageDefinition = withPage({ page: pageIds.project().releases })(ReleasesPage);
export const EditReleasePageWithPageDefinition = withPage({ page: releaseInstancePages.edit })(CreateOrEditReleasePage);
export class ReleasesRoute extends React.Component {
    render() {
        const projectLinks = routeLinks.projectRoutes();
        const releaseLinks = projectLinks.deployments.releases;
        const releaseInstanceLinks = projectLinks.deployments.release(`:releaseVersion`);
        return (<Switch>
                <MigratedReloadableRoute path={projectLinks.deployments.releaseCreate} render={() => <CreateReleasePageWithPageDefinition />}/>
                <MigratedReloadableRoute path={releaseInstanceLinks.root}>
                    <RedirectReleaseVersion>
                        <Switch>
                            <MigratedReloadableRoute path={releaseInstanceLinks.edit} render={(routeProps: RouteComponentProps<{
            releaseVersion: string;
        }>) => <EditReleasePageWithPageDefinition releaseVersion={routeProps.match.params.releaseVersion}/>}/>
                            <MigratedReloadableRoute path={releaseInstanceLinks.deployments.create(":goal?", ":previousId?", ":tenantIds?")} render={({ match }: RouteComponentProps<CreateDeploymentRouteParams>) => <CreateDeploymentPageWithPageDefinition goal={getDeploymentGoal(match.params)} releaseVersion={match.params.releaseVersion}/>}/>
                            <MigratedReloadableRoute path={releaseInstanceLinks.deployments.specific(":deploymentId")} render={({ match }: RouteComponentProps<{
            deploymentId: string;
        }>) => <ReleaseDeploymentDetailsPageWithPageDefinition deploymentId={match.params.deploymentId}/>}/>
                            <MigratedReloadableRoute exact path={releaseInstanceLinks.root} render={({ match }: RouteComponentProps<{
            releaseVersion: string;
        }>) => <ReleasePageWithPageDefinition releaseVersion={match.params.releaseVersion}/>}/>
                        </Switch>
                    </RedirectReleaseVersion>
                </MigratedReloadableRoute>
                <MigratedReloadableRoute path={releaseLinks} render={() => <ReleasesPageWithPageDefinition />}/>
            </Switch>);
    }
    static displayName = "ReleasesRoute";
}
interface CreateDeploymentRouteParams {
    goal?: DeploymentCreateGoal;
    tenantIds?: string;
    tags?: string;
    previousId?: string;
    releaseVersion: string;
}
function getDeploymentGoal({ goal, previousId, tenantIds, tags }: CreateDeploymentRouteParams): CreateDeploymentGoal {
    if (goal === DeploymentCreateGoal.To || goal === undefined) {
        return {
            environmentIds: previousId?.split(",").filter((id) => Boolean(id)),
            tenantIds: tenantIds?.split(",").filter((id) => Boolean(id)),
            tenantTags: tags?.split(",").filter((id) => Boolean(id)),
        };
    }
    if (goal == DeploymentCreateGoal.TryAgain && !previousId) {
        throw new Error("Previous deployment id needs to be provided when retrying a deployment.");
    }
    if (goal == DeploymentCreateGoal.TryAgain && previousId) {
        return {
            previousDeploymentId: previousId,
        };
    }
    return {};
}
